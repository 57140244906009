export default class NotificationStateManager{
    static getAllNotifications = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.notifications = data.notification || [];
        state.adminPaginate = data.paginate || [];

        return state;
    }

    static getMerchantNotifications = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.merchantNotifications = data.notification || [];
        state.merchantPaginate = data.paginate || [];

        return state;
    }
    static getBasicNotifications = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.basicNotifications = data.notification || [];
        state.basicPaginate = data.paginate || [];

        return state;
    }

    static getAllNotificationsWithoutPage = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.notificationsWithoutPage = data.notification || [];
        return state;
    }

    static getMerchantNotificationsWithoutPage = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.merchantNotificationsWithoutPage = data.notification || [];
        return state;
    }
    static getBasicNotificationsWithoutPage = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.basicNotificationsWithoutPage = data.notification || [];
        return state;
    }

}