import * as ActionTypes             from './action-types';
import qs from "qs";

export const updateLocalCurrentTeam = (team) => ({
    type: ActionTypes.UPDATE_LOCAL_CURRENT_TEAM,
    team
})

export const showHideUnassignButton = (flag, newSelectedRowKeys) => ({
    type: ActionTypes.SHOW_UNASSIGN_BUTTON,
    data: {flag, newSelectedRowKeys}
})

export const getAllErrorType = (params = {}) => {
    
    let url = `${process.env.REACT_APP_API_URL}/api/errorType?`
    if (params.search) {
        url += `filters[name]=${params.search}`
    }
    return {
    type: ActionTypes.GET_ALL_ERRORS,
    payload: {
        request: {
            url,
            method: "get",
            params: params,
            paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
            },
        }
    }
}};

export const getTeamById = (teamId) => {
    
    let url = `${process.env.REACT_APP_API_URL}/api/teams/${teamId}`
    return {
    type: ActionTypes.GET_TEAM_BY_ID,
    payload: {
        request: {
            url,
            method: "get",
        }
    }
}};

export const createErrorType = (payload) => ({
    type: ActionTypes.CREATE_ERROR_TYPE,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/errorType`,
            method: "post",
            data: payload
        }
    }
})

export const updateErrorById =(id,payload) => ({
    type: ActionTypes.UPDATE_ERROR_TYPE,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/errorType/${id}`,
            method: "patch",
            data: payload
        }
    },
    meta: payload
});

export const deleteById = (teamId) => ({
    type: ActionTypes.DELETE_TEAM,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/errorType/${teamId}`,
            method: "delete",
        }
    },
    meta:{
        "_id": teamId
    }
})

export const updateTeamByStatus =(teamId,status) => ({
    type: ActionTypes.UPDATE_TEAM_STATUS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/teams/${teamId}`,
            method: "put",
            data:{
                "isActive": status,
            }
        }
    },
    meta:{
            "isActive": status,
            "_id": teamId,
        }
});

export const updateErrorByStatus =(errorId,status) => ({
    type: ActionTypes.UPDATE_ERROR_STATUS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/errorType/${errorId}`,
            method: "patch",
            data:{
                "isActive": status,
            }
        }
    },
    meta:{
            "isActive": status,
            "_id": errorId,
        }
});
