import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/shelf/action-types'
import ShelfStateManager        from './state-manager';

const INITIAL_STATE = {
  shelf:[],
  searchedShelf:[]
};

export default function ShelfStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case S(ActionTypes.GET_ALL_SHELF):
                return ShelfStateManager.getAllShelf(state,action);
        case S(ActionTypes.CREATE_SHELF):
                return ShelfStateManager.createShelf(state,action);
        case S(ActionTypes.UPDATE_SHELF):
                return ShelfStateManager.updateShelf(state,action);
        case S(ActionTypes.DELETE_SHELF):
                return ShelfStateManager.deleteShelf(state,action);
        case S(ActionTypes.GET_SINGLE_SHELF):
                return ShelfStateManager.getSkuAssociatedShelf(state,action);
        case S(ActionTypes.GET_ALL_SEARCH_SKU):
                return ShelfStateManager.getSearchSKU(state,action);
        case (ActionTypes.UPDATE_SHELF_BY_SEARCH):
                return ShelfStateManager.updateShelfBySearch(state,action);
        default:
            return state;
    }
}