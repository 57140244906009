import React, { useState, useMemo } from "react";
import Fullscreen from "fullscreen-react";
import { Button, Drawer, Input, Modal, Switch } from "antd";
import {
  ColumnHeightOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  CopyFilled,
  DeleteFilled,
  EditOutlined,
  DownloadOutlined,
  SearchOutlined
} from "@ant-design/icons";
import CustomTable from "components/common/table/TableNew";
import CommonLayout from "components/layout/Layout";
import TableSearchDropdown from "components/TableSearchDropdown";
import { useNavigate } from "react-router-dom";
import { REPORT_COLUMN } from "utils/column";
import session from "utils/session";
import { TextMessage } from "utils/constants";
import ReportService from "services/report-service/report-service";
import useSWR from "swr";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import ViewReport from './components/view-report.component'
import { ReportVisibility } from "common/enum";
const { Search } = Input;

const Report = () => {
  const [filters, setFilters] = useState({
    page: 1,
    perPage: 10,
    orderBy: "",
    filters: {
      title: "",
      status: "",
      activate: ""
    },
  });
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [isEnter, setIsEnter] = useState(false);
  const [viewReportModal, setViewReportModal] = useState(undefined);

  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const reportService = useMemo(() => new ReportService(), []);

  const userId = session.userId;
  const { isAdmin } = session;
  const {
    data,
    isLoading,
    isValidating,
    mutate: getReportList,
  } = useSWR({ key: "report-list", ...filters }, (payload) =>
    reportService.getReportList(payload)
  );

  const reportList = data?.data?.reports ?? [];
  const reportPagination = data?.data?.pagination ?? {};

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };



  const deleteReport = (val) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const response = await reportService.deleteReportById(val._id);
        if (response?.message) {
          showAlert({
            message: response.message ?? "Report Status Updated Successfully",
            variant: AlertVariant.SUCCESS,
          });
          getReportList();
        }
      },
      okType: "primary",
    });
  };

  const cloneReport = (val) => {
    Modal.confirm({
      title: TextMessage.REPORT_CLONE_CONFIRM_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        const response = await reportService.cloneReport(val._id);
        if (response?.message) {
          showAlert({
            message: response.message ?? "Report Cloned Successfully",
            variant: AlertVariant.SUCCESS,
          });
          getReportList();
        }
      },
      okType: "primary",
    });
  };

  const onDownloadReport = (val) => {
    reportService.downloadReport(val?._id, val?.title);
  };

  const onSearch = (value) => {
    setFilters((prev) => ({
      ...prev,
      page: 1,
      filters: {
        ...prev.filters,
        title: value,
      },
    }));
  };

  const changeReportActivation = (report) => {
    if (report.activate) {
      Modal.confirm({
        title: "Are you sure you want to deactivate this report?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        cancelText: "No",
        onOk: () => updateReport(report._id, { activate: false }),
        okType: "primary",
      });
    } else {
      Modal.confirm({
        title: "Do you want to activate the Report?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        cancelText: "No",
        onOk: () => updateReport(report._id, { activate: true }),
        okType: "primary",
      });
    }
  };

  const updateReport = async (id, params) => {
    const response = await reportService.updateReportById(id, params);
    if (response?.message) {
      showAlert({
        message: response.message ?? "Report Status Updated Successfully",
        variant: AlertVariant.SUCCESS,
      });
      getReportList();
    }
  };

  const getTableActions = (row) => {
    const hasOperationAccess = isAdmin || row?.createdBy?._id === userId;
    return (
      <div className="action-btns-container">
        <Button
          className="btn-style"
          disabled={!hasOperationAccess}
          onClick={() =>
            navigate(`/reports/edit/${row._id}`)}
        >
          <EditOutlined className="mr-5" />
        </Button>
        <Button
          className="btn-style"
          onClick={() => {
            onDownloadReport(row);
          }}
        >
          <DownloadOutlined className="mr-5" />
        </Button>
        <Button
          className="btn-style"
          onClick={() => {
            deleteReport(row);
          }}
          disabled={!hasOperationAccess}
        >
          <DeleteFilled className="mr-5" />
        </Button>
        <Button
          className="btn-style"
          disabled={!isAdmin && row.status === ReportVisibility.PUBLIC}
          onClick={() => {
            cloneReport(row);
          }}
        >
          <CopyFilled className="mr-5" />
        </Button>
      </div>
    );
  };

  const handleUpdateComplete = () => {
    getReportList();
    setViewReportModal(undefined);
  };

  const setColumns = () => {
    let cols = [];

    REPORT_COLUMN.forEach((o) => {
      if (o.dataIndex === "title") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => (
            <TableSearchDropdown
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              dataIndex={o.dataIndex}
            />
          ),
          filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }
    });
    return cols;
  };

  const setupRows = (data) => {
    return (
      data?.map((o) => {
        return {
          ...o,
          _id: o._id,
          activate: (
            <Switch
              checked={o.activate}
              className="switchStyle"
              onChange={() => changeReportActivation(o)}
              disabled={!isAdmin && o?.createdBy?._id !== userId}
            />
          ),
          action: getTableActions(o),
          title: <p style={{ cursor: 'pointer' }} onClick={() => setViewReportModal(o?._id)}>{o?.title}</p>,
        };
      }) ?? []
    );
  };

  return (
    <CommonLayout className="no-content-mr" breadCrumbText="Reports">
      <div className="promo-body">
        <div className="body-right">
          <Search
            placeholder="Search"
            enterButton
            allowClear
            onSearch={onSearch}
            className="userBtn"
          />
        </div>
        <div className="body-left">
          <Button
            type="primary"
            className="addShelfBtn"
            onClick={() => navigate("/reports/create")}
          >
            Create reports
          </Button>
        </div>
      </div>
      <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
        <div className="table-cardbody">
          <div className="tablebody">
            <div className="table-main">
              <div className="table-header">
                <div></div>
                <div className="filterGrpWrapper1">
                  <div className="settingGrp">
                    <ReloadOutlined
                      onClick={() => getReportList()}
                      className="reloadIconStyle"
                    />
                    <ColumnHeightOutlined
                      className="reloadIconStyle"
                      onClick={handleTableHeight}
                    />
                    <FullscreenOutlined
                      onClick={() => {
                        setIsEnter((prev) => !prev);
                      }}
                      className="reloadIconStyle"
                    />
                  </div>
                </div>
              </div>

              <div className="table-real">
                <CustomTable
                  className="assign"
                  columns={setColumns()}
                  rows={setupRows(reportList)}
                  isLoading={isLoading || isValidating}
                  rowKey="_id"
                  isViewable={false}
                  isDeleteable={false}
                  total={reportPagination?.total ?? reportList?.length ?? 0}
                  size={tableSize}
                  isPopup={true}
                  onChange={async (
                    { current, pageSize },
                    _filter,
                    { order, columnKey } = {}
                  ) => {
                    const params = {
                      page: current,
                      perPage: pageSize,
                      filters: {},
                    };

                    if (order && columnKey) {
                      params.sortBy = columnKey;
                      params.orderBy = order;
                    } else {
                      params.orderBy = "";
                      params.sortBy = "";
                    }

                    const keys = Object.keys(_filter);
                    keys.forEach((key) => {
                      if (_filter[key]) {
                        if (key === "title") {
                          params.filters[key] = _filter[key][0];
                        } else if (key === "status") {
                          params.filters[key] = _filter[key]?.join(",");
                        } else if (key === "activate") {
                          params.filters[key] = _filter[key]?.join(",");
                        }
                      }
                    });

                    if (keys.length === 0 || Object.values(_filter).every((v) => !v || v.length === 0)) {
                      params.filters = {
                        title: "",
                        status: "",
                        activate: "",
                      };
                    }

                    setFilters((prev) => ({
                      ...prev,
                      ...params,
                    }));

                    getReportList();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Fullscreen>
      <Drawer
        open={viewReportModal}
        title="View Report"
        width={640}
        height={1900}
        onClose={() => setViewReportModal(undefined)}
      >
        <ViewReport
          handleUpdateComplete={handleUpdateComplete}
          reportId={viewReportModal}
          isEditMode={false}
        />
      </Drawer>
    </CommonLayout>
  );
};

export default Report;
