import './Notifications.css'
import { useEffect, useState } from "react";
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Pagination, Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "components/layout/Layout";
import { setPage } from "actions/app";
import session from 'utils/session';
import { getAllNotifications, getMerchantNotifications, getBasicNotifications, markAllRead, markSingleRead } from 'actions/notifications';
import moment from 'moment';
import { getAllNotificationsWithoutPage, getMerchantNotificationsWithoutPage, getBasicNotificationsWithoutPage } from '../../actions/notifications';
import { Link } from 'react-router-dom';

const Notifications = () => {
    const dispatch = useDispatch()
    const [loadingState, setLoadingState] = useState(false)
    const [adminPage, setAdminPage] = useState(0)
    const [merchantPage, setMerchantPage] = useState(0)
    const [basicPage, setBasicPage] = useState(0)

    const notifications = useSelector(state => state.notifications.notifications)
    const merchantNotifications = useSelector(state => state.notifications.merchantNotifications)
    const basicNotifications = useSelector(state => state.notifications.basicNotifications)
    const adminPaginate = useSelector(state => state.notifications.adminPaginate)
    const merchantPaginate = useSelector(state => state.notifications.merchantPaginate)
    const basicPaginate = useSelector(state => state.notifications.basicPaginate)

    const loggedInAdmin = session && session.role.toLowerCase() === 'admin' && session.userId
    useEffect(() => {
        dispatch(setPage('Notifications'))
    }, [dispatch])

    useEffect(() => {
        if (session && session.role.toLowerCase() === 'merchant') {
            const merchantCall = async () => {
                setLoadingState(true)
                const res = await dispatch(getMerchantNotifications(session.userId, merchantPage))
                if (res) {
                    setLoadingState(false)
                }
            }
            merchantCall()
        } else if (session && session.role.toLowerCase() === 'basic') {
            const basicCall = async () => {
                setLoadingState(true)
                const res = await dispatch(getBasicNotifications(session.userId, basicPage))
                if (res) {
                    setLoadingState(false)
                }
            }
            basicCall()
        } else {
            const adminCall = async () => {
                setLoadingState(true)
                const res = await dispatch(getAllNotifications(session.userId, adminPage))
                if (res) {
                    setLoadingState(false)
                }
            }
            adminCall()
        }
    }, [dispatch, adminPage, merchantPage, basicPage])

    const handleSingleRead = (val) => {
        Modal.confirm({
            title: 'Do you want to mark this notification as read?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => singleReadYes(val),
            okType: 'primary',
        });
    }

    const singleReadYes = async (val) => {
        await dispatch(markSingleRead(val))
        await dispatch(getAllNotifications(loggedInAdmin, adminPage))
        await dispatch(getAllNotificationsWithoutPage(loggedInAdmin))
    }

    const handleAllRead = (val) => {
        Modal.confirm({
            title: 'Do you want to mark all notification as read?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => allReadYes(),
            okType: 'primary',
        });
    }

    const allReadYes = async () => {
        await dispatch(markAllRead())
        await dispatch(getAllNotifications(loggedInAdmin, adminPage))
        await dispatch(getAllNotificationsWithoutPage(loggedInAdmin))
    }

    const handleSingleReadMerchant = (val) => {
        Modal.confirm({
            title: 'Do you want to mark this notification as read?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => singleReadMerchantYes(val),
            okType: 'primary',
        });
    }

    const singleReadMerchantYes = async (val) => {
        await dispatch(markSingleRead(val))
        await dispatch(getMerchantNotifications(session.userId, merchantPage))
        await dispatch(getMerchantNotificationsWithoutPage(session.userId))
    }

    const handleAllReadMerchant = (val) => {
        Modal.confirm({
            title: 'Do you want to mark all notification as read?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => allReadMerchantYes(),
            okType: 'primary',
        });
    }

    const allReadMerchantYes = async () => {
        await dispatch(markAllRead())
        await dispatch(getMerchantNotifications(session.userId, merchantPage))
        await dispatch(getMerchantNotificationsWithoutPage(session.userId))
    }

    const handleLoadMoreAdmin = (page) => {
        setAdminPage(page - 1)
    }

    const handleLoadMoreMerchant = (page) => {
        setMerchantPage(page - 1)
    }
    const handleSingleReadBasic = (val) => {
        Modal.confirm({
            title: 'Do you want to mark this notification as read?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => singleReadBasicYes(val),
            okType: 'primary',
        });
    }

    const singleReadBasicYes = async (val) => {
        await dispatch(markSingleRead(val))
        await dispatch(getBasicNotifications(session.userId, basicPage))
        await dispatch(getBasicNotificationsWithoutPage(session.userId))
    }

    const handleAllReadBasic = (val) => {
        Modal.confirm({
            title: 'Do you want to mark all notification as read?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => allReadBasicYes(),
            okType: 'primary',
        });
    }

    const allReadBasicYes = async () => {
        await dispatch(markAllRead())
        await dispatch(getBasicNotifications(session.userId, basicPage))
        await dispatch(getBasicNotificationsWithoutPage(session.userId))
    }

    const handleLoadMoreBasic = (page) => {
        setBasicPage(page - 1)
    }
    return (
        <CommonLayout className="content-mr" breadCrumbText='Notifications'>


            {
                !loadingState ?
                    <div className="notificationCardBody">

                        <div className="notificationFrame">

                            <div className="card">

                                <div className="cardHead">
                                    <div className="headTitleWrapper">
                                        <div className="headTitle">
                                            <span className='headTitleText'>Recent Updates</span>
                                        </div>

                                        <Button
                                            onClick={session && session.role.toLowerCase() === 'admin' ? handleAllRead : session.role.toLowerCase() === 'merchant' ? handleAllReadMerchant : handleAllReadBasic}
                                            icon={<CheckCircleOutlined />} >Mark All Read</Button>

                                    </div>
                                </div>

                                <div className="cardBody">
                                    <div className="listWrapper">
                                        {
                                            session && session.role.toLowerCase() === 'merchant' && merchantNotifications && merchantNotifications.length > 0 &&
                                            merchantNotifications.map((item) => (
                                                <div className="singlelistWrapper" key={item._id}>
                                                    <div className="singleListFrame">
                                                        <div className="leftDiv">
                                                            <span className={item.markedReadByMerchant ? 'notificationReadTitleText' : 'notificationTitleText'}>{item.message}</span>
                                                            <span className='subText'><strong>From</strong> :{item?.from?.fullName}    <strong>To</strong> :{item?.to?.fullName}</span>
                                                        </div>

                                                        <div className="rightDiv">
                                                            <span className='dateText'>{moment(item.createdAt).format('ll')}</span>
                                                            {!item.markedReadByMerchant ? <Button className='notificationCheckBtn' onClick={() => handleSingleReadMerchant(item._id)}>
                                                                <CheckCircleOutlined />
                                                            </Button> : <div className='w-44'></div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        {
                                            session && session.role.toLowerCase() === 'basic' && basicNotifications && basicNotifications.length > 0 &&
                                            basicNotifications.map((item) => (
                                                <div className="singlelistWrapper" key={item._id}>
                                                    <div className="singleListFrame">
                                                        <div className="leftDiv">
                                                            <span className={item.markedReadByBasic ? 'notificationReadTitleText' : 'notificationTitleText'}>{item.message}</span>
                                                            <span className='subText'><strong>From</strong> :{item?.from?.fullName}    <strong>To</strong> :{item?.to?.fullName}</span>
                                                        </div>

                                                        <div className="rightDiv">
                                                            <span className='dateText'>{moment(item.createdAt).format('ll')}</span>
                                                            {!item.markedReadByBasic ? <Button className='notificationCheckBtn' onClick={() => handleSingleReadBasic(item._id)}>
                                                                <CheckCircleOutlined />
                                                            </Button> : <div className='w-44'></div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                        {
                                            session && session.role.toLowerCase() === 'admin' && notifications.length > 0 &&
                                            notifications.map((item, i) => (
                                                <div className="singlelistWrapper" key={item._id}>
                                                    <div className="singleListFrame">
                                                        <div className="leftDiv">
                                                            <Link to={item.url || ''}>
                                                                <span className={item.markedReadByAdmin ? 'notificationReadTitleText' : 'notificationTitleText'}>{item.message}</span>
                                                            </Link>
                                                            <span className='subText'><strong>From</strong> :{item?.from?.fullName}    <strong>To</strong> :{item?.to?.fullName}</span>
                                                        </div>

                                                        <div className="rightDiv">
                                                            <span className='dateText'>{moment(item.createdAt).format('ll')}</span>
                                                            {!item.markedReadByAdmin ? <Button className='notificationCheckBtn' onClick={() => handleSingleRead(item._id)}>
                                                                <CheckCircleOutlined />
                                                            </Button> : <div className='w-44'></div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>

                            </div>

                        </div>

                        {
                            session && session.role.toLowerCase() === 'admin' &&
                            <div className="buttonsFrame">
                                <Pagination
                                    pageSize={8}
                                    showTotal={total => `Total ${total} items`}
                                    current={adminPage + 1}
                                    total={adminPaginate && adminPaginate.total}
                                    onChange={handleLoadMoreAdmin} />
                            </div>
                        }

                        {
                            session && session.role.toLowerCase() === 'merchant' &&
                            <div className="buttonsFrame">
                                <Pagination
                                    pageSize={8}
                                    showTotal={total => `Total ${total} items`}
                                    current={merchantPage + 1}
                                    total={merchantPaginate && merchantPaginate.total}
                                    onChange={handleLoadMoreMerchant} />
                            </div>
                        }
                        {
                            session && session.role.toLowerCase() === 'basic' &&
                            <div className="buttonsFrame">
                                <Pagination
                                    pageSize={8}
                                    showTotal={total => `Total ${total} items`}
                                    current={basicPage + 1}
                                    total={basicPaginate && basicPaginate.total}
                                    onChange={handleLoadMoreBasic} />
                            </div>
                        }
                    </div>
                    :

                    <div className="errorTipDiv">
                        <Spin size='middle' tip='Loading...' />
                    </div>
            }
        </CommonLayout>
    )
}

export default Notifications;