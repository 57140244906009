import * as ActionTypes from "./action-types";

export const getSKU = (record) => (
  {  
    type: ActionTypes.GET_ALL_SKU,      
    payload: {
      request: {
        url:  `${process.env.REACT_APP_API_URL}/api/sku?perPage=10&page=${record}`,
        method: "get",
      },
    },
}
)

export const getSingleSKU = (id) => (
  {  
    type: ActionTypes.GET_SINGLE_SKU,      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/sku/${id}`,        
        method: "get",
      },
    },
}
)

export const getBrand = () => (
    {  
      type: ActionTypes.GET_ALL_BRANDS,      
      payload: {
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/brand`,        
          method: "get",
        },
      },
  }
  )

  export const getSingleBrand = (id) => (
    {  
      type: ActionTypes.GET_SINGLE_BRAND,      
      payload: {
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/brand/${id}`,        
          method: "get",
        },
      },
  }
  )


export const getSearchSKU = (skuId='',brandDesc='',manuDesc='') => (
  {  
    type: ActionTypes.GET_ALL_SEARCH_SKU,      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/sku?page=0&perPage=10&sku_id=${skuId}&sku_brand_desc=${brandDesc}&sku_manufacturer_desc=${manuDesc}`,        
        method: "get",
      },
    },
}
)

export const getSkuExportData = (data) => ({
  type: ActionTypes.GET_SKU_EXPORT_DATA,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/sku/download`,
      method: "post",
      data
    },
  },
});


export const getZone = () => ({
  type: ActionTypes.GET_ALL_BRANDS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/sku/zone`,
      method: "get",
    },
  },
});


export const getDepartment = () => ({
  type: ActionTypes.GET_ALL_BRANDS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/sku/department`,
      method: "get",
    },
  },
});

export const getClass = () => ({
  type: ActionTypes.GET_ALL_BRANDS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/sku/class`,
      method: "get",
    },
  },
});


export const getSubclass = () => ({
  type: ActionTypes.GET_ALL_BRANDS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/sku/subclass`,
      method: "get",
    },
  },
});