import "./../elements/elements.css";
import React from "react";
import { useState } from "react";
import { Input } from "antd";

const InputElement = (props) => {
    const [value, setValue] = useState(props.ans ? props.ans : "");
    const [regexMatched, setRegexMatched] = useState(undefined)
    const { details, isRegexRequired, regex } = props;
    const handleChange = (e) => {
        setValue(e.target.value);
        if (isRegexRequired || details?.isRegexRequired) {
            setRegexMatched(!new RegExp(details.regex).test(e.target.value));
        }
        props.onChange(e.target.value, props.id)

    }

    return (
        <div className="input-frame">
            <div className="d-label">
                {props.designate && props.designate === "required" ? (
                    <span className="spanReq">*</span>
                ) : (
                    ""
                )}{" "}
                <label className="input-label">{props.label}</label>
            </div>
            {props.action === "create" && (
                <>
                    <Input
                        className="input-text-field"
                        onChange={handleChange}
                        value={value}
                        placeholder="Enter here"
                    />
                    <span className="error-label">{props.error}</span>
                    {(isRegexRequired && regex) ||
                        (details?.isRegexRequired && details?.regex && (
                            <span
                                className={`${value && regexMatched
                                    ? "error"
                                    : value && !regexMatched
                                        ? "success"
                                        : "info"
                                    }-label-info label-info`}
                            >
                                {value && regexMatched
                                    ? `Value not matching with the regex ${details?.regex}`
                                    : value && !regexMatched
                                        ? `Matched with the regex ${details?.regex}`
                                        : `Should match with the regex ${details?.regex}`}
                            </span>
                        ))}
                </>
            )}
            {props.action === "edit" && (
                <>
                    <Input
                        className="input-text-field"
                        onChange={handleChange}
                        value={value}
                        placeholder="Enter here"
                    />
                    <span className="error-label">{props.error}</span>
                </>
            )}
            {props.action === "view" && (
                <Input
                    className="input-text-field"
                    value={props.ans}
                    placeholder="Enter here"
                    disabled
                />
            )}
        </div>
    )
}
export default InputElement;