import logger from "common/logger";
import { HttpService } from "services/network/http.service";

export default class AdSetService extends HttpService {
  async addAdSet(payload: any, timezone: any) {
    try {
      const response = await this.post(`/api/adset?timezone=${timezone}`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async updateAdSet(id: string, payload: any, timezone: any) {
    try {
      const response = await this.put(`/api/adset/${id}?timezone=${timezone}`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async getThemesByType(type: string) {
    try {
      const response = await this.get(`/api/master?type=${type}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async getPromoIds() {
    try {
      const response = await this.get(`/api/promoId`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
}