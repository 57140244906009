import axios from "axios";
import { multiClientMiddleware } from "redux-axios-middleware";
import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "reducers";
import session from "utils/session";
import { UNAUTHORIZED_ACTION_TYPES } from "utils/constants";
import { errorMsg, successMsg } from "actions/login";

export const axiosClients = {
  default: {
    client: axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      responseType: "json",
    }),
  },
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      multiClientMiddleware(axiosClients, {
        getRequestOptions: (action) => {
          action.payload.request.headers = action.payload.request.headers || {};
          action.payload.request.headers["Access-Control-Allow-Origin"] = "*";

          if (UNAUTHORIZED_ACTION_TYPES.indexOf(action.type) === -1) {
            action.payload.request.headers[
              "Authorization"
            ] = `Bearer ${session.token}`;
          }
          return action;
        },
        onComplete: ({ action }) => {
          if (
            action.payload &&
            action.payload.status &&
            action.payload.data?.message
          ) {
            store.dispatch(
              successMsg({
                data: action.payload.data.message,
              })
            );
          }
          if (action.error && action.error.response?.data?.message) {
            // console.log(action.error?.response?.data?.message, "store error");
            store.dispatch(
              errorMsg({
                data: action.error.response.data.message,
              })
            );
          }
        },
      })
    )
  )
);

export default store;
