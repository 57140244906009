import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Checkbox, Select, Spin } from "antd";
import "./../createPromoNew.css";
import { useDispatch } from "react-redux";
import { getSKUBySearch } from "actions/shelf";
import PromoService from "services/promo-service/promo-service";

const { Option } = Select;

const CheckboxElement = (props) => {
    const [value, setValue] = useState(props.ans ? props.ans : []);
    const [skuChildren, setSkuChildren] = useState([]);
    const dispatch = useDispatch();
    const [timer, setTimer] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [dropdownOptions, setDropdownOptions] = useState({});
    const promoService = useMemo(() => new PromoService(), []);
    const [sku, setSku] = useState([]);
    const [selectValues, setSelectValues] = useState({});

    const fetchOptions = useCallback(async (optionValue) => {
        const listType = optionValue === 'Class' ? 'class' : optionValue === 'Sub-class' ? 'subclass' : optionValue;
        if (!dropdownOptions[optionValue]) {
            try {
                const response = await promoService.getSKUListData(listType);
                const data = await response.data?.skuList;
                setDropdownOptions((prevOptions) => ({
                    ...prevOptions,
                    [optionValue]: data,
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        }
    }, [dropdownOptions, promoService]);

    const handleCheckboxChange = useCallback((checkedValues) => {
        const uncheckedValues = value.filter(val => !checkedValues.includes(val));

        uncheckedValues.forEach(unchecked => {
            if (unchecked === "SKU") {
                setSku([]);
                props.handleSubOptionChange(props.id, "SKU", [], "SKU");
            } else {
                setSelectValues(prevValues => {
                    const newValues = { ...prevValues };
                    delete newValues[unchecked];
                    props.handleSubOptionChange(props.id, unchecked, '', unchecked);
                    return newValues;
                });
            }
        });

        setValue(checkedValues);
        props.onChange(checkedValues, props.id);

        checkedValues.forEach((option) => {
            if (option !== "SKU") {
                fetchOptions(option);
            }
        });
    }, [fetchOptions, props, value]);

    useEffect(() => {
        value.forEach((option) => {
            if (option !== "SKU") {
                fetchOptions(option);
            }
        });
    }, [value, fetchOptions]);

    useEffect(() => {
        if (Array.isArray(props.ans)) {
            const updatedSelectValues = {};
            props.ans.forEach((ansItem) => {
                const option = props.options.find(item => item.optionText === ansItem);
                if (option && option.subOptions) {
                    option.subOptions.forEach(subOption => {
                        if (subOption.subOptionValue) {
                            if (option.optionText === 'SKU') {
                                setSku(subOption.subOptionValue);
                            } else {
                                updatedSelectValues[ansItem] = subOption.subOptionValue;
                            }
                        }
                    });
                }
            });
            setSelectValues(updatedSelectValues);
        }
    }, [props]);

    const onChange = (value, subOptionLabel) => {
        setSelectValues(prevValues => ({
            ...prevValues,
            [subOptionLabel]: value,
        }));
        props.handleSubOptionChange(props.id, subOptionLabel, value, subOptionLabel);
    };

    const handleSearchSkuKeys = async (value) => {
        clearTimeout(timer);
        if (value.length >= 3) {
            const newTimer = setTimeout(async () => {
                setFetching(true);
                const res = await dispatch(getSKUBySearch(value));
                if (!res.error) {
                    const dataSku = res.payload.data.data;
                    const skuData = dataSku.map((item) => (
                        <Option key={item.sku_key}>{item.sku_key}</Option>
                    ));
                    setSkuChildren(skuData);
                    setFetching(false);
                } else {
                    setFetching(false);
                }
            }, 700);
            setTimer(newTimer);
        }
    };

    const handleSku = (value) => {
        setSku(value);
        props.handleSubOptionChange(props.id, 'SKU', value, 'SKU');
    };

    const handlePasteSku = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('Text');
    
        const skuArray = pastedData.split(/[, ]+/)
            .map(sku => sku.trim())
            .filter(sku => sku);
    
        const uniqueSkus = [...new Set([...sku, ...skuArray])];
        handleSku(uniqueSkus);
    };
    

    const plainOption = props.options?.map((item) => item.optionText) || [];

    return (
        <>
            <div className="radio-frame" style={{ minHeight: '60px' }} key={props._id}>
                <div className="d-label">
                    {props.designate && props.designate === 'required' ? <span className="spanReq">*</span> : ''}
                    <label className="input-label">{props.label}</label>
                </div>
                {props.action === "create" && (
                    <>
                        <Checkbox.Group options={plainOption} value={value || ""} onChange={handleCheckboxChange} />
                        <span className="error-label">{props.error}</span>
                    </>
                )}
                {props.action === "view" && (
                    <Checkbox.Group options={plainOption} value={value || ""} disabled />
                )}
                {props.action === "edit" && (
                    <Checkbox.Group options={plainOption} value={value || ""} onChange={handleCheckboxChange} />
                )}
            </div>
            {value.length > 0 && (
                <div className="container">
                    {value.map((option, index) => (
                        <React.Fragment key={index}>
                            {index % 2 === 0 && index !== 0 && <div className="line-break"></div>}
                            <div className={`input-frame width-50 option-item ${index % 2 !== 0 ? 'has-margin' : ''}`}>
                                <label className="input-label">
                                    {option}
                                </label>
                                <div className="select-dropdown">
                                    {option !== 'SKU' && (
                                        <Select
                                            className="select-dropdown"
                                            onChange={(value) => onChange(value, option)}
                                            value={selectValues[option] || undefined}
                                            placeholder="Select"
                                            mode={fetching ? "multiple" : "tags"}
                                            tokenSeparators={[","]} maxTagCount={1}
                                            disabled={props.action === 'view'} showSearch filterOption={(input, option) => {
                                                return (option?.children?.toLowerCase() ?? "").includes(
                                                    input?.toLowerCase()
                                                );
                                            }}
                                        >
                                            {dropdownOptions[option]?.map((item) => {
                                                if (option?.toLowerCase() === 'department') {
                                                    return <Option key={item.department_id}>{item.department_id + " - " + item.department_desc}</Option>;
                                                }
                                                if (option?.toLowerCase() === 'class') {
                                                    return <Option key={item.class_id}>{item.class_id + " - " + item.class_desc}</Option>;
                                                }
                                                if (option === 'Sub-class' || option?.toLowerCase() === 'subclass') {
                                                    return <Option key={item.sub_class_id}>{item.sub_class_id + " - " + item.sub_class_desc}</Option>;
                                                }
                                                return null;
                                            })}
                                        </Select>
                                    )}
                                    {option === 'SKU' && (
                                        <Select
                                            showSearch
                                            value={sku}
                                            placeholder={"Search and select SKU Keys"}
                                            className="select-container w-100"
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={handleSearchSkuKeys}
                                            onChange={handleSku}
                                            disabled={props.action === 'view'}
                                            notFoundContent={fetching ? <Spin size="small" /> : null}
                                            mode="multiple"
                                            tokenSeparators={[",", " "]}
                                            maxTagCount={2}
                                            maxTagTextLength={6}
                                            maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
                                            onPaste={handlePasteSku}
                                        >
                                            {skuChildren}
                                        </Select>
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </>
    );
};

export default CheckboxElement;
