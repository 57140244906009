import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const DateEle = ({ question, index, questionType, questionHeader, options, designate, action, handleDateChange, answer, record }) => {
  return (
    <div
      className="datePickerDiv"
    >

      {
        action === 'create' && <RangePicker
          format="MM/DD/YYYY"
          onChange={(value, dateString) => handleDateChange(value, dateString, index, record)}
        />
      }

      {
        action === 'view' && <RangePicker
          format="MM/DD/YYYY"
          disabled
          value={(answer && [moment(answer[0]), moment(answer[1])]) || []}
          className="select-date"
        />
      }

    </div>
  )
}

export default DateEle;