import './Layout.css'
import { Layout, } from 'antd';
import Topbar from 'components/topbar/Topbar';
import Sidebar from 'components/sidebar/Sidebar';
import AppHolder from './AppHolder';
import LayoutContentWrapper from './LayoutContentWrapper';
import LayoutContentStyle from './LayoutContentStyle';
const { Content } = Layout;

const CommonLayout = (props) => {
  return (
    <AppHolder>
      <Layout style={{ height: '100vh' }}>
        <Sidebar />
        <Layout style={{ flexDirection: 'col', overflowX: 'auto' }}>
          <Topbar breadCrumbText={props.breadCrumbText} backButton={props.backButton} id={props.id} backUrl={props.backUrl} />
          <Layout
            className="isoContentMainLayout"
            style={{
              height: '100vh'
            }}>
            <Content
              className={`isomorphicContent ${props.className && props.className}`}
            >
              <LayoutContentWrapper>
                <LayoutContentStyle>
                  {props.children}
                </LayoutContentStyle>
              </LayoutContentWrapper>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </AppHolder>
  )
}

export default CommonLayout;