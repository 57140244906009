import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/users/action-types'
import UsersStateManager        from './state-manager';

const INITIAL_STATE = {
    users: [],
    usersBackup: [],
    searchedUsers:[],
    teamUsers: [],
    unassignUsers: [],
    unassignUsersTotal: 0
};

export default function UsersStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_LOCAL_USER_JSON:
                return { ...state, users: [ ...action.users ] };
        case ActionTypes.UPDATE_LOCAL_USER_DATA:
                return UsersStateManager.getFilteredUsers(state,action);
        case S(ActionTypes.GET_ALL_USERS):
                return UsersStateManager.getAllUsers(state,action);
        case S(ActionTypes.GET_UNASSIGN_USERS):
                return UsersStateManager.getUnassignUsers(state,action);        
        case S(ActionTypes.GET_ALL_TEAM_USERS):
                return UsersStateManager.getAllUsers(state,action);        
        case S(ActionTypes.CREATE_USER):
                return UsersStateManager.createUser(state,action);
        case S(ActionTypes.UPDATE_USER):
                return UsersStateManager.updateUser(state,action);
        case S(ActionTypes.DELETE_USER):
                return UsersStateManager.deleteUser(state,action);
        case S(ActionTypes.GET_USER_BY_NAME):
                return UsersStateManager.getUserByName(state,action);
        case S(ActionTypes.UPDATE_USER_STATUS):
                return UsersStateManager.updateUserStatus(state,action);
        case (ActionTypes.UPDATE_USER_BY_NAME):
                return UsersStateManager.updateUserBySearch(state,action);
        // case S(ActionTypes.GET_USER_BY_ID):
        //         return UsersStateManager.getUserById(state,action);
    
        default:
            return state;
    }
}