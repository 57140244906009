import './Map.css'
import { setPage } from "actions/app";
import CommonLayout from "components/layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, Spin, Tag } from 'antd';
import Fullscreen from "fullscreen-react";
import {  ColumnHeightOutlined, DeleteOutlined, ExclamationCircleOutlined, FullscreenOutlined, ReloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import CustomTable from 'components/common/table/Table';
import {  MAP_STATUS_LIST_COLUMNS } from 'utils/column';
import { useNavigate, useParams } from 'react-router';
import { SECONDS, TextMessage } from 'utils/constants';
import { deleteMapById, getMapsByStatus  } from 'actions/map'
import session from 'utils/session';

const MapStatusTable = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const mapByStatus = useSelector(state => state.maps.mapByStatus)
    const navigate = useNavigate()
    const [isEnter, setIsEnter] = useState(false);

    const [loadingState,setLoadingState] = useState(false)
    const [reloadingState,setReLoadingState] = useState(false)
    const [showDeleteAlert,setShowDeleteAlert] = useState(false)

    const [tableSize,setTableSize] = useState('default')
    const [tableClicked,setTableClicked] = useState(0)


    useEffect(() => {

      if(showDeleteAlert){
        setTimeout(() => {
          setShowDeleteAlert(false)
        }, SECONDS);
      }
      
    },[dispatch,showDeleteAlert])

    useEffect(() => {
        dispatch(setPage('MAP Status'))
    },[dispatch])

    useEffect(() => {
      const getCall = async() =>{
        setLoadingState(true)
        const res = await dispatch(getMapsByStatus('status',id))
        if(res){
          setLoadingState(false)
        }
      }
      getCall()
    },[dispatch,id])

    const setupRows = (maps) => {
        return maps?.map((o) => {
            const mapType = o.mapType ? o.mapType.toLowerCase() === 'sku' ? o.mapType.toUpperCase() :  o.mapType.charAt(0).toUpperCase() + o.mapType.slice(1) : ''
            const status = o.status ?  o.status.charAt(0).toUpperCase() + o.status.slice(1) : ''
            const mapCreatedBy = (o.createdBy && o.createdBy.userId === session.userId) ? true : false
            return {
                "_id": o._id,
                "mapType": mapType,
                "status": status.toLowerCase() === 'pending' ? <Tag color={"red"}>{status}</Tag> : status.toLowerCase() === 'approved' ? <Tag className="status-symbol-approved">{status}</Tag> :  <Tag className='status-symbol-rejected'>{status}</Tag>,
                "isStatus": status,
                "isMapType": mapType,
                "createdDate": o?.createdAt || '',
                "mapCreatedBy": mapCreatedBy
            }
        }) 
    }

    const viewMap = (record) => {
      navigate(`/map/view/${record._id}`)
    }

    const deleteMap = (value) => {
          Modal.confirm({
            title: TextMessage.CONFIRMATION_TEXT,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () =>deleteMapYes(value),
            okType:'primary',
        });
    }

    const deleteMapYes = async (value) => {
        await dispatch(deleteMapById(value._id))
        await dispatch(getMapsByStatus('status',id))
        window.scrollTo(0, 0);
        setShowDeleteAlert(true)
    }

    const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
    }

    const handleTableHeight = () => {
      if(tableClicked === 2){
        setTableClicked(0)
        setTableSize('default')
      }
      if(tableClicked === 0){
        setTableClicked(tableClicked + 1)
        setTableSize('small')
      }
      if(tableClicked === 1){
        setTableClicked(tableClicked + 1)
        setTableSize('middle')
      }
    }

    const handleReload = async() => {
      setReLoadingState(true)
      const res = await dispatch(getMapsByStatus('status',id))
      if(res){
        setReLoadingState(false)
      }
    }


    return(
        <CommonLayout  className="no-content-mr" breadCrumbText='MAP' backUrl={'/map'} backButton={true} 
        id={id?.charAt(0).toUpperCase() + id?.slice(1)}>
            {
              (loadingState || reloadingState) ? 
                <div className="errorTipDiv">
              <Spin size='middle' tip='Loading...'/>
            </div>
              : <>
            <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
              <div className="mapTableWrapper">
                  <div className="map-table-topbar">
                    <div className="mapFilterGrpWrapper">
                        <div className="mapsettingGrp">
                            <ReloadOutlined 
                            onClick={handleReload} 
                            className='reloadIconStyle'/>
                            <ColumnHeightOutlined className='reloadIconStyle' 
                            onClick={handleTableHeight}
                            />
                            <FullscreenOutlined 
                            onClick={() => {setIsEnter(true)}} 
                            className='reloadIconStyle'/>
                        </div>
                    </div>
                </div>

                {/* table */}

                <div className='w-100'>
                    <CustomTable
                        columns={MAP_STATUS_LIST_COLUMNS}
                        rows={setupRows(mapByStatus)}
                        isDeleteable={true}
                        rowKey="_id"
                        isViewable={true}
                        onDelete={deleteMap}
                        total={mapByStatus?.length}
                        onView={viewMap}
                        size={tableSize}
                    />
                </div>

            </div>
            </Fullscreen>

            {
              showDeleteAlert && 
                        <Alert message={TextMessage.MAP_DELETE_TEXT} type="error" 
                        showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined/>} className='alertStyle'
                        />
            }
              </>
            }
        </CommonLayout>
    )
}

export default MapStatusTable;