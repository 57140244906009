export const LOGIN = "LOGIN";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const EMAIL_VALIDATOR = "EMAIL_VALIDATOR";
export const ERROR_MSG = "ERROR_MSG";
export const SUCCESS_MSG = "SUCCESS_MSG";
export const DISABLE_MSG = "DISABLE_MSG";
export const LOGOUT = "LOGOUT"

export const FCM_TOKEN = "FCM_TOKEN"
