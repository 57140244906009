export type ENVIRONMENT = {
  API_BASE_URL: string;
  APP_ENV?: string;
};

const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || "local";
const API_BASE_URL = process.env.REACT_APP_API_URL || "";

const environments: ENVIRONMENT = {
  API_BASE_URL,
  APP_ENV,
};

export default environments;
