import * as ActionTypes from './action-types';
import qs from "qs";


export const showHideUnassignButton = (flag, newSelectedRowKeys) => ({
    type: ActionTypes.SHOW_UNASSIGN_BUTTON,
    data: { flag, newSelectedRowKeys }
})

export const getAllAdsets = (params = {}) => {

    let url = `${process.env.REACT_APP_API_URL}/api/adset/`
    return {
        type: ActionTypes.GET_ALL_ADSETS,
        payload: {
            request: {
                url,
                method: "get",
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        }
    }
};

export const getAllEvents = (params = {}) => {

    let url = `${process.env.REACT_APP_API_URL}/api/events/`
    return {
        type: ActionTypes.GET_ALL_EVENTS,
        payload: {
            request: {
                url,
                method: "get",
                params: params,
                paramsSerializer: (params) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        }
    }
};

export const createAdset = (payload) => ({
    type: ActionTypes.CREATE_ADSET,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/adset`,
            method: "post",
            data: payload
        }
    }
})

export const updateAdset = (payload, id) => ({
    type: ActionTypes.UPDATE_ADSET,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/adset/${id}`,
            method: "put",
            data: payload
        }
    }
})

export const createEvent = (payload,timezone) => ({
    type: ActionTypes.CREATE_EVENT,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/events?timezone=${timezone}`,
            method: "post",
            data: payload
        }
    }
})

export const updateEvent = (payload, id,timezone) => ({
    type: ActionTypes.UPDATE_EVENT,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/events/${id}?timezone=${timezone}`,
            method: "put",
            data: payload
        }
    }
})

export const deleteEventById = (id) => ({
    type: ActionTypes.DELETE_EVENT,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/events/${id}`,
            method: "delete",
        }
    },
    meta: {
        "_id": id
    }
})

export const deleteAdsetById = (id) => ({
    type: ActionTypes.DELETE_ADSET,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/adset/${id}`,
            method: "delete",
        }
    },
    meta: {
        "_id": id
    }
})
