export default class UsersStateManager{
    static getAllUsers = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.users = data.users || [];
        state.usersBackup = data.users || [];

        return state;
    }

    static getFilteredUsers = (prevState, action) => {
        const state = { ...prevState };
        if (action.userType === 'all') {
            state.users = state.usersBackup;
        }else {
            state.users = state.usersBackup?.filter(item => item.role.toLowerCase() === action.userType);
        }

        return state;
    }

    static getUnassignUsers = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        const updatedUsers = data.users.map(user => ({
            ...user,
            label: user.fullName,
            value: user._id
          }));
        state.unassignUsers = updatedUsers || [];
        state.unassignUsersTotal = data?.pagination?.total;
        
        return state;
    }

    static getAllTeamUsers = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.teamUsers = data.users || [];

        return state;
    }

    static createUser = (prevState, action) => {
        const state = { ...prevState };

        state.users=[action.payload.data.data,...state.users];
       
        return state;
    } 

    static updateUser = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.users.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.users[index]={ ...state.users[index], ...newRecord };
          state.users = [ ...state.users ];
        }
  
        return state;
    }

    static updateUserStatus = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.users.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.users[index]={ ...state.users[index], ...newRecord };
          state.users = [ ...state.users ];
        }
  
        return state;
    }

    static deleteUser = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.users = state.users.filter((item) => item._id !== id)
        
        return state;
    }

    static getUserByName = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.users = data.users;

        return state;
    }

    static updateUserBySearch = (prevState, action) => {
        const state = { ...prevState };
        const  data  = action.users;
        state.searchedUsers = data;

        return state;
    }
}