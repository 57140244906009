import "./Table.css";
import React from "react";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PercentageOutlined,
  DiffOutlined,
  RetweetOutlined,
  DownloadOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Table, Button } from "antd";
import session from "utils/session";
const CustomTable = (props) => {
  const {
    total,
    isPopup = false,
    size = "default",
    isPromoView = false,
    onChange,
    isLoading,
    rowSelectionType,
    checkRowDeleatable,
    isRowCheckboxDisabled,
  } = props;
  const isAdmin =
    session && session.role && session.role.toLowerCase() === "admin";
  const prepareRowSelection = () => {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        props.onSelectRows && props.onSelectRows(selectedRowKeys, selectedRows);
      },
      getCheckboxProps: (record) => ({
        // disabled: isRowCheckboxDisabled(record)
        disabled:
          typeof isRowCheckboxDisabled === "function" &&
          isRowCheckboxDisabled(record),
      }),
    };
    return rowSelection;
  };

  const editFunction = (event, record, onEdit) => {
    event.stopPropagation();
    onEdit && onEdit(record);
  };
  const viewFunction = (event, record, onView) => {
    event.stopPropagation();
    onView && onView(record);
  };
  const deleteFunction = (event, record, onDelete) => {
    event.stopPropagation();
    onDelete && onDelete(record);
  };
  const reGenerateFunction = (event, record, onReGenerate) => {
    event.stopPropagation();
    onReGenerate && onReGenerate(record);
  };
  const exportFunction = (event, record, onExport) => {
    event.stopPropagation();
    onExport && onExport(record);
  };
  const cancelFunction = (event, record, onCancel) => {
    event.stopPropagation();
    if (onCancel) {
      onCancel(record);
    }
  };

  const prepareColumns = () => {
    let { columns } = props;
    const {
      isEditable,
      isDeleteable,
      isViewable,
      isViewablePromoDetails,
      isReGenerated,
      onView,
      onEdit,
      onCancel,
      isCancelable,
      onDelete,
      onViewPromoDetails,
      onReGenerate,
      isExportable,
      onExport,
    } = props;
    if (
      isEditable ||
      isDeleteable ||
      isViewable ||
      isViewablePromoDetails ||
      isCancelable ||
      isReGenerated ||
      isExportable
    ) {
      columns = [
        ...columns,
        {
          title: "Actions",
          dataIndex: "actions",
          render: (_, record, index) => {
            return (
              <div className="action-btns-container">
                {isReGenerated &&
                  record?.templateStatus?.toLowerCase() === `failed` && (
                    <Button
                      className="btn-style custom_button"
                      onClick={(event) => {
                        reGenerateFunction(event, record, onReGenerate);
                      }}
                    >
                      <RetweetOutlined />
                    </Button>
                  )}
                {isViewable && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => {
                      viewFunction(event, record, onView);
                    }}
                  >
                    {isPromoView ? <DiffOutlined /> : <EyeOutlined />}
                  </Button>
                )}
                {isViewablePromoDetails && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => {
                      viewFunction(event, record, onViewPromoDetails);
                    }}
                  >
                    <PercentageOutlined />
                  </Button>
                )}
                {isEditable && isAdmin && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => editFunction(event, record, onEdit)}
                    disabled={
                      record?.ticketStatusValue?.toLowerCase() === "resolved" ||
                      record?.ticketStatusValue?.toLowerCase() === "rejected" ||
                      record?.ticketStatusValue?.toLowerCase() === "resolved" ||
                      record?.ticketStatusValue?.toLowerCase() === "approved" ||
                      record?.ticketStatusValue?.toLowerCase() === "done"
                    }
                  >
                    <EditOutlined />
                  </Button>
                )}
                {isEditable && !isAdmin && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => editFunction(event, record, onEdit)}
                  >
                    <EditOutlined />
                  </Button>
                )}
                {isCancelable && isAdmin && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => cancelFunction(event, record, onCancel)}
                  >
                    <StopOutlined />
                  </Button>
                )}

              {!isAdmin && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => cancelFunction(event, record, onCancel)}
                    disabled={
                      record?.role?.toLowerCase() !== "admin"
                    }
                  >
                    <StopOutlined />
                  </Button>
                )}
                {isDeleteable && isAdmin && record?.templateType !== "XML" && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => {
                      deleteFunction(event, record, onDelete);
                    }}
                    disabled={
                      checkRowDeleatable
                        ? checkRowDeleatable(record)
                        : record?.ticketStatusValue?.toLowerCase() ===
                        "rejected" ||
                        record?.ticketStatusValue?.toLowerCase() ===
                        "resolved" ||
                        record?.ticketStatusValue?.toLowerCase() ===
                        "approved" ||
                        record?.ticketStatusValue?.toLowerCase() === "done" ||
                        record?.role?.toLowerCase() === "admin" ||
                        record?.templateStatus?.toLowerCase() === "live" ||
                        record?.templateStatus?.toLowerCase() === "expired" ||
                        record?.templateStatus?.toLowerCase() !== `successful`
                    }
                  >
                    <DeleteOutlined />
                  </Button>
                )}
                {isDeleteable && !isAdmin && (
                  <Button
                    className="btn-style custom_button"
                    onClick={(event) => {
                      deleteFunction(event, record, onDelete);
                    }}
                    disabled={
                        record?.role?.toLowerCase() !== "admin"
                      }
                  >
                    <DeleteOutlined />
                  </Button>
                )}
              </div>
            );
          },
        },
      ];
    }
    return columns;
  };

  return (
    <div className="pag new-table" style={{ overflowX: "auto" }}>
      <Table
        className={props.className && props.className}
        columns={prepareColumns()}
        onChange={onChange}
        dataSource={props.rows}
        rowKey={props.rowKey || "key"}
        loading={isLoading}
        pagination={{
          showSizeChanger: props.total > 10 ? true : false,
          showQuickJumper:
            props?.showQuickJumper === undefined
              ? true
              : props?.showQuickJumper,
          showTotal: (total) => `Total ${total} items`,
          total: total,
          position: ["bottomLeft"],
          defaultPageSize: 10,
        }}
        rowSelection={
          rowSelectionType
            ? { type: rowSelectionType, ...prepareRowSelection() }
            : null
        }
        size={size}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (
                props.isViewable &&
                props.isViewablePromoDetails &&
                isPopup === false
              ) {
                props.onView(record);
                props.onViewPromoDetails(record);
              }
            },
          };
        }}
        rowClassName={(record, rowindex) => {
          if (props.isViewable && props.isViewablePromoDetails) {
            return "clickable";
          }
        }}
      />
    </div>
  );
};
export default CustomTable;
