import * as ActionTypes from "./action-types";

export const getProfile = () => (
  {  
    type: ActionTypes.GET_PROFILE,      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/auth/profile`,        
        method: "get",
      },
    },
}
)

export const editProfile = (data,id) => (
    {  
      type: ActionTypes.EDIT_PROFILE,
        
      payload: {
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/auth/profile/${id}`,
          data,        
          method: "patch",
        },
      },
  }
  )

  export const changePassword = (data) => (
    {  
      type: ActionTypes.CHANGE_PASSOWRD,
        
      payload: {
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/auth/changePassword`,
          data,        
          method: "patch",
        },
      },
  }
  )
