import { Button, Checkbox, Input, Select } from "antd";
import {
  CheckSquareOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const Option = Select;

const CheckBoxQuestion = ({
  question,
  handleDeleteOptionsCheckbox,
  handleOptionTextChangeCheckbox,
  loading,
  isCheckbox,
  handleAddOptionsCheckbox,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  handleQuestionDesignate,
  handleQuestionFlag,
}) => {
  return (
    <>
      {isCheckbox && question.questionType === "checkbox" && (
        <div className="formBuilderFrameTwoDynamic" key={index}>
          <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
            {question.questionType === "checkbox" &&
              question.optionValue?.map((op, j) => (
                <>
                  <div
                    className={"formBuilderFrameTwoDynamicOptionFrame"}
                    key={j}
                  >
                    <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                      <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                        <Input
                          addonBefore={<CheckSquareOutlined />}
                          placeholder={`Option ${j + 1}`}
                          suffix={
                            <DeleteOutlined
                              className="cp"
                              onClick={() =>
                                handleDeleteOptionsCheckbox(index, j)
                              }
                            />
                          }
                          value={op.optionText}
                          onChange={(e) =>
                            handleOptionTextChangeCheckbox(
                              e.target.value,
                              index,
                              j
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>

          {question.questionType === "checkbox" && (
            <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
              <Button
                icon={
                  <PlusOutlined
                    style={{ marginRight: "-10px", color: "#0F2A6F" }}
                  />
                }
                className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                onClick={() => handleAddOptionsCheckbox(index)}
              >
                Add Option
              </Button>
            </div>
          )}
        </div>
      )}

      {isCheckbox && question.questionType === "checkbox" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox onChange={(e) => handleQuestionDesignate(e, index)}>
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}

      {isCheckbox && question.questionType === "checkbox" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="fomBuilderSelectStyle">
            <Select
              placeholder="Select Question Flag"
              onChange={(value) => handleQuestionFlag(index, value)}
            >
              <Option value={"Start Date"}>Start Date</Option>
              <Option value={"List Price"}>List Price</Option>
              <Option value={"Map Price"}>MAP Price</Option>
              <Option value={"Map Hold Duration"}>MAP Hold Duration</Option>
              <Option value={"Days Of Inventory"}>Days Of Inventory</Option>
              <Option value={"Pricing Groups"}>Pricing Groups</Option>
            </Select>
          </div>
        </div>
      )}

      {isCheckbox && question.questionType === "checkbox" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          <span className="errorLabel">
            {question?.errors?.optionTextError !== null &&
              question?.errors?.optionTextError}
          </span>
          <div className="formBuilderActionsWrapper">
            <div className="copyDiv">
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={() => copyQuestion(index)}
              >
                <CopyOutlined />
              </Button>
            </div>
            <div className="delDiv" disabled={loading}>
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={() => deleteQuestion(index)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckBoxQuestion;
