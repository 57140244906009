import "./elements.css";
import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Tabs } from "antd";
const { TabPane } = Tabs;

const TabsEle = (props) => {
  const [key, setKey] = useState(props.ans ? props.ans : "");
  const handleTabCB = useCallback(
    (key) => {
      setKey(key);
      props.isPromoTypeQuestion
        ? props.handlePromoTypeQuestionChange(key, props.id, props.pqid)
        : props.onChange(key, props.id);
    },
    [props]
  );

  useEffect(() => {
    if (
      (props.action === "edit" || props.action === "create") &&
      !props.ans &&
      props.options.length
    ) {
      handleTabCB(props.options[0].optionText);
    }
  }, [props.ans, props.action, props.options]);

  const handleTab = (key) => {
    setKey(key);
    props.isPromoTypeQuestion
      ? props.handlePromoTypeQuestionChange(key, props.id, props.pqid)
      : props.onChange(key, props.id);
  };

  return (
    <div className="text-frame">
      <div className="d-label">
        {props.designate && props.designate === "required" ? (
          <span className="spanReq">*</span>
        ) : (
          ""
        )}
        <label className="input-label">{props.label}</label>
      </div>
      {props.action === "create" && (
        <>
          <Tabs activeKey={key} onChange={handleTab}>
            {props.options?.map((item) => (
              <TabPane tab={item.optionText} key={item.optionText}></TabPane>
              // <Option key={item.optionText}>{item.option}</Option>
            ))}
          </Tabs>
        </>
      )}
      {props.action === "view" && (
        <>
          <Tabs defaultActiveKey={key} onChange={handleTab} disabled>
            {props.options?.map((item) => (
              <TabPane
                tab={item.optionText}
                key={item.optionText}
                disabled
              ></TabPane>
              // <Option key={item.optionText}>{item.option}</Option>
            ))}
          </Tabs>
        </>
      )}
      {props.action === "edit" && (
        <>
          <Tabs defaultActiveKey={key} onChange={handleTab} disabled>
            {props.options?.map((item) => (
              <TabPane tab={item.optionText} key={item.optionText}></TabPane>
              // <Option key={item.optionText}>{item.option}</Option>
            ))}
          </Tabs>
        </>
      )}
    </div>
  );
};

export default TabsEle;
