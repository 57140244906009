import * as ActionTypes             from './action-types';

export const getAllNotifications = (adminId,page=0) => ({
    type: ActionTypes.GET_ALL_NOTIFICATIONS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${adminId}?page=${page}&perPage=8`,
            method: "get",
        }
    }
});

export const getMerchantNotifications = (merchantId,page=0) => ({
    type: ActionTypes.GET_ALL_MERCHANT_NOTIFICATIONS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${merchantId}?page=${page}&perPage=8`,
            method: "get",
        }
    }
});
export const getBasicNotifications = (basicUserId,page=0) => ({
    type: ActionTypes.GET_ALL_BASIC_NOTIFICATIONS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${basicUserId}?page=${page}&perPage=8`,
            method: "get",
        }
    }
});

export const markSingleRead = (notificationId) => ({
    type: ActionTypes.MARK_SINGLE_READ,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${notificationId}`,
            method: "patch",
        }
    }
});

export const markAllRead = () => ({
    type: ActionTypes.MARK_ALL_READ,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification`,
            method: "patch",
        }
    }
});

export const getAllNotificationsWithoutPage = (adminId) => ({
    type: ActionTypes.GET_ALL_NOTIFICATIONS_WITHOUT_PAGINATION,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${adminId}`,
            method: "get",
        }
    }
});

export const getMerchantNotificationsWithoutPage = (merchantId) => ({
    type: ActionTypes.GET_ALL_MERCHANT_NOTIFICATIONS_WITHOUT_PAGINATION,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${merchantId}`,
            method: "get",
        }
    }
});
export const getBasicNotificationsWithoutPage = (basicUserId) => ({
    type: ActionTypes.GET_ALL_BASIC_NOTIFICATIONS_WITHOUT_PAGINATION,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/notification/${basicUserId}`,
            method: "get",
        }
    }
});