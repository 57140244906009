/* eslint-disable no-unused-vars */
import "./ErrorReport.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Fullscreen from "fullscreen-react";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Tag,
  Select,
  Alert,
  Spin,
} from "antd";
import {
  CloseCircleOutlined,
  ColumnHeightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  LoadingOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import CommonLayout from "components/layout/Layout";
import CustomTable from "components/common/table/TableNew";
import TableSearchDropdown from "components/TableSearchDropdown";
import DateFilter from "components/DateFilter";
import { ERROR_TICKET_LIST_COLUMNS } from "utils/column";
import { Validator } from "utils/validations";
import {
  TextMessage,
  FIELD_REQUIRED,
  SECONDS,
  CircleTickIcon,
  DATE_FORMAT_DASH,
} from "utils/constants";
import session from "utils/session";
import { useNavigate } from "react-router-dom";
import { setPage } from "actions/app";
import {
  createErrorTickets,
  deleteErrorTicketById,
  getAllErrorTickets,
  getAllErrorTypeValues,
  resetSingleTicket,
  updateErrorTicketById,
  updateErrorTicketBySearch,
  getErrorReportExportData,
} from "actions/errorTicket";
import { getSKUBySearch } from "actions/shelf";
import { getPromoListNames } from "actions/promotions";
import { getAllUsers } from "actions/users/index";
import DropDownWithSearch from "./../promotion/elements/DropDownWithSearch";

const { Search, TextArea } = Input;
const { Option } = Select;

const ErrorReport = () => {
  const [isEnter, setIsEnter] = useState(false);
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [loadingState, setLoadingState] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentSuccessMsg, setCurrentSuccessMsg] = useState("");

  const dispatch = useDispatch();
  const errorTickets = useSelector((state) => state.errorTickets.errorTickets);
  const total = useSelector((state) => state.errorTickets.total);
  // const searchedErrorTickets = useSelector(
  //   (state) => state.errorTickets.searchedErrorTickets
  // );
  const errorTypeValues = useSelector(
    (state) => state.errorTickets.errorTypeValues
  );
  const promotionNames = useSelector((state) => state.promo.promoListNames);
  const users = useSelector((state) => state.users.users);
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRecord, setCurrentRecord] = useState("");

  const [title, setTitle] = useState("");
  const [sku, setSku] = useState([]);
  const [ticketDate, setTicketDate] = useState(
    moment().format(DATE_FORMAT_DASH)
  );
  const [errorType, setErrorType] = useState(null);
  const [priority, setPriority] = useState(null);
  const [textArea, setTextArea] = useState("");
  const [promoName, setPromoName] = useState(null);
  const [actionOwner, setActionOwner] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [ticketDateError, setTicketDateError] = useState("");
  const [errorTypeError, setErrorTypeError] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [promoNameError, setPromoNameError] = useState("");
  const [actionOwnerError, setActionOwnerError] = useState("");

  const [okBtnText, setOkBtnText] = useState("");
  const [tab, setTab] = useState("All Ticket");
  const [successTicketId, setSuccessTicketId] = useState("");
  const [successModalType, setSuccessModalType] = useState("failed");
  const [messageModal, setMessageModal] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [errorTypeValueOther, setErrorTypeValueOther] = useState("");
  const [errorTypeValueOtherError, setErrorTypeValueOtherError] = useState("");

  const [fetching, setFetching] = useState(false);
  const [dataSku, setDataSku] = useState([]);
  const [timer, setTimer] = useState(null);
  const [reloadingState] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // const [filters, setFilters] = useState({});

  useEffect(() => {
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
  }, [showDeleteAlert]);

  useEffect(() => {
    dispatch(setPage("Error Report"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetSingleTicket([]));
  }, [dispatch]);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      getData();
      await dispatch(getAllErrorTypeValues());
      await dispatch(getPromoListNames());
      await dispatch(getAllUsers());
    };
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (errorTickets) {
      setTickets(errorTickets);
    }
  }, [errorTickets]);

  useEffect(() => {
    if (searchedText === "") {
      dispatch(updateErrorTicketBySearch([]));
    }
  }, [searchedText, dispatch]);

  useEffect(() => {
    setTableLoading(true);
    getData({ page: 1 }, false);
    setCurrPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, searchValue]);
  const getData = async (params = {}, isLoading = true) => {
    if (searchValue) {
      params.search = searchValue;
    }
    if (tab !== "All Ticket") {
      params.filters = {
        ...params.filters,
        status: [...(params.filters?.status || []), tab],
      };
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const merchantId =
      session && session.role.toLowerCase() === "merchant" && session.fullName;
    if (merchantId) {
      params.filters = {
        ...params.filters,
        raisedBy: merchantId,
      };
    }

    const res = await dispatch(getAllErrorTickets(params));
    if (res) {
      setLoadingState(false);
      setTableLoading(false);
    }
  };

  const setupRows = (errorTickets) => {
    return errorTickets?.map((o) => {
      return {
        ticketNumber: o.ticketNumber || "",
        _id: o._id,
        title: o.title,
        promoName: o?.promoName || null,
        raisedBy: o.raisedBy,
        status:
          o.status?.toLowerCase() === "open" ? (
            <Tag color={"red"}>Open</Tag>
          ) : (
            <Tag color={"green"}>Resolved</Tag>
          ),
        date: o?.date ? moment(o.date).utc().format(DATE_FORMAT_DASH) : "-",
        priority:
          o.priority?.toLowerCase() === "high" ? (
            <span className="highClr">High</span>
          ) : o.priority?.toLowerCase() === "low" ? (
            <span className="lowClr">Low</span>
          ) : (
            <span className="mediumClr">Medium</span>
          ),
        priorityValue: o?.priority,
        ticketStatusValue: o?.status,
        errorType: o?.errorType,
        additionalInfo: o?.additionalInfo,
        dateValue: o?.date || "",
        isPriority:
          o.priority?.toLowerCase() === "high"
            ? true
            : o.priority?.toLowerCase() === "medium"
              ? false
              : "1",
        isStatus: o.status?.toLowerCase() === "open" ? true : false,
        sku: o.skuId || [],
        promoNameId: o.promoName?.name || "",
        actionOwner: o?.actionOwner?._id || null,
        actionOwnerName: o?.actionOwner?.fullName || "",
        adSet: o?.adsetDetails?.name || "",
        eventName: o?.eventDetails?.name || "",
      };
    });
  };

  const priorityOptions = [
    {
      _id: "High",
      value: "High - Promo is Live",
    },
    {
      _id: "Medium",
      value: "Medium - Promo going live within 3 days",
    },
    {
      _id: "Low",
      value: "Low - Promo going live > 3 days",
    },
  ];

  const handleSearchSkuKeys = async (value) => {
    clearTimeout(timer);

    if (value.length >= 3) {
      const newTimer = setTimeout(async () => {
        setFetching(true);
        setDataSku([]);
        const res = await dispatch(getSKUBySearch(value));
        if (res.error === undefined) {
          setDataSku(res.payload.data.data);
          setFetching(false);
        } else {
          setFetching(false);
        }
      }, 700);
      setTimer(newTimer);
    }
  };

  const renderTag = () => {
    const children = [];
    for (let i = 0; i < errorTypeValues.length; i++) {
      children.push(
        <Option key={errorTypeValues[i]}>{errorTypeValues[i]}</Option>
      );
    }
    const priorityChildren = [];
    for (let i = 0; i < priorityOptions.length; i++) {
      priorityChildren.push(
        <Option key={priorityOptions[i]._id}>{priorityOptions[i].value}</Option>
      );
    }
    const skuChildren = [];
    if (dataSku.length > 0) {
      for (let i = 0; i < dataSku.length; i++) {
        skuChildren.push(
          <Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>
        );
      }
    }
    const promotionChildren = [];
    if (promotionNames && session.role.toLowerCase() === "admin") {
      for (let i = 0; i < promotionNames.length; i++) {
        promotionChildren.push(
          <Option key={promotionNames[i]._id}>{promotionNames[i].name}</Option>
        );
      }
    }
    if (promotionNames && session.role.toLowerCase() === "merchant") {
      const filtered = promotionNames.filter(
        (item) => item.owner?._id === session.userId
      );
      for (let i = 0; i < filtered.length; i++) {
        promotionChildren.push(
          <Option key={filtered[i]._id}>{filtered[i].name}</Option>
        );
      }
    }

    const usersChildren = [];
    if (users && users.length > 0) {
      if (currentAction === "Create Change Request") {
        const filtered = users.filter(
          (item) =>
            item._id !== session.userId && item.role.toLowerCase() === "admin"
        );
        for (let i = 0; i < filtered.length; i++) {
          usersChildren.push(
            <Option key={filtered[i]._id}>
              {filtered[i].fullName || filtered[i].firstName}
            </Option>
          );
        }
      }
      if (currentAction === "Update Error Ticket") {
        const filtered = users.filter(
          (item) => item.role.toLowerCase() === "admin"
        );
        for (let i = 0; i < filtered.length; i++) {
          usersChildren.push(
            <Option key={filtered[i]._id}>
              {filtered[i].fullName || filtered[i].firstName}
            </Option>
          );
        }
      }
    }
    return (
      <form className="userForm" onSubmit={handleSubmit}>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <label>
                <span className="spanReq">*</span> Title
              </label>

              <Input
                placeholder="Title"
                value={title}
                className="mt-5"
                onChange={handleTitle}
              />
              <span className="errorLabel">{titleError}</span>
            </div>
          </Col>

          <Col className="gutter-row" span={12}>
            <div>
              <DropDownWithSearch
                onChange={handlePromoName}
                label="Promo Name"
                required={true}
                value={promoName}
              />
              <span className="errorLabel">{promoNameError}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <label>SKU</label>
              <Select
                showSearch
                value={sku}
                placeholder={"Search and select SKU Keys"}
                className="w-100 mt-5"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                onChange={handleSku}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? "multiple" : "tags"}
                tokenSeparators={[","]}
                maxTagCount={3}
              >
                {skuChildren}
              </Select>
            </div>
          </Col>

          <Col className="gutter-row" span={12}>
            <div>
              <label>
                <span className="spanReq">*</span> Date
              </label>
              <DatePicker
                onChange={handleDate}
                format={DATE_FORMAT_DASH}
                disabled
                value={
                  ticketDate === ""
                    ? ticketDate
                    : moment(ticketDate, DATE_FORMAT_DASH)
                }
                showToday={false}
                className="datePic mt-5"
              />

              <span className="errorLabel">{ticketDateError}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Error Type
              </label>

              <Select
                placeholder="Select"
                className="w-100 mt-5"
                onChange={handleErrorType}
                value={errorType}
              >
                {children}
              </Select>
              <span className="errorLabel">{errorTypeError}</span>
            </div>
          </Col>
        </Row>

        {errorType === "Other (please specify in comments)" && (
          <Row gutter={16} className="mb-25">
            <Col className="gutter-row" span={24}>
              <div>
                <label>
                  <span className="spanReq">*</span> Specify Other
                </label>
                <TextArea
                  placeholder="Write Here"
                  onChange={handleErrorTypeValueOther}
                  className="w-100"
                  value={errorTypeValueOther}
                  rows={3}
                />
                <span className="errorLabel">{errorTypeValueOtherError}</span>
              </div>
            </Col>
          </Row>
        )}

        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Priority
              </label>

              <Select
                placeholder="Select"
                className="w-100 mt-5"
                onChange={handlePriority}
                value={priority}
              >
                {priorityChildren}
              </Select>
              <span className="errorLabel">{priorityError}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Action Owner
              </label>

              <Select
                showSearch
                placeholder="Search and select action owner"
                optionFilterProp="children"
                onChange={handleActionOwner}
                value={actionOwner}
                className="mt-5 w-100"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {usersChildren}
              </Select>
              <span className="errorLabel">{actionOwnerError}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <div>
              <label>Additional Information</label>
              <TextArea
                placeholder="Write Here"
                onChange={handleTextArea}
                className="w-100 mt-5"
                value={textArea}
                rows={3}
              />
            </div>
          </Col>
        </Row>
      </form>
    );
  };

  const handleTitle = (e) => {
    const { value } = e.target;
    setTitleError(Validator.validate("title", value, null, null, true));
    setTitle(value);
  };

  const handleAdd = () => {
    setModalOpen(true);
    setCurrentAction("Create Change Request");
    setOkBtnText("Submit Ticket");
  };

  const handleCancel = () => {
    setModalOpen(false);
    setCurrentAction("");
    setOkBtnText("");
    setTitle("");
    setSku([]);
    setTicketDate(moment().format(DATE_FORMAT_DASH));
    setPriority(null);
    setTextArea("");
    setPromoName(null);
    setActionOwner(null);
    setErrorType(null);
    setErrorTypeValueOther("");
    setTitleError("");
    setTicketDateError("");
    setErrorTypeError("");
    setPriorityError("");
    setCurrentRecord("");
    setPromoNameError("");
    setErrorTypeValueOtherError("");
    setActionOwnerError("");
  };

  const handlePromoName = (value) => {
    setPromoNameError(value === null ? "This Field is Required" : "");
    setPromoName(value);
  };

  const handleActionOwner = (value) => {
    setActionOwnerError(value === null ? "This Field is Required" : "");
    setActionOwner(value);
  };

  const handleTextArea = (e) => {
    const { value } = e.target;
    setTextArea(value);
  };

  const handleErrorTypeValueOther = (e) => {
    const { value } = e.target;
    setErrorTypeValueOtherError(
      Validator.validate("title", value, null, null, true)
    );
    setErrorTypeValueOther(value);
  };

  const handleSku = (value) => {
    setSku(value);
  };

  const handleErrorType = (value) => {
    let errormsg =
      value === "" || value.length === 0 || value === null
        ? "Please Select Error Type"
        : "";
    if (value === "Other (please specify in comments)") {
      setErrorTypeError(errormsg);
      setErrorType(value);
    } else {
      setErrorTypeError(errormsg);
      setErrorType(value);
      setErrorTypeValueOther("");
      setErrorTypeValueOtherError("");
    }
  };

  const handlePriority = (value) => {
    let errormsg =
      value === "" || value.length === 0 || value === null
        ? "Please Select Priority"
        : "";
    setPriorityError(errormsg);
    setPriority(value);
  };

  const handleDate = (value) => {
    setTicketDateError(Validator.validate("title", value, null, null, true));
    setTicketDate(value || "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newRecord = {};

    if (currentAction === "Create Change Request") {
      if (
        titleError !== "" ||
        ticketDateError !== "" ||
        errorTypeError !== "" ||
        priorityError !== "" ||
        title === "" ||
        ticketDate === "" ||
        errorType === null ||
        priority === null ||
        promoNameError !== "" ||
        promoName === null ||
        (errorType === "Other (please specify in comments)" &&
          errorTypeValueOther === "") ||
        (errorType === "Other (please specify in comments)" &&
          errorTypeValueOtherError !== "") ||
        actionOwnerError !== "" ||
        actionOwner === null
      ) {
        setTitleError(title === "" ? FIELD_REQUIRED : titleError);
        setPromoNameError(
          promoName === "" || promoName === null
            ? FIELD_REQUIRED
            : promoNameError
        );
        setActionOwnerError(
          actionOwner === "" || actionOwner === null
            ? FIELD_REQUIRED
            : actionOwnerError
        );
        setErrorTypeError(
          errorType === "" || errorType === null ? "This field is required" : ""
        );
        setPriorityError(
          priority === "" || priority === null ? "This field is required" : ""
        );
        setTicketDateError(
          ticketDate === "" ? FIELD_REQUIRED : ticketDateError
        );
        setErrorTypeValueOtherError(
          errorType === "Other (please specify in comments)" &&
            errorTypeValueOther === ""
            ? FIELD_REQUIRED
            : errorTypeValueOtherError
        );
        return;
      }
      setLoading(true);

      newRecord = {
        title: title,
        sku: sku,
        date: ticketDate,
        raisedBy: session?.fullName,
        priority: priority,
        additionalInfo: textArea,
        errorType:
          errorType === "Other (please specify in comments)"
            ? `${errorType} - ${errorTypeValueOther}`
            : errorType,
        status: "Open",
        promoName: promoName,
        actionOwner: actionOwner,
      };

      const response = await dispatch(
        createErrorTickets(
          newRecord.title,
          newRecord.sku,
          newRecord.date,
          newRecord.additionalInfo,
          newRecord.status,
          newRecord.priority,
          newRecord.errorType,
          newRecord.raisedBy,
          newRecord.promoName.value,
          newRecord.actionOwner
        )
      );

      if (response.error === undefined) {
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("success");
        setSuccessTicketId(
          response.payload.data.data.createdReport.ticketNumber
        );
        setMessageModal("Your Ticket has been created");
      } else {
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("failed");
      }
    }

    if (currentAction === "Update Error Ticket") {
      if (
        titleError !== "" ||
        ticketDateError !== "" ||
        errorTypeError !== "" ||
        priorityError !== "" ||
        title === "" ||
        ticketDate === "" ||
        errorType === null ||
        priority === null ||
        promoNameError !== "" ||
        promoName === null ||
        (errorType === "Other (please specify in comments)" &&
          errorTypeValueOther === "") ||
        (errorType === "Other (please specify in comments)" &&
          errorTypeValueOtherError !== "") ||
        actionOwnerError !== "" ||
        actionOwner === null
      ) {
        setTitleError(title === "" ? FIELD_REQUIRED : titleError);
        setPromoNameError(promoName === null ? FIELD_REQUIRED : promoNameError);
        setActionOwnerError(
          actionOwner === "" || actionOwner === null
            ? FIELD_REQUIRED
            : actionOwnerError
        );
        setErrorTypeError(
          errorType === "" || errorType === null ? "This field is required" : ""
        );
        setPriorityError(
          priority === "" || priority === null ? "This field is required" : ""
        );
        setTicketDateError(
          ticketDate === "" ? FIELD_REQUIRED : ticketDateError
        );
        setErrorTypeValueOtherError(
          errorType === "Other (please specify in comments)" &&
            errorTypeValueOther === ""
            ? FIELD_REQUIRED
            : errorTypeValueOtherError
        );
        return;
      }
      setLoading(true);
      newRecord = {
        title: title,
        sku: sku,
        raisedBy: currentRecord?.raisedBy,
        priority: priority,
        additionalInfo: textArea,
        errorType:
          errorType === "Other (please specify in comments)"
            ? `${errorType} - ${errorTypeValueOther}`
            : errorType,
        status: currentRecord.ticketStatusValue,
        promoName: promoName ? promoName.value || promoName._id : "",
        _id: currentRecord._id,
        actionOwner: actionOwner,
      };

      const response = await dispatch(
        updateErrorTicketById(
          newRecord.title,
          newRecord.sku,
          newRecord.additionalInfo,
          newRecord.status,
          newRecord.priority,
          newRecord.errorType,
          newRecord.raisedBy,
          newRecord.promoName,
          newRecord.actionOwner,
          newRecord._id
        )
      );

      if (response.error === undefined) {
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("success");
        setSuccessTicketId(
          response.payload.data.data.updatedReport.ticketNumber
        );
        setMessageModal("Ticket Updated Successfully");
      } else {
        handleCancel();
        setLoading(false);
        setTab("All Ticket");
        setSuccessModal(true);
        setSuccessModalType("failed");
      }
    }
  };

  const updateErrorTicket = (record) => {
    if (record.errorType.includes("Other")) {
      const values = record?.errorType.split(" - ");
      setErrorType(values[0]);
      setErrorTypeValueOther(values[1]);
    } else {
      setErrorType(record?.errorType);
    }
    setModalOpen(true);
    setCurrentAction("Update Error Ticket");
    setCurrentRecord(record);
    setTitle(record?.title);
    setSku(record.sku || []);
    setTicketDate(record?.date);
    setPriority(record?.priorityValue);
    setTextArea(record?.additionalInfo);
    setPromoName(record?.promoName);
    setActionOwner(record?.actionOwner);
    setOkBtnText("Save");
  };

  const viewErrorTicket = (record) => {
    navigate(`/error-report/${record._id}`);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModal(false);
    setSuccessTicketId("");
    setSuccessModalType("failed");
    setMessageModal("");
  };

  const deleteErrorTicket = (val) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteErrorTicketYes(val),
      okType: "primary",
    });
  };

  const deleteErrorTicketYes = async (val) => {
    await dispatch(deleteErrorTicketById(val._id));
    window.scrollTo(0, 0);
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };
  const handleReload = async () => {
    getData();
  };

  const setColumns = () => {
    let cols = [];

    ERROR_TICKET_LIST_COLUMNS.forEach((o) => {
      if (o.dataIndex === "date") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <DateFilter
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else if (o.dataIndex !== "priority" && o.dataIndex !== "status") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }
    });
    return cols;
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const onSearch = async (value) => {
    setTableLoading(true);
    setSearchValue(value);
  };

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value);
  };

  const exportErrorReportData = async () => {
    let data = {
      sortBy: "createdAt",
      orderBy: "DESC",
      search: searchValue || "",
      filters: {},
    };
    setButtonLoading(true);
    const res = await dispatch(getErrorReportExportData(data));
    setCurrentSuccessMsg(res?.payload?.data?.message);
    setTimeout(() => {
      setCurrentSuccessMsg("");
    }, 6000);
    setButtonLoading(false);
  };
  return (
    <CommonLayout className="content-mr" breadCrumbText="Error Report">
      {currentSuccessMsg && (
        <Alert message={currentSuccessMsg} closable type="success" showIcon />
      )}
      {loadingState || reloadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="add-error-card">
            <div className="error-right-content">
              <Search
                placeholder="Search Error Ticket"
                enterButton
                className="searchErrorBtn"
                onSearch={onSearch}
                allowClear
                onChange={handleSearchChange}
              />
            </div>

            <div className="left-content">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="addErrorBtn"
                onClick={handleAdd}
              >
                Create Change Request
              </Button>
            </div>
          </div>

          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="error-table-card">
              <div className="error-table-topbar">
                <div className="errorbtnGrpWrapper">
                  <button
                    className={tab === "All Ticket" ? "btnStyle" : "btnStyle1"}
                    onClick={() => setTab("All Ticket")}
                  >
                    All Ticket
                  </button>
                  <button
                    className={tab === "Open" ? "btnStyle" : "btnStyle1"}
                    onClick={() => setTab("Open")}
                  >
                    Open
                  </button>
                  <button
                    className={tab === "Resolved" ? "btnStyle" : "btnStyle1"}
                    onClick={() => setTab("Resolved")}
                  >
                    Closed
                  </button>
                </div>

                <div className="errorFilterGrpWrapper">
                  <Button
                    icon={
                      buttonLoading ? <LoadingOutlined /> : <ExportOutlined />
                    }
                    onClick={exportErrorReportData}
                    disabled={buttonLoading}
                  >
                    Export
                  </Button>
                  <div className="settingGrp">
                    <ReloadOutlined
                      onClick={handleReload}
                      className="reloadIconStyle"
                    />
                    <ColumnHeightOutlined
                      className="reloadIconStyle"
                      onClick={handleTableHeight}
                    />
                    <FullscreenOutlined
                      onClick={() => {
                        setIsEnter(true);
                      }}
                      className="reloadIconStyle"
                    />
                  </div>
                </div>
              </div>

              {/* table */}
              <div className="w-100">
                <CustomTable
                  isLoading={loadingState || tableLoading}
                  columns={setColumns()}
                  rows={setupRows(tickets)}
                  isEditable={true}
                  isDeleteable={true}
                  rowKey="_id"
                  isViewable={true}
                  onEdit={updateErrorTicket}
                  onDelete={deleteErrorTicket}
                  total={total}
                  onView={viewErrorTicket}
                  size={tableSize}
                  onChange={async (
                    { current, pageSize },
                    filter = {},
                    { field, order, columnKey }
                  ) => {
                    const params = {
                      page: current,
                      perPage: pageSize,
                      sortBy: columnKey,
                      orderBy: order,
                    };
                    setCurrPage(current);
                    setPageSize(pageSize);
                    const keys = Object.keys(filter);
                    keys.forEach((key) => {
                      if (
                        (key === "title" ||
                          key === "promoName.name" ||
                          key === "raisedBy" ||
                          key === "actionOwnerName") &&
                        filter[key]
                      ) {
                        if (!params.search) {
                          params.search = {};
                        }
                        if (key === "actionOwnerName") {
                          params.search["actionOwner.fullName"] = filter[key];
                        } else {
                          params.search[key] = filter[key];
                        }
                      } else {
                        if (filter[key]) {
                          if (!params.filters) {
                            params.filters = {};
                          }
                          if (key === "date") {
                            params.date = filter[key];
                          } else {
                            params.filters[key] = filter[key];
                          }
                        }
                      }
                    });

                    setTableLoading(true);
                    getData(params, false);
                  }}
                />
              </div>

              <Modal
                visible={modalOpen}
                title={currentAction}
                onOk={handleSubmit}
                onCancel={handleCancel}
                okText={okBtnText}
                destroyOnClose
                maskClosable={false}
                footer={
                  currentAction === "Create Change Request" ||
                    currentAction === "Update Error Ticket"
                    ? [
                      <Button onClick={handleCancel}>Cancel</Button>,
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                        className="footerBtnNavy"
                      >
                        {loading ? "Loading..." : okBtnText}
                      </Button>,
                    ]
                    : null
                }
              >
                {currentAction === "Create Change Request" ||
                  currentAction === "Update Error Ticket" ? (
                  renderTag(currentRecord)
                ) : (
                  <></>
                )}
              </Modal>

              <Modal
                visible={successModal}
                title={currentAction}
                destroyOnClose
                footer={null}
                closable={false}
              >
                {successModalType === "success" ? (
                  <div className="modalContentDiv">
                    <CircleTickIcon className="successIconStyle" />
                    <div className="descriptionDiv">
                      <span className="desctitleText">
                        {messageModal} Ticket ID: #{successTicketId}
                      </span>
                      <span className="dessubTitleText">
                        You’ll soon get response from our support team
                      </span>
                    </div>
                    <Button className="mt-30" onClick={handleCloseSuccessModal}>
                      Close
                    </Button>
                  </div>
                ) : (
                  <div className="modalContentDiv">
                    <CloseCircleOutlined className="failIconStyle" />
                    <div className="descriptionDiv">
                      <span className="desctitleText">
                        Something went wrong. Try again after sometime.
                      </span>
                    </div>
                    <Button className="mt-30" onClick={handleCloseSuccessModal}>
                      Close
                    </Button>
                  </div>
                )}
              </Modal>

              {showDeleteAlert && (
                <Alert
                  message={TextMessage.ERROR_TICKET_DELETE_TEXT}
                  type="error"
                  showIcon
                  closable
                  onClose={handleDeleteAlertClose}
                  icon={<DeleteOutlined />}
                  className="alertStyle"
                />
              )}
            </div>
          </Fullscreen>
        </>
      )}
    </CommonLayout>
  );
};

export default ErrorReport;
