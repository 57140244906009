import * as ActionTypes             from './action-types';

export const updateLocalMapsJSON = (maps) => ({
    type: ActionTypes.UPDATE_LOCAL_MAPS_JSON,
    maps
})

export const mapCreateMessage = (msg) => ({
    type: ActionTypes.MAP_CREATE_MESSAGE,
    msg
})

export const mapValidateMessage = (msg) => ({
    type: ActionTypes.MAP_VALIDATE_MESSAGE,
    msg
})

export const getAllMaps = () => ({
    type: ActionTypes.GET_ALL_MAPS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map`,
            method: "get",
        }
    }
});

export const getMapsByStatus = (fieldName='',fieldValue='') => ({
    type: ActionTypes.GET_MAPS_BY_STATUS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map?fieldName=${fieldName}&fieldValue=${fieldValue}`,
            method: "get",
        }
    }
});

export const viewMap = (id) => ({
    type: ActionTypes.VIEW_MAP,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map/${id}`,
            method: "get",
        }
    }
});

export const createMap = (data) => ({
    type: ActionTypes.MAP_CREATE,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map`,
            method: "post",
            data
        }
    }
})

export const validateMap = (data) => ({
    type: ActionTypes.MAP_VALIDATE,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map/validate`,
            method: "post",
            data
        }
    }
})

export const deleteMapById = (id) => ({
    type: ActionTypes.DELETE_MAP,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map/${id}`,
            method: "delete",
        }
    },
    meta:{
        "_id": id
    }
})

export const changeStatus = (id,status,rejectReason='') => ({
    type: ActionTypes.CHANGE_STATUS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map/${id}`,
            method: "patch",
            data:{
                "status" : status,
                "rejectReason" : rejectReason,
            }
        }
    }
})

export const getMapsBySearch = (fieldName='',fieldValue='') => ({
    type: ActionTypes.GET_MAP_BY_SEARCH,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/map/search?fieldName=${fieldName}&fieldValue=${fieldValue}`,
            method: "get",
        }
    }
})

export const clearMapsBySearch = (val) => ({
    type: ActionTypes.CLEAR_MAP_BY_SEARCH,
    val
})

export const updateMapSearchFilterFlag = (val) => ({
    type: ActionTypes.UPDATE_SEARCH_FILTER_FLAG,
    val
})

export const updateSelectedBrand = (val) => ({
    type: ActionTypes.UPDATE_SELECTED_BRAND,
    val
})

export const updateSelectedSku = (val) => ({
    type: ActionTypes.UPDATE_SELECTED_SKU,
    val
})

export const updateChosenMapType = (val) => ({
    type: ActionTypes.UPDATE_CHOSEN_MAP_TYPE,
    val
})