export const UPDATE_LOCAL_SHELF_JSON = "UPDATE_LOCAL_SHELF_JSON";
export const CREATE_LOCAL_SHELF_JSON = "CREATE_LOCAL_SHELF_JSON";
export const DELETE_LOCAL_SHELF_JSON = "DELETE_LOCAL_SHELF_JSON";

export const GET_ALL_SHELF = "GET_ALL_SHELF";
export const GET_SINGLE_SHELF = "GET_SINGLE_SHELF";
export const CREATE_SHELF = "CREATE_SHELF";
export const DELETE_SHELF = "DELETE_SHELF";
export const UPDATE_SHELF = "UPDATE_SHELF";
export const GET_SHELF_BY_ID = "GET_SHELF_BY_ID";
export const UPDATE_SHELF_BY_SEARCH = "UPDATE_SHELF_BY_SEARCH";
export const GET_SKU_BY_SEARCH = "GET_SKU_BY_SEARCH";
export const GET_ALL_SEARCH_SKU = "GET_ALL_SEARCH_SKU";
export const GET_SKU_ASSOCIATED_SHELF= "GET_SKU_ASSOCIATED_SHELF";

export const IMPORT_SHELF = "IMPORT_SHELF";
export const GET_SHELF_EXPORT_DATA = "GET_SHELF_EXPORT_DATA";