import { CopyOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Select } from "antd";

const Option = Select;

const DatePickerQuestion = ({
  isDatePicker,
  index,
  question,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  loading,
  handleQuestionDesignate,
  handleQuestionFlag,
}) => {
  return (
    <>
      {isDatePicker && question.questionType === "datePicker" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox onChange={(e) => handleQuestionDesignate(e, index)}>
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}

      {isDatePicker && question.questionType === "datePicker" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="fomBuilderSelectStyle">
            <Select
              placeholder="Select Question Flag"
              onChange={(value) => handleQuestionFlag(index, value)}
            >
              <Option value={"Start Date"}>Start Date</Option>
              <Option value={"List Price"}>List Price</Option>
              <Option value={"Map Price"}>MAP Price</Option>
              <Option value={"Map Hold Duration"}>MAP Hold Duration</Option>
              <Option value={"Days Of Inventory"}>Days Of Inventory</Option>
              <Option value={"Pricing Groups"}>Pricing Groups</Option>
            </Select>
          </div>
        </div>
      )}

      {isDatePicker && question.questionType === "datePicker" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          <div className="formBuilderActionsWrapper">
            <div className="copyDiv">
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={() => copyQuestion(index)}
              >
                <CopyOutlined />
              </Button>
            </div>
            <div className="delDiv" disabled={loading}>
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={() => deleteQuestion(index)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DatePickerQuestion;
