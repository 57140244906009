import React, { useState, useEffect } from "react";
import './xml-editor.css';
import { xml2js, js2xml } from "xml-js";
import { Select } from "antd";
const { Option } = Select;

const XmlEditor = ({ xmlData, setXmlData, optionsVal, actionType }) => {
  const [selectedTag, setSelectedTag] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const handleTagClick = (path) => {
    setSelectedTag(path);
    setIsDropdownOpen(true);
  };

  const handleOptionSelect = (option) => {
    const xmlObj = xml2js(xmlData, { compact: false });
    let current = xmlObj.elements;
    const newText = `{{${option}}}`;
    const path = selectedTag;

    if (
      current[path?.[0]]?.elements[path?.[1]]?.elements?.[path[2]]?.elements?.[path[3]]?.elements?.[path[4]]?.text
    ) {
      current[path[0]].elements[path[1]].elements[path[2]].elements[path[3]].elements[path[4]].text = newText;
    }

    if (
      current[path?.[0]]?.elements[path?.[1]]?.elements?.[path[2]]?.elements?.[path[3]]?.text
    ) {
      current[path[0]].elements[path[1]].elements[path[2]].elements[path[3]].text = newText;
    }


    if (
      current[path?.[0]]?.elements[path?.[1]]?.elements?.[path[2]].text
    ) {
      current[path[0]].elements[path[1]].elements[path[2]].text = newText;
    }

    if (
      current[path?.[0]]?.elements[path?.[1]].text
    ) {
      current[path[0]].elements[path[1]].text = newText;
    }

    if (
      current[path?.[0]].text
    ) {
      current[path[0]].text = newText;
    }
    const xmlResult = js2xml(xmlObj, { compact: false, spaces: 2 });
    setXmlData(xmlResult);
    setIsDropdownOpen(false);
  };
  const renderXml = (elements, path = []) => {
    return elements?.map((element, index) => {
      const newPath = [...path, index];
      if (element.type === "element") {
        return (
          <div key={index} style={{ marginLeft: "20px" }}>
            <span style={{ color: "brown" }}>{`<${element.name}`}</span>
            {element.attributes &&
              Object.entries(element.attributes).map(([key, value]) => (
                <span key={key} style={{ color: "red" }}>
                  {` ${key}="${value}"`}
                </span>
              ))}
            <span style={{ color: "brown" }}>{`>`}</span>
            {element.elements && renderXml(element.elements, newPath)}
            <span style={{ color: "brown" }}>{`</${element.name}>`}</span>
          </div>
        );
      }
      if (element.type === "text") {
        return (
          <span
            key={index}
            onClick={() => handleTagClick(newPath)}
            style={{ cursor: "pointer", color: "blue" }}
          >
            {element.text}
          </span>
        );
      }
      return null;
    });
  };
  return (
    <div>
      <h1>XML Editor</h1>
      <div className="xmlEditor">
        <div className="editor">
          <pre>{renderXml(xml2js(xmlData, { compact: false }).elements)}</pre>
        </div>
        <div className="dropdownPromo">
          {isDropdownOpen && actionType !== 'view' && (
            <div className="wrapperXmlQuestions">
              <h3>Select an option</h3>

              <Select
                allowClear
                style={{
                  width: "100%",
                  padding: "5px 0 0 0",
                }}
                id="questions"
                placeholder="Select Options..."
                onChange={(value) => handleOptionSelect(value)}
                // value={values.promoType}
                showSearch
                filterOption={(input, option) => {
                  return (option?.children?.toLowerCase() ?? "").includes(
                    input?.toLowerCase()
                  );
                }}
              >
                {optionsVal?.map((option) => (
                  <Option key={option?.value} value={option?.value}>
                    {option?.label}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default XmlEditor;
