import BottomSection from "./BottomSection";
const TextAreaQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleQuestionDesignate,
  index,
  handleSaveQuestion,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
}) => {
  return (
    <>
      <BottomSection
        isSameType={question.questionType === "textarea"}
        onCheckboxChange={(e) => handleQuestionDesignate(e, index)}
        onSaveClick={() => handleSaveQuestion(question, index)}
        loading={loading}
        handleCancelEditView={handleCancelEditView}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
      />
    </>
  );
};

export default TextAreaQuestionView;
