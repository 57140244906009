import * as ActionTypes         from 'actions/mapFormBuilder/action-types'
import MapFormBuilderStateManager        from './state-manager';
import { S,  }                  from 'utils/prefix';

const INITIAL_STATE = {
    mapFormBuilder: [],
};

export default function MapFormBuilderStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_LOCAL_MAP_FORM_BUILDER_JSON:
                return { ...state, mapFormBuilderLocal: [ ...action.formBuilder ] };
        case ActionTypes.UPDATE_LOCAL_MAP_QUESTION_SAVE_JSON:
                return { ...state, mapQuestionsLocal: [ ...action.questions ] };

        case S(ActionTypes.GET_MAP_FORMBUILDER):
                return MapFormBuilderStateManager.getMapFormBuilder(state,action);
        case S(ActionTypes.CREATE_MAP_FORMBUILDER):
                return MapFormBuilderStateManager.createMapFormBuilder(state,action);
        case S(ActionTypes.DELETE_MAP_FORMBUILDER):
                return MapFormBuilderStateManager.deleteMapFormBuilder(state,action);
        default:
            return state;
    }
}