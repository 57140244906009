import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  SisternodeOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Input, Select } from "antd";
import { showQuestionError } from "utils/constants";

const Option = Select;

const TabQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleAddSubOptionsTab,
  handleAddOptionsTab,
  handleDeleteOptionsTab,
  handleOptionTextChangeTab,
  handleSubOptionTypeChangeTab,
  handleDeleteSubOptionsTab,
  handleSuboptionTextChangeTab,
  index,
  handleSaveQuestion,
  handleQuestionDesignate,
  handleQuestionFlag,
}) => {
  return (
    <>
      {question.questionType === "tabs" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
            {question.questionType === "tabs" &&
              question.optionValue?.map((op, j) => (
                <>
                  <div
                    className="formBuilderFrameTwoDynamicOptionFrame"
                    key={j}
                  >
                    <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                      <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                        <Input
                          addonBefore={`${j + 1}st`}
                          addonAfter={
                            <SisternodeOutlined
                              onClick={() => handleAddSubOptionsTab(index, j)}
                            />
                          }
                          suffix={
                            <DeleteOutlined
                              className="cp"
                              onClick={() => handleDeleteOptionsTab(index, j)}
                            />
                          }
                          value={op.optionText}
                          onChange={(e) =>
                            handleOptionTextChangeTab(e.target.value, index, j)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    {op?.subOptions?.map((sOp, k) => (
                      <>
                        <div
                          className="formBuilderFrameTwoDynamicSubOptionFrame"
                          key={k}
                        >
                          <div className="formBuilderFrameTwoDynamicSubOptionValueFrame">
                            <div className="formBuilderFrameTwoDynamicSubOptionValueGroup">
                              <div className="formBuilderFrameTwoDynamicSubOptionValueGroupFrame">
                                <div className="formBuilderFrameTwoDynamicSubOptionValueGroupFirstChildWrapper">
                                  <Input
                                    placeholder="Label"
                                    addonAfter={
                                      <Select
                                        placeholder="Select"
                                        value={sOp.subOptionType}
                                        onChange={(value) =>
                                          handleSubOptionTypeChangeTab(
                                            value,
                                            index,
                                            j,
                                            k
                                          )
                                        }
                                      >
                                        <Option value={"multiSelect"}>
                                          Multi Select
                                        </Option>
                                      </Select>
                                    }
                                    suffix={
                                      <DeleteOutlined
                                        className="cp"
                                        onClick={() =>
                                          handleDeleteSubOptionsTab(index, j, k)
                                        }
                                      />
                                    }
                                    value={sOp.subOptionText}
                                    onChange={(e) =>
                                      handleSuboptionTextChangeTab(
                                        e.target.value,
                                        index,
                                        j,
                                        k
                                      )
                                    }
                                  />
                                </div>

                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ))}
          </div>

          {question.questionType === "tabs" && (
            <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
              <Button
                icon={
                  <PlusOutlined
                    style={{ marginRight: "-10px", color: "#0F2A6F" }}
                  />
                }
                className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                onClick={() => handleAddOptionsTab(index)}
              >
                Add Tab
              </Button>
            </div>
          )}
        </div>
      )}
      {question.questionType === "tabs" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox
              onChange={(e) => handleQuestionDesignate(e, index)}
              checked={
                question.designate && question.designate === "optional"
                  ? false
                  : true
              }
            >
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}

      {question.questionType === "tabs" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="fomBuilderSelectStyle">
            <Select
              placeholder="Select Question Flag"
              onChange={(value) => handleQuestionFlag(index, value)}
              value={question.questionFlag || null}
            >
              <Option value={"Start Date"}>Start Date</Option>
              <Option value={"List Price"}>List Price</Option>
              <Option value={"Map Price"}>MAP Price</Option>
              <Option value={"Map Hold Duration"}>MAP Hold Duration</Option>
              <Option value={"Days Of Inventory"}>Days Of Inventory</Option>
              <Option value={"Pricing Groups"}>Pricing Groups</Option>
            </Select>
          </div>
        </div>
      )}
      {question.questionType === "tabs" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          {showQuestionError(question)}
          <div className="formBuilderActionsWrapper">
            <Button
              icon={<CloseOutlined />}
              className="saveBtn"
              onClick={handleCancelEditView}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TabQuestionView;
