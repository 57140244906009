import * as ActionTypes     from 'actions/app/action-types';
import AppStateManager      from './state-manager';

const INITIAL_STATES = {
    currentPage: "dashboard",
}

export default function appStateManager (state=INITIAL_STATES, action) {
    switch (action.type) {
        case ActionTypes.SET_PAGE:
            return AppStateManager.setPage(state, action);
        case ActionTypes.SET_CURRENT_PAGE:
            return { ...state, currentPage: action.pageId };
        default: 
            return state;
    }
}