import "./elements.css";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import { Input } from "antd";
import MediaService from "services/media/media-service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";

const MediaElement = (props) => {
  const [value, setValue] = useState(props.ans ? props.ans : "");
  const [url, setUrl] = useState(undefined);
  const mediaService = useMemo(() => new MediaService(), []);
  const { showAlert } = useAlert();

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await mediaService.uploadMedia(formData);
        if (response && response?.status) {
          setValue(response?.data?._id);
          setUrl(response?.data?.url);
          props.onChange(response?.data?._id, props.id);
          showAlert({
            message: response?.message || "File Uploaded successfully",
            variant: AlertVariant.SUCCESS,
          });
        } else {
          showAlert({
            message:
              response?.message || "Something went wrong while uploading...",
            variant: AlertVariant.ERROR,
          });
        }
      } catch (error) {
        showAlert({
          message: "Something went wrong while uploading...",
          variant: AlertVariant.ERROR,
        });
      }
    }
  };

  const getMediaUpload = async () => {
    const getMedia = await mediaService.getMedia(props.ans);
    setUrl(getMedia?.data?.url);
  };

  useEffect(() => {
    if (props.ans) {
      getMediaUpload();
    }
    return () => {};
  }, [props?.ans]);

  return (
    <div className="input-frame">
      <div className="d-label">
        {props.designate && props.designate === "required" ? (
          <span className="spanReq">*</span>
        ) : (
          ""
        )}{" "}
        <label className="input-label">{props.label}</label>
      </div>
      {props.action === "create" && (
        <>
          <Input
            className="input-text-field"
            // value={value}
            type="file"
            style={{
              minHeight: 42,
            }}
            onChange={handleUpload}
            placeholder="Enter here"
          />
          <span className="error-label">{props.error}</span>
        </>
      )}
      {props.action === "edit" && (
        <>
          <Input
            className="input-text-field"
            // value={value}
            type="file"
            style={{
              minHeight: 42,
            }}
            onChange={handleUpload}
            placeholder="Enter here"
          />
          <span className="error-label">{props.error}</span>
          <a title={url} href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </>
      )}
      {props.action === "view" && (
        <a title={url} href={url} target="_blank" rel="noreferrer">
          {url}
        </a>
      )}
    </div>
  );
};

export default MediaElement;
