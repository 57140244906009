export const UPDATE_LOCAL_FORM_BUILDER_JSON = "UPDATE_LOCAL_FORM_BUILDER_JSON";
export const UPDATE_LOCAL_QUESTION_SAVE_JSON = "UPDATE_LOCAL_QUESTION_SAVE_JSON";

export const GET_ALL_QUESTIONS = "GET_ALL_QUESTIONS";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const UPDATE_QUESTION_STATUS = "UPDATE_QUESTION_STATUS";

export const GET_FORMBUILDER = "GET_FORM_BUILDER";
export const CREATE_FORMBUILDER = "CREATE_FORMBUILDER";
export const UPDATE_FORMBUILDER = "UPDATE_FORMBUILDER";
export const DELETE_QUESTION_FROM_FORMBUILDER = "DELETE_QUESTION_FROM_FORMBUILDER";
export const CHANGE_QUESTION_ORDER = "CHANGE_QUESTION_ORDER";