import BottomSection from "./BottomSection";
const MediaAnswerQuestions = ({
  isMedia,
  question,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  loading,
  handleQuestionDesignate,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
  onRegexCheckboxChange,
  handleRegexChange,
}) => {
  return (
    <>
      <BottomSection
        isSameType={isMedia && question.questionType === "media"}
        onCheckBoxChange={(e) => handleQuestionDesignate(e, index)}
        loading={loading}
        handleSaveQuestion={() => handleSaveQuestion(question, index)}
        copyQuestion={() => copyQuestion(index)}
        deleteQuestion={() => deleteQuestion(index)}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
        showRegex={false}
        onRegexCheckboxChange={(e) => onRegexCheckboxChange(e, index)}
        handleRegexChange={(e) => handleRegexChange(e, index)}
      />
    </>
  );
};

export default MediaAnswerQuestions;
