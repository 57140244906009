import { S, F } from "utils/prefix";
import * as ActionTypes from "actions/login/action-types";
import LoginStateManager from "./state-manager";

const INITIAL_STATE = {
  logins: [],
  loginsCalendar: [],
  total_logins: 0,
  order_settings: {},
  currentErrorMsg: "",
  currentSuccessMsg: "",
  user: null,
};

export default function loginStates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case S(ActionTypes.LOGIN):
      return LoginStateManager.addNewLoginSuccess(state, action);
    case S(ActionTypes.FORGOT_PASSWORD):
    case S(ActionTypes.RESET_PASSWORD):
    case ActionTypes.SUCCESS_MSG:
      return {
        ...state,
        currentSuccessMsg: action.payload?.data?.data,
        currentErrorMsg: "",
      };

    case ActionTypes.ERROR_MSG:
      return {
        ...state,
        currentErrorMsg: action.payload?.data?.data,
        currentSuccessMsg: "",
      };

    case ActionTypes.DISABLE_MSG:
      return {
        ...state,
        currentSuccessMsg: "",
        currentErrorMsg: "",
      };

    case S(ActionTypes.EMAIL_VALIDATOR):
    case ActionTypes.LOGOUT:
      return LoginStateManager.logout(state, action);

    case F(ActionTypes.LOGIN):
    case F(ActionTypes.FORGOT_PASSWORD):
    case F(ActionTypes.RESET_PASSWORD):
    case F(ActionTypes.EMAIL_VALIDATOR):
    default:
      return state;
  }
}
