import "./SingleBrand.css";
import React from "react";
import { useEffect ,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input,Spin, Tabs,Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomTable from "components/common/table/Table";
import CommonLayout from "components/layout/Layout";
import TableSearchDropdown                                                        from 'components/TableSearchDropdown';
import { Outlet } from "react-router-dom";
import { BRAND_SKU_LIST_COLUMNS, PROMOCODE_LIST_COLUMNS } from "utils/column";
import { useParams } from "react-router";
import data from "utils/dataList.json";
import { getSingleBrand} from "actions/sku";
import { setPage } from "actions/app";
import { getPromoAssociatedBrand } from "actions/promotions";

const { Search } = Input;
const { TabPane } = Tabs;

const SingleBrand = () => {
  const dispatch = useDispatch(); 
  const { singleBrandData } = useSelector((state) => state.sku);
  const { promoAssociatedBrand } = useSelector((state) => state.promo);
  const { id } = useParams();
  const [key, setKey] = useState("skus");
  const [searchedPromo, setSearchedPromo] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getSingleBrand(id));
      if (res?.payload?.data?.data) {
      const res1 = await dispatch(getPromoAssociatedBrand(res?.payload?.data?.data[0].sku_brand_desc))
      if(res1){
        setLoadingState(false);
      }
      }
    };
    getCall();
    dispatch(setPage("Brands"));
   
  }, [dispatch,id]);

  const handleTab = (key) => {
    setKey(key);
  };   

  const onSearch = async (value) => {
    setSearchedPromo(
      singleBrandData.filter((item) =>
        item.sku_desc.toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      )
    );
  };


  const setupRowsSku = (singleBrandData) => {
    return singleBrandData?.map((o) => {
      return {
        sku: o.sku_desc,
        product: o?.brand_product_type? o?.brand_product_type: "-",
        dayId: o.class_id,       
      };
    });
  };

  const setColumns = () => {
    let cols = [];

    BRAND_SKU_LIST_COLUMNS.forEach((o) => {
        if ( o.dataIndex !== 'product') {
            const colsOptions = {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                    return (
                        <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                                             selectedKeys={selectedKeys}
                                             confirm={confirm}
                                             clearFilters={clearFilters}
                                             dataIndex={o.dataIndex} />
                    )
                },
                filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                onFilter: (value, record) => {
                
                    const rec = record[o.dataIndex]
                                    ? record[o.dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
                                    : '';
                    return rec;
                },
                
            }
            cols.push({ ...o, ...colsOptions });
        }
        else {
            cols.push(o);
        }
           
    });
    return cols
}

  const setupRowsPromo = (promoByStatus) => {
    return promoByStatus?.map((o) => {
      const oStatus =
        (o.status === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
        (o.status === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
        (o.status === "Expired" && (
          <Tag className="status-symbol-expired">Expired</Tag>
        )) ||
        (o.status === "Incomplete" && (
          <Tag className="status-symbol-incomplete">Incomplete</Tag>
        )) ||
        (o.status === "Approved" && (
          <Tag className="status-symbol-approved">Approved</Tag>
        )) ||
        (o.status === "Live" && (
          <Tag className="status-symbol-live">Live</Tag>
        )) ||
        (o.status === "Rejected" && (
          <Tag className="status-symbol-rejected">Rejected</Tag>
        )) ||
        (o.status === "Live-Errors" && (
          <Tag className="status-symbol-lv">Live-Errors</Tag>
        )) ||
        (o.status === "Pending-Errors" && (
          <Tag className="status-symbol-pv">Pending-Errors</Tag>
        )) ||
        (o.status === "Approved-Errors" && (
          <Tag className="status-symbol-av">Approved-Errors</Tag>
        )) ||
        (o.status === "Expired-Errors" && (
          <Tag className="status-symbol-pv">Expired-Errors</Tag>
        )) ||
        (o.status === "Incomplete-Errors" && (
          <Tag className="status-symbol-iv">Incomplete-Errors</Tag>
        ))||
        (o.status === "Submitted-Errors" && (
          <Tag className="status-symbol-iv">Submitted-Errors</Tag>
        ))
      return {
        promoname: o.name,
        _id: o._id,
        status: oStatus,
        expiringdate: o?.endDate || '',
      };
    });
  };

  const setColumnsPromocodes = () => {
        let cols = [];
    
        PROMOCODE_LIST_COLUMNS.forEach((o) => {
            if (o.dataIndex === "promoname") {
                const colsOptions = {
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        return (
                            <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                                                 selectedKeys={selectedKeys}
                                                 confirm={confirm}
                                                 clearFilters={clearFilters}
                                                 dataIndex={o.dataIndex} />
                        )
                    },
                    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                    onFilter: (value, record) => {
                    
                        const rec = record[o.dataIndex]
                                        ? record[o.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                                        : '';
                        return rec;
                    },
                    
                }
                cols.push({ ...o, ...colsOptions });
            }
            else {
                cols.push(o);
            }
               
        });
        return cols
    }
  
  return (    
    <CommonLayout breadCrumbText="Brands" className="content-mr" backButton={true} id={id} backUrl={'/brands'}>
     {!loadingState?(<div><div className="brand-card">
        <div className="page-header1">
          <div className="layout-block">
            <div className="heading-left">
              <div className="heading-left-wrapper">
                <span className="head-text">{singleBrandData && singleBrandData[0]?.sku_brand_desc}</span>
              </div>
            </div>
          </div>          

          <div className="tabs-top">
            <div className="tabs-wrapper">
              <div className="brand-box-shadow"></div>
              <div className="tabs-groups">
                <Tabs defaultActiveKey="skus" onChange={handleTab}>
                  <TabPane tab="SKU's" key="skus"></TabPane>
                  <TabPane tab="Promo codes" key="promocode"></TabPane>                  
                </Tabs>
              </div>
              {key === "skus" &&
                <div className="right-extra">
                  <Search
                    className="right-wrapper brandBtn"
                    placeholder="SKU search"
                    onSearch={onSearch}
                  />
                </div>
              }
            </div>
          </div>
        </div>

        <div className="user-table-card">
          <Outlet />

          {/* table starts */}

          <div className="w-100 mt-16">
            {key === "skus" && (
              <CustomTable
                columns={setColumns()}
                rows={
                  searchedPromo ? setupRowsSku(searchedPromo) : setupRowsSku(singleBrandData)
                }
                rowKey="skus_id"               
                total={singleBrandData?.length}
              />
            )}
            {key === "promocode" && (
              <CustomTable
                columns={setColumnsPromocodes()}
                rows={setupRowsPromo(promoAssociatedBrand)}
                rowKey="promocode_id"               
                total={data?.promocodes?.length}
              />
            )}
           
          </div>
        </div>
      </div></div>):(<div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>)}
      
    </CommonLayout>
  );
};

export default SingleBrand;