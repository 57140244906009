import * as ActionTypes from "./action-types";

export const updateLocalFormBuilderJSON = (formBuilder) => ({
  type: ActionTypes.UPDATE_LOCAL_FORM_BUILDER_JSON,
  formBuilder,
});

export const updateLocalSaveQuestionJSON = (questions) => ({
  type: ActionTypes.UPDATE_LOCAL_QUESTION_SAVE_JSON,
  questions,
});

export const getAllQuestions = () => ({
  type: ActionTypes.GET_ALL_QUESTIONS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/questionPromo`,
      method: "get",
    },
  },
});

export const createQuestion = (questions) => ({
  type: ActionTypes.CREATE_QUESTION,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/questionPromo`,
      method: "post",
      data: questions,
    },
  },
});

export const updateQuestionById = (questions, questionId) => ({
  type: ActionTypes.UPDATE_QUESTION,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/questionPromo/${questionId}`,
      method: "put",
      data: questions,
    },
  },
  meta: {
    questions: questions,
  },
});

export const updateQuestionByWorkflowTeam = (questions, promoId) => ({
  type: "update_question",
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${promoId}/question/${questions?._id}`,
      method: "patch",
      data: {
        standardWorkflowApproverTeamId:
          questions?.standardWorkflowApproverTeamId,
        emergencyWorkflowApproverTeamId:
          questions?.emergencyWorkflowApproverTeamId,
      },
    },
  },
});

export const updateQuestionStatus = (value, questionId) => ({
  type: ActionTypes.UPDATE_QUESTION_STATUS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/questionPromo/${questionId}`,
      method: "patch",
      data: {
        isPublished: value,
      },
    },
  },
  meta: {
    isPublished: value,
    _id: questionId,
  },
});

export const deleteQuestionById = (questionId) => ({
  type: ActionTypes.DELETE_QUESTION,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/questionPromo/${questionId}`,
      method: "delete",
    },
  },
  meta: {
    _id: questionId,
  },
});

export const getFormBuilder = () => ({
  type: ActionTypes.GET_FORMBUILDER,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/formBuilderPromo`,
      method: "get",
    },
  },
});

export const createFormBuilder = (questionIds) => ({
  type: ActionTypes.CREATE_FORMBUILDER,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/formBuilderPromo`,
      method: "post",
      data: {
        questionIds: questionIds,
        status: "active",
      },
    },
  },
});

export const updateFormBuilder = (questionIds, id) => ({
  type: ActionTypes.UPDATE_FORMBUILDER,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/formBuilderPromo/${id}`,
      method: "put",
      data: {
        questionIds: questionIds,
        status: "active",
      },
    },
  },
  meta: {
    _id: id,
    questionIds: questionIds,
  },
});

export const deleteQuestionFromFormBuilder = (questionId, formBuilderId) => ({
  type: ActionTypes.DELETE_QUESTION_FROM_FORMBUILDER,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/formBuilderPromo/${formBuilderId}`,
      method: "patch",
      data: {
        questionId: questionId,
      },
    },
  },
  meta: {
    _id: formBuilderId,
    questionId: questionId,
  },
});

export const changeQuestionOrder = (questionId, formBuilderId) => ({
  type: ActionTypes.CHANGE_QUESTION_ORDER,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/formBuilderPromo/dragAndDrop/${formBuilderId}`,
      method: "patch",
      data: {
        questionIds: questionId,
      },
    },
  },
  meta: {
    _id: formBuilderId,
    questionIds: questionId,
  },
});
