import "./Users.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Spin,
  Select,
  Space,
} from "antd";
import {
  ColumnHeightOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import Fullscreen from "fullscreen-react";
import { Outlet } from "react-router-dom";
import CommonLayout from "components/layout/Layout";
import { Validator } from "utils/validations";
import { TextMessage, FIELD_REQUIRED, SECONDS } from "utils/constants";
import {
  getAllUsers,
  createUser,
  updateUserBySearch,
} from "actions/users/index";
import { setPage } from "actions/app";
import { getAllTeams } from "actions/teams";
import { USER_PERMISSIONS } from "utils/column";
import { NavLink } from "react-router-dom";
import session from "utils/session";
const { Search } = Input;

const SharedUserLayout = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [isEnter, setIsEnter] = useState(false);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const { teams } = useSelector((state) => state.teams);

  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRecord, setCurrentRecord] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  // const [role,setRole] = useState('Merchant')
  const [role, setRole] = useState("Admin");
  const userRole = session && session.role ? session.role.toLowerCase() : "";
  const teamDetail = session && session.teamDetail ? session.teamDetail : null;
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [teamIdError, setTeamIdError] = useState("");
  const [okBtnText, setOkBtnText] = useState("");
  const [showAddAlert, setShowAddAlert] = useState(false);
  const [addAlertText, setAddAlertText] = useState("");
  const [addAlertType, setAddAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [searchedText, setSearchedText] = useState("");
  const [loadingState, setLoadingState] = useState(false);
  const [reloadingState, setReLoadingState] = useState(false);

  useEffect(() => {
    if (showAddAlert) {
      setTimeout(() => {
        setShowAddAlert(false);
      }, SECONDS);
    }
  }, [showAddAlert]);

  useEffect(() => {
    dispatch(setPage("All Users"));
  }, [dispatch]);
  useEffect(() => {
    setSearchedText("")
  }, [users])
  const getData = async (params = {}, isLoading = true) => {
    if (params?.search !== "" && searchedText) {
      params.search = searchedText;
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const res = await dispatch(getAllTeams(params));
    if (res) {
      setLoadingState(false);
    }
  };
  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      getData({ page: 1, perPage: 100 }, false);
    };
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getAllUsers());
      if (res) {
        setLoadingState(false);
      }
    };
    getCall();
  }, [dispatch]);

  useEffect(() => {
    if (searchedText === "") {
      dispatch(updateUserBySearch([]));
    }
  }, [searchedText, dispatch]);

  const renderTag = () => {
    return (
      <form className="userForm" onSubmit={handleSubmit}>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <label
                className={currentAction === "View Details" ? "label-grey" : ""}
              >
                {currentAction !== "View Details" && (
                  <span className="spanReq">*</span>
                )}{" "}
                First Name
              </label>

              <Input
                placeholder="First Name"
                value={firstName}
                className="mt-5"
                onChange={handleFirstName}
                disabled={currentAction === "View Details"}
              />
              <span className="errorLabel">{firstNameError}</span>
            </div>
          </Col>

          <Col className="gutter-row" span={12}>
            <div>
              <label
                className={currentAction === "View Details" ? "label-grey" : ""}
              >
                {currentAction !== "View Details" && (
                  <span className="spanReq">*</span>
                )}{" "}
                Last Name
              </label>
              <Input
                placeholder="Last Name"
                value={lastName}
                className="mt-5"
                onChange={handleLastName}
                disabled={currentAction === "View Details"}
              />
              <span className="errorLabel">{lastNameError}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label
                className={currentAction === "View Details" ? "label-grey" : ""}
              >
                {currentAction !== "View Details" && (
                  <span className="spanReq">*</span>
                )}{" "}
                Email
              </label>
              <Input
                placeholder="Email"
                value={email}
                className="mt-5"
                onChange={handleEmail}
                disabled={currentAction !== "Add User"}
              />
              <span className="errorLabel">{emailError}</span>
            </div>
          </Col>
        </Row>

        <span className="passwordSpan">Role</span>
        <div>
          <Radio.Group
            onChange={onRoleChange}
            value={role.toLowerCase()}
            disabled={currentAction === "View Details"}
          >
            {/* <Radio value={'Merchant'}>Merchant</Radio> */}
            <Radio value={"admin"}>Admin</Radio>
            <Radio value={"basic"}>Basic User</Radio>
            <Radio value={"approver"}>Approver</Radio>
          </Radio.Group>
        </div>

        {role.toLowerCase() !== "admin" && (
          <>
            {" "}
            <label
              className="passwordSpan"
              style={{
                marginTop: "10px",
              }}
            >
              {role === "Approver" && <span className="spanReq">*</span>} Teams
            </label>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Select team"
                onChange={handleUserChange}
                options={teams.filter((i) => i.status === 1)}
              />
            </Space>
            <span className="errorLabel">{teamIdError}</span>
          </>
        )}

        {role.toLowerCase() !== "admin" && (
          <>
            <span
              className="passwordSpan"
              style={{
                marginTop: "10px",
              }}
            >
              Permissions
            </span>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Select permissions"
                onChange={handlePermissionChange}
                options={USER_PERMISSIONS}
              />
            </Space>
          </>
        )}
      </form>
    );
  };

  const handleUserChange = (value) => {
    setTeamIdError("");
    setSelectedTeamId(value);
  };

  const handlePermissionChange = (value) => {
    setPermissions(value);
  };

  const handleAdd = () => {
    setModalOpen(true);
    setCurrentAction("Add User");
    setOkBtnText("Add User");
  };
  const handleCancel = () => {
    setModalOpen(false);
    setCurrentAction("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setOkBtnText("");
    setCurrentRecord({
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    });
  };

  const onRoleChange = (e) => {
    setTeamIdError("");
    setRole(e.target.value);
  };

  const handleFirstName = (e) => {
    const { value } = e.target;
    setFirstNameError(
      Validator.validate("alphanumeric", value, null, null, true)
    );
    setFirstName(value);
  };

  const handleLastName = (e) => {
    const { value } = e.target;
    setLastNameError(
      Validator.validate("alphanumeric", value, null, null, true)
    );
    setLastName(value);
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmailError(Validator.validate("email", value, null, null, true));
    setEmail(value.toLowerCase());
  };

  const resetValues = () => {
    setModalOpen(false);
    setCurrentAction("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setOkBtnText("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newRecord = {};

    if (currentAction === "Add User") {
      if (
        firstNameError !== "" ||
        lastNameError !== "" ||
        emailError !== "" ||
        firstName === "" ||
        lastName === "" ||
        email === ""
      ) {
        setFirstNameError(firstName === "" ? FIELD_REQUIRED : firstNameError);
        setLastNameError(lastName === "" ? FIELD_REQUIRED : lastNameError);
        setEmailError(email === "" ? FIELD_REQUIRED : emailError);
        return;
      }
      if (role.toLowerCase() === "approver" && !selectedTeamId) {
        setTeamIdError(FIELD_REQUIRED);
        return;
      }

      setLoading(true);

      newRecord = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role.toLowerCase() === "admin" ? "Admin" : role,
        access: role.toLowerCase() === "admin" ? "Full" : "Edit Only",
        active: true,
        permissions:
          permissions.length && role.toLowerCase() !== "admin"
            ? permissions
            : [],
      };

      if (role.toLowerCase() !== "admin" && selectedTeamId) {
        newRecord.teamId = selectedTeamId;
      }

      const response = await dispatch(createUser(newRecord));

      if (response.error === undefined) {
        setLoading(false);
        setShowAddAlert(true);
        setAddAlertText(TextMessage.USER_CREATE_TEXT);
        setAddAlertType("success");
        resetValues();
      } else {
        setLoading(false);
        setShowAddAlert(true);
        setAddAlertText(TextMessage.ERROR_TEXT);
        setAddAlertType("error");
        resetValues();
      }
    }
  };

  const handleAddAlertClose = () => {
    setShowAddAlert(false);
    setCurrentAction("");
  };

  const onSearch = async (value) => {
    const searchedUser = users.filter((item) =>
      item?.fullName?.toLowerCase().trim().includes(value.toLowerCase().trim())
    );
    dispatch(updateUserBySearch(searchedUser));
  };

  const handleReload = async () => {
    setReLoadingState(true);
    dispatch(updateUserBySearch([]));
    const res = await dispatch(getAllUsers());
    if (res) {
      setReLoadingState(false);
    }
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value);
  };

  return (
    <CommonLayout className="content-mr" breadCrumbText="Manage Users">
      {loadingState || reloadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="addUserCard">
            <div className="user-right-content">
              <Search
                placeholder="Search By User Name"
                enterButton
                allowClear
                onSearch={onSearch}
                className="userErrorBtn"
                onChange={handleSearchChange}
                value={searchedText}
              />
            </div>

            <div className="user-left-content">
              <Button
                type="primary"
                icon={<UserAddOutlined />}
                className="addUserBtn"
                onClick={handleAdd}
                disabled={teamDetail?.isVCM}
              >
                Add User
              </Button>
            </div>
          </div>

          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="user-table-card">
              <div className="user-table-topbar">
                <div className="btnGrpWrapper">
                  <NavLink
                    end
                    to={"/users"}
                    className={({ isActive }) =>
                      isActive ? "btnStyle" : "btnStyle1"
                    }
                  >
                    All
                  </NavLink>
                  <NavLink
                    end
                    to={"/users/admin"}
                    className={({ isActive }) =>
                      isActive ? "btnStyle" : "btnStyle1"
                    }
                  >
                    Admin
                  </NavLink>
                  <NavLink
                    end
                    to={"/users/basic"}
                    className={({ isActive }) =>
                      isActive ? "btnStyle" : "btnStyle1"
                    }
                  >
                    Basic
                  </NavLink>
                  <NavLink
                    end
                    to={"/users/approver"}
                    className={({ isActive }) =>
                      isActive ? "btnStyle" : "btnStyle1"
                    }
                  >
                    Approver
                  </NavLink>
                </div>

                <div className="sharedFilterGrpWrapper">
                  <div className="settingGrp">
                    <ReloadOutlined
                      onClick={handleReload}
                      className="reloadIconStyle"
                    />
                    <ColumnHeightOutlined
                      className="reloadIconStyle"
                      onClick={handleTableHeight}
                    />
                    <FullscreenOutlined
                      onClick={() => {
                        setIsEnter(true);
                      }}
                      className="reloadIconStyle"
                    />
                  </div>
                </div>
              </div>

              <Outlet context={tableSize} />

              {/* modal starts */}

              <Modal
                visible={modalOpen}
                title={currentAction}
                onOk={handleSubmit}
                onCancel={handleCancel}
                okText={okBtnText}
                destroyOnClose
                footer={
                  currentAction === "Add User" ||
                    currentAction === "Update User"
                    ? [
                      <Button onClick={handleCancel}>Cancel</Button>,
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        className="footerBtnNavy"
                        disabled={loading}
                      >
                        {loading ? `Loading...` : okBtnText}
                      </Button>,
                    ]
                    : null
                }
              >
                {currentAction === "Add User" ||
                  currentAction === "Update User" ||
                  currentAction === "View Details" ? (
                  renderTag(currentRecord)
                ) : (
                  <></>
                )}
              </Modal>
              {showAddAlert && (
                <Alert
                  message={addAlertText}
                  type={addAlertType}
                  showIcon
                  closable
                  onClose={handleAddAlertClose}
                  className="alertStyle"
                />
              )}
            </div>
          </Fullscreen>
        </>
      )}
    </CommonLayout>
  );
};

export default SharedUserLayout;
