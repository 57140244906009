import logger from "common/logger";
import { HttpService } from "services/network/http.service";

const serializeParams = (params: any) => {
  const queryParams = Object.keys(params).reduce((acc, key) => {
    const value = params[key];
    if (value !== undefined) {
      if (typeof value === "object" && value !== null) {
        // Handle nested objects like filters
        Object.keys(value).forEach((nestedKey) => {
          acc.push(
            `${encodeURIComponent(`${key}[${nestedKey}]`)}=${encodeURIComponent(
              value[nestedKey]
            )}`
          );
        });
      } else {
        acc.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    }
    return acc;
  }, [] as string[]);

  return queryParams.join("&");
};

export default class PromoService extends HttpService {
  async getTimeline(promoId: string) {
    try {
      const response = await this.get(`/api/timeline/${promoId}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async getLoadPromos(params: any) {
    try {
      const queryString = serializeParams(params);
      const response = await this.get(`/api/promo/templates?${queryString}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async deleteTemplate(val: any) {
    try {
      const response = await this.delete(
        `/api/promo/${val.promoId}/template/${val.templateId}`
      );
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async loadCSVTemplate(promos: any) {
    try {
      const payload = { promos: promos };
      const response = await this.post(`/api/promo/load`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async loadXMLTemplate(promos: any) {
    try {
      const payload = { promos: promos };
      const response = await this.post(`/api/promo/loadXML`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  
  async exportTemplate(val: any) {
    try {
      const response = await this.get(
        `/api/promo/${val.promoId}/template/${val.templateId
        }/download/${val.templateType.toLowerCase()}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      console.error("Error exporting template:", error);
      throw error;
    }
  }
  async getSKUListData(listType: any) {
    try {
      let url = '';
      switch (listType.toLowerCase()) {
        case 'department':
          url = `/api/sku/Department?sortBy=department_id&orderBy=ASC`;
          break;
        case 'class':
          url = `/api/sku/Class?sortBy=class_id&orderBy=ASC`;
          break;
        case 'subclass':
          url = `/api/sku/subclass?sortBy=sub_class_id&orderBy=ASC`;
          break;
        default:
          url = `/api/sku/${listType}`;
      }
      const response = await this.get(url);
      return response;
    } catch (error) {
      console.error("Error exporting template:", error);
      throw error;
    }
  }

  async updatePromoStatus(promoId: string, status: string) {
    try {
      const response = await this.put(`/api/promo/${promoId}`, {
        status,
      });
      return response;
    } catch (error) {
      console.error("Error updating promo status:", error);
      throw error;
    }
  }
}
