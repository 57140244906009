import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/promoTypes/action-types'
import PromoTypeStateManager    from './state-manager';

const INITIAL_STATE = {
  promoTypes: [],
  searchedPromoTypes:[],
  promoTypeQuestions:[]
};

export default function PromoTypesStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_LOCAL_PROMO_TYPES_JSON:
                return { ...state, promoTypes: [ ...action.promoTypes ] };
        case S(ActionTypes.GET_ALL_PROMO_TYPES):
                return PromoTypeStateManager.getAllPromoTypes(state,action);
        case S(ActionTypes.CREATE_PROMO_TYPES):
                return PromoTypeStateManager.createPromoType(state,action);
        case S(ActionTypes.UPDATE_PROMO_TYPES):
                return PromoTypeStateManager.updatePromoType(state,action);
        case S(ActionTypes.DELETE_PROMO_TYPES):
                return PromoTypeStateManager.deletePromoType(state,action);
        case S(ActionTypes.UPDATE_PROMO_TYPE_STATUS):
                return PromoTypeStateManager.updatePromoTypeStatus(state,action);
        
        case S(ActionTypes.LIST_QUESTION_UNPUBLISHED):
                return PromoTypeStateManager.getQuestionUnPublished(state,action);
        case S(ActionTypes.GET_PROMO_TYPE_BY_ID):
                return PromoTypeStateManager.getPromoTypeById(state, action)
        case (ActionTypes.UPDATE_PROMO_TYPE_BY_NAME):
                return PromoTypeStateManager.updatePromoTypeBySearch(state,action);
        
        default:
            return state;
    }
}