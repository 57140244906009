import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Step1 } from "./create";
import { formatQuery } from "react-querybuilder";
import unionBy from "lodash/unionBy";
import session from "utils/session";
import { ReportVisibility } from "common/enum";

const { Input, Switch, Checkbox, Button, Modal, Radio } = require("antd");
const Step3 = forwardRef(
  (
    {
      query,
      allFields = [],
      selectedFields: initialListedFields = [],
      prevReportDetails = {},
    },
    ref
  ) => {
    const { isAdmin } = session;
    const [formData, setFormData] = useState({
      status: prevReportDetails?.status ?? ReportVisibility.PRIVATE,
      activate: prevReportDetails?.activate ?? false,
      name: prevReportDetails?.title ?? "",
    });

    const [formError, setFormError] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [listedFields, setListedFields] = useState(initialListedFields);
    const [selectedFields, setSelectedFields] = useState(
      initialListedFields || []
    );
    const [modalSelectedFields, setModalSelectedFields] = useState([]);

    const remainingFields = useMemo(
      () =>
        allFields.filter(
          (item) =>
            !listedFields.some((listedItem) => listedItem.name === item?.name)
        ),
      [listedFields, allFields]
    );

    useEffect(() => {
      if (prevReportDetails?.projections) {
        const prevProjections = prevReportDetails?.projections ?? [];
        const projectionMap = new Map(
          prevProjections.map((item, index) => [item.name, index])
        );

        const sortedListedFields = unionBy(
          prevProjections,
          listedFields,
          "name"
        ).sort((a, b) => {
          const indexA = projectionMap.has(a.name)
            ? projectionMap.get(a.name)
            : Infinity;
          const indexB = projectionMap.has(b.name)
            ? projectionMap.get(b.name)
            : Infinity;
          return indexA - indexB;
        });

        setSelectedFields(prevProjections);
        setListedFields(sortedListedFields);
      }
    }, [prevReportDetails]);

    const handleFormDataChange = (key, value) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
      if (!value) {
        setFormError({
          ...formError,
          [key]: "This field is required",
        });
      }
    };

    const onCheckClick = (e, field) => {
      if (e.target.checked) {
        setSelectedFields((prev) => {
          const newSelectedFields = [...prev, field];
          // Maintain the order as per listedFields
          return newSelectedFields.sort(
            (a, b) =>
              listedFields.findIndex((item) => item.name === a.name) -
              listedFields.findIndex((item) => item.name === b.name)
          );
        });
      } else {
        setSelectedFields((prev) =>
          prev.filter((item) => field.name !== item.name)
        );
      }
    };

    const selectAllClicked = () => {
      setSelectedFields(listedFields);
    };

    const addMoreClick = () => {
      setModalOpen(true);
    };

    const handleModalClose = () => {
      setModalSelectedFields([]);
      setModalOpen(false);
    };

    const onCheckboxClick = (checked, field) => {
      if (checked) {
        setModalSelectedFields((prev) => [...prev, field]);
      } else {
        setModalSelectedFields((prev) =>
          prev.filter((item) => field.name !== item.name)
        );
      }
    };

    const addClick = () => {
      setListedFields((prev) => [...prev, ...modalSelectedFields]);
      setSelectedFields((prev) => [...prev, ...modalSelectedFields]);
      handleModalClose();
    };

    useImperativeHandle(ref, () => ({
      isValid() {
        if (!formData.name) {
          setFormError((prev) => ({
            ...prev,
            name: "Please enter report name",
          }));
          return false;
        } else {
          setFormError((prev) => ({
            ...prev,
            name: undefined,
          }));
        }
        if (!selectedFields.length) {
          setFormError((prev) => ({
            ...prev,
            selectedFields: "Please select atleast one field",
          }));
          return false;
        } else {
          setFormError((prev) => ({
            ...prev,
            selectedFields: undefined,
          }));
        }
        return true;
      },
      getData() {
        return {
          title: formData.name,
          status: formData.status,
          activate: formData.activate,
          filters: JSON.parse(formatQuery(query, "mongodb")),
          rules: query,
          projections: selectedFields,
          uiFilters: initialListedFields,
        };
      },
    }));

    const handleDragStart = (e, startIndex) => {
      e.dataTransfer.setData("startIndex", startIndex);
    };

    const handleDrop = (e, endIndex) => {
      const startIndex = parseInt(e.dataTransfer.getData("startIndex"), 10);
      if (startIndex === null || startIndex === endIndex) return;

      const updatedListedFields = [...listedFields];
      const [draggedItem] = updatedListedFields.splice(startIndex, 1);
      updatedListedFields.splice(endIndex, 0, draggedItem);
      setListedFields(updatedListedFields);

      const selectedFieldNames = selectedFields.map((field) => field.name);
      const updatedSelectedFields = updatedListedFields.filter((item) =>
        selectedFieldNames.includes(item.name)
      );
      setSelectedFields(updatedSelectedFields);

      e.dataTransfer.clearData();
    };

    return (
      <>
        <div className="step3 h-full">
          <div className="flex step3_input">
            <div className="input-frame">
              <div className="d-label">
                <label className="input-label">Report Name</label>
              </div>
              <Input
                className="input-text-field"
                onChange={(e) => handleFormDataChange("name", e.target.value)}
                placeholder="Enter the report name"
                value={formData.name}
              />
              <span className="error-label">{formError.name}</span>
            </div>
            <div className="switch">
              <label className="">Activate</label>
              <Switch
                checked={formData?.activate}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    activate: e || !formData?.activate,
                  }));
                }}
              />
            </div>
          </div>
          <div className="step3-fields">
            <div className="step3-select-all">
              <div>Fields</div>
              <Button type="link" onClick={selectAllClicked}>
                Select All
              </Button>
            </div>
            <div className="fields-grid">
              {listedFields.map((item, i) => (
                <div
                  key={item.label}
                  className="field-item"
                  draggable
                  onDragStart={(e) => handleDragStart(e, i)}
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={(e) => handleDrop(e, i)}
                >
                  <Checkbox
                    className="check-style checkbox"
                    onChange={(e) => onCheckClick(e, item)}
                    checked={selectedFields?.some(
                      (field) => field?.name === item?.name
                    )}
                  >
                    <div className="field-name">{item?.label}</div>
                  </Checkbox>
                </div>
              ))}
            </div>
            <span className="error-label">{formError.selectedFields}</span>
            <div className="flex-end flex">
              <Button
                type="primary"
                className="footerBtnNavy"
                onClick={addMoreClick}
              >
                {"Add More"}
              </Button>
            </div>
          </div>
          <div className="status-field">
            <div>
              <label className="input-label">
                Would you prefer to make the report private or public?
              </label>
            </div>
            <div
              title={
                isAdmin ? undefined : "Only Admin can create public reports"
              }
            >
              <Radio.Group
                value={formData.status}
                disabled={!isAdmin}
                onChange={(e) => {
                  setFormData((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }));
                }}
              >
                {Object.values(ReportVisibility)?.map((item) => (
                  <Radio value={item} key={item}>
                    {item}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        </div>
        <Modal
          open={modalOpen}
          title={"Add Fields"}
          className="add-more-modal"
          onCancel={handleModalClose}
          footer={[
            <Button key="Cancel Button" onClick={handleModalClose}>
              Cancel
            </Button>,
            <Button
              key="Add Button"
              type="primary"
              className="footerBtnNavy"
              onClick={addClick}
            >
              Add
            </Button>,
          ]}
          destroyOnClose
        >
          <Step1
            fields={remainingFields}
            onCheckboxClick={onCheckboxClick}
            selectedFields={modalSelectedFields}
          />
        </Modal>
      </>
    );
  }
);
export default Step3;
