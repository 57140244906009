import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import Element from "../Element";
import { sendSub1, sendSubInput, sendSubInputThree } from "actions/promotions";
const { Option } = Select;

const DropDownEle = (props) => {
  const [value, setValue] = useState(props.ans ? props.ans : null);
  const dispatch = useDispatch();
  const [obj, setObj] = useState(null);
  const [subOption, setSubOption] = useState(null);
  useEffect(() => {
    const value = props.ans;
    if (value) {
      const object1 = props.options?.find((item) =>
        (item?.optionText || item?.subOptionText)
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      );

      if (object1?.subOptions?.length) {
        setSubOption(true);
        setObj(object1?.subOptions);
        if (
          (props.action === "edit" || props.action === "view") &&
          props.SubOptionInput
        ) {
          dispatch(
            sendSubInput(
              props?.SubOptionInput[0]?.value,
              "subinput",
              props.id,
              props.SubOptionInput[0]?.label || ""
            )
          );
        } else if (
          (props.action === "edit" || props.action === "view") &&
          props.SubOptionInputThree
        ) {
          dispatch(
            sendSubInputThree(
              props?.SubOptionInputThree[0]?.value,
              "subinput",
              props.id,
              props.SubOptionInputThree[0].label || ""
            )
          );
        } else if (
          (props.action === "edit" || props.action === "view") &&
          props.subOptions &&
          props.subOptions[0]?.subOptionType === "input"
        ) {
          dispatch(
            sendSub1(
              props?.subOptions[0]?.value,
              "subinput",
              props.id,
              props?.subOptions[0]?.subOptionText || "",
              props?.subOptions[0]?.masterDataType || ""
            )
          );
        } else if (
          (props.action === "edit" || props.action === "view") &&
          props.subOptions &&
          props.subOptions[0]?.subOptionType === "radio"
        ) {
          dispatch(
            sendSub1(
              props?.subOptions[0]?.value,
              "subradio",
              props.id,
              props?.subOptions[0]?.subOptionText || "",
              props?.subOptions[0]?.masterDataType || ""
            )
          );
        }
      } else {
        setSubOption(false);
        dispatch(sendSub1(null, null, props.id));
        dispatch(sendSubInput(null, null, props.id));
        dispatch(sendSubInputThree(null, null, props.id));
        setObj(false);
      }
      return;
    }
  }, [props.ans, props.options, props.SubOptionInput, props.SubOptionInputThree, props.subOptions, props.action, props.id, dispatch]);

  const handleSubChange = (e, data, index) => {
    obj[index].value = e;
    // obj ? obj[0]?.subOptions[0]?.value = e : "";
    dispatch(sendSub1(e, data.id, props?.id, obj[index]?.subOptionText || ""));
  };

  // const handleSubChangeInput = (value, id) => {
  //   dispatch(sendSubInput(value, id, props?.id, obj[1]?.subOptionText || ''));
  // };

  // const handleSubChangeInputThree = (value, id) => {
  //   dispatch(sendSubInputThree(value, id, props?.id, obj[2]?.subOptionText || ''));
  // };

  const onChange = (e) => {
    setValue(e);
    props.isPromoTypeQuestion ? props.handlePromoTypeQuestionChange(e, props?.id, props?.pqid) : props.onChange(e, props?.id);

    const object1 = props.options?.find((item) =>
      (item?.optionText || item?.subOptionText)
        .toLowerCase()
        .trim()
        .includes(e.toLowerCase().trim())
    );

    if (object1?.subOptions?.length) {
      setSubOption(true);
      setObj(object1?.subOptions);
      if (object1?.subOptions?.length === 1) {
        dispatch(sendSubInput(null, null, props?.id));
        dispatch(sendSubInputThree(null, null, props?.id));
      }
      if (object1?.subOptions?.length === 2) {
        dispatch(sendSubInput(null, null, props?.id));
        dispatch(sendSubInputThree(null, null, props?.id));
      }
    } else {
      setSubOption(false);
      dispatch(sendSub1(null, null, props?.id));
      dispatch(sendSubInput(null, null, props?.id));
      dispatch(sendSubInputThree(null, null, props?.id));
      setObj(false);
    }

  };
  return (
    <>
      <div className="input-frame">
        <label className="input-label">
          {props.designate && props.designate === "required" ? (
            <span className="spanReq">*</span>
          ) : (
            ""
          )}
          {props.label}
        </label>
        {props.action === "create" && (
          <div className="select-dropdown">
            <Select
              className="select-dropdown"
              onChange={onChange}
              value={value}
              placeholder="Select"
            >
              {props.options?.map((item) => (
                <Option key={item.optionText || item.subOptionText}>
                  {item.option || item.subOptionText}
                </Option>
              ))}
            </Select>
            <span className="error-label">{props.error}</span>
          </div>
        )}

        {props.action === "edit" && (
          <div className="select-dropdown">
            <Select
              className="select-dropdown"
              onChange={onChange}
              value={value}
              placeholder="Select"
            >
              {props.options?.map((item) => (
                <Option key={item.optionText || item.subOptionText}>
                  {item.option || item.subOptionText}
                </Option>
              ))}
            </Select>
            <span className="error-label">{props.error}</span>
          </div>
        )}
        {props.action === "view" && (
          <div className="select-dropdown">
            <Select className="select-dropdown" value={props.ans} disabled>
              {props.options?.map((item) => (
                <Option key={item.optionText}>{item.option}</Option>
              ))}
            </Select>
          </div>
        )}
      </div>
      {subOption &&
        props.action === "create" &&
        obj?.map((subOptions, index) => (
          <Element
            field={subOptions.subOptionType}
            label={subOptions.subOptionText}
            options={subOptions.subOptions}
            onChange={(e, f) => handleSubChange(e, f, index)}
            id={index}
            details={subOptions}
            action={props.action}
            isRegexRequired={subOptions.isRegexRequired}
            regex={subOptions.regex}
          />
        ))}

      {subOption &&
        props.action === "view" &&
        obj?.map((item) => (
          <Element
            ans={item?.value}
            field={item?.subOptionType}
            label={item?.subOptionText}
            options={item?.subOptions}
            action={props.action}
            onChange={handleSubChange}
            id={"sub" + item?.subOptionType}
          />
        ))}

      {subOption &&
        props.action === "edit" &&
        obj?.map((item, index) => (
          <Element
            key={index}
            ans={item && item?.value}
            field={item?.subOptionType}
            label={item?.subOptionText}
            options={item?.subOptions}
            action={props.action}
            onChange={(e, data) => handleSubChange(e, props, index)}
            id={"sub" + item?.subbOptionType}
            isRegexRequired={item && item?.isRegexRequired}
            details={item}
            error={props.error}
            designate={props.designate}
            regex={item && item?.regex}
          />
        ))}
    </>
  );
};

export default DropDownEle;
