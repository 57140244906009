import { ExclamationCircleOutlined } from "@ant-design/icons";
import { changeStatus, mapCreateMessage, viewMap } from "actions/map";
import {  Button, Col, Input, Modal, Row, Spin, Table, Tag } from "antd";
import CommonLayout from "components/layout/Layout";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { FIELD_REQUIRED, SECONDS, TextMessage } from "utils/constants";
import session from "utils/session";
import Element from "./Element";

const { TextArea } = Input;

const MapView = () => {
    const {id} = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const viewMapDetails = useSelector(state => state.maps.viewMap)
    const [loadingState,setLoadingState] = useState(false)
    const [skuTableColumn,setSkuTableColumn] = useState([])
    const [brandTableColumn,setBrandTableColumn] = useState([])
    const [showApproveAlert,setShowApproveAlert] = useState('')
    const [showRejectAlert,setShowRejectAlert] = useState('')
    const [modalOpen,setModalOpen] = useState(false)
    const [rejectReason,setRejectReason] = useState('')
    const [rejectReasonError,setRejectReasonError] = useState('')
    const [loading,setLoading] = useState(false)
    const isAdmin = session && session.role && session.role.toLowerCase() === "admin";

    useEffect(() => {
        const getCall = async() =>{
        setLoadingState(true)
        const res = await dispatch(viewMap(id))
        if(res){
          setLoadingState(false)
        }
      }
      getCall()
    },[dispatch,id])

     useEffect(() => {
      if(showApproveAlert){
        setTimeout(() => {
          setShowApproveAlert(false)
        }, SECONDS);
      }
      if(showRejectAlert){
        setTimeout(() => {
          setShowRejectAlert(false)
        }, SECONDS);
      }
      
    },[showApproveAlert,showRejectAlert])

    useEffect(() => {
        if(viewMapDetails && viewMapDetails.length>0 && viewMapDetails[0].mapType === 'sku'){
            const columnsData = viewMapDetails[0].dynamicRecord[0]?.fieldData?.map((item,i) => {
            return {
                title: item.questionHeader,
                dataIndex: item.questionHeader,
                key: item.questionHeader,
                }
            })

            const columnsSystemData = viewMapDetails[0].dynamicRecord[0]?.fieldSysData?.map((item,i) => {
                return {
                title: item.label,
                dataIndex: item.label,
                key: item.label,
                }
            })

            setSkuTableColumn([{
                title: 'SKU',
                dataIndex: 'sku',
                key: 'sku',
                },...columnsData,
                ...columnsSystemData,
                {
                title: 'MAP Price Update',
                dataIndex: 'mapPriceUpdate',
                key: 'mapPriceUpdate',
                },
                {
                title: 'MAP Price Update',
                dataIndex: 'mapPriceLoad',
                key: 'mapPriceLoad',
                },
            ])
        }

            if(viewMapDetails && viewMapDetails.length>0 && viewMapDetails[0].mapType === 'brand'){
            const columnsData = viewMapDetails[0].dynamicRecord[0]?.fieldData?.map((item,i) => {
                return {
                title: item.questionHeader,
                dataIndex: item.questionHeader,
                key: item.questionHeader,
                }
            })

            setBrandTableColumn([{
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            },...columnsData,])
        }

    },[viewMapDetails])


    const generateSkuRows = () => {
        const rows = [];
        const dynamicRecord  = viewMapDetails && viewMapDetails[0]?.dynamicRecord

        dynamicRecord.forEach((rec,i) => {
        let obj = {}
        obj = {
          'key': i,
          'sku': rec.sku,
        }

        rec.fieldData.forEach((fd,i) => {
          const obj1 = {
            [fd.questionHeader] : <Element
                                    key={i}
                                    question={fd?.question_id}
                                    index={i}
                                    questionType={fd?.question_id?.questionType}
                                    questionHeader={fd?.question_id?.questionHeader}
                                    options={fd?.question_id?.optionValue}
                                    designate={fd?.question_id?.designate}
                                    action={'view'}
                                    answer={fd.value || ''}
                                    subAnswer={fd.subAnswer || ''}
                                    subAnswerQuestionType={fd.subAnswerQuestionType || ''}
                                />,
          }

          obj = { ...obj, ...obj1}
        })

        rec?.fieldSysData.forEach(fd => {
          const obj2 = {
            [fd.label] : fd.value
          }

          obj = {...obj,...obj2}
        })

        const lastObj = {
          'mapPriceUpdate': rec.mapPriceUpdate || '-',
          'mapPriceLoad': rec.mapPriceLoad || '-',
        }

        obj = {...obj, ...lastObj}


        rows.push(obj)
      })
        
        return rows;
    }

    const generateBrandRows = () => {
        const rows = [];
        const dynamicRecord  = viewMapDetails && viewMapDetails[0]?.dynamicRecord

        dynamicRecord?.forEach((item,i) => {
            let obj = {}
            obj ={
                'brand': item.brand,
                key: i
            }

            item?.fieldData?.forEach((fd,i) => {
                obj = {
                    ...obj,
                    [fd.questionHeader] : <Element
                                    key={i}
                                    question={fd?.question_id}
                                    index={i}
                                    questionType={fd?.question_id?.questionType}
                                    questionHeader={fd?.question_id?.questionHeader}
                                    options={fd?.question_id?.optionValue}
                                    designate={fd?.question_id?.designate}
                                    action={'view'}
                                    answer={fd.value || ''}
                                    subAnswer={fd.subAnswer || ''}
                                    subAnswerQuestionType={fd.subAnswerQuestionType || ''}
                                />
                }
            })
            

            rows.push(obj)
        })
        
        return rows;
    }

    const handleApprove = () => {
        Modal.confirm({
            title: TextMessage.MAP_APPROVAL_CONFIRMATION_TEXT,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () =>handleApproveYes(),
            okType:'primary',
        });
    }

    const handleApproveYes = async() => {
        setLoading(true)
        const res = await dispatch(changeStatus(id,'approved'))
        if(res.error === undefined){
            await dispatch(viewMap(id))
            dispatch(mapCreateMessage(TextMessage.MAP_APPROVED_TEXT))
            setLoading(false)
            window.scrollTo(0, 0);
            navigate('/map')
        }
    }

    const handleRejectModal = () => {
        setModalOpen(true)
    }

    const handleModalCancel = () => {
        setModalOpen(false)
        setRejectReason('')
        setRejectReasonError('')
    }

    const handleRejectReason = (e) => {
        const { value } = e.target;
        setRejectReasonError(value === '' ? FIELD_REQUIRED : '')
        setRejectReason(value)
    }

    const handleReject = async() => {
        if(rejectReason === '' || rejectReasonError !== ''){
            setRejectReasonError(rejectReason === '' ? FIELD_REQUIRED : '')
            return;
        }
        setLoading(true)
        const res = await dispatch(changeStatus(id,'rejected',rejectReason))
        if(res.error === undefined){
            await dispatch(viewMap(id))
            dispatch(mapCreateMessage(TextMessage.MAP_DECLINED_TEXT))
            setLoading(false)
            setModalOpen(false)
            window.scrollTo(0, 0);
            setRejectReason('')
            setRejectReasonError('')
            navigate('/map')
        }else{
            await dispatch(viewMap(id))
            setLoading(false)
            setModalOpen(false)
            setRejectReason('')
            setRejectReasonError('')
        }
    }

    return(
        <CommonLayout className="content-mr" breadCrumbText='MAP View' backUrl={'/map'} backButton={true}>
            {
                loadingState  ? <div className="errorTipDiv">
              <Spin size='middle' tip='Loading...'/>
            </div>
            :
            
            <div className="map-create-card">

                <div className="d-flex-col">
                    <Row>
                        <Col>
                            <label className="f-w">MAP Type</label>
                            <div className="mt-5">
                                
                                <Input
                                    disabled
                                    value={viewMapDetails[0]?.mapType ? viewMapDetails[0]?.mapType.toLowerCase() === 'sku' ? viewMapDetails[0]?.mapType.toUpperCase() :  viewMapDetails[0]?.mapType.charAt(0).toUpperCase() + viewMapDetails[0]?.mapType.slice(1) : ''}
                                />
                            </div>
                        </Col>
                    </Row>
                    
                </div>

                <div className="d-flex-col mt-16">
                    <Row style={{width:'188px'}}> 
                        <Col>
                            <label className="f-w">Status</label>
                            <div className="mt-5">
                                <span>
                                {viewMapDetails[0]?.status.toLowerCase() === 'pending' ? 
                                    <Tag color={"red"}>{viewMapDetails[0]?.status.charAt(0).toUpperCase() + viewMapDetails[0]?.status.slice(1)}</Tag> : 
                                viewMapDetails[0]?.status.toLowerCase() === 'approved' ? 
                                    <Tag className="status-symbol-approved">{viewMapDetails[0]?.status.charAt(0).toUpperCase() + viewMapDetails[0]?.status.slice(1)}</Tag> 
                                    :  
                                    <Tag className="status-symbol-rejected">{viewMapDetails[0]?.status.charAt(0).toUpperCase() + viewMapDetails[0]?.status.slice(1)}</Tag>}
                            </span>
                            </div>
                        </Col>
                    </Row>
                </div>

                {
                    viewMapDetails[0]?.status.toLowerCase() === 'rejected' &&
                <div className="d-flex-col mt-16">
                    <Row>
                        <Col className="d-flex-col">
                            <label className="f-w">Reject Reason</label>
                            <span className="mt-5">
                                {viewMapDetails[0]?.rejectReason ? viewMapDetails[0].rejectReason : ''}
                            </span>
                        </Col>
                    </Row>
                    
                </div>
                }

                <div className="mt-30 d-flex">
                    <div className='w-100'>
                        <div className='w-100' style={{overflowX:'auto'}}>
                            <Table
                            columns={viewMapDetails && viewMapDetails[0]?.mapType === 'sku' ? skuTableColumn : brandTableColumn}
                            dataSource={viewMapDetails && viewMapDetails[0]?.mapType === 'sku' ? generateSkuRows() : generateBrandRows()}
                            pagination={false}
                            />
                        </div>

                        {
                         (isAdmin && viewMapDetails && viewMapDetails[0]?.status && viewMapDetails[0]?.status.toLowerCase() === 'pending') ? 
                           <>
                            <div className='mt-30 text-end'>
                            <Button  
                            disabled={loading}
                            onClick={handleRejectModal}
                            >Reject</Button>
                            <Button className='footerBtnNavy ml-10' 
                            disabled={loading}
                            onClick={handleApprove}
                            >Approve</Button>
                            </div>
                           </>
                         : ''
                        }

                        
                    </div>
                </div>

                <Modal title="Reject Reason" visible={modalOpen} 
                onOk={handleReject} 
                onCancel={handleModalCancel}
                destroyOnClose
                maskClosable={false}
                footer={[
                    <Button onClick={handleModalCancel} disabled={loading}>Cancel</Button>,
                    <Button
                      type="primary"
                      onClick={handleReject}
                      disabled={loading}
                      className="footerBtnNavy"
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>,
                  ]}
                >
                   <TextArea placeholder='Write Here' onChange={handleRejectReason} className='w-100' 
                    value={rejectReason} rows={5}/>
                    <span className='errorLabel'>{rejectReasonError}</span>
                </Modal>

            </div>

            }
        </CommonLayout>
    )
}

export default MapView;