import * as ActionTypes from "./action-types";

export const updateLocalPromoTypesJSON = (promoTypes) => ({
  type: ActionTypes.UPDATE_LOCAL_PROMO_TYPES_JSON,
  promoTypes,
});

export const getAllPromoTypes = () => ({
  type: ActionTypes.GET_ALL_PROMO_TYPES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType`,
      method: "get",
    },
  },
});
export const getPromoTypeById = (id) => ({
  type: ActionTypes.GET_PROMO_TYPE_BY_ID,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${id}`,
      method: "get",
    },
  },
});

export const createPromoType = (data) => ({
  type: ActionTypes.CREATE_PROMO_TYPES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType`,
      method: "post",
      data: {
        standardWorkflow: data.standardWorkflow,
        emergencyWorkflow: data.emergencyWorkflow,
        status: data.status,
        promoType: data.promoType,
        subtype: data.subType,
      },
    },
  },
});

export const updatePromoTypeById = (data) => ({
  type: ActionTypes.UPDATE_PROMO_TYPES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${data._id}`,
      method: "put",
      data: {
        standardWorkflow: data.standardWorkflow,
        emergencyWorkflow: data.emergencyWorkflow,
        status: data.status,
        promoType: data.promoType,
        subtype: data.subType,
      },
    },
  },
  meta: {
    data: {
      standardWorkflow: data.standardWorkflow.name,
      emergencyWorkflow: data.emergencyWorkflow.name,
      status: data.status,
      promoType: data.promoType,
      subType: data.promoSubType,
    },
  },
});

export const deletePromoTypeById = (promoTypeId) => ({
  type: ActionTypes.DELETE_PROMO_TYPES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${promoTypeId}`,
      method: "delete",
    },
  },
  meta: {
    _id: promoTypeId,
  },
});

export const updatePromoTypeStatus = (promoTypeId, status) => ({
  type: ActionTypes.UPDATE_PROMO_TYPE_STATUS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${promoTypeId}`,
      method: "patch",
      data: {
        status: status,
      },
    },
  },
  meta: {
    status: status,
    _id: promoTypeId,
  },
});

export const updatePromoTypeBySearch = (promoTypes) => ({
  type: ActionTypes.UPDATE_PROMO_TYPE_BY_NAME,
  promoTypes,
});

export const addQuestionToPormoType = (questions, id) => ({
  type: ActionTypes.ADD_QUESTION_PROMOTYPE,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${id}/question`,
      method: "post",
      data: {
        questionIds: questions,
      },
    },
  },
});

export const getQuestionUnPublished = (id) => ({
  type: ActionTypes.LIST_QUESTION_UNPUBLISHED,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${id}/question`,
      method: "get",
    },
  },
});

export const markQuestionPromoTypePublish = (id) => ({
  type: ActionTypes.MARK_PUBLISH,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${id}/question/published`,
      method: "patch",
    },
  },
});

export const markUnPublishQuestionPromoType = (questionId, id) => ({
  type: ActionTypes.REMOVE_QUESTION_PUBLISHED,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${id}/question/unpublished`,
      method: "patch",
      data: {
        quesId: questionId,
      },
    },
  },
});

export const deleteQuestionPromoType = (questionId, id) => ({
  type: ActionTypes.DELETE_QUESTION_PROMOTYPE,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/promoType/${id}/question`,
      method: "delete",
      data: {
        quesId: questionId,
      },
    },
  },
});
