import * as ActionTypes             from './action-types';

export const updateLocalMapFormBuilderJSON = (formBuilder) => ({
    type: ActionTypes.UPDATE_LOCAL_MAP_FORM_BUILDER_JSON,
    formBuilder
})

export const updateLocalMapSaveQuestionJSON = (questions) => ({
    type: ActionTypes.UPDATE_LOCAL_MAP_QUESTION_SAVE_JSON,
    questions
})


export const getMapFormBuilder = () => ({
    type: ActionTypes.GET_MAP_FORMBUILDER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/formBuilderMap`,
            method: "get",
        }
    }
});

export const createMapFormBuilder = () => ({
    type: ActionTypes.CREATE_MAP_FORMBUILDER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/formBuilderMap`,
            method: "post",
            data: {
                "name":"Map Form Builder",
                "status":"active"
            }
        }
    }
});

export const addQuestionToMapFormBuilder = (mapFormBuilderId,questionIds,questionType) => ({
    type: ActionTypes.ADD_QUESTION_TO_MAP_FORMBUILDER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/formBuilderMap/questions/add?mapId=${mapFormBuilderId}`,
            method: "patch",
            data: {
                "questionIds":questionIds,
                "questionType":questionType
            }
        }
    }
});

export const deleteQuestionFromMapFormBuilder = (mapFormBuilderId,questionId,questionType) => ({
    type: ActionTypes.DELETE_QUESTION_FROM_MAP_FORMBUILDER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/formBuilderMap/questions/delete?mapId=${mapFormBuilderId}`,
            method: "patch",
            data: {
                "quesId":questionId,
                "questionType":questionType
            }
        }
    }
});

export const deleteMapFormBuilder = (mapFormBuilderId) => ({
    type: ActionTypes.DELETE_MAP_FORMBUILDER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/formBuilderMap/${mapFormBuilderId}`,
            method: "delete",
        }
    }
});

export const changeMapQuestionOrder = (mapFormBuilderId,questionIds,questionType) => ({
    type: ActionTypes.CHANGE_MAP_QUESTION_ORDER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/formBuilderMap/questions/changeposition?mapId=${mapFormBuilderId}`,
            method: "patch",
            data: {
                "questionIds":questionIds,
                "questionType":questionType
            }
        }
    }
});