export default class MapStateManager{
    static getAllMaps = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        state.maps = data.map || [];
        state.mapStatus = data.mapStatus || [];

        return state;
    }

    static viewMap = (prevState, action) => {
        const state = { ...prevState };

        const data  = action.payload.data.data;
        
        state.viewMap = [data] || [];

        return state;
    }

    static getMapsBySearch = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.mapBySearch = data || [];

        return state;
    }

    static getMapsByStatus = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.mapByStatus = data.map || [];

        return state;
    }

  
    static deleteMap = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.maps = state.maps.filter((item) => item._id !== id)
        
        return state;
    }

}