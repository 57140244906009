import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
import { useDispatch } from "react-redux";
import { getPromoDD } from "actions/promotions/index";
export function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      showSearch
      value={ props.value?.name? props.value?.name : props.value?.label }
    />
  );
}

// Usage of DebounceSelect

const DropDownWithSearch = (props) => {
  const [value, setValue] = useState(props.value);
  const dispatch = useDispatch();

  async function fetchPromoList(search) {
    const res = await dispatch(getPromoDD(search));
    return (
      res.payload?.data?.data?.promoList.map((promo) => ({
        label: promo.name,
        value: promo._id,
      })) || []
    );
  }
  return (
    <div className="input-frame">
      {props.label ? (
        <label className="input-label">
          {props.required ? <span className="spanReq">*</span> : ""}{" "}
          {props.label}
        </label>
      ) : (
        <></>
      )}
      <DebounceSelect
        value={value}
        placeholder="Select Promo"
        fetchOptions={fetchPromoList}
        onChange={(newValue) => {
          setValue(newValue);
          props.onChange?.(newValue, props.id);
        }}
        style={{
          width: "100%",
        }}
        disabled={props.action === "view"}
      />
    </div>
  );
};

export default DropDownWithSearch;
