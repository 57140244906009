import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";

const ShortAnswerQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleQuestionDesignate,
  index,
  handleSaveQuestion,
}) => {
  return (
    <>
      {question.questionType === "input" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox
              onChange={(e) => handleQuestionDesignate(e, index)}
              checked={
                question.designate && question.designate === "optional"
                  ? false
                  : true
              }
            >
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}
      {question.questionType === "input" && (
        <div className="formBuilderFrameTwoDynamic"></div>
      )}

      {question.questionType === "input" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          <div className="formBuilderActionsWrapper">
            <Button
              icon={<CloseOutlined />}
              className="saveBtn"
              onClick={handleCancelEditView}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ShortAnswerQuestionView;
