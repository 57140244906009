import { useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { createPromo, updateStage } from "actions/promotions/index";
import { Drawer, Button, Alert } from "antd";
import "./createPromoNew.css";
import { QuestionStatus, TextMessage } from "utils/constants";
import StatusDrawer from "components/common/statusDrawer";
import Teams from "./teams";
import CreateStaticForm from "./CreateStaticForm";
import SaticQuestionForm from "./staticQuestionForm";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import ReactHtmlParser from 'react-html-parser';

const CreatePromo = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [stages, setStages] = useState([]);
  const [isDraft, setIsDraft] = useState(false);
  const [staticQuestions, setStaticQuestions] = useState([]);
  const [showCreateAlert, setShowCreateAlert] = useState(false);
  const [createText, setCreateText] = useState("");
  const teamsRef = useRef();
  const formRef = useRef();
  const staticQuestionsRef = useRef();
  const dispatch = useDispatch();
  const [currentSuccessMsg, setCurrentSuccessMsg] = useState();
  const { showAlert } = useAlert();
  const handleSubmit = async (e) => {
    const isFormValid = formRef.current.isValid();
    const isStaticQuestionValid = staticQuestionsRef.current.isValid();
    if (!isFormValid) {
      return;
    }
    if (!isStaticQuestionValid) {
      return;
    }
    if (e === QuestionStatus.Submitted) {
      const isTeamValid = teamsRef.current.isValid();
      if (!isTeamValid) {
        return;
      }
    }
    const payload = formRef.current.getFormData();
    payload.stages = teamsRef.current.getStagesData();
    payload.staticQuestions = staticQuestionsRef.current.getStaticQuestionData();
    if (e === QuestionStatus.Incomplete) {
      setLoadingSave(true);
      payload.isDraft = true;
      payload.actionTeam = stages[0].teamId;
      payload.actionTeamName = stages[0].name;
    } else if (e === QuestionStatus.Submitted) {
      setLoading(true);
      payload.actionTeam = stages[1] ? stages[1]?.teamId : stages[0].teamId;
      payload.actionTeamName = stages[1] ? stages[1]?.name : stages[0].name;
    }
    setLoadingSave(false);
    setLoading(false);
console.log(payload, "payload")
    const res = await dispatch(createPromo(payload));
    setLoading(false);
    setLoadingSave(false);
    if (!res.error) {
      if (e === QuestionStatus.Incomplete) {
        setIsDraft(true);
        setCreateText("Promo has saved in draft successfully");
      }
      setShowCreateAlert(true);
      if (e === QuestionStatus.Submitted) {
        await dispatch(
          updateStage(res.payload?.data?.data._id, {
            teamId: stages[0].teamId,
            status: QuestionStatus.Submitted,
          })
        );
      }
    } else {
      // showErrorMsg(res?.error?.response?.data?.message);
      const message = res?.error?.response?.data?.message;
      showAlert({
        message: ReactHtmlParser(message),
        variant: AlertVariant.ERROR
      });
    }
  };

  const updateStages = useCallback((stages) => {
    setStages(stages);
  }, []);

  const updateStaticQuestion = useCallback((questions) => {
    setStaticQuestions(questions);
  }, [])

  const showErrorMsg = useCallback((msg) => {
    setCurrentSuccessMsg({
      message: msg,
      type: "error",
    });
    setTimeout(() => {
      setCurrentSuccessMsg("");
    }, 6000);
  }, []);

  const nextTeamName = stages?.[1]?.name;
  return (
    <div>
      <Drawer
        title={`Create a New Offer`}
        width={640}
        height={1900}
        onClose={props.onClose}
        open={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          <div className="flex space-between w-full create-footer">
            <Button
              type="link"
              onClick={() => handleSubmit(QuestionStatus.Incomplete)}
              disabled={loading || loadingSave}
            >
              {loadingSave ? `Loading...` : "Save as draft"}
            </Button>
            <div>
              <Button
                onClick={() => props.onClose()}
                disabled={loading || loadingSave}
              >
                {loading ? `Loading...` : "Cancel"}
              </Button>
              <Button
                type="primary"
                onClick={() => handleSubmit(QuestionStatus.Submitted)}
                disabled={loading || loadingSave}
                className="next-btn"
              >
                {loading ? `Loading...` : "Submit"}
              </Button>
            </div>
          </div>
        }
      >
        {currentSuccessMsg && (
          <Alert
            message={currentSuccessMsg.message}
            closable
            type={currentSuccessMsg.type}
            showIcon
          />
        )}

        <CreateStaticForm
          ref={formRef}
          setStages={updateStages}
          errormsg={showErrorMsg}
          setStaticQuestions={updateStaticQuestion}
          type="create"
        />

        <div style={{ marginTop: '15px' }}>
          <SaticQuestionForm
            ref={staticQuestionsRef}
            questions={staticQuestions}
            action="create"
            errormsg={(msg) => {
              setCurrentSuccessMsg({
                message: msg,
                type: "error",
              });
              setTimeout(() => {
                setCurrentSuccessMsg("");
              }, 6000);
            }} />
        </div>

        <Teams
          stages={stages}
          ref={teamsRef}
          action="create"
          errormsg={(msg) => {
            setCurrentSuccessMsg({
              message: msg,
              type: "error",
            });
            setTimeout(() => {
              setCurrentSuccessMsg("");
            }, 6000);
          }}
        />
      </Drawer>
      {showCreateAlert && (
        <StatusDrawer
          onClose={() => {
            setShowCreateAlert(false);
            setCreateText(false);
            props.onClose({ refresh: true });
          }}
          title={createText || TextMessage.PROMOTION_CREATE_TEXT}
          visible={showCreateAlert}
          subtitle={
            !isDraft ? 
            nextTeamName
              ? `It has been submitted to ${nextTeamName} for action.`
              : "You can check at “All promo”" : null
          }
        />
      )}
    </div>
  );
};

export default CreatePromo;
