import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox, Select } from "antd";

const Option = Select;

const TextAreaQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleQuestionDesignate,
  index,
  handleSaveQuestion,
  handleQuestionFlag,
}) => {
  return (
    <>
      {question.questionType === "textarea" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox
              onChange={(e) => handleQuestionDesignate(e, index)}
              checked={
                question.designate && question.designate === "optional"
                  ? false
                  : true
              }
            >
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}

      {question.questionType === "textarea" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="fomBuilderSelectStyle">
            <Select
              placeholder="Select Question Flag"
              onChange={(value) => handleQuestionFlag(index, value)}
              value={question.questionFlag || null}
            >
              <Option value={"Start Date"}>Start Date</Option>
              <Option value={"List Price"}>List Price</Option>
              <Option value={"Map Price"}>MAP Price</Option>
              <Option value={"Map Hold Duration"}>MAP Hold Duration</Option>
              <Option value={"Days Of Inventory"}>Days Of Inventory</Option>
              <Option value={"Pricing Groups"}>Pricing Groups</Option>
            </Select>
          </div>
        </div>
      )}
      {question.questionType === "textarea" && (
        <div className="formBuilderFrameTwoDynamic"></div>
      )}

      {question.questionType === "textarea" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          <div className="formBuilderActionsWrapper">
            <Button
              icon={<CloseOutlined />}
              className="saveBtn"
              onClick={handleCancelEditView}
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default TextAreaQuestionView;
