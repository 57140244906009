import BottomSection from "./BottomSection";
const ShortAnswerQuestion = ({
  isShortAnswer,
  question,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  loading,
  handleQuestionDesignate,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
  onRegexCheckboxChange,
  handleRegexChange,
}) => {
  return (
    <>
      <BottomSection
        isSameType={isShortAnswer && question.questionType === "input"}
        onCheckBoxChange={(e) => handleQuestionDesignate(e, index)}
        loading={loading}
        handleSaveQuestion={() => handleSaveQuestion(question, index)}
        copyQuestion={() => copyQuestion(index)}
        deleteQuestion={() => deleteQuestion(index)}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
        showRegex={true}
        onRegexCheckboxChange={(e) => onRegexCheckboxChange(e, index)}
        handleRegexChange={(e) => handleRegexChange(e, index)}
      />
    </>
  );
};

export default ShortAnswerQuestion;
