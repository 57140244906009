import { combineReducers } from "redux";
import loginReducer from "./login";
import AppReducer from "./app";
import profileReducer from "./profile";
import ErrorTicketsReducer from "./errorTicket";
import UsersReducer from "./users";
import ShelfReducer from "./shelf";
import PromoReducer from "./promotions";
import PromoTypesReducer from "./promoTypes";
import TeamsReducer from "./teams";
import SnapshotReducer from "./snapshot";
import FormBuilderReducer from "./formBuilder";
import SkuReducer from "./sku";
import NotificationReducer from "./notifications";
import MapFormBuilderReducer from "./mapFormBuilder";
import MapReducer from "./map";
import WorkflowReducer from "./workflow";
import CalendarReducer from "./calendar";
import ErrorsReducer from "./error-type";
import ReportReducer from "./report";
import TicketReportReducer from "./ticketReport";
import CommonReducer from "./common";

const rootReducer = combineReducers({
  app: AppReducer,
  login: loginReducer,
  profile: profileReducer,
  errorTickets: ErrorTicketsReducer,
  users: UsersReducer,
  shelf: ShelfReducer,
  promo: PromoReducer,
  promoTypes: PromoTypesReducer,
  teams: TeamsReducer,
  snapshots: SnapshotReducer,
  formBuilder: FormBuilderReducer,
  sku: SkuReducer,
  notifications: NotificationReducer,
  mapFormBuilder: MapFormBuilderReducer,
  maps: MapReducer,
  workflow: WorkflowReducer,
  calendar: CalendarReducer,
  errorType: ErrorsReducer,
  report: ReportReducer,
  ticketReport: TicketReportReducer,
  common: CommonReducer,
});

export default rootReducer;
