import React, { useEffect, useState } from "react";
import { Radio } from "antd";
import { useDispatch } from "react-redux";
import Element from "../Element";
import { sendSub1, sendSubInput, sendSubInputThree } from "actions/promotions";
import MasterDropDownEle from "./MasterDropDownEle";

const RadioEle = (props) => {
  const [value, setValue] = useState(props.ans ? props.ans : "");
  const dispatch = useDispatch();
  const [obj, setObj] = useState(null);
  const [subOption, setSubOption] = useState(false);
  const [objMaster, setObjMaster] = useState(null);
  const [subOptionMaster, setSubOptionMaster] = useState(false);

  useEffect(() => {
    if (value && (props.action === "view" || props.action === "edit") && props.options) {
      const object1 = props.options?.filter((item) =>
        (item?.optionText || item?.subOptionText)
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      );

      if (object1[0]?.subOptions?.length) {
        setSubOption(true);
        setObj(object1[0]?.subOptions);
        if ((props.action === "edit" || props.action === "view") && props.SubOptionInput) {
          dispatch(
            sendSubInput(
              props?.SubOptionInput[0]?.value,
              "subinput",
              props.id,
              props.SubOptionInput[0]?.label || ""
            )
          );
        }
        if ((props.action === "edit" || props.action === "view") && props.SubOptionInputThree) {
          dispatch(
            sendSubInputThree(
              props?.SubOptionInputThree[0]?.value,
              "subinput",
              props.id,
              props.SubOptionInputThree[0]?.label || ""
            )
          );
        }
        if ((props.action === "edit" || props.action === "view") && props.subOptions) {
          dispatch(
            sendSub1(
              props?.subOptions[0]?.value,
              "subinput",
              props.id,
              props.subOptions[0]?.subOptionText || "",
              props.subOptions[0]?.masterDataType || ""
            )
          );
        }
      } else {
        setSubOption(false);
        dispatch(sendSub1(null, null, props.id));
        dispatch(sendSubInput(null, null, props.id));
        dispatch(sendSubInputThree(null, null, props.id));
        setObj(false);
      }
      return;
    }

    if (value && props.action === "create" && props.subOptions?.value) {
      const object1 = props.options?.filter((item) =>
        (item?.optionText || item?.subOptionText)
          .toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      );

      if (object1[0]?.subOptions?.length) {
        setSubOption(true);
        setObj(object1[0]?.subOptions);
      } else {
        setSubOption(false);
        dispatch(sendSub1(null, null, props.id));
        setObj(false);
      }
      return;
    }
  }, [
    props.subOptions,
    dispatch,
    props.action,
    props.ans,
    props.id,
    props.options,
    value,
    props.SubOptionInput,
    props.SubOptionInputThree,
  ]);

  useEffect(() => {
    if (
      props.subOptions &&
      props?.subOptions.length > 0 &&
      props?.subOptions[0].isMaster &&
      props?.subOptions[0].isMaster === true &&
      (props.action === "view" || props.action === "edit")
    ) {
      setSubOptionMaster(true);
      setObjMaster(props.subOptions[0]);
    }
  }, [props.subOptions, props.action]);

  const handleSubChange = (value, id) => {
    obj[0].value = value;
    dispatch(
      sendSub1(value, id, props.id, obj[0]?.subOptionText || "", obj[0]?.masterDataType || "")
    );
  };

  const handleSubChangeInput = (value, id) => {
    dispatch(sendSubInput(value, id, props.id, obj[1]?.subOptionText || ""));
  };

  const handleSubChangeInputThree = (value, id) => {
    dispatch(sendSubInputThree(value, id, props.id, obj[2]?.subOptionText || ""));
  };

  const onChange = (e) => {
    setValue(e.target.value);
    props.isPromoTypeQuestion
      ? props.handlePromoTypeQuestionChange(
        e.target.value,
        props.id,
        props.pqid
      )
      : props.onChange(e.target.value, props.id);

    const object1 = props.options?.filter((item) =>
      (item?.optionText || item?.subOptionText)
        .toLowerCase()
        .trim()
        .includes(e.target.value.toLowerCase().trim())
    );

    if (object1[0]?.subOptions?.length) {
      if (object1[0]?.subOptions[0]?.isMaster) {
        setSubOptionMaster(true);
      } else {
        setSubOptionMaster(false);
      }

      setSubOption(true);
      setObj(object1[0]?.subOptions);
      if (object1[0]?.subOptions?.length === 1) {
        dispatch(sendSubInput(null, null, props.id));
        dispatch(sendSubInputThree(null, null, props.id));
      }
      if (object1[0]?.subOptions?.length === 2) {
        dispatch(sendSubInput(null, null, props.id));
        dispatch(sendSubInputThree(null, null, props.id));
      }
    } else {
      setSubOption(false);
      setSubOptionMaster(false);
      dispatch(sendSub1(null, null, props.id));
      dispatch(sendSubInput(null, null, props.id));
      dispatch(sendSubInputThree(null, null, props.id));
      setObj(false);
    }
  };

  return (
    <>
      <div className="radio-frame">
        <div>
          <label className="input-label">
            {props.designate && props.designate === "required" ? (
              <span className="spanReq">*</span>
            ) : (
              ""
            )}{" "}
            {props.label}
          </label>
        </div>
        {props.action === "create" && (
          <>
            <div>
              <Radio.Group onChange={onChange} value={value}>
                {props.options?.map((item) => (
                  <Radio
                    value={item.optionText || item.subOptionText}
                    key={item.optionText || item.subOptionText}
                  >
                    {item.optionText || item.subOptionText}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            <span className="error-label">{props.error}</span>
          </>
        )}
        {props.action === "edit" && (
          <>
            <div>
              <Radio.Group onChange={onChange} value={value}>
                {props.options?.map((item) => (
                  <Radio value={item.optionText || item.subOptionText}>
                    {item.optionText || item.subOptionText}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
            <span className="error-label">{props.error}</span>
          </>
        )}
        {props.action === "view" && (
          <div>
            <Radio.Group value={props.ans} disabled>
              {props.options?.map((item) => (
                <Radio value={item.optionText || item.subOptionText}>
                  {item.optionText || item.subOptionText}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        )}
      </div>
      {subOption && props.action === "create" && !obj[0].isMaster && (
        <Element
          field={obj[0]?.subOptionType}
          label={obj[0]?.subOptionText}
          options={obj[0]?.subOptions}
          action={props.action}
          onChange={handleSubChange}
          id={"sub" + obj[0]?.subOptionType}
          id1={props.id}
          sub={"subsub"}
          error={props.error}
          designate={props.designate}
          isRegexRequired={obj[0]?.isRegexRequired}
          regex={obj[0]?.regex}
        />
      )}

      {subOption && props.action === "create" && obj[1]?.subOptionType && (
        <Element
          field={obj[1]?.subOptionType}
          label={obj[1]?.subOptionText}
          options={obj[1]?.subOptions}
          onChange={handleSubChangeInput}
          id={"subOptions"}
          action={props.action}
          error={props.error}
          designate={props.designate}
          isRegexRequired={obj[1]?.isRegexRequired}
          regex={obj[1]?.regex}
        />
      )}

      {subOption && props.action === "create" && obj[2]?.subOptionType && (
        <Element
          field={obj[2]?.subOptionType}
          label={obj[2]?.subOptionText}
          options={obj[2]?.subOptions}
          onChange={handleSubChangeInputThree}
          id={"subOptions"}
          action={props.action}
          error={props.error}
          designate={props.designate}
          isRegexRequired={obj[2]?.isRegexRequired}
          regex={obj[2]?.regex}
        />
      )}

      {subOption &&
        props.action === "create" &&
        obj[0]?.isMaster &&
        obj[0].isMaster === true && (
          <MasterDropDownEle
            options={obj[0]}
            action={props.action}
            onChange={handleSubChange}
            id={"sub" + obj[0]?.subOptionType}
            error={props.error}
            designate={props.designate}
          />
        )}

      {subOption && props.action === "edit" && !obj[0].isMaster && (
        <Element
          field={obj[0]?.subOptionType}
          label={obj[0]?.subOptionText}
          options={obj[0]?.subOptions}
          action={props.action}
          onChange={handleSubChange}
          id={"sub" + obj[0]?.subOptionType}
          id1={props.id}
          sub={"subsub"}
          ans={props.SubOptionInput}
          error={props.error}
          designate={props.designate}
          isRegexRequired={obj[0]?.isRegexRequired}
          regex={obj[0]?.regex}
        />
      )}

      {subOption && props.action === "edit" && obj[1]?.subOptionType && (
        <Element
          field={obj[1]?.subOptionType}
          label={obj[1]?.subOptionText}
          options={obj[1]?.subOptions}
          onChange={handleSubChangeInput}
          id={"subOptions"}
          action={props.action}
          ans={props.SubOptionInput}
          error={props.error}
          designate={props.designate}
          isRegexRequired={obj[1]?.isRegexRequired}
          regex={obj[1]?.regex}
        />
      )}

      {subOption && props.action === "edit" && obj[2]?.subOptionType && (
        <Element
          field={obj[2]?.subOptionType}
          label={obj[2]?.subOptionText}
          options={obj[2]?.subOptions}
          onChange={handleSubChangeInputThree}
          id={"subOptions"}
          action={props.action}
          ans={props.SubOptionInputThree}
          error={props.error}
          designate={props.designate}
          isRegexRequired={obj[2]?.isRegexRequired}
          regex={obj[2]?.regex}
        />
      )}

      {subOption &&
        props.action === "edit" &&
        obj[0]?.isMaster &&
        obj[0].isMaster === true && (
          <MasterDropDownEle
            options={obj[0]}
            action={props.action}
            onChange={handleSubChange}
            id={"sub" + obj[0]?.subOptionType}
            ans={props.subOptions}
            error={props.error}
            designate={props.designate}
          />
        )}

      {subOptionMaster &&
        props.action === "view" &&
        objMaster.isMaster &&
        objMaster.isMaster === true && (
          <MasterDropDownEle
            options={objMaster}
            action={props.action}
            onChange={handleSubChange}
            id={"sub" + objMaster?.subOptionType}
            ans={props.subOptions}
            error={props.error}
            designate={props.designate}
          />
        )}

      {subOption &&
        props.action === "view" &&
        !obj[0]?.isMaster &&
        !obj[1]?.subOptionType &&
        !obj[2]?.subOptionType && (
          <Element
            field={obj[0]?.subOptionType}
            label={obj[0]?.subOptionText}
            options={obj[0]?.subOptions}
            action={props.action}
            ans={props.SubOptionInput}
            error={props.error}
            designate={props.designate}
          />
        )}

      {subOption &&
        props.action === "view" &&
        obj[1]?.subOptionType &&
        !obj[2]?.subOptionType && (
          <Element
            field={obj[1]?.subOptionType}
            label={obj[1]?.subOptionText}
            options={obj[1]?.subOptions}
            action={props.action}
            ans={props.SubOptionInput}
            error={props.error}
            designate={props.designate}
          />
        )}

      {subOption &&
        props.action === "view" &&
        obj[2]?.subOptionType && (
          <Element
            field={obj[2]?.subOptionType}
            label={obj[2]?.subOptionText}
            options={obj[2]?.subOptions}
            action={props.action}
            ans={props.SubOptionInputThree}
            error={props.error}
            designate={props.designate}
          />
        )}
    </>
  );
};

export default RadioEle;
