/* eslint-disable no-unused-vars */
// eslint-disable
import CommonLayout from "components/layout/Layout";
import { Spin, Input, Button, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getWorkflowList, deleteWorkflow } from "actions/workflow/index";
import { useEffect, useState } from "react";
import { FileAddOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import CustomTable from "components/common/table/TableNew";
import Fullscreen from "fullscreen-react";
import { WORKFLOW_LIST_COLUMNS } from "utils/column";
import { TextMessage } from "utils/constants";
import { useNavigate } from "react-router-dom";
import ReactFlow, { Controls } from "reactflow";
import { useMemo } from "react";
import Team from "./Team";
import session from "utils/session";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import "./list.css";
const { Search } = Input;

const WorlflowList = () => {
  const [searchedText, setSearchedText] = useState("");
  const [isEnter, setIsEnter] = useState(false);
  const [tableSize, setTableSize] = useState("default");
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [viewData, setViewData] = useState(undefined);
  const dispatch = useDispatch();
  const { workflows, total } = useSelector((state) => state.workflow);
  const navigate = useNavigate();
  const role = session && session.role ? session.role.toLowerCase() : "";
  const { showAlert } = useAlert();
  const userPermissions =
    session && session.permissions ? session.permissions : [];
  const getData = async (params = {}, isLoading = true) => {
    // if (isLoading) {
    //   setLoadingState(true);
    // }
    const res = await dispatch(getWorkflowList(params));
    // if (res) {
    //   setLoadingState(false);
    //   setTableLoading(false);
    // }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const handleSearchChange = (e) => {
    setSearchedText(e.target.value);
  };
  const onSearch = async (value) => {
    const params = {
      ...currentFilters,
    };

    if (value) {
      params.search = {name: value};
      params.page = currPage;
      getData(params);
    } else {
      setSearchedText("");
      getData({ page: currPage, pageSize: pageSize });
    }
  };

  const handleAdd = () => {
    // setModalOpen(true)
    // setCurrentAction('Create Team')
    // setOkBtnText('Add')
    navigate("/workflow/create");
  };
  const setColumns = () => {
    let cols = [];

    WORKFLOW_LIST_COLUMNS.forEach((o) => {
      cols.push(o);
    });
    return cols;
  };
  const setupRows = (promoTypes) => {
    return promoTypes?.map((o) => {
      return {
        name: o.name,
        _id: o._id,
        type: o.type.charAt(0).toUpperCase() + o.type.slice(1),
        promoTypeCount: o.promoTypeCount,
        jsonView: o.jsonView,
        stages: o.stages,
      };
    });
  };
  const updatePromoType = (record) => {
    navigate(`/workflow/edit/${record._id}`);
  };
  const deleteWorkFlowYes = async (data) => {
    const res = await dispatch(deleteWorkflow(data._id));
    if(res?.error){
      showAlert({
        message: res?.error?.response?.data?.message,
        variant: AlertVariant.ERROR
      });
    } else{
      dispatch(getWorkflowList());
    }
  };

  const deleteWorkflowClick = (value) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteWorkFlowYes(value),
      okType: "primary",
    });
  };

  const checkPermission = (row) => {
      if (role === "admin") {
        return false;
      } else {
        return userPermissions.includes("workflow") ? false : true;
      }
    
  };

  const nodeType = useMemo(() => {
    return {
      ...ReactFlow.defaultNodeTypes,
      default: (props) => <Team {...props} hideAdd={true} />,
    };
  }, []);
  return (
    <CommonLayout
      className="no-content-mr workflow-list"
      breadCrumbText="Workflow"
    >
      <div className="promo-type-card">
        <div className="promo-right-content">
          <Search
            placeholder="Search Workflow"
            enterButton
            allowClear
            className="searchPromoTypeBtn"
            onSearch={onSearch}
            onChange={handleSearchChange}
          />
        </div>

        <div className="promo-left-content">
          <Button
            type="primary"
            icon={<FileAddOutlined />}
            className="addPromoTypeBtn"
            onClick={handleAdd}
          >
            Add New Workflow
          </Button>
        </div>
      </div>
      {false ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
          <div className="promo-table-card">
            <div className="w-100">
              <CustomTable
                columns={setColumns()}
                rows={setupRows(workflows)}
                isEditable={true}
                isDeleteable={true}
                rowKey="_id"
                onEdit={updatePromoType}
                onDelete={deleteWorkflowClick}
                onView={(record) => setViewData(record)}
                total={total}
                size={tableSize}
                isViewable={true}
                isPopup={true}
                isPromoView={false}
                checkRowDeleatable={checkPermission}
                onChange={async (
                  { current, pageSize },
                  filter = {},
                  { field, order, columnKey }
                ) => {
                  const params = {
                    page: current,
                    perPage: pageSize,
                    sortBy: order && columnKey,
                    orderBy: order,
                  };
                  setCurrPage(current);
                  setPageSize(pageSize);
                  setTableLoading(true);
                  setCurrentFilters(params);
                  getData(params, false);
                }}
              />
            </div>
          </div>
        </Fullscreen>
      )}
      <Modal
        open={viewData}
        title={viewData?.name}
        // onOk={handleSubmit}
        onCancel={() => setViewData(undefined)}
        // okText={okBtnText}
        destroyOnClose
        footer={null}
      >
        {viewData && (
          <div style={{ height: "calc(60vh)" }}>
            <ReactFlow
              nodes={updateJsonViewNodes(
                viewData.jsonView.nodes,
                viewData.stages
              )}
              edges={viewData.jsonView.edges}
              viewport={viewData.jsonView.viewport}
              nodeTypes={nodeType}
              fitView={true}
              defaultViewport={{ zoom: 1 }}
            >
              <Controls />
            </ReactFlow>
          </div>
        )}
      </Modal>
    </CommonLayout>
  );
};
export const updateJsonViewNodes = (nodes = [], stages = []) => {
  return nodes.map((node, index) => {
    const stag = stages.find((s) => s?.teamDetails?._id === node?.data?.teamId);
    const newNode = {
      ...node,
      data: {
        label: stag?.teamDetails?.name || node?.data?.name,
        teamId: stag?.teamDetails?._id || node?.data?.teamId,
      },
    };
    return newNode;
  });
};
export default WorlflowList;
