import session                              from 'utils/session';

export default class LoginStateManager {
  static addNewLoginSuccess = (prevState, action) => {
    let state = { ...prevState };
    const token = action.payload.data.data.payload.token;
    session.setToken(token);
    session.setFullName(action.payload.data.data.payload.user.fullName)
    session.setRole(action.payload.data.data.payload.user.role)
    session.setUserId(action.payload.data.data.payload.user._id)
    session.setTeamId(action.payload.data.data.payload.user.teamId)
    session.setTeamDetail(action.payload.data.data.payload.user.teamDetail)

    state.user = action.payload.data.data.payload.user
    return state;
  };  
    
    static logout = (prevState, action) => {
        let state = { ...prevState };

        state = {
            user: null
        };
        session.clear();
        return state
    }
}
