import logger from "common/logger";
import { HttpService } from "services/network/http.service";

export default class ErrorTypeService extends HttpService {
  async addErrorType(payload: any) {
    try {
      const response = await this.post(`/api/errorType`, payload);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async updateErrorType(id: string, payload: any) {
    try {
      const response = await this.patch(
        `/api/errorType/${id}`,
        payload
      );
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
}
