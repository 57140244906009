import React from 'react'
import {  useEffect } from "react";
import { Alert} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { disableMsg } from 'actions/login';

const Error = (props) => {
    const { currentErrorMsg,currentSuccessMsg} = useSelector((state) => state.login); 
    const dispatch = useDispatch();
    useEffect(() => {
      setTimeout(() => {        
        dispatch(disableMsg());
      }, 5000); 
    }, [currentErrorMsg,currentSuccessMsg,dispatch]);   
  return (
   
    <>
    {props.showError ? (
      <>
        {currentErrorMsg && (
          <Alert message={currentErrorMsg} closable type="error" showIcon />
        )}
      </>
    ) : (
      <>
        {currentErrorMsg && (
          <Alert message={currentErrorMsg} closable type="error" showIcon />
        )}
        {currentSuccessMsg && (
          <Alert
            message={currentSuccessMsg}
            closable
            type="success"
            showIcon
          />
        )}
      </>
    )}
  </>
  )
}

export default Error