import { TeamOutlined, CloseCircleOutlined } from "@ant-design/icons";

import React from "react";
import "./team.css";
import CustomHandle from "./CustomHandle";

const Team = ({
  data,
  isConnectable,
  selected,
  onClick,
  onConnect,
  onRemove,
  id,
  addClick,
  hideAdd=false,
  ...props
}) => {
  const handleRemoveClick = (event) => {
    event.stopPropagation(); // Prevent the node click event from triggering
    onRemove(id);
  };

  return (
    <div className="team-node-wrapper">
      <CustomHandle
        type="source"
        position="bottom"
        style={{ background: "#555" }}
        hideAdd={hideAdd}
        addClick={(e) => {
          e.stopPropagation();
          addClick(id, "source", e);
        }}
      />
      <div className="team-node">
        <div className="team-icon-wrapper">
          <TeamOutlined style={{ fontSize: "14px", color: "#0F2A6F" }} />
        </div>
        <div className="team-node-label">{data.label}</div>
      </div>
      <CustomHandle
        type="target"
        position="top"
        style={{ background: "#555" }}
        hideAdd={hideAdd}
        addClick={(e) => {
          e.stopPropagation();
          addClick(id, "target", e);
        }}
      />
      <div className="on-hover-actions">
        {onRemove && <CloseCircleOutlined onClick={handleRemoveClick} />}
      </div>
    </div>
  );
};

export default Team;
