export default class MapFormBuilderStateManager{
    static getMapFormBuilder = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.mapFormBuilder = data || [];

        return state;
    }

    static createMapFormBuilder = (prevState, action) => {
        const state = { ...prevState };

        state.mapFormBuilder=[action.payload.data.data,...state.mapFormBuilder];
       
        return state;
    }

    static deleteMapFormBuilder = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.mapFormBuilder.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.mapFormBuilder[index]={ ...state.mapFormBuilder[index], ...newRecord };
          state.mapFormBuilder = [ ...state.mapFormBuilder ];
        }
  
        return state;
    }

}