import React, { useEffect } from "react";
import { useState } from "react";
import { Select, Spin } from "antd";
import { getAllShelf, getSKUBySearch } from "actions/shelf";
import { getPromoList } from "actions/promotions";
import { useDispatch } from "react-redux";
import { getAllUsers } from "actions/users";
import { getBrand } from "actions/sku";
const { Option } = Select;

const MultipleSelect = (props) => {
  const [value, setValue] = useState(props?.ans || props?.options?.value || []);
  const [promoOptionData, setPromoOptionData] = useState([])
  const [shelfOptionData, setShelfOptionData] = useState([])
  const [userOptionData, setUserOptionData] = useState([])
  const [brandOptionData, setBrandOptionData] = useState([])
  const [fetching, setFetching] = useState(false);
  const [dataSku, setDataSku] = useState([]);
  const [timer, setTimer] = useState(null)
  const dispatch = useDispatch();
  const onChange = (e) => {
    setValue(e);
    props.isPromoTypeQuestion ? props.handlePromoTypeQuestionChange(e, props.id, props.pqid) : props.onChange(e, props.id);
  };

  useEffect(() => {
    if (props?.options?.masterDataType === 'promo') {
      const getPromoCall = async () => {
        const res = await dispatch(getPromoList())

        if (res.error === undefined) {
          setPromoOptionData(res.payload.data.data.payload.promoList || [])
        }
      }
      getPromoCall()
    }
    if (props?.options?.masterDataType === 'user') {
      const getUserCall = async () => {
        const res = await dispatch(getAllUsers())
        if (res.error === undefined) {
          setUserOptionData(res.payload.data.data.users || [])
        }
      }
      getUserCall()
    }
    if (props?.options?.masterDataType === 'shelf') {
      const getShelfCall = async () => {
        const res = await dispatch(getAllShelf())

        if (res.error === undefined) {
          setShelfOptionData(res.payload.data.data.payload.shelfList || [])
        }
      }
      getShelfCall()
    }

    if (props?.options?.masterDataType === 'brand') {
      const getBrandCall = async () => {
        const res = await dispatch(getBrand())

        if (res.error === undefined) {
          setBrandOptionData(res.payload.data.data || [])
        }
      }
      getBrandCall()
    }
  }, [props.options, dispatch])

  const handleSearchSkuKeys = async (value) => {
    clearTimeout(timer)

    if (value.length >= 3) {
      const newTimer = setTimeout(async () => {
        setFetching(true)
        setDataSku([])
        const res = await dispatch(getSKUBySearch(value))
        if (res.error === undefined) {
          setDataSku(res.payload.data.data)
          setFetching(false)
        } else {
          setFetching(false)
        }
      }, 700)
      setTimer(newTimer)
    }


  }

  const promoChildren = [];
  if (promoOptionData.length > 0) {
    const filteredPromo = promoOptionData.filter(item => item.status.toLowerCase() !== 'expired')
    for (let i = 0; i < filteredPromo.length; i++) {
      promoChildren.push(<Option key={filteredPromo[i]._id}>{filteredPromo[i].name}</Option>);
    }
  }
  const userChildren = [];
  if (userOptionData.length > 0) {
    const filteredUser = userOptionData.filter(item => item.isActive === true)
    for (let i = 0; i < filteredUser.length; i++) {
      userChildren.push(<Option key={filteredUser[i].fullName || filteredUser[i].firstName}>{filteredUser[i].fullName || filteredUser[i].firstName}</Option>);
    }
  }
  const shelfChildren = [];
  if (shelfOptionData.length > 0) {
    for (let i = 0; i < shelfOptionData.length; i++) {
      shelfChildren.push(<Option key={shelfOptionData[i]._id}>{shelfOptionData[i].shelfPath}</Option>);
    }
  }
  const skuChildren = [];
  if (dataSku.length > 0) {
    for (let i = 0; i < dataSku.length; i++) {
      skuChildren.push(<Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>);
    }
  }

  const brandChildren = [];
  if (brandOptionData.length > 0) {
    for (let i = 0; i < brandOptionData.length; i++) {
      brandChildren.push(<Option key={brandOptionData[i].sku_brand_desc}>{brandOptionData[i].sku_brand_desc}</Option>);
    }
  }

  return (
    <>
      <div className="input-frame">
        <label className="input-label">
          {props.designate && props.designate === 'required' ? <span className="spanReq">*</span> : ''}
          {props.label}
        </label>
        {props.action === "create" && (
          <div className="select-dropdown">
            {
              props.options.masterDataType === 'sku' && <Select
                className="select-dropdown"
                showSearch
                onChange={onChange}
                value={value || []}
                placeholder="Search and Select SKU"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? 'multiple' : 'tags'}
                tokenSeparators={[',']}
                maxTagCount={3}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'sku' && skuChildren && skuChildren.length > 0 && skuChildren
                }

              </Select>
            }

            {
              props.options.masterDataType === 'brand' && <Select
                showSearch
                placeholder="Search and select Brand"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                mode="multiple"
                maxTagCount={3}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'brand' && brandChildren && brandChildren.length > 0 && brandChildren
                }
              </Select>
            }
            {
              props.options.masterDataType === 'user' && <Select
                showSearch
                placeholder="Search and select User"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                mode="multiple"
                maxTagCount={3}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'user' && userChildren && userChildren.length > 0 && userChildren
                }
              </Select>
            }
            {/* end create user */}

            {
              props.options.masterDataType !== 'sku' && props.options.masterDataType !== 'brand' && props.options.masterDataType !== 'user' && <Select
                className="select-dropdown"
                mode="multiple"
                onChange={onChange}
                value={value}
                placeholder="Select"
              >
                {!props.options.isMaster && props.options?.map((item) => (
                  <Option key={item.optionText}>{item.option}</Option>
                ))}

                {
                  props.options.masterDataType && props.options.masterDataType === 'promo' && promoChildren && promoChildren.length > 0 && promoChildren
                }
                {
                  props.options.masterDataType && props.options.masterDataType === 'shelf' && shelfChildren && shelfChildren.length > 0 && shelfChildren
                }
              </Select>
            }
            <span className="error-label">{props.error}</span>
          </div>
        )}
        {props.action === "edit" && (
          <div className="select-dropdown">
            {
              props.options.masterDataType === 'sku' && <Select
                className="select-dropdown"
                showSearch
                onChange={onChange}
                value={value}
                placeholder="Select"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={fetching ? 'multiple' : 'tags'}
                tokenSeparators={[',']}
                maxTagCount={3}
              >
                {
                  props.options.masterDataType === 'sku' && skuChildren && skuChildren.length > 0 && skuChildren
                }

              </Select>
            }

            {
              props.options.masterDataType === 'brand' && <Select
                showSearch
                placeholder="Search and select Brand"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                mode="multiple"
                maxTagCount={3}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'brand' && brandChildren && brandChildren.length > 0 && brandChildren
                }
              </Select>
            }

            {/* for edit user */}
            {
              props.options.masterDataType === 'user' && <Select
                showSearch
                placeholder="Search and select User"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                mode="multiple"
                maxTagCount={3}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'user' && userChildren && userChildren.length > 0 && userChildren
                }
              </Select>
            }
            {/* edit user end */}

            {
              props.options.masterDataType !== 'sku' && props.options.masterDataType !== 'brand' && props.options.masterDataType !== 'user' && <Select
                className="select-dropdown"
                mode="multiple"
                onChange={onChange}
                value={value}
                placeholder="Select"
              >
                {!props.options.isMaster && props.options?.map((item) => (
                  <Option key={item.optionText}>{item.option}</Option>
                ))}

                {
                  props.options.masterDataType && props.options.masterDataType === 'promo' && promoChildren && promoChildren.length > 0 && promoChildren
                }

                {
                  props.options.masterDataType && props.options.masterDataType === 'shelf' && shelfChildren && shelfChildren.length > 0 && shelfChildren
                }
              </Select>
            }
            <span className="error-label">{props.error}</span>
          </div>
        )}
        {props.action === "view" && (
          <div className="select-dropdown">
            {
              props.options.masterDataType === 'sku' && <Select
                className="select-dropdown"
                showSearch
                onChange={onChange}
                value={value}
                placeholder="Select"
                defaultActiveFirstOption={false}
                showArrow={false}
                disabled
                filterOption={false}
                onSearch={handleSearchSkuKeys}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                mode={'multiple'}
                maxTagCount={3}
              >
                {
                  props.options.masterDataType === 'sku' && skuChildren.length > 0 && skuChildren
                }

              </Select>
            }

            {
              props.options.masterDataType === 'brand' && <Select
                showSearch
                placeholder="Search and select Brand"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                disabled
                mode="multiple"
                maxTagCount={3}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'brand' && brandChildren && brandChildren.length > 0 && brandChildren
                }
              </Select>
            }
            {
              props.options.masterDataType === 'user' && <Select
                showSearch
                placeholder="Search and select User"
                optionFilterProp="children"
                className="select-dropdown"
                onChange={onChange}
                value={value}
                disabled
                mode="multiple"
                maxTagCount={3}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              >
                {
                  props.options.masterDataType && props.options.masterDataType === 'user' && userChildren && userChildren.length > 0 && userChildren
                }
              </Select>
            }
            {/* end view user */}

            {
              props.options.masterDataType !== 'sku' && props.options.masterDataType !== 'brand' && props.options.masterDataType !== 'user' && <Select
                className="select-dropdown"
                mode="multiple"
                onChange={onChange}
                value={value}
                placeholder="Select"
                disabled
              >
                {!props.options.isMaster && props.options?.map((item) => (
                  <Option key={item.optionText}>{item.option}</Option>
                ))}

                {
                  props.options.masterDataType && props.options.masterDataType === 'promo' && promoChildren.length > 0 && promoChildren
                }

                {
                  props.options.masterDataType && props.options.masterDataType === 'shelf' && shelfChildren.length > 0 && shelfChildren
                }
              </Select>
            }
            <span className="error-label">{props.error}</span>
          </div>
        )}
      </div>

    </>
  );
};

export default MultipleSelect;