import {
  BellOutlined,
  ForkOutlined,
  HistoryOutlined,
  PercentageOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  UserOutlined,
  WarningOutlined,
  BranchesOutlined,
  TeamOutlined,
  CalendarOutlined, 
  FileExcelOutlined
} from "@ant-design/icons";
import ReportIcon from "image/report-icon.svg";

export const MainMenu = [
  {
    id: "promo management",
    permissionId: "promo",
    children: [
      // {
      //   id: "all",
      //   title: "Promo",
      //   link: "/promotions",
      // },
      {
        id: "all v2",
        title: "Offer",
        link: "/promotions-v2",
      },
      // {
      //   id: "form builder",
      //   title: "Form Builder",
      //   link: "/form-builder",
      // },
      {
        id: "promo types",
        title: "Offer Types",
        link: "/promo-types",
      },
    ],
    title: "Promo Management",
    link: "/promotions",
    icon: <PercentageOutlined />,
  },
  // {
  //   id: "map management",
  //   permissionId: "promo",
  //   children: [
  //     {
  //       id: "map",
  //       title: "MAP",
  //       link: "/map",
  //     },
  //     {
  //       id: "map form builder",
  //       title: "MAP Form Builder",
  //       link: "/map/type",
  //     },
  //   ],
  //   title: "MAP Management",
  //   link: "/map",
  //   icon: <ForkOutlined />,
  // },
  {
    id: "manage users",
    permissionId: "user",
    children: [
      {
        id: "all users",
        title: "All Users",
        link: "/users",
      },
      {
        id: "admin",
        title: "Admin",
        link: "/users/admin",
      },
      {
        id: "basic",
        title: "Basic",
        link: "/users/basic",
      },
      {
        id: "approver",
        title: "Approver",
        link: "/users/approver",
      },
    ],
    title: "Manage Users",
    link: "/users",
    icon: <UserOutlined />,
  },
  {
    id: "automation templates",
    permissionId: "automationTemplates",
    title: "Automation Templates",
    link: "/automation-templates",
    icon: <FileExcelOutlined />,
    children: [
      {
        id: "XML-templates",
        permissionId: "XML-templates",
        title: "XML Templates",
        link: "/xml-templates",
      },
      {
        id: "CSV-templates",
        title: "CSV Templates",
        link: "/csv-templates",
      },
    ],
  },
  {
    id: "workflow",
    permissionId: "workflow",
    children: [],
    title: "Workflow",
    link: "/workflow",
    icon: <BranchesOutlined />,
  },
  {
    id: "teams",
    permissionId: "team",
    children: [],
    title: "Teams",
    link: "/teams",
    icon: <TeamOutlined />,
  },
  {
    id: "sku",
    permissionId: "sku",
    children: [],
    title: "SKU",
    link: "/sku",
    icon: <ReconciliationOutlined />,
  },
  {
    id: "brands",
    permissionId: "brands",
    children: [],
    title: "Brands",
    link: "/brands",
    icon: <ShopOutlined />,
  },
  {
    id: "shelf",
    permissionId: "shelf",
    children: [],
    title: "Shelf",
    link: "/shelf",
    icon: <ProfileOutlined />,
  },
  {
    id: "error report",
    permissionId: "report",
    title: "Change Request Management",
    link: "/error-report",
    icon: <WarningOutlined />,
    children: [
      // {
      //   id: "error report",
      //   title: "Error Report",
      //   link: "/error-report",
      // },
      {
        id: "ticket-report",
        permissionId: "ticket-report",
        title: "Change Request",
        link: "/ticket-report",
      },
      {
        id: "error-type",
        title: "Change Type",
        link: "/error-type",
      },
    ],
  },
  // {
  //   id: "ticket-report",
  //   permissionId: "ticket-report",
  //   children: [],
  //   title: "Change Request",
  //   link: "/ticket-report",
  //   icon: <WarningOutlined />,
  // },
  {
    id: "snaphshot",
    permissionId: "snapshot",
    children: [],
    title: "Snapshot/History",
    link: "/snapshot",
    icon: <HistoryOutlined />,
  },
  {
    id: "notifications",
    permissionId: "notifications",
    children: [],
    title: "Notifications",
    link: "/notifications",
    icon: <BellOutlined />,
  },
  {
    id: "Reports",
    children: [],
    title: "Reports",
    link: "/reports",
    permissionId: "report",
    icon: <img src={ReportIcon} alt="logo" />,
  },
  {
    id: "calendar",
    permissionId: "calendar",
    children: [],
    title: "Calendar Management",
    link: "/calendar",
    icon: <CalendarOutlined />,
  },
];

export const MerchantMenu = [
  {
    id: "promo management",
    children: [
      {
        id: "all",
        title: "Promo",
        link: "/promotions",
      },
    ],
    title: "Promo Management",
    link: "/promotions",
    icon: <PercentageOutlined />,
  },
  {
    id: "map management",
    children: [
      {
        id: "map",
        title: "MAP",
        link: "/map",
      },
    ],
    title: "MAP Management",
    link: "/map",
    icon: <ForkOutlined />,
  },
  {
    id: "error report",
    children: [],
    title: "Error Report",
    link: "/error-report",
    icon: <WarningOutlined />,
  },
  {
    id: "notifications",
    children: [],
    title: "Notifications",
    link: "/notifications",
    icon: <BellOutlined />,
  },
];

export const BottomMenu = [];
