import "./SingleShelf.css";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input,Spin, Tabs,Tag, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomTable from "components/common/table/Table";
import CommonLayout from "components/layout/Layout";
import TableSearchDropdown                                                        from 'components/TableSearchDropdown';
import { Outlet } from "react-router-dom";
import { SHELF_SKU_LIST_COLUMNS, PROMOCODE_LIST_COLUMNS } from "utils/column";
import { useParams } from "react-router";
import data from "utils/dataList.json";
import { getSkuAssociatedShelf,getSearchSKU } from "actions/shelf";
import { getPromoAssociatedShelf } from "actions/promotions";
import { setPage } from "actions/app";

const { TabPane } = Tabs;

const SingleShelf = () => {
  const dispatch = useDispatch();
  const { singleShelfData,skuSearchList } = useSelector((state) => state.shelf);
  const { promoAssociatedShelf } = useSelector((state) => state.promo);
  const { id } = useParams();
  const [key, setKey] = useState("skus");
  const [searched, setSearched] = useState(false);
  const [skuSearchText,setSkuSearchText] = useState('');
  const [loadingState, setLoadingState] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [total,setTotal]=useState(null);
  const [shelfPath, setShelfPath]= useState("");
  const [timer, setTimer] = useState(null)


  useEffect(() => {
    if(!searched){
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getSkuAssociatedShelf(id, pageNo));
      setTotal(res.payload.data.data.paginate.total);
      setShelfPath(res.payload.data.data.shelfPath);
      if (res?.payload?.data?.data) {
      const res1 = await dispatch(getPromoAssociatedShelf(res?.payload?.data?.data?.shelfPath))
      if(res1){
        setLoadingState(false);
      }
      }
    };
    getCall();
    dispatch(setPage("Shelf")); 
  }
  }, [dispatch,id, pageNo,searched]);

  const handleTab = (key) => {
    setKey(key);
    setLoadingState(false)
  };

  const handleSearchChange = () => {

    if(!skuSearchText){
      return;
    } 
    clearTimeout(timer)
        const newTimer = setTimeout(async() => {
        setLoadingState(true)
        const res = await dispatch(getSearchSKU(id,skuSearchText))
        if(res.error === undefined){
          setLoadingState(false)
          setSearched(true)
        }else{
          setLoadingState(false)
          setSearched(false)
        }
      }, 700)
      setTimer(newTimer)

    }

    const handleSearchClear = async() => {
      if(!skuSearchText){
      return;
    }
      setLoadingState(true)
      setSearched(false)
      setSkuSearchText('')

      const res = await dispatch(getSkuAssociatedShelf(id, pageNo))
      if(res){
        setLoadingState(false)
      }
    }

  const singleShelfPagination = async (record) => {
    setPageNo(record - 1);
  };

  const setupRowsSku = (singleShelfData) => {
    return singleShelfData?.map((o) => {
      return {
        sku: o.sku_id,
        product: o?.brand_product_type? o?.brand_product_type: "-",
        dayId: o.class_id, 
        brand: o.sku_brand_desc,
        buyerdesc:o.buyer_desc,
        classdesc:o.class_desc,
        skumanufacturerdesc:o.sku_manufacturer_desc,
        subclassdesc:o.sub_class_desc,
      };
    });
  };

  const setColumns = () => {
    let cols = [];

    SHELF_SKU_LIST_COLUMNS.forEach((o) => {
        if ( o.dataIndex !== 'product') {
            const colsOptions = {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                    return (
                        <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                                             selectedKeys={selectedKeys}
                                             confirm={confirm}
                                             clearFilters={clearFilters}
                                             dataIndex={o.dataIndex} />
                    )
                },
                filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                onFilter: (value, record) => {
                
                    const rec = record[o.dataIndex]
                                    ? record[o.dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
                                    : '';
                    return rec;
                },
                
            }
            cols.push({ ...o, ...colsOptions });
        }
        else {
            cols.push(o);
        }
           
    });
    return cols
}

  const setupRowsPromo = (promoAssociatedShelf) => {
    return promoAssociatedShelf?.map((o) => {
      const oStatus =
        (o.status === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
        (o.status === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
        (o.status === "Expired" && (
          <Tag className="status-symbol-expired">Expired</Tag>
        )) ||
        (o.status === "Incomplete" && (
          <Tag className="status-symbol-incomplete">Incomplete</Tag>
        )) ||
        (o.status === "Approved" && (
          <Tag className="status-symbol-approved">Approved</Tag>
        )) ||
        (o.status === "Live" && (
          <Tag className="status-symbol-live">Live</Tag>
        )) ||
        (o.status === "Rejected" && (
          <Tag className="status-symbol-rejected">Rejected</Tag>
        )) ||
        (o.status === "Live-Errors" && (
          <Tag className="status-symbol-lv">Live-Errors</Tag>
        )) ||
        (o.status === "Pending-Errors" && (
          <Tag className="status-symbol-pv">Pending-Errors</Tag>
        )) ||
        (o.status === "Approved-Errors" && (
          <Tag className="status-symbol-av">Approved-Errors</Tag>
        )) ||
        (o.status === "Expired-Errors" && (
          <Tag className="status-symbol-pv">Expired-Errors</Tag>
        )) ||
        (o.status === "Incomplete-Errors" && (
          <Tag className="status-symbol-iv">Incomplete-Errors</Tag>
        ))||
        (o.status === "Submitted-Errors" && (
          <Tag className="status-symbol-iv">Submitted-Errors</Tag>
        ))
      return {
        promoname: o.name,
        _id: o._id,
        status: oStatus,
        expiringdate: o?.endDate || '',
      };
    });
  };

  const setColumnsPromocodes = () => {
        let cols = [];
    
        PROMOCODE_LIST_COLUMNS.forEach((o) => {
            if (o.dataIndex === "promoname") {
                const colsOptions = {
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        return (
                            <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                                                 selectedKeys={selectedKeys}
                                                 confirm={confirm}
                                                 clearFilters={clearFilters}
                                                 dataIndex={o.dataIndex} />
                        )
                    },
                    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                    onFilter: (value, record) => {
                    
                        const rec = record[o.dataIndex]
                                        ? record[o.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                                        : '';
                        return rec;
                    },
                    
                }
                cols.push({ ...o, ...colsOptions });
            }
            else {
                cols.push(o);
            }
               
        });
        return cols
    }
  
  return (    
    <CommonLayout breadCrumbText="Shelf" className="content-mr" backButton={true} id={shelfPath} backUrl={'/shelf'}
    >
     {!loadingState?(<div><div className="brand-card">
        <div className="page-header1">
          <div className="layout-block">
            <div className="heading-left">
              <div className="heading-left-wrapper">
                <span className="head-text">{shelfPath}</span>
              </div>
            </div>
          </div>          

          <div className="tabs-top">
            <div className="tabs-wrapper">
              <div className="shelf-box-shadow"></div>
              <div className="tabs-groups">
                <Tabs defaultActiveKey="skus" onChange={handleTab}>
                  <TabPane tab="SKU's" key="skus"></TabPane>
                  <TabPane tab="Promo codes" key="promocode"></TabPane>                  
                </Tabs>
              </div>
              {key === "skus" &&
                <div className="right-extra">
                <Input placeholder="Search By SKU"  
                className="skuSearchInput"
                onChange={(e) => setSkuSearchText(e.target.value)}
                value={skuSearchText}
                />
                <Button type="primary" className='addUserBtn' onClick={handleSearchChange}>Search</Button>
                <Button danger onClick={handleSearchClear}>Clear</Button>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="user-table-card">
          <Outlet />

          {/* table starts */}

          {searched ? <div className="w-100 mt-16">
            {key === "skus" && (
              <CustomTable
                columns={setColumns()}
                rows={
                  setupRowsSku(skuSearchList)
                }
                rowKey="search_skus_id"               
                total={total}
              />
            )}
            
            {key === "promocode" && (
              <CustomTable
                columns={setColumnsPromocodes()}
                rows={setupRowsPromo(promoAssociatedShelf)}
                rowKey="search_promo_id"               
                total={data?.promoAssociatedShelf?.length}
              />
            )}
           
          </div> : <div className="w-100 mt-16">
            {key === "skus" && (
              <CustomTable
                columns={setColumns()}
                rows={
                  setupRowsSku(singleShelfData)
                }
                rowKey="skus_id"               
                total={total}
                onChange={singleShelfPagination}
                current={pageNo + 1}
              />
            )}
            
            {key === "promocode" && (
              <CustomTable
                columns={setColumnsPromocodes()}
                rows={setupRowsPromo(promoAssociatedShelf)}
                rowKey="promocode_id"               
                total={data?.promoAssociatedShelf?.length}
              />
            )}
           
          </div> }
        </div>
      </div></div>):(<div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>)}
      
    </CommonLayout>
  );
};

export default SingleShelf;