import { Input } from "antd";
const { TextArea } = Input
const TextEle = ({question,index,questionType,questionHeader,options,designate,action,handleTextAreaChange,answer,textareaValue,setTextareaValue,isLevelTwo=false,handleTextareaSubChange,subIndex,subAnswer,record}) => {

    const handleTextarea = (e,index,subIndex,questionType,questionHeader,record) => {
      const arr = [...textareaValue]
      arr[subIndex].value = e.target.value
      setTextareaValue(arr)
      handleTextareaSubChange(e.target.value,index,subIndex,questionType,questionHeader,record)
    }
    return(
        <div 
        className="textareaDiv"
        >
            {
        action === 'create' && isLevelTwo &&
            <div 
            className="text-frame-flex"
            >
                {designate && designate === 'required' ? <span className="spanReq">*</span> : ''}
                <label className="input-label">{questionHeader}</label>
            </div>
            }

            { action ==="create" && !isLevelTwo &&
            <TextArea 
            onChange={(e) => handleTextAreaChange(e.target.value,index,record)} 
            rows={2} 
            placeholder="Enter Detail here"  /> 
            }

            {
                action === 'create' && isLevelTwo && <TextArea 
                    onChange={(e) => handleTextarea(e,index,subIndex,questionType,questionHeader,record)}
                    value={textareaValue && textareaValue[subIndex]?.value}
                    placeholder="Enter Detail here"
                    rows={2}
                />
            }

            { action ==="view" && !isLevelTwo &&
            <TextArea 
            className="input-text-field" 
            disabled
            value={answer || ''}
            rows={2} placeholder="Enter Detail here"  /> 
            }

            {
                action === 'view' && isLevelTwo && <TextArea 
                    className="input-text-field" 
                    disabled
                    value={subAnswer || ''}
                    placeholder="Enter Detail here"
                />
            }

    </div>
    )
}

export default TextEle;