import CheckEle from "./elements/CheckEle";
import DateEle from "./elements/DateEle";
import DatePickerEle from "./elements/DatePickerEle";
import DropDownEle from "./elements/DropDownEle";
import InputEle from "./elements/InputEle";
import MasterDropDownEle from "./elements/MasterDropDownEle";
import MultipleSelect from "./elements/MultipleSelect";
import RadioEle from "./elements/RadioEle";
import TabEle from "./elements/TabEle";
import TextEle from "./elements/TextEle";
import DropDownWithSearch from "./../promotion/elements/DropDownWithSearch";

const Element = ({
  question,
  index,
  questionType,
  questionHeader,
  options,
  designate,
  action,
  handleInputChange,
  handleTextAreaChange,
  handleCheckboxChange,
  handleDateChange,
  handleDropdownChange,
  handleMultipleSelectChange,
  handleRadioChange,
  handleTabChange,
  handleMasterDropDownChange,
  isLevelTwo,
  handleRadioSubChange,
  handleInputSubChange,
  handleDropdownSubChange,
  handleMasterDropDownSubChange,
  answer,
  subAnswer,
  subAnswerQuestionType,
  handleTextareaSubChange,
  handleDatePickerChange,
  record,
  questionFlag,
}) => {
  return (
    <>
      {questionType === "input" && (
        <InputEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleInputChange={handleInputChange}
          answer={answer}
          subAnswer={subAnswer}
          questionFlag={questionFlag}
        />
      )}

      {questionType === "textarea" && (
        <TextEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleTextAreaChange={handleTextAreaChange}
          answer={answer}
        />
      )}

      {questionType === "checkbox" && (
        <CheckEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleCheckboxChange={handleCheckboxChange}
          answer={answer}
        />
      )}

      {questionType === "date" && (
        <DateEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleDateChange={handleDateChange}
          answer={answer}
        />
      )}

      {questionType === "datePicker" && (
        <DatePickerEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleDatePickerChange={handleDatePickerChange}
          answer={answer}
        />
      )}

      {questionType === "dropdown" && !question.isMaster && (
        <DropDownEle
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleDropdownChange={handleDropdownChange}
          handleRadioSubChange={handleRadioSubChange}
          handleInputSubChange={handleInputSubChange}
          handleDropdownSubChange={handleDropdownSubChange}
          answer={answer}
          subAnswerQuestionType={subAnswerQuestionType}
          subAnswer={subAnswer}
          handleTextareaSubChange={handleTextareaSubChange}
          record={record}
        />
      )}

      {questionType === "multiSelect" && (
        <MultipleSelect
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleMultipleSelectChange={handleMultipleSelectChange}
          answer={answer}
        />
      )}

      {questionType === "radio" && (
        <RadioEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleRadioChange={handleRadioChange}
          isLevelTwo={isLevelTwo}
          handleRadioSubChange={handleRadioSubChange}
          handleInputSubChange={handleInputSubChange}
          handleDropdownSubChange={handleDropdownSubChange}
          handleMasterDropDownSubChange={handleMasterDropDownSubChange}
          answer={answer}
          subAnswerQuestionType={subAnswerQuestionType}
          subAnswer={subAnswer}
          handleTextareaSubChange={handleTextareaSubChange}
        />
      )}

      {questionType === "tabs" && (
        <TabEle
          record={record}
          index={index}
          question={question}
          questionHeader={questionHeader}
          questionType={questionType}
          designate={designate}
          options={options}
          action={action}
          handleTabChange={handleTabChange}
          answer={answer}
        />
      )}

      {questionType === "dropdown" &&
      question?.isMaster &&
      question?.isMaster === true &&
      question?.masterDataType === "promo" ? (
        <DropDownWithSearch
          onChange={handleMasterDropDownChange}
          value={answer}
          options={question}
          action={action}
          designate={designate}
          label={question?.subOptionText || question?.questionHeader}
          required={designate && designate === "required"}
        />
      ) : (
        questionType === "dropdown" &&
        question.isMaster && (
          <MasterDropDownEle
            record={record}
            index={index}
            question={question}
            questionHeader={questionHeader}
            questionType={questionType}
            designate={designate}
            options={options}
            action={action}
            handleMasterDropDownChange={handleMasterDropDownChange}
            answer={answer}
          />
        )
      )}
    </>
  );
};

export default Element;
