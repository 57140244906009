import * as ActionTypes         from 'actions/map/action-types'
import { S,  }                  from 'utils/prefix';
import MapStateManager        from './state-manager';

const INITIAL_STATE = {
    mapsLocal: [],
    mapCreateMessage: '',
    mapValidateMessage: '',
    maps:[],
    viewMap: [],
    mapStatus: [],
    mapByStatus:[],
    mapBySearch:[],
    searchFilter: false,
    selectedBrand:[],
    selectedSku:[],
    chosenMapType: null
};

export default function MapStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_LOCAL_MAPS_JSON:
                return { ...state, mapsLocal: [ ...action.maps ] };
        case ActionTypes.MAP_CREATE_MESSAGE:
                return { ...state, mapCreateMessage: action.msg  };
        case ActionTypes.MAP_VALIDATE_MESSAGE:
                return { ...state, mapValidateMessage: action.msg  };

        case S(ActionTypes.GET_ALL_MAPS):
                return MapStateManager.getAllMaps(state,action);
        case S(ActionTypes.VIEW_MAP):
                return MapStateManager.viewMap(state,action);
        case S(ActionTypes.DELETE_MAP):
                return MapStateManager.deleteMap(state,action);
        case S(ActionTypes.GET_MAPS_BY_STATUS):
                return MapStateManager.getMapsByStatus(state,action);
        case S(ActionTypes.GET_MAP_BY_SEARCH):
                return MapStateManager.getMapsBySearch(state,action);
        case (ActionTypes.CLEAR_MAP_BY_SEARCH):
                return { ...state, mapBySearch: action.val  };
        case (ActionTypes.UPDATE_SEARCH_FILTER_FLAG):
                return { ...state, searchFilter: action.val  };
        case (ActionTypes.UPDATE_SELECTED_BRAND):
                return { ...state, selectedBrand: action.val  };
        case (ActionTypes.UPDATE_SELECTED_SKU):
                return { ...state, selectedSku: action.val  };
        case (ActionTypes.UPDATE_CHOSEN_MAP_TYPE):
                return { ...state, chosenMapType: action.val  };

        default:
            return state;
    }
}