import "./Brands.css";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fullscreen from "fullscreen-react";
import { Input,Spin } from "antd";
import {
  ColumnHeightOutlined,
  FullscreenOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import CustomTable from "components/common/table/Table";
import CommonLayout from "components/layout/Layout";
import { Outlet, useNavigate } from "react-router-dom";
import { BRAND_LIST_COLUMNS } from "utils/column";
import { getBrand } from "actions/sku";
import { setPage } from "actions/app";

const { Search } = Input;

const Brands = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brandList } = useSelector((state) => state.sku);
  const [tableSize, setTableSize] = useState("default");
  const [searchedPromo, setSearchedPromo] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [tableClicked, setTableClicked] = useState(0);
  const [isEnter, setIsEnter] = useState(false);
  const [reloadingState,setReLoadingState] = useState(false)

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getBrand());;
      if (res) {
        setLoadingState(false);
      }
    };
    getCall();
    
    dispatch(setPage("Brands"));
    
  }, [dispatch]);

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  }; 

  const onSearch = async (value) => {
    setSearchedPromo(
      brandList.filter((item) =>
        item.sku_brand_desc.toLowerCase()
          .trim()
          .includes(value.toLowerCase().trim())
      )
    );
  };

  const viewSingleBrand = (record) => {
    
    navigate(`/singlebrand/${record._id}`);
  };

  const setupRows = (brandList) => {
    return brandList?.map((o) => {
      return {
        brands: o?.sku_brand_desc,
        skuno: o?.sku_no,
        _id: o?.sku_brand_id,
      };
    });
  };

  const handleReload = async() => {
      setReLoadingState(true)
      const res = await dispatch(getBrand())
      if(res){
        setReLoadingState(false)
      }
    }

  return (
    <CommonLayout className="content-mr" breadCrumbText="Brands">
      {
        (loadingState || reloadingState) ? <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div> : <>
        <div className="add-user-card">
        <div className="shelf-right-contents">
          <Search
            placeholder="Search By Brand name"
            enterButton
            allowClear
            onSearch={onSearch}
            className="userErrorBtn"
          />
        </div>

        <div className="left-content"></div>
      </div>

      <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
        <div className="user-table-card">
          <div className="table-topbar">
            <div className="shelf-left-contents">
              <div className="filterGrpWrapper">
                <div className="settingGrp">
                  <ReloadOutlined onClick={handleReload} className='reloadIconStyle'/>
                  <ColumnHeightOutlined
                    className="reloadIconStyle"
                    onClick={handleTableHeight}
                  />
                  <FullscreenOutlined
                    onClick={() => {
                      setIsEnter(true);
                    }}
                    className="reloadIconStyle"
                  />
                </div>
              </div>
            </div>
          </div>

          <Outlet />

          {/* table starts */}

          <div className="w-100 mt-16">
            <CustomTable
              columns={BRAND_LIST_COLUMNS}            
              rows={
                searchedPromo ? setupRows(searchedPromo) : setupRows(brandList)
              }
              rowKey="_id"    
              isViewable={true}      
              total={brandList?.length}
              onView={viewSingleBrand}
              size={tableSize}
            />
          </div>
        </div>
      </Fullscreen>
      </>
      }
      
    </CommonLayout>
  );
};

export default Brands;
