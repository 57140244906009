export default class ErrorStateManager {
  static getAllErrors = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;
    // const updatedTeams = data.teams.map(team => ({
    //     ...team,
    //     label: team.name,
    //     value: team._id
    //   }));
    // state.teams = updatedTeams || [];
    // state.pagination = data.pagination || undefined;
    state.total = data.pagination?.total || 0;
    state.errors = data.errorTypes;
    return state;
  };

  static createTeam = (prevState, action) => {
    const state = { ...prevState };

    state.errors = [action.payload.data.data, ...state.errors];
    state.total = state.total + 1;
    return state;
  };

  static updateTeam = (prevState, action) => {
    const state = { ...prevState };

    return state;
  };

  static deleteTeam = (prevState, action) => {
    const state = { ...prevState };
    const id = action.meta.previousAction.meta._id;

    state.errors = state.errors.filter((item) => item._id !== id);
    state.total = state.total - 1;

    return state;
  };

  static updateTeamStatus = (prevState, action) => {
    const state = { ...prevState };
    const newRecord = action?.meta?.previousAction?.meta;

    if (state?.teams) {
      const index = state?.teams?.findIndex(
        (item) => item._id === newRecord._id
      );
      if (index !== -1) {
        state.teams[index] = { ...state.teams[index], ...newRecord };
        state.teams = [...state.teams];
      }
    }

    return state;
  };

  static getTeam = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;

    state.currentTeam = data || [];

    return state;
  };

  static updateErrorStatus = (prevState, action) => {
    const state = { ...prevState };

    const newRecord = action.meta.previousAction.meta;
    const index = state?.errors?.findIndex(
      (item) => item._id === newRecord._id
    );
    if (index !== -1) {
      state.errors[index] = { ...state.errors[index], ...newRecord };
      state.errors = [...state.errors];
    }

    return state;
  };
}
