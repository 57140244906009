import * as ActionTypes from "./action-types";

export const login = (data) => (
  {  
    type: ActionTypes.LOGIN,
      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/auth/login`,
        data,
        method: "post",
      },
    },
}
)

export const errorMsg = (data) => (
  {  
    type: ActionTypes.ERROR_MSG,  
    payload:{
      data      
    }     
}
)

export const disableMsg = (data) => (
  {  
    type: ActionTypes.DISABLE_MSG,  
    payload:{
      data      
    }     
}
)

export const successMsg = (data) => (
  {  
    type: ActionTypes.SUCCESS_MSG,  
    payload:{
      data      
    }     
}
)

export const forgotPass = (data) => (
  {  
    type: ActionTypes.FORGOT_PASSWORD,
      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/auth/forgetPassword`,
        data,
        method: "post",
      },
    },
}
)

export const emailValidator = (tokenid,userid) => (
  {  
    type: ActionTypes.EMAIL_VALIDATOR,
      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/auth/checkToken/${userid}`,        
        method: "get",
      },
    },
}
)

export const resetPass = ({newPassword, conformPassword,userid}) => (
  
  {  
    type: ActionTypes.RESET_PASSWORD,
      
    payload: {
      request: {
        url: `${process.env.REACT_APP_API_URL}/api/auth/resetPassword/${userid}`,
        data: {newPassword, conformPassword},
        method: "patch",
      },
    },
}
)

export const logout = () => ({
    type: ActionTypes.LOGOUT,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/auth/logout`,
            method: "get",
        }
    }
})

export const sendFCMToken =(token) => ({
    type: ActionTypes.FCM_TOKEN,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/auth/fcmToken`,
            method: "patch",
            data:{
                "fcmToken": token,
            }
        }
    },
    meta:{
            "fcmToken": token,
        }
});