import "./elements.css";
import React from "react";
import { useState } from "react";
import { Input } from "antd";
const { TextArea } = Input;

const TextEle = (props) => {

  const [value, setValue] = useState(props.ans?props.ans:"");

  const handleChange = (e)=>{
props.isPromoTypeQuestion ? props.handlePromoTypeQuestionChange(e.target.value, props.id,props.pqid): props.onChange(e.target.value,props.id);
setValue(e.target.value);
  }
  
  return (
    <div className="text-frame">
      <div className="text-frame-flex">
        {props.designate && props.designate === 'required' ? <span className="spanReq">*</span> : ''}
        <label className="input-label">{props.label}</label>
      </div>
      {(props.action==="create")&&
      (<>
      <TextArea className="input-text-field" onChange={handleChange} value={value} rows={5} placeholder="Enter Detail here"  />
      <span className="error-label">{props.error}</span>
      </>)    
      }
      {(props.action==="view")&&
      (<>
      <TextArea value={value} className="input-text-field" disabled/>
      </>)    
      }
      {(props.action==="edit")&&
      (<>
      <TextArea className="input-text-field" value={value} onChange={handleChange} rows={5} placeholder="Enter Detail here"  />
      </>)    
      }
    </div>
  );
};

export default TextEle;
