import './Map.css'
import { setPage } from "actions/app";
import CommonLayout from "components/layout/Layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Modal, Select, Spin, Tag } from 'antd';
import Fullscreen from "fullscreen-react";
import { ClockCircleOutlined, ColumnHeightOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FullscreenOutlined, LikeOutlined, ReloadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import CustomTable from 'components/common/table/Table';
import { MAP_LIST_COLUMNS } from 'utils/column';
import { useNavigate } from 'react-router';
import { MAP_SECONDS, SECONDS, TextMessage } from 'utils/constants';
import { clearMapsBySearch, deleteMapById, getAllMaps, getMapsBySearch, mapCreateMessage, updateChosenMapType, updateMapSearchFilterFlag, updateSelectedBrand, updateSelectedSku  } from 'actions/map'
import { Link } from 'react-router-dom';
import { getSKUBySearch } from 'actions/shelf';
import { getBrand } from 'actions/sku';
import session from 'utils/session';

const {Option} = Select

const Map = () => {
    const dispatch = useDispatch()
    const maps = useSelector(state => state.maps.maps)
    const mapBySearch = useSelector(state => state.maps.mapBySearch)
    const searchFilter = useSelector(state => state.maps.searchFilter)
    const mapStatus = useSelector(state => state.maps.mapStatus)
    const mapCreateText = useSelector(state => state.maps.mapCreateMessage)
    const selectedBrand = useSelector(state => state.maps.selectedBrand)
    const selectedSku = useSelector(state => state.maps.selectedSku)
    const chosenMapType = useSelector(state => state.maps.chosenMapType)
    const navigate = useNavigate()
    const [isEnter, setIsEnter] = useState(false);

    const [loadingState,setLoadingState] = useState(false)
    const [reloadingState,setReLoadingState] = useState(false)
    const [showDeleteAlert,setShowDeleteAlert] = useState(false)

    const [tableSize,setTableSize] = useState('default')
    const [tableClicked,setTableClicked] = useState(0)
    const [filter, setFilter] = useState("all");
    const [statusFilter, setStatusFilter] = useState(false);
    const [filtered, setFiltered] = useState([]);

    const [timer, setTimer] = useState(null)
    const [fetching, setFetching] = useState(false);
    const [dataSku, setDataSku] = useState([]);
    const [brandOptionData,setBrandOptionData] = useState([])

    const brandChildren = [];
    if(brandOptionData.length>0){
        for (let i = 0; i < brandOptionData.length; i++) {
                brandChildren.push(<Option key={brandOptionData[i].sku_brand_desc}>{brandOptionData[i].sku_brand_desc}</Option>);
            }
    }

    const skuChildren = [];
    if(dataSku.length>0){
        for (let i = 0; i < dataSku.length; i++) {
                skuChildren.push(<Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>);
            }
    }

    const approvedStatus = mapStatus && mapStatus.length>0 && mapStatus.filter(item => item?.status?.toLowerCase() === 'approved')
    const rejectedStatus = mapStatus && mapStatus.length>0 && mapStatus.filter(item => item?.status?.toLowerCase() === 'rejected')
    const pendingStatus = mapStatus && mapStatus.length>0 && mapStatus.filter(item => item?.status?.toLowerCase() === 'pending')

    useEffect(() => {

      if(mapCreateText){
        setTimeout(() => {
          dispatch(mapCreateMessage(''))
        }, MAP_SECONDS);
      }

      if(showDeleteAlert){
        setTimeout(() => {
          setShowDeleteAlert(false)
        }, SECONDS);
      }
      
    },[dispatch,showDeleteAlert,mapCreateText])

    useEffect(() => {
        dispatch(setPage('MAP'))
    },[dispatch])
    

    useEffect(() => {
      const getCall = async() =>{
        setLoadingState(true)
        const res = await dispatch(getAllMaps())
        if(res){
          setLoadingState(false)
        }
      }
      getCall()

      const getBrandCall = async () => {
            setFetching(true)
            const res = await dispatch(getBrand())
            
            if(res.error === undefined){
                setBrandOptionData(res.payload.data.data || [])
                setFetching(false)
            }
        }
        getBrandCall()
    },[dispatch])

    const handleShowCreate = () => {
        navigate('/map/create')
    }

    const setupRows = (maps) => {
        return maps?.map((o) => {
            const mapType = o.mapType ? o.mapType.toLowerCase() === 'sku' ? o.mapType.toUpperCase() :  o.mapType.charAt(0).toUpperCase() + o.mapType.slice(1) : ''
            const status = o.status ?  o.status.charAt(0).toUpperCase() + o.status.slice(1) : ''
            const mapCreatedBy = (o.createdBy && o.createdBy.userId === session.userId) ? true : false

            return {
                "_id": o._id,
                "mapType": mapType,
                "status": status.toLowerCase() === 'pending' ? <Tag color={"red"}>{status}</Tag> : status.toLowerCase() === 'approved' ? <Tag className="status-symbol-approved">{status}</Tag> :  <Tag className='status-symbol-rejected'>{status}</Tag>,
                "isStatus": status,
                "isMapType": mapType,
                "createdDate": o?.createdAt || '',
                "mapCreatedBy": mapCreatedBy,
                "isMap": true
            }
        }) 
    }

    const viewMap = (record) => {
      navigate(`/map/view/${record._id}`)
    }

    const handleSucessAlertClose = () => {
      dispatch(mapCreateMessage(''))
    }

    const deleteMap = (value) => {
          Modal.confirm({
            title: TextMessage.CONFIRMATION_TEXT,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () =>deleteMapYes(value),
            okType:'primary',
        });
    }

    const deleteMapYes = async (value) => {
        await dispatch(deleteMapById(value._id))
        await dispatch(getAllMaps())
        window.scrollTo(0, 0);
        setShowDeleteAlert(true)
    }

    const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
    }

    const handleTableHeight = () => {
      if(tableClicked === 2){
        setTableClicked(0)
        setTableSize('default')
      }
      if(tableClicked === 0){
        setTableClicked(tableClicked + 1)
        setTableSize('small')
      }
      if(tableClicked === 1){
        setTableClicked(tableClicked + 1)
        setTableSize('middle')
      }
    }

    const handleReload = async() => {
      setReLoadingState(true)
      const res = await dispatch(getAllMaps())
      if(res){
        setReLoadingState(false)
      }
    }

    const handleFilter = async(value) => {
      setFilter(value)
      if(value.toLowerCase() === 'pending'){
        if(searchFilter){
          const arr = mapBySearch.filter(item => item?.status.toLowerCase() === 'pending')
          setFiltered(arr)
          setStatusFilter(true)
          return;
        }
        if(!searchFilter){
          const arr = maps.filter(item => item?.status.toLowerCase() === 'pending')
          setFiltered(arr)
          setStatusFilter(true)
          return;
        }
      }

      if(value.toLowerCase() === 'all'){
        setStatusFilter(false)
        return;
      }
    }

    const handleSearchSkuKeys = async(value) => {
      clearTimeout(timer)

      if(value.length>=3){
        const newTimer = setTimeout(async() => {
        setFetching(true)
        setDataSku([])
        const res = await dispatch(getSKUBySearch(value))
        if(res.error === undefined){
          setDataSku(res.payload.data.data)
          setFetching(false)
        }else{
          setFetching(false)
        }
      }, 700)
      setTimer(newTimer)
      }

      
    }

    const handleSelectedSku = (value) => {
    dispatch(updateSelectedSku(value))
    dispatch(updateSelectedBrand([]))
  }

  const handleSelectedBrand = (value) => {
    dispatch(updateSelectedBrand(value))
    dispatch(updateSelectedSku([]))
  }

  const handleSearchChange = async () => {
    if(selectedBrand.length === 0 && selectedSku.length === 0){
      return;
    }

    if(selectedBrand.length>0){
      const str = selectedBrand.toString()
      setLoadingState(true)
      const res = await dispatch(getMapsBySearch('brand',str))

      if(res.error === undefined){
        setLoadingState(false)
        dispatch(updateMapSearchFilterFlag(true));
        return;
      }else{
        setLoadingState(false)
        dispatch(updateMapSearchFilterFlag(false))
      }

      return;
    }

    if(selectedSku.length>0){
      const str = selectedSku.toString()
      setLoadingState(true)
      const res = await dispatch(getMapsBySearch('sku',str))
      if(res.error === undefined){
        setLoadingState(false)
        dispatch(updateMapSearchFilterFlag(true))
        return;
      }else{
        setLoadingState(false)
      dispatch(updateMapSearchFilterFlag(false))
      }
      return;
    }

  }

  const handleSearchClear = async() => {
    if(!searchFilter) return;
    
    dispatch(updateSelectedSku([]))
    dispatch(updateSelectedBrand([]))
    dispatch(updateChosenMapType(null))
    setLoadingState(true)

    const res = await dispatch(getAllMaps())
    if(res){
      setLoadingState(false)
      dispatch(clearMapsBySearch([]))
      dispatch(updateMapSearchFilterFlag(false))
    }
  }

  const handleChosenMapType = (value) => {
    dispatch(updateChosenMapType(value))
  }

    return(
        <CommonLayout  className="no-content-mr" breadCrumbText='MAP'>
            {
              (loadingState || reloadingState) ? 
                <div className="errorTipDiv">
              <Spin size='middle' tip='Loading...'/>
            </div>
              : <>
                <div className="mapBodyTop">
                <div className="mapBodyTopBtn">
                    <div className="mapBodyTopRight">
                      <Select
                      value={chosenMapType}
                      placeholder='Select SKU/Brand'
                      style={{width:'180px'}}
                      onChange={handleChosenMapType}
                      >
                        <Option value='SKU'>SKU</Option>
                        <Option value='brand'>Brand</Option>
                      </Select>
                      
                      {
                        chosenMapType && chosenMapType === 'SKU' && <Select
                        showSearch
                          onChange={handleSelectedSku}
                        placeholder="Search and Select SKU"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchSkuKeys}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        mode={fetching ? 'multiple' :'tags'}
                        tokenSeparators={[',']}
                        maxTagCount={3}
                        value={(selectedSku &&selectedSku.length>0) ? selectedSku : []}
                        style={{width: (selectedSku && selectedSku.length === 0) ? '180px' : ''}}
                        >
                        {skuChildren.length>0 && skuChildren}
                    </Select>
                      }
                      
                      {
                        chosenMapType && chosenMapType === 'brand' && <Select
                        showSearch
                        placeholder="Search and select Brand"
                        optionFilterProp="children"
                        style={{width: (selectedBrand && selectedBrand.length === 0) ? '180px' : ''}}
                        mode='multiple'
                        onChange={handleSelectedBrand}
                        value={(selectedBrand && selectedBrand.length>0) ? selectedBrand : []}
                        maxTagCount={3}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {brandChildren.length>0 && brandChildren}
                    </Select>
                      }

                      {
                        chosenMapType && <>
                          <Button type="primary" className='addUserBtn' 
                      onClick={handleSearchChange}
                      >Search</Button>
                      <Button danger 
                      onClick={handleSearchClear}
                      >Clear</Button>
                        </>
                      }
                    </div>

                    <div className="mapBodyTopLeft">
                        <Button type="primary"
                        className='addErrorBtn' 
                        onClick={handleShowCreate}
                    >
                        Create MAP
                    </Button>
                    </div>
                </div>

                <div className="mapBodyTopSummary">
                    <p className="mapBodyTopSummaryHeadingText">MAP Summary</p>

                    <div className="content-live">
              <div className="layout-horizontal">
                <div className="static flex-zero">
                  <Link to={'/map/status/pending'}>
                    <div className="static-title">
                      <span className="static-title-text">
                       Pending to Review
                      </span>
                    </div>
                    <div className="static-value">
                      <div className="static-value-prefix">
                        <ClockCircleOutlined className="symbol" />
                      </div>
                      <div className="static-value-digit">
                        <span className="static-value-digit-text">
                         {
                            (pendingStatus && pendingStatus.length>0) ? pendingStatus[0].count : 0
                          }
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="static flex-zero">
                    <Link to={'/map/status/approved'}>
                          <div className="static-title">
                      <span className="static-title-text">Approved</span>
                    </div>
                    <div className="static-value">
                      <div className="static-value-prefix">
                        <LikeOutlined className="symbol-approved" />
                      </div>
                      <div className="static-value-digit">
                        <span className="static-value-digit-text">
                          {
                            (approvedStatus && approvedStatus.length>0) ? approvedStatus[0].count : 0
                          }
                        </span>
                      </div>
                    </div>
                    </Link>
                </div>

                <div className="static flex-zero">
                    <Link to={'/map/status/rejected'}>
                          <div className="static-title">
                      <span className="static-title-text">Rejected</span>
                    </div>
                    <div className="static-value">
                      <div className="static-value-prefix">
                        <EditOutlined className="symbol-rejected" />
                      </div>
                      <div className="static-value-digit">
                        <span className="static-value-digit-text">
                          {
                            (rejectedStatus && rejectedStatus.length>0) ? rejectedStatus[0].count : 0
                          }
                        </span>
                      </div>
                    </div>
                    </Link>
                </div>

              </div>
            </div>

                </div>
            </div>

            <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
              <div className="mapTableWrapper">
                  <div className="map-table-topbar">
                    <div className="mapbtnGrpWrapper">
                        <button 
                        className={filter === 'all' ? 'btnStyle' : 'btnStyle1'} 
                        onClick={() =>handleFilter('all')}
                        >
                            All MAP
                        </button>
                        <button 
                        className={filter === 'pending' ? 'btnStyle' : 'btnStyle1'} 
                        onClick={() => handleFilter('pending')}
                        >Pending to Review</button>
                    </div>

                    <div className="mapFilterGrpWrapper">
                        <div className="mapsettingGrp">
                            <ReloadOutlined 
                            onClick={handleReload} 
                            className='reloadIconStyle'/>
                            <ColumnHeightOutlined className='reloadIconStyle' 
                            onClick={handleTableHeight}
                            />
                            <FullscreenOutlined 
                            onClick={() => {setIsEnter(true)}} 
                            className='reloadIconStyle'/>
                        </div>
                    </div>
                </div>

                {/* table */}

                <div className='w-100'>
                    <CustomTable
                        columns={MAP_LIST_COLUMNS}
                        rows={statusFilter ? setupRows(filtered) : searchFilter ? setupRows(mapBySearch) : setupRows(maps)}
                        isDeleteable={true}
                        rowKey="_id"
                        isViewable={true}
                        onDelete={deleteMap}
                        total={maps?.length}
                        onView={viewMap}
                        size={tableSize}
                    />
                </div>

            </div>
            </Fullscreen>

            {
            mapCreateText && <Alert message={mapCreateText} type={'success'}
                        showIcon closable onClose={handleSucessAlertClose}
                        className='alertStyle'
                  />
            }

            {
              showDeleteAlert && 
                        <Alert message={TextMessage.MAP_DELETE_TEXT} type="error" 
                        showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined/>} className='alertStyle'
                        />
            }
              </>
            }
        </CommonLayout>
    )
}

export default Map;