import '../formBuilder/FormBuilder.css'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "components/layout/Layout";
import { setPage } from "actions/app";
import { Input, Select, Checkbox, DatePicker, Radio, Tabs, Modal, Spin, Alert } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, } from '@ant-design/icons';
import { TextMessage, questionTypesSelectOption, SECONDS } from 'utils/constants'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFormBuilder, deleteQuestionById, getAllQuestions, getFormBuilder, updateFormBuilder, updateQuestionById, } from 'actions/formBuilder';
import DropDownQuestion from './DropDownQuestionView';
import CheckBoxQuestionView from './CheckBoxQuestionView';
import DateQuestionView from './DateQuestionView';
import MultiSelectQuestionView from './MultiSelectQuestionView';
import TabQuestionView from './TabQuestionView';
import ShortAnswerQuestionView from './ShortAnswerQuestionView';
import TextAreaQuestionView from './TextAreaQuestionView';
import MultipleChoiceQuestionView from './MultipleChoiceQuestionView';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const Option = Select

const questionTypeOptionsChildren = [];
for (let i = 0; i < questionTypesSelectOption.length; i++) {
    questionTypeOptionsChildren.push(<Option key={questionTypesSelectOption[i]._id}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '5px' }}>{questionTypesSelectOption[i].icon}</span>
            <span>{questionTypesSelectOption[i].value}</span>
        </div>
    </Option>);
}

const RadioOptions = (options) => (
    options?.map((op, i) => (
        <Radio disabled>{op?.optionText}</Radio>
    ))
)

const CheckBoxOptions = (options) => (
    options?.map((op, i) => (
        <Checkbox disabled>{op?.optionText}</Checkbox>
    ))
)

const DropdownOptions = () => (
    <Select disabled value={null} placeholder='Select'></Select>
)

const TextareaOptions = () => (
    <TextArea rows={2} className='w-100' disabled placeholder='Write here' />
)

const ShortAnswerOptions = () => (
    <Input className='w-100' disabled placeholder='Write here' />
)

const DateOptions = () => (
    <RangePicker disabled />
)

const TabOptions = (options) => (
    <Tabs>
        {
            options?.map((op, i) => (
                <TabPane tab={op.optionText} key={i}>
                    {<Select mode='multi' disabled value={[]} placeholder='Multi Select' />}
                </TabPane>
            ))
        }
    </Tabs>
)

const MultiSelectOptions = () => (
    <Select disabled value={[]} placeholder='Multi-Select'></Select>
)

const FormBuilderView = () => {
    const questions = useSelector((state) => state.formBuilder.questions)
    const formBuilder = useSelector((state) => state.formBuilder.formBuilder)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [unPublishedQuestions, setUnPublishedQuestions] = useState([])
    const [selectedIndex, setSelectedIndex] = useState('')
    const [isFormBuilderExists, setIsFormBuilderExists] = useState(false)
    const [loadingState, setLoadingState] = useState(false)
    const [loading, setLoading] = useState(false)
    const [publishLoading, setPublishLoading] = useState(false)

    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [showUpdateAlert, setShowUpdateAlert] = useState(false)
    const [updateAlertText, setUpdateAlertText] = useState('')
    const [updateAlertType, setUpdateAlertType] = useState('')


    useEffect(() => {
        if (showUpdateAlert) {
            setTimeout(() => {
                setShowUpdateAlert(false)
            }, SECONDS);
        }
        if (showDeleteAlert) {
            setTimeout(() => {
                setShowDeleteAlert(false)
            }, SECONDS);
        }
    }, [showUpdateAlert, showDeleteAlert])
    useEffect(() => {
        dispatch(setPage('Form Builder View'))
    }, [dispatch])

    useEffect(() => {
        const getCall = async () => {
            setLoadingState(true)
            const res = await dispatch(getAllQuestions())
            const res1 = await dispatch(getFormBuilder())
            if (res && res1) {
                setLoadingState(false)
            } else {
                setLoadingState(false)
            }
        }
        getCall()
    }, [dispatch])

    useEffect(() => {
        if (questions) {
            setUnPublishedQuestions(questions.filter(item => item.isPublished === false && item.isPromoQuestion === true))
        }
    }, [questions])

    useEffect(() => {
        if (formBuilder && formBuilder.length > 0) {
            setIsFormBuilderExists(true)
        } else {
            setIsFormBuilderExists(false)
        }
    }, [formBuilder])

    const handleQuestionText = (text, i) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].questionHeader = text
        setUnPublishedQuestions(questionsArr)
    }

    const handleQuestionDesignate = (e, i) => {
        const questionsArr = [...unPublishedQuestions]
        if (e.target.checked === true) {
            questionsArr[i].designate = 'required'
        } else {
            questionsArr[i].designate = 'optional'
        }
        setUnPublishedQuestions(questionsArr)
    }

    const handleQuestionType = (value, i) => {
        const questionsArr = [...unPublishedQuestions]
        if (value === 'dropdown') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }

        if (value === 'date') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
        if (value === 'tabs') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
        if (value === 'multiSelect') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
        if (value === 'input') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
        if (value === 'textarea') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
        if (value === 'checkbox') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
        if (value === 'radio') {
            questionsArr[i].questionType = value
            setUnPublishedQuestions(questionsArr)
        }
    }

    const handleAddOptions = (i) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setUnPublishedQuestions(questionsArr)
    }

    const handleFormBuilderSelectMasterOption = (i, value) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i] = { ...questionsArr[i], isMaster: true, masterDataType: value }

        delete questionsArr[i].optionValue
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteOptions = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setUnPublishedQuestions(questionsArr)
    }

    const handleAddSubOptions = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j] = { ...questionsArr[i].optionValue[j], subOptions: [{ subOptionText: "", subOptionType: null }] }

        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteSubOptions = (i, j, k) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions.splice(k, 1)
        setUnPublishedQuestions(questionsArr)
    }

    const handleAddMoreSubOptions = (i, j, k) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions.push({ subOptionText: "", subOptionType: null })
        setUnPublishedQuestions(questionsArr)
    }

    const handleOptionTextChange = (text, i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setUnPublishedQuestions(questionsArr)
    }

    const handleSuboptionTextChange = (text, i, j, k) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionText: text }

        setUnPublishedQuestions(questionsArr)
    }
    const handleSubOptionTypeChange = (value, i, j, k) => {
        const questionsArr = [...unPublishedQuestions]
        if (value === 'input') {
            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }

            delete questionsArr[i].optionValue[j].subOptions[k].subOptions
            delete questionsArr[i].optionValue[j].subOptions[k].isMaster
            delete questionsArr[i].optionValue[j].subOptions[k].masterDataType

            setUnPublishedQuestions(questionsArr)
        } else if (value === 'dropdown') {
            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }

            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptions: [{ subOptionText: "" }] }

            setUnPublishedQuestions(questionsArr)

        } else {
            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }

            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptions: [{ subOptionText: "" }] }
            delete questionsArr[i].optionValue[j].subOptions[k].isMaster
            delete questionsArr[i].optionValue[j].subOptions[k].masterDataType
            setUnPublishedQuestions(questionsArr)
        }
    }

    const handleAddMoreInnerSubOptions = (i, j, k, l) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions.push({ subOptionText: "" })
        setUnPublishedQuestions(questionsArr)
    }

    const handleFormBuilderSelectMasterSubOption = (i, j, k, value) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], isMaster: true, masterDataType: value }
        delete questionsArr[i].optionValue[j].subOptions[k].subOptions
        setUnPublishedQuestions(questionsArr)

    }

    const handleAddInnerTwoSubOptions = (i, j, k, l) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l], subOptions: [{ subOptionText: '', subOptionType: null }] }
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteInnerSubOptions = (i, j, k, l) => {
        const questionsArr = [...unPublishedQuestions]
        questionsArr[i].optionValue[j].subOptions[k].subOptions.splice(l, 1)

        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteInnerTwoSubOptions = (i, j, k, l, m) => {
        const questionsArr = [...unPublishedQuestions]
        questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions.splice(m, 1)

        setUnPublishedQuestions(questionsArr)
    }

    const handleInnerSubOptionTextChange = (text, i, j, k, l) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l], subOptionText: text }

        setUnPublishedQuestions(questionsArr)
    }
    const handleInnerTwoSubOptionText = (text, i, j, k, l, m) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m], subOptionText: text }

        setUnPublishedQuestions(questionsArr)
    }

    const handleInnerTwoSubOptionType = (value, i, j, k, l, m) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m], subOptionType: value }

        setUnPublishedQuestions(questionsArr)
    }

    // functions for tabs

    const handleAddSubOptionsTab = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j] = { ...questionsArr[i].optionValue[j], subOptions: [{ subOptionText: "", subOptionType: null }] }

        setUnPublishedQuestions(questionsArr)
    }

    const handleAddOptionsTab = (i) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsTab = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setUnPublishedQuestions(questionsArr)
    }

    const handleOptionTextChangeTab = (text, i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setUnPublishedQuestions(questionsArr)
    }

    const handleSubOptionTypeChangeTab = (value, i, j, k) => {
        const questionsArr = [...unPublishedQuestions]
        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteSubOptionsTab = (i, j, k) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions.splice(k, 1)
        setUnPublishedQuestions(questionsArr)
    }
    const handleSuboptionTextChangeTab = (text, i, j, k) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionText: text }

        setUnPublishedQuestions(questionsArr)
    }

    //functions for short answer
    const handleAddOptionsShortAnswer = (i) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsShortAnswer = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setUnPublishedQuestions(questionsArr)
    }

    //functions for textarea
    const handleAddOptionsTextArea = (i) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsTextArea = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setUnPublishedQuestions(questionsArr)
    }

    const handleOptionTextChangeTextArea = (text, i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setUnPublishedQuestions(questionsArr)
    }

    //function for checkbox
    const handleAddOptionsCheckbox = (i) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setUnPublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsCheckbox = (i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setUnPublishedQuestions(questionsArr)
    }

    const handleOptionTextChangeCheckbox = (text, i, j) => {
        const questionsArr = [...unPublishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setUnPublishedQuestions(questionsArr)
    }


    const handleSaveQuestion = async (question, index) => {
        if (question.questionHeader === '') {
            const qArr = [...unPublishedQuestions]
            qArr[index].errors.questionHeaderError = 'This Field is Required'
            setUnPublishedQuestions(qArr)
            return;
        } else {
            const qArr = [...unPublishedQuestions]
            qArr[index].errors.questionHeaderError = null
            setUnPublishedQuestions(qArr)
        }

        if (!question.isMaster && (question.questionType === 'checkbox' || question.questionType === 'dropdown' || question.questionType === 'radio' || question.questionType === 'tabs' || question.questionType === 'multiSelect') && question.optionValue.length === 0) {
            const qArr = [...unPublishedQuestions]
            qArr[index].errors.optionTextError = 'Please add at least one option'
            setUnPublishedQuestions(qArr)
            return;
        } else {
            const qArr = [...unPublishedQuestions]
            qArr[index].errors.optionTextError = null
            setUnPublishedQuestions(qArr)
        }

        if (question.questionType === 'dropdown' || question.questionType === 'radio' || question.questionType === 'checkbox' ||
            question.questionType === 'tabs' || question.questionType === 'multiSelect') {
            if (!question.isMaster) {
                const isEmptySubOpt = question.optionValue.some(item => item.optionText === '')
                if (isEmptySubOpt) {
                    const qArr = [...unPublishedQuestions]
                    qArr[index].errors.optionTextError = 'Please add option value'
                    setUnPublishedQuestions(qArr)
                    return;
                } else {
                    const qArr = [...unPublishedQuestions]
                    qArr[index].errors.optionTextError = null
                    setUnPublishedQuestions(qArr)
                }
            }
        }

        if (question.questionType === 'dropdown' || question.questionType === 'radio' || question.questionType === 'tabs') {
            if (!question.isMaster) {
                for (let i = 0; i < question.optionValue.length; i++) {
                    const element = question.optionValue[i];
                    const isElementHasEmptySubOpt = element.subOptions && element.subOptions.some(sop => sop.subOptionText === '')
                    if (isElementHasEmptySubOpt) {
                        const qArr = [...unPublishedQuestions]

                        qArr[index].errors.subOptionTextError = 'This Field is Required'
                        setUnPublishedQuestions(qArr)
                        return;
                    } else {
                        const qArr = [...unPublishedQuestions]
                        qArr[index].errors.subOptionTextError = null
                        setUnPublishedQuestions(qArr)
                    }

                    const isElementHasEmptySubOptType = element.subOptions && element.subOptions.some(sop => sop.subOptionType === null)
                    if (isElementHasEmptySubOptType) {
                        const qArr = [...unPublishedQuestions]
                        qArr[index].errors.subOptionTypeError = 'This Field is Required'
                        setUnPublishedQuestions(qArr)
                        return;
                    } else {
                        const qArr = [...unPublishedQuestions]
                        qArr[index].errors.subOptionTypeError = null
                        setUnPublishedQuestions(qArr)
                    }
                }
            }
        }

        if (question.questionType === 'dropdown' || question.questionType === 'radio') {
            if (!question.isMaster) {
                for (let i = 0; i < question.optionValue.length; i++) {
                    const element = question.optionValue[i];
                    if (element.subOptions) {
                        for (let j = 0; j < element?.subOptions.length; j++) {
                            const subOptionsElement = element.subOptions[j];
                            if (!subOptionsElement.isMaster) {
                                const isSubOptionsElementHasEmptySubOpt = subOptionsElement.subOptions && subOptionsElement.subOptions.some(sop => sop.subOptionText === '')
                                if (isSubOptionsElementHasEmptySubOpt) {
                                    const qArr = [...unPublishedQuestions]
                                    qArr[index].errors.innerTwoSubOptionTextError = 'This Field is Required'
                                    setUnPublishedQuestions(qArr)
                                    return;
                                } else {
                                    const qArr = [...unPublishedQuestions]
                                    qArr[index].errors.innerTwoSubOptionTextError = null
                                    setUnPublishedQuestions(qArr)
                                }
                            }
                        }
                    }
                }
            }
        }



        let newRecord = {
            ...question
        }

        delete newRecord.errors

        setLoading(true)
        const response = await dispatch(updateQuestionById(newRecord, question._id))

        if (response.error === undefined) {
            setLoading(false)
            setSelectedIndex('')
            dispatch(getAllQuestions())
            setShowUpdateAlert(true)
            setUpdateAlertText(TextMessage.QUESTION_UPDATE_TEXT)
            setUpdateAlertType('success')
        } else {
            setSelectedIndex('')
            dispatch(getAllQuestions())
            setLoading(false)
            setShowUpdateAlert(true)
            setUpdateAlertText(TextMessage.ERROR_TEXT)
            setUpdateAlertType('error')
        }
    }

    const deleteQuestion = (val) => {
        Modal.confirm({
            title: TextMessage.CONFIRMATION_TEXT,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => deleteQuestionYes(val),
            okType: 'primary',
        });
    }

    const deleteQuestionYes = async (val) => {
        await dispatch(deleteQuestionById(val))
        setShowDeleteAlert(true)
    }
    const handlePublish = async () => {
        setPublishLoading(true)
        if (!isFormBuilderExists) {
            let ids = unPublishedQuestions.map(item => item._id)
            const response = await dispatch(createFormBuilder(ids))

            if (response.error === undefined) {
                setPublishLoading(false)
                setShowUpdateAlert(true)
                setUpdateAlertText(TextMessage.QUESTION_PUBLISH_TEXT)
                setUpdateAlertType('success')
                await dispatch(getAllQuestions())
                await dispatch(getFormBuilder())
            } else {
                setPublishLoading(false)
                setShowUpdateAlert(true)
                setUpdateAlertText(TextMessage.ERROR_TEXT)
                setUpdateAlertType('error')
                await dispatch(getAllQuestions())
                await dispatch(getFormBuilder())
            }

        } else {
            let id = formBuilder[0]._id
            let ids = unPublishedQuestions.map(item => item._id)
            const response = await dispatch(updateFormBuilder(ids, id))
            if (response.error === undefined) {
                setPublishLoading(false)
                setShowUpdateAlert(true)
                setUpdateAlertText(TextMessage.QUESTION_PUBLISH_TEXT)
                setUpdateAlertType('success')
                await dispatch(getAllQuestions())
                await dispatch(getFormBuilder())
            } else {
                setPublishLoading(false)
                setShowUpdateAlert(true)
                setUpdateAlertText(TextMessage.ERROR_TEXT)
                setUpdateAlertType('error')
                await dispatch(getAllQuestions())
                await dispatch(getFormBuilder())
            }
        }
    }

    const EditQuestion = (question, i) => {
        const questionsArr = [...unPublishedQuestions]

        if (question.questionType === 'dropdown' || question.questionType === 'tabs' || question.questionType === 'radio' ||
            question.questionType === 'checkbox' || question.questionType === 'multiSelect') {
            questionsArr[i] = { ...questionsArr[i], errors: { questionHeaderError: null, optionTextError: null, subOptionTextError: null, innerSubOptionTextError: null, innerTwoSubOptionTextError: null, subOptionTypeError: null } }
            setUnPublishedQuestions(questionsArr)
        }

        if (question.questionType === 'date' || question.questionType === 'input' || question.questionType === 'textarea') {
            questionsArr[i] = { ...questionsArr[i], errors: { questionHeaderError: null } }
            setUnPublishedQuestions(questionsArr)
        }
        setSelectedIndex(i)
    }

    const handleCancelEditView = async () => {
        await dispatch(getAllQuestions())
        setSelectedIndex('')
    }

    const handleUpdateAlertClose = () => {
        setShowUpdateAlert(false)
    }

    const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
    }

    const renderEditQuestion = (selectedQs, i) => (
        <div className="formBuilderFrameTwo">
            <div className={'formBuilderFrameTwoActiveDeck'}>
                <div className="formBuilderFrameTwoPromoForm">
                    <div className="formBuilderFrameTwoPromoFormFrame">

                        <div className="formBuilderFrameTwoPromoQuestion">
                            <label className='formBuilderFrameTwoPromoLabel'>Question</label>
                            <input type="text"
                                className='formBuilderFrameTwoPromoQuestionInput'
                                placeholder='Write Here'
                                value={selectedQs.questionHeader}
                                onChange={(e) => handleQuestionText(e.target.value, i)}
                            />
                            <span className='errorLabel'>{selectedQs?.errors?.questionHeaderError}</span>
                        </div>

                        <div className="formBuilderFrameTwoPromoType">
                            <label className='formBuilderFrameTwoPromoLabelType'>Type</label>
                            <div className='formBuilderFrameTwoPromoTypeSelect'>
                                <Select
                                    className='formBuilderFrameTwoPromoTypeSelectBtn'
                                    value={selectedQs.questionType}
                                    placeholder='Select Question Type'
                                    onChange={(value) => handleQuestionType(value, i)}
                                >
                                    {questionTypeOptionsChildren}
                                </Select>
                            </div>
                        </div>

                    </div>
                </div>

                {
                    selectedQs.questionType === 'dropdown' && <DropDownQuestion
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        index={i}
                        questionTypeOptionsChildren={questionTypeOptionsChildren}
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleSubOptionTypeChange={handleSubOptionTypeChange}
                        handleDeleteSubOptions={handleDeleteSubOptions}
                        handleSuboptionTextChange={handleSuboptionTextChange}
                        handleInnerSubOptionTextChange={handleInnerSubOptionTextChange}
                        handleAddInnerTwoSubOptions={handleAddInnerTwoSubOptions}
                        handleDeleteInnerSubOptions={handleDeleteInnerSubOptions}
                        handleInnerTwoSubOptionText={handleInnerTwoSubOptionText}
                        handleInnerTwoSubOptionType={handleInnerTwoSubOptionType}
                        handleDeleteInnerTwoSubOptions={handleDeleteInnerTwoSubOptions}
                        handleAddMoreInnerSubOptions={handleAddMoreInnerSubOptions}
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        handleAddOptions={handleAddOptions}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleFormBuilderSelectMasterSubOption={handleFormBuilderSelectMasterSubOption}
                        handleFormBuilderSelectMasterOption={handleFormBuilderSelectMasterOption}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'checkbox' && <CheckBoxQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleDeleteOptionsCheckbox={handleDeleteOptionsCheckbox}
                        handleOptionTextChangeCheckbox={handleOptionTextChangeCheckbox}
                        handleAddOptionsCheckbox={handleAddOptionsCheckbox}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'date' && <DateQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }
                {
                    selectedQs.questionType === 'multiSelect' && <MultiSelectQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleAddOptions={handleAddOptions}
                        handleFormBuilderSelectMasterOption={handleFormBuilderSelectMasterOption}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'tabs' && <TabQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleAddSubOptionsTab={handleAddSubOptionsTab}
                        handleAddOptionsTab={handleAddOptionsTab}
                        handleDeleteOptionsTab={handleDeleteOptionsTab}
                        handleOptionTextChangeTab={handleOptionTextChangeTab}
                        handleSubOptionTypeChangeTab={handleSubOptionTypeChangeTab}
                        handleDeleteSubOptionsTab={handleDeleteSubOptionsTab}
                        handleSuboptionTextChangeTab={handleSuboptionTextChangeTab}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'input' && <ShortAnswerQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleAddOptionsShortAnswer={handleAddOptionsShortAnswer}
                        handleDeleteOptionsShortAnswer={handleDeleteOptionsShortAnswer}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'textarea' && <TextAreaQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleAddOptionsTextArea={handleAddOptionsTextArea}
                        handleDeleteOptionsTextArea={handleDeleteOptionsTextArea}
                        handleOptionTextChangeTextArea={handleOptionTextChangeTextArea}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'radio' && <MultipleChoiceQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleAddOptions={handleAddOptions}
                        index={i}
                        handleSubOptionTypeChange={handleSubOptionTypeChange}
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteSubOptions={handleDeleteSubOptions}
                        handleSuboptionTextChange={handleSuboptionTextChange}
                        handleInnerSubOptionTextChange={handleInnerSubOptionTextChange}
                        handleAddInnerTwoSubOptions={handleAddInnerTwoSubOptions}
                        handleDeleteInnerSubOptions={handleDeleteInnerSubOptions}
                        handleInnerTwoSubOptionText={handleInnerTwoSubOptionText}
                        handleInnerTwoSubOptionType={handleInnerTwoSubOptionType}
                        handleDeleteInnerTwoSubOptions={handleDeleteInnerTwoSubOptions}
                        handleAddMoreInnerSubOptions={handleAddMoreInnerSubOptions}
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        questionTypeOptionsChildren={questionTypeOptionsChildren}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleFormBuilderSelectMasterSubOption={handleFormBuilderSelectMasterSubOption}
                        handleFormBuilderSelectMasterOption={handleFormBuilderSelectMasterOption}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

            </div>
        </div>
    )

    const handleRouteChange = (e) => {
        e.preventDefault()
        navigate(e.target.value)
    }

    return (
        <CommonLayout breadCrumbText='Form Builder'>
            {
                !loadingState ?
                    <div className="formBuilderBody">
                        <div className="formBuilderFrameWrapper">
                            <div className="formBuilderContentWrapper">

                                <div className="formBuilderFrameOne">
                                    <div className="formBuilderFrameOneLeft">
                                        <Radio.Group
                                            value={`/form-builder/view`}
                                            onChange={handleRouteChange}
                                        >
                                            <Radio.Button value={`/form-builder`} >Editing</Radio.Button>
                                            <Radio.Button value={`/form-builder/view`}
                                                style={{ background: '#0F2A6F', borderColor: '#0F2A6F', color: 'white' }}
                                            >Viewing</Radio.Button>
                                            <Radio.Button value={`/form-builder/publish`}>Published</Radio.Button>
                                        </Radio.Group>
                                    </div>

                                    <div className="formBuilderFrameOneRight">
                                        <button className={(unPublishedQuestions.length === 0 || publishLoading) ? 'formBuilderFrameOnePublishBtn' : 'formBuilderFrameOnePublishBtnEnable'}
                                            disabled={unPublishedQuestions.length === 0 || publishLoading}
                                            onClick={handlePublish}
                                        >{publishLoading ? 'Saving' : 'Publish'}</button>
                                    </div>
                                </div>



                            </div>

                            <div className="formBuilderViewWrapper">
                                {
                                    unPublishedQuestions.length > 0 ? unPublishedQuestions.map((question, i) => {
                                        if (i === selectedIndex) {
                                            return renderEditQuestion(question, i)

                                        }

                                        return <>
                                            <div className="formBuilderViewContent" key={i}>
                                                <div className='w-100 formBuilderViewLabelWrapper'>
                                                    <label className='saveQuestionLabel'>{question.questionHeader}</label>
                                                    <div className='formBuilderViewBtnGrp'>
                                                        <EditOutlined
                                                            onClick={() => EditQuestion(question, i)}
                                                            className='formBuilderViewEditBtn'
                                                        />

                                                        <DeleteOutlined
                                                            className='cp'
                                                            onClick={() => deleteQuestion(question._id)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="savedQuesOptionDiv">
                                                    {question.questionType === 'radio' && RadioOptions(question?.optionValue)}
                                                    {question.questionType === 'checkbox' && CheckBoxOptions(question?.optionValue)}
                                                    {question.questionType === 'dropdown' && DropdownOptions()}
                                                    {question.questionType === 'textarea' && TextareaOptions()}
                                                    {question.questionType === 'input' && ShortAnswerOptions()}
                                                    {question.questionType === 'date' && DateOptions()}
                                                    {question.questionType === 'tabs' && TabOptions(question?.optionValue)}
                                                    {question.questionType === 'multiSelect' && MultiSelectOptions()}
                                                </div>
                                            </div>


                                        </>
                                    }) : ''
                                }
                            </div>
                        </div>

                        {
                            showDeleteAlert &&
                            <Alert message={TextMessage.QUESTION_DELETE_TEXT} type="error"
                                showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined />} className='alertStyle'
                            />
                        }

                        {
                            showUpdateAlert &&
                            <Alert message={updateAlertText} type={updateAlertType}
                                showIcon closable onClose={handleUpdateAlertClose} className='alertStyle'
                            />
                        }
                    </div>

                    :
                    <div className="errorTipDiv">
                        <Spin size='middle' tip='Loading...' />
                    </div>
            }
        </CommonLayout>
    )
}

export default FormBuilderView;