import BottomSection from "./BottomSection"
const DateQuestionView = ({
  question,
  handleCancelEditView,
  handleQuestionDesignate,
  handleSaveQuestion,
  index,
  loading,
  handleStandardTeamChange,
  handleEmergencyTeamChange
}) => {
  return (
    <>
      <BottomSection
        isSameType={question.questionType === "date"}
        onCheckboxChange={(e) => handleQuestionDesignate(e, index)}
        onSaveClick={() => handleSaveQuestion(question, index)}
        loading={loading}
        handleCancelEditView={handleCancelEditView}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
      />
    </>
  );
};

export default DateQuestionView;
