import '../formBuilder/FormBuilder.css'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "components/layout/Layout";
import { setPage } from "actions/app";
import { Input, Select, Checkbox, DatePicker, Radio, Tabs, Spin, Modal, Alert } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, EditOutlined } from '@ant-design/icons';
import { TextMessage, SECONDS, questionTypesSelectOption } from 'utils/constants'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { changeQuestionOrder, deleteQuestionFromFormBuilder, getAllQuestions, getFormBuilder, updateQuestionById } from 'actions/formBuilder';
import MultipleChoiceQuestionView from 'containers/formBuilderView/MultipleChoiceQuestionView';
import TextAreaQuestionView from 'containers/formBuilderView/TextAreaQuestionView';
import ShortAnswerQuestionView from 'containers/formBuilderView/ShortAnswerQuestionView';
import TabQuestionView from 'containers/formBuilderView/TabQuestionView';
import MultiSelectQuestionView from 'containers/formBuilderView/MultiSelectQuestionView';
import DateQuestionView from 'containers/formBuilderView/DateQuestionView';
import CheckBoxQuestionView from 'containers/formBuilderView/CheckBoxQuestionView';
import DropDownQuestion from 'containers/formBuilderView/DropDownQuestionView';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const Option = Select

const questionTypeOptionsChildren = [];
for (let i = 0; i < questionTypesSelectOption.length; i++) {
    questionTypeOptionsChildren.push(<Option key={questionTypesSelectOption[i]._id}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '5px' }}>{questionTypesSelectOption[i].icon}</span>
            <span>{questionTypesSelectOption[i].value}</span>
        </div>
    </Option>);
}

const RadioOptions = (options) => (
    options?.map((op, i) => (
        <Radio disabled>{op?.optionText}</Radio>
    ))
)

const CheckBoxOptions = (options) => (
    options?.map((op, i) => (
        <Checkbox disabled>{op?.optionText}</Checkbox>
    ))
)

const DropdownOptions = () => (
    <Select disabled value={null} placeholder='Select'></Select>
)

const TextareaOptions = () => (
    <TextArea rows={2} className='w-100' disabled placeholder='Write here' />
)

const ShortAnswerOptions = () => (
    <Input className='w-100' disabled placeholder='Write here' />
)

const DateOptions = () => (
    <RangePicker disabled />
)

const TabOptions = (options) => (
    <Tabs>
        {
            options.map((op, i) => (
                <TabPane tab={op.optionText} key={i}>
                    {<Select mode='multi' disabled value={[]} placeholder='Multi Select' />}
                </TabPane>
            ))
        }
    </Tabs>
)

const FormBuilderPublish = () => {
    const formBuilder = useSelector((state) => state.formBuilder.formBuilder)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [publishedQuestions, setpublishedQuestions] = useState([])
    const [loadingState, setLoadingState] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [formBuilderId, setFormBuilderId] = useState('')
    const [selectedIndex, setSelectedIndex] = useState('')
    const [showUpdateAlert, setShowUpdateAlert] = useState(false)
    const [updateAlertText, setUpdateAlertText] = useState('')
    const [updateAlertType, setUpdateAlertType] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (showUpdateAlert) {
            setTimeout(() => {
                setShowUpdateAlert(false)
            }, SECONDS);
        }
        if (showDeleteAlert) {
            setTimeout(() => {
                setShowDeleteAlert(false)
            }, SECONDS);
        }
    }, [showDeleteAlert, showUpdateAlert])

    useEffect(() => {
        dispatch(setPage('Form Builder Publish'))
    }, [dispatch])

    useEffect(() => {
        const getCall = async () => {
            setLoadingState(true)
            const res = await dispatch(getAllQuestions())
            const res1 = await dispatch(getFormBuilder())
            if (res && res1) {
                setLoadingState(false)
            } else {
                setLoadingState(false)
            }
        }
        getCall()
    }, [dispatch])

    useEffect(() => {
        if (formBuilder && formBuilder.length > 0) {
            setFormBuilderId(formBuilder[0]._id)
            setpublishedQuestions(formBuilder[0].questionIds)
        }
    }, [formBuilder])

    const handleDeleteQuestionFormBuilder = (qId, fId) => {
        Modal.confirm({
            title: TextMessage.CONFIRMATION_TEXT,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => deleteQuestionYes(qId, fId),
            okType: 'primary',
        });
    }

    const deleteQuestionYes = async (qId, fId) => {
        await dispatch(deleteQuestionFromFormBuilder(qId, fId))
        await dispatch(getFormBuilder())
        setShowDeleteAlert(true)
    }

    const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
    }

    const handleUpdateAlertClose = () => {
        setShowUpdateAlert(false)
    }

    const handleDrag = (e, index) => {
        e.dataTransfer.setData("itemIndex", index);
    };

    const handleDrop = async (e, index) => {
        const movingItemIndex = e.dataTransfer.getData("itemIndex");
        const targetItemIndex = index;
        let allQuestions = publishedQuestions;

        let movingItem = allQuestions[movingItemIndex];
        allQuestions.splice(movingItemIndex, 1);
        allQuestions.splice(targetItemIndex, 0, movingItem);

        const ids = allQuestions.map((item) => item._id)

        const response = await dispatch(changeQuestionOrder(ids, formBuilderId))

        if (response.error === undefined) {
            setShowUpdateAlert(true)
            setUpdateAlertText("Question Order Changed Successfully")
            setUpdateAlertType('success')
            await dispatch(getFormBuilder())
        } else {
            setShowUpdateAlert(true)
            setUpdateAlertText(TextMessage.ERROR_TEXT)
            setUpdateAlertType('error')
        }

    };

    const handleQuestionText = (text, i) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].questionHeader = text
        setpublishedQuestions(questionsArr)
    }

    const handleQuestionDesignate = (e, i) => {
        const questionsArr = [...publishedQuestions]
        if (e.target.checked === true) {
            questionsArr[i].designate = 'required'
        } else {
            questionsArr[i].designate = 'optional'
        }
        setpublishedQuestions(questionsArr)
    }

    const handleQuestionType = (value, i) => {
        const questionsArr = [...publishedQuestions]
        if (value === 'dropdown') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }

        if (value === 'date') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
        if (value === 'tabs') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
        if (value === 'multiSelect') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
        if (value === 'input') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
        if (value === 'textarea') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
        if (value === 'checkbox') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
        if (value === 'radio') {
            questionsArr[i].questionType = value
            setpublishedQuestions(questionsArr)
        }
    }

    const handleAddOptions = (i) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setpublishedQuestions(questionsArr)
    }

    const handleFormBuilderSelectMasterOption = (i, value) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i] = { ...questionsArr[i], isMaster: true, masterDataType: value }

        delete questionsArr[i].optionValue
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteOptions = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setpublishedQuestions(questionsArr)
    }

    const handleAddSubOptions = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j] = { ...questionsArr[i].optionValue[j], subOptions: [{ subOptionText: "", subOptionType: null }] }

        setpublishedQuestions(questionsArr)
    }

    const handleDeleteSubOptions = (i, j, k) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions.splice(k, 1)
        setpublishedQuestions(questionsArr)
    }

    const handleAddMoreSubOptions = (i, j, k) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions.push({ subOptionText: "", subOptionType: null })
        setpublishedQuestions(questionsArr)
    }

    const handleOptionTextChange = (text, i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setpublishedQuestions(questionsArr)
    }

    const handleSuboptionTextChange = (text, i, j, k) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionText: text }

        setpublishedQuestions(questionsArr)
    }
    const handleSubOptionTypeChange = (value, i, j, k) => {
        const questionsArr = [...publishedQuestions]
        if (value === 'input') {
            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }

            delete questionsArr[i].optionValue[j].subOptions[k].subOptions
            delete questionsArr[i].optionValue[j].subOptions[k].isMaster
            delete questionsArr[i].optionValue[j].subOptions[k].masterDataType

            setpublishedQuestions(questionsArr)
        } else if (value === 'dropdown') {
            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }

            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptions: [{ subOptionText: "" }] }

            setpublishedQuestions(questionsArr)

        } else {
            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }

            questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptions: [{ subOptionText: "" }] }
            delete questionsArr[i].optionValue[j].subOptions[k].isMaster
            delete questionsArr[i].optionValue[j].subOptions[k].masterDataType
            setpublishedQuestions(questionsArr)
        }
    }

    const handleAddMoreInnerSubOptions = (i, j, k, l) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions.push({ subOptionText: "" })
        setpublishedQuestions(questionsArr)
    }

    const handleFormBuilderSelectMasterSubOption = (i, j, k, value) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], isMaster: true, masterDataType: value }
        delete questionsArr[i].optionValue[j].subOptions[k].subOptions
        setpublishedQuestions(questionsArr)

    }

    const handleAddInnerTwoSubOptions = (i, j, k, l) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l], subOptions: [{ subOptionText: '', subOptionType: null }] }
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteInnerSubOptions = (i, j, k, l) => {
        const questionsArr = [...publishedQuestions]
        questionsArr[i].optionValue[j].subOptions[k].subOptions.splice(l, 1)

        setpublishedQuestions(questionsArr)
    }

    const handleDeleteInnerTwoSubOptions = (i, j, k, l, m) => {
        const questionsArr = [...publishedQuestions]
        questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions.splice(m, 1)

        setpublishedQuestions(questionsArr)
    }

    const handleInnerSubOptionTextChange = (text, i, j, k, l) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l], subOptionText: text }

        setpublishedQuestions(questionsArr)
    }
    const handleInnerTwoSubOptionText = (text, i, j, k, l, m) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m], subOptionText: text }

        setpublishedQuestions(questionsArr)
    }

    const handleInnerTwoSubOptionType = (value, i, j, k, l, m) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = { ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m], subOptionType: value }

        setpublishedQuestions(questionsArr)
    }

    // functions for tabs

    const handleAddSubOptionsTab = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j] = { ...questionsArr[i].optionValue[j], subOptions: [{ subOptionText: "", subOptionType: null }] }

        setpublishedQuestions(questionsArr)
    }

    const handleAddOptionsTab = (i) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsTab = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setpublishedQuestions(questionsArr)
    }

    const handleOptionTextChangeTab = (text, i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setpublishedQuestions(questionsArr)
    }

    const handleSubOptionTypeChangeTab = (value, i, j, k) => {
        const questionsArr = [...publishedQuestions]
        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionType: value }
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteSubOptionsTab = (i, j, k) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions.splice(k, 1)
        setpublishedQuestions(questionsArr)
    }
    const handleSuboptionTextChangeTab = (text, i, j, k) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].subOptions[k] = { ...questionsArr[i].optionValue[j].subOptions[k], subOptionText: text }

        setpublishedQuestions(questionsArr)
    }

    //functions for short answer
    const handleAddOptionsShortAnswer = (i) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsShortAnswer = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setpublishedQuestions(questionsArr)
    }

    //functions for textarea
    const handleAddOptionsTextArea = (i) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.push({ optionText: '' })
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsTextArea = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setpublishedQuestions(questionsArr)
    }

    const handleOptionTextChangeTextArea = (text, i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setpublishedQuestions(questionsArr)
    }

    //function for checkbox
    const handleAddOptionsCheckbox = (i) => {
        const questionsArr = [...publishedQuestions]
        questionsArr[i].optionValue = (questionsArr[i].optionValue) ? [...questionsArr[i].optionValue, { optionText: '' }] : [{ optionText: '' }]
        setpublishedQuestions(questionsArr)
    }

    const handleDeleteOptionsCheckbox = (i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue.splice(j, 1)
        setpublishedQuestions(questionsArr)
    }

    const handleOptionTextChangeCheckbox = (text, i, j) => {
        const questionsArr = [...publishedQuestions]

        questionsArr[i].optionValue[j].optionText = text

        setpublishedQuestions(questionsArr)
    }

    const EditQuestion = (question, i) => {
        const questionsArr = [...publishedQuestions]

        if (question.questionType === 'dropdown' || question.questionType === 'tabs' || question.questionType === 'radio' ||
            question.questionType === 'checkbox' || question.questionType === 'multiSelect') {
            questionsArr[i] = { ...questionsArr[i], errors: { questionHeaderError: null, optionTextError: null, subOptionTextError: null, innerSubOptionTextError: null, innerTwoSubOptionTextError: null, subOptionTypeError: null } }
            setpublishedQuestions(questionsArr)
        }

        if (question.questionType === 'date' || question.questionType === 'input' || question.questionType === 'textarea') {
            questionsArr[i] = { ...questionsArr[i], errors: { questionHeaderError: null } }
            setpublishedQuestions(questionsArr)
        }
        setSelectedIndex(i)
    }

    const handleCancelEditView = async () => {
        await dispatch(getFormBuilder())
        setSelectedIndex('')
    }

    const handleSaveQuestion = async (question, index) => {
        if (question.questionHeader === '') {
            const qArr = [...publishedQuestions]
            qArr[index].errors.questionHeaderError = 'This Field is Required'
            setpublishedQuestions(qArr)
            return;
        } else {
            const qArr = [...publishedQuestions]
            qArr[index].errors.questionHeaderError = null
            setpublishedQuestions(qArr)
        }

        if (!question.isMaster && (question.questionType === 'checkbox' || question.questionType === 'dropdown' || question.questionType === 'radio' || question.questionType === 'tabs' || question.questionType === 'multiSelect') && question.optionValue.length === 0) {
            const qArr = [...publishedQuestions]
            qArr[index].errors.optionTextError = 'Please add at least one option'
            setpublishedQuestions(qArr)
            return;
        } else {
            const qArr = [...publishedQuestions]
            qArr[index].errors.optionTextError = null
            setpublishedQuestions(qArr)
        }

        if (question.questionType === 'dropdown' || question.questionType === 'radio' || question.questionType === 'checkbox' ||
            question.questionType === 'tabs' || question.questionType === 'multiSelect') {
            if (!question.isMaster) {
                const isEmptySubOpt = question.optionValue.some(item => item.optionText === '')
                if (isEmptySubOpt) {
                    const qArr = [...publishedQuestions]
                    qArr[index].errors.optionTextError = 'Please add option value'
                    setpublishedQuestions(qArr)
                    return;
                } else {
                    const qArr = [...publishedQuestions]
                    qArr[index].errors.optionTextError = null
                    setpublishedQuestions(qArr)
                }
            }
        }

        if (question.questionType === 'dropdown' || question.questionType === 'radio' || question.questionType === 'tabs') {
            if (!question.isMaster) {
                for (let i = 0; i < question.optionValue.length; i++) {
                    const element = question.optionValue[i];
                    const isElementHasEmptySubOpt = element.subOptions && element.subOptions.some(sop => sop.subOptionText === '')
                    if (isElementHasEmptySubOpt) {
                        const qArr = [...publishedQuestions]

                        qArr[index].errors.subOptionTextError = 'This Field is Required'
                        setpublishedQuestions(qArr)
                        return;
                    } else {
                        const qArr = [...publishedQuestions]
                        qArr[index].errors.subOptionTextError = null
                        setpublishedQuestions(qArr)
                    }

                    const isElementHasEmptySubOptType = element.subOptions && element.subOptions.some(sop => sop.subOptionType === null)
                    if (isElementHasEmptySubOptType) {
                        const qArr = [...publishedQuestions]
                        qArr[index].errors.subOptionTypeError = 'This Field is Required'
                        setpublishedQuestions(qArr)
                        return;
                    } else {
                        const qArr = [...publishedQuestions]
                        qArr[index].errors.subOptionTypeError = null
                        setpublishedQuestions(qArr)
                    }
                }
            }
        }

        if (question.questionType === 'dropdown' || question.questionType === 'radio') {
            if (!question.isMaster) {
                for (let i = 0; i < question.optionValue.length; i++) {
                    const element = question.optionValue[i];
                    if (element.subOptions) {
                        for (let j = 0; j < element?.subOptions.length; j++) {
                            const subOptionsElement = element.subOptions[j];
                            if (!subOptionsElement.isMaster) {
                                const isSubOptionsElementHasEmptySubOpt = subOptionsElement.subOptions && subOptionsElement.subOptions.some(sop => sop.subOptionText === '')
                                if (isSubOptionsElementHasEmptySubOpt) {
                                    const qArr = [...publishedQuestions]
                                    qArr[index].errors.innerTwoSubOptionTextError = 'This Field is Required'
                                    setpublishedQuestions(qArr)
                                    return;
                                } else {
                                    const qArr = [...publishedQuestions]
                                    qArr[index].errors.innerTwoSubOptionTextError = null
                                    setpublishedQuestions(qArr)
                                }
                            }
                        }
                    }
                }
            }
        }



        let newRecord = {
            ...question
        }

        delete newRecord.errors

        setLoading(true)
        const response = await dispatch(updateQuestionById(newRecord, question._id))

        if (response.error === undefined) {
            setLoading(false)
            setSelectedIndex('')
            dispatch(getFormBuilder())
            setShowUpdateAlert(true)
            setUpdateAlertText("Question has been updated successfully")
            setUpdateAlertType('success')
        } else {
            setSelectedIndex('')
            dispatch(getFormBuilder())
            setLoading(false)
            setShowUpdateAlert(true)
            setUpdateAlertText(TextMessage.ERROR_TEXT)
            setUpdateAlertType('error')
        }
    }

    const renderEditQuestion = (selectedQs, i) => (
        <div className="formBuilderFrameTwo">
            <div className={'formBuilderFrameTwoActiveDeck'}>
                <div className="formBuilderFrameTwoPromoForm">
                    <div className="formBuilderFrameTwoPromoFormFrame">

                        <div className="formBuilderFrameTwoPromoQuestion">
                            <label className='formBuilderFrameTwoPromoLabel'>Question</label>
                            <input type="text"
                                className='formBuilderFrameTwoPromoQuestionInput'
                                placeholder='Write Here'
                                value={selectedQs.questionHeader}
                                onChange={(e) => handleQuestionText(e.target.value, i)}
                            />
                            <span className='errorLabel'>{selectedQs?.errors?.questionHeaderError}</span>
                        </div>

                        <div className="formBuilderFrameTwoPromoType">
                            <label className='formBuilderFrameTwoPromoLabelType'>Type</label>
                            <div className='formBuilderFrameTwoPromoTypeSelect'>
                                <Select
                                    className='formBuilderFrameTwoPromoTypeSelectBtn'
                                    value={selectedQs.questionType}
                                    placeholder='Select Question Type'
                                    onChange={(value) => handleQuestionType(value, i)}
                                >
                                    {questionTypeOptionsChildren}
                                </Select>
                            </div>
                        </div>

                    </div>
                </div>

                {
                    selectedQs.questionType === 'dropdown' && <DropDownQuestion
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        index={i}
                        questionTypeOptionsChildren={questionTypeOptionsChildren}
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleSubOptionTypeChange={handleSubOptionTypeChange}
                        handleDeleteSubOptions={handleDeleteSubOptions}
                        handleSuboptionTextChange={handleSuboptionTextChange}
                        handleInnerSubOptionTextChange={handleInnerSubOptionTextChange}
                        handleAddInnerTwoSubOptions={handleAddInnerTwoSubOptions}
                        handleDeleteInnerSubOptions={handleDeleteInnerSubOptions}
                        handleInnerTwoSubOptionText={handleInnerTwoSubOptionText}
                        handleInnerTwoSubOptionType={handleInnerTwoSubOptionType}
                        handleDeleteInnerTwoSubOptions={handleDeleteInnerTwoSubOptions}
                        handleAddMoreInnerSubOptions={handleAddMoreInnerSubOptions}
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        handleAddOptions={handleAddOptions}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleFormBuilderSelectMasterSubOption={handleFormBuilderSelectMasterSubOption}
                        handleFormBuilderSelectMasterOption={handleFormBuilderSelectMasterOption}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'checkbox' && <CheckBoxQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleDeleteOptionsCheckbox={handleDeleteOptionsCheckbox}
                        handleOptionTextChangeCheckbox={handleOptionTextChangeCheckbox}
                        handleAddOptionsCheckbox={handleAddOptionsCheckbox}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'date' && <DateQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }
                {
                    selectedQs.questionType === 'multiSelect' && <MultiSelectQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleAddOptions={handleAddOptions}
                        handleFormBuilderSelectMasterOption={handleFormBuilderSelectMasterOption}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'tabs' && <TabQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleAddSubOptionsTab={handleAddSubOptionsTab}
                        handleAddOptionsTab={handleAddOptionsTab}
                        handleDeleteOptionsTab={handleDeleteOptionsTab}
                        handleOptionTextChangeTab={handleOptionTextChangeTab}
                        handleSubOptionTypeChangeTab={handleSubOptionTypeChangeTab}
                        handleDeleteSubOptionsTab={handleDeleteSubOptionsTab}
                        handleSuboptionTextChangeTab={handleSuboptionTextChangeTab}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'input' && <ShortAnswerQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleAddOptionsShortAnswer={handleAddOptionsShortAnswer}
                        handleDeleteOptionsShortAnswer={handleDeleteOptionsShortAnswer}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'textarea' && <TextAreaQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleAddOptionsTextArea={handleAddOptionsTextArea}
                        handleDeleteOptionsTextArea={handleDeleteOptionsTextArea}
                        handleOptionTextChangeTextArea={handleOptionTextChangeTextArea}
                        index={i}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

                {
                    selectedQs.questionType === 'radio' && <MultipleChoiceQuestionView
                        question={selectedQs}
                        handleCancelEditView={handleCancelEditView}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleAddOptions={handleAddOptions}
                        index={i}
                        handleSubOptionTypeChange={handleSubOptionTypeChange}
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteSubOptions={handleDeleteSubOptions}
                        handleSuboptionTextChange={handleSuboptionTextChange}
                        handleInnerSubOptionTextChange={handleInnerSubOptionTextChange}
                        handleAddInnerTwoSubOptions={handleAddInnerTwoSubOptions}
                        handleDeleteInnerSubOptions={handleDeleteInnerSubOptions}
                        handleInnerTwoSubOptionText={handleInnerTwoSubOptionText}
                        handleInnerTwoSubOptionType={handleInnerTwoSubOptionType}
                        handleDeleteInnerTwoSubOptions={handleDeleteInnerTwoSubOptions}
                        handleAddMoreInnerSubOptions={handleAddMoreInnerSubOptions}
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        questionTypeOptionsChildren={questionTypeOptionsChildren}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleFormBuilderSelectMasterSubOption={handleFormBuilderSelectMasterSubOption}
                        handleFormBuilderSelectMasterOption={handleFormBuilderSelectMasterOption}
                        handleQuestionDesignate={handleQuestionDesignate}
                    />
                }

            </div>
        </div>
    )

    const handleRouteChange = (e) => {
        e.preventDefault()
        navigate(e.target.value)
    }
    return (
        <CommonLayout breadCrumbText='Form Builder'>
            {
                !loadingState ?
                    <div className="formBuilderBody">
                        <div className="formBuilderFrameWrapper">
                            <div className="formBuilderContentWrapper">

                                <div className="formBuilderFrameOne">
                                    <div className="formBuilderFrameOneLeft">
                                        <Radio.Group
                                            value={`/form-builder/publish`}
                                            onChange={handleRouteChange}
                                        >
                                            <Radio.Button value={`/form-builder`} >Editing</Radio.Button>
                                            <Radio.Button value={`/form-builder/view`}>Viewing</Radio.Button>
                                            <Radio.Button value={`/form-builder/publish`}
                                                style={{ background: '#0F2A6F', borderColor: '#0F2A6F', color: 'white' }}
                                            >Published</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                </div>

                                <div className="formBuilderViewWrapper" onDragOver={(e) => e.preventDefault()}>
                                    {
                                        publishedQuestions.length > 0 ? publishedQuestions.map((question, i) => {

                                            if (i === selectedIndex) {
                                                return renderEditQuestion(question, i)
                                            }


                                            return <>
                                                <div className="formBuilderPublishContent cp" key={i}
                                                    draggable
                                                    onDragStart={(e) => handleDrag(e, i)}
                                                    onDrop={(e) => handleDrop(e, i)}>
                                                    <div className='w-100 formBuilderViewLabelWrapper cp'>
                                                        <label className='saveQuestionLabel cp'>{question.questionHeader}</label>
                                                        <div className='formBuilderViewBtnGrp cp'>
                                                            <EditOutlined
                                                                onClick={() => EditQuestion(question, i)}
                                                                className='formBuilderViewEditBtn'
                                                            />

                                                            <DeleteOutlined
                                                                className='cp'
                                                                onClick={() => handleDeleteQuestionFormBuilder(question._id, formBuilderId)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="savedQuesOptionDiv cp">
                                                        {question.questionType === 'radio' && RadioOptions(question?.optionValue)}
                                                        {question.questionType === 'checkbox' && CheckBoxOptions(question?.optionValue)}
                                                        {question.questionType === 'dropdown' && DropdownOptions()}
                                                        {question.questionType === 'textarea' && TextareaOptions()}
                                                        {question.questionType === 'input' && ShortAnswerOptions()}
                                                        {question.questionType === 'date' && DateOptions()}
                                                        {question.questionType === 'tabs' && TabOptions(question?.optionValue)}
                                                    </div>
                                                </div>
                                            </>
                                        }) : ''
                                    }
                                </div>

                            </div>
                        </div>

                        {
                            showDeleteAlert &&
                            <Alert message={TextMessage.QUESTION_UNPUBLISH_TEXT} type="error"
                                showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined />} className='alertStyle'
                            />
                        }

                        {
                            showUpdateAlert &&
                            <Alert message={updateAlertText} type={updateAlertType}
                                showIcon closable onClose={handleUpdateAlertClose} className='alertStyle'
                            />
                        }
                    </div>

                    :
                    <div className="errorTipDiv">
                        <Spin size='middle' tip='Loading...' />
                    </div>
            }
        </CommonLayout>
    )
}

export default FormBuilderPublish;