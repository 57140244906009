import { showLoader, showMessage } from "actions/common";
import axios, { AxiosError, AxiosInstance } from "axios";
import environments from "common/environments";
import logger from "common/logger";
import session from "utils/session";
// import { showLoader } from '../../redux/actions';

type Configs = {
  ssrServer?: boolean;
};

export class ClientSideHttpClient {
  private axios: AxiosInstance;

  constructor(configs: Configs = { ssrServer: false }) {
    const { ssrServer } = configs;
    this.axios = axios.create({
      baseURL: !ssrServer ? environments.API_BASE_URL : "/",
    });

    this.axios.interceptors.request.use(
      (config: any) => {
        if (!config.silent) {
          showLoader(true);
        }

        if (session.token) {
          // eslint-disable-next-line no-param-reassign
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${session.token}`,
          };
        }
        return config;
      },
      (error: AxiosError) => {
        showLoader(false);
        logger.error(error);
        throw error?.response?.data || error;
      }
    );

    this.axios.interceptors.response.use(
      (response) => {
        showLoader(false);
        return response;
      },
      (error: AxiosError) => {
        showLoader(false);
        const errorMessage =
        (error?.response?.data as any)?.data?.message ??
          (error?.response?.data as any)?.message ??
          error?.message ??
          "Something went wrong";
        showMessage(errorMessage);
        // const path = Router.asPath;
        // if (!path.includes("/auth/login")) {
        //   if ([401, 403].includes((error as any)?.response?.status_code)) {
        //     performLogout();
        //   }
        // }
        throw error?.response?.data || error;
      }
    );
  }

  get httpClient() {
    return this.axios;
  }
}
