/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
import "./Promotion.css";
import React from "react";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Fullscreen from "fullscreen-react";
import { Button, Input, Modal, Tag, Spin, Switch, Alert } from "antd";
import {
  ColumnHeightOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  ExportOutlined,
  LoadingOutlined,
  SearchOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import PromoStats from "./PromoStats";
import CustomTable from "components/common/table/TableNew";
import StatusDrawer from "components/common/statusDrawer";
import CommonLayout from "components/layout/Layout";
import CreatePromo from "./CreatePromoNew";
import ViewPromo from "./ViewPromoNew";
import EditPromo from "./EditPromoNew";
import { useLocation } from "react-router-dom";
import { PROMO_COLUMNS, LOAD_PROMOS_COLUMNS } from "utils/column";
import session from "utils/session";
import {
  getPromoList,
  deletePromo,
  changeStatusPromo,
  getPromoExportData,
  sendSub2,
  getRequiredQuestionPromoType,
} from "actions/promotions/index";
import { setPage } from "actions/app";
import { TextMessage, DATE_FORMAT_DASH } from "utils/constants";
import moment from "moment";
import TableSearchDropdown from "components/TableSearchDropdown";
import DateFilter from "components/DateFilter";
import Workflow from "./workflow";
import PromoService from "services/promo-service/promo-service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import { openDownloadLink } from "common/helper";
const { Search } = Input;

const Promotions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const { promoList, promoListTotal } = useSelector((state) => state.promo);
  const promoService = useMemo(() => new PromoService(), []);
  const [filter, setFilter] = useState("All Promo");
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [editId, setEditId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [userActive, setUserActive] = useState(true);
  const [userDeActive, setUserDeActive] = useState(false);
  const [live, setLive] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [currentSuccessMsg, setCurrentSuccessMsg] = useState();
  const [allFilters, setAllFilters] = useState({});
  const [showWorkflow, setShowWorkflow] = useState();
  const [loadPromoList, setLoadPromoList] = useState([]);
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);
  const [selectedCSVRow, setCSVSelectedRow] = useState(null);
  const [selectedXMLRow, setXMLSelectedRow] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [selectedRowsState, setSelectedRowsState] = useState([]);
  const isMerchant =
    session && session.role && session.role.toLowerCase() === "merchant";

  const role = session && session.role ? session.role.toLowerCase() : "";
  const { isAdmin } = session;
  const teamDetail = session && session.teamDetail ? session.teamDetail : null;
  const { search } = useLocation();
  const prId = new URLSearchParams(search).get("promo");
  const userPermissions =
    session && session.permissions ? session.permissions : [];

  useEffect(() => {
    if (prId) {
      setViewId(prId);
      setViewModal(true);
    }
  }, [prId]);

  useEffect(() => {
    dispatch(setPage("Promotions"));
  }, [dispatch]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMerchant]);

  useEffect(() => {
    setTableLoading(true);
    getData({ page: 1 }, false);
    setCurrPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchValue]);

  const getData = async (params = {}, isLoading = true) => {
    if (filter !== "Submit") {
      const statusValue = isMerchant ? "Action" : "Action";
      params.filterBy = "status";
      params.filterValue = params.filterValue
        ? `${params.filterValue},${statusValue}`
        : statusValue;
    }
    if (filter === "Submit") {
      params.tab = "team-action";
    }
    if (searchValue) {
      params.search = searchValue;
    }
    if (isLoading) {
      setLoadingState(true);
    }
    if (filter !== "Load promos") {
      const res = await dispatch(getPromoList(params));
      if (res) {
        setLoadingState(false);
        setTableLoading(false);
      }
    } else {
      await promoService
        .getLoadPromos(params)
        .then((response) => {
          setLoadPromoList(response?.data?.promos);
          setLoadingState(false);
          setTableLoading(false);
          setTotalCount(response?.data?.totalCount);
        })
        .catch((error) => console.error("Error fetching promos:", error));
    }
  };

  const changeFilter = (value) => {
    setIsTemplateSelected(false);
    setFilter(value);
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const handleAdd = () => {
    setShowCreate(true);
  };

  const handleReload = async () => {
    getData();
  };

  const deletePromotion = (val) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deletePromoYes(val),
      okType: "primary",
    });
  };

  const deletePromoYes = async (val) => {
    if (filter == "Load promos") {
      const resp = await promoService.deleteTemplate(val);
      if (resp.error) {
        showAlert({
          message: resp?.error?.response?.data?.message,
          variant: AlertVariant.ERROR,
        });
      } else {
        showAlert({
          message: resp?.message,
          variant: AlertVariant.SUCCESS,
        });
      }
    } else {
      const resp = await dispatch(deletePromo(val._id));
      if (resp.error) {
        showAlert({
          message: resp?.error?.response?.data?.message,
          variant: AlertVariant.ERROR,
        });
      } else {
        setShowDeleteAlert(true);
      }
    }
    await getData({ page: currPage, perPage: pageSize }, false);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleCreateClose = (params) => {
    setShowCreate(false);
    setViewModal(false);
    setEditModal(false);
    dispatch(sendSub2([]));
    dispatch(getRequiredQuestionPromoType([]));
    if (params?.refresh) {
      handleReload();
    }
  };

  const openPromo = (record, name) => {
    if (filter !== "Load promos") {
      if (name === "view") {
        setViewModal(true);
        setEditId(record);
        setLive(false);
      }

      if (name === "edit") {
        setEditModal(true);
        setEditId(record);
        setLive(false);
      }
    } else {
      if (name === "view") {
        record.templateType?.toLowerCase() == "csv"
          ? navigate(
            `/csv-templates/createTemplate/${record?.csvTemplate?._id
            }/${"view"}`
          )
          : navigate(
            `/xml-templates/createTemplate/${record?.xmlTemplate?._id
            }/${"view"}`
          );
      } else if (name === "edit") {
        record.templateType?.toLowerCase() == "csv"
          ? navigate(
            `/csv-templates/createTemplate/${record?.csvTemplate?._id
            }/${"edit"}`
          )
          : navigate(
            `/xml-templates/createTemplate/${record.xmlTemplate?._id
            }/${"edit"}`
          );
      }
    }
  };

  const onSearch = async (value) => {
    setSearchText(value);
    const params = {
      ...currentFilters,
    };

    if (value) {
      params.filters = { name: value };
      params.page = currPage;
      getData(params);
    } else {
      getData({ page: currPage, pageSize: pageSize }, false);
    }
  };
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to deactivate the promo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => changeActivate(id, false),
      okCancel: userDeactivateNo,
      okType: "primary",
    });
  };

  const changeActivate = async (id, checked) => {
    const response = await dispatch(
      changeStatusPromo(id, { isActive: checked })
    );
    if (response.error) {
      showAlert({
        message: response?.error?.response?.data?.message,
        variant: AlertVariant.ERROR,
      });
    } else {
      await getData({ page: currPage, perPage: pageSize }, false);
    }
  };

  const userDeactivateNo = () => {
    setUserActive(true);
  };

  const changeStatusNo = async (id, checked) => {
    Modal.confirm({
      title: "Do you want to activate the promo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => changeActivate(id, true),
      okCancel: userActivateNo,
      okType: "primary",
    });
  };

  const userActivateNo = () => {
    setUserDeActive(false);
  };

  const setupRows = (promoList) => {
    if (filter == "Load promos") {
      return promoList?.map((o) => {
        const oPromoStatus =
          (o.promoStatus === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
          (o.promoStatus === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
          (o.promoStatus === "Canceled" && <Tag color={"red"}>Cancelled</Tag>) ||
          (o.promoStatus === "Expired" && (
            <Tag className="status-symbol-expired">Expired</Tag>
          )) ||
          (o.promoStatus === "Incomplete" && (
            <Tag className="status-symbol-incomplete">Incomplete</Tag>
          )) ||
          (o.promoStatus === "Approved" && (
            <Tag className="status-symbol-approved">Approved</Tag>
          )) ||
          (o.promoStatus === "Live" && (
            <Tag className="status-symbol-live">Live</Tag>
          )) ||
          (o.promoStatus === "Rejected" && (
            <Tag className="status-symbol-rejected">Rejected</Tag>
          )) ||
          (o.promoStatus === "Live-Errors" && (
            <Tag className="status-symbol-lv">Live-Errors</Tag>
          )) ||
          (o.promoStatus === "Pending-Errors" && (
            <Tag className="status-symbol-pv">Pending-Errors</Tag>
          )) ||
          (o.promoStatus === "Approved-Errors" && (
            <Tag className="status-symbol-av">Approved-Errors</Tag>
          )) ||
          (o.promoStatus === "Expired-Errors" && (
            <Tag className="status-symbol-pv">Expired-Errors</Tag>
          )) ||
          (o.promoStatus === "Incomplete-Errors" && (
            <Tag className="status-symbol-iv">Incomplete-Errors</Tag>
          )) ||
          (o.promoStatus === "Submitted-Errors" && (
            <Tag className="status-symbol-iv">Submitted-Errors</Tag>
          ));

        return {
          ...o,
          name: o.promoName,
          _id: o._id,
          templateStatus: o?.status,
          // status:
          //   o.templateName.toLowerCase() !== "xml" ? (
          //     <div>{oStatus || "-"}</div>
          //   ) : (
          //     "-"
          //   ),
          submittedby: o.owner?.fullName,
          promoType: o.promoTypeName,
          templateName: o.templateName,
          templateType: o.templateType,
          // promoStatus: o.oPromoStatus,
          promoStatus: (
            <div>{oPromoStatus || "-"}</div>
          ),
          actionTeamName: o.actionTeamName || "-",
          exportedAt: o.exportedAt || "-",
          eventName: o?.eventName ?? "-",
          promoRowStatus: o.promoStatus,
        };
      });
    } else {
      return promoList?.map((o) => {
        const oStatus =
          (o.status === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
          (o.status === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
          (o.status === "Canceled" && <Tag color={"red"}>Cancelled</Tag>) ||
          (o.status === "Expired" && (
            <Tag className="status-symbol-expired">Expired</Tag>
          )) ||
          (o.status === "Incomplete" && (
            <Tag className="status-symbol-incomplete">Incomplete</Tag>
          )) ||
          (o.status === "Approved" && (
            <Tag className="status-symbol-approved">Approved</Tag>
          )) ||
          (o.status === "Live" && (
            <Tag className="status-symbol-live">Live</Tag>
          )) ||
          (o.status === "Rejected" && (
            <Tag className="status-symbol-rejected">Rejected</Tag>
          )) ||
          (o.status === "Live-Errors" && (
            <Tag className="status-symbol-lv">Live-Errors</Tag>
          )) ||
          (o.status === "Pending-Errors" && (
            <Tag className="status-symbol-pv">Pending-Errors</Tag>
          )) ||
          (o.status === "Approved-Errors" && (
            <Tag className="status-symbol-av">Approved-Errors</Tag>
          )) ||
          (o.status === "Expired-Errors" && (
            <Tag className="status-symbol-pv">Expired-Errors</Tag>
          )) ||
          (o.status === "Incomplete-Errors" && (
            <Tag className="status-symbol-iv">Incomplete-Errors</Tag>
          )) ||
          (o.status === "Submitted-Errors" && (
            <Tag className="status-symbol-iv">Submitted-Errors</Tag>
          ));
        const promoCreatedBy =
          o?.owner?._id && o?.owner?._id === session.userId ? true : false;
        return {
          ...o,
          name: o.name,
          _id: o._id,
          promoStatus: o.status,
          status: (
            <div onClick={() => setShowWorkflow(o)}>{oStatus || "-"}</div>
          ),
          submittedby: o.owner?.fullName,
          startDate: o.startDate
            ? moment(o.startDate).utc().format(DATE_FORMAT_DASH)
            : "-",
          endDate: o.endDate
            ? moment(o.endDate).utc().format(DATE_FORMAT_DASH)
            : "-",
          pricingNeeded: o.priceNeeded || "-",
          promoTag: o.promoTag || "-",
          active: o.isActive ? (
            <Switch
              checked={userActive}
              className="switchStyle"
              onChange={() => changeStatusYes(o._id)}
              disabled={o.status === "Live"}
            />
          ) : (
            <Switch
              checked={userDeActive}
              onChange={() => changeStatusNo(o._id)}
              disabled={o.status === "Live"}
            />
          ),
          promoCreatedBy: promoCreatedBy,
          eventName: o?.event?.name ?? "-",
          eventId: o?.event?.eventId ?? "-",
          workFlowType: o?.isEmergency ? "Emergency" : "Standard",
          isPromo: true,
          actionTeam: o.actionTeam || "-",
          actionTeamName: o.actionTeamName || "-",
          // actionTeam:
          //   o?.stages?.find((item) => item.teamId === o.actionTeam)?.name ||
          //   "-",
          comp_id: o.comp_id,
        };
      });
    }
  };

  const exportPromoData = async () => {
    setButtonLoading(true);
    // const params = { ...filters };
    // if (filter !== "All Promo") {
    //   const statusValue = isMerchant ? "Submitted" : "Pending";
    //   params.filterName = "name";
    //   params.filterValue = statusValue;
    // }

    const params = {
      ...currentFilters,
    };
    if (searchValue) {
      params.search = searchValue;
    }
    await dispatch(getPromoExportData(params)).then((res) => {
      setCurrentSuccessMsg({
        message:
          res?.payload?.data?.message || res?.error?.response?.data?.message,
        type: res?.payload?.data?.message ? "success" : "error",
      });
      setTimeout(() => {
        setCurrentSuccessMsg("");
      }, 6000);
      setButtonLoading(false);
    });
  };

  const loadTemplate = () => {
    Modal.confirm({
      title: "Are you sure you want to perform this action?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => loadTemplateYes(selectedCSVRow, selectedXMLRow),
      okType: "primary",
    });
  };
  const loadTemplateYes = (selectedCSVRow, selectedXMLRow) => {
    if (selectedCSVRow && selectedCSVRow.length > 0) {
      promoService.loadCSVTemplate(selectedCSVRow).then((resp) => {
        if (resp?.status) {
          getData();
          setIsTemplateSelected(false);
          setSelectedRowsState([]);
          openDownloadLink(resp?.message)
          showAlert({
            message: 'CSV exported successfully.',
            variant: AlertVariant.SUCCESS,
          });
        } 
        // else {
        //   showAlert({
        //     message: resp?.message,
        //     variant: AlertVariant.ERROR,
        //   });
        // }
      });
    }
    if (selectedXMLRow && selectedXMLRow.length > 0) {
      promoService.loadXMLTemplate(selectedXMLRow).then((resp) => {
        if (resp?.status) {
          getData();
          setIsTemplateSelected(false);
          openDownloadLink(resp?.message)
          setSelectedRowsState([]);
          showAlert({
            message: 'XML exported successfully.',
            variant: AlertVariant.SUCCESS,
          });
        } 
        // else {
        //   showAlert({
        //     message: resp?.message,
        //     variant: AlertVariant.ERROR,
        //   });
        // }
      });
    }
  };

  const setColumns = () => {
    let cols = [];

    if (filter === "Load promos") {
      LOAD_PROMOS_COLUMNS.forEach((o) => {
        if (
          o.dataIndex === "promoName" ||
          o.dataIndex === "templateName" ||
          o.dataIndex === "promoTypeName" ||
          o.dataIndex === "eventName" ||
          o.dataIndex === "actionTeamName"
        ) {
          const colsOptions = {
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            ),
          };
          cols.push({ ...o, ...colsOptions });
        } else if (o.dataIndex === "exportedAt") {
          const colsOptions = {
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <DateFilter
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            ),
          };
          cols.push({ ...o, ...colsOptions });
        } else {
          cols.push(o);
        }
      });
    } else {
      PROMO_COLUMNS.forEach((o) => {
        if (
          o.dataIndex === "name" ||
          o.dataIndex === "submittedby" ||
          o.dataIndex === "eventName" ||
          o.dataIndex === "promoTag" ||
          o.dataIndex === "actionTeamName" ||
          o.dataIndex === "eventId"
        ) {
          const colsOptions = {
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            ),
          };
          cols.push({ ...o, ...colsOptions });
        } else if (o.dataIndex === "startDate" || o.dataIndex === "endDate") {
          const colsOptions = {
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => (
              <DateFilter
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            ),
          };
          cols.push({ ...o, ...colsOptions });
        } else {
          cols.push(o);
        }
      });
    }

    return cols;
  };

  const checkPermission = (row) => {
    if (row?.promoStatus === "Incomplete") {
      if (role === "admin") {
        return false;
      } else {
        row?.owner?._id === session.userId ? false : true;
      }
    } else {
      return true;
    }
  };

  const exportTemplate = async (val) => {
    try {
      const blob = await promoService.exportTemplate(val);
      if (blob.size === 0) {
        return;
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    if (newSelectedRowKeys.length) {
      var csvPromos = [];
      var xmlPromos = [];
      selectedRows.forEach((promo) =>
        promo.templateType == 'CSV'
          ? csvPromos.push({ promoId: promo.promoId, templateId: promo.templateId, eventId: promo.eventId })
          : xmlPromos.push({ promoId: promo.promoId, templateId: promo.templateId, eventId: promo.eventId })
      );

      setIsTemplateSelected(true);
      setCSVSelectedRow(csvPromos);
      setXMLSelectedRow(xmlPromos);

      // Refresh table or re-apply the row disabling logic with selectedRows
      setSelectedRowsState(selectedRows);
    } else {
      setIsTemplateSelected(false);
      setCSVSelectedRow(null);
      setXMLSelectedRow(null);
      setSelectedRowsState([]); // Reset table with no selected rows
    }
  };

  // const onSelectChange = (newSelectedRowKeys, selectedRows) => {
  //   if (newSelectedRowKeys.length) {
  //     var csvPromos = [];
  //     var xmlPromos = [];
  //     selectedRows.forEach((promo) =>
  //       promo.templateType == 'CSV' ? csvPromos.push({ promoId: promo.promoId, templateId: promo.templateId, eventId: promo.eventId }) :
  //         xmlPromos.push({ promoId: promo.promoId, templateId: promo.templateId, eventId: promo.eventId })
  //     );
  //     setIsTemplateSelected(true);
  //     setCSVSelectedRow(csvPromos);
  //     setXMLSelectedRow(xmlPromos);
  //   } else {
  //     setIsTemplateSelected(false);
  //     setCSVSelectedRow(null);
  //     setXMLSelectedRow(null)
  //   }
  // };

  const reGenerateTemplate = (val) => {
    var promoId = [];
    promoId.push({ promoId: val.promoId, templateId: val.templateId });
    Modal.confirm({
      title: "Are you sure you want to perform this action?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => loadTemplateYes(promoId),
      okType: "primary",
    });
  };

  // const isRowCheckboxDisabled = (promo) => {
  //   // if (promo?.templateType?.toLowerCase() === "xml" || promo?.promoRowStatus === 'Canceled') {
  //   if (promo?.promoRowStatus === 'Canceled') {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const isRowCheckboxDisabled = (promo, selectedRows) => {
    const csvSelected = selectedRows.some(row => row.templateType === 'CSV');
    const xmlSelected = selectedRows.some(row => row.templateType === 'XML');

    // Disable XML rows if CSV is selected, and vice versa
    if ((csvSelected && promo.templateType === 'XML') || (xmlSelected && promo.templateType === 'CSV')) {
      return true;
    }

    // Keep the original condition
    if (promo?.promoRowStatus === 'Canceled') {
      return true;
    }

    return false;
  };

  const rowId = (val) => {
    return val?.templateId + val?.promoId;
  };

  const handlePromotionCancel = async (promotion) => {
    try {
      const response = await promoService.updatePromoStatus(
        promotion._id,
        "Canceled"
      );
      if (response?.message) {
        showAlert({ message: response.message, variant: AlertVariant.SUCCESS });
        handleReload();
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleCancelClick = (id) => {
    Modal.confirm({
      title: "Are you sure you want to cancel this promotion?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => handlePromotionCancel(id),
      okType: "primary",
    });
  };

  return (
    <CommonLayout className="no-content-mr" breadCrumbText="Promo Management">
      {currentSuccessMsg && (
        <Alert
          message={currentSuccessMsg.message}
          closable
          type={currentSuccessMsg.type}
          showIcon
        />
      )}
      {loadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="promo-body">
            <div className="body-right">
              <Search
                placeholder="Search By Offer Name"
                enterButton
                allowClear
                value={searchText}
                onChange={handleChange}
                onSearch={onSearch}
                className="userBtn"
              />
            </div>
            <div className="body-left">
              <Button
                type="primary"
                className="addShelfBtn"
                onClick={handleAdd}
                disabled={
                  role === "approver" ||
                  (role === "basic" && (!userPermissions.includes("promo") || teamDetail?.name !== 'Merchants')) ||
                  teamDetail?.isVCM
                }

              >
                Create a new Offer
              </Button>
            </div>
          </div>
          <PromoStats />
          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="table-cardbody">
              <div className="tablebody">
                <div className="table-main">
                  <div className="table-header">
                    <div className="btnGrpWrapper">
                      <button
                        className={
                          filter === "All Promo" ? "btnStyle" : "btnStyle1"
                        }
                        onClick={() => changeFilter("All Promo")}
                      >
                        All Offers
                      </button>
                      {!isMerchant && (
                        <button
                          className={
                            filter === "Submit" ? "btnStyle" : "btnStyle1"
                          }
                          onClick={() => changeFilter("Submit")}
                        >
                          Offers Pending Your Team’s Action
                        </button>
                      )}
                      {isMerchant && (
                        <button
                          className={
                            filter === "Submit" ? "btnStyle" : "btnStyle1"
                          }
                          onClick={() => changeFilter("Submit")}
                        >
                          Offers Pending Your Team’s Action
                        </button>
                      )}
                      {(role === "admin" || userPermissions.includes("loadPromo")) && <button
                        className={
                          filter === "Load promos" ? "btnStyle" : "btnStyle1"
                        }
                        onClick={() => changeFilter("Load promos")}
                      >
                        Load Offers
                      </button>
                      }
                    </div>

                    <div className="filterGrpWrapper1">
                      {filter !== "Load promos" && (
                        <Button
                          icon={
                            buttonLoading ? (
                              <LoadingOutlined />
                            ) : (
                              <ExportOutlined />
                            )
                          }
                          onClick={exportPromoData}
                          disabled={buttonLoading}
                        >
                          Export
                        </Button>
                      )}
                      {filter == "Load promos" && (
                        <Button
                          icon={
                            buttonLoading ? (
                              <LoadingOutlined />
                            ) : (
                              <LoginOutlined />
                            )
                          }
                          onClick={loadTemplate}
                          disabled={!isTemplateSelected || buttonLoading}
                        >
                          EXPORT File
                        </Button>
                      )}

                      <div className="settingGrp">
                        <ReloadOutlined
                          onClick={handleReload}
                          className="reloadIconStyle"
                        />
                        <ColumnHeightOutlined
                          className="reloadIconStyle"
                          onClick={handleTableHeight}
                        />
                        <FullscreenOutlined
                          onClick={() => {
                            setIsEnter(true);
                          }}
                          className="reloadIconStyle"
                        />
                      </div>
                    </div>
                  </div>

                  {showDeleteAlert && (
                    <StatusDrawer
                      onClose={handleDeleteAlertClose}
                      title={TextMessage.PROMOTION_DELETE_TEXT}
                      visible={showDeleteAlert}
                    />
                  )}
                  {showCreate && (
                    <CreatePromo
                      visible={showCreate}
                      onClose={handleCreateClose}
                    />
                  )}

                  {viewModal && (
                    <ViewPromo
                      visible={viewModal}
                      viewId={viewId}
                      onClose={handleCreateClose}
                      liveFlag={live}
                      data={editId}
                    />
                  )}
                  {editModal && (
                    <EditPromo
                      visible={editModal}
                      data={editId}
                      onClose={handleCreateClose}
                    />
                  )}
                  <div className="table-real">
                    {filter !== "Load promos" && (
                      <CustomTable
                        className="assign"
                        columns={setColumns()}
                        rows={
                          filter === "Submit"
                            ? setupRows(promoList?.filter((promo) => promo.isActive))
                            : setupRows(promoList)
                        }
                        isLoading={loadingState || tableLoading}
                        rowKey="_id"
                        isViewable={true}
                        isEditable={
                          (userPermissions.includes("promo") &&
                            !teamDetail?.isVCM) ||
                          (role === "admin" && !teamDetail?.isVCM)
                        }
                        onView={(record) => openPromo(record, "view")}
                        onEdit={(record) => openPromo(record, "edit")}
                        isDeleteable={
                          (userPermissions.includes("promo") &&
                            !teamDetail?.isVCM) ||
                          (role === "admin" && !teamDetail?.isVCM)
                        }
                        onDelete={deletePromotion}
                        total={promoListTotal}
                        size={tableSize}
                        isPopup={true}
                        isCancelable={
                          userPermissions.includes("promo") || isAdmin
                        }
                        onCancel={handleCancelClick}
                        checkRowDeleatable={checkPermission}
                        onChange={async (
                          { current, pageSize },
                          filter = {},
                          { field, order, columnKey }
                        ) => {
                          const params = {
                            page: current,
                            perPage: pageSize,
                            sortBy: order && columnKey,
                            orderBy: order,
                          };
                          setCurrPage(current);
                          setPageSize(pageSize);
                          const keys = Object.keys(filter);
                          keys.forEach((key) => {
                            if (filter[key]) {
                              if (!params.filters) {
                                params.filters = {};
                              }
                              if (key === "active") {
                                params.filters["isActive"] =
                                  filter[key]?.join(",");
                              } else if (
                                key === "name" ||
                                key === "owner.fullName"
                              ) {
                                params.filters[key] = filter[key][0];
                              } else if (key === "status") {
                                params.filters[key] = filter[key]?.join(",");
                              } else if (key === "startDate") {
                                params.filters.startDate = filter[key];
                              } else if (key === "endDate") {
                                params.filters.endDate = filter[key];
                              } else if (key === "isEmergency") {
                                if (filter[key]?.length <= 1) {
                                  params.filters[key] = filter[key];
                                }
                              } else if (key === "event.name") {
                                params.filters[key] = filter[key];
                              } else if (key === "event.eventId") {
                                params.filters[key] = filter[key];
                              } else if (key === "adset.name") {
                                params.filters[key] = filter[key];
                              } else if (key === "actionTeamName") {
                                params.filters[key] = filter[key];
                              }
                              setAllFilters(params.filters);
                            }
                          });
                          setTableLoading(true);
                          setCurrentFilters(params);
                          getData(params, false);
                        }}
                        promoList={promoList}
                      />
                    )}
                    {filter == "Load promos" && (
                      <CustomTable
                        className="assign"
                        columns={setColumns()}
                        rows={setupRows(loadPromoList)}
                        isLoading={loadingState || tableLoading}
                        rowKey={rowId}
                        isDeleteable={
                          (userPermissions.includes("promo") &&
                            !teamDetail?.isVCM) ||
                          (role === "admin" && !teamDetail?.isVCM)
                        }
                        isCancelable={
                          userPermissions.includes("promo") || isAdmin
                        }
                        onCancel={handleCancelClick}
                        onDelete={deletePromotion}
                        total={totalCount}
                        size={tableSize}
                        isPopup={true}
                        onChange={async (
                          { current, pageSize },
                          filter = {},
                          { field, order, columnKey }
                        ) => {
                          const params = {
                            page: current,
                            perPage: pageSize,
                            sortBy: order && columnKey,
                            orderBy: order,
                          };
                          setCurrPage(current);
                          setPageSize(pageSize);
                          const keys = Object.keys(filter);
                          keys.forEach((key) => {
                            if (filter[key]) {
                              if (!params.filters) {
                                params.filters = {};
                              }
                              if (
                                key === "promoName" ||
                                key === "promoTypeName" ||
                                key === "templateName" ||
                                key === "templateType" ||
                                key === "actionTeamName" ||
                                key === "eventName"
                              ) {
                                params.filters[key] = filter[key][0];
                              } else if (key === "status" || key === "promoStatus") {
                                params.filters[key] = filter[key]?.join(",");
                              }
                              setAllFilters(params.filters);
                            }
                          });
                          setTableLoading(true);
                          setCurrentFilters(params);
                          getData(params, false);
                        }}
                        promoList={loadPromoList}
                        rowSelectionType="checkbox"
                        onSelectRows={onSelectChange}
                        isReGenerated={true}
                        onReGenerate={reGenerateTemplate}
                        isExportable={!teamDetail?.isVCM}
                        onExport={exportTemplate}
                        // isRowCheckboxDisabled={isRowCheckboxDisabled}
                        isRowCheckboxDisabled={(promo) => isRowCheckboxDisabled(promo, selectedRowsState)}
                      />
                    )}
                  </div>
                </div>
              </div>
              {showWorkflow && (
                <Workflow
                  data={showWorkflow}
                  onCancel={() => setShowWorkflow(null)}
                />
              )}
            </div>
          </Fullscreen>
        </>
      )}
    </CommonLayout>
  );
};

export default Promotions;
