export const UPDATE_LOCAL_MAPS_JSON = "UPDATE_LOCAL_MAPS_JSON";
export const MAP_CREATE_MESSAGE = "MAP_CREATE_MESSAGE";
export const MAP_VALIDATE_MESSAGE = "MAP_VALIDATE_MESSAGE";

export const GET_ALL_MAPS = "GET_ALL_MAPS";
export const MAP_CREATE = "MAP_CREATE";
export const MAP_VALIDATE = "MAP_VALIDATE";
export const DELETE_MAP = "DELETE_MAP";
export const VIEW_MAP = "VIEW_MAP";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const GET_MAPS_BY_STATUS = "GET_MAPS_BY_STATUS";
export const GET_MAP_BY_SEARCH = "GET_MAP_BY_SEARCH";
export const CLEAR_MAP_BY_SEARCH = "CLEAR_MAP_BY_SEARCH";
export const UPDATE_SEARCH_FILTER_FLAG = "UPDATE_SEARCH_FILTER_FLAG";
export const UPDATE_SELECTED_BRAND = "UPDATE_SELECTED_BRAND";
export const UPDATE_SELECTED_SKU = "UPDATE_SELECTED_SKU";
export const UPDATE_CHOSEN_MAP_TYPE = "UPDATE_CHOSEN_MAP_TYPE";