import './LoadingScreen.css'
import { Spin } from 'antd';

const LoadingScreen = ({ showSpin }) => {
    return(
        <div>
            {
            showSpin ? <div className="overlay-div">
                <>
                <div className="loadingScreenTipDiv">
                    <Spin size='middle' tip='We are creating the shelf data. Please do not close or refresh the page. It will be over in a moment...'/>
                </div>
                </>
            </div> : <></>

            }
        </div>
    )
}

export default LoadingScreen;