/* eslint-disable no-unused-vars */
import "./Promotion.css";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fullscreen from "fullscreen-react";
import { Button, Input, Modal, Tag, Spin, Switch, Alert } from "antd";
import {
  ColumnHeightOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  ExportOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PromoStats from "./PromoStats";
import CustomTable from "components/common/table/TableNew";
import StatusDrawer from "components/common/statusDrawer";
import CommonLayout from "components/layout/Layout";
import CreatePromo from "./CreatePromoNew";
import ViewPromo from "./ViewPromo";
import EditPromo from "./EditPromoNew";
import { useLocation } from "react-router-dom";
import { PROMO_COLUMNS } from "utils/column";
import session from "utils/session";
import {
  getPromoList,
  deletePromo,
  changeStatusPromo,
  getPromoExportData,
  sendSub2,
  getRequiredQuestionPromoType,
} from "actions/promotions/index";
import { setPage } from "actions/app";
import { TextMessage, DATE_FORMAT_DASH } from "utils/constants";
import moment from "moment";
import TableSearchDropdown from "components/TableSearchDropdown";
import DateFilter from "components/DateFilter";
const { Search } = Input;

const Promotions = () => {
  const dispatch = useDispatch();
  const { promoList, promoListTotal } = useSelector((state) => state.promo);

  const [filter, setFilter] = useState("All Promo");
  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [editId, setEditId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [viewId, setViewId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [isEnter, setIsEnter] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({});
  const [userActive, setUserActive] = useState(true);
  const [userDeActive, setUserDeActive] = useState(false);
  const [live, setLive] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [currentSuccessMsg, setCurrentSuccessMsg] = useState();
  const [allFilters, setAllFilters] = useState({});
  const isMerchant =
    session && session.role && session.role.toLowerCase() === "merchant";

  const role = session && session.role ? session.role.toLowerCase() : "";

  const { search } = useLocation();
  const prId = new URLSearchParams(search).get("promo");

  useEffect(() => {
    if (prId) {
      setViewId(prId);
      setViewModal(true);
    }
  }, [prId]);

  useEffect(() => {
    dispatch(setPage("Promotions"));
  }, [dispatch]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMerchant]);

  useEffect(() => {
    setTableLoading(true);
    getData({ page: 1 }, false);
    setCurrPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, searchValue]);

  const getData = async (params = {}, isLoading = true) => {
    if (filter !== "All Promo") {
      const statusValue = isMerchant ? "Action" : "Action";
      params.filterBy = "status";
      params.filterValue = params.filterValue
        ? `${params.filterValue},${statusValue}`
        : statusValue;
    }
    if (searchValue) {
      params.search = searchValue;
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const res = await dispatch(getPromoList(params));
    if (res) {
      setLoadingState(false);
      setTableLoading(false);
    }
  };

  const changeFilter = (value) => {
    setFilter(value);
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const handleAdd = () => {
    setShowCreate(true);
  };

  const handleReload = async () => {
    getData();
  };

  const deletePromotion = (val) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deletePromoYes(val),
      okType: "primary",
    });
  };

  const deletePromoYes = async (val) => {
    await dispatch(deletePromo(val._id));
    await getData({ page: currPage, perPage: pageSize }, false);
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleCreateClose = (params) => {
    setShowCreate(false);
    setViewModal(false);
    setEditModal(false);
    dispatch(sendSub2([]));
    dispatch(getRequiredQuestionPromoType([]));
    if (params?.refresh) {
      handleReload();
    }
  };

  const openPromo = (record, name) => {
    if (name === "view") {
      setViewModal(true);
      setViewId(record?._id);
      setLive(false);
    }

    if (name === "edit") {
      setEditModal(true);
      setEditId(record);
      setLive(false);
    }
  };

  const onSearch = async (value) => {
    setTableLoading(true);
    setSearchValue(value);
  };

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to deactivate the promo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => changeActivate(id, false),
      okCancel: userDeactivateNo,
      okType: "primary",
    });
  };

  const changeActivate = async (id, checked) => {
    const response = await dispatch(
      changeStatusPromo(id, { isActive: checked })
    );
    if (response) {
      await getData({ page: currPage, perPage: pageSize }, false);
    }
  };

  const userDeactivateNo = () => {
    setUserActive(true);
  };

  const changeStatusNo = async (id, checked) => {
    Modal.confirm({
      title: "Do you want to activate the promo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => changeActivate(id, true),
      okCancel: userActivateNo,
      okType: "primary",
    });
  };

  const userActivateNo = () => {
    setUserDeActive(false);
  };

  const setupRows = (promoList) => {
    return promoList?.map((o) => {
      const oStatus =
        (o.status === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
        (o.status === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
        (o.status === "Expired" && (
          <Tag className="status-symbol-expired">Expired</Tag>
        )) ||
        (o.status === "Incomplete" && (
          <Tag className="status-symbol-incomplete">Incomplete</Tag>
        )) ||
        (o.status === "Approved" && (
          <Tag className="status-symbol-approved">Approved</Tag>
        )) ||
        (o.status === "Live" && (
          <Tag className="status-symbol-live">Live</Tag>
        )) ||
        (o.status === "Rejected" && (
          <Tag className="status-symbol-rejected">Rejected</Tag>
        )) ||
        (o.status === "Live-Errors" && (
          <Tag className="status-symbol-lv">Live-Errors</Tag>
        )) ||
        (o.status === "Pending-Errors" && (
          <Tag className="status-symbol-pv">Pending-Errors</Tag>
        )) ||
        (o.status === "Approved-Errors" && (
          <Tag className="status-symbol-av">Approved-Errors</Tag>
        )) ||
        (o.status === "Expired-Errors" && (
          <Tag className="status-symbol-pv">Expired-Errors</Tag>
        )) ||
        (o.status === "Incomplete-Errors" && (
          <Tag className="status-symbol-iv">Incomplete-Errors</Tag>
        )) ||
        (o.status === "Submitted-Errors" && (
          <Tag className="status-symbol-iv">Submitted-Errors</Tag>
        ));
      const promoCreatedBy =
        o?.owner?._id && o?.owner?._id === session.userId ? true : false;
      return {
        ...o,
        name: o.name,
        _id: o._id,
        promoStatus: o.status,
        status: <div>{oStatus || "-"}</div>,
        submittedby: o.owner?.fullName,
        startDate: o.startDate
          ? moment(o.startDate).utc().format(DATE_FORMAT_DASH)
          : "-",
        endDate: o.endDate
          ? moment(o.endDate).utc().format(DATE_FORMAT_DASH)
          : "-",
        pricingNeeded: o.priceNeeded || "-",
        promoTag: o.promoTag || "-",
        active: o.isActive ? (
          <Switch
            checked={userActive}
            className="switchStyle"
            onChange={() => changeStatusYes(o._id)}
            disabled={o.status === "Live"}
          />
        ) : (
          <Switch
            checked={userDeActive}
            onChange={() => changeStatusNo(o._id)}
            disabled={o.status === "Live"}
          />
        ),
        promoCreatedBy: promoCreatedBy,
        isPromo: true,
        workFlowType: o?.isEmergency ? "Emergency" : "Standard",
      };
    });
  };

  const exportPromoData = async () => {
    setButtonLoading(true);
    // const params = { ...filters };
    // if (filter !== "All Promo") {
    //   const statusValue = isMerchant ? "Submitted" : "Pending";
    //   params.filterName = "name";
    //   params.filterValue = statusValue;
    // }

    const params = {
      ...currentFilters,
    };
    if (searchValue) {
      params.search = searchValue;
    }
    const res = await dispatch(getPromoExportData(params));
    setCurrentSuccessMsg({
      message:
        res?.payload?.data?.message || res?.error?.response?.data?.message,
      type: res?.payload?.data?.message ? "success" : "error",
    });
    setTimeout(() => {
      setCurrentSuccessMsg("");
    }, 6000);
    setButtonLoading(false);
  };
  const setColumns = () => {
    let cols = [];

    PROMO_COLUMNS.forEach((o) => {
      if (o.dataIndex === "name" || o.dataIndex === "submittedby") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else if (o.dataIndex === "startDate" || o.dataIndex === "endDate") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <DateFilter
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }
    });
    return cols;
  };
  return (
    <CommonLayout className="no-content-mr" breadCrumbText="Promo Management">
      {currentSuccessMsg && (
        <Alert
          message={currentSuccessMsg.message}
          closable
          type={currentSuccessMsg.type}
          showIcon
        />
      )}
      {loadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <>
          <div className="promo-body">
            <div className="body-right">
              <Search
                placeholder="Search By Promo Name or Submitted By"
                enterButton
                allowClear
                onSearch={onSearch}
                className="userBtn"
              />
            </div>
            <div className="body-left">
              {/* <Button
                type="primary"
                className="addShelfBtn"
                onClick={handleAdd}
                disabled={role === "approver"}
              >
                Create a new promo
              </Button> */}
            </div>
          </div>
          <PromoStats />
          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="table-cardbody">
              <div className="tablebody">
                <div className="table-main">
                  <div className="table-header">
                    <div className="btnGrpWrapper">
                      <button
                        className={
                          filter === "All Promo" ? "btnStyle" : "btnStyle1"
                        }
                        onClick={() => changeFilter("All Promo")}
                      >
                        All Promo
                      </button>
                      {!isMerchant && (
                        <button
                          className={
                            filter === "Submit" ? "btnStyle" : "btnStyle1"
                          }
                          onClick={() => changeFilter("Submit")}
                        >
                          Actions
                        </button>
                      )}
                      {isMerchant && (
                        <button
                          className={
                            filter === "Submit" ? "btnStyle" : "btnStyle1"
                          }
                          onClick={() => changeFilter("Submit")}
                        >
                          Actions
                        </button>
                      )}
                    </div>

                    <div className="filterGrpWrapper1">
                      <Button
                        icon={
                          buttonLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <ExportOutlined />
                          )
                        }
                        onClick={exportPromoData}
                        disabled={buttonLoading}
                      >
                        Export
                      </Button>

                      <div className="settingGrp">
                        <ReloadOutlined
                          onClick={handleReload}
                          className="reloadIconStyle"
                        />
                        <ColumnHeightOutlined
                          className="reloadIconStyle"
                          onClick={handleTableHeight}
                        />
                        <FullscreenOutlined
                          onClick={() => {
                            setIsEnter(true);
                          }}
                          className="reloadIconStyle"
                        />
                      </div>
                    </div>
                  </div>

                  {showDeleteAlert && (
                    <StatusDrawer
                      onClose={handleDeleteAlertClose}
                      title={TextMessage.PROMOTION_DELETE_TEXT}
                      visible={showDeleteAlert}
                    />
                  )}
                  {showCreate && (
                    <CreatePromo
                      visible={showCreate}
                      onClose={handleCreateClose}
                    />
                  )}

                  {viewModal && (
                    <ViewPromo
                      visible={viewModal}
                      viewId={viewId}
                      onClose={handleCreateClose}
                      liveFlag={live}
                    />
                  )}
                  {editModal && (
                    <EditPromo
                      visible={editModal}
                      data={editId}
                      onClose={handleCreateClose}
                    />
                  )}
                  <div className="table-real">
                    <CustomTable
                      className="assign"
                      columns={setColumns()}
                      rows={setupRows(promoList)}
                      isLoading={loadingState || tableLoading}
                      rowKey="_id"
                      isViewable={true}
                      isEditable={true}
                      onView={(record) => openPromo(record, "view")}
                      onEdit={(record) => openPromo(record, "edit")}
                      isDeleteable={true}
                      onDelete={deletePromotion}
                      total={promoListTotal}
                      size={tableSize}
                      isPopup={true}
                      onChange={async (
                        { current, pageSize },
                        filter = {},
                        { field, order, columnKey }
                      ) => {
                        const params = {
                          page: current,
                          perPage: pageSize,
                          sortBy: order && columnKey,
                          orderBy: order,
                        };
                        setCurrPage(current);
                        setPageSize(pageSize);
                        const keys = Object.keys(filter);
                        keys.forEach((key) => {
                          if (filter[key]) {
                            if (!params.filters) {
                              params.filters = {};
                            }
                            if (key === "active") {
                              params.filters["isActive"] =
                                filter[key]?.join(",");
                            } else if (
                              key === "name" ||
                              key === "owner.fullName"
                            ) {
                              params.filters[key] = filter[key][0];
                            } else if (key === "status") {
                              params.filters[key] = filter[key]?.join(",");
                            } else if (key === "startDate") {
                              params.filters.startDate = filter[key];
                            } else if (key === "endDate") {
                              params.filters.endDate = filter[key];
                            }
                            setAllFilters(params.filters);
                          }
                        });
                        setTableLoading(true);
                        setCurrentFilters(params);
                        getData(params, false);
                      }}
                      promoList={promoList}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fullscreen>
        </>
      )}
    </CommonLayout>
  );
};

export default Promotions;
