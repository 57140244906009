import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/snapshot/action-types'
import SnapshotStateManager     from './state-manager';

const INITIAL_STATE = {
    snapshots: [],
    paginateDetails:[],
    module: null
};

export default function SnapshotStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.MODULE_CHANGE:
                return { ...state, module: action.module };
        case S(ActionTypes.GET_ALL_SNAPSHOT_LOGS):
                return SnapshotStateManager.getAllSnapshots(state,action);
        
        default:
            return state;
    }
}