import React, { useMemo } from "react";
import Element from "./Element";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import session from "utils/session";
import { QuestionStatus } from "utils/constants";

const Teams = forwardRef((props, ref) => {
  const { stages: stagesFromProps } = props;
  const [teams, setTeams] = useState([]);
  const [currentStage, setCurrentStage] = useState(0);
  const [editAllowed, setEditAllowed] = useState(false);
  const [answers, setAnswers] = useState({});
  const [stages, setStages] = useState();
  const userTeam = session.teamId;
  const isAdmin = session.role === "Admin";
  const isCreateMode = props.action === "create";

  const myTeamIndex = useMemo(() => {
    return isAdmin ? 0 : stages?.findIndex((i) => i.teamId === session.teamId);
  }, [stages, isAdmin]);

  const isMyTeamFormSubmitted = [
    QuestionStatus.Submitted,
    QuestionStatus.Approved,
  ].includes(stages?.[myTeamIndex]?.status);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    const teams =
      stagesFromProps?.map((i) => ({
        name: i.name,
        value: i.teamId,
      })) || [];

    setTeams(teams);
    setStages(stagesFromProps);

    if (stagesFromProps.length > 0) {
      setEditAllowed(stagesFromProps?.[0]?.teamId === userTeam);
    }
  }, [stagesFromProps, userTeam]);

  useImperativeHandle(ref, () => ({

    handleStageChange(index) {
      handleTeamClick(index)
    },

    isValid() {
      let valid = true;
      // if (!stages.length) {
      //   props.errormsg(
      //     "A Promo cannot be created since the event's lock period has already passed"
      //   );
      //   return false;
      // }
      stages.forEach((s) => {
        s?.questions?.forEach((q) => {
          const answeredQuestion = answers[q.questionId?._id] || q?.value;
          if (q?.isRegexRequired && q?.regex && answeredQuestion) {
            let regex;
            try {
              regex = new RegExp(q.regex);
            } catch (err) {
              console.log(err);
            }

            const isValidAnswer = regex
              ? regex.test(answeredQuestion || "")
              : true;
            if (!isValidAnswer) {
              setErrors((prev) => ({
                ...prev,
                [q.questionId._id]: `Not matching with regex (${q.regex})`,
              }));
              valid = false;
            } else {
              const _errors = { ...errors };
              delete _errors[q.questionId._id];
              setErrors(_errors);
            }
          } else {
            setErrors((prev) => ({
              ...prev,
              [q.questionId._id]: ``,
            }));
          }
          if (q?.questionId?.optionValue) {
            q?.questionId?.optionValue.forEach((sub, index) => {
              sub?.subOptions && sub?.subOptions.forEach((subOption, suOpIndexIndex) => {

                if (subOption?.isRegexRequired && subOption?.regex) {
                  let regex;
                  try {
                    regex = new RegExp(subOption.regex);
                  } catch (err) {
                    console.log(err);
                  }
                  const isValidAnswer = regex && subOption?.subOptionText
                    ? regex.test(subOption?.value || "")
                    : true;
                  if (!isValidAnswer) {
                    setErrors((prev) => ({
                      ...prev,
                      [q.questionId
                        ._id]: `Not matching with regex (${subOption.regex})`,
                    }));
                    valid = false;
                  } else {
                    const _errors = { ...errors };
                    delete _errors[[q.questionId._id]];
                    setErrors(_errors);
                  }
                }
              })
            })
          }
          // Added stage === 0 as form 1 should validate irrespective of user role
          const shouldValidateStageForUser =
            s.teamId === userTeam || (s?.stage === 0 && isAdmin);
          if (shouldValidateStageForUser) {
            if (
              (q?.designate === "required" ||
                q?.questionId?.designate === "required") &&
              !answeredQuestion
            ) {
              setErrors((prev) => ({
                ...prev,
                [q.questionId._id]: "This field is required",
              }));
              valid = false;
            }
            if (q?.questionId?.optionValue) {
              q?.questionId?.optionValue.forEach((sub, index) => {
                sub?.subOptions && sub?.subOptions.forEach((subOption, suOpIndexIndex) => {
                  if ((q?.designate === "required" || q?.questionId.designate == "required") && !q?.questionId?.questionType == 'radio' && sub?.subOptions && !subOption.value) {
                    valid = false;
                    setErrors((prev) => ({
                      ...prev,
                      [q.questionId._id]: "This field is required",
                    }));
                  } else if ((q?.designate === "required" || q?.questionId.designate == "required") && q?.questionId?.questionType == 'radio' && sub?.subOptions && !subOption.value) {
                    // valid = false;
                    // setErrors((prev) => ({
                    //   ...prev,
                    //   [q.questionId._id]: "This field is required",
                    // }));
                  }

                })
              })
            }
          }
        });
      });

      if (!valid) {
        const questionId = Object.keys(errors)[0];
        const jumpToStage = stages?.findIndex((item) =>
          item.questions?.some(
            (question) => question?.questionId?._id === questionId
          )
        );

        if (jumpToStage !== -1 && jumpToStage !== currentStage) {
          handleTeamClick(jumpToStage);
        }
      }
      return valid;
    },

    getStagesData() {
      const currentStageData = {
        ...stages[currentStage],
        questions: stages[currentStage]?.questions?.map((q) => ({
          ...q,
          value: answers[q.questionId?._id] || q.value,
        })),
      };
      const finalData = stages?.map((stageData, stageIndex) =>
        stageIndex === currentStage ? currentStageData : stageData
      );
      return finalData;
    },
  }));

  const handleQuestionChange = (value, id) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [id]: value }));
  };
  

  const handleTeamClick = (index) => {
    const currentStageData = {
      ...stages[currentStage],
      questions: stages[currentStage]?.questions?.map((q) => ({
        ...q,
        value: answers[q.questionId?._id]
          ? answers[q.questionId?._id]
          : q.value,
      })),
    };

    setStages((prev) =>
      prev?.map((stageData, stageIndex) =>
        stageIndex === currentStage ? currentStageData : stageData
      )
    );
    setCurrentStage(index);
    const nextStage = stages[index];
    const nextTeam = teams[index];
    if (nextStage && nextTeam) {
      const isEditableByStatus = [
        QuestionStatus.Incomplete,
        QuestionStatus.Rejected,
      ].includes(nextStage.status);

      const isAllowedNextEdit = myTeamIndex < index && isEditableByStatus;

      const isUserFromTeam = isCreateMode || nextTeam.value === userTeam;
      setEditAllowed(
        !isMyTeamFormSubmitted &&
        (isAllowedNextEdit ||
          (isUserFromTeam && isEditableByStatus))
      );
    }
    props.teamClick?.(index);
  };

  if (!teams.length) {
    return null;
  }

  return (
    <>
      <div className="flex teams-wrapper">
        {teams?.map((team, index) => (
          <div
            className={`team-item ${index === currentStage ? "active" : ""}`}
            onClick={() => {
              handleTeamClick(index);
            }}
          >
            <div className="team-content">
              {team.name}
              <div className="active-border"></div>
            </div>
          </div>
        ))}
      </div>
      {stages[currentStage]?.questions
        ?.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .map((q) => {
          const item = q.questionId;

          return (
            <Element
              id={item._id}
              field={item?.questionType}
              label={item?.questionHeader}
              onChange={(value, id, ptId = "") =>
                handleQuestionChange(value, item._id)
              }
              ans={answers[item._id] || q?.value}
              options={item?.optionValue}
              error={
                item.designate === "required" || q?.isRegexRequired
                  ? errors[item._id]
                  : ""
              }
              item={item}
              designate={
                props.action === "create"
                  ? item.designate
                  : (stagesFromProps[currentStage].teamId === userTeam &&
                    item.designate) ||
                  ""
              }
              // handlePromoTypeQuestionChange={handlePromoTypeQuestionChange}
              key={item._id}
              details={q}
              isRegexRequired={q.isRegexRequired}
              regex={q.regex}
              action={
                props.isDraft
                  ? props.action
                  : editAllowed
                    ? props.action
                    : props.action === "create" && currentStage === 0
                      ? "create"
                      : "view"
              }
            />
          );
        })}
    </>
  );
});
export default React.memo(Teams);
