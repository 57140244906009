import React, { Component } from "react";
import { Button, Space, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DATE_FILTER_FORMAT } from "utils/constants";
import moment from "moment";

class DateFilter extends Component {
  render() {
    const { dataIndex, selectedKeys, confirm, clearFilters, setSelectedKeys } =
      this.props;

    return (
      <div style={{ padding: 8 }}>
        <DatePicker
          onChange={(date) => {
            const _date = date.format(DATE_FILTER_FORMAT);
            setSelectedKeys(_date);
          }}
          format="MM/DD/YYYY"
          className="datePic mt-5 mb-8"
          value={
            selectedKeys && selectedKeys.length
              ? moment(selectedKeys)
              : undefined
          }
          allowClear={false}
        />        
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  }

  handleReset = (clearFilters) => {
    clearFilters();
    this.props.onReset && this.props.onReset();
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const date = selectedKeys ? selectedKeys : "";

    this.props.onSearch && this.props.onSearch(date, dataIndex);
  };
}

export default DateFilter;
