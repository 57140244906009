import { setPage } from "actions/app";
import CustomTable from "components/common/table/Table";
import CommonLayout from "components/layout/Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { MAP_TYPE_COLUMNS } from "utils/column";
import { mapTypes } from "utils/constants";

const MapSelect = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setPage('MAP Form Builder'))
    },[dispatch])

    const setupRows = () => {
        return mapTypes?.map((o) => {
            return {
                "type": o.type,
                "_id": o._id,
            }
        }) 
    }

    const viewMapTypes = (record) => {
      navigate(`/map/type/form-builder/${record.type.toLowerCase()}`)
    }

    return(
        <CommonLayout  className="content-mr" breadCrumbText='MAP Form Builder'>
            <div className="promo-table-card">
              <div className='w-100'>
                  <CustomTable
                    columns={MAP_TYPE_COLUMNS}
                    rows={setupRows()}
                    rowKey="_id"
                    isViewable={true}
                    onView={viewMapTypes}
                    isPopup={true}
                    isPromoView={true}
                    />
              </div>

             
          </div>
        </CommonLayout>
    )
}

export default MapSelect;