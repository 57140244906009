import React from "react";
import RadioElement from "./staticElements/RadioElement";
import DropdownElement from "./staticElements/DropdownElement";
import CheckboxElement from "./staticElements/CheckboxElement";
import InputElement from "./staticElements/InputElement";
const StaticElements = (props) => {
  return (
    <>
      {props.field === "input" && (
        <InputElement
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          action={props.action}
          ans={props.ans}
          error={props.error}
          sub={props.sub}
          id={props.id}
          id1={props.id1}
          designate={props.designate}
          isRegexRequired={props.isRegexRequired}
          handleSubOptionChange={props.handleSubOptionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          details={props.details}
          promoType={props.promoType}
        />
      )}
      {props.field === "dropdown" && (
        <DropdownElement
          onChange={props.onChange}
          id={props.id}
          item={props.item}
          label={props.label}
          ans={
            (props.action === "view" || props.action === "edit") && props.ans
          }
          options={props.options}
          action={props.action}
          designate={props.designate}
          handleSubOptionChange={props.handleSubOptionChange}
          fieldData={props.fieldData}
          promoTypeId={props.promoTypeId || ""} error={props.error}
          handleChangeExits={props.handleChangeExits}
          promoType={props.promoType}
        />
      )}
      {props.field === "radio" && (
        <RadioElement
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          subOptions={props.subOptions}
          subOption1={props.subOption1}
          designate={props.designate}
          handleSubOptionChange={props.handleSubOptionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          SubOptionInput={props.SubOptionInput}
          SubOptionInputThree={props.SubOptionInputThree}
          promoType={props.promoType}
        />
      )}
      {props.field === "checkbox" && (
        <CheckboxElement
          label={props.label}
          options={props.options}
          onChange={props.onChange}
          id={props.id}
          action={props.action}
          ans={props.ans}
          error={props.error}
          designate={props.designate}
          handleSubOptionChange={props.handleSubOptionChange}
          isPromoTypeQuestion={props.isPromoTypeQuestion || false}
          pqid={props.pqid}
          promoType={props.promoType}
        />
      )}
    </>
  );
};

export default StaticElements;