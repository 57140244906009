import * as ActionTypes             from './action-types';
import qs from "qs";

export const updateLocalCurrentTeam = (team) => ({
    type: ActionTypes.UPDATE_LOCAL_CURRENT_TEAM,
    team
})

export const showHideUnassignButton = (flag, newSelectedRowKeys) => ({
    type: ActionTypes.SHOW_UNASSIGN_BUTTON,
    data: {flag, newSelectedRowKeys}
})

export const getAllTeams = (params = {}) => {
    
    let url = `${process.env.REACT_APP_API_URL}/api/teams?`
    if (params.search) {
        url += `filters[name]=${params.search}`
    }
    return {
    type: ActionTypes.GET_ALL_TEAMS,
    payload: {
        request: {
            url,
            method: "get",
            params: params,
            paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
            },
        }
    }
}};

export const getTeamById = (teamId) => {
    
    let url = `${process.env.REACT_APP_API_URL}/api/teams/${teamId}`
    return {
    type: ActionTypes.GET_TEAM_BY_ID,
    payload: {
        request: {
            url,
            method: "get",
        }
    }
}};

export const createTeam = (name,status,isVCM) => ({
    type: ActionTypes.CREATE_TEAM,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/teams`,
            method: "post",
            data:{
                "name": name,
                "status": status,
                "isVCM" : isVCM
            }
        }
    }
})

export const updateTeamById =(teamId,name,status,isVCM) => ({
    type: ActionTypes.UPDATE_TEAM,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/teams/${teamId}`,
            method: "put",
            data:{
                "name": name,
                "status": status,
                "isVCM" : isVCM
            }
        }
    },
    meta:{
            "name": name,
            "status": status,
            "_id": teamId,
            "isVCM" : isVCM
        }
});

export const deleteTeamById = (teamId, isVCM) => ({
    type: ActionTypes.DELETE_TEAM,
    payload: {
        request: {
            url:`${process.env.REACT_APP_API_URL}/api/teams/${teamId}`,
            method: "delete",
        }
    },
    meta:{
        "_id": teamId
    }
})

export const updateTeamByStatus =(teamId,status) => ({
    type: ActionTypes.UPDATE_TEAM_STATUS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/teams/${teamId}`,
            method: "put",
            data:{
                "isActive": status,
            }
        }
    },
    meta:{
            "isActive": status,
            "_id": teamId,
        }
});
