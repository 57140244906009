import React, { useMemo } from "react";
import {
  Handle,
  useNodeId,
  useReactFlow,
  getOutgoers,
  getIncomers,
  useStore,
} from "reactflow";
import { PlusCircleOutlined } from "@ant-design/icons";
const selector = (s) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});
const CustomHandle = (props) => {
  const nodeId = useNodeId();
  const { edges } = useStore(selector);
  const { getNodes } = useReactFlow();
  const incomers = useMemo(() => {
    return getIncomers({ id: nodeId }, getNodes(), edges);
  }, [edges, nodeId, getNodes]);
  const outgoers = useMemo(() => {
    return getOutgoers({ id: nodeId }, getNodes(), edges);
  }, [edges, nodeId, getNodes]);

  if (props.type === "source" && outgoers.length === 0) {
    return (
      <Handle {...props} isConnectable={false}>
        {!props.hideAdd && (
          <PlusCircleOutlined
            style={{
              position: "absolute",
              top: "auto",
              left: "50%",
              bottom: "-4px",
              transform: "translate(-50%, 0)",
              pointerEvents: "all",
              background: "white",
              cursor: "pointer",
            }}
            position="top"
            className="nodrag nopan"
            onClick={props.addClick}
          />
        )}
      </Handle>
    );
  } else if (props.type === "target" && incomers.length === 0) {
    return (
      <Handle {...props} isConnectable={false}>
        {!props.hideAdd && (
          <PlusCircleOutlined
            style={{
              position: "absolute",
              top: "-4px",
              left: "50%",
              bottom: "auto",
              transform: "translate(-50%, 0)",
              pointerEvents: "all",
              background: "white",
              cursor: "pointer",
            }}
            position="right"
            className="nodrag nopan"
            onClick={props.addClick}
          />
        )}
      </Handle>
    );
  }

  return <Handle {...props}></Handle>;
};

export default CustomHandle;
