import { Select } from "antd";
import { useEffect, useState } from "react";
import InputEle from "./InputEle";
import RadioEle from "./RadioEle";
import TextEle from "./TextEle";
const { Option } = Select;

const DropDownEle = ({question,index,questionType,questionHeader,options,designate,action,handleDropdownChange,handleRadioSubChange,handleInputSubChange,isLevelTwo=false,handleDropdownSubChange,answer,subAnswer,handleTextareaSubChange,record}) => {
    const [subOptionObj,setSubOptionObj] = useState(null);
    const [inputValue,setInputValue] = useState('')
    const [textareaValue,setTextareaValue] = useState('')

    useEffect(() => {
    if(action === 'view' && answer){
      const object1 = options?.filter((item) =>
      (item?.optionText || item?.subOptionText)
        .toLowerCase()
        .trim()
        .includes(answer.toLowerCase().trim())
      );

      if(object1[0]?.subOptions?.length){
        setSubOptionObj(object1[0]?.subOptions)
      }else{
        setSubOptionObj(null)
      }
    }
  },[action,answer,options])

    const handleDropdown = (value,i,record) => {
      handleInputSubChange('',i,'','','',record)
      handleTextareaSubChange('',i,'','','',record)
      handleDropdownChange(value,i,record)

      const object1 = options?.filter((item) =>
      (item?.optionText || item?.subOptionText)
        .toLowerCase()
        .trim()
        .includes(value.toLowerCase().trim())
      );

      if(object1[0]?.subOptions?.length){
        setSubOptionObj(object1[0]?.subOptions)
      }else{
        setSubOptionObj(null)
      }
    }

    useEffect(() => {
      if(subOptionObj && subOptionObj[0].subOptionType === 'input'){
        let arr = []
        for(let i=0;i<subOptionObj.length;i++){
          arr.push({
            subIndex:i,
            value:""
          })
        }
        setInputValue(arr)
      }

      if(subOptionObj && subOptionObj[0].subOptionType === 'textarea'){
        let arr = []
        for(let i=0;i<subOptionObj.length;i++){
          arr.push({
            subIndex:i,
            value:""
          })
        }
        setTextareaValue(arr)
      }
    },[subOptionObj])

    return(<>
    <div 
    className="dropDownDiv"
    >
      {
        action === 'create' && isLevelTwo &&
      <div className="d-label">
      {designate && designate === 'required' ? <span className="spanReq">*</span> : ''}  
      <label className="input-label">{questionHeader}</label>
      </div>
      }

      {
        action === 'create' && !isLevelTwo && <div 
        >   
            <Select
              onChange={(value) => handleDropdown(value,index,record)}
              placeholder="Select"
            >
              {options?.map((item) => (
                <Option key={item.optionText}>
                  {item.option}
                </Option>
              ))}
            </Select>
          </div>
      }

      {
        action === 'create' && isLevelTwo && <div 
        >
            <Select
              onChange={(value) => handleDropdownSubChange(value,index,questionType,questionHeader,record)}
              placeholder="Select"
            >
              {options?.map((item) => (
                <Option key={item.optionText || item.subOptionText}>
                  {item.optionText || item.subOptionText}
                </Option>
              ))}
            </Select>
          </div>
      }

      {/* view cases */}

      {
        action === 'view' && !isLevelTwo && <div>
            <Select
              disabled
              value={answer || null}
              placeholder="Select"
            >
              {options?.map((item) => (
                <Option key={item.optionText}>
                  {item.option}
                </Option>
              ))}
            </Select>
          </div>
      }

      {
        action === 'view' && isLevelTwo && <div >
            <Select
              disabled
              placeholder="Select"
              value={(subAnswer.length>0 && subAnswer[0].value) || null}
            >
              {options?.map((item) => (
                <Option key={item.optionText || item.subOptionText}>
                  {item.optionText || item.subOptionText}
                </Option>
              ))}
            </Select>
          </div>
      }
    </div>
    {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'radio' && 
        <RadioEle
        question={subOptionObj}
        index={index}
        questionType={subOptionObj[0].subOptionType}
        questionHeader={subOptionObj[0].subOptionText}
        options={subOptionObj[0].subOptions}
        action={'create'}
        isLevelTwo={true}
        handleRadioSubChange={handleRadioSubChange}
        record={record}
        />
      }

    {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'input' && 
        subOptionObj.map((item,i) => {
          return(<InputEle
            key={i}
            index={index}
            action={'create'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleInputSubChange={handleInputSubChange}
            subIndex={i}
            inputValue={inputValue && inputValue.length>0 && inputValue}
            setInputValue={setInputValue}
            record={record}
          />)
        }
        )
      }

    {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'textarea' && 
        subOptionObj.map((item,i) => {
          return(<TextEle
            key={i}
            index={index}
            action={'create'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleTextareaSubChange={handleTextareaSubChange}
            subIndex={i}
            textareaValue={textareaValue && textareaValue.length>0 && textareaValue}
            setTextareaValue={setTextareaValue}
            record={record}
          />)
        }
        )
      }

      {/* views sub*/}
      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'radio' && 
        <RadioEle
        question={subOptionObj}
        index={index}
        questionType={subOptionObj[0].subOptionType}
        questionHeader={subOptionObj[0].subOptionText}
        options={subOptionObj[0].subOptions}
        action={'view'}
        isLevelTwo={true}
        handleRadioSubChange={handleRadioSubChange}
        subAnswer={subAnswer}
        />
      }

      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'input' && 
        subOptionObj.map((item,i) => 
        {
          let val = subAnswer.filter(item => item.subIndex === i)
          return(<InputEle
            key={i}
            index={index}
            action={'view'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleInputSubChange={handleInputSubChange}
            subIndex={i}
            subAnswer={val[0].value}
          />)}
        )
      }

      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'textarea' && 
        subOptionObj.map((item,i) => 
        {
          let val = subAnswer.filter(item => item.subIndex === i)
          return(<TextEle
            key={i}
            index={index}
            action={'view'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleTextareaSubChange={handleTextareaSubChange}
            subIndex={i}
            subAnswer={val[0].value}
          />)}
        )
      }
      
   </> )
}

export default DropDownEle;