import BottomSection from "./BottomSection";

const TextAreaQuestion = ({
  isTextArea,
  question,
  loading,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  handleQuestionDesignate,
  handleEmergencyTeamChange,
  handleStandardTeamChange
}) => {
  return (
    <>
      <BottomSection
        isSameType={isTextArea && question.questionType === "textarea"}
        onCheckBoxChange={(e) => handleQuestionDesignate(e, index)}
        loading={loading}
        handleSaveQuestion={() => handleSaveQuestion(question, index)}
        copyQuestion={() => copyQuestion(index)}
        deleteQuestion={() => deleteQuestion(index)}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
      />
    </>
  );
};

export default TextAreaQuestion;
