import { S, } from 'utils/prefix';
import * as ActionTypes from 'actions/notifications/action-types'
import NotificationStateManager from './state-manager';

const INITIAL_STATE = {
        notifications: [],
        merchantNotifications: [],
        adminPaginate: [],
        merchantPaginate: [],
        merchantNotificationsWithoutPage: [],
        notificationsWithoutPage: [],
        basicPaginate:[],
        basicNotificationsWithoutPage:[]
};

export default function NotificationStates(state = INITIAL_STATE, action) {
        switch (action.type) {

                case S(ActionTypes.GET_ALL_NOTIFICATIONS):
                        return NotificationStateManager.getAllNotifications(state, action);
                case S(ActionTypes.GET_ALL_MERCHANT_NOTIFICATIONS):
                        return NotificationStateManager.getMerchantNotifications(state, action);
                case S(ActionTypes.GET_ALL_BASIC_NOTIFICATIONS):
                        return NotificationStateManager.getBasicNotifications(state, action);
                case S(ActionTypes.GET_ALL_NOTIFICATIONS_WITHOUT_PAGINATION):
                        return NotificationStateManager.getAllNotificationsWithoutPage(state, action);
                case S(ActionTypes.GET_ALL_MERCHANT_NOTIFICATIONS_WITHOUT_PAGINATION):
                        return NotificationStateManager.getMerchantNotificationsWithoutPage(state, action);
                case S(ActionTypes.GET_ALL_BASIC_NOTIFICATIONS_WITHOUT_PAGINATION):
                        return NotificationStateManager.getBasicNotificationsWithoutPage(state, action);
                default:
                        return state;
        }
}