import * as yup from "yup";

export const ReportEditSchema = yup.object().shape({
  title: yup.string().required("Report name is required"),
});

export const CreateXMLTemplate = yup.object().shape({
  templateName: yup.string().required("Template name is required"),
  promoType: yup.string().required("Promo type is required"),
  fileName: yup.string().required("Upload template is required"),
});
