export default class ReportsStateManager {
    static getAllReports = (prevState, action) => {
        const state = { ...prevState };

        const data = action.payload.data.data;
        const updatedReports = data.reports.map(team => ({
            ...team,
            label: team.name,
            value: team._id
        }));
        state.reports = updatedReports || [];
        state.pagination = data.pagination || undefined;
        state.total = data.pagination?.total || 0;

        return state;
    }











}