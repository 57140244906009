import { DownOutlined } from "@ant-design/icons";
import { Timeline, Dropdown, Space, Spin } from "antd";
import { SuccessSvg, SubmitSvg, ErrorSvg, RejectSvg } from "utils/constants";
import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import useSWR from "swr";
import PromoService from "services/promo-service/promo-service";
const ViewActivity = (props) => {
  const promoId = props.id;

  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedMonthKey, setSelectedMonthKey] = useState("0");
  const [filteredTimeline, setFilteredTimeline] = useState([]);
  const promoService = useMemo(() => new PromoService(), []);

  const { data, isLoading, isValidating } = useSWR(
    { key: "timeline", id: promoId },
    (payload) => promoService.getTimeline(payload.id)
  );

  useEffect(() => {
    if (data?.data) {
      const _timeline = data?.data?.filter((i) => {
        const s = moment(i.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ").format(
          "MMMM YYYY"
        );
        return s === selectedMonth;
      });
      setFilteredTimeline(_timeline || []);
    }
  }, [data?.data, selectedMonth]);

  const items = useMemo(() => {
    let startMonth, endMonth;
    data?.data?.forEach((i) => {
      const s = moment(i.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ");
      const e = moment(i.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ");
      if (!startMonth || startMonth > s) startMonth = s;
      if (!endMonth || endMonth < e) endMonth = e;
    });
    const monthlist = [];
    while (startMonth <= endMonth) {
      monthlist.push({
        label: startMonth.format("MMMM YYYY"),
        key: monthlist.length + 1 + "",
      });
      startMonth.add(1, "months");
    }

    setSelectedMonth(monthlist?.[0]?.label);
    setSelectedMonthKey(monthlist?.[0]?.key);

    return monthlist;
  }, [data?.data]);

  function getTimelineColor(status) {
    switch (status) {
      case "Approved":
        return "#27AE60";
      case "Rejected":
        return "#EB5757";
      case "Submitted":
        return "#828282";
      case "Error Ticket":
        return "#F2994A";
      default:
        return "#27AE60";
    }
  }

  function getTimelineSvg(status) {
    switch (status) {
      case "Approved":
        return <SuccessSvg />;
      case "Rejected":
        return <RejectSvg />;
      case "Submitted":
        return <SubmitSvg />;
      case "Error Ticket":
        return <ErrorSvg />;
      default:
        return <SuccessSvg />;
    }
  }

  if (isLoading || isValidating) {
    return (
      <span
        className="timeline-wrapper-main"
        style={{ flexDirection: "row", margin: "24px 0" }}
      >
        Loading ... <Spin />
      </span>
    );
  }

  return (
    <>
      <br />
      <div className="timeline-wrapper-main">
        <Dropdown
          menu={{
            items,
            selectable: true,
            selectedKeys: [selectedMonthKey],
            onClick: (item) => {
              items.forEach((e) => {
                if (e.key === item.key) {
                  setSelectedMonth(e.label);
                  setSelectedMonthKey(e.key);
                }
              });
            },
          }}
          trigger={["click"]}
        >
          <div>
            <Space>
              {selectedMonth ? selectedMonth : items[selectedMonthKey]?.label}
              <DownOutlined />
            </Space>
          </div>
        </Dropdown>
        <div style={{ marginTop: "32px", width: "336px" }}>
          <Timeline>
            {filteredTimeline?.map((item) => {
              return (
                <Timeline.Item
                  key={item?.updatedAt}
                  color={getTimelineColor(item.status)}
                  dot={getTimelineSvg(item.status)}
                >
                  <div
                    className={
                      item.status === "Approved"
                        ? "timeline-wrapper-success"
                        : "timeline-wrapper"
                    }
                  >
                    {item.status === "Error Ticket" && item.reason && item.reason.startsWith('Change Request') ? (
                      <div>{item.reason}</div>
                    ) : (
                      <div className="timeline-content">
                        <span>{`${item.status} by  ${
                          item?.creadtedByDetails?.fullName || item?.teamId?.name
                        } ${
                          item?.creadtedByteam?.name
                            ? `(${item?.creadtedByteam?.name})`
                            : ""
                        }`}</span>
                        <span className="date-label">
                          {moment(item.updatedAt).format("ddd, Do")}
                        </span>
                      </div>
                    )}
                    {item.status === "Rejected" && (
                      <div>Reason - {item.reason}</div>
                    )}
                   
                   </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
      </div>
    </>
  );
};
export default ViewActivity;
