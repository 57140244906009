import moment from "moment";
export default class AdsetStateManager{
    static getAllAdsets = (prevState, action) => {
        const state = { ...prevState };

        const data  = action?.payload?.data?.data;
        const updatedAdsets = data?.adsetList.map(adset => ({
            ...adset,
            label: adset.name,
            value: adset._id,
            id: adset._id,
            title: adset.name,
            start: moment(adset.startDate).format('YYYY-MM-DD'),
            end: moment(adset.endDate).add(1, 'days').format('YYYY-MM-DD'),
            className: 'adset-view'
          }));
        state.adsets = updatedAdsets || [];
        state.adsetsPagination = data?.pagination || undefined;

        return state;
    }

    static getAllEvents = (prevState, action) => {
        const state = { ...prevState };

        const data  = action?.payload?.data?.data;
        const updatedEvents = data?.events.map(event => ({
            ...event,
            id: event._id,
            title: event.name,
            start: moment(event.startDate).format('YYYY-MM-DD'),
            end: moment(event.endDate).add(1, 'days').format('YYYY-MM-DD'),
            className: 'event-view'
          }));
        state.events = updatedEvents || [];
        state.eventsPagination = data?.pagination || undefined;

        return state;
    }

    static createAdset = (prevState, action) => {
        const state = { ...prevState };
        const adset = action.payload.data.data;
        const updatedAdset = {
            ...adset,
            label: adset.name,
            value: adset._id,
            id: adset._id,
            title: adset.name,
            start: moment(adset.startDate).format('YYYY-MM-DD'),
            end: moment(adset.endDate).add(1, 'days').format('YYYY-MM-DD'),
            className: 'adset-view'
        }
        state.adsets = [...state.adsets, updatedAdset];
       
        return state;
    }

    static updateAdset = (prevState, action) => {
        const state = { ...prevState };
        const newAdset = action.payload.data.data;

        if (newAdset) {
            const updatedAdsets = state.adsets.map(adset => (
                adset._id === action.payload.data.data._id ?
                {
                ...adset,
                ...newAdset,
                label: newAdset.name,
                value: newAdset._id,
                id: newAdset._id,
                title: newAdset.name,
                start: moment(newAdset.startDate).format('YYYY-MM-DD'),
                end: moment(newAdset.endDate).format('YYYY-MM-DD'),
                className: 'adset-view'
              } : adset
              ));
            state.adsets = updatedAdsets;
        }

        return state;
    }

    static createEvent = (prevState, action) => {
        const state = { ...prevState };
        const event = action.payload.data.data;
        const upadatedEvent = {
            ...event,
            id: event._id,
            title: event.name,
            start: moment(event.startDate).format('YYYY-MM-DD'),
            end: moment(event.endDate).add(1, 'days').format('YYYY-MM-DD'),
            className: 'event-view'
        }
        state.events = [upadatedEvent, ...state.events];
       
        return state;
    }

    static updateEvent = (prevState, action) => {
        const state = { ...prevState };
        const newEvent = action.payload.data.data;

        const updatedEvents = state.events.map(event => (
            event._id === action.payload.data.data._id ?
            {
            ...event,
            ...newEvent,
            label: newEvent.name,
            value: newEvent._id,
            id: newEvent._id,
            title: newEvent.name,
            start: moment(newEvent.startDate).format('YYYY-MM-DD'),
            end: moment(newEvent.endDate).format('YYYY-MM-DD'),
            className: 'event-view'
          } : event
          ));
        state.events = updatedEvents;

        return state;
    }

    static deleteEvent = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.events = state.events.filter((item) => item._id !== id)
        
        return state;
    }

    static deleteAdset = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.adsets = state.adsets.filter((item) => item._id !== id)
        
        return state;
    }

}