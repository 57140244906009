export default class PromoTypeStateManager{
    static getAllPromoTypes = (prevState, action) => {
        const state = { ...prevState };

        const data  = action.payload.data.data.payload;
        
        state.promoTypes = data.promoTypes || [];

        return state;
    }

    static createPromoType = (prevState, action) => {
        const state = { ...prevState };
        const data=action.payload.data.data;
        state.promoTypes=[{...data, emergencyWorkflow:data?.emergencyWorkflowDetails?.name, standardWorkflow:data?.standardWorkflowDetails?.name},...state.promoTypes];
       
        return state;
    } 

    static updatePromoType = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.promoTypes.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.promoTypes[index]={ ...state.promoTypes[index], ...newRecord };
          state.promoTypes = [ ...state.promoTypes ];
        }
  
        return state;
    }

    static deletePromoType = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.promoTypes = state.promoTypes.filter((item) => item._id !== id)
        
        return state;
    }

    static updatePromoTypeStatus = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.promoTypes.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.promoTypes[index]={ ...state.promoTypes[index], ...newRecord };
          state.promoTypes = [ ...state.promoTypes ];
        }
  
        return state;
    }

    static updatePromoTypeBySearch = (prevState, action) => {
        const state = { ...prevState };
        const  data  = action.promoTypes;
        state.searchedPromoTypes = data;
        return state;
    }

    static getQuestionUnPublished = (prevState, action) => {
        const state = { ...prevState };

        const data  = action.payload.data.data;
        
        state.promoTypeQuestions = data || [];

        return state;
    }
    static getPromoTypeById=(prevState, action)=>{
        const state={...prevState};
        const data=action.payload.data.data;
        state.promoTypeById=data||{};
        return state;
    }
}