import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/calendar/action-types'
import AdsetStateManager    from './state-manager';

const INITIAL_STATE = {
  adsets: [],
  adsetsPagination: undefined,
  events: [],
  eventsPagination: undefined,
};

export default function AdsetStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case S(ActionTypes.GET_ALL_ADSETS):
                return AdsetStateManager.getAllAdsets(state,action);
        case S(ActionTypes.GET_ALL_EVENTS):
                return AdsetStateManager.getAllEvents(state,action);        
        case S(ActionTypes.CREATE_ADSET):
                return AdsetStateManager.createAdset(state,action);
        case S(ActionTypes.UPDATE_ADSET):
                return AdsetStateManager.updateAdset(state,action);
        case S(ActionTypes.UPDATE_EVENT):
                return AdsetStateManager.updateEvent(state,action);
        case S(ActionTypes.CREATE_EVENT):
                return AdsetStateManager.createEvent(state,action);
        case S(ActionTypes.DELETE_EVENT):
                return AdsetStateManager.deleteEvent(state,action);
        case S(ActionTypes.DELETE_ADSET):
                return AdsetStateManager.deleteAdset(state,action);
        case ActionTypes.SHOW_UNASSIGN_BUTTON:
                return { ...state, showUnassignButton: action.data?.flag, newSelectedRowKeys: action.data?.newSelectedRowKeys };
        default:
            return state;
    }
}