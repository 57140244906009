import * as ActionTypes from "./action-types";
import qs from "qs";
export const SORT_ORDER = {
  ascend: "ASC",
  descend: "DSC",
};

export const getWorkflowList = (params = {}) => {
  let url = `${process.env.REACT_APP_API_URL}/api/workflow?`;
  return {
    type: ActionTypes.GET_WORKFLOW_LIST,
    payload: {
      request: {
        url,
        method: "get",
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    },
  };
};
export const createWorkFlow = (data = {}) => {
  let url = `${process.env.REACT_APP_API_URL}/api/workflow`;
  return {
    type: ActionTypes.GET_WORKFLOW_LIST,
    payload: {
      request: {
        url,
        method: "post",
        data,
      },
    },
  };
};
export const editWorkFlow = (data = {}) => {
  let url = `${process.env.REACT_APP_API_URL}/api/workflow/${data.id}`;
  return {
    type: ActionTypes.GET_WORKFLOW_LIST,
    payload: {
      request: {
        url,
        method: "put",
        data,
      },
    },
  };
};
export const copyWorkFlow = (data) => {
  let url = `${process.env.REACT_APP_API_URL}/api/promoType/clone/${data}`;
  return {
    type: ActionTypes.CLONE_WORKFLOW,
    payload: {
      request: {
        url,
        method: "post",
        data: {},
      },
    },
  };
};
export const deleteWorkflow = (id) => {
  let url = `${process.env.REACT_APP_API_URL}/api/workflow/${id}`;
  return {
    type: ActionTypes.GET_WORKFLOW_LIST,
    payload: {
      request: {
        url,
        method: "delete",
      },
    },
  };
};
export const viewWorkflow = (id) => ({
  type: ActionTypes.VIEW_WORKFLOW,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/workflow/${id}`,
      method: "get",
    },
  },
});
