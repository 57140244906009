export default class ShelfStateManager{
    static getAllShelf = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data.payload;
        
        state.shelf = data.shelfList || [];

        return state;
    }

    static createShelf = (prevState, action) => {
        const state = { ...prevState };
        state.shelf=[action.payload.data.data,...state.shelf];
       
        return state;
    } 

    static updateShelf = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.shelf.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.shelf[index]={ ...state.shelf[index], ...newRecord };
          state.shelf = [ ...state.shelf ];
        }
  
        return state;
    }

    static deleteShelf = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.shelf = state.shelf.filter((item) => item._id !== id)
        
        return state;
    }

    static getSkuAssociatedShelf = (prevState, action) => {
        const state = { ...prevState };
  
        const  data  = action.payload.data?.data?.skuKey;
        
        state.singleShelfData = data || [];
  
        return state;
      }

      static getSearchSKU = (prevState, action) => {
        const state = { ...prevState };
  
        const  data  = action.payload.data?.data;
        
        state.skuSearchList = data || [];
  
        return state;
      }

    static updateShelfBySearch = (prevState, action) => {
        const state = { ...prevState };
        const  data  = action.shelf;
        state.searchedShelf = data;

        return state;
    }
}