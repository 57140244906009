import * as ActionTypes from "./action-types";
import { SORT_ORDER } from "./../promotions";
import qs from "qs";

export const updateLocalErrorTicketJSON = (errorTickets) => ({
  type: ActionTypes.UPDATE_LOCAL_ERROR_TICKET_JSON,
  errorTickets,
});

export const getAllErrorTickets = (params = {}) => {
  if (params.orderBy) {
    params.orderBy = SORT_ORDER[params.orderBy];
  }
  let url = `${process.env.REACT_APP_API_URL}/api/errorReport/v2`;

  return {
    type: ActionTypes.GET_ALL_ERROR_TICKET,
    payload: {
      request: {
        url: url,
        method: "get",
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    },
  };
};

export const createErrorTickets = (
  title,
  sku,
  date,
  additionalInfo,
  status,
  priority,
  errorType,
  raisedBy,
  promoName,
  actionOwner
) => ({
  type: ActionTypes.CREATE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport`,
      method: "post",
      data: {
        errorType: errorType,
        additionalInfo: additionalInfo,
        status: status,
        raisedBy: raisedBy,
        title: title,
        priority: priority,
        date: date,
        skuId: sku,
        promoName: promoName,
        actionOwner: actionOwner,
      },
    },
  },
});

export const getSingleErrorTicket = (id) => ({
  type: ActionTypes.GET_SINGLE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/${id}`,
      method: "get",
    },
  },
});

export const updateErrorTicketById = (
  title,
  sku,
  additionalInfo,
  status,
  priority,
  errorType,
  raisedBy,
  promoName,
  actionOwner,
  _id
) => ({
  type: ActionTypes.UPDATE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/${_id}`,
      method: "put",
      data: {
        errorType: errorType,
        additionalInfo: additionalInfo,
        status: status,
        raisedBy: raisedBy,
        title: title,
        priority: priority,
        skuId: sku,
        promoName: promoName,
        actionOwner: actionOwner,
      },
    },
  },
  meta: {
    errorType: errorType,
    additionalInfo: additionalInfo,
    status: status,
    raisedBy: raisedBy,
    title: title,
    priority: priority,
    _id: _id,
  },
});

export const deleteErrorTicketById = (_id) => ({
  type: ActionTypes.DELETE_ERROR_TICKET,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/${_id}`,
      method: "delete",
    },
  },
  meta: {
    _id: _id,
  },
});

export const updateTicketStatus = (_id, status) => ({
  type: ActionTypes.CHANGE_STATUS,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/${_id}`,
      method: "patch",
      data: {
        status: status,
      },
    },
  },
  meta: {
    status: status,
    _id: _id,
  },
});

export const getCommentAdmin = (id) => ({
  type: ActionTypes.GET_COMMENT_ADMIN,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/commentData/${id}`,
      method: "get",
    },
  },
});

export const createCommentAdmin = (id, msg) => ({
  type: ActionTypes.CREATE_COMMENT_ADMIN,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/comment`,
      method: "post",
      data: {
        comment: msg,
        reportId: id,
      },
    },
  },
});

export const updateErrorTicketBySearch = (errorTickets) => ({
  type: ActionTypes.UPDATE_ERROR_TICKET_BY_SEARCH,
  errorTickets,
});

export const resetSingleTicket = (errorTickets) => ({
  type: ActionTypes.RESET_SINGLE_TICKET,
  errorTickets,
});

export const getAllErrorTypeValues = () => ({
  type: ActionTypes.GET_ERROR_TYPE_VALUES,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/allMsg`,
      method: "get",
    },
  },
});

export const getErrorReportExportData = (data) => ({
  type: ActionTypes.GET_ERROR_EXPORT_DATA,
  payload: {
    request: {
      url: `${process.env.REACT_APP_API_URL}/api/errorReport/download`,
      method: "post",
      data
    },
  },
});
