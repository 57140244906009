import "index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./containers/app/App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";
import AlertProvider from "context/alert/alert.provider";
import DataLoader from "components/data-loader/data-loader.component";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AlertProvider>
          <App />
          <DataLoader />
        </AlertProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
