export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const NAME_PATTERN =
  /^[a-zA-Z0-9&()]+(([',. -][a-zA-Z0-9&() ])?[a-zA-Z0-9&()]*)*$/g;
export const UPPERCASE_PATTERN = /(?=.*?[A-Z])/;
export const LOWERCASE_PATTERN = /(?=.*?[a-z])/;
export const SPECIAL_PATTERN = /(?=.*?[#?!@$%^&*-])/;
export const DIGIT_PATTERN = /(?=.*?[0-9])/;
export const ALPHANUMERIC_PATTERN = /^[a-zA-Z0-9-]*$/;
export const ALPHANUMERIC_PATTERN_WITH_SPACE = /^[a-zA-Z0-9\s]*$/;
export const ALPHANUMERIC_SPECIAL_NOT_AT_START = /^(?=.*[a-zA-Z0-9\s]).*$/;
