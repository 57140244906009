import { S } from "utils/prefix";
import * as ActionTypes from "actions/errorTicket/action-types";
import ErrorTicketStateManager from "./state-manager";

const INITIAL_STATE = {
  getCommentAdmin: [],
  errorTickets: [],
  singleErrorTicket: [],
  searchedErrorTickets: [],
  errorTypeValues: [],
  total: 0,
};

export default function ErrorTicketsStates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_LOCAL_ERROR_TICKET_JSON:
      return { ...state, errorTickets: [...action.errorTickets] };

    case S(ActionTypes.GET_ALL_ERROR_TICKET):
      return ErrorTicketStateManager.getAllErrorTickets(state, action);
    case S(ActionTypes.CREATE_ERROR_TICKET):
      return ErrorTicketStateManager.createErrorTickets(state, action);
    case S(ActionTypes.GET_SINGLE_ERROR_TICKET):
      return ErrorTicketStateManager.getSingleErrorTicket(state, action);
    case S(ActionTypes.UPDATE_ERROR_TICKET):
      return ErrorTicketStateManager.updateErrorTicket(state, action);
    case S(ActionTypes.DELETE_ERROR_TICKET):
      return ErrorTicketStateManager.deleteErrorTicket(state, action);
    case S(ActionTypes.GET_COMMENT_ADMIN):
      return ErrorTicketStateManager.getCommentAdmin(state, action);
    case S(ActionTypes.CHANGE_STATUS):
      return ErrorTicketStateManager.updateTicketStatus(state, action);
    case ActionTypes.UPDATE_ERROR_TICKET_BY_SEARCH:
      return ErrorTicketStateManager.updateErrorTicketBySearch(state, action);
    case ActionTypes.RESET_SINGLE_TICKET:
      return ErrorTicketStateManager.resetSingleTicket(state, action);
    case S(ActionTypes.GET_ERROR_TYPE_VALUES):
      return ErrorTicketStateManager.getAllErrorTypeValues(state, action);

    default:
      return state;
  }
}
