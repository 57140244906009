import { S, F } from "../../utils/prefix";
import * as ActionTypes from "../../actions/sku/action-types";

const INITIAL_STATE = {
   skuList:null,
   skuPage:null,
   brandList:null,
   singleBrandData:null,
   singleSKUData:null,
   skuSearchList:[],
  };

  export default function skuStates(state = INITIAL_STATE, action) {
    switch (action.type) {
      case S(ActionTypes.GET_ALL_SKU):       
        return {
            ...state,
            skuList: action.payload.data?.data?.skuList,      
            skuPage: action.payload.data?.data?.paginate,  
          };
      case S(ActionTypes.GET_ALL_SEARCH_SKU):       
        return {
            ...state,
            skuSearchList: action.payload.data?.data?.skuList,  
          };

          case S(ActionTypes.GET_ALL_BRANDS):
          
        return {
            ...state,
            brandList: action.payload?.data?.data,            
          };

          case S(ActionTypes.GET_SINGLE_BRAND):
            
        return {
            ...state,
            singleBrandData: action.payload?.data?.data,          
          };

          case S(ActionTypes.GET_SINGLE_SKU):            
        return {
            ...state,
            singleSKUData: action.payload?.data?.data,          
          };
        
      case F(ActionTypes.GET_ALL_SKU):
      case F(ActionTypes.GET_SINGLE_SKU): 
      case F(ActionTypes.GET_ALL_BRANDS): 
      case F(ActionTypes.GET_SINGLE_BRAND):
      default:
        return state;
    }
  }