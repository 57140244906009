import { getPromoList } from "actions/promotions";
import { getAllPromoTypes } from "actions/promoTypes";
import { getAllShelf, getSKUBySearch } from "actions/shelf";
import { getBrand } from "actions/sku";
import { getAllUsers } from "actions/users";
import { Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
const { Option } = Select;

const MultipleSelect = ({question,index,questionType,questionHeader,options,designate,action,handleMultipleSelectChange,answer,record}) => {
    const dispatch = useDispatch();
    const [promoOptionData,setPromoOptionData] = useState([])
    const [shelfOptionData,setShelfOptionData] = useState([])
    const [userOptionData,setUserOptionData] = useState([])
    const [brandOptionData,setBrandOptionData] = useState([])
    const [promoTypeOptionData,setPromoTypeOptionData] = useState([])
    const [fetching, setFetching] = useState(false);
    const [dataSku, setDataSku] = useState([]);
    const [timer, setTimer] = useState(null)

    useEffect(() => {
    if(question.masterDataType === 'promo'){
        const getPromoCall = async () => {
            const res = await dispatch(getPromoList())
            
            if(res.error === undefined){
                setPromoOptionData(res.payload.data.data.payload.promoList || [])
            }
        }
        getPromoCall()
    }
    if(question.masterDataType === 'user'){
        const getUserCall = async () => {
            const res = await dispatch(getAllUsers())
            
            if(res.error === undefined){
                setUserOptionData(res.payload.data.data.users || [])
            }
        }
        getUserCall()
    }
    if(question.masterDataType === 'shelf'){
        const getShelfCall = async () => {
            const res = await dispatch(getAllShelf())
            
            if(res.error === undefined){
                setShelfOptionData(res.payload.data.data.payload.shelfList || [])
            }
        }
        getShelfCall()
    }

    if(question.masterDataType === 'brand'){
        const getBrandCall = async () => {
            const res = await dispatch(getBrand())
            
            if(res.error === undefined){
                setBrandOptionData(res.payload.data.data || [])
            }
        }
        getBrandCall()
    }

    if(question.masterDataType === 'promoType'){
        const getPromoTypeCall = async () => {
            const res = await dispatch(getAllPromoTypes())
            
            if(res.error === undefined){
                setPromoTypeOptionData(res.payload.data.data.payload.promoType || [])
            }
        }
        getPromoTypeCall()
    }
  },[question,dispatch])

  const promoChildren = [];
  if(promoOptionData.length>0){
    const filteredPromo = promoOptionData.filter(item => item.status.toLowerCase() !== 'expired')
    for (let i = 0; i < filteredPromo.length; i++) {
            promoChildren.push(<Option key={filteredPromo[i].name}>{filteredPromo[i].name}</Option>);
        }
  }
  const userChildren = [];
  if(userOptionData.length>0){
    const filteredUser = userOptionData.filter(item => item.isActive === true)
    for (let i = 0; i < filteredUser.length; i++) {
            userChildren.push(<Option key={filteredUser[i].fullName || filteredUser[i].firstName}>{filteredUser[i].fullName || filteredUser[i].firstName}</Option>);
        }
  }
  
  const shelfChildren = [];
  if(shelfOptionData.length>0){
    for (let i = 0; i < shelfOptionData.length; i++) {
            shelfChildren.push(<Option key={shelfOptionData[i].shelfPath}>{shelfOptionData[i].shelfPath}</Option>);
        }
  }
  const skuChildren = [];
  if(dataSku.length>0){
    for (let i = 0; i < dataSku.length; i++) {
            skuChildren.push(<Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>);
        }
  }
  const brandChildren = [];
  if(brandOptionData.length>0){
    for (let i = 0; i < brandOptionData.length; i++) {
            brandChildren.push(<Option key={brandOptionData[i].sku_brand_desc}>{brandOptionData[i].sku_brand_desc}</Option>);
        }
  }
  const promoTypeChildren = [];
  if(promoTypeOptionData.length>0){
    for (let i = 0; i < promoTypeOptionData.length; i++) {
            promoTypeChildren.push(<Option key={promoTypeOptionData[i].promoType}>{promoTypeOptionData[i].promoType}</Option>);
        }
  }

  const handleSearchSkuKeys = async(value) => {
      clearTimeout(timer)

      if(value.length>=3){
        const newTimer = setTimeout(async() => {
        setFetching(true)
        setDataSku([])
        const res = await dispatch(getSKUBySearch(value))
        if(res.error === undefined){
          setDataSku(res.payload.data.data)
          setFetching(false)
        }else{
          setFetching(false)
        }
      }, 700)
      setTimer(newTimer)
      }
    }
  
    return(
    <div 
    className="dropDownDiv"
    >
     
      {
        action === 'create' && !question.isMaster &&<Select
              mode="multiple"
              onChange={(value) => handleMultipleSelectChange(value,index,record)}
              placeholder="Select"
            >
                {options?.map((item) => (
                <Option key={item.optionText}>{item.option}</Option>
              ))}
            </Select>
      }

      {/* master data */}

      {
        action === 'create' && question.isMaster &&
        <div 
        >
            {
              question.masterDataType === 'sku' && <Select
              showSearch
              onChange={(value) => handleMultipleSelectChange(value,index,record)}
              placeholder="Search and Select SKU"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearchSkuKeys}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              mode={fetching ? 'multiple' :'tags'}
              tokenSeparators={[',']}
              maxTagCount={3}
            >
              {
                question.masterDataType && question.masterDataType === 'sku' && skuChildren && skuChildren.length>0 && skuChildren
              }
              
            </Select>
            }

            {
              question.masterDataType === 'brand' && <Select
                        showSearch
                        mode="multiple"
                        placeholder="Search and select Brand"
                        optionFilterProp="children"
                        onChange={(value) => handleMultipleSelectChange(value,index,record)}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {
                question.masterDataType && question.masterDataType === 'brand' && brandChildren && brandChildren.length>0 && brandChildren
              }
                      </Select>
            }

            {
              question.masterDataType === 'user' && <Select
                        showSearch
                        placeholder="Search and select User"
                        optionFilterProp="children"
                        mode="multiple"
                        onChange={(value) => handleMultipleSelectChange(value,index,record)}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {
                question.masterDataType && question.masterDataType === 'user' && userChildren && userChildren.length>0 && userChildren
              }
                      </Select>
            }
            
            {
              question.masterDataType && question.masterDataType !== 'sku' && question.masterDataType !== 'brand' && question.masterDataType !== 'user' && <Select
              mode="multiple"
              onChange={(value) => handleMultipleSelectChange(value,index,record)}
              placeholder="Select"
            >
              {
                question.masterDataType && question.masterDataType === 'promo' && promoChildren && promoChildren.length>0 && promoChildren
              }
              {
                question.masterDataType && question.masterDataType === 'shelf' && shelfChildren && shelfChildren.length>0 && shelfChildren
              }
              {
                question.masterDataType && question.masterDataType === 'promoType' && promoTypeChildren && promoTypeChildren.length>0 && promoTypeChildren
              }
            </Select>
            }
          </div>
      }

      {
        action === 'view' && !question.isMaster &&<Select
              className="select-dropdown"
              mode="multiple"
              disabled
              value={answer || []}
              placeholder="Select"
            >
                {options?.map((item) => (
                <Option key={item.optionText}>{item.option}</Option>
              ))}
            </Select>
      }

      {
        action === 'view' && question.isMaster &&
        <div className="select-dropdown">
            {
              question.masterDataType === 'sku' && <Select
              className="select-dropdown"
              showSearch
              disabled
              placeholder="Search and Select SKU"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearchSkuKeys}
              notFoundContent={fetching ? <Spin size="small" /> : null}
              mode={fetching ? 'multiple' :'tags'}
              tokenSeparators={[',']}
              maxTagCount={3}
              value={answer || []}
            >
              {
                question.masterDataType && question.masterDataType === 'sku' && skuChildren && skuChildren.length>0 && skuChildren
              }
              
            </Select>
            }

            {
              question.masterDataType === 'brand' && <Select
                        showSearch
                        mode="multiple"
                        placeholder="Search and select Brand"
                        optionFilterProp="children"
                        className="select-dropdown"
                        disabled
                        value={answer || []}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {
                question.masterDataType && question.masterDataType === 'brand' && brandChildren && brandChildren.length>0 && brandChildren
              }
                      </Select>
            }

            {
              question.masterDataType === 'user' && <Select
                        showSearch
                        placeholder="Search and select User"
                        optionFilterProp="children"
                        className="select-dropdown"
                        mode="multiple"
                        disabled
                        value={answer || []}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {
                question.masterDataType && question.masterDataType === 'user' && userChildren && userChildren.length>0 && userChildren
              }
                      </Select>
            }
            
            {
              question.masterDataType && question.masterDataType !== 'sku' && question.masterDataType !== 'brand' && question.masterDataType !== 'user' && <Select
              className="select-dropdown"
              mode="multiple"
              disabled
              value={answer || []}
              placeholder="Select"
            >
              {
                question.masterDataType && question.masterDataType === 'promo' && promoChildren && promoChildren.length>0 && promoChildren
              }
              {
                question.masterDataType && question.masterDataType === 'shelf' && shelfChildren && shelfChildren.length>0 && shelfChildren
              }
              {
                question.masterDataType && question.masterDataType === 'promoType' && promoTypeChildren && promoTypeChildren.length>0 && promoTypeChildren
              }
            </Select>
            }
          </div>
      }

    </div>
    )
}

export default MultipleSelect;