export default class TeamsStateManager{
    static getAllTeams = (prevState, action) => {
        const state = { ...prevState };

        const data  = action.payload.data.data;
        const updatedTeams = data.teams.map(team => ({
            ...team,
            label: team.name,
            value: team._id
          }));
        state.teams = updatedTeams || [];
        state.pagination = data.pagination || undefined;
        state.total = data.pagination?.total || 0;

        return state;
    }

    static createTeam = (prevState, action) => {
        const state = { ...prevState };
        if (!action?.payload?.data?.data?.members) {
            action.payload.data.data.members = 0;
        }

        state.teams = [action.payload.data.data,...state.teams];
       
        return state;
    }

    static updateTeam = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.teams.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.teams[index]={ ...state.teams[index], ...newRecord };
          state.teams = [ ...state.teams ];
        }
  
        return state;
    }

    static deleteTeam = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.teams = state.teams.filter((item) => item._id !== id)
        
        return state;
    }

    static updateTeamStatus = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state?.teams?.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.teams[index]={ ...state.teams[index], ...newRecord };
          state.teams = [ ...state.teams ];
        }
  
        return state;
    }

    static getTeam = (prevState, action) => {
        const state = { ...prevState };

        const data  = action.payload.data.data;
        
        state.currentTeam = data || [];

        return state;
    }

}