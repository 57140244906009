import "./App.css";
import React, { useEffect } from "react";
import session from "utils/session";
import Login from "containers/login/Login";
import Promotions from "containers/promotion/Promotions";
import PromotionsNew from "containers/promotion/promotionsNew";
import PromoStatusTable from "containers/promotion/PromoStatusTable";
import Profile from "containers/profile/profile";
import ForgotPassword from "containers/forgotPass/ForgotPassword";
import ResetPassword from "containers/forgotPass/ResetPassword";
import SharedUserLayout from "containers/users/SharedUserLayout";
import SharedTeamDetailLayout from "containers/teamDetails/SharedTeamDetailLayout";
import TeamUsers from "containers/teamDetails/TeamUsers";
import AllUsers from "containers/users/AllUsers";
import AdminUsers from "containers/users/AdminUsers";
import BasicUsers from "containers/users/BasicUsers";
import ApproverUsers from "containers/users/ApproverUsers";
import Sku from "containers/sku/sku";
import SkuPromoDetails from "containers/sku/SkuPromoDetails";
import Brands from "containers/brands/Brands";
import ErrorReport from "containers/errorReport/ErrorReport";
import ErrorType from "containers/error-type/Error-type";
import ErrorReportDetailPage from "containers/errorReport/ErrorReportDetailPage";
import TicketReportDetailPage from "containers/ticketReport/TicketReportDetailPage";
import Shelf from "containers/shelf/Shelf";
import SingleBrand from "containers/brands/SingleBrand";
import SingleShelf from "containers/shelf/SingleShelf";
import PromoTypes from "containers/promoTypes/PromoTypes";
import Snapshot from "containers/snapshot/Snapshot";
import Notifications from "containers/notifications/Notifications";
import FormBuilder from "containers/formBuilder/FormBuilder";
import FormBuilderView from "containers/formBuilderView/FormBuilderView";
import FormBuilderPublish from "containers/formBuilderPublish/FormBuilderPublish";
import { onMessageListener } from "utils/firebase-notification";
import { notification } from "antd";
import { useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import PromoTypeFormBuilder from "containers/promoTypes/formbuilder/PromoTypeFormBuilder";
import PromoTypeFormBuilderView from "containers/promoTypes/formBuilderView/PromoTypeFormBuilderView";
import PromoTypeFormBuilderPublish from "containers/promoTypes/formBuilderPublish/PromoTypeFormBuilderPublish";
import Map from "containers/map/Map";
import MapFormBuilder from "containers/map/mapFormBuilder/MapFormBuilder";
import MapFormBuilderView from "containers/map/mapFormBuilderView/MapFormBuilderView";
import MapFormBuilderPublish from "containers/map/mapFormBuilderPublish/MapFormBuilderPublish";
import MapSelect from "containers/map/MapSelect";
import MapView from "containers/map/MapView";
import MapStatusTable from "containers/map/MapStatusTable";
import MapCreate from "containers/map/MapCreate";
import Teams from "containers/teams/Teams";
import Workflow from "containers/workflow/list";
import WorkflowCreate from "containers/workflow/create";
import CalendarManagement from "containers/calendar/Calendar";
import XmlTemplate from "containers/automation-templates/xml-template/xml-templates";
import CsvTemplate from "containers/automation-templates/csv-template/csv-templates";
import CreateTemplate from "containers/automation-templates/csv-template/createTemplate";
import CreateXMLTemplate from "containers/automation-templates/xml-template/createTemplate"
import Report from "containers/reports/list";
import ReportCreate from "containers/reports/create";
import { SWRConfig } from "swr";
import TicketReport from "containers/ticketReport/TicketReport";

const App = () => {
  const navigate = useNavigate();
  const [notificationObj, setNotificationObj] = useState({
    message: "",
    description: "",
  });

  useEffect(() => {
    if (!session.token) {
      const { pathname } = window.location;
      if (pathname.includes("/forgot") || pathname.includes("reset")) {
        pathname.includes("/forgot") && navigate("/forgot");
        pathname.includes("reset") && navigate(`${pathname}`);
      } else {
        navigate("/login");
      }
    } else {
      const { pathname } = window.location;
      !pathname.includes("login") ? navigate(pathname) : navigate("login");
    }
  }, [navigate]);

  useEffect(() => {
    if (notificationObj.description !== "") {
      notification.info({
        message: notificationObj.message,
        description: notificationObj.description,
      });
    }
  }, [notificationObj]);

  onMessageListener()
    .then((payload) => {
      setNotificationObj({
        message: "New Message",
        description:
          payload.notification && payload.notification.body
            ? payload.notification.body
            : "New notification received.",
      });
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <Routes>
        <Route
          path="/reset/:tokenid/:userid"
          exact
          element={<ResetPassword />}
        />
        <Route
          path="error-report/:id"
          exact
          element={<ErrorReportDetailPage />}
        />
        <Route path="promotable/:id" exact element={<PromoStatusTable />} />
        <Route path="singlebrand/:id" exact element={<SingleBrand />} />
        <Route path="error-report" exact element={<ErrorReport />} />
        <Route path="error-type" exact element={<ErrorType />} />
        <Route path="form-builder/view" exact element={<FormBuilderView />} />
        <Route
          path="form-builder/publish"
          exact
          element={<FormBuilderPublish />}
        />
        <Route path="form-builder" exact element={<FormBuilder />} />
        <Route path="shelf" exact element={<Shelf />} />
        <Route path="singleshelf/:id" exact element={<SingleShelf />} />
        <Route path="promotions" exact element={<Promotions />} />
        <Route path="promotions-v2" exact element={<PromotionsNew />} />

        <Route path="xml-templates" exact element={<XmlTemplate />} />
        <Route path="xml-templates/createTemplate" exact element={<CreateXMLTemplate />} />
        <Route path="xml-templates/createTemplate/:id" exact element={<CreateXMLTemplate />} />
        <Route path="xml-templates/createTemplate/:id/:type" exact element={<CreateXMLTemplate />} />
        <Route path="csv-templates" exact element={<CsvTemplate />} />
        <Route path="csv-templates/createTemplate" exact element={<CreateTemplate />} />
        <Route path="csv-templates/createTemplate/:id" exact element={<CreateTemplate />} />
        <Route path="csv-templates/createTemplate/:id/:type" exact element={<CreateTemplate />} />
        <Route path="login" exact element={<Login />} />
        <Route path="profile" exact element={<Profile />} />
        <Route path="forgot" exact element={<ForgotPassword />} />
        <Route path="sku" exact element={<Sku />} />
        <Route path="skupromodetails/:id" exact element={<SkuPromoDetails />} />
        <Route path="brands" exact element={<Brands />} />
        <Route path="users" element={<SharedUserLayout />} exact>
          <Route index exact element={<AllUsers />} />
          <Route path="admin" exact element={<AdminUsers />} />
          <Route path="basic" exact element={<BasicUsers />} />
          <Route path="approver" exact element={<ApproverUsers />} />
        </Route>
        <Route
          path="promo-types/form-builder/:id"
          exact
          element={<PromoTypeFormBuilder />}
        />
        <Route
          path="promo-types/form-builder/view/:id"
          exact
          element={<PromoTypeFormBuilderView />}
        />
        <Route
          path="promo-types/form-builder/publish/:id"
          exact
          element={<PromoTypeFormBuilderPublish />}
        />
        <Route path="promo-types" exact element={<PromoTypes />} />
        <Route path="teams" exact element={<Teams />} />
        <Route
          path="teams/team-detail/:teamId"
          element={<SharedTeamDetailLayout />}
          exact
        >
          <Route index exact element={<TeamUsers />} />
        </Route>
        <Route path="notifications" exact element={<Notifications />} />
        <Route path="snapshot" exact element={<Snapshot />} />
        <Route path="map" exact element={<Map />} />
        <Route path="map/create" exact element={<MapCreate />} />
        <Route path="map/view/:id" exact element={<MapView />} />
        <Route path="map/type" exact element={<MapSelect />} />
        <Route path="map/status/:id" exact element={<MapStatusTable />} />
        <Route
          path="map/type/form-builder/:id"
          exact
          element={<MapFormBuilder />}
        />
        <Route
          path="map/type/form-builder/view/:id"
          exact
          element={<MapFormBuilderView />}
        />
        <Route
          path="map/type/form-builder/publish/:id"
          exact
          element={<MapFormBuilderPublish />}
        />
        <Route path="workflow" exact element={<Workflow />} />
        <Route path="workflow/create" exact element={<WorkflowCreate />} />
        <Route path="workflow/edit/:id" exact element={<WorkflowCreate />} />
        <Route path="/" element={<Navigate replace to="/promotions-v2" />} />
        <Route path="calendar" exact element={<CalendarManagement />} />
        <Route path="reports" exact element={<Report />} />
        <Route path="reports/create" exact element={<ReportCreate />} />
        <Route path="reports/edit/:id" exact element={<ReportCreate />} />
        <Route path="ticket-report" exact element={<TicketReport />} />
        <Route
          path="ticket-report/:id"
          exact
          element={<TicketReportDetailPage />}
        />
      </Routes>
    </SWRConfig>
  );
};

export default App;
