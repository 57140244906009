import {  Tabs } from "antd";
const { TabPane } = Tabs;

const TabEle = ({question,index,questionType,questionHeader,options,designate,action,handleTabChange,answer,record}) => {
    return(
        <div className="text-frame">
            <div className="text-frame-flex">
                {designate && designate === 'required' ? <span className="spanReq">*</span> : ''}
                <label className="input-label">{questionHeader}</label>
            </div>

            { action ==="create" &&
            <Tabs  
            onChange={(key) =>handleTabChange(key,index,record)}
            >
            {options?.map((item) => (
              <TabPane tab={item.optionText} key={item.optionText}></TabPane>
            ))}
          </Tabs>
            }

            { action ==="view" &&
            <Tabs  
            disabled
            defaultActiveKey={answer}
            >
            {options?.map((item) => (
              <TabPane tab={item.optionText} key={item.optionText}></TabPane>
            ))}
          </Tabs>
            }

    </div>
    )
}

export default TabEle;