import "./Topbar.css";
import { Avatar, Breadcrumb, Layout, Popover } from "antd";
import {
  BellOutlined,
  DownOutlined,
  UserOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import session from "utils/session";
import { logout } from "actions/login";
import { useEffect } from "react";
import moment from "moment";
import {
  getAllNotificationsWithoutPage,
  getMerchantNotificationsWithoutPage, getBasicNotificationsWithoutPage
} from "../../actions/notifications";

const { Header } = Layout;

const Topbar = ({
  breadCrumbText,
  backButton = false,
  id = "",
  backUrl = "",
}) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const dispatch = useDispatch();

  const notificationsWithoutPage = useSelector(
    (state) => state.notifications.notificationsWithoutPage
  );
  const merchantNotificationsWithoutPage = useSelector(
    (state) => state.notifications.merchantNotificationsWithoutPage
  );
  const basicNotificationsWithoutPage = useSelector(
    (state) => state.notifications.basicNotificationsWithoutPage
  );
  const [unReadAdminCount, setUnReadAdminCount] = useState(0);
  const [unReadAdminList, setUnReadAdminList] = useState([]);

  const [unReadMerchantCount, setUnReadMerchantCount] = useState(0);
  const [unReadMerchantList, setUnReadMerchantList] = useState([]);

  const [unReadBasicCount, setUnReadBasicCount] = useState(0);
  const [unReadBasicList, setUnReadBasicList] = useState([]);

  const handleLogout = async () => {
    const res = await dispatch(logout());
    if (res.error === undefined) {
      navigate("/login");
    }
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  useEffect(() => {
    if (session && session.role && session.role.toLowerCase() === "merchant") {
      const merchantCall = async () => {
        await dispatch(getMerchantNotificationsWithoutPage(session.userId));
      };
      merchantCall();
    } else if (session && session.role && session.role.toLowerCase() === "basic") {
      const basicCall = async () => {
        await dispatch(getBasicNotificationsWithoutPage(session.userId));
      };
      basicCall();
    } else {
      const adminCall = async () => {
        await dispatch(getAllNotificationsWithoutPage(session.userId));
      };
      adminCall();
    }
  }, [dispatch]);

  useEffect(() => {
    if (notificationsWithoutPage) {
      const notRead = notificationsWithoutPage.filter(
        (item) => item.markedReadByAdmin === false
      );

      setUnReadAdminList(notRead);
      setUnReadAdminCount(notRead.length);
    }
    if (merchantNotificationsWithoutPage) {
      const notRead = merchantNotificationsWithoutPage.filter(
        (item) => item.markedReadByMerchant === false
      );

      setUnReadMerchantList(notRead);
      setUnReadMerchantCount(notRead.length);
    }
    if (basicNotificationsWithoutPage) {
      const notRead = basicNotificationsWithoutPage.filter(
        (item) => item.markedReadByBasic === false
      );

      setUnReadBasicList(notRead);
      setUnReadBasicCount(notRead.length);
    }
  }, [notificationsWithoutPage, merchantNotificationsWithoutPage, basicNotificationsWithoutPage]);

  const content = (
    <div className="topbarDropdownWrapper isoUserDropdown">
      <button className="isoDropdownLink" onClick={handleProfile}>
        Profile Setting
      </button>
      <button className="isoDropdownLink profileClr" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );

  const notificationContent = (
    <div className="notificationWrapper">
      <div className="notificationHead">
        <div className="notificationHeadTitleWrapper">
          <span className="titleLeftText">Notifications</span>
        </div>
      </div>

      <div className="notificationBody">
        {session &&
          session.role &&
          session.role.toLowerCase() === "admin" &&
          unReadAdminList.length > 0
          ? unReadAdminList
            .map((item) => (
              <div className="bodyFrame" key={item._id}>
                <div className="textDiv">
                  <div className="textDivFrame">
                    <span className="titleBodyText">{item.message}</span>
                    <span className="NotificationDateText">
                      {moment(item.createdAt).format("ll")}
                    </span>
                  </div>
                </div>
              </div>
            ))
            .slice(0, 3)
          : session &&
          session.role &&
          session.role.toLowerCase() === "admin" && (
            <div className="noNotificationDiv">No Notification to Read</div>
          )}
        {session &&
          session.role &&
          session.role.toLowerCase() === "merchant" &&
          unReadMerchantList.length > 0
          ? unReadMerchantList
            .map((item) => (
              <div className="bodyFrame" key={item._id}>
                <div className="textDiv">
                  <div className="textDivFrame">
                    <span className="titleBodyText">{item.message}</span>
                    <span className="NotificationDateText">
                      {moment(item.createdAt).format("ll")}
                    </span>
                  </div>
                </div>
              </div>
            ))
            .slice(0, 3)
          : session &&
          session.role &&
          session.role.toLowerCase() === "merchant" && (
            <div className="noNotificationDiv">No Notification to Read</div>
          )}
        {session &&
          session.role &&
          session.role.toLowerCase() === "basic" &&
          unReadBasicList.length > 0
          ? unReadBasicList
            .map((item) => (
              <div className="bodyFrame" key={item._id}>
                <div className="textDiv">
                  <div className="textDivFrame">
                    <span className="titleBodyText">{item.message}</span>
                    <span className="NotificationDateText">
                      {moment(item.createdAt).format("ll")}
                    </span>
                  </div>
                </div>
              </div>
            ))
            .slice(0, 3)
          : session &&
          session.role &&
          session.role.toLowerCase() === "basic" && (
            <div className="noNotificationDiv">No Notification to Read</div>
          )}
      </div>

      <div className="notificationHead">
        <div className="notificationHeadTitleWrapper1">
          {session &&
            session.role &&
            session.role.toLowerCase() === "admin" &&
            unReadAdminList.length > 0 && (
              <Link to={"/notifications"} className="viewAllText">
                View All Notifications
              </Link>
            )}
          {session &&
            session.role &&
            session.role.toLowerCase() === "merchant" &&
            unReadMerchantList.length > 0 && (
              <Link to={"/notifications"} className="viewAllText">
                View All Notifications
              </Link>
            )}
          {session &&
            session.role &&
            session.role.toLowerCase() === "basic" &&
            unReadBasicList.length > 0 && (
              <Link to={"/notifications"} className="viewAllText">
                View All Notifications
              </Link>
            )}
        </div>
      </div>
    </div>
  );

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const handleNotificationVisibleChange = () => {
    setNotificationVisible(!notificationVisible);
  };

  return (
    <Header className="site-layout-background header-p">
      <div className="page-header">
        <div className="heading">
          <div className="components-heading">
            {backButton && (
              <span className="page-text">
                <Link to={backUrl} className="backBtnStyle">
                  <ArrowLeftOutlined className="arrowBtnStyle" />
                </Link>
              </span>
            )}
            <span className="page-text">{breadCrumbText}</span>
          </div>

          <div className="heading-extra">
            <Popover
              trigger="click"
              placement="bottom"
              content={notificationContent}
              onOpenChange={handleNotificationVisibleChange}
              open={notificationVisible}
            >
              {session &&
                session.role &&
                session.role.toLowerCase() === "admin" && (
                  <div className="isoIconWrapper">
                    <BellOutlined className="bellIconStyle" />
                    <span className="notifySpan">{unReadAdminCount}</span>
                  </div>
                )}

              {session &&
                session.role &&
                session.role.toLowerCase() === "merchant" && (
                  <div className="isoIconWrapper">
                    <BellOutlined className="bellIconStyle" />
                    <span className="notifySpan">{unReadMerchantCount}</span>
                  </div>
                )}
              {session &&
                session.role &&
                session.role.toLowerCase() === "basic" && (
                  <div className="isoIconWrapper">
                    <BellOutlined className="bellIconStyle" />
                    <span className="notifySpan">{unReadBasicCount}</span>
                  </div>
                )}
            </Popover>

            <Popover
              trigger="click"
              placement="bottomRight"
              content={content}
              onOpenChange={handleVisibleChange}
              open={visible}
            >
              <span className="user-title">{session && session.fullName}</span>
              <DownOutlined className="downIcon" />
              <Avatar className="userAvatar" icon={<UserOutlined />} />
            </Popover>
          </div>
        </div>

        <div className="breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
              {backUrl ? (
                <Link to={backUrl}>{breadCrumbText}</Link>
              ) : (
                breadCrumbText
              )}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{backButton ? id : ""}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    </Header>
  );
};

export default Topbar;
