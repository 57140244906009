import { Checkbox, Button, Radio } from "antd";
import { CopyOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const BottomSection = ({
  isSameType,
  onCheckBoxChange,
  loading,
  handleSaveQuestion,
  copyQuestion,
  deleteQuestion,
  question,
  showError,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
  showRegex = false,
  onRegexCheckboxChange,
  handleRegexChange,
}) => {
  const promoDetails = useSelector((state) => state.promoTypes.promoTypeById);
  const emergencyTeams = promoDetails?.emergencyWorkflow?.stages || [];
  const standardTeams = promoDetails?.standardWorkflow?.stages || [];
  return (
    <>
      {isSameType && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox onChange={onCheckBoxChange}>Required</Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
          {standardTeams.length && (
            <>
              <div>
                Standard Workflow teams{" "}
                <span className="errorLabel">
                  {question?.errors?.standardTeamError}
                </span>
              </div>

              <Radio.Group
                onChange={handleStandardTeamChange}
                value={question.standardWorkflowApproverTeamId}
              >
                {standardTeams?.filter(item => !item?.teamDetails?.isVCM).map((item, i) => (
                  <Radio value={item?.teamDetails?._id} key={i}>
                    {item?.teamDetails?.name}
                  </Radio>
                ))}
              </Radio.Group>
            </>
          )}
          {emergencyTeams.length && (
            <>
              <div>
                Emergency Workflow teams{" "}
                <span className="errorLabel">
                  {question?.errors?.emergencyTeamError}
                </span>
              </div>
              <Radio.Group
                onChange={handleEmergencyTeamChange}
                value={question.emergencyWorkflowApproverTeamId}
              >
                {emergencyTeams?.filter(item => !item?.teamDetails?.isVCM).map((item, i) => (
                  <Radio value={item?.teamDetails?._id} key={i}>
                    {item?.teamDetails?.name}
                  </Radio>
                ))}
              </Radio.Group>
            </>
          )}
          {showRegex && (
            <div>
              <Checkbox onChange={onRegexCheckboxChange}>Regex</Checkbox>
              {question.isRegexRequired && (
                <div className="formBuilderFrameTwoPromoQuestion">
                  <label className="formBuilderFrameTwoPromoLabel">
                    Conditions
                  </label>
                  <input
                    type="text"
                    className="formBuilderFrameTwoPromoQuestionInput"
                    placeholder="Write Here"
                    value={question.regex}
                    onChange={handleRegexChange}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isSameType && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={handleSaveQuestion}
            disabled={loading}
          >
            Save
          </Button>
          {showError?.()}
          <div className="formBuilderActionsWrapper" disabled={loading}>
            <div className="copyDiv">
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={copyQuestion}
              >
                <CopyOutlined />
              </Button>
            </div>
            <div className="delDiv" disabled={loading}>
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={deleteQuestion}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default BottomSection;
