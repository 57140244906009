import "components/common/profile.css";
import React from "react";
import { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Card, Button, Form, Input,Spin, Alert } from "antd";
import { LockOutlined, CheckCircleFilled, DeleteOutlined } from "@ant-design/icons";
import CommonLayout from "components/layout/Layout";
import Error from "components/common/error";
import { FormControl } from "components/common/LoginStyle";
import { Validator } from "utils/validations";
import * as Patterns from "utils/patterns";
import * as Constants from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, editProfile, changePassword } from "actions/profile";
import { setPage } from "actions/app";
import { TextMessage,SECONDS }     from 'utils/constants'

const Profile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.profile);
  const [edit, setEdit] = useState(false);
  const [change, setChange] = useState(false);
  const [loading,setLoading] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [loadingState,setLoadingState] = useState(false)
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [userProfileData, setUserProfileData] = useState(null);
  const [showDeleteAlert,setShowDeleteAlert] = useState(false)
  const [showPasswordAlert,setShowPasswordAlert] = useState(false)

  useEffect(() => {
    dispatch(setPage("Profile"));
  }, [dispatch]);

  const [resetPasswordError, setResetPasswordError] = useState({
    special: false,
    digit: false,
    upper: false,
    lower: false,
    min: false,
  });

  useEffect(() => {
    
    const getCall = async() =>{
      setLoadingState(true)
      const res = await dispatch(getProfile());
      if(res){
        setLoadingState(false)
      }
    }
    getCall()
  }, [dispatch]);

  useEffect(() => {
  if(userProfile){
    setUserProfileData({
      firstname: userProfile?.firstName,
      lastname: userProfile?.lastName,
      email: userProfile?.email,
    })
  }
},[userProfile])

useEffect(() => {
      if(showDeleteAlert){
        setTimeout(() => {
          setShowDeleteAlert(false)
        }, SECONDS);
      }
      if(showPasswordAlert){
        setTimeout(() => {
          setShowPasswordAlert(false)
        }, SECONDS);
      }
    },[showDeleteAlert,showPasswordAlert])

  useEffect(() => {
    if(userProfileData){
      form.setFieldsValue(userProfileData)
    }
}, [form,userProfileData])

  const handleChangePassword = async(e) => {
    e.preventDefault();
    if (
      confirmPasswordError !== "" ||
      passwordError !== "" ||
      oldPasswordError !== "" ||
      password === "" ||
      confirmPassword === "" ||
      oldPassword === ""
    ) {
      confirmPassword === ""
        ? setConfirmPasswordError(Constants.FIELD_REQUIRED)
        : setConfirmPasswordError(confirmPasswordError);
      if (password === "") {
        setPasswordError(Constants.FIELD_REQUIRED);
      }
      if (oldPassword === "") {
        setOldPasswordError(Constants.FIELD_REQUIRED);
      }
      return;
    }

    setLoading(true)

    const response = await dispatch(
      changePassword({
        newPassword: password,
        oldPassword: oldPassword,
      })
    );

    if(response.error === undefined){
      handleReset()
      setLoading(false)
      setShowPasswordAlert(true)  
    }else{
      setLoading(false)
      handleReset()
    }     
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    if (oldPassword !== "") {
      setOldPasswordError("");
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setResetPasswordError(
      Validator.validate("resetpass", e.target.value, 7, true)
    );
    if (password !== "") {
      setPasswordError("");
    }
    e.target.value && setChange(true);
    !e.target.value && setChange(false);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError(
      Validator.validate("confirm", e.target.value, password, null, null, true)
    );
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setEdit(true);
  };   

  const onFinish = async (values) => {
    const data = {
      fullName: values?.firstname + " " + values?.lastname,
      firstName:values?.firstname,
      lastName:values?.lastname,
      email: values?.email.toLowerCase(),
    };
    
    const res = await dispatch(editProfile(data, userProfile?._id));
    if(res.error === undefined){
      await dispatch(getProfile());
      setEdit(false);
    }else{
      setShowDeleteAlert(true)
      await dispatch(getProfile());
      setEdit(false);
    }
  };

  const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
    }

  const handlePasswordAlertClose = () => {
        setShowPasswordAlert(false)
    }

    const handleReset = () => {
      setOldPassword('')
      setPassword('')
      setConfirmPassword('')
      setPasswordError('')
      setConfirmPasswordError('')
      setOldPasswordError('')
      setChange(false)
      setResetPasswordError({
          special: false,
          digit: false,
          upper: false,
          lower: false,
          min: false,
        })
    } 

  return (
    <CommonLayout
      breadCrumbText="Profile settings"
      backButton={true}
      backUrl={"/"}
    >
      <div className="profileWholeWrapper">
        <Scrollbars
          style={{ width: "100%", height: "calc(100vh - 141px)" }}
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
        >{ !loadingState ? 
          <div className="card-body">
            {!edit && (
              <div className="label-width">
                <div className="err">
                  <Error showError={true}/>
                  </div>
                <Card>
                  
                  <div className="extra-head">
                    <div className="extra-wrap">
                      <div className="text-extra-basic">
                        <p className="text-basic">Basic details</p>
                      </div>
                      <div className="extra">
                        <span className="text-extra" onClick={handleEdit}>
                          Edit
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="body-styl">
                    <div className="frame">
                      <div className="text-box">
                        <p className="full-name">Full name</p>
                        <p className="full-name-api">{userProfile?.fullName}</p>
                      </div>
                      <div className="text-box">
                        <p className="full-name">Email</p>
                        <p className="full-name-api">{userProfile?.email}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            )}
            {edit && (
              <div className="content-edit">
                <Card className="edit">
                  <div className="extra-head">
                    <div className="extra-wrap">
                      <div className="text-extra-basic">
                        <p className="text-basic">Edit details</p>
                      </div>
                    </div>
                  </div>
                  <div className="body-styl-edit">
                    <div className="frame-edit">
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}                      
                        initialValues={userProfileData}
                        requiredMark={"required"}
                      >
                        <Form.Item
                          className="affix-wrapperin"
                          label="First name"
                          name="firstname"
                          rules={[
                            {
                              validator: (r, value) => {
                                if (value) {
                                  return value?.match(Patterns.NAME_PATTERN)
                                    ? Promise.resolve()
                                    : Promise.reject("Invalid Value");
                                } else {
                                  return Promise.reject("First name is required!");
                                }
                              },
                            },
                          ]}
                          required
                        >
                          <Input
                            classname="affix-wrapperin"
                            placeholder="First name"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Last name"
                          name="lastname"
                          rules={[
                            {
                              validator: (r, value) => {
                                if (value) {
                                  return value?.match(Patterns.NAME_PATTERN)
                                    ? Promise.resolve()
                                    : Promise.reject("Invalid Value");
                                } else {
                                  return Promise.reject("Last name is required!");
                                }
                              },
                            },
                          ]}
                          required
                        >
                          <Input
                            classname="affix-wrapperin"
                            placeholder="Last name"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required.'
                            },
                            {
                              type: "email",
                              message: 'Email is not valid'
                            },
                          ]}
                          required
                        >
                          <Input
                            classname="affix-wrapperin"
                            placeholder="Email"
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Card>
              </div>
            )}
            <div>
              <Card className="change">
                <div className="extra-head">
                  <div className="extra-wrap">
                    <div className="text-extra-change">
                      <p className="text-basic-change">Change password</p>
                    </div>
                  </div>
                </div>
                <div className="body-styl-edit">
                  <div className="frame-edit">
                    <form className="form" onSubmit={handleChangePassword}>
                      <FormControl>
                        <Input.Password
                          className="affix-wrapperin addUserBtnProfile"
                          size="large"
                          placeholder="Old Password"
                          type="password"
                          value={oldPassword}
                          onChange={handleOldPasswordChange}
                          prefix={<LockOutlined />}
                        />
                        <span className="error-label">{oldPasswordError}</span>
                      </FormControl>
                      <FormControl>
                        <Input.Password
                          className="affix-wrapperin addUserBtnProfile"
                          size="large"
                          placeholder="New Password"
                          type="password"
                          value={password}
                          onChange={handlePasswordChange}
                          prefix={<LockOutlined />}
                        />
                        <span className="error-label">{passwordError}</span>
                        {change && (
                          <div className="reset-box">
                            <div className="col-reset">
                              {resetPasswordError.lower ? (
                                <div className="one-reset green">
                                  <CheckCircleFilled />
                                  One lowercase charecter
                                </div>
                              ) : (
                                <div className="one-reset">
                                  <CheckCircleFilled />
                                  One lowercase charecter
                                </div>
                              )}
                              {resetPasswordError.digit ? (
                                <div className="one-reset green">
                                  <CheckCircleFilled />
                                  One number{" "}
                                </div>
                              ) : (
                                <div className="one-reset">
                                  <CheckCircleFilled />
                                  One number{" "}
                                </div>
                              )}
                              {resetPasswordError.min ? (
                                <div className="one-reset green">
                                  <CheckCircleFilled />8 charecters minimum{" "}
                                </div>
                              ) : (
                                <div className="one-reset">
                                  <CheckCircleFilled />8 charecters minimum{" "}
                                </div>
                              )}
                            </div>
                            <div className="col-reset">
                              {resetPasswordError.upper ? (
                                <div className="one-reset green">
                                  <CheckCircleFilled />
                                  One Uppercase charecter
                                </div>
                              ) : (
                                <div className="one-reset">
                                  <CheckCircleFilled />
                                  One Uppercase charecter
                                </div>
                              )}
                              {resetPasswordError.special ? (
                                <div className="one-reset green">
                                  <CheckCircleFilled />
                                  One special charecter{" "}
                                </div>
                              ) : (
                                <div className="one-reset">
                                  <CheckCircleFilled />
                                  One special charecter{" "}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </FormControl>
                      <FormControl>
                        <Input.Password
                          className="affix-wrapperin addUserBtnProfile"
                          size="large"
                          placeholder="Confirm Password"
                          type="password"
                          value={confirmPassword}
                          onChange={handleConfirmPassword}
                          prefix={<LockOutlined />}
                        />
                        <span className="error-label">
                          {confirmPasswordError}
                        </span>
                      </FormControl>

                      <Button
                        type="primary"
                        className="addUserBtnAuth"
                        onClick={handleChangePassword}
                        disabled={loading}>
                          {loading ? `Loading...` : "Set as new password"}
                      </Button>
                    </form>
                  </div>
                </div>
              </Card>
            </div>
            {
                    showDeleteAlert && 
                        <Alert message={TextMessage.PROFILE_ERROR_TEXT} type="error" 
                        showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined/>} className='alertStyle'
                        />
                }

            {
                    showPasswordAlert && 
                        <Alert message={TextMessage.PASSWORD_CHANGED_TEXT} type={'success'}
                        showIcon closable onClose={handlePasswordAlertClose} icon={<DeleteOutlined/>} className='alertStyle'
                        />
                }
          </div>:<div className="errorTipDiv">
              <Spin size='middle' tip='Loading...'/>
            </div>
}
        </Scrollbars>
      </div>
    </CommonLayout>
  );
};

export default Profile;