import { Button, Input } from "antd";
import {
  CheckSquareOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import BottomSection from "./BottomSection";

const CheckBoxQuestion = ({
  question,
  handleDeleteOptionsCheckbox,
  handleOptionTextChangeCheckbox,
  loading,
  isCheckbox,
  handleAddOptionsCheckbox,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  handleQuestionDesignate,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
}) => {
  return (
    <>
      {isCheckbox && question.questionType === "checkbox" && (
        <div className="formBuilderFrameTwoDynamic" key={index}>
          <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
            {question.questionType === "checkbox" &&
              question.optionValue?.map((op, j) => (
                <>
                  <div
                    className={"formBuilderFrameTwoDynamicOptionFrame"}
                    key={j}
                  >
                    <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                      <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                        <Input
                          addonBefore={<CheckSquareOutlined />}
                          placeholder={`Option ${j + 1}`}
                          suffix={
                            <DeleteOutlined
                              className="cp"
                              onClick={() =>
                                handleDeleteOptionsCheckbox(index, j)
                              }
                            />
                          }
                          value={op.optionText}
                          onChange={(e) =>
                            handleOptionTextChangeCheckbox(
                              e.target.value,
                              index,
                              j
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>

          {question.questionType === "checkbox" && (
            <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
              <Button
                icon={
                  <PlusOutlined
                    style={{ marginRight: "-10px", color: "#0F2A6F" }}
                  />
                }
                className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                onClick={() => handleAddOptionsCheckbox(index)}
              >
                Add Option
              </Button>
            </div>
          )}
        </div>
      )}
      <BottomSection
        isSameType={isCheckbox && question.questionType === "checkbox"}
        onCheckBoxChange={(e) => handleQuestionDesignate(e, index)}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
        loading={loading}
        handleSaveQuestion={() => handleSaveQuestion(question, index)}
        copyQuestion={() => copyQuestion(index)}
        deleteQuestion={() => deleteQuestion(index)}
        question={question}
        showError={() => (
          <span className="errorLabel">
            {question?.errors?.optionTextError !== null &&
              question?.errors?.optionTextError}
          </span>
        )}
      />
    </>
  );
};

export default CheckBoxQuestion;
