import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Radio, Select } from "antd";
import CommonLayout from "components/layout/Layout";
import { setPage } from "actions/app";
import {
  questionTypesSelectOption,
  SECONDS,
  TextMessage,
} from "utils/constants";
import { PlusOutlined } from "@ant-design/icons";
import DropDownQuestion from "containers/promoTypes/formbuilder/DropDownQuestion";
import DateQuestion from "containers/promoTypes/formbuilder/DateQuestion";
import MultiSelectQuestion from "containers/promoTypes/formbuilder/MultiSelectQuestion";
import TabQuestion from "containers/promoTypes/formbuilder/TabQuestion";
import ShortAnswerQuestion from "containers/promoTypes/formbuilder/ShortAnswerQuestion";
import TextAreaQuestion from "containers/promoTypes/formbuilder/TextAreaQuestion";
import CheckBoxQuestion from "containers/promoTypes/formbuilder/CheckBoxQuestion";
import MultipleChoiceQuestion from "containers/promoTypes/formbuilder/MultipleChoiceQuestion";
import { createQuestion } from "actions/formBuilder";
import { addQuestionToPormoType } from "actions/promoTypes";
import { getPromoTypeById } from "actions/promoTypes";
import { useSelector } from "react-redux";
import MediaQuestions from "containers/formBuilder/MediaQuestions";
import MediaAnswerQuestions from "./mediaAnswerQuestion";
const Option = Select;
const PromoTypeFormBuilder = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const questionTypeOptionsChildren = [];
  for (let i = 0; i < questionTypesSelectOption.length; i++) {
    questionTypeOptionsChildren.push(
      <Option key={questionTypesSelectOption[i]._id}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: "5px" }}>
            {questionTypesSelectOption[i].icon}
          </span>
          <span>{questionTypesSelectOption[i].value}</span>
        </div>
      </Option>
    );
  }

  useEffect(() => {
    dispatch(setPage("Promo Type Form Edit"));
  }, [dispatch]);
  useEffect(() => {
    const getCall = async () => {
      await dispatch(getPromoTypeById(id));
    };
    getCall();
  }, [id, dispatch]);

  const [questions, setQuestions] = useState([]);
  const [showQuestion, setShowQuestion] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDate, setIsDate] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isMedia, setIsMedia] = useState(false);
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [isShortAnswer, setIsShortAnswer] = useState(false);
  const [isTextArea, setIsTextArea] = useState(false);
  const [isCheckbox, setIsCheckbox] = useState(false);
  const [isMultipleChoice, setIsMultipleChoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddAlert, setShowAddAlert] = useState(false);
  const [addAlertText, setAddAlertText] = useState("");
  const [addAlertType, setAddAlertType] = useState("");
  const promoDetails = useSelector((state) => state.promoTypes.promoTypeById);

  useEffect(() => {
    if (showAddAlert) {
      setTimeout(() => {
        setShowAddAlert(false);
      }, SECONDS);
    }
  }, [showAddAlert]);

  const handleQuestionText = (text, i) => {
    const questionsArr = [...questions];
    questionsArr[i].questionHeader = text;
    setQuestions(questionsArr);
  };

  const handleQuestionDesignate = (e, i) => {
    const questionsArr = [...questions];
    if (e.target.checked === true) {
      questionsArr[i].designate = "required";
      questionsArr[i].optionValue && questionsArr[i].optionValue.forEach((option) => {
        option.subOptions && option.subOptions.forEach((subOption) => {
          subOption.designate = "required";
        })
      })
    } else {
      questionsArr[i].designate = "optional";
      questionsArr[i].optionValue && questionsArr[i].optionValue.forEach((option) => {
        option.subOption && option.subOptions.forEach((subOption) => {
          subOption.designate = "optional";
        })
      })
    }
    setQuestions(questionsArr);
  };
  const handleStandardTeamChange = (e, i) => {
    const questionsArr = [...questions];
    questionsArr[i].standardWorkflowApproverTeamId = e.target.value;
    setQuestions(questionsArr);
  };
  const handleEmergencyTeamChange = (e, i) => {
    const questionsArr = [...questions];
    questionsArr[i].emergencyWorkflowApproverTeamId = e.target.value;
    setQuestions(questionsArr);
  };
  const onRegexCheckboxChange = (e, i, j, k, type) => {
    const questionsArr = [...questions];
    type ?
      questionsArr[i].optionValue[j].subOptions[k].isRegexRequired = e.target.checked :
      questionsArr[i].isRegexRequired = e.target.checked;
    setQuestions(questionsArr);
  };
  const handleRegexChange = (e, i, j, k, type) => {
    const questionsArr = [...questions];
    type ? questionsArr[i].optionValue[j].subOptions[k].regex = e.target.value :
      questionsArr[i].regex = e.target.value;
    setQuestions(questionsArr);
  };
  const handleQuestionType = (value, i) => {
    const questionsArr = [...questions];
    if (value === "dropdown") {
      setIsDropdown(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: {
          questionHeaderError: null,
          optionTextError: null,
          subOptionTextError: null,
          innerSubOptionTextError: null,
          innerTwoSubOptionTextError: null,
          subOptionTypeError: null,
        },
      };
      setQuestions(questionsArr);
    }

    if (value === "date") {
      setIsDate(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null },
      };
      setQuestions(questionsArr);
    }
    if (value === "media") {
      setIsMedia(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null },
      };
      setQuestions(questionsArr);
    }
    if (value === "tabs") {
      setIsTab(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: {
          questionHeaderError: null,
          optionTextError: null,
          subOptionTextError: null,
          subOptionTypeError: null,
        },
      };
      setQuestions(questionsArr);
    }
    if (value === "multiSelect") {
      setIsMultiSelect(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null, optionTextError: null },
      };
      setQuestions(questionsArr);
    }
    if (value === "input") {
      setIsShortAnswer(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null },
      };
      setQuestions(questionsArr);
    }
    if (value === "textarea") {
      setIsTextArea(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null },
      };
      setQuestions(questionsArr);
    }
    if (value === "checkbox") {
      setIsCheckbox(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null, optionTextError: null },
      };
      setQuestions(questionsArr);
    }
    if (value === "radio") {
      setIsMultipleChoice(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: {
          questionHeaderError: null,
          optionTextError: null,
          subOptionTextError: null,
          innerSubOptionTextError: null,
          innerTwoSubOptionTextError: null,
          subOptionTypeError: null,
        },
      };
      setQuestions(questionsArr);
    }
  };
  const handleShowQuestion = () => {
    setShowQuestion(true);
    const newQuestion = {
      questionHeader: "",
      questionType: null,
      designate: "optional",
      optionValue: [{ optionText: "" }],
    };
    setQuestions([...questions, newQuestion]);
  };

  const deleteQuestion = (i) => {
    const questionsArr = [...questions];

    questionsArr.splice(i, 1);
    setQuestions(questionsArr);
  };

  const copyQuestion = (i) => {
    const questionsArr = [...questions];

    const nq = { ...questionsArr[i] };
    setQuestions([...questionsArr, nq]);
  };

  const handleAddOptions = (i) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.push({ optionText: "" });
    console.log(questionsArr, "questionsArr");
    setQuestions(questionsArr);
  };
  const handleFormBuilderSelectMasterOption = (i, value) => {
    const questionsArr = [...questions];

    questionsArr[i] = {
      ...questionsArr[i],
      isMaster: true,
      masterDataType: value,
    };
    console.log(questionsArr, "questionsArr");
    delete questionsArr[i].optionValue;
    setQuestions(questionsArr);
  };

  const handleFormBuilderSelectMasterOptionSub = (value, i, j, k, l) => {
    const questionsArr = [...questions];
    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j],
      isMaster: true,
      masterDataType: value,
    };
    console.log(
      questionsArr[i].optionValue[j],
      "questionsArr[i].optionValue"
    );
    // delete questionsArr[i].optionValue[j].subOptions[k];
    setQuestions(questionsArr);
  };



  const handleDeleteOptions = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.splice(j, 1);
    setQuestions(questionsArr);
  };

  const handleAddSubOptions = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j] = {
      ...questionsArr[i].optionValue[j],
      subOptions: [{ subOptionText: "", subOptionType: null, isRegexRequired: false, }],
    };

    setQuestions(questionsArr);
  };

  const handleDeleteSubOptions = (i, j, k) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions.splice(k, 1);
    setQuestions(questionsArr);
  };

  const handleAddMoreSubOptions = (i, j, k) => {
    const questionsArr = [...questions];
    questionsArr[i].optionValue[j].subOptions.push({
      subOptionText: "",
      subOptionType: null,
      isRegexRequired: false,
    });
    setQuestions(questionsArr);
  };

  const handleOptionTextChange = (text, i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].optionText = text;

    setQuestions(questionsArr);
  };

  const handleSuboptionTextChange = (text, i, j, k) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      subOptionText: text,
    };

    setQuestions(questionsArr);
  };
  const handleSubOptionTypeChange = (value, i, j, k) => {
    const questionsArr = [...questions];
    if (value === "input" || value === "textarea" || value === "date") {
      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptionType: value,
      };

      delete questionsArr[i].optionValue[j].subOptions[k].subOptions;
      delete questionsArr[i].optionValue[j].subOptions[k].isMaster;
      delete questionsArr[i].optionValue[j].subOptions[k].masterDataType;

      setQuestions(questionsArr);
    } else if (value === "dropdown") {
      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptionType: value,
      };

      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptions: [{ subOptionText: "" }],
      };

      setQuestions(questionsArr);
    } else {
      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptionType: value,
      };

      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptions: [{ subOptionText: "" }],
      };
      delete questionsArr[i].optionValue[j].subOptions[k].isMaster;
      delete questionsArr[i].optionValue[j].subOptions[k].masterDataType;
      setQuestions(questionsArr);
    }
  };
  const handleAddMoreInnerSubOptions = (i, j, k, l) => {
    console.log(i, j, k, l, "suboption");
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j]?.subOptions[k]?.subOptions?.push({
      subOptionText: "",
    });
    setQuestions(questionsArr);
  };
  const handleFormBuilderSelectMasterSubOption = (i, j, k, value) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      isMaster: true,
      masterDataType: value,
    };

    delete questionsArr[i].optionValue[j].subOptions[k].subOptions;
    setQuestions(questionsArr);
  };

  const handleAddInnerTwoSubOptions = (i, j, k, l) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l],
      subOptions: [{ subOptionText: "", subOptionType: null }],
    };
    setQuestions(questionsArr);
  };

  const handleDeleteInnerSubOptions = (i, j, k, l) => {
    const questionsArr = [...questions];
    questionsArr[i].optionValue[j].subOptions[k].subOptions.splice(l, 1);

    setQuestions(questionsArr);
  };

  const handleDeleteInnerTwoSubOptions = (i, j, k, l, m) => {
    const questionsArr = [...questions];
    questionsArr[i].optionValue[j].subOptions[k].subOptions[
      l
    ].subOptions.splice(m, 1);

    setQuestions(questionsArr);
  };

  const handleInnerSubOptionTextChange = (text, i, j, k, l) => {
    const questionsArr = [...questions];
    console.log(text, i, j, k, l, "rtecxt");
    questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l],
      subOptionText: text,
    };

    setQuestions(questionsArr);
  };
  const handleInnerTwoSubOptionText = (text, i, j, k, l, m) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[
      m
      ],
      subOptionText: text,
    };

    setQuestions(questionsArr);
  };

  const handleInnerTwoSubOptionType = (value, i, j, k, l, m) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[
      m
      ],
      subOptionType: value,
    };

    setQuestions(questionsArr);
  };

  // functions for tabs

  const handleAddSubOptionsTab = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j] = {
      ...questionsArr[i].optionValue[j],
      subOptions: [{ subOptionText: "", subOptionType: null }],
    };

    setQuestions(questionsArr);
  };

  const handleAddOptionsTab = (i) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setQuestions(questionsArr);
  };

  const handleDeleteOptionsTab = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.splice(j, 1);
    setQuestions(questionsArr);
  };

  const handleOptionTextChangeTab = (text, i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].optionText = text;

    setQuestions(questionsArr);
  };

  const handleSubOptionTypeChangeTab = (value, i, j, k) => {
    const questionsArr = [...questions];
    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      subOptionType: value,
    };
    setQuestions(questionsArr);
  };

  const handleDeleteSubOptionsTab = (i, j, k) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions.splice(k, 1);
    setQuestions(questionsArr);
  };
  const handleSuboptionTextChangeTab = (text, i, j, k) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      subOptionText: text,
    };

    setQuestions(questionsArr);
  };

  //functions for short answer
  const handleAddOptionsShortAnswer = (i) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setQuestions(questionsArr);
  };

  const handleDeleteOptionsShortAnswer = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.splice(j, 1);
    setQuestions(questionsArr);
  };

  //functions for textarea
  const handleAddOptionsTextArea = (i) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setQuestions(questionsArr);
  };

  const handleDeleteOptionsTextArea = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.splice(j, 1);
    setQuestions(questionsArr);
  };

  const handleOptionTextChangeTextArea = (text, i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].optionText = text;

    setQuestions(questionsArr);
  };

  //function for checkbox
  const handleAddOptionsCheckbox = (i) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setQuestions(questionsArr);
  };

  const handleDeleteOptionsCheckbox = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue.splice(j, 1);
    setQuestions(questionsArr);
  };

  const handleOptionTextChangeCheckbox = (text, i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j].optionText = text;

    setQuestions(questionsArr);
  };

  const handleSaveQuestion = async (question, index) => {
    if (question.questionHeader === "") {
      const qArr = [...questions];
      qArr[index].errors.questionHeaderError = "This Field is Required";
      setQuestions(qArr);
      return;
    } else {
      const qArr = [...questions];
      qArr[index].errors.questionHeaderError = null;
      setQuestions(qArr);
    }
    if (!question.standardWorkflowApproverTeamId) {
      const qArr = [...questions];
      qArr[index].errors.standardTeamError = "This Field is Required";
      setQuestions(qArr);
      return;
    } else {
      const qArr = [...questions];
      qArr[index].errors.standardTeamError = null;
      setQuestions(qArr);
    }
    if (!question.emergencyWorkflowApproverTeamId) {
      const qArr = [...questions];
      qArr[index].errors.emergencyTeamError = "This Field is Required";
      setQuestions(qArr);
      return;
    } else {
      const qArr = [...questions];
      qArr[index].errors.emergencyTeamError = null;
      setQuestions(qArr);
    }

    if (
      !question.isMaster &&
      (question.questionType === "checkbox" ||
        question.questionType === "dropdown" ||
        question.questionType === "radio" ||
        question.questionType === "tabs" ||
        question.questionType === "multiSelect") &&
      question.optionValue.length === 0
    ) {
      const qArr = [...questions];
      qArr[index].errors.optionTextError = "Please add at least one option";
      setQuestions(qArr);
      return;
    } else {
      const qArr = [...questions];
      qArr[index].errors.optionTextError = null;
      setQuestions(qArr);
    }

    if (
      question.questionType === "dropdown" ||
      question.questionType === "radio" ||
      question.questionType === "checkbox" ||
      question.questionType === "tabs" ||
      question.questionType === "multiSelect"
    ) {
      if (!question.isMaster) {
        const isEmptySubOpt = question.optionValue.some(
          (item) => item.optionText === ""
        );
        if (isEmptySubOpt) {
          const qArr = [...questions];
          qArr[index].errors.optionTextError = "Please add option value";
          setQuestions(qArr);
          return;
        } else {
          const qArr = [...questions];
          qArr[index].errors.optionTextError = null;
          setQuestions(qArr);
        }
      }
    }

    if (
      question.questionType === "dropdown" ||
      question.questionType === "radio" ||
      question.questionType === "tabs"
    ) {
      if (!question.isMaster) {
        for (let i = 0; i < question.optionValue.length; i++) {
          const element = question.optionValue[i];
          const isElementHasEmptySubOpt =
            element.subOptions &&
            element.subOptions.some((sop) => sop.subOptionText === "");
          if (isElementHasEmptySubOpt) {
            const qArr = [...questions];

            qArr[index].errors.subOptionTextError = "This Field is Required";
            setQuestions(qArr);
            return;
          } else {
            const qArr = [...questions];
            qArr[index].errors.subOptionTextError = null;
            setQuestions(qArr);
          }

          const isElementHasEmptySubOptType =
            element.subOptions &&
            element.subOptions.some((sop) => sop.subOptionType === null);
          if (isElementHasEmptySubOptType) {
            const qArr = [...questions];
            qArr[index].errors.subOptionTypeError = "This Field is Required";
            setQuestions(qArr);
            return;
          } else {
            const qArr = [...questions];
            qArr[index].errors.subOptionTypeError = null;
            setQuestions(qArr);
          }
        }
      }
    }

    if (
      question.questionType === "dropdown" ||
      question.questionType === "radio"
    ) {
      if (!question.isMaster) {
        for (let i = 0; i < question.optionValue.length; i++) {
          const element = question.optionValue[i];
          if (element.subOptions) {
            for (let j = 0; j < element?.subOptions.length; j++) {
              const subOptionsElement = element.subOptions[j];
              if (!subOptionsElement.isMaster) {
                const isSubOptionsElementHasEmptySubOpt =
                  subOptionsElement.subOptions &&
                  subOptionsElement.subOptions.some(
                    (sop) => sop.subOptionText === ""
                  );
                if (isSubOptionsElementHasEmptySubOpt) {
                  const qArr = [...questions];
                  qArr[index].errors.innerTwoSubOptionTextError =
                    "This Field is Required";
                  setQuestions(qArr);
                  return;
                } else {
                  const qArr = [...questions];
                  qArr[index].errors.innerTwoSubOptionTextError = null;
                  setQuestions(qArr);
                }
              }
            }
          }
        }
      }
    }

    setLoading(true);

    let newRecord = {
      ...question,
      isPromoTypeQuestion: true,
      isRegexRequired: question.isRegexRequired && question.questionType === "input",
    };

    delete newRecord.errors;

    const response = await dispatch(createQuestion(newRecord));
    if (response.error === undefined) {
      const questionsArr = [...questions];

      questionsArr.splice(index, 1);
      setQuestions(questionsArr);
      const qData = [
        {
          standardWorkflowApproverTeamId:
            newRecord.standardWorkflowApproverTeamId,
          emergencyWorkflowApproverTeamId:
            newRecord.emergencyWorkflowApproverTeamId,
          questionId: response.payload.data.data._id,
          isRegexRequired: newRecord.isRegexRequired,
          regex: newRecord.regex,
        },
      ];

      const response1 = await dispatch(addQuestionToPormoType(qData, id));

      if (response1.error === undefined) {
        setLoading(false);
        setShowAddAlert(true);
        setAddAlertText(TextMessage.QUESTION_CREATE_TEXT);
        setAddAlertType("success");
      } else {
        setLoading(false);
        setShowAddAlert(true);
        setAddAlertText(TextMessage.ERROR_TEXT);
        setAddAlertType("error");
      }
    } else {
      setLoading(false);
      setShowAddAlert(true);
      setAddAlertText(TextMessage.ERROR_TEXT);
      setAddAlertType("error");
    }
  };

  const handleAddAlertClose = () => {
    setShowAddAlert(false);
  };

  const handleRouteChange = (e) => {
    e.preventDefault();
    navigate(e.target.value);
  };

  return (
    <CommonLayout
      breadCrumbText="Promo Type Form"
      backButton={true}
      backUrl={"/promo-types"}
    >
      <div className="formBuilderBody">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "600px",
          }}
        >
          <div className="input-frame">
            <label className="input-label" style={{ fontWeight: 500 }}>
              Promo Type Name
            </label>
            {promoDetails?.promoType}
          </div>
          <div>
            <label className="input-label" style={{ fontWeight: 500 }}>
              Standard Workflow
            </label>
            {promoDetails?.standardWorkflow?.name}
          </div>
          <div>
            <label className="input-label" style={{ fontWeight: 500 }}>
              Emergency Workflow
            </label>
            {promoDetails?.emergencyWorkflow?.name}
          </div>
        </div>
        <div className="formBuilderFrameWrapper">
          <div className="formBuilderContentWrapper">
            <div className="formBuilderFrameOne">
              <div className="formBuilderFrameOneLeft">
                <Radio.Group
                  value={`/promo-types/form-builder/${id}`}
                  onChange={handleRouteChange}
                >
                  <Radio.Button
                    value={`/promo-types/form-builder/${id}`}
                    className="fomrBuilderEditBtn"
                    style={{
                      background: "#0F2A6F",
                      borderColor: "#0F2A6F",
                      color: "white",
                    }}
                  >
                    Editing
                  </Radio.Button>
                  <Radio.Button value={`/promo-types/form-builder/view/${id}`}>
                    Viewing
                  </Radio.Button>
                  <Radio.Button
                    value={`/promo-types/form-builder/publish/${id}`}
                  >
                    Published
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>

            {showQuestion &&
              questions?.map((question, i) => (
                <div className="formBuilderFrameTwo" key={i}>
                  <div className={"formBuilderFrameTwoActiveDeck"}>
                    <div className="formBuilderFrameTwoPromoForm">
                      <div className="formBuilderFrameTwoPromoFormFrame">
                        <div className="formBuilderFrameTwoPromoQuestion">
                          <label className="formBuilderFrameTwoPromoLabel">
                            Question {i + 1}
                          </label>
                          <input
                            type="text"
                            className="formBuilderFrameTwoPromoQuestionInput"
                            placeholder="Write Here"
                            value={question.questionHeader}
                            onChange={(e) =>
                              handleQuestionText(e.target.value, i)
                            }
                          />
                          <span className="errorLabel">
                            {question?.errors?.questionHeaderError}
                          </span>
                        </div>

                        <div className="formBuilderFrameTwoPromoType">
                          <label className="formBuilderFrameTwoPromoLabelType">
                            Type
                          </label>
                          <div className="formBuilderFrameTwoPromoTypeSelect">
                            <Select
                              className="formBuilderFrameTwoPromoTypeSelectBtn"
                              value={question.questionType}
                              placeholder="Select Question Type"
                              onChange={(value) => handleQuestionType(value, i)}
                            >
                              {questionTypeOptionsChildren}
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isDropdown && question.questionType === "dropdown" && (
                      <DropDownQuestion
                        question={question}
                        isDropdown={isDropdown}
                        index={i}
                        questionTypeOptionsChildren={
                          questionTypeOptionsChildren
                        }
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleSubOptionTypeChange={handleSubOptionTypeChange}
                        handleDeleteSubOptions={handleDeleteSubOptions}
                        handleSuboptionTextChange={handleSuboptionTextChange}
                        handleInnerSubOptionTextChange={
                          handleInnerSubOptionTextChange
                        }
                        handleAddInnerTwoSubOptions={
                          handleAddInnerTwoSubOptions
                        }
                        handleDeleteInnerSubOptions={
                          handleDeleteInnerSubOptions
                        }
                        handleInnerTwoSubOptionText={
                          handleInnerTwoSubOptionText
                        }
                        handleInnerTwoSubOptionType={
                          handleInnerTwoSubOptionType
                        }
                        handleDeleteInnerTwoSubOptions={
                          handleDeleteInnerTwoSubOptions
                        }
                        handleAddMoreInnerSubOptions={
                          handleAddMoreInnerSubOptions
                        }
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        handleAddOptions={handleAddOptions}
                        deleteQuestion={deleteQuestion}
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleFormBuilderSelectMasterSubOption={
                          handleFormBuilderSelectMasterSubOption
                        }
                        handleFormBuilderSelectMasterOption={
                          handleFormBuilderSelectMasterOption
                        }
                        handleFormBuilderSelectMasterOptionSub={
                          handleFormBuilderSelectMasterOptionSub
                        }
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                        onRegexCheckboxChange={onRegexCheckboxChange}
                        handleRegexChange={handleRegexChange}
                      />
                    )}

                    {isDate && question.questionType === "date" && (
                      <DateQuestion
                        showQuestion={showQuestion}
                        isDate={isDate}
                        question={question}
                        index={i}
                        deleteQuestion={deleteQuestion}
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                      />
                    )}
                    {isMultiSelect &&
                      question.questionType === "multiSelect" && (
                        <MultiSelectQuestion
                          question={question}
                          isMultiSelect={isMultiSelect}
                          index={i}
                          deleteQuestion={deleteQuestion}
                          copyQuestion={copyQuestion}
                          handleSaveQuestion={handleSaveQuestion}
                          loading={loading}
                          handleOptionTextChange={handleOptionTextChange}
                          handleAddOptions={handleAddOptions}
                          handleFormBuilderSelectMasterOption={
                            handleFormBuilderSelectMasterOption
                          }
                          handleDeleteOptions={handleDeleteOptions}
                          handleQuestionDesignate={handleQuestionDesignate}
                          handleStandardTeamChange={handleStandardTeamChange}
                          handleEmergencyTeamChange={handleEmergencyTeamChange}
                          onRegexCheckboxChange={onRegexCheckboxChange}
                          handleRegexChange={handleRegexChange}
                        />
                      )}

                    {isTab && (
                      <TabQuestion
                        showQuestion={showQuestion}
                        isTab={isTab}
                        question={question}
                        questionTypeOptionsChildren={
                          questionTypeOptionsChildren
                        }
                        handleAddSubOptionsTab={handleAddSubOptionsTab}
                        handleAddOptionsTab={handleAddOptionsTab}
                        handleDeleteOptionsTab={handleDeleteOptionsTab}
                        handleOptionTextChangeTab={handleOptionTextChangeTab}
                        handleSubOptionTypeChangeTab={
                          handleSubOptionTypeChange
                        }
                        handleDeleteSubOptionsTab={handleDeleteSubOptionsTab}
                        handleSuboptionTextChangeTab={
                          handleSuboptionTextChangeTab
                        }
                        index={i}
                        deleteQuestion={deleteQuestion}
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteOptions={handleDeleteOptions}
                        handleInnerSubOptionTextChange={
                          handleInnerSubOptionTextChange
                        }
                        handleAddInnerTwoSubOptions={
                          handleAddInnerTwoSubOptions
                        }
                        handleDeleteInnerSubOptions={
                          handleDeleteInnerSubOptions
                        }
                        handleInnerTwoSubOptionText={
                          handleInnerTwoSubOptionText
                        }
                        handleInnerTwoSubOptionType={
                          handleInnerTwoSubOptionType
                        }
                        handleDeleteInnerTwoSubOptions={
                          handleDeleteInnerTwoSubOptions
                        }
                        handleAddMoreInnerSubOptions={
                          handleAddMoreInnerSubOptions
                        }
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        handleAddOptions={handleAddOptions}
                        handleFormBuilderSelectMasterSubOption={
                          handleFormBuilderSelectMasterSubOption
                        }
                        handleFormBuilderSelectMasterOption={
                          handleFormBuilderSelectMasterOption
                        }
                        onRegexCheckboxChange={onRegexCheckboxChange}
                        handleRegexChange={handleRegexChange}
                      />
                    )}

                    {isMedia && (
                      <MediaAnswerQuestions
                        showQuestion={showQuestion}
                        isMedia={isMedia}
                        question={question}
                        handleAddOptionsShortAnswer={
                          handleAddOptionsShortAnswer
                        }
                        handleDeleteOptionsShortAnswer={
                          handleDeleteOptionsShortAnswer
                        }
                        index={i}
                        deleteQuestion={deleteQuestion}
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                        onRegexCheckboxChange={onRegexCheckboxChange}
                        handleRegexChange={handleRegexChange}
                      />
                    )}
                    {isShortAnswer && (
                      <ShortAnswerQuestion
                        showQuestion={showQuestion}
                        isShortAnswer={isShortAnswer}
                        question={question}
                        handleAddOptionsShortAnswer={
                          handleAddOptionsShortAnswer
                        }
                        handleDeleteOptionsShortAnswer={
                          handleDeleteOptionsShortAnswer
                        }
                        index={i}
                        deleteQuestion={deleteQuestion}
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                        onRegexCheckboxChange={onRegexCheckboxChange}
                        handleRegexChange={handleRegexChange}
                      />
                    )}

                    {isTextArea && (
                      <TextAreaQuestion
                        showQuestion={showQuestion}
                        isTextArea={isTextArea}
                        question={question}
                        handleAddOptionsTextArea={handleAddOptionsTextArea}
                        handleDeleteOptionsTextArea={
                          handleDeleteOptionsTextArea
                        }
                        handleOptionTextChangeTextArea={
                          handleOptionTextChangeTextArea
                        }
                        index={i}
                        deleteQuestion={deleteQuestion}
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                      />
                    )}

                    {isCheckbox && (
                      <CheckBoxQuestion
                        showQuestion={showQuestion}
                        isCheckbox={isCheckbox}
                        question={question}
                        handleDeleteOptionsCheckbox={
                          handleDeleteOptionsCheckbox
                        }
                        handleOptionTextChangeCheckbox={
                          handleOptionTextChangeCheckbox
                        }
                        handleAddOptionsCheckbox={handleAddOptionsCheckbox}
                        index={i}
                        deleteQuestion={deleteQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        copyQuestion={copyQuestion}
                        loading={loading}
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                      />
                    )}

                    {isMultipleChoice && (
                      <MultipleChoiceQuestion
                        deleteQuestion={deleteQuestion}
                        showQuestion={showQuestion}
                        isMultipleChoice={isMultipleChoice}
                        question={question}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionTextChange={handleOptionTextChange}
                        handleAddOptions={handleAddOptions}
                        index={i}
                        handleSubOptionTypeChange={handleSubOptionTypeChange}
                        handleAddSubOptions={handleAddSubOptions}
                        handleDeleteSubOptions={handleDeleteSubOptions}
                        handleSuboptionTextChange={handleSuboptionTextChange}
                        handleInnerSubOptionTextChange={
                          handleInnerSubOptionTextChange
                        }
                        handleAddInnerTwoSubOptions={
                          handleAddInnerTwoSubOptions
                        }
                        handleDeleteInnerSubOptions={
                          handleDeleteInnerSubOptions
                        }
                        handleInnerTwoSubOptionText={
                          handleInnerTwoSubOptionText
                        }
                        handleInnerTwoSubOptionType={
                          handleInnerTwoSubOptionType
                        }
                        handleDeleteInnerTwoSubOptions={
                          handleDeleteInnerTwoSubOptions
                        }
                        handleAddMoreInnerSubOptions={
                          handleAddMoreInnerSubOptions
                        }
                        handleAddMoreSubOptions={handleAddMoreSubOptions}
                        questionTypeOptionsChildren={
                          questionTypeOptionsChildren
                        }
                        copyQuestion={copyQuestion}
                        handleSaveQuestion={handleSaveQuestion}
                        loading={loading}
                        handleFormBuilderSelectMasterSubOption={
                          handleFormBuilderSelectMasterSubOption
                        }
                        handleFormBuilderSelectMasterOption={
                          handleFormBuilderSelectMasterOption
                        }
                        handleQuestionDesignate={handleQuestionDesignate}
                        handleStandardTeamChange={handleStandardTeamChange}
                        handleEmergencyTeamChange={handleEmergencyTeamChange}
                        onRegexCheckboxChange={onRegexCheckboxChange}
                        handleRegexChange={handleRegexChange}
                      />
                    )}
                  </div>
                </div>
              ))}

            {
              <div className="formBuilderFrameThree">
                <div className="formBuilderFrameThreeDividerWrapper">
                  <div className="formBuilderFrameThreeDividerOne">
                    <div className="formBuilderFrameThreeLineOne"></div>
                  </div>

                  <div className="formBuilderFrameThreeDividerBtnDiv">
                    <Button
                      icon={<PlusOutlined />}
                      className="formBuilderFrameThreeDividerBtn"
                      onClick={handleShowQuestion}
                    >
                      Add a Question
                    </Button>
                  </div>

                  <div className="formBuilderFrameThreeDividerTwo">
                    <div className="formBuilderFrameThreeLineTwo"></div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>

        {showAddAlert && (
          <Alert
            message={addAlertText}
            type={addAlertType}
            showIcon
            closable
            onClose={handleAddAlertClose}
            className="alertStyle"
          />
        )}
      </div>
    </CommonLayout>
  );
};

export default PromoTypeFormBuilder;
