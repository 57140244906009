import logger from "common/logger";
import { HttpService } from "services/network/http.service";

export default class MediaService extends HttpService {
  async uploadMedia(data:any) {
    try {
      const response = await this.post(`/api/media/`, data);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
  async getMedia(id:any) {
    try {
      const response = await this.get(`/api/media/${id}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
}
