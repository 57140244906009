import { Button, Input, Select, Checkbox } from "antd";
import {
  DeleteOutlined,
  DownCircleOutlined,
  LineOutlined,
  PlusOutlined,
  SisternodeOutlined,
} from "@ant-design/icons";
import {
  RadioTypeIcon,
  CircleTypeIcon,
  showQuestionError,
} from "utils/constants";
import BottomSection from "./BottomSection"
const Option = Select;

const MultipleChoiceQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleDeleteOptions,
  handleOptionTextChange,
  handleAddOptions,
  index,
  handleSubOptionTypeChange,
  handleAddSubOptions,
  handleDeleteSubOptions,
  handleSuboptionTextChange,
  handleInnerSubOptionTextChange,
  handleDeleteInnerSubOptions,
  handleAddMoreInnerSubOptions,
  handleAddMoreSubOptions,
  handleSaveQuestion,
  handleFormBuilderSelectMasterSubOption,
  handleQuestionDesignate,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
  onRegexCheckboxChange,
  handleRegexChange,
}) => {
  return (
    <>
      {question.questionType === "radio" && (
        <div className="formBuilderFrameTwoDynamic">
          <>
            <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
              {question.questionType === "radio" &&
                question.optionValue?.map((op, j) => (
                  <>
                    <div
                      className="formBuilderFrameTwoDynamicOptionFrame"
                      key={j}
                    >
                      <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                        <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                          <Input
                            addonBefore={<RadioTypeIcon />}
                            placeholder={`Option ${j + 1}`}
                            addonAfter={
                              <SisternodeOutlined
                                onClick={() => handleAddSubOptions(index, j)}
                              />
                            }
                            suffix={
                              <DeleteOutlined
                                className="cp"
                                onClick={() => handleDeleteOptions(index, j)}
                              />
                            }
                            value={op.optionText}
                            onChange={(e) =>
                              handleOptionTextChange(e.target.value, index, j)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                      {op?.subOptions?.map((sOp, k) => (
                        <>
                          <div
                            className="formBuilderFrameTwoDynamicSubOptionFrame"
                            key={k}
                          >
                            <div className="formBuilderFrameTwoDynamicSubOptionValueFrame">
                              <div className="formBuilderFrameTwoDynamicSubOptionValueGroup">
                                <div className="formBuilderFrameTwoDynamicSubOptionValueGroupFrame">
                                  <div className="formBuilderFrameTwoDynamicSubOptionValueGroupFirstChildWrapper">
                                    <Input
                                      placeholder="Label"
                                      addonAfter={
                                        <Select
                                          placeholder="Select"
                                          value={sOp.subOptionType}
                                          onChange={(value) =>
                                            handleSubOptionTypeChange(
                                              value,
                                              index,
                                              j,
                                              k
                                            )
                                          }
                                        >
                                          <Option value={"input"}>
                                            <LineOutlined /> Short Answer
                                          </Option>
                                          <Option value={"dropdown"}>
                                            <DownCircleOutlined /> Dropdown
                                          </Option>
                                        </Select>
                                      }
                                      suffix={
                                        <DeleteOutlined
                                          className="cp"
                                          onClick={() =>
                                            handleDeleteSubOptions(index, j, k)
                                          }
                                        />
                                      }
                                      value={sOp.subOptionText}
                                      onChange={(e) =>
                                        handleSuboptionTextChange(
                                          e.target.value,
                                          index,
                                          j,
                                          k
                                        )
                                      }
                                    />
                                  </div>

                                  <div>
                                    {sOp?.subOptions?.map((inSop, l) => (
                                      <>
                                        <div
                                          className="formBuilderFrameTwoDynamicSubOptionValueGroupSubFrame"
                                          key={l}
                                        >
                                          <Input
                                            addonBefore={
                                              inSop.subOptionType ===
                                                "radio" ? (
                                                <CircleTypeIcon />
                                              ) : (
                                                <DownCircleOutlined />
                                              )
                                            }
                                            value={inSop.subOptionText}
                                            onChange={(e) =>
                                              handleInnerSubOptionTextChange(
                                                e.target.value,
                                                index,
                                                j,
                                                k,
                                                l
                                              )
                                            }
                                            suffix={
                                              <DeleteOutlined
                                                className="cp"
                                                onClick={() =>
                                                  handleDeleteInnerSubOptions(
                                                    index,
                                                    j,
                                                    k,
                                                    l
                                                  )
                                                }
                                              />
                                            }
                                          />
                                        </div>
                                      </>
                                    ))}
                                    {
                                      sOp.subOptionType === "input" && (
                                        <div key={index}>
                                          <Checkbox onChange={(e) => onRegexCheckboxChange(e, index, j, k, "multiChoice")} checked={sOp.isRegexRequired && sOp?.isRegexRequired === true ? true : false}>Regex</Checkbox>
                                          {sOp?.isRegexRequired && (
                                            <div className="formBuilderFrameTwoPromoQuestion" key={index}>
                                              <label className="formBuilderFrameTwoPromoLabel">
                                                Conditions
                                              </label>
                                              <input
                                                type="text"
                                                className="formBuilderFrameTwoPromoQuestionInput"
                                                placeholder="Write Here"
                                                value={sOp.regex}
                                                onChange={(e) => handleRegexChange(e, index, j, k, "multiChoice")}
                                              />
                                            </div>
                                          )}
                                        </div>
                                      )
                                    }
                                    {
                                      <div className="formBuilderFrameTwoDynamicSubOptionValueAddFrame">
                                        {sOp.subOptionType === "radio" &&
                                          !sOp.isMaster && (
                                            <Button
                                              icon={
                                                <PlusOutlined
                                                  style={{
                                                    marginRight: "-10px",
                                                    color: "#0F2A6F",
                                                  }}
                                                />
                                              }
                                              className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                                              onClick={() =>
                                                handleAddMoreInnerSubOptions(
                                                  index,
                                                  j,
                                                  k
                                                )
                                              }
                                            >
                                              Add Option
                                            </Button>
                                          )}
                                        {sOp.subOptionType === "dropdown" &&
                                          !sOp.isMaster && (
                                            <Button
                                              icon={
                                                <PlusOutlined
                                                  style={{
                                                    marginRight: "-10px",
                                                    color: "#0F2A6F",
                                                  }}
                                                />
                                              }
                                              className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                                              onClick={() =>
                                                handleAddMoreInnerSubOptions(
                                                  index,
                                                  j,
                                                  k
                                                )
                                              }
                                            >
                                              Add Option
                                            </Button>
                                          )}

                                        {sOp.subOptionType === "dropdown" && (
                                          <div className="fomBuilderSelectStyle">
                                            <Select
                                              placeholder="Select Master"
                                              value={sOp?.masterDataType}
                                              onChange={(value) =>
                                                handleFormBuilderSelectMasterSubOption(
                                                  index,
                                                  j,
                                                  k,
                                                  value
                                                )
                                              }
                                            >
                                              <Option value={"shelf"}>
                                                Shelf
                                              </Option>
                                              {/* <Option value={"sku"}>SKU</Option> */}
                                              <Option value={"promo"}>
                                                Promo
                                              </Option>
                                              <Option value={"user"}>
                                                Users
                                              </Option>
                                              <Option value={"brand"}>
                                                Brands
                                              </Option>
                                              {/* <Option value={"department"}>Departments</Option>
                                              <Option value={"class"}>Class</Option>
                                              <Option value={"subclass"}>Subclass</Option> */}
                                            </Select>
                                          </div>
                                        )}
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      {op?.subOptions?.length > 0 && (
                        <div className="formBuilderFrameTwoDynamicSubOptionValueAddFrame">
                          <Button
                            icon={
                              <PlusOutlined
                                style={{
                                  marginRight: "-10px",
                                  color: "#0F2A6F",
                                }}
                              />
                            }
                            className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                            onClick={() => handleAddMoreSubOptions(index, j)}
                          >
                            Add Option
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                ))}
            </div>

            {question.questionType === "radio" && (
              <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
                {!question.isMaster && (
                  <Button
                    icon={
                      <PlusOutlined
                        style={{ marginRight: "-10px", color: "#0F2A6F" }}
                      />
                    }
                    className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                    onClick={() => handleAddOptions(index)}
                  >
                    Add Option
                  </Button>
                )}
              </div>
            )}
          </>
        </div>
      )}
      <BottomSection
        isSameType={question.questionType === "radio"}
        onCheckboxChange={(e) => handleQuestionDesignate(e, index)}
        onSaveClick={() => handleSaveQuestion(question, index)}
        loading={loading}
        handleCancelEditView={handleCancelEditView}
        question={question}
        showError={() => showQuestionError(question)}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}

      />
    </>
  );
};

export default MultipleChoiceQuestionView;
