export const UPDATE_LOCAL_PROMO_TYPES_JSON = "UPDATE_LOCAL_PROMO_TYPES_JSON";

export const GET_ALL_PROMO_TYPES = "GET_ALL_PROMO_TYPES";
export const CREATE_PROMO_TYPES = "CREATE_PROMO_TYPES";
export const DELETE_PROMO_TYPES = "DELETE_PROMO_TYPES";
export const UPDATE_PROMO_TYPES = "UPDATE_PROMO_TYPES";
export const UPDATE_PROMO_TYPE_STATUS = "UPDATE_PROMO_TYPE_STATUS";
export const UPDATE_PROMO_TYPE_BY_NAME = "UPDATE_PROMO_TYPE_BY_NAME";

export const ADD_QUESTION_PROMOTYPE = "ADD_QUESTION_PROMOTYPE";
export const REMOVE_QUESTION_PUBLISHED = "REMOVE_QUESTION_PUBLISHED";
export const MARK_PUBLISH = "MARK_PUBLISH";
export const LIST_QUESTION_PUBLISHED = "LIST_QUESTION_PUBLISHED";
export const LIST_QUESTION_UNPUBLISHED = "LIST_QUESTION_UNPUBLISHED";
export const DELETE_QUESTION_PROMOTYPE = "DELETE_QUESTION_PROMOTYPE";
export const GET_PROMO_TYPE_BY_ID="GET_PROMO_TYPE_BY_ID"