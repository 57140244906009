export const UPDATE_LOCAL_ERROR_TICKET_JSON = "UPDATE_LOCAL_ERROR_TICKET_JSON";
export const GET_ALL_ERROR_TICKET = "GET_ALL_ERROR_TICKET";
export const CREATE_ERROR_TICKET = "CREATE_ERROR_TICKET";
export const GET_SINGLE_ERROR_TICKET = "GET_SINGLE_ERROR_TICKET";
export const UPDATE_ERROR_TICKET = "UPDATE_ERROR_TICKET";
export const DELETE_ERROR_TICKET = "DELETE_ERROR_TICKET";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const UPDATE_ERROR_TICKET_BY_SEARCH = "UPDATE_ERROR_TICKET_BY_SEARCH";
export const RESET_SINGLE_TICKET = "RESET_SINGLE_TICKET";
export const GET_ERROR_TYPE_VALUES = "GET_ERROR_TYPE_VALUES";
export const CREATE_COMMENT_ADMIN = "CREATE_COMMENT_ADMIN";
export const GET_COMMENT_ADMIN = "GET_COMMENT_ADMIN";
export const GET_ERROR_EXPORT_DATA = "GET_ERROR_EXPORT_DATA";