export default class TicketReportStateManager {
  static getAllErrorTickets = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;

    if (data) {
      state.total = data.pagination?.total || 0;

      state.errorTickets = data.errorTicketList || [];
    }

    return state;
  };
  static getAllPromoNames = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;

    if (data) {
      state.promos = data || [];
    }

    return state;
  };
  static getSingleErrorTicket = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;

    state.singleErrorTicket = data;

    return state;
  };

  static createErrorTickets = (prevState, action) => {
    const state = { ...prevState };

    state.errorTickets = [action.payload.data.data[0], ...state.errorTickets];
    state.total = state.total + 1;
    return state;
  };
  static uploadMedia = (prevState, action) => {
    const state = { ...prevState };

    state.media = [action.payload.data.data, ...state.media];
    return state;
  };
  static getMedia = (prevState, action) => {
    const state = { ...prevState };

    state.media = [action.payload.data.data, ...state.media];
    return state;
  };
  static getCommentAdmin = (prevState, action) => {
    const state = { ...prevState };
    const data = action.payload.data;
    state.getCommentAdmin = data;

    return state;
  };

  static updateErrorTicket = (prevState, action) => {
    const state = { ...prevState };

    const newRecord = action.meta.previousAction.meta;

    const index = state.errorTickets.findIndex(
      (item) => item._id === newRecord._id
    );
    if (index !== -1) {
      state.errorTickets[index] = {
        ...state.errorTickets[index],
        ...action.payload.data.data.updatedReport,
      };
      state.errorTickets = [...state.errorTickets];
    }

    return state;
  };

  static updateTicketStatus = (prevState, action) => {
    const state = { ...prevState };

    const newRecord = action.meta.previousAction.meta;

    const index = state.errorTickets.findIndex(
      (item) => item._id === newRecord._id
    );
    if (index !== -1) {
      state.errorTickets[index] = {
        ...state.errorTickets[index],
        ...newRecord,
      };
      state.errorTickets = [...state.errorTickets];
    }

    return state;
  };

  static deleteErrorTicket = (prevState, action) => {
    const state = { ...prevState };
    const id = action.meta.previousAction.meta._id;

    state.errorTickets = state.errorTickets.filter((item) => item._id !== id);

    return state;
  };

  static updateErrorTicketBySearch = (prevState, action) => {
    const state = { ...prevState };
    const data = action.errorTickets;
    state.searchedErrorTickets = data;

    return state;
  };

  static resetSingleTicket = (prevState, action) => {
    const state = { ...prevState };
    const data = action.errorTickets;
    state.singleErrorTicket = data;

    return state;
  };

  static getAllErrorTypeValues = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;
    state.errorTypeValues = data || [];

    return state;
  };

  static getActionTeams = (prevState, action) => {
    const state = { ...prevState };

    const data = action.payload.data.data;
    state.actionTeams = data || [];

    return state;
  };
}
