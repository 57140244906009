import CommonLayout from "components/layout/Layout";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomTable from "components/common/table/TableNew";
import TableSearchDropdown from "components/TableSearchDropdown";
import Fullscreen from "fullscreen-react";
import { Alert, Button, Input, Modal, Spin, Switch, Tag } from "antd";
import { TextMessage, SECONDS } from "utils/constants";
import AutomationService from "services/automation-templete/automation.service";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";
import {
    ColumnHeightOutlined,
    DeleteOutlined,
    SearchOutlined,
    ExclamationCircleOutlined,
    FileAddOutlined,
    FullscreenOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import "./../automation-template.css";
import session from "utils/session";
const { Search } = Input;

const XmlTemplate = () => {
    const [isEnter, setIsEnter] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showAlert } = useAlert();
    const [templatesData, setTemplates] = useState([]);
    const [tableSize, setTableSize] = useState("default");
    const [tableClicked, setTableClicked] = useState(0);
    const [templateDeActive, setTemplateDeActive] = useState(false);
    const [templateActive, setTemplateActive] = useState(true);
    const [loadingState, setLoadingState] = useState(false);
    const [searchedText, setSearchedText] = useState("");
    const [reloadingState, setReLoadingState] = useState(false);
    const [searchedTemplate, setSearchTemplate] = useState([]);
    const [isSearched, setIsSearched] = useState(false);
    const automationService = useMemo(() => new AutomationService(), []);
    const [totalCount, setTotalCount] = useState("");
    const [currPage, setCurrPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tableLoading, setTableLoading] = useState(false);
    const [currentFilters, setCurrentFilters] = useState({});
    const teamDetail = session && session.teamDetail ? session.teamDetail : null;
    const role = session && session.role ? session.role.toLowerCase() : "";
    const userPermissions =
    session && session.permissions ? session.permissions : [];
    const getTemplates = async (params = {}, isLoading = true) => {
        if (isLoading) {
            setLoadingState(true);
        }
        await automationService.getXMLTemplates(params).then(response => {
            setTemplates(response?.message?.data?.xmlTemplate);
            setTotalCount(response?.message?.data?.count);
            setLoadingState(false);
            setTableLoading(false);
        }).catch(error => console.error('Error fetching templates:', error));
    }

    useEffect(() => {
        getTemplates({ page: currPage, pageSize: pageSize }, false);
        return () => { };
    }, []);
    const CSV_TEMPLATE_LIST_COLUMNS = [
        {
            title: "Template Name",
            isSortable: true,
            dataIndex: "templateName",
            key: "name",
            width: "50%",
            sorter: (a, b) =>
                a.templateName.toString().localeCompare(b.templateName.toString()),
        },
        {
            title: "Promo Type",
            isSortable: true,
            dataIndex: "promoType",
            key: "promoType",
            width: "35%",
            sorter: (a, b) =>
                a.promoType.toString().localeCompare(b.promoType.toString()),
        },
        // {
        //     title: "Activate/Deactivate",
        //     isSortable: true,
        //     dataIndex: "isActive",
        //     key: "isActive",
        //     width: "10%",
        //     align: "center",
        //     filters: [
        //         {
        //             text: "Active",
        //             value: true,
        //         },
        //         {
        //             text: "Deactive",
        //             value: false,
        //         },
        //     ],
        //     onFilter: (value, record) => record.isActive === value,
        //     render: (isActive, record) => (
        //         <Switch
        //             checked={isActive} className="switchStyle"
        //             onChange={() =>
        //                 isActive ? changeStatusYes(record._id, record) : changeStatusNo(record._id, record)
        //             }
        //         />
        //     ),
        // }
    ];

    const handleTableHeight = () => {
        if (tableClicked === 2) {
            setTableClicked(0);
            setTableSize("default");
        }
        if (tableClicked === 0) {
            setTableClicked(tableClicked + 1);
            setTableSize("small");
        }
        if (tableClicked === 1) {
            setTableClicked(tableClicked + 1);
            setTableSize("middle");
        }
    };

    const handleSearchChange = (e) => {
        setSearchedText(e.target.value);
    };

    const viewTemplate = (record) => {
        navigate(`/xml-templates/createTemplate/${record._id}/${'view'}`);
    };

    const onSearch = async (value) => {
        const params = {
            ...currentFilters,
        };

        if (value) {
            params.search = { name: value };
            params.page = 1;
            getTemplates(params)
        } else {
            setSearchedText('');
            getTemplates({ page: currPage, pageSize: pageSize }, false)
        }
        // dispatch(updatePromoTypeBySearch(searchedPromoTypeData));
    };

    const handleAdd = () => {
        navigate(`/xml-templates/createTemplate`);
    };

    const handleReload = async () => {
        setReLoadingState(true);
        const res = await getTemplates({ page: currPage, pageSize: pageSize }, false);
        if (res) {
            setReLoadingState(false);
        }
    };
    const changeStatusYes = async (id, data) => {
        Modal.confirm({
            title: "Are you sure you want to deactivate this template?",
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            cancelText: "No",
            onOk: () => templateStatus(id, data, false),
            okCancel: templateDeactivateNo,
            okType: "primary",
        });
    };

    const changeStatusNo = async (id, data) => {
        Modal.confirm({
            title: "Do you want to activate this template?",
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            cancelText: "No",
            onOk: () => templateStatus(id, data, true),
            okCancel: templateActivateNo,
            okType: "primary",
        });
    };

    const templateStatus = async (id, data, status) => {
        data.isActive = status;
        // setTemplateActive(status);
        const response = await automationService.updateXMLTemplate(id, data);
        if (response?.status) {
            showAlert({
                message: response?.message?.message,
                variant: AlertVariant.SUCCESS,
            });
            getTemplates({ page: currPage, pageSize: pageSize }, false);
        }
    };

    const templateActivateNo = () => {
        setTemplateDeActive(false);
    };

    const templateDeactivateNo = () => {
        setTemplateActive(true);
    };

    const setColumns = () => {
        let cols = [];

        CSV_TEMPLATE_LIST_COLUMNS.forEach((o) => {
            if (o.dataIndex === "promoType" || o.dataIndex === "templateName") {
                const colsOptions = {
                    filterDropdown: ({
                        setSelectedKeys,
                        selectedKeys,
                        confirm,
                        clearFilters,
                    }) => {
                        return (
                            <TableSearchDropdown
                                setSelectedKeys={setSelectedKeys}
                                selectedKeys={selectedKeys}
                                confirm={confirm}
                                clearFilters={clearFilters}
                                dataIndex={o.dataIndex}
                            />
                        );
                    },
                    filterIcon: (filtered) => (
                        <SearchOutlined
                            style={{ color: filtered ? "#1890ff" : undefined }}
                        />
                    )
                };
                cols.push({ ...o, ...colsOptions });
            } else {
                cols.push(o);
            }
        });
        return cols;
    };

    const setupRows = (templates) => {
        return templates?.map((o) => {
            return {
                templateName: o.name,
                _id: o._id,
                promoType: o.promoTypeId.promoType,
                isActive: o?.isActive
            };
        });
    };

    const updateTemplate = (record) => {
        navigate(`/xml-templates/createTemplate/${record._id}/${'edit'}`);
    };

    const deleteTemplate = (value) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this template?',
            icon: <ExclamationCircleOutlined />,
            okText: "Yes",
            cancelText: "No",
            onOk: () => deleteTemplateYes(value),
            okType: "primary",
        });
    };

    const deleteTemplateYes = async (value) => {
        const response = await automationService.deleteXMLTemplate(value._id);
        if (response?.status) {
            showAlert({
                message:
                    response?.message?.message || response?.message ||
                    "XML Template deleted successfully",
                variant: AlertVariant.SUCCESS,
            });
            getTemplates({ page: currPage, pageSize: pageSize }, false);
        }
        window.scrollTo(0, 0);
    };
    const checkPermission = (row) => {
        if (role === "admin") {
            return false;
        } else {
            return userPermissions.includes("automationTemplates") ? false : true;
        }

    };
    return (
        <CommonLayout className="content-mr" breadCrumbText="Automation Template / XML Templates">
            {loadingState || reloadingState ? (
                <div className="errorTipDiv">
                    <Spin size="middle" tip="Loading..." />
                </div>
            ) : (
                <>
                    <div className="promo-type-card">
                        <div className="promo-right-content">
                            <Search
                                placeholder="Search"
                                enterButton
                                allowClear
                                className="searchPromoTypeBtn"
                                onSearch={onSearch}
                                onChange={handleSearchChange}
                            />
                        </div>

                        <div className="promo-left-content">
                            <Button
                                type="primary"
                                icon={<FileAddOutlined />}
                                className="addPromoTypeBtn"
                                onClick={handleAdd}
                                disabled={teamDetail?.isVCM}
                            >
                                Create Template
                            </Button>
                        </div>
                    </div>

                    <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
                        <div className="promo-table-card">
                            <div className="promo-table-topbar">
                                <div className="promoRightFilterGrpWrapper">
                                    <div className="promoRightSettingGrp">
                                        <ReloadOutlined
                                            onClick={handleReload}
                                            className="reloadIconStyle"
                                        />
                                        <ColumnHeightOutlined
                                            className="reloadIconStyle"
                                            onClick={handleTableHeight}
                                        />
                                        <FullscreenOutlined
                                            onClick={() => {
                                                setIsEnter(true);
                                            }}
                                            className="reloadIconStyle"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100">
                                <CustomTable
                                    columns={setColumns()}
                                    rows={setupRows(
                                        isSearched
                                            ? searchedTemplate
                                            : templatesData
                                    )}
                                    isEditable={!teamDetail?.isVCM}
                                    isDeleteable={!teamDetail?.isVCM}
                                    rowKey="_id"
                                    onEdit={updateTemplate}
                                    onDelete={deleteTemplate}
                                    total={totalCount}
                                    size={tableSize}
                                    isViewable={true}
                                    onView={viewTemplate}
                                    isPopup={true}
                                    isLoading={loadingState || tableLoading}
                                    checkRowDeleatable={checkPermission}
                                    onChange={async (
                                        { current, pageSize },
                                        filter = {},
                                        { field, order, columnKey }
                                    ) => {
                                        const params = {
                                            page: current,
                                            perPage: pageSize,
                                            sortBy: order && columnKey,
                                            orderBy: order,
                                        };
                                        setCurrPage(current);
                                        setPageSize(pageSize);
                                        const keys = Object.keys(filter);
                                        keys.forEach((key) => {
                                            if (filter[key]) {
                                                if (!params.filters) {
                                                    params.filters = {};
                                                }
                                                if (!params.search) {
                                                    params.search = {};
                                                }
                                                if (
                                                    key === "name" ||
                                                    key === "promoType"
                                                ) {
                                                    params.search[key] = filter[key][0];
                                                } else
                                                    if (key === "isActive") {
                                                        params.filters[key] = filter[key]?.join(",");
                                                    }
                                            }
                                        });
                                        setTableLoading(true);
                                        setCurrentFilters(params);
                                        getTemplates(params, false);
                                    }}
                                />
                            </div>
                        </div>
                    </Fullscreen>
                </>
            )}
        </CommonLayout>
    )
}
export default XmlTemplate;