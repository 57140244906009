import './Users.css'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Col, Input, Modal, Row, Switch } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, SearchOutlined, } from '@ant-design/icons';
import CustomTable from 'components/common/table/Table';
import { USER_TEAM_LIST_COLUMNS } from 'utils/column';
import { Validator } from 'utils/validations';
import { TextMessage, FIELD_REQUIRED, SECONDS } from 'utils/constants';
import { updateUserById, updateUserStatus, getAllTeamUsers, unassignUserFromTeam } from 'actions/users/index'
import TableSearchDropdown from 'components/TableSearchDropdown';
import { useParams } from 'react-router-dom';
import { showHideUnassignButton } from "actions/teams/index"

const TeamUsers = () => {
  const { teamId } = useParams()
  const dispatch = useDispatch()
  const users = useSelector((state) => state.users.users)
  const searchedUsers = useSelector((state) => state.users.searchedUsers)
  const [showAddAlert, setShowAddAlert] = useState(false)
  const [addAlertText, setAddAlertText] = useState('')
  const [addAlertType, setAddAlertType] = useState('')

  const [modalOpen, setModalOpen] = useState(false)
  const [currentAction, setCurrentAction] = useState('')
  const [currentRecord, setCurrentRecord] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: '',
  })
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [role, setRole] = useState('Merchant')

  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [okBtnText, setOkBtnText] = useState('')
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showUpdateAlert, setShowUpdateAlert] = useState(false)
  const [updateAlertText, setUpdateAlertText] = useState('')
  const [updateAlertType, setUpdateAlertType] = useState('')
  const [loading, setLoading] = useState(false)
  const [userActive, setUserActive] = useState(true)
  const [userDeActive, setUserDeActive] = useState(false)

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      await dispatch(getAllTeamUsers(teamId))
      setLoading(false)
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (showUpdateAlert) {
      setTimeout(() => {
        setShowUpdateAlert(false)
      }, SECONDS);
    }
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false)
      }, SECONDS);
    }
  }, [showUpdateAlert, showDeleteAlert])

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: 'Do you want to make this user De-activate?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => userDeactivateYes(id, false),
      okCancel: userDeactivateNo,
      okType: 'primary',
    });
  }

  const userDeactivateYes = async (id, checked) => {
    await dispatch(updateUserStatus(id, checked))
  }
  const userDeactivateNo = () => {
    setUserActive(true)
  }

  const changeStatusNo = async (id, checked) => {
    Modal.confirm({
      title: 'Do you want to make this user Activate?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => userActivateYes(id, true),
      okCancel: userActivateNo,
      okType: 'primary',
    });
  }

  const userActivateYes = async (id, checked) => {
    await dispatch(updateUserStatus(id, checked))
  }
  const userActivateNo = () => {
    setUserDeActive(false)
  }

  const setupRows = (users) => {
    return users?.map((o) => {
      return {
        "userName": o.fullName,
        "_id": o._id,
        "email": o.email,
        'role': o.role,
        'accessType': o.role.toLowerCase() === 'Admin' ? 'Full' : 'Edit Only',
        'active': o.isActive ? <Switch checked={userActive} className='switchStyle' onChange={() => changeStatusYes(o._id)} /> : <Switch checked={userDeActive} onChange={() => changeStatusNo(o._id)} />,
        'firstName': o?.firstName,
        'lastName': o?.lastName
      }
    })
  }

  const renderTag = () => {
    return (
      <form
        className='userForm'
        onSubmit={handleSubmit}
      >

        <Row gutter={16} className='mb-25'>

          <Col className="gutter-row" span={12}>
            <div>
              <label className={currentAction === 'View Details' ? 'label-grey' : ''}>
                {currentAction !== 'View Details' && <span className='spanReq'>*</span>} First Name
              </label>

              <Input placeholder='First Name' value={firstName} className='mt-5'
                onChange={handleFirstName} disabled={currentAction === 'View Details'}
              />
              <span className='errorLabel'>{firstNameError}</span>
            </div>
          </Col>

          <Col className="gutter-row" span={12}>
            <div>
              <label className={currentAction === 'View Details' ? 'label-grey' : ''}>
                {currentAction !== 'View Details' && <span className='spanReq'>*</span>} Last Name</label>
              <Input placeholder='Last Name' value={lastName} className='mt-5'
                onChange={handleLastName} disabled={currentAction === 'View Details'}
              />
              <span className='errorLabel'>{lastNameError}</span>
            </div>
          </Col>

        </Row>

        <Row gutter={16} className='mb-25'>
          <Col className="gutter-row" span={24}>
            <div>
              <label className={currentAction === 'View Details' ? 'label-grey' : ''}>
                {currentAction !== 'View Details' && <span className='spanReq'>*</span>} Email</label>
              <Input placeholder='Email' value={email} className='mt-5'
                onChange={handleEmail} disabled={currentAction === 'View Details'}
              />
              <span className='errorLabel'>{emailError}</span>
            </div>
          </Col>
        </Row>
      </form>
    );
  };

  const handleCancel = () => {
    setModalOpen(false)
    setCurrentAction('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setFirstNameError('')
    setLastNameError('')
    setEmailError('')
    setOkBtnText('')
  }

  const handleFirstName = (e) => {
    const { value } = e.target
    setFirstNameError(Validator.validate('title', value, null, null, true))
    setFirstName(value)
  }

  const handleLastName = (e) => {
    const { value } = e.target
    setLastNameError(Validator.validate('title', value, null, null, true))
    setLastName(value)
  }

  const handleEmail = (e) => {
    const { value } = e.target
    setEmailError(Validator.validate('email', value, null, null, true))
    setEmail(value)
  }

  const viewUser = (record) => {

    setModalOpen(true)
    setCurrentAction('View Details')
    setCurrentRecord(record)
    setFirstName(record.firstName)
    setLastName(record.lastName)
    setEmail(record.email)
    setRole(record.role)
  }

  const resetValues = () => {
    setModalOpen(false)
    setCurrentAction('')
    setFirstName('')
    setLastName('')
    setEmail('')
    setFirstNameError('')
    setLastNameError('')
    setEmailError('')
    setOkBtnText('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let newRecord = {};

    if (currentAction === 'Update User') {

      if (firstNameError !== "" || lastNameError !== "" || emailError !== "" || firstName === "" || lastName === "" || email === "") {
        setFirstNameError(firstName === "" ? FIELD_REQUIRED : firstNameError)
        setLastNameError(lastName === "" ? FIELD_REQUIRED : lastNameError)
        setEmailError(email === "" ? FIELD_REQUIRED : emailError)
        return;
      }

      setLoading(true)

      newRecord = {
        _id: currentRecord._id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        fullName: `${firstName} ${lastName}`
      }

      const response = await dispatch(updateUserById(newRecord._id, newRecord.firstName, newRecord.lastName, newRecord.email, newRecord.role, newRecord.fullName))

      if (response.error === undefined) {
        setLoading(false)
        setShowUpdateAlert(true)
        setUpdateAlertText(TextMessage.USER_UPDATE_TEXT)
        setUpdateAlertType('success')
        resetValues()
      } else {
        setLoading(false)
        setShowUpdateAlert(true)
        setUpdateAlertText(response.error.message)
        setUpdateAlertType('error')
        resetValues()
      }

    }

    if (currentAction === 'Assign User') {
      setLoading(true)

      newRecord = {
        teamId: currentRecord._id,
      }
      // const response = await dispatch(updateUserById(newRecord._id,newRecord.firstName,newRecord.lastName,newRecord.email,newRecord.role,newRecord.fullName))
      const response = '';
      if (response.error === undefined) {
        setLoading(false)
        setShowUpdateAlert(true)
        setUpdateAlertText(TextMessage.USER_ASSIGNED_TEXT)
        setUpdateAlertType('success')
        resetValues()
      } else {
        setLoading(false)
        setShowUpdateAlert(true)
        setUpdateAlertText(response.error.message)
        setUpdateAlertType('error')
        resetValues()
      }

    }
  }

  const deleteUser = (val) => {
    Modal.confirm({
      title: users && users.length === 1 ? 'Are you sure you want to delete All user from this team?' : TextMessage.UNASSIGN_CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => deleteUserYes(val),
      okType: 'primary',
    });
  }
  const handleReload = async () => {
    setLoading(true)
    const res = await dispatch(getAllTeamUsers(teamId))
    if (res) {
      setLoading(false)
    }
  }

  const deleteUserYes = async (val) => {
    const response = await dispatch(unassignUserFromTeam([val._id], teamId, users && users.length === 1 ? 'warning' : ''))
    if (response.error === undefined) {
      setShowDeleteAlert(true)
      handleReload();
      setLoading(false)
    } else {
      setLoading(false)
      setShowAddAlert(true)
      setAddAlertText(response?.error?.response?.data?.message || TextMessage.ERROR_TEXT)
      setAddAlertType('error')
    }
  }

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false)
  }

  const handleErrorClose = () => {
    setShowAddAlert(false)
  }

  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false)
    setCurrentAction('')
  }
  const onSelectChange = (newSelectedRowKeys) => {
    if (newSelectedRowKeys.length) {
      dispatch(showHideUnassignButton(true, newSelectedRowKeys))
    } else {
      dispatch(showHideUnassignButton(false, newSelectedRowKeys))
    }
  };

  const setColumns = () => {
    let cols = [];

    USER_TEAM_LIST_COLUMNS.forEach((o) => {
      if (o.dataIndex !== "role" && o.dataIndex !== "accessType" && o.dataIndex !== "active") {
        const colsOptions = {
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
              <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex} />
            )
          },
          filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          onFilter: (value, record) => {

            const rec = record[o.dataIndex]
              ? record[o.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
              : '';
            return rec;
          },

        }
        cols.push({ ...o, ...colsOptions });
      }
      else {
        cols.push(o);
      }

    });
    return cols
  }

  return (
    <>


      {/* table starts */}
      <div className='w-100'>
        <CustomTable
          isLoading={loading}
          columns={setColumns()}
          rows={setupRows(searchedUsers.length > 0 ? searchedUsers : users)}
          isEditable={false}
          isDeleteable={true}
          rowKey="_id"
          isViewable={true}
          onDelete={deleteUser}
          total={users?.length}
          onView={viewUser}
          isPopup={true}
          rowSelectionType='checkbox'
          onSelectRows={onSelectChange}
        />
      </div>

      {/* modal starts */}

      <Modal
        open={modalOpen}
        title={currentAction}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText={okBtnText}
        destroyOnClose
        footer={
          (currentAction === "Add User" || currentAction === "Update User") ? [
            <Button onClick={handleCancel}>Cancel</Button>,
            <Button type='primary' onClick={handleSubmit}
              className='footerBtnNavy' disabled={loading}>{loading ? `Loading...` : okBtnText}</Button>
          ] : null

        }
      >
        {(currentAction === "Add User" || currentAction === "Update User" || currentAction === 'View Details') ?
          renderTag(currentRecord) : <></>}
      </Modal>

      {
        showDeleteAlert &&
        <Alert message={TextMessage.USER_UNASSIGN_TEXT} type="error"
          showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined />} className='alertStyle'
        />
      }

      {
        showUpdateAlert &&
        <Alert message={updateAlertText} type={updateAlertType}
          showIcon closable onClose={handleUpdateAlertClose} className='alertStyle'
        />
      }
      {
        showAddAlert &&
        <Alert message={addAlertText} type={addAlertType}
          showIcon closable onClose={handleErrorClose}
          className='alertStyle'
        />
      }

    </>
  )
}

export default TeamUsers;