import {
  deleteQuestionPromoType,
  getQuestionUnPublished,
  markQuestionPromoTypePublish,
} from "actions/promoTypes";
import { setPage } from "actions/app";
import {
  Input,
  Select,
  Checkbox,
  DatePicker,
  Radio,
  Tabs,
  Modal,
  Spin,
  Alert,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import CommonLayout from "components/layout/Layout";
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  SECONDS,
  TextMessage,
  questionTypesSelectOption,
} from "utils/constants";
import CheckBoxQuestionView from "./CheckBoxQuestionView";
import DateQuestionView from "./DateQuestionView";
import DropDownQuestion from "./DropDownQuestionView";
import MultipleChoiceQuestionView from "./MultipleChoiceQuestionView";
import MultiSelectQuestionView from "./MultiSelectQuestionView";
import ShortAnswerQuestionView from "./ShortAnswerQuestionView";
import TabQuestionView from "./TabQuestionView";
import TextAreaQuestionView from "./TextAreaQuestionView";
import {
  updateQuestionById,
  updateQuestionByWorkflowTeam,
} from "actions/formBuilder";
import MediaAnswerQuestions from "../formbuilder/mediaAnswerQuestion";
import MediaAnswerQuestionView from "./MediaAnswerQuestionview";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
const Option = Select;

const questionTypeOptionsChildren = [];
for (let i = 0; i < questionTypesSelectOption.length; i++) {
  questionTypeOptionsChildren.push(
    <Option key={questionTypesSelectOption[i]._id}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "5px" }}>
          {questionTypesSelectOption[i].icon}
        </span>
        <span>{questionTypesSelectOption[i].value}</span>
      </div>
    </Option>
  );
}

const RadioOptions = (options) =>
  options?.map((op, i) => <Radio disabled>{op?.optionText}</Radio>);

const CheckBoxOptions = (options) =>
  options?.map((op, i) => <Checkbox disabled>{op?.optionText}</Checkbox>);

const DropdownOptions = () => (
  <Select disabled value={null} placeholder="Select"></Select>
);

const TextareaOptions = () => (
  <TextArea rows={2} className="w-100" disabled placeholder="Write here" />
);

const ShortAnswerOptions = () => (
  <Input className="w-100" disabled placeholder="Write here" />
);

const DateOptions = () => <RangePicker disabled />;

const TabOptions = (options) => (
  <Tabs>
    {options?.map((op, i) => (
      <TabPane tab={op.optionText} key={i}>
        {<Select mode="multi" disabled value={[]} placeholder="Multi Select" />}
      </TabPane>
    ))}
  </Tabs>
);

const MultiSelectOptions = () => (
  <Select disabled value={[]} placeholder="Multi-Select"></Select>
);

const PromoTypeFormBuilderView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const promoTypeQuestions = useSelector(
    (state) => state.promoTypes.promoTypeQuestions
  );
  const promoDetails = useSelector((state) => state.promoTypes.promoTypeById);

  useEffect(() => {
    dispatch(setPage("Promo Type Form View"));
  }, [dispatch]);
  const [questions, setQuestions] = useState([]);
  const [loadingState, setLoadingState] = useState(false);
  const [unPublishedQuestions, setUnPublishedQuestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [updateAlertText, setUpdateAlertText] = useState("");
  const [updateAlertType, setUpdateAlertType] = useState("");

  useEffect(() => {
    if (showUpdateAlert) {
      setTimeout(() => {
        setShowUpdateAlert(false);
      }, SECONDS);
    }
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
  }, [showUpdateAlert, showDeleteAlert]);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getQuestionUnPublished(id));
      if (res) {
        setLoadingState(false);
      } else {
        setLoadingState(false);
      }
    };
    getCall();
  }, [id, dispatch]);

  useEffect(() => {
    if (promoTypeQuestions) {
      let _unpub = promoTypeQuestions.filter(
        (item) => item?.questionDetails?.isPublished === false
      );
      _unpub = _unpub.map((item) => {
        const newData = { ...item, ...item.questionDetails, errors: {} };
        delete newData.questionDetails;
        return newData;
      });
      setUnPublishedQuestions(_unpub);
    }
  }, [promoTypeQuestions]);

  // Added new function
  const copyQuestion = (i) => {
    const questionsArr = [...questions];

    const nq = { ...questionsArr[i] };
    setQuestions([...questionsArr, nq]);
  };

  const handleQuestionText = (text, i) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].questionHeader = text;
    setUnPublishedQuestions(questionsArr);
  };

  const handleQuestionDesignate = (e, i) => {
    const questionsArr = [...unPublishedQuestions];
    if (e.target.checked === true) {
      questionsArr[i].designate = "required";
      questionsArr[i].optionValue && questionsArr[i].optionValue.forEach((option) => {
        option.subOptions && option.subOptions.forEach((subOption) => {
          subOption.designate = "required";
        })
      })
    } else {
      questionsArr[i].designate = "optional";
      questionsArr[i].optionValue && questionsArr[i].optionValue.forEach((option) => {
        option.subOption && option.subOptions.forEach((subOption) => {
          subOption.designate = "optional";
        })
      })
    }
    setUnPublishedQuestions(questionsArr);
  };
  const handleStandardTeamChange = (e, i) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].standardWorkflowApproverTeamId = e.target.value;
    setUnPublishedQuestions(questionsArr);
  };
  const handleEmergencyTeamChange = (e, i) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].emergencyWorkflowApproverTeamId = e.target.value;
    setUnPublishedQuestions(questionsArr);
  };
  // const onRegexCheckboxChange = (e, i) => {
  //   const questionsArr = [...unPublishedQuestions];
  //   questionsArr[i].isRegexRequired = e.target.checked;
  //   setUnPublishedQuestions(questionsArr);
  // };
  const onRegexCheckboxChange = (e, i, j, k, type) => {
    const questionsArr = [...unPublishedQuestions];
    type ?
      questionsArr[i].optionValue[j].subOptions[k].isRegexRequired = e.target.checked :
      questionsArr[i].isRegexRequired = e.target.checked;
    // type && e.target.checked ? questionsArr[i].designate = "required" : questionsArr[i].designate = "optional";
    setUnPublishedQuestions(questionsArr);
  };
  const handleRegexChange = (e, i, j, k, type) => {
    const questionsArr = [...unPublishedQuestions];
    type ? questionsArr[i].optionValue[j].subOptions[k].regex = e.target.value :
      questionsArr[i].regex = e.target.value;
    setUnPublishedQuestions(questionsArr);
  };
  // const handleRegexChange = (e, i) => {
  //   const questionsArr = [...unPublishedQuestions];
  //   questionsArr[i].regex = e.target.value;
  //   setUnPublishedQuestions(questionsArr);
  // };
  const handleQuestionType = (value, i) => {
    const questionsArr = [...unPublishedQuestions];
    if (value === "dropdown") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }

    if (value === "date") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "tabs") {
      setIsTab(true);
      questionsArr[i].questionType = value;
      questionsArr[i] = {
        ...questionsArr[i],
        errors: {
          questionHeaderError: null,
          optionTextError: null,
          subOptionTextError: null,
          subOptionTypeError: null,
        },
      };
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "multiSelect") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "input") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "textarea") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "checkbox") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "radio") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
    if (value === "media") {
      questionsArr[i].questionType = value;
      setUnPublishedQuestions(questionsArr);
    }
  };

  const handleAddOptions = (i) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setUnPublishedQuestions(questionsArr);
  };

  const handleFormBuilderSelectMasterOption = (i, value) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i] = {
      ...questionsArr[i],
      isMaster: true,
      masterDataType: value,
    };

    delete questionsArr[i].optionValue;
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteOptions = (i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.splice(j, 1);
    setUnPublishedQuestions(questionsArr);
  };

  // const handleAddSubOptions = (i, j) => {
  //   const questionsArr = [...unPublishedQuestions];

  //   questionsArr[i].optionValue[j] = {
  //     ...questionsArr[i].optionValue[j],
  //     subOptions: [{ subOptionText: "", subOptionType: null }],
  //   };

  //   setUnPublishedQuestions(questionsArr);
  // };
  const handleAddSubOptions = (i, j) => {
    const questionsArr = [...questions];

    questionsArr[i].optionValue[j] = {
      ...questionsArr[i].optionValue[j],
      subOptions: [{ subOptionText: "", subOptionType: null, isRegexRequired: false, }],
    };

    setUnPublishedQuestions(questionsArr);
  };


  const handleDeleteSubOptions = (i, j, k) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions.splice(k, 1);
    setUnPublishedQuestions(questionsArr);
  };
  const handleAddMoreSubOptions = (i, j, k) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].optionValue[j].subOptions.push({
      subOptionText: "",
      subOptionType: null,
      isRegexRequired: false,
    });
    setUnPublishedQuestions(questionsArr);
  };
  // const handleAddMoreSubOptions = (i, j, k) => {
  //   const questionsArr = [...unPublishedQuestions];

  //   questionsArr[i].optionValue[j].subOptions.push({
  //     subOptionText: "",
  //     subOptionType: null,
  //   });
  //   setUnPublishedQuestions(questionsArr);
  // };

  const handleOptionTextChange = (text, i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].optionText = text;

    setUnPublishedQuestions(questionsArr);
  };

  const handleSuboptionTextChange = (text, i, j, k) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      subOptionText: text,
    };

    setUnPublishedQuestions(questionsArr);
  };
  const handleSubOptionTypeChange = (value, i, j, k) => {
    const questionsArr = [...unPublishedQuestions];
    if (value === "input" || value === "textarea" || value === "date") {
      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptionType: value,
      };

      delete questionsArr[i].optionValue[j].subOptions[k].subOptions;
      delete questionsArr[i].optionValue[j].subOptions[k].isMaster;
      delete questionsArr[i].optionValue[j].subOptions[k].masterDataType;

      setUnPublishedQuestions(questionsArr);
    } else if (value === "dropdown") {
      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptionType: value,
      };

      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptions: [{ subOptionText: "" }],
      };

      setUnPublishedQuestions(questionsArr);
    } else {
      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptionType: value,
      };

      questionsArr[i].optionValue[j].subOptions[k] = {
        ...questionsArr[i].optionValue[j].subOptions[k],
        subOptions: [{ subOptionText: "" }],
      };
      delete questionsArr[i].optionValue[j].subOptions[k].isMaster;
      delete questionsArr[i].optionValue[j].subOptions[k].masterDataType;
      setUnPublishedQuestions(questionsArr);
    }
  };
  // const handleSubOptionTypeChange = (value, i, j, k) => {
  //   const questionsArr = [...unPublishedQuestions];
  //   if (value === "input") {
  //     questionsArr[i].optionValue[j].subOptions[k] = {
  //       ...questionsArr[i].optionValue[j].subOptions[k],
  //       subOptionType: value,
  //     };

  //     delete questionsArr[i].optionValue[j].subOptions[k].subOptions;
  //     delete questionsArr[i].optionValue[j].subOptions[k].isMaster;
  //     delete questionsArr[i].optionValue[j].subOptions[k].masterDataType;

  //     setUnPublishedQuestions(questionsArr);
  //   } else if (value === "dropdown") {
  //     questionsArr[i].optionValue[j].subOptions[k] = {
  //       ...questionsArr[i].optionValue[j].subOptions[k],
  //       subOptionType: value,
  //     };

  //     questionsArr[i].optionValue[j].subOptions[k] = {
  //       ...questionsArr[i].optionValue[j].subOptions[k],
  //       subOptions: [{ subOptionText: "" }],
  //     };

  //     setUnPublishedQuestions(questionsArr);
  //   } else {
  //     questionsArr[i].optionValue[j].subOptions[k] = {
  //       ...questionsArr[i].optionValue[j].subOptions[k],
  //       subOptionType: value,
  //     };

  //     questionsArr[i].optionValue[j].subOptions[k] = {
  //       ...questionsArr[i].optionValue[j].subOptions[k],
  //       subOptions: [{ subOptionText: "" }],
  //     };
  //     delete questionsArr[i].optionValue[j].subOptions[k].isMaster;
  //     delete questionsArr[i].optionValue[j].subOptions[k].masterDataType;
  //     setUnPublishedQuestions(questionsArr);
  //   }
  // };

  const handleAddMoreInnerSubOptions = (i, j, k, l) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].optionValue[j]?.subOptions[k]?.subOptions?.push({
      subOptionText: "",
    });
    setUnPublishedQuestions(questionsArr);
  };

  const handleFormBuilderSelectMasterSubOption = (i, j, k, value) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      isMaster: true,
      masterDataType: value,
    };
    delete questionsArr[i].optionValue[j].subOptions[k].subOptions;
    setUnPublishedQuestions(questionsArr);
  };

  const handleAddInnerTwoSubOptions = (i, j, k, l) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l],
      subOptions: [{ subOptionText: "", subOptionType: null }],
    };
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteInnerSubOptions = (i, j, k, l) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].optionValue[j].subOptions[k].subOptions.splice(l, 1);

    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteInnerTwoSubOptions = (i, j, k, l, m) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].optionValue[j].subOptions[k].subOptions[
      l
    ].subOptions.splice(m, 1);

    setUnPublishedQuestions(questionsArr);
  };

  const handleInnerSubOptionTextChange = (text, i, j, k, l) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l],
      subOptionText: text,
    };

    setUnPublishedQuestions(questionsArr);
  };
  const handleInnerTwoSubOptionText = (text, i, j, k, l, m) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[
      m
      ],
      subOptionText: text,
    };

    setUnPublishedQuestions(questionsArr);
  };

  const handleInnerTwoSubOptionType = (value, i, j, k, l, m) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[m] = {
      ...questionsArr[i].optionValue[j].subOptions[k].subOptions[l].subOptions[
      m
      ],
      subOptionType: value,
    };

    setUnPublishedQuestions(questionsArr);
  };

  // functions for tabs

  const handleAddSubOptionsTab = (i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j] = {
      ...questionsArr[i].optionValue[j],
      subOptions: [{ subOptionText: "", subOptionType: null }],
    };

    setUnPublishedQuestions(questionsArr);
  };

  const handleAddOptionsTab = (i) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteOptionsTab = (i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.splice(j, 1);
    setUnPublishedQuestions(questionsArr);
  };

  const handleOptionTextChangeTab = (text, i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].optionText = text;

    setUnPublishedQuestions(questionsArr);
  };

  const handleSubOptionTypeChangeTab = (value, i, j, k) => {
    const questionsArr = [...unPublishedQuestions];
    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      subOptionType: value, subOptionText: ""
    };
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteSubOptionsTab = (i, j, k) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions.splice(k, 1);
    setUnPublishedQuestions(questionsArr);
  };
  const handleSuboptionTextChangeTab = (text, i, j, k) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].subOptions[k] = {
      ...questionsArr[i].optionValue[j].subOptions[k],
      subOptionText: text,
    };

    setUnPublishedQuestions(questionsArr);
  };

  //functions for short answer
  const handleAddOptionsShortAnswer = (i) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteOptionsShortAnswer = (i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.splice(j, 1);
    setUnPublishedQuestions(questionsArr);
  };

  //functions for textarea
  const handleAddOptionsTextArea = (i) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteOptionsTextArea = (i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.splice(j, 1);
    setUnPublishedQuestions(questionsArr);
  };

  const handleOptionTextChangeTextArea = (text, i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].optionText = text;

    setUnPublishedQuestions(questionsArr);
  };

  //function for checkbox
  const handleAddOptionsCheckbox = (i) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.push({ optionText: "" });
    setUnPublishedQuestions(questionsArr);
  };

  const handleDeleteOptionsCheckbox = (i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue.splice(j, 1);
    setUnPublishedQuestions(questionsArr);
  };

  const handleOptionTextChangeCheckbox = (text, i, j) => {
    const questionsArr = [...unPublishedQuestions];

    questionsArr[i].optionValue[j].optionText = text;

    setUnPublishedQuestions(questionsArr);
  };

  const handleSaveQuestion = async (question, index) => {
    if (question.questionHeader === "") {
      const qArr = [...unPublishedQuestions];
      if (!qArr[index].errors) {
        qArr[index].errors = {};
      }
      qArr[index].errors.questionHeaderError = "This Field is Required";
      setUnPublishedQuestions(qArr);
      return;
    } else {
      const qArr = [...unPublishedQuestions];
      if (!qArr[index].errors) {
        qArr[index].errors = {};
      }
      qArr[index].errors.questionHeaderError = null;
      setUnPublishedQuestions(qArr);
    }

    if (
      !question.isMaster &&
      (question.questionType === "checkbox" ||
        question.questionType === "dropdown" ||
        question.questionType === "radio" ||
        question.questionType === "tabs" ||
        question.questionType === "multiSelect") &&
      question.optionValue.length === 0
    ) {
      const qArr = [...unPublishedQuestions];
      if (!qArr[index].errors) {
        qArr[index].errors = {};
      }
      qArr[index].errors.optionTextError = "Please add at least one option";
      setUnPublishedQuestions(qArr);
      return;
    } else {
      const qArr = [...unPublishedQuestions];
      if (!qArr[index].errors) {
        qArr[index].errors = {};
      }
      qArr[index].errors.optionTextError = null;
      setUnPublishedQuestions(qArr);
    }

    if (
      question.questionType === "dropdown" ||
      question.questionType === "radio" ||
      question.questionType === "checkbox" ||
      question.questionType === "tabs" ||
      question.questionType === "multiSelect"
    ) {
      if (!question.isMaster) {
        const isEmptySubOpt = question.optionValue.some(
          (item) => item.optionText === ""
        );
        if (isEmptySubOpt) {
          const qArr = [...unPublishedQuestions];
          if (!qArr[index].errors) {
            qArr[index].errors = {};
          }
          qArr[index].errors.optionTextError = "Please add option value";
          setUnPublishedQuestions(qArr);
          return;
        } else {
          const qArr = [...unPublishedQuestions];
          if (!qArr[index].errors) {
            qArr[index].errors = {};
          }
          qArr[index].errors.optionTextError = null;
          setUnPublishedQuestions(qArr);
        }
      }
    }

    if (
      question.questionType === "dropdown" ||
      question.questionType === "radio" ||
      question.questionType === "tabs"
    ) {
      if (!question.isMaster) {
        for (let i = 0; i < question.optionValue.length; i++) {
          const element = question.optionValue[i];
          const isElementHasEmptySubOpt =
            element.subOptions &&
            element.subOptions.some((sop) => sop.subOptionText === "");
          if (isElementHasEmptySubOpt) {
            const qArr = [...unPublishedQuestions];
            if (!qArr[index].errors) {
              qArr[index].errors = {};
            }
            qArr[index].errors.subOptionTextError = "This Field is Required";
            setUnPublishedQuestions(qArr);
            return;
          } else {
            const qArr = [...unPublishedQuestions];
            if (!qArr[index].errors) {
              qArr[index].errors = {};
            }
            qArr[index].errors.subOptionTextError = null;
            setUnPublishedQuestions(qArr);
          }

          const isElementHasEmptySubOptType =
            element.subOptions &&
            element.subOptions.some((sop) => sop.subOptionType === null);
          if (isElementHasEmptySubOptType) {
            const qArr = [...unPublishedQuestions];
            if (!qArr[index].errors) {
              qArr[index].errors = {};
            }
            qArr[index].errors.subOptionTypeError = "This Field is Required";
            setUnPublishedQuestions(qArr);
            return;
          } else {
            const qArr = [...unPublishedQuestions];
            if (!qArr[index].errors) {
              qArr[index].errors = {};
            }
            qArr[index].errors.subOptionTypeError = null;
            setUnPublishedQuestions(qArr);
          }
        }
      }
    }

    if (
      question.questionType === "dropdown" ||
      question.questionType === "radio"
    ) {
      if (!question.isMaster) {
        for (let i = 0; i < question.optionValue.length; i++) {
          const element = question.optionValue[i];
          if (element.subOptions) {
            for (let j = 0; j < element?.subOptions.length; j++) {
              const subOptionsElement = element.subOptions[j];
              if (!subOptionsElement.isMaster) {
                const isSubOptionsElementHasEmptySubOpt =
                  subOptionsElement.subOptions &&
                  subOptionsElement.subOptions.some(
                    (sop) => sop.subOptionText === ""
                  );
                if (isSubOptionsElementHasEmptySubOpt) {
                  const qArr = [...unPublishedQuestions];
                  if (!qArr[index].errors) {
                    qArr[index].errors = {};
                  }
                  qArr[index].errors.innerTwoSubOptionTextError =
                    "This Field is Required";
                  setUnPublishedQuestions(qArr);
                  return;
                } else {
                  const qArr = [...unPublishedQuestions];
                  if (!qArr[index].errors) {
                    qArr[index].errors = {};
                  }
                  qArr[index].errors.innerTwoSubOptionTextError = null;
                  setUnPublishedQuestions(qArr);
                }
              }
            }
          }
        }
      }
    }

    let newRecord = {
      ...question,
      isRegexRequired: question.isRegexRequired && question.questionType === "input",

    };

    delete newRecord.errors;

    setLoading(true);

    const response = await dispatch(
      updateQuestionById(newRecord, question._id)
    );
    await dispatch(updateQuestionByWorkflowTeam(newRecord, promoDetails._id));

    if (response.error === undefined) {
      setLoading(false);
      setSelectedIndex("");
      dispatch(getQuestionUnPublished(id));
      setShowUpdateAlert(true);
      setUpdateAlertText(TextMessage.QUESTION_UPDATE_TEXT);
      setUpdateAlertType("success");
    } else {
      setSelectedIndex("");
      dispatch(getQuestionUnPublished(id));
      setLoading(false);
      setShowUpdateAlert(true);
      setUpdateAlertText(TextMessage.ERROR_TEXT);
      setUpdateAlertType("error");
    }
  };

  const deleteQuestion = (questionId, id) => {
    Modal.confirm({
      title: TextMessage.DELETE_PROMO_QUESTION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteQuestionYes(questionId, id),
      okType: "primary",
    });
  };

  const deleteQuestionYes = async (questionId, id) => {
    const res = await dispatch(deleteQuestionPromoType(questionId, id));
    if (res.error === undefined) {
      const index = questions.findIndex(question => question.id === id);
      if (index !== -1) {
        const questionsArr = [...questions];
        questionsArr.splice(index, 1);
        setQuestions(questionsArr);
      }
      await dispatch(getQuestionUnPublished(id));
      setShowDeleteAlert(true);
    } else {
      await dispatch(getQuestionUnPublished(id));
    }
  };

  const handlePublish = async () => {
    if (!enablePublish) {
      setShowUpdateAlert(true);
      setUpdateAlertText(
        "All teams of workflow should be assigned atlease one question"
      );
      setUpdateAlertType("error");
      return;
    }
    setPublishLoading(true);
    const response = await dispatch(markQuestionPromoTypePublish(id));
    if (response.error === undefined) {
      setPublishLoading(false);
      setShowUpdateAlert(true);
      setUpdateAlertText(TextMessage.QUESTION_PUBLISH_TEXT);
      setUpdateAlertType("success");
      await dispatch(getQuestionUnPublished(id));
    } else {
      setPublishLoading(false);
      setShowUpdateAlert(true);
      setUpdateAlertText(TextMessage.ERROR_TEXT);
      setUpdateAlertType("error");
      await dispatch(getQuestionUnPublished(id));
    }
  };

  const EditQuestion = (question, i) => {
    const questionsArr = [...unPublishedQuestions];

    if (
      question.questionType === "dropdown" ||
      question.questionType === "tabs" ||
      question.questionType === "radio" ||
      question.questionType === "checkbox" ||
      question.questionType === "multiSelect"
    ) {
      questionsArr[i] = {
        ...questionsArr[i],
        errors: {
          questionHeaderError: null,
          optionTextError: null,
          subOptionTextError: null,
          innerSubOptionTextError: null,
          innerTwoSubOptionTextError: null,
          subOptionTypeError: null,
        },
      };
      setUnPublishedQuestions(questionsArr);
    }

    if (
      question.questionType === "date" ||
      question.questionType === "input" ||
      question.questionType === "media" ||
      question.questionType === "textarea"
    ) {
      questionsArr[i] = {
        ...questionsArr[i],
        errors: { questionHeaderError: null },
      };
      setUnPublishedQuestions(questionsArr);
    }
    setSelectedIndex(i);
  };

  const handleCancelEditView = async () => {
    await dispatch(getQuestionUnPublished(id));
    setSelectedIndex("");
  };

  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };
  const enablePublish = useMemo(() => {
    if (promoDetails) {
      const standardStages = promoDetails?.standardWorkflow?.stages?.filter(
        (stage) => !stage?.teamDetails?.isVCM
      );
      const emergencyStages = promoDetails?.emergencyWorkflow?.stages?.filter(
        (stage) => !stage?.teamDetails?.isVCM
      );
      const allSTeamSelected = standardStages?.every(
        (e) =>
          promoTypeQuestions?.some(
            (q) => q?.standardWorkflowApproverTeamId === e?.teamDetails?._id
          )
      );
      const allETeamSelected = emergencyStages?.every(
        (e) =>
          promoTypeQuestions?.some(
            (q) => q?.emergencyWorkflowApproverTeamId === e?.teamDetails?._id
          )
      );
      return allSTeamSelected && allETeamSelected;
    }
    return false;
  }, [promoDetails, promoTypeQuestions]);

  const renderEditQuestion = (selectedQs, i) => {
    return (
      <div className="formBuilderFrameTwo">
        <div className={"formBuilderFrameTwoActiveDeck"}>
          <div className="formBuilderFrameTwoPromoForm">
            <div className="formBuilderFrameTwoPromoFormFrame">
              <div className="formBuilderFrameTwoPromoQuestion">
                <label className="formBuilderFrameTwoPromoLabel">
                  Question
                </label>
                <input
                  type="text"
                  className="formBuilderFrameTwoPromoQuestionInput"
                  placeholder="Write Here"
                  value={selectedQs.questionHeader}
                  onChange={(e) => handleQuestionText(e.target.value, i)}
                />
                <span className="errorLabel">
                  {selectedQs?.errors?.questionHeaderError}
                </span>
              </div>

              <div className="formBuilderFrameTwoPromoType">
                <label className="formBuilderFrameTwoPromoLabelType">
                  Type1
                </label>
                <div className="formBuilderFrameTwoPromoTypeSelect">
                  <Select
                    className="formBuilderFrameTwoPromoTypeSelectBtn"
                    value={selectedQs.questionType}
                    placeholder="Select Question Type"
                    onChange={(value) => handleQuestionType(value, i)}
                  >
                    {questionTypeOptionsChildren}
                  </Select>
                </div>
              </div>
            </div>
          </div>

          {selectedQs.questionType === "dropdown" && (
            <DropDownQuestion
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              index={i}
              questionTypeOptionsChildren={questionTypeOptionsChildren}
              handleAddSubOptions={handleAddSubOptions}
              handleDeleteOptions={handleDeleteOptions}
              handleOptionTextChange={handleOptionTextChange}
              handleSubOptionTypeChange={handleSubOptionTypeChange}
              handleDeleteSubOptions={handleDeleteSubOptions}
              handleSuboptionTextChange={handleSuboptionTextChange}
              handleInnerSubOptionTextChange={handleInnerSubOptionTextChange}
              handleAddInnerTwoSubOptions={handleAddInnerTwoSubOptions}
              handleDeleteInnerSubOptions={handleDeleteInnerSubOptions}
              handleInnerTwoSubOptionText={handleInnerTwoSubOptionText}
              handleInnerTwoSubOptionType={handleInnerTwoSubOptionType}
              handleDeleteInnerTwoSubOptions={handleDeleteInnerTwoSubOptions}
              handleAddMoreInnerSubOptions={handleAddMoreInnerSubOptions}
              handleAddMoreSubOptions={handleAddMoreSubOptions}
              handleAddOptions={handleAddOptions}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleFormBuilderSelectMasterSubOption={
                handleFormBuilderSelectMasterSubOption
              }
              handleFormBuilderSelectMasterOption={
                handleFormBuilderSelectMasterOption
              }
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
              onRegexCheckboxChange={onRegexCheckboxChange}
              handleRegexChange={handleRegexChange}
            />
          )}

          {selectedQs.questionType === "checkbox" && (
            <CheckBoxQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              handleDeleteOptionsCheckbox={handleDeleteOptionsCheckbox}
              handleOptionTextChangeCheckbox={handleOptionTextChangeCheckbox}
              handleAddOptionsCheckbox={handleAddOptionsCheckbox}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
            />
          )}

          {selectedQs.questionType === "date" && (
            <DateQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
            />
          )}
          {selectedQs.questionType === "multiSelect" && (
            <MultiSelectQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleDeleteOptions={handleDeleteOptions}
              handleOptionTextChange={handleOptionTextChange}
              handleAddOptions={handleAddOptions}
              handleFormBuilderSelectMasterOption={
                handleFormBuilderSelectMasterOption
              }
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
              onRegexCheckboxChange={onRegexCheckboxChange}
              handleRegexChange={handleRegexChange}
            />
          )}

          {selectedQs.questionType === "tabs" && (
            <TabQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              handleAddSubOptionsTab={handleAddSubOptionsTab}
              handleAddOptionsTab={handleAddOptionsTab}
              handleDeleteOptionsTab={handleDeleteOptionsTab}
              handleOptionTextChangeTab={handleOptionTextChangeTab}
              handleSubOptionTypeChangeTab={handleSubOptionTypeChange}
              handleDeleteSubOptionsTab={handleDeleteSubOptionsTab}
              handleSuboptionTextChangeTab={handleSuboptionTextChangeTab}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
              isTab={"true"}
              questionTypeOptionsChildren={
                questionTypeOptionsChildren
              }
              deleteQuestion={deleteQuestion}
              copyQuestion={copyQuestion}
              handleAddSubOptions={handleAddSubOptions}
              handleDeleteOptions={handleDeleteOptions}
              handleOptionTextChange={handleOptionTextChange}
              handleSubOptionTypeChange={handleSubOptionTypeChange}
              handleDeleteSubOptions={handleDeleteSubOptions}
              handleSuboptionTextChange={handleSuboptionTextChange}
              handleInnerSubOptionTextChange={
                handleInnerSubOptionTextChange
              }
              handleAddInnerTwoSubOptions={
                handleAddInnerTwoSubOptions
              }
              handleDeleteInnerSubOptions={
                handleDeleteInnerSubOptions
              }
              handleInnerTwoSubOptionText={
                handleInnerTwoSubOptionText
              }
              handleInnerTwoSubOptionType={
                handleInnerTwoSubOptionType
              }
              handleDeleteInnerTwoSubOptions={
                handleDeleteInnerTwoSubOptions
              }
              handleAddMoreInnerSubOptions={
                handleAddMoreInnerSubOptions
              }
              handleAddMoreSubOptions={handleAddMoreSubOptions}
              handleAddOptions={handleAddOptions}
              handleFormBuilderSelectMasterSubOption={
                handleFormBuilderSelectMasterSubOption
              }
              handleFormBuilderSelectMasterOption={
                handleFormBuilderSelectMasterOption
              }
              onRegexCheckboxChange={onRegexCheckboxChange}
              handleRegexChange={handleRegexChange}
            />
          )}

          {selectedQs.questionType === "input" && (
            <ShortAnswerQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              handleAddOptionsShortAnswer={handleAddOptionsShortAnswer}
              handleDeleteOptionsShortAnswer={handleDeleteOptionsShortAnswer}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
              onRegexCheckboxChange={onRegexCheckboxChange}
              handleRegexChange={handleRegexChange}
            />
          )}
          {selectedQs.questionType === "media" && (
            <MediaAnswerQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              handleAddOptionsShortAnswer={handleAddOptionsShortAnswer}
              handleDeleteOptionsShortAnswer={handleDeleteOptionsShortAnswer}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
              onRegexCheckboxChange={onRegexCheckboxChange}
              handleRegexChange={handleRegexChange}
            />
          )}

          {selectedQs.questionType === "textarea" && (
            <TextAreaQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              handleAddOptionsTextArea={handleAddOptionsTextArea}
              handleDeleteOptionsTextArea={handleDeleteOptionsTextArea}
              handleOptionTextChangeTextArea={handleOptionTextChangeTextArea}
              index={i}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
            />
          )}

          {selectedQs.questionType === "radio" && (
            <MultipleChoiceQuestionView
              question={selectedQs}
              handleCancelEditView={handleCancelEditView}
              handleDeleteOptions={handleDeleteOptions}
              handleOptionTextChange={handleOptionTextChange}
              handleAddOptions={handleAddOptions}
              index={i}
              handleSubOptionTypeChange={handleSubOptionTypeChange}
              handleAddSubOptions={handleAddSubOptions}
              handleDeleteSubOptions={handleDeleteSubOptions}
              handleSuboptionTextChange={handleSuboptionTextChange}
              handleInnerSubOptionTextChange={handleInnerSubOptionTextChange}
              handleAddInnerTwoSubOptions={handleAddInnerTwoSubOptions}
              handleDeleteInnerSubOptions={handleDeleteInnerSubOptions}
              handleInnerTwoSubOptionText={handleInnerTwoSubOptionText}
              handleInnerTwoSubOptionType={handleInnerTwoSubOptionType}
              handleDeleteInnerTwoSubOptions={handleDeleteInnerTwoSubOptions}
              handleAddMoreInnerSubOptions={handleAddMoreInnerSubOptions}
              handleAddMoreSubOptions={handleAddMoreSubOptions}
              questionTypeOptionsChildren={questionTypeOptionsChildren}
              handleSaveQuestion={handleSaveQuestion}
              loading={loading}
              handleFormBuilderSelectMasterSubOption={
                handleFormBuilderSelectMasterSubOption
              }
              handleFormBuilderSelectMasterOption={
                handleFormBuilderSelectMasterOption
              }
              handleQuestionDesignate={handleQuestionDesignate}
              handleStandardTeamChange={handleStandardTeamChange}
              handleEmergencyTeamChange={handleEmergencyTeamChange}
              onRegexCheckboxChange={onRegexCheckboxChange}
              handleRegexChange={handleRegexChange}
            />
          )}
        </div>
      </div>
    );
  };

  const handleRouteChange = (e) => {
    e.preventDefault();
    navigate(e.target.value);
  };
  return (
    <CommonLayout
      breadCrumbText="Promo Type Form"
      backButton={true}
      backUrl={"/promo-types"}
    >
      {loadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <div className="formBuilderBody">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "600px",
            }}
          >
            <div className="input-frame">
              <label className="input-label" style={{ fontWeight: 500 }}>
                Promo Type Name
              </label>
              {promoDetails?.promoType}
            </div>
            <div>
              <label className="input-label" style={{ fontWeight: 500 }}>
                Standard Workflow
              </label>
              {promoDetails?.standardWorkflow?.name}
            </div>
            <div>
              <label className="input-label" style={{ fontWeight: 500 }}>
                Emergency Workflow
              </label>
              {promoDetails?.emergencyWorkflow?.name}
            </div>
          </div>
          <div className="formBuilderFrameWrapper">
            <div className="formBuilderContentWrapper">
              <div className="formBuilderFrameOne">
                <div className="formBuilderFrameOneLeft">
                  <Radio.Group
                    value={`/promo-types/form-builder/view/${id}`}
                    onChange={handleRouteChange}
                  >
                    <Radio.Button value={`/promo-types/form-builder/${id}`}>
                      Editing
                    </Radio.Button>
                    <Radio.Button
                      value={`/promo-types/form-builder/view/${id}`}
                      style={{
                        background: "#0F2A6F",
                        borderColor: "#0F2A6F",
                        color: "white",
                      }}
                    >
                      Viewing
                    </Radio.Button>
                    <Radio.Button
                      value={`/promo-types/form-builder/publish/${id}`}
                    >
                      Published
                    </Radio.Button>
                  </Radio.Group>
                </div>

                <div className="formBuilderFrameOneRight">
                  <button
                    className={
                      unPublishedQuestions.length === 0 || publishLoading
                        ? "formBuilderFrameOnePublishBtn"
                        : "formBuilderFrameOnePublishBtnEnable"
                    }
                    disabled={
                      unPublishedQuestions.length === 0 || publishLoading
                    }
                    onClick={handlePublish}
                  >
                    {publishLoading ? "Saving" : "Publish"}
                  </button>
                </div>
              </div>
            </div>

            <div className="formBuilderViewWrapper">
              {unPublishedQuestions.length > 0
                ? unPublishedQuestions.map((question, i) => {
                  if (i === selectedIndex) {
                    return renderEditQuestion(question, i);
                  }

                  return (
                    <>
                      <div className="formBuilderViewContent" key={i}>
                        <div className="w-100 formBuilderViewLabelWrapper">
                          <label className="saveQuestionLabel">
                            {question.questionHeader}
                          </label>
                          <div className="formBuilderViewBtnGrp">
                            <EditOutlined
                              onClick={() => EditQuestion(question, i)}
                              className="formBuilderViewEditBtn"
                            />

                            <DeleteOutlined
                              className="cp"
                              onClick={() => deleteQuestion(question._id, id)}
                            />
                          </div>
                        </div>
                        <div className="savedQuesOptionDiv">
                          {question.questionType === "radio" &&
                            RadioOptions(question?.optionValue)}
                          {question.questionType === "checkbox" &&
                            CheckBoxOptions(question?.optionValue)}
                          {question.questionType === "dropdown" &&
                            DropdownOptions()}
                          {question.questionType === "textarea" &&
                            TextareaOptions()}
                          {question.questionType === "input" ||
                            (question.questionType === "media" &&
                              ShortAnswerOptions())}
                          {question.questionType === "date" && DateOptions()}
                          {question.questionType === "tabs" &&
                            TabOptions(question?.optionValue)}
                          {question.questionType === "multiSelect" &&
                            MultiSelectOptions()}
                        </div>
                      </div>
                    </>
                  );
                })
                : ""}
            </div>
          </div>

          {showDeleteAlert && (
            <Alert
              message={TextMessage.QUESTION_DELETE_TEXT}
              type="error"
              showIcon
              closable
              onClose={handleDeleteAlertClose}
              icon={<DeleteOutlined />}
              className="alertStyle"
            />
          )}

          {showUpdateAlert && (
            <Alert
              message={updateAlertText}
              type={updateAlertType}
              showIcon
              closable
              onClose={handleUpdateAlertClose}
              className="alertStyle"
            />
          )}
        </div>
      )}
    </CommonLayout>
  );
};

export default PromoTypeFormBuilderView;
