import Icon, {
  AlignLeftOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  DownCircleOutlined,
  LineOutlined,
  SelectOutlined,
  SwapOutlined,
  FileOutlined,
} from "@ant-design/icons";

export const NOT_AUTHORIZED = 401;
export const UNAUTHORIZED_ACTION_TYPES = [
  "LOGIN",
  "FORGOT_PASSWORD",
  "RESET_PASSWORD",
];
export const FIELD_REQUIRED = "This field is required.";
export const CONFRIM_MATCH = "Confirm password needs to match password";
export const VALUE_TOO_SHORT = "Given value is too short";
export const VALUE_TOO_LONG = "Given value is too long";
export const EMAIL_NOT_VALID = "Email is not valid";
export const INVALID_VALUE = "Invalid value";
export const INVALID_QUANTITY = "Invalid Quantity";
export const INVALID_QUANTITY_MAX = "Quantity should be less than < 100000";
export const SUCCESS_OK = 200;
export const LIVE_ERRORS = "Live-Errors";
export const PENDING_ERRORS = "Pending-Errors";
export const SUBMITTED_ERRORS = "Submitted-Errors";
export const INCOMPLETE_ERRORS = "Incomplete-Errors";
export const APPROVED_ERRORS = "Approved-Errors";
export const EXPIRED_ERRORS = "Expired-Errors";

export const NO_STATUS_CODE = 0;

export const filterSelectOptions = [
  {
    value: "Promotions",
    _id: "promo",
  },
  {
    value: "Shelf",
    _id: "shelf",
  },
  {
    value: "User",
    _id: "user",
  },
  {
    value: "Promo Type",
    _id: "promoType",
  },
  {
    value: "Error Report",
    _id: "report",
  },
  {
    value: "Form Builder",
    _id: "formbuilder",
  },

  {
    value: "Promo Question",
    _id: "questionPromo",
  },
  {
    value: "Notifications",
    _id: "notification",
  },
  {
    value: "MAP",
    _id: "map",
  },
  {
    value: "All",
    _id: "all",
  },
];

export const SECONDS = 3000;
export const MAP_SECONDS = 8000;

const CircleSvg = () => (
  <svg width="14" height="14" viewBox="0 0 32 32" fill="none">
    <path
      d="M16 0.25C7.30234 0.25 0.25 7.30234 0.25 16C0.25 24.6977 7.30234 31.75 16 31.75C24.6977 31.75 31.75 24.6977 31.75 16C31.75 7.30234 24.6977 0.25 16 0.25ZM16 29.0781C8.77891 29.0781 2.92188 23.2211 2.92188 16C2.92188 8.77891 8.77891 2.92188 16 2.92188C23.2211 2.92188 29.0781 8.77891 29.0781 16C29.0781 23.2211 23.2211 29.0781 16 29.0781Z"
      fill="black"
      fillOpacity="0.85"
    />
  </svg>
);

export const CircleTypeIcon = (props) => (
  <Icon component={CircleSvg} {...props} />
);

const RadioSvg = () => (
  <svg width="14" height="14" viewBox="0 0 12 12" fill="none">
    <rect x="4" y="4" width="4" height="4" rx="2" fill="#0F2A6F" />
    <path
      d="M1.25 6C1.25 3.37665 3.37665 1.25 6 1.25C8.62335 1.25 10.75 3.37665 10.75 6C10.75 8.62335 8.62335 10.75 6 10.75C3.37665 10.75 1.25 8.62335 1.25 6Z"
      stroke="#0F2A6F"
    />
  </svg>
);

export const RadioTypeIcon = (props) => (
  <Icon component={RadioSvg} {...props} />
);

export const questionTypesSelectOption = [
  {
    _id: "dropdown",
    value: `Dropdown`,
    icon: <DownCircleOutlined />,
  },
  {
    _id: "textarea",
    value: `Textarea`,
    icon: <AlignLeftOutlined />,
  },
  {
    _id: "input",
    value: `Short Answer`,
    icon: <LineOutlined />,
  },
  {
    _id: "checkbox",
    value: `Checkboxes`,
    icon: <CheckSquareOutlined />,
  },
  {
    _id: "radio",
    value: `Multiple Choice`,
    icon: <RadioTypeIcon />,
  },
  {
    _id: "date",
    value: `Date Range`,
    icon: <CalendarOutlined />,
  },
  {
    _id: "tabs",
    value: `Tabs`,
    icon: <SwapOutlined />,
  },
  {
    _id: "multiSelect",
    value: `Multi Select`,
    icon: <SelectOutlined />,
  },
  {
    _id: "media",
    value: `Document`,
    icon: <FileOutlined />,
  },
];

export const DATE_FORMAT = "MM/DD/YYYY";
export const MAP_DATE_FORMAT = "MM/DD/YYYY";
export const DATE_FORMAT_DASH = "MM/DD/YYYY";
export const DATE_FILTER_FORMAT = "MM/DD/YYYY";

export const showQuestionError = (question) => {
  if (question?.errors?.optionTextError !== null) {
    return (
      <span className="errorLabel">{question?.errors?.optionTextError}</span>
    );
  }
  if (question?.errors?.subOptionTextError !== null) {
    return <span className="errorLabel">Sub Option Required</span>;
  }
  if (
    question.questionType !== "tabs" &&
    question?.errors?.innerTwoSubOptionTextError !== null
  ) {
    return <span className="errorLabel">Inner Sub Option Required</span>;
  }
  if (question?.errors?.subOptionTypeError !== null) {
    return <span className="errorLabel">Sub Option Type Required</span>;
  }
};

export const TextMessage = {
  ERROR_TEXT: "Error Occured.Try again",
  CONFIRMATION_TEXT: "Are you sure you want to delete this record?",
  UNASSIGN_CONFIRMATION_TEXT: "Are you sure you want to unassign this user?",
  USER_CREATE_TEXT: "User has been created successfully",
  USER_UPDATE_TEXT: "User has been updated successfully",
  USER_ASSIGNED_TEXT: "User Assigned successfully",
  USER_DELETE_TEXT: "User has been deleted successfully",
  USER_UNASSIGN_TEXT: "User has been unassign successfully",
  USERS_DELETE_TEXT: "User has been deleted successfully",
  SHELF_CREATE_TEXT: "Shelf has been created successfully",
  SHELF_UPDATE_TEXT: "Shelf has been updated successfully",
  SHELF_DELETE_TEXT: "Shelf has been deleted successfully",
  SHELF_FILE_TEXT: "Shelf data upload is in progress",
  ERROR_TICKET_DELETE_TEXT: "Ticket has been deleted successfully",
  ERROR_TICKET_RESOLVE_TEXT: "Ticket has been cancelled",
  ERROR_TICKET_REOPEN_TEXT: "Ticket has been re-opened",
  PROMOTYPE_CREATE_TEXT: "Promo type has been created successfully",
  PROMOTYPE_UPDATE_TEXT: "Promo type has been updated successfully",
  PROMOTYPE_DELETE_TEXT: "Promo type has been deleted successfully",
  CONFIRM_DELETE_PROMOTYPE_TEXT:
    "Are you sure you want to delete this Promo Type?",
  TEAM_CREATE_TEXT: "Team has been created successfully",
  EVENT_CREATE_TEXT: "Event has been created successfully",
  EVENT_UPDATE_TEXT: "Event has been updated successfully",
  ADSET_CREATE_TEXT: "Adset has been created successfully",
  ADSET_UPDATE_TEXT: "Adset has been updated successfully",
  TEAM_UPDATE_TEXT: "Team has been updated successfully",
  TEAM_DELETE_TEXT: "Team has been deleted successfully",
  ERROR_TYPE_DELETE_TEXT: "Change type has been deleted successfully",
  ADSET_DELETE_TEXT: "Adset has been deleted successfully",
  EVENT_DELETE_TEXT: "Event has been deleted successfully",
  TEAM_DELETE_MESSAGE:
    "Users must be unassigned or reassigned before deleting team",
  QUESTION_CREATE_TEXT: "Question has been created successfully",
  QUESTION_UPDATE_TEXT: "Question has been updated successfully",
  QUESTION_DELETE_TEXT: "Question has been deleted successfully",
  QUESTION_PUBLISH_TEXT: "Question has been published successfully",
  QUESTION_UNPUBLISH_TEXT: "Question has been unpublished successfully",
  PROMOTION_CREATE_TEXT: "Promo has been created successfully",
  PROMOTION_UPDATE_TEXT: "Promo has been updated successfully",
  PROMOTION_DELETE_TEXT: "Promo has been deleted successfully",
  PROMOTION_APPROVE_TEXT: "Promo has been approved",
  PROMOTION_DECLINE_TEXT: "Promo has been rejected",
  REQUIRED_ERROR_TEXT: "Please fill all required fields",
  PROFILE_ERROR_TEXT: "Please enter valid firstname and lastname",
  SHELF_EXPORT_TEXT:
    "Your export is in progress, it will be send to your registered email id once it is completed.",
  MAP_APPROVAL_TEXT: "Enter Approval for MAP Hold Duration",
  MAP_PRICE_REVIEW_TEXT: "Enter MAP Price Load",
  MAP_CREATE_TEXT: "MAP has been created successfully",
  MAP_DELETE_TEXT: "MAP has been deleted successfully",
  MAP_VALIDATE_TEXT: "Fill up the mismatch MAP price value for SKU",
  MAP_APPROVED_TEXT: "MAP has been approved",
  MAP_DECLINED_TEXT: "MAP has been rejected",
  MAP_APPROVAL_CONFIRMATION_TEXT:
    "Are you sure you want to approve this record?",
  PASSWORD_CHANGED_TEXT: "Password has been changed successfully",
  DELETE_PROMO_QUESTION_TEXT: "Are you sure, you want to delete this question?",
  UNPUBLISH_PROMO_QUESTION_TEXT:
    "Are you sure, you want to unpublish this question",
  REPORT_DELETE_TEXT: "Report has been deleted successfully",
  REPORT_CLONE_TEXT: "Report has been cloned successfully",
  REPORT_CLONE_CONFIRM_TEXT: "Are you sure, you want to clone this report ?"
};

const CircleTickSvg = () => (
  <svg
    viewBox="64 64 896 896"
    focusable="false"
    width="1em"
    height="1em"
    fill="#52C41A"
    aria-hidden="true"
  >
    <path
      d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 585.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"
      fill="white"
    ></path>
    <path
      d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
      fill="#52C41A"
    ></path>
  </svg>
);

export const CircleTickIcon = (props) => (
  <Icon component={CircleTickSvg} {...props} />
);

export const mapTypes = [
  {
    type: "SKU",
    _id: "1",
  },
  {
    type: "Brand",
    _id: "2",
  },
];

export const mapQuestionTypesSelectOption = [
  {
    _id: "dropdown",
    value: `Dropdown`,
    icon: <DownCircleOutlined />,
  },
  {
    _id: "textarea",
    value: `Textarea`,
    icon: <AlignLeftOutlined />,
  },
  {
    _id: "input",
    value: `Short Answer`,
    icon: <LineOutlined />,
  },
  {
    _id: "checkbox",
    value: `Checkboxes`,
    icon: <CheckSquareOutlined />,
  },
  {
    _id: "radio",
    value: `Multiple Choice`,
    icon: <RadioTypeIcon />,
  },
  {
    _id: "datePicker",
    value: `Date Picker`,
    icon: <CalendarOutlined />,
  },
  {
    _id: "tabs",
    value: `Tabs`,
    icon: <SwapOutlined />,
  },
  {
    _id: "multiSelect",
    value: `Multi Select`,
    icon: <SelectOutlined />,
  },
  {
    _id: "date",
    value: `Date Range`,
    icon: <CalendarOutlined />,
  },
];

export const priorities = [
  {
    label: "Urgent",
    value: "urgent",
  },
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
];

export const workflow = [
  {
    label: "Workflow completed 100%",
    value: "100",
  },
  {
    label: "Workflow completed >=75%",
    value: "75",
  },
  {
    label: "Workflow completed >=50%",
    value: "50",
  },
  {
    label: "Workflow completed >=25%",
    value: "25",
  },
];
export const timeline = [
  {
    _id: "65a9514b7c15ad83b1fb06fb",
    promoId: "62faab0f49d056c5ebd695c8",
    status: "Approved",
    teamId: {
      _id: "6582f520a596b2fab206ccfd",
      name: "Iron",
    },
    createdBy: "62cfac171b72ec87c4a73058",
    createdAt: "2024-01-18T16:26:51.112Z",
    updatedAt: "2024-01-18T16:26:51.112Z",
    __v: 0,
  },
  {
    _id: "65aeb645f6ec64f59bd0e83d",
    promoId: "62faab0f49d056c5ebd695c8",
    status: "Rejected",
    teamId: {
      _id: "658451c9cba2f51a9b35dbf7",
      name: "team1",
    },
    createdBy: "62cfac171b72ec87c4a73058",
    createdAt: "2024-01-22T18:39:01.661Z",
    updatedAt: "2024-01-22T18:39:01.661Z",
    __v: 0,
  },
  {
    _id: "65aeb6a9f6ec64f59bd0ee31",
    promoId: "62faab0f49d056c5ebd695c8",
    status: "Submitted",
    teamId: {
      _id: "658451d1cba2f51a9b35dbff",
      name: "team3",
    },
    createdBy: "62cfac171b72ec87c4a73058",
    createdAt: "2024-01-22T18:40:41.431Z",
    updatedAt: "2024-01-22T18:40:41.431Z",
    __v: 0,
  },
  {
    _id: "65aeb6c23281bbf48852545c",
    promoId: "62faab0f49d056c5ebd695c8",
    status: "Error Ticket",
    teamId: {
      _id: "658451d1cba2f51a9b35dbff",
      name: "team3",
    },
    createdBy: "62cfac171b72ec87c4a73058",
    createdAt: "2024-01-22T18:40:41.431Z",
    updatedAt: "2024-01-22T18:40:41.431Z",
    __v: 0,
  },
];

export const SuccessSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      cx="12"
      cy="12"
      r="11.75"
      fill="#F1FFF7"
      stroke="#27AE60"
      stroke-width="0.5"
    />
    <circle cx="12" cy="12" r="4" fill="#27AE60" />
  </svg>
);

export const SubmitSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      cx="12"
      cy="12"
      r="11.75"
      fill="#F6F6F6"
      stroke="#828282"
      stroke-width="0.5"
    />
    <circle cx="12" cy="12" r="4" fill="#828282" />
  </svg>
);

export const ErrorSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      cx="12"
      cy="12"
      r="11.75"
      fill="#FFF8F2"
      stroke="#F2994A"
      stroke-width="0.5"
    />
    <circle cx="12" cy="12" r="4" fill="#F2994A" />
  </svg>
);

export const RejectSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      cx="12"
      cy="12"
      r="11.75"
      fill="#FFF2F2"
      stroke="#EB5757"
      stroke-width="0.5"
    />
    <circle cx="12" cy="12" r="3.5" fill="#EB5757" stroke="#EB5757" />
  </svg>
);

export const QuestionStatus = {
  Approved: "Approved",
  Error: "Error Ticket",
  Incomplete: "Incomplete",
  Rejected: "Rejected",
  Submitted: "Submitted",
};

export const xmlDataInitial = `
<root>
</root>
`;