import { S } from "utils/prefix";
import * as ActionTypes from "actions/workflow/action-types";
import WorkflowStateManager from "./state-manager";

const INITIAL_STATE = {
  workflows: [],
  pagination: undefined,
  total: 0,
};

export default function WorkflowStates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case S(ActionTypes.GET_WORKFLOW_LIST):
      return WorkflowStateManager.getAllData(state, action);
    default:
      return state;
  }
}
