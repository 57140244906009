import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/error-type/action-types'
import TeamsStateManager    from './state-manager';

const INITIAL_STATE = {
  errors: [],
  pagination: undefined,
  total: 0,
//   currentTeam: undefined,
//   showUnassignButton: false,
//   newSelectedRowKeys: []
};

export default function ErrorsStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case S(ActionTypes.GET_ALL_ERRORS):
                return TeamsStateManager.getAllErrors(state,action);
        case S(ActionTypes.CREATE_ERROR_TYPE):
                return TeamsStateManager.createTeam(state,action);
        case S(ActionTypes.UPDATE_ERROR_TYPE):
                return TeamsStateManager.updateTeam(state,action);
        case S(ActionTypes.DELETE_TEAM):
                return TeamsStateManager.deleteTeam(state,action);
        case S(ActionTypes.UPDATE_ERROR_STATUS):
                return TeamsStateManager.updateErrorStatus(state,action);
        case S(ActionTypes.UPDATE_TEAM_STATUS):
                return TeamsStateManager.updateTeamStatus(state,action);
        case ActionTypes.UPDATE_LOCAL_CURRENT_TEAM:
                return { ...state, currentTeam: action.team };
        case S(ActionTypes.GET_TEAM_BY_ID):
                return TeamsStateManager.getTeam(state,action);
        case ActionTypes.SHOW_UNASSIGN_BUTTON:
                return { ...state, showUnassignButton: action.data?.flag, newSelectedRowKeys: action.data?.newSelectedRowKeys };
        default:
            return state;
    }
}