import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Select, Input } from "antd";
const { Option } = Select;

const DropdownElement = (props) => {
    const [value, setValue] = useState(props.ans ? props.ans : null);
    const [subInputValue, setSubInputValue] = useState(null);
    const [pricePointValue, setPricePointValue] = useState(null);
    const [numberValue, setNumberValue] = useState(null);
    const [numberGetValue, setNumberGetValue] = useState(null);
    const [offValue, setOffValue] = useState(null);
    const [isPricePoint, setIsPricePoint] = useState(false);
    const [isPerecentOff, setIsPerecentOff] = useState(false);
    const [isNumberToBuy, setIsNumberToBuy] = useState(false);
    const [isNumberToGet, setIsNumberToGet] = useState(false);
    const [formError, setFormError] = useState({});
    const [simplePlaceHolderText, setSimplePlaceHolderText] = useState(null);
    const [inputLabelText, setInputLabelText] = useState(null);

    const onChange = (e) => {
        setValue(e);
        props.onChange(e, props?.id, props?.label);
        if (props.promoType == 'simple') {
            setSubInputValue(null);
            setIsPricePoint(false);
            setIsNumberToBuy(false);
            switch (e) {
                case '01 - Dollars off - Input $ value only':
                    setSimplePlaceHolderText('Input $ value only');
                    setInputLabelText('Dollars off');
                    break;
                case '02 - Percentage off - Input % value only':
                    setSimplePlaceHolderText('Input % value only');
                    setInputLabelText('Percentage Off');
                    break;
                case '04 - PP with Save Up To (Single Fixed Price Point) - Input the price point':
                    setSimplePlaceHolderText('Input the price point');
                    setInputLabelText('Price Point');
                    break;
                case '05 - Buy QTYX for PP Grp':
                    setIsPricePoint(true);
                    setIsNumberToBuy(true);
                    break;
                case '06 - As advertised':
                    setSimplePlaceHolderText('Input value');
                    setInputLabelText('Advertised Value');
                    break;
                default:
                    setInputLabelText(null);
                    setSimplePlaceHolderText(null);
                    break;
            }
        } else {
            setIsPricePoint(false);
            setIsPerecentOff(false);
            setIsNumberToBuy(false);
            setIsNumberToGet(false);
            setSubInputValue(null);
            setPricePointValue(null);
            setNumberValue(null);
            setNumberGetValue(null);
            setOffValue(null);
            switch (e) {
                case '19 - BX or More GX Pct Off':
                    setIsNumberToBuy(true);
                    setIsPerecentOff(true);
                    break;
                case '20 - BX or More GX at PP Ea':
                    setIsNumberToBuy(true);
                    setIsPricePoint(true);
                    break;
                case '21 - BX or More GX PP Grp':
                    setIsNumberToBuy(true);
                    setIsPricePoint(true);
                    break;
                case '10 - BXGX Free':
                    setIsNumberToBuy(true);
                    setIsNumberToGet(true);
                    break;
                case '12 - BXGX Percent Off':
                    setIsPerecentOff(true);
                    setIsNumberToBuy(true);
                    setIsNumberToGet(true);
                    break;
                case '13 - BXGY Free':
                    setIsNumberToBuy(true);
                    setIsNumberToGet(true);
                    break;
                case '15 - BXGY Percent Off':
                    setIsPerecentOff(true);
                    setIsNumberToBuy(true);
                    setIsNumberToGet(true);
                    break;
                default:
                    break;
            }
        }

    }

    const handleKeyDown = (e) => {
        if (!/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
            e.preventDefault();
        }
    };

    const validPercentagePattern = /^\d*$/;
    const validPricePattern = /^\d*\.?\d{0,2}$/;

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        if (inputLabelText === 'Percentage Off') {
            if (validPercentagePattern.test(inputValue)) {
                setSubInputValue(inputValue);
                props.handleSubOptionChange(props?.id, inputLabelText, e.target.value, value);
            }
        }
        else if (validPricePattern.test(inputValue)) {
            setSubInputValue(inputValue);
            props.handleSubOptionChange(props?.id, inputLabelText, e.target.value, value);
        }
    };

    const handlePricePointChange = (e) => {
        const inputValue = e.target.value;
        if (validPricePattern.test(inputValue)) {
            setPricePointValue(inputValue);
            props.handleSubOptionChange(props?.id, 'Price Point', e.target.value, value)
        }
    };

    const handleNumberChange = (e) => {
        const inputValue = e.target.value;
        if (validPercentagePattern.test(inputValue)) {
            setNumberValue(inputValue);
            props.handleSubOptionChange(props?.id, 'Number to Buy', e.target.value, value)
        }
    };

    const handleNumberGetChange = (e) => {
        const inputValue = e.target.value;
        if (validPercentagePattern.test(inputValue)) {
            setNumberGetValue(inputValue);
            props.handleSubOptionChange(props?.id, 'Number to Get', e.target.value, value)
        }
    };

    const handleOffValueChange = (e) => {
        const inputValue = e.target.value;
        if (validPercentagePattern.test(inputValue)) {
            setOffValue(inputValue);
            props.handleSubOptionChange(props?.id, 'Percentage Off', e.target.value, value)
        }
    };

    useEffect(() => {
        const option = props?.options?.find(item => item.optionText == props.ans)
        if (option) {
            option?.subOptions?.forEach(element => {
                if (props.promoType == 'simple' && element.subOptionText == 'Dollars off' || element.subOptionText == 'Percentage Off' || element.subOptionText == 'Price Point' || element.subOptionText == 'Advertised Value') {
                    setSubInputValue(element.subOptionValue)
                    switch (element.subOptionText) {
                        case "Dollars off":
                            setSimplePlaceHolderText('Input $ value only');
                            setInputLabelText('Dollars off');
                            break;
                        case "Percentage Off":
                            setSimplePlaceHolderText('Input % value only');
                            setInputLabelText('Percentage Off');
                            break;
                        case "Price Point":
                            setSimplePlaceHolderText('Input the price point');
                            setInputLabelText('Price Point');
                            break;
                        case "Advertised Value":
                            setSimplePlaceHolderText('Advertised Value');
                            setInputLabelText('Advertised Value');
                            break;
                        default:
                            break;
                    }
                } else if (element.subOptionText == 'Price Point') {
                    setPricePointValue(element.subOptionValue)
                    setIsPricePoint(true)
                } else if (element.subOptionText == 'Number to Buy') {
                    setNumberValue(element.subOptionValue)
                    setIsNumberToBuy(true)
                } else if (element.subOptionText == 'Number to Get') {
                    setNumberGetValue(element.subOptionValue)
                    setIsNumberToGet(true)
                } else if (element.subOptionText == 'Percentage Off') {
                    setOffValue(element.subOptionValue)
                    setIsPerecentOff(true)
                }
                if (element.subOptionText === 'Price Point' && element.subOptionValue) {
                    setPricePointValue(element.subOptionValue);
                    setIsPricePoint(true)
                }

                if (element.subOptionText === 'Percentage Off' && element.subOptionValue) {
                    setOffValue(element.subOptionValue)
                    setIsPerecentOff(true)
                }

                if (element.subOptionText === 'Number to Buy' && element.subOptionValue) {
                    setNumberValue(element.subOptionValue)
                    setIsNumberToBuy(true)
                }

                if (element.subOptionText === 'Number to Get' && element.subOptionValue) {
                    setNumberGetValue(element.subOptionValue)
                    setIsNumberToGet(true)
                }
            });
        }
    }, [])

    return (
        <>
            <div className="input-frame">
                <label className="input-label">
                    {props.designate && props.designate === "required" ? (
                        <span className="spanReq">*</span>
                    ) : (
                        ""
                    )}
                    {props.label}
                </label>
                {props.action === "create" && (
                    <div className="select-dropdown">
                        <Select
                            className="select-dropdown"
                            onChange={onChange}
                            value={value}
                            placeholder="Select"
                        >
                            {props.options?.map((item) => (
                                <Option key={item.optionText}>
                                    {item.optionText}
                                </Option>
                            ))}
                        </Select>
                        <span className="error-label">{props.error}</span>
                    </div>

                )}

                {props.action === "edit" && (
                    <div className="select-dropdown">
                        <Select
                            className="select-dropdown"
                            onChange={onChange}
                            value={value}
                            placeholder="Select"
                        >
                            {props.options?.map((item) => (
                                <Option key={item.optionText}>
                                    {item.optionText}
                                </Option>
                            ))}
                        </Select>
                        <span className="error-label">{props.error}</span>
                    </div>
                )}
                {props.action === "view" && (
                    <div className="select-dropdown">
                        <Select className="select-dropdown" value={props.ans} disabled>
                            {props.options?.map((item) => (
                                <Option key={item.optionText}>{item.option}</Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>
            {value && props.label == "Price format and offer price" && props.promoType == 'simple' && !(value == '06 - As advertised' || value == '05 - Buy QTYX for PP Grp') && <div style={{ marginBottom: "15px" }}>
                <label className="input-label">
                    {props.designate && props.designate === "required" ? (
                        <span className="spanReq">*</span>
                    ) : (
                        ""
                    )}
                    {simplePlaceHolderText}
                </label>
                <Input
                    className="input-text-field"
                    onChange={(e) => {
                        const value = e.target.value;
                        const numericValue = parseFloat(value);
                        if (inputLabelText === 'Percentage Off') {
                            if ((numericValue >= 0 && numericValue <= 100) || value === "") {
                                handleInputChange(e);
                            }
                        } else {
                            if (numericValue >= 0 || value === "") {
                                handleInputChange(e);
                            }
                        }
                    }}
                    onKeyDown={inputLabelText === 'Price Point' || inputLabelText === 'Dollars off' ? null : handleKeyDown}
                    value={subInputValue}
                    type="Number"
                    min={0}
                    placeholder={simplePlaceHolderText}
                    disabled={props.action === "view"}
                />
            </div>}
            {value && props.label == "Price format and offer price" && props.promoType == 'simple' && value == '05 - Buy QTYX for PP Grp' && <div style={{ marginBottom: "15px" }} className="flex space-between gap-16 ">
                {isPricePoint && (<div className="width-50">
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}
                        Input the price point
                    </label>
                    <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                                handlePricePointChange(e);
                            }
                        }}
                        value={pricePointValue} type="Number" min={0}
                        placeholder="Input the price point" disabled={props.action == "view"}
                    />

                </div>)}
                {isNumberToBuy && (<div className="width-50">
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}
                        Input the number to Buy
                    </label>
                    <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                                handleNumberChange(e);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        value={numberValue} type="Number" min={0}
                        placeholder="Input the number to Buy" disabled={props.action == "view"}
                    />

                </div>)}
            </div>}
            {value && props.label == "Price format and offer price" && props.promoType == 'multi-buy' && <div style={{ marginBottom: "15px" }} className="flex space-between gap-16 ">
                {isPricePoint && (<div className="width-50">
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}
                        Input the price point
                    </label>
                    <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                                handlePricePointChange(e);
                            }
                        }}
                        value={pricePointValue} type="Number" min={0}
                        placeholder="Input the price point" disabled={props.action == "view"}
                    />

                </div>)}
                {isPerecentOff && (<div className="width-50">
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}
                        Input the % off value
                    </label>
                    <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            const numericValue = parseFloat(value);
                            if ((numericValue >= 0 && numericValue <= 100) || value === "") {
                                handleOffValueChange(e);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        value={offValue}
                        type="Number"
                        min={0}
                        placeholder="Input the % off value"
                        disabled={props.action === "view"}
                    />
                    {/* <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                                handleOffValueChange(e);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        value={offValue} type="Number" min={0}
                        placeholder="Input the % off value" disabled={props.action == "view"}
                    /> */}

                </div>)}
                {isNumberToBuy && (<div className="width-50">
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}
                        Input the number to Buy
                    </label>
                    <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                                handleNumberChange(e);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        value={numberValue} type="Number" min={0}
                        placeholder="Input the number to Buy" disabled={props.action == "view"}
                    />

                </div>)}

                {isNumberToGet && (<div className="width-50">
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}
                        Input the number to Get
                    </label>
                    <Input
                        className="input-text-field"
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                                handleNumberGetChange(e);
                            }
                        }}
                        onKeyDown={handleKeyDown}
                        value={numberGetValue} type="Number" min={0}
                        placeholder="Input the number to Get" disabled={props.action == "view"}
                    />

                </div>)}
            </div>}
        </>
    )
}
export default DropdownElement;
