import "./elements.css";
import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const DateEle = (props) => {
  const onChange = (value, dateString) => {
    props.isPromoTypeQuestion
      ? props.handlePromoTypeQuestionChange(dateString, props.id, props.pqid)
      : props.onChange(dateString, props.id);
  };

  return (
    <div className="date-frame">
      <div className="d-label">
        {props.designate && props.designate === "required" ? (
          <span className="spanReq">*</span>
        ) : (
          ""
        )}{" "}
        <label className="input-label">{props.label}</label>
      </div>
      {props.action === "create" && (
        <>
          <RangePicker            
            format="MM/DD/YYYY"
            onChange={onChange}
            className="select-date"
          />{" "}
          <span className="error-label">{props.error}</span>
        </>
      )}
      {props.action === "view" && (
        <>
          <RangePicker
            disabled
            format="MM/DD/YYYY"
            className="select-date"
            value={
              (props.ans && [moment(props.ans[0]), moment(props.ans[1])]) || []
            }
          />{" "}
          <span className="error-label">{props.error}</span>
        </>
      )}
      {props.action === "edit" && (
        <>
          <RangePicker
            format="MM/DD/YYYY"
            onChange={onChange}
            className="select-date"
            value={
              (props.ans && [moment(props.ans[0]), moment(props.ans[1])]) || []
            }
          />{" "}
          <span className="error-label">{props.error}</span>
        </>
      )}
    </div>
  );
};

export default DateEle;
