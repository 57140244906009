import { S } from "utils/prefix";
import * as ActionTypes from "actions/ticketReport/action-types";
import TicketReportStateManager from "./state-manager";

const INITIAL_STATE = {
  getCommentAdmin: [],
  errorTickets: [],
  singleErrorTicket: {},
  searchedErrorTickets: [],
  errorTypeValues: [],
  total: 0,
  actionTeams: [],
  promos: [],
  media: []
};

export default function TicketReportStates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.UPDATE_LOCAL_ERROR_TICKET_JSON:
      return { ...state, errorTickets: [...action.errorTickets] };

    case S(ActionTypes.GET_ALL_ERROR_TICKET):
      return TicketReportStateManager.getAllErrorTickets(state, action);
    case S(ActionTypes.GET_PROMO_LIST_NAMES):
      return TicketReportStateManager.getAllPromoNames(state, action);
    case S(ActionTypes.CREATE_ERROR_TICKET):
      return TicketReportStateManager.createErrorTickets(state, action);
    case S(ActionTypes.GET_SINGLE_ERROR_TICKET):
      return TicketReportStateManager.getSingleErrorTicket(state, action);
    case S(ActionTypes.UPDATE_ERROR_TICKET):
      return TicketReportStateManager.updateErrorTicket(state, action);
    case S(ActionTypes.UPLOAD_ATTACHMENT):
      return TicketReportStateManager.uploadMedia(state, action);
    case S(ActionTypes.GET_ATTACHMENT):
      return TicketReportStateManager.getMedia(state, action);
    case S(ActionTypes.DELETE_ERROR_TICKET):
      return TicketReportStateManager.deleteErrorTicket(state, action);
    case S(ActionTypes.GET_COMMENT_ADMIN):
      return TicketReportStateManager.getCommentAdmin(state, action);
    case S(ActionTypes.CHANGE_STATUS):
      return TicketReportStateManager.updateTicketStatus(state, action);
    case ActionTypes.UPDATE_ERROR_TICKET_BY_SEARCH:
      return TicketReportStateManager.updateErrorTicketBySearch(state, action);
    case ActionTypes.RESET_SINGLE_TICKET:
      return TicketReportStateManager.resetSingleTicket(state, action);
    case S(ActionTypes.GET_ERROR_TYPE_VALUES):
      return TicketReportStateManager.getAllErrorTypeValues(state, action);
    case S(ActionTypes.GET_ACTION_TEAM_DATA):
      return TicketReportStateManager.getActionTeams(state, action);
    default:
      return state;
  }
}
