import { S, F } from "../../utils/prefix";
import * as ActionTypes from "../../actions/profile/action-types";
import ProfileStateManager from "./state-manager";

const INITIAL_STATE = {
  logins: [],
  loginsCalendar: [],
  total_logins: 0,
  order_settings: {},
  userProfile: null,
};

export default function profileStates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case S(ActionTypes.GET_PROFILE):
      return ProfileStateManager.getProfileSuccess(state, action);

    case S(ActionTypes.EDIT_PROFILE):  
    case S(ActionTypes.CHANGE_PASSOWRD): 
    case F(ActionTypes.GET_PROFILE):
    case F(ActionTypes.EDIT_PROFILE):
    case F(ActionTypes.CHANGE_PASSOWRD):     
    default:
      return state;
  }
}
