import { getPromoStats } from "actions/promotions/index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Promotion.css";
import {
  ClockCircleOutlined,
  CloudOutlined,
  LikeOutlined,
  EditOutlined,
  WarningOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import session from "utils/session";
import upload from "image/clarity_upload-cloud-line.svg";
import circle_half from "image/hugeicons_cancel-circle-half-dot-1.svg";
import cancel_circle from "image/hugeicons_cancel-circle-half-dot.svg";
import cancel from "image/mage_cancel.svg";
import preview from "image/mage_preview.svg";
import symbols from "image/material-symbols-light_home-storage-outline.svg";
import simple_line from "image/simple-line-icons_action-undo.svg";
import warning from "image/warning.svg";
const STATS = {
  fullyApproved: "fully approved",
  canceled: "canceled",
  loaded: "loaded",
  partiallyApproved: "partially approved",
  actionable: "actionable",
  live: "live",
  expired: "expired",
  totalPromos: "total promos",
};
const PromoStats = () => {
  const dispatch = useDispatch();
  const { statusValue } = useSelector((state) => state.promo);
  const isMerchant =
    session && session.role && session.role.toLowerCase() === "merchant";
  useEffect(() => {
    const getCall = async () => {
      await dispatch(getPromoStats());
    };
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fullyApprovedValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.fullyApproved
  );
  const canceledValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.canceled
  );
  // const loadedValue = statusValue?.filter(
  //   (item) => item._id?.toLowerCase() === STATS.loaded
  // );
  const liveValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.live
  );
  const partiallyApprovedValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.partiallyApproved
  );
  const actionableValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.actionable
  );
  const expiredValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.expired
  );
  const totalPromosValue = statusValue?.filter(
    (item) => item._id?.toLowerCase() === STATS.totalPromos
  );
  return (
    <div className="page-header-promo">
      <div className="heading-left">
        <div className="wrapper">
          <span className="title">Offer Summary</span>
        </div>
      </div>
      <div className="content-live">
        <div className="layout-horizontal">
          <div className="static">
            <Link
              to={`/promotable/Total Promos`}
            >
              <div className="static-title">
                <span className="static-title-text">
                  Total Offers
                </span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">
                  {/* <ClockCircleOutlined className="symbol" /> */}
                  <img
                    src={symbols}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {(totalPromosValue?.length > 0 ? totalPromosValue[0].count : 0)}
                  </span>
                </div>
              </div>
            </Link>
          </div>



          <div className="static">
            <Link to="/promotable/Actionable">
              <div className="static-title">
                <span className="static-title-text">Actionable</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">
                  <img
                    src={simple_line}
                    alt="" className="summaryIcon"
                  />

                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {actionableValue?.length > 0 ? actionableValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="static">
            <Link to="/promotable/Partially Approved">
              <div className="static-title">
                <span className="static-title-text">Partially Approved</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">
                  <img
                    src={cancel_circle}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {partiallyApprovedValue?.length > 0 ? partiallyApprovedValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>

          <div className="static">
            <Link to="/promotable/Fully Approved">
              <div className="static-title">
                <span className="static-title-text">Fully Approved</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">
                  <img
                    src={circle_half}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {fullyApprovedValue?.length > 0 ? fullyApprovedValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>

          {/* <div className="static">
            <Link to="/promotable/Loaded">
              <div className="static-title">
                <span className="static-title-text">Loaded</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">                  
                  <img
                    src={preview}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {loadedValue?.length > 0 ? loadedValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div> */}
          <div className="static">
            <Link to="/promotable/Canceled">
              <div className="static-title">
                <span className="static-title-text">Canceled</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">

                  <img
                    src={cancel}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {/* {o?.status==="Incomplete"?o.count:0} */}
                    {canceledValue?.length > 0 ? canceledValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="static">
            <Link to="/promotable/Live">
              <div className="static-title">
                <span className="static-title-text">Live</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">
                  <img
                    src={upload}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {liveValue?.length > 0 ? liveValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className="static">
            <Link to="/promotable/Expired">
              <div className="static-title">
                <span className="static-title-text">Expired</span>
              </div>
              <div className="static-value">
                <div className="static-value-prefix">
                  <img
                    src={warning}
                    alt="" className="summaryIcon"
                  />
                </div>
                <div className="static-value-digit">
                  <span className="static-value-digit-text">
                    {expiredValue?.length > 0 ? expiredValue[0].count : 0}
                  </span>
                </div>
              </div>
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
};
export default PromoStats;
