export const triggerDownload = (data: any, fileName: string) => {
  const fileData = new Blob([data], { type: "text/csv" });
  const url = URL.createObjectURL(fileData);
  const downloadBtn: HTMLAnchorElement = document.createElement("a");
  downloadBtn.setAttribute("href", url);
  downloadBtn.setAttribute("download", fileName);
  document.body.appendChild(downloadBtn);
  downloadBtn.click();
  document.body.removeChild(downloadBtn);
  URL.revokeObjectURL(url);
};

export function replaceBracesWithSpace(xmlString:string) {
  return xmlString?.replace(/{{|}}/g, "");
}

export const openDownloadLink = (message:string) => {
  // Use a regular expression to extract the URL from the HTML string
  const urlPattern = /href="([^"]*)"/;
  const match = message.match(urlPattern);

  if (match && match[1]) {
    // Open the URL in a new window
    window.open(match[1], '_blank');
  } else {
    console.log('URL not found in the message.');
  }
}