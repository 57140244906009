import { Radio } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import DropDownEle from "./DropDownEle";
import InputEle from "./InputEle";
import MasterDropDownEle from "./MasterDropDownEle";
import TextEle from "./TextEle";

const RadioEle = ({question,index,questionType,questionHeader,options,designate,action,handleRadioChange,handleRadioSubChange,isLevelTwo=false,handleInputSubChange,handleDropdownSubChange,handleMasterDropDownSubChange,answer,subAnswer,handleTextareaSubChange,record}) => {

  const [subOptionObj,setSubOptionObj] = useState(null);
  const [inputValue,setInputValue] = useState('')
  const [textareaValue,setTextareaValue] = useState('')

  useEffect(() => {
    if(action === 'view' && answer){
      const object1 = options?.filter((item) =>
      (item?.optionText || item?.subOptionText)
        .toLowerCase()
        .trim()
        .includes(answer.toLowerCase().trim())
      );

      if(object1[0]?.subOptions?.length){
        setSubOptionObj(object1[0]?.subOptions)
      }else{
        setSubOptionObj(null)
      }
    }
  },[action,answer,options])

  const handleRadio = (value,i,record) => {
      handleInputSubChange('',i,'','','',record)
      handleTextareaSubChange('',i,'','','',record)
      handleRadioChange(value,i,record)
      setInputValue('')

      const object1 = options?.filter((item) =>
      (item?.optionText || item?.subOptionText)
        .toLowerCase()
        .trim()
        .includes(value.toLowerCase().trim())
      );

      if(object1[0]?.subOptions?.length){
        setSubOptionObj(object1[0]?.subOptions)
      }else{
        setSubOptionObj(null)
      }
    }

    useEffect(() => {
      if(subOptionObj && subOptionObj[0].subOptionType === 'input'){
        let arr = []
        for(let i=0;i<subOptionObj.length;i++){
          arr.push({
            subIndex:i,
            value:""
          })
        }
        setInputValue(arr)
      }

      if(subOptionObj && subOptionObj[0].subOptionType === 'textarea'){
        let arr = []
        for(let i=0;i<subOptionObj.length;i++){
          arr.push({
            subIndex:i,
            value:""
          })
        }
        setTextareaValue(arr)
      }
    },[subOptionObj])

    return(<>
    <div 
    // className="input-frame"
    >
      {action === 'create' && isLevelTwo &&
      <div className="d-label">
      {designate && designate === 'required' ? <span className="spanReq">*</span> : ''}  
      <label className="input-label">{questionHeader}</label>
      </div>
      }

      {
        action === 'create' && !isLevelTwo && <Radio.Group
                onChange={(e) => handleRadio(e.target.value,index,record)}        
              >
                {options?.map((item,i) => (
                  <Radio value={item.optionText || item.subOptionText} key={i}>
                    {item.optionText || item.subOptionText}
                  </Radio>
                ))}
              </Radio.Group>
      }

      {
        action === 'create' && isLevelTwo && <Radio.Group
                onChange={(e) => handleRadioSubChange(e.target.value,index,questionType,questionHeader,record)}     
              >
                {options?.map((item,i) => (
                  <Radio value={item.optionText || item.subOptionText} key={i}>
                    {item.optionText || item.subOptionText}
                  </Radio>
                ))}
              </Radio.Group>
      }

      {/* view */}
      {
        action === 'view' && !isLevelTwo && <Radio.Group
                disabled    
                value={answer || ''}
              >
                {options?.map((item,i) => (
                  <Radio value={item.optionText || item.subOptionText} key={i}>
                    {item.optionText || item.subOptionText}
                  </Radio>
                ))}
              </Radio.Group>
      }

      {
        action === 'view' && isLevelTwo && <Radio.Group
                disabled     
                value={(subAnswer.length>0 && subAnswer[0].value) || ''}
              >
                {options?.map((item,i) => (
                  <Radio value={item.optionText || item.subOptionText} key={i}>
                    {item.optionText || item.subOptionText}
                  </Radio>
                ))}
              </Radio.Group>
      }

    </div>

    {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'input' && 
        subOptionObj.map((item,i) => {
          return(<InputEle
            key={i}
            index={index}
            action={'create'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleInputSubChange={handleInputSubChange}
            subIndex={i}
            inputValue={inputValue && inputValue.length>0 && inputValue}
            setInputValue={setInputValue}
            record={record}
          />)
        }
        )
      }

    {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'textarea' && 
        subOptionObj.map((item,i) => {
          return(<TextEle
            key={i}
            index={index}
            action={'create'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleTextareaSubChange={handleTextareaSubChange}
            subIndex={i}
            textareaValue={textareaValue && textareaValue.length>0 && textareaValue}
            setTextareaValue={setTextareaValue}
            record={record}
          />)
        }
        )
      }

      {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'dropdown'  && !subOptionObj[0]?.isMaster &&
        <DropDownEle
          index={index}
          question={subOptionObj[0]}
          questionHeader={subOptionObj[0].subOptionText}
          questionType={subOptionObj[0].subOptionType}
          options={subOptionObj[0].subOptions}
          action={'create'}
          isLevelTwo={true}
          handleDropdownSubChange={handleDropdownSubChange}
          record={record}
        /> 
      }

      {
        action === 'create' && subOptionObj && subOptionObj[0].subOptionType === 'dropdown' && subOptionObj[0]?.isMaster &&
        <MasterDropDownEle
          index={index}
          question={subOptionObj[0]}
          questionHeader={subOptionObj[0].subOptionText}
          questionType={subOptionObj[0].subOptionType}
          action={'create'}
          isLevelTwo={true}
          handleMasterDropDownSubChange={handleMasterDropDownSubChange}
          record={record}
          /> 
      }

      {/* views */}
      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'input' && 
        subOptionObj.map((item,i) => {
          // let val = subAnswer.filter(item => item.subIndex === i)
          let val = subAnswer && typeof subAnswer !== 'string' && subAnswer.length>0 && subAnswer.filter(item => item.subIndex === i)
          return(<InputEle
            key={i}
            index={index}
            action={'view'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleInputSubChange={handleInputSubChange}
            subIndex={i}
            // subAnswer={val[0].value}
            subAnswer={val.length>0 ? val[0].value : ''}
          />)}
        )
      }

      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'textarea' && 
        subOptionObj.map((item,i) => 
        {
          let val = subAnswer.filter(item => item.subIndex === i)
          return(<TextEle
            key={i}
            index={index}
            action={'view'}
            isLevelTwo={true}
            questionHeader={item.subOptionText}
            questionType={item.subOptionType}
            handleTextareaSubChange={handleTextareaSubChange}
            subIndex={i}
            subAnswer={val[0].value}
          />)}
        )
      }

      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'dropdown'  && !subOptionObj[0]?.isMaster &&
        <DropDownEle
          index={index}
          question={subOptionObj[0]}
          questionHeader={subOptionObj[0].subOptionText}
          questionType={subOptionObj[0].subOptionType}
          options={subOptionObj[0].subOptions}
          action={'view'}
          isLevelTwo={true}
          handleDropdownSubChange={handleDropdownSubChange}
          subAnswer={subAnswer}
        /> 
      }

      {
        action === 'view' && subOptionObj && subOptionObj[0].subOptionType === 'dropdown' && subOptionObj[0]?.isMaster &&
        <MasterDropDownEle
          index={index}
          question={subOptionObj[0]}
          questionHeader={subOptionObj[0].subOptionText}
          questionType={subOptionObj[0].subOptionType}
          action={'view'}
          isLevelTwo={true}
          handleMasterDropDownSubChange={handleMasterDropDownSubChange}
          subAnswer={subAnswer}
          /> 
      }
   </> )
}

export default RadioEle;