import * as ActionTypes from './action-types';

export const setPage = (pageName) => ({
    type: ActionTypes.SET_PAGE,
    pageName
});

export const setCurrentSelectedPage = (pageId) => ({
    type: ActionTypes.SET_CURRENT_PAGE,
    pageId
});
