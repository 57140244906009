import { SET_MESSAGE, TOGGLE_LOADER } from "actions/common";

const INITIAL_STATE = {
  loader: false,
  message: "",
};

export default function CommonStates(state = INITIAL_STATE, action: any) {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_LOADER:
      return { ...state, loader: payload };
    case SET_MESSAGE:
      return { ...state, message: payload };
    default:
      return state;
  }
}
