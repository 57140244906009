/* eslint-disable no-unused-vars */
import { getAllMaps } from 'actions/map';
import { getAllNotificationsWithoutPage, getMerchantNotificationsWithoutPage, getBasicNotificationsWithoutPage } from 'actions/notifications';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";
import store from 'store';
import session from './session';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID
} = process.env;

const config = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID
}

// initializeApp(config);

// const messaging = getMessaging();
const messaging = {};

const requestForToken = async () => {
  const checkSupport = await isSupported()
  if (checkSupport) {
    const res = await Notification.requestPermission()
    if (res !== "denied") {
      return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            return currentToken;
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
    }
  }

};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      if (session && session.role.toLowerCase() === 'merchant') {
        store.dispatch(getMerchantNotificationsWithoutPage(session.userId))
        store.dispatch(getAllMaps())
      } else if (session && session.role.toLowerCase() === 'basic') {
        store.dispatch(getBasicNotificationsWithoutPage(session.userId))
        store.dispatch(getAllMaps())
      } else {
        store.dispatch(getAllNotificationsWithoutPage(session.userId))
        store.dispatch(getAllMaps())
      }
    });
  });

export { requestForToken }