import React, { useEffect, useState, useMemo } from "react";
import { Radio, Select } from "antd";
import { useDispatch } from "react-redux";
import PromoService from "services/promo-service/promo-service";
const { Option } = Select;
const RadioElement = (props) => {
    const [value, setValue] = useState(props.ans ? props.ans : "");
    const dispatch = useDispatch();
    const [zoneList, setZoneList] = useState([]);
    const [zoneValue, setZoneValue] = useState([]);
    const [label, setLabel] = useState('Group Zone');
    const promoService = useMemo(() => new PromoService(), []);
    const onChange = async (e) => {
        setValue(e.target.value);
        props.onChange(e.target.value, props.id);
        if (props.label !== 'Is MAP override approved?') {
            setZoneValue([]);
            if (e.target.value === props.options[0].optionText) {
                setLabel('Group Zone');
                promoService.getSKUListData('groupzone')
                    .then(response => {
                        setZoneList(response?.data?.skuList);
                    });
            } else if (e.target.value === props.options[1].optionText) {
                setLabel('Individual Zone');
                promoService.getSKUListData('zone').then(response => {
                    setZoneList(response?.data?.skuList);
                });
            } else {
                setLabel('');
            }
        }
    }
    const handleZoneChange = (e) => {
        setZoneValue(e);
        props.handleSubOptionChange(props?.id, 'Group Zone', e, value)
    }

    useEffect(() => {
        const option = props.options.find(item => item.optionText == props.ans)
        if (option) {
            if (option.optionText === props.options[0].optionText) {
                setLabel('Group Zone');
                promoService.getSKUListData('groupzone')
                    .then(response => {
                        setZoneList(response?.data?.skuList);
                    });
            } else if (option.optionText === props.options[1].optionText) {
                setLabel('Individual Zone');
                promoService.getSKUListData('zone').then(response => {
                    setZoneList(response?.data?.skuList);
                });
            } else {
                setLabel('');
            }
            option?.subOptions?.forEach(element => {
                if (element.subOptionText == 'Group Zone') {
                    setZoneValue(element.subOptionValue)
                }
            });
        }
    }, [])

    return (
        <>
            <div className="radio-frame" style={{ minHeight: '50px', marginBottom: '15px' }}>
                <div>
                    <label className="input-label">
                        {props.designate && props.designate === "required" ? (
                            <span className="spanReq">*</span>
                        ) : (
                            ""
                        )}{" "}
                        {props.label}
                    </label>
                </div>
                {props.action === "create" && (
                    <>
                        <div>
                            <Radio.Group onChange={onChange} value={value}>
                                {props.options?.map((item) => (
                                    <Radio
                                        value={item.optionText}
                                        key={item.optionText}
                                    >
                                        {item.optionText}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </div>
                        <span className="error-label">{props.error}</span>
                    </>
                )}
                {props.action === "edit" && (
                    <>
                        <div>
                            <Radio.Group onChange={onChange} value={value}>
                                {props.options?.map((item) => (
                                    <Radio
                                        value={item.optionText}
                                        key={item.optionText}
                                    >
                                        {item.optionText}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </div>
                        <span className="error-label">{props.error}</span>
                    </>
                )}
                {props.action === "view" && (
                    <div>
                        <Radio.Group value={props.ans} disabled>
                            {props.options?.map((item) => (
                                <Radio value={item.optionText}>
                                    {item.optionText}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </div>
                )}
            </div>
            {value && value !== 'ECOM only' && props.label == 'Select the applicable zone(s)' && <div className="input-frame">
                <label className="input-label">
                    {label}
                </label>
                {props.action === "create" && (
                    <div className="select-dropdown">
                        {label == "Group Zone" && <Select
                            className="select-dropdown"
                            onChange={handleZoneChange}
                            value={zoneValue}
                            placeholder="Select"
                        >
                            {zoneList.map((item) => (
                                <Option key={item.zone_id}>
                                    {item.group_zone}
                                </Option>
                            ))}
                        </Select>}
                        {label == "Individual Zone" && <Select
                            className="select-dropdown"
                            onChange={handleZoneChange}
                            value={zoneValue} maxTagCount={3}
                            placeholder="Select" mode="multiple"
                        >
                            {zoneList
                            .sort((a, b) => a.zone_id - b.zone_id) // Sorts the list by zone_id
                            .map((item) => (
                                <Option key={item.zone_id}>
                                    {item.zone_id + " - " + item.name}
                                </Option>
                            ))}
                        </Select>}
                    </div>

                )}

                {props.action === "edit" && (
                    <div className="select-dropdown">
                        {label == "Group Zone" && <Select
                            className="select-dropdown"
                            onChange={handleZoneChange}
                            value={zoneValue}
                            placeholder="Select"
                        >
                            {zoneList.map((item) => (
                                <Option key={item.zone_id}>
                                    {item.group_zone}
                                </Option>
                            ))}
                        </Select>}
                        {label == "Individual Zone" && <Select
                            className="select-dropdown"
                            onChange={handleZoneChange}
                            value={zoneValue} maxTagCount={3}
                            placeholder="Select" mode="multiple"
                        >
                            {zoneList.map((item) => (
                                <Option key={item.zone_id}>
                                    {item.zone_id + " - " + item.name}
                                </Option>
                            ))}
                        </Select>}
                    </div>
                )}
                {props.action === "view" && (
                    <div className="select-dropdown">
                        <Select className="select-dropdown" value={zoneValue} mode="multiple" disabled>
                            {label == "Group Zone" && zoneList.map((item) => (
                                <Option key={item.zone_id}>
                                    {item.group_zone}
                                </Option>
                            ))}
                            {label == "Individual Zone" && zoneList.map((item) => (
                                <Option key={item.zone_id}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}
            </div>}
        </>
    )
}
export default RadioElement;