import "./CreatePromo.css";
import "./Promotion.css";
import "../promotion/elements/elements.css";
import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import {
  Drawer,
  Modal,
  Button,
  Spin,
  Input,
  DatePicker,
  Alert,
  Space,
  Radio,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import session from "utils/session";
import Element from "./Element";
import StatusDrawer from "components/common/statusDrawer";
import {
  viewPromo,
  editPromo,
  getPromoList,
  getPromoByStatus,
  changeStatusPromo,
} from "actions/promotions/index";
import { DATE_FORMAT_DASH, TextMessage } from "utils/constants";
import ViewActivity from "./ViewActivity";
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ViewPromo = (props) => {
  const dispatch = useDispatch();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [text, setText] = useState("");
  const [textError, setTextError] = useState("");
  const [title, setTitle] = useState("");
  const { promoById } = useSelector((state) => state.promo);
  const [loadingState, setLoadingState] = useState(false);
  const isAdmin =
    session && session.role && session.role.toLowerCase() === "admin";

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(viewPromo(props.viewId));

      if (res) {
        setLoadingState(false);
      } else {
        <Alert
          message={"Error occured in view promo!"}
          closable
          type="error"
          showIcon
        />;
      }
    };
    getCall();
  }, [dispatch, props.viewId]);

  useEffect(() => {
    setDate([
      promoById && promoById.startDate ? promoById.startDate : null,
      promoById && promoById.endDate ? promoById.endDate : null,
    ]);
  }, [promoById]);

  const handleCancel = () => {
    setTextError("");
    setModalOpen(false);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleOk = async (id) => {
    if (!text) {
      setTextError("This field is required!");
      return;
    }
    const api = { reasonCode: text, status: "Rejected" };
    setLoading(true);

    const res = text && (await dispatch(changeStatusPromo(props.viewId, api)));
    if (res) {
      await dispatch(getPromoList());
      setTitle(TextMessage.PROMOTION_DECLINE_TEXT);
      setShowDeleteAlert(true);
      setLoading(false);
      setModalOpen(false);
      setTextError("");
    } else {
      setLoading(false);
      setModalOpen(false);
      setTextError("");
    }
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleDate = (value, dateString) => {
    setDate(dateString);
  };

  const handleLive = async (id) => {
    const api = { ...promoById, startDate: date[0], endDate: date[1] };
    setLoading(true);
    const res = await dispatch(editPromo(id, api));

    if (res.error === undefined) {
      dispatch(getPromoByStatus("status", props.statusId));
      dispatch(getPromoByStatus("status", "Pending"));
      dispatch(getPromoByStatus("status", "Submitted"));
      dispatch(getPromoList());
      setTitle("Promo has been updated successfully!");
      setShowDeleteAlert(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleApprove = async (id) => {
    const api = { status: "Approved" };
    setLoading(true);
    const res = await dispatch(changeStatusPromo(id, api));
    if (res.error === undefined) {
      await dispatch(getPromoList());
      setTitle(TextMessage.PROMOTION_APPROVE_TEXT);
      setShowDeleteAlert(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleDeleteAlertClose = () => {
    setTitle("");
    setShowDeleteAlert(false);
    props.onClose();
  };

  const [mode, setMode] = useState("promo");
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  return (
    <div>
      <Drawer
        title={
          isAdmin
            ? promoById?.status === "Live" && props.liveFlag
              ? "Edit Promo"
              : "Review"
            : promoById?.status === "Live" && props.liveFlag
              ? "Edit Promo"
              : "View"
        }
        width={640}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
        footer={
          promoById?.status === "Live" &&
          props.liveFlag && (
            <Space className="create-style-edit">
              <Button
                type="primary"
                onClick={() => handleLive(props.viewId)}
                disabled={loading}
              >
                {loading ? `Loading...` : "Update"}
              </Button>
            </Space>
          )
        }
      >
        <Radio.Group
          onChange={handleModeChange}
          value={mode}
          style={{
            marginBottom: 8,
            width: "100%",
            display: "flex",
          }}
        >
          <Radio.Button value="promo" style={{ width: "100%" }}>
            Promo
          </Radio.Button>
          <Radio.Button value="activity" style={{ width: "100%" }}>
            Activity log
          </Radio.Button>
        </Radio.Group>

        {!loadingState && mode === "promo" && (
          <>
            {!props.liveFlag && (
              <div
                className={
                  promoById?.status === "Rejected" && promoById?.reasonCode
                    ? "page-header-review"
                    : "page-header-review-reject"
                }
              >
                <div className="content-review">
                  <div className="layout-block-review">
                    <div className="lay-static">
                      <div className="static-titlelay">
                        <span className="text-kr">Submitted by</span>
                      </div>
                      <div className="static-valuelay">
                        <span className="text-value">
                          {promoById?.owner?.fullName}
                        </span>
                      </div>
                    </div>
                    <div className="lay-static">
                      <div className="static-titlelay">
                        <span className="text-kr">Current Status</span>
                      </div>
                      <div className="static-valuelay text-value">
                        <span
                          className={
                            (promoById?.status === "Approved" &&
                              "text-approved") ||
                            (promoById?.status === "Pending" &&
                              "text-pending") ||
                            (promoById?.status === "Submitted" &&
                              "text-pending") ||
                            (promoById?.status === "Live" && "text-live") ||
                            (promoById?.status === "Expired" &&
                              "text-expired") ||
                            (promoById?.status === "Rejected" &&
                              "text-rejected") ||
                            (promoById?.status === "Incomplete" &&
                              "text-incomplete") ||
                            (promoById?.status.includes("Errors") && "text-lv")
                          }
                        >
                          {promoById?.status}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="frame-review">
                    <div className="tag-review"></div>
                    {!(
                      promoById?.status === "Submitted" ||
                      promoById?.status === "Rejected" ||
                      promoById?.status === "Approved" ||
                      promoById?.status === "Incomplete" ||
                      promoById?.status === "Live" ||
                      promoById?.status === "Expired"
                    ) && (
                        <div className="button-review">
                          <Button
                            onClick={() => handleApprove(props.viewId)}
                            disabled={loading}
                          >
                            {loading ? `Loading...` : "Approve"}
                          </Button>
                        </div>
                      )}
                    {!(
                      promoById?.status === "Submitted" ||
                      promoById?.status === "Rejected" ||
                      promoById?.status === "Approved" ||
                      promoById?.status === "Incomplete" ||
                      promoById?.status === "Live" ||
                      promoById?.status === "Expired"
                    ) && (
                        <div className="button-review">
                          <Button onClick={() => handleOpen()} disabled={loading}>
                            {"Reject"}
                          </Button>
                        </div>
                      )}
                    {/* <div className="dp-review"></div> */}
                  </div>
                </div>
                {promoById?.status === "Rejected" && promoById?.reasonCode && (
                  <div>
                    <div className="lay-static">
                      <div className="static-titlelay">
                        <span className="text-kr">Reject reason</span>
                      </div>
                      <div className="static-valuelay">
                        <span className="text-value">
                          {promoById?.reasonCode}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <Modal
                  visible={modalOpen}
                  onCancel={handleCancel}
                  onOk={handleOk}
                  title={"Reject reason"}
                  footer={[
                    <Button onClick={handleCancel}>Cancel</Button>,
                    <Button
                      type="primary"
                      onClick={handleOk}
                      disabled={loading}
                      className="footerBtnNavy"
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>,
                  ]}
                  destroyOnClose
                >
                  <TextArea
                    onChange={handleChange}
                    rows={5}
                    placeholder="Enter Detail here"
                  />
                  {!text && <span className="error-label">{textError}</span>}
                </Modal>
              </div>
            )}
            <div className="create-body">
              {/* <Error /> */}
              <div className="create-frame">
                <div className="create-promo-frame">
                  <div className="input-frame">
                    <div className="d-label">
                      <span className="spanReq">*</span>{" "}
                      <label className="input-label">Name of the Promo</label>
                    </div>
                    <Input
                      className="input-text-field"
                      value={promoById?.name}
                      placeholder="Enter here"
                      disabled
                    />
                  </div>
                  <div className="date-frame">
                    <div className="d-label">
                      <label className="input-label">
                        <span className="spanReq">*</span> When does the Promo
                        start and end?
                      </label>
                    </div>
                    <RangePicker
                      format={DATE_FORMAT_DASH}
                      onChange={handleDate}
                      value={[
                        date[0] && moment.utc(date[0]),
                        date[1] && moment.utc(date[1]),
                      ]}
                      className="select-date"
                      placeholder={['Start Date (MM/DD/YYYY)', 'End Date (MM/DD/YYYY)']}
                      disabled={
                        promoById?.status === "Live" && props.liveFlag
                          ? false
                          : true
                      }
                    />
                  </div>

                  {promoById?.fieldData?.map((item, id) => (
                    <Element
                      id={item?.quesObj?._id}
                      ans={item?.value}
                      field={item?.quesObj?.questionType}
                      label={item?.quesObj?.questionHeader}
                      subOption1={item?.subOption1 ? item?.subOption1 : ""}
                      options={item?.quesObj?.optionValue}
                      subOptions={item?.subOptions ? item?.subOptions : ""}
                      SubOptionInput={
                        item?.subOptionInput || item?.SubOptionInput || ""
                      }
                      SubOptionInputThree={item.subOptionInputThree || ""}
                      action={"view"}
                      item={item.quesObj}
                      designate={item.quesObj.designate || ""}
                      fieldData={item.fieldData || []}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}

        {loadingState && (
          <div className="errorTipDiv">
            <Spin size="middle" tip="Loading..." />
          </div>
        )}

        {!loadingState && mode === "activity" && <ViewActivity />}
      </Drawer>
      {showDeleteAlert && (
        <StatusDrawer
          onClose={handleDeleteAlertClose}
          title={title}
          visible={showDeleteAlert}
        />
      )}
    </div>
  );
};

export default ViewPromo;
