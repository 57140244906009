import { Input } from "antd";

const InputEle = ({question,index,questionType,questionHeader,options,designate,action,handleInputChange,handleInputSubChange,isLevelTwo=false,subIndex,answer,subAnswer,inputValue,setInputValue,record,questionFlag}) => {

    const handleInput = (e,index,subIndex,questionType,questionHeader,record) => {
      const arr = [...inputValue]
      arr[subIndex].value = e.target.value
      setInputValue(arr)
      handleInputSubChange(e.target.value,index,subIndex,questionType,questionHeader,record)
    }
    return(
    <div 
    className="inputDiv"
    >
      {
        action === 'create' && isLevelTwo &&
      
      <div className="d-label">
      {designate && designate === 'required' ? <span className="spanReq">*</span> : ''}  
      <label className="input-label">{questionHeader}</label>
      </div>
      }

      {
        action === 'create' && !isLevelTwo && <Input 
            onChange={(e) => handleInputChange(e.target.value,index,record)}
            placeholder="Enter here"
            className="map-input"
            type={(questionFlag && (questionFlag === 'Map Price' || questionFlag === 'List Price')) ? 'number' : 'text'}
        />
      }
      {
        action === 'create' && isLevelTwo && <Input 
            onChange={(e) => handleInput(e,index,subIndex,questionType,questionHeader,record)}
            value={inputValue && inputValue[subIndex]?.value}
            placeholder="Enter here"
            type={(questionFlag && (questionFlag === 'Map Price' || questionFlag === 'List Price')) ? 'number' : 'text'}
        />
      }

      {
        action === 'view' && !isLevelTwo && <Input 
            className="input-text-field" 
            disabled
            value={answer || ''}
            placeholder="Enter here"
        />
      }

      {
        action === 'view' && isLevelTwo && <Input 
            className="input-text-field" 
            disabled
            value={subAnswer || ''}
            placeholder="Enter here"
        />
      }
    </div>
    )
}

export default InputEle;