import { CopyOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";

const TextAreaQuestion = ({
  isTextArea,
  question,
  loading,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  handleQuestionDesignate,
}) => {
  return (
    <>
      {isTextArea && question.questionType === "textarea" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox onChange={(e) => handleQuestionDesignate(e, index)}>
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}
      {isTextArea && question.questionType === "textarea" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          <div className="formBuilderActionsWrapper">
            <div className="copyDiv">
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={() => copyQuestion(index)}
              >
                <CopyOutlined />
              </Button>
            </div>
            <div className="delDiv" disabled={loading}>
              <Button
                disabled={loading}
                className="formBuilderCopyBtn"
                onClick={() => deleteQuestion(index)}
              >
                <DeleteOutlined />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TextAreaQuestion;
