import logo from "image/Frame 147.svg";
import { useState, useEffect } from "react";
import { Button, Input } from "antd";
import {
  LockOutlined,
  CheckCircleFilled,
  LeftOutlined,
} from "@ant-design/icons";
import Footer from "components/common/footer";
import {
  FormControl,
  Container,
  WrapperImg,
} from "components/common/LoginStyle";
import * as Patterns from "utils/patterns";
import * as Constants from "utils/constants";
import { Validator } from "utils/validations";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link,useNavigate } from "react-router-dom";
import { resetPass, emailValidator } from "actions/login";
import { setPage } from "actions/app";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tokenid, userid } = useParams();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [lower, setLower] = useState(false);
  const [upper, setUpper] = useState(false);
  const [digit, setDigit] = useState(false);
  const [min, setMin] = useState(false);
  const [special, setSpecial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    dispatch(setPage("Reset Password"));
  }, [dispatch]);

  

  useEffect(() => {
    const handleEmail = async () => {
      const res = await dispatch(emailValidator(tokenid, userid));    
      setValid(res.payload.data.status);
    };
    handleEmail();
  },[tokenid, userid,dispatch]);

  
  const isResetPasswordValid = (val, minVal, isRequired) => {
    if (isRequired && (val === "" || !val)) {
      setSpecial(false);
      setDigit(false);
      setLower(false);
      setUpper(false);
      setMin(false);
      return Constants.FIELD_REQUIRED;
    }

    minVal && val.length > minVal ? setMin(true) : setMin(false);

    if (val !== "" && val !== null) {
      Patterns.UPPERCASE_PATTERN.test(val) ? setUpper(true) : setUpper(false);
      Patterns.LOWERCASE_PATTERN.test(val) ? setLower(true) : setLower(false);
      Patterns.DIGIT_PATTERN.test(val) ? setDigit(true) : setDigit(false);
      Patterns.SPECIAL_PATTERN.test(val) ? setSpecial(true) : setSpecial(false);
    }

    return "";
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (
      confirmPasswordError !== "" ||
      passwordError !== "" ||
      confirmPassword === "" ||
      password === ""
    ) {
      if (confirmPassword === "") {
        setConfirmPasswordError(Constants.FIELD_REQUIRED);
      } else {
        setConfirmPasswordError(confirmPasswordError);
      }
      if (password === "") {
        setPasswordError(Constants.FIELD_REQUIRED);
      }
      return;
    }

    setLoading(true);

    const res = await dispatch(
      resetPass({
        newPassword: password,
        conformPassword: confirmPassword,
        userid: tokenid,
      })
    );

    if (res.error === undefined) {
      setLoading(false);
      navigate("/login");
    } else {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    isResetPasswordValid(value, 7, true);
  };

  const handleConfirmPassword = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    setConfirmPasswordError(
      Validator.validate("confirm", value, password, null, null, true)
    );
  };

  return (
    <>
      <Container>
        {valid ? (
          <>
            <img src={logo} alt="logo" />
            <WrapperImg>
              <div className="head-box">
                <div className="item-count-reset">
                  <div className="item-tab-reset">
                    <span className="tab1-reset">
                      <span className="text-mode-reset">
                        {"Set a new password"}
                      </span>
                    </span>
                    <span className="ink-reset"></span>
                  </div>
                </div>
                <span className="line-only"></span>
              </div>

              <form className="form" onSubmit={handleResetPassword}>
                <FormControl>
                  <Input.Password
                    size="large"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    prefix={<LockOutlined />}
                  />
                  <span className="error-label">{passwordError}</span>
                  <div className="reset-box">
                    <div className="col-reset">
                      {lower ? (
                        <div className="one-reset green">
                          <CheckCircleFilled />
                          One lowercase charecter
                        </div>
                      ) : (
                        <div className="one-reset">
                          <CheckCircleFilled />
                          One lowercase charecter
                        </div>
                      )}
                      {digit ? (
                        <div className="one-reset green">
                          <CheckCircleFilled />
                          One number{" "}
                        </div>
                      ) : (
                        <div className="one-reset">
                          <CheckCircleFilled />
                          One number{" "}
                        </div>
                      )}
                      {min ? (
                        <div className="one-reset green">
                          <CheckCircleFilled />8 charecters minimum{" "}
                        </div>
                      ) : (
                        <div className="one-reset">
                          <CheckCircleFilled />8 charecters minimum{" "}
                        </div>
                      )}
                    </div>
                    <div className="col-reset">
                      {upper ? (
                        <div className="one-reset green">
                          <CheckCircleFilled />
                          One Uppercase charecter
                        </div>
                      ) : (
                        <div className="one-reset">
                          <CheckCircleFilled />
                          One Uppercase charecter
                        </div>
                      )}
                      {special ? (
                        <div className="one-reset green">
                          <CheckCircleFilled />
                          One special charecter{" "}
                        </div>
                      ) : (
                        <div className="one-reset">
                          <CheckCircleFilled />
                          One special charecter{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </FormControl>
                <FormControl>
                  <Input.Password
                    size="large"
                    placeholder="Confirm Password"
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPassword}
                    prefix={<LockOutlined />}
                  />
                  <span className="error-label">{confirmPasswordError}</span>
                </FormControl>

                <Button
                  onClick={handleResetPassword}
                  className="addUserBtnAuth"
                  disabled={loading}
                  block
                >
                  {loading ? `Loading...` : "Create password"}
                </Button>
                <div className="back-login">
                  <Link to="/login" className="links">
                    <LeftOutlined />
                    <span className="back-login-text">Back to login</span>
                  </Link>
                </div>
              </form>
              <Footer />
            </WrapperImg>
          </>
        ) : (
          <div>
            Please try again <Link to="/forgot">Forgot Password</Link> or check your Mail, this
            reset link is no longer active
          </div>
        )}
      </Container>
    </>
  );
};

export default ResetPassword;
