export const GET_PROMO_LIST = "GET_PROMO_LIST";
export const GET_PROMO_DD = "GET_PROMO_DD";
export const GET_PROMO_LIST_NAMES = "GET_PROMO_LIST_NAMES";
export const GET_PROMOBY_ID = "GET_PROMOBY_ID";
export const PROMOBY_STATUS = "PROMOBY_STATUS";
export const DELETE_PROMO = "DELETE_PROMO";
export const CREATE_PROMO = "CREATE_PROMO";
export const VIEW_PROMO = "VIEW_PROMO";
export const EDIT_PROMO = "EDIT_PROMO";
export const SEND_SUB1 = "SEND_SUB1";
export const SEND_SUB2 = "SEND_SUB2";
export const SEND_SUB_INPUT = "SEND_SUB_INPUT";
export const SEND_LAST = "SEND_LAST";
export const CHANGE_STATUS_PROMO = "CHANGE_STATUS_PROMO";
export const GET_PROMO_ASSOCIATED_BRAND = "GET_PROMO_ASSOCIATED_BRAND";
export const GET_PROMO_ASSOCIATED_SKU = "GET_PROMO_ASSOCIATED_SKU";
export const GET_PROMO_ASSOCIATED_SHELF = "GET_PROMO_ASSOCIATED_SHELF";
export const GET_PROMO_EXPORT_DATA = "GET_PROMO_EXPORT_DATA";
export const SEND_SUB_INPUT_THREE = "SEND_SUB_INPUT_THREE";
export const REQUIRED_QUES_PROMO_TYPE = "REQUIRED_QUES_PROMO_TYPE";
export const GET_PROMO_STATS = "GET_PROMO_STATS";

export const GET_PROMOTYPE_FOR_PROMO = "GET_PROMOTYPE_FOR_PROMO";
export const UPDATE_STAGE = "UPDATE_STAGE";
export const GET_TIMELINE="GET_TIMELINE"
