import './Snapshot.css'
import { useEffect, useState }                                                   from "react";
import { useDispatch, useSelector }                                              from "react-redux";
import {  Pagination, Select, Spin }                                                         from 'antd';
import { Scrollbars }                                                            from 'react-custom-scrollbars';
import moment                                                                    from 'moment';
import CommonLayout                                                              from "components/layout/Layout";
import { filterSelectOptions }                                                   from "utils/constants";
import { setPage }                                                               from "actions/app";
import { getAllSnapshots, moduleChange }                                                       from 'actions/snapshot';

const { Option } = Select;

const Snapshot = () => {
    const dispatch = useDispatch()
    const snapshots = useSelector(state => state.snapshots.snapshots)
    const paginateDetails = useSelector(state => state.snapshots.paginateDetails)
    const module = useSelector(state => state.snapshots.module)
    const [loading,setLoading]= useState(false)
    const [loadingState,setLoadingState] = useState(false)

    const filterSelectOptionsChildren = [];
    for (let i = 0; i < filterSelectOptions.length; i++) {
         filterSelectOptionsChildren.push(<Option key={filterSelectOptions[i]._id}>{filterSelectOptions[i].value}</Option>);
    }

    useEffect(() => {
        dispatch(setPage('Snapshot/History'))
    },[dispatch])

    useEffect(() => {
        const getCall = async() =>{
        setLoadingState(true)
        const res = await dispatch(getAllSnapshots('module',module === null ? '' : module,0))
        if(res){
          setLoadingState(false)
        }
      }
      getCall()
    },[dispatch,module])

    const handleModuleSelect = async (value) => {
        await dispatch(moduleChange(value === 'all' ? null : value))

        setLoading(true)

        const response = await dispatch(getAllSnapshots('module',value === 'all' ? '' : value,0))

        if(response.error === undefined){
            setLoading(false)
        }else{
            setLoading(false)
            
        }
    }

    const handleLoadMore = async(page) => {
        setLoading(true)
        const response = await dispatch(getAllSnapshots('module',module === null ? '' : module,page-1))

        if(response.error === undefined){
            setLoading(false)
        }else{
            setLoading(false)
        }
    }

    const displayMsg = (snapshot) => {
        if(snapshot.module.toLowerCase() === 'user' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.data?.fullName || snapshot?.responseData?.data?.firstName} was deleted successfully` 
        }
        if(snapshot.module.toLowerCase() === 'user' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.data?.fullName || snapshot?.responseData?.data?.firstName} was updated successfully`
        }
        if(snapshot.module.toLowerCase() === 'user' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.fullName || snapshot?.responseData?.data?.firstName} was created successfully by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}`
        }

        if(snapshot.module.toLowerCase() === 'report' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.data?.title} was deleted successfully` 
        }

        if(snapshot.module.toLowerCase() === 'report' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.data?.title} was updated successfully` 
        }

        if(snapshot.module.toLowerCase() === 'report' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.title} was created successfully by ${snapshot?.createdBy?.fullName 
                || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'promoType' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.data?.promoType} was deleted successfully` 
        }

        if(snapshot.module === 'promoType' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.message}` 
        }

        if(snapshot.module === 'promoType' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.promoType} was created successfully by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'shelf' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.data?.shelfPath} was deleted successfully` 
        }

        if(snapshot.module === 'shelf' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.data?.shelfPath} was updated successfully` 
        }

        if(snapshot.module === 'shelf' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.shelfPath} was created successfully by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }
        
        if(snapshot.module === 'promo' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.data?.name} was deleted successfully` 
        }

        if(snapshot.module === 'promo' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.data?.name} was updated successfully` 
        }

        if(snapshot.module === 'promo' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.name} was created successfully by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'formbuilder' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.message} by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'formbuilder' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.data?.name} was updated successfully by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'formbuilder' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.name} was created successfully by ${snapshot?.createdBy?.fullName 
                || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'questionPromo' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.data?.questionHeader} was deleted by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'questionPromo' && (snapshot.actionType.toLowerCase() === 'update' || snapshot.actionType.toLowerCase() === 'status-update')){
            return `${snapshot?.responseData?.data?.questionHeader} was updated by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'questionPromo' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.data?.questionHeader} was created by ${snapshot?.createdBy?.fullName 
                || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module === 'notification' && (snapshot.actionType.toLowerCase() === 'update')){
            return `${snapshot?.responseData?.message}` 
        }

        if(snapshot.module.toLowerCase() === 'map' && snapshot.actionType.toLowerCase() === 'create'){
            return `${snapshot?.responseData?.message} by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module.toLowerCase() === 'map' && snapshot.actionType.toLowerCase() === 'delete'){
            return `${snapshot?.responseData?.message} by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }

        if(snapshot.module.toLowerCase() === 'map' && snapshot.actionType.toLowerCase() === 'update'){
            return `${snapshot?.responseData?.message} by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}` 
        }
        if(snapshot.module.toLowerCase() === 'errorticket' && snapshot.actionType.toLowerCase() === 'create'){
            return `Change Request ${snapshot?.requestData.ticketNumber} was created by ${snapshot?.createdBy?.fullName || snapshot?.createdBy?.firstName}, please make adjustment based on feedback` 
        }
    }

    const renderThumbVerticalView = ({ style, ...props }) =>(
                <div {...props} style={{ ...style, 
                    backgroundColor: 'rgba(15, 42, 111, 1)',
                    borderRadius:'inherit',
                    cursor:'pointer'}}/>)

    return(
        <CommonLayout  className="content-mr"breadCrumbText='Snapshot/History'>
            {
             (loadingState || loading) ? 
                <div className='snapshotTipDiv'>
                    <Spin size='middle' tip='Loading...'/>
                </div>

               :  
            <>
            <Scrollbars 
                style={{ width: "100%", height: 'calc(100vh - 101px)'}}
                renderThumbVertical={renderThumbVerticalView}
            >
                <div className="snapshot-card">
                <div className="snapshot-head">
                    <div className="snapshotTitleWrapper">
                        <div className="snapshotTitle">
                            <span className='snapshotTitleText'>Logs</span>
                        </div>

                        <div className="snapshotFilterGrp">
                            <Select placeholder={"Filter By.."} 
                            onChange={handleModuleSelect}
                            value={module}
                            >
                                {filterSelectOptionsChildren}
                            </Select>
                        </div>
                    </div>
                </div>

                <div className="snapshot-body">
                    <div className="snapshotListWrapper">
                        <div className="snapshotSingleListWrapper">
                            {
                                snapshots.map((item) =>(
                                    <div className="snapshotSingleList" key={item._id}>
                                        <div className="snapLeftDiv">
                                            <span className="snapListTitleText">
                                                {item.errorMsg ? item.errorMsg : displayMsg(item)}
                                            </span>
                                            <span className='snapListSubTitleText'>
                                                {item?.module?.toLowerCase() === 'map' ? item?.module?.toUpperCase() : item?.module.charAt(0).toUpperCase() + item?.module.slice(1)} 
                                               - 
                                                {item?.actionType.charAt(0).toUpperCase() + item?.actionType.slice(1)}
                                            </span>
                                        </div>

                                        <div className="snapRightDiv">
                                            <span className='snapDateText'>
                                                {
                                                    item?.actionType.toLowerCase() === 'create' ? 
                                                    moment(item?.createdAt).format('ll') 
                                                    : moment(item?.updatedAt).format('ll')
                                                }
                                            </span>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            </Scrollbars>

            <div className='mt-10'>
                <Pagination 
                    pageSize={10}
                    showTotal={total => `Total ${total} items`}
                    current={paginateDetails && parseInt(paginateDetails.pageNumber)+1} 
                    total={paginateDetails && paginateDetails.total} 
                    onChange={handleLoadMore}
                    showSizeChanger={false}
                    />
            </div>
           </> }
        </CommonLayout>
    )
}

export default Snapshot;