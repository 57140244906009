import store from "store";

export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const SET_MESSAGE = "SET_MESSAGE";

export const showLoader = (payload: boolean) => {
  store.dispatch({
    type: TOGGLE_LOADER,
    payload,
  });
};

export const showMessage = (payload: string) => {
  store.dispatch({
    type: SET_MESSAGE,
    payload,
  });
};
