import { HttpService } from "services/network/http.service";
import qs from "qs";
import logger from "common/logger";
import { triggerDownload } from "common/helper";

type TReportListVariables = Partial<{
  filters: Partial<{
    search: string;
  }>;
  orderBy?: keyof typeof SORT_ORDER;
}>;
const SORT_ORDER = {
  ascend: "ASC",
  descend: "DESC",
} as const;
export default class ReportService extends HttpService {
  // async getReportList(variables: TReportListVariables) {
  //   debugger;
  //   try {
  //     const { filters = {}, ...rest } = variables;
  //     const generatedFilters = Object.entries(filters ?? {})
  //       .filter(([_key, value]) => Boolean(value))
  //       .reduce((acc: any, [key, value]) => {
  //         acc[key] = value;
  //         return acc;
  //       }, {});
  //     const params = qs.stringify(
  //       { ...rest, filters: generatedFilters },
  //       { arrayFormat: "repeat" }
  //     );

  //     const response = await this.get(`/api/reports?${params}`);
  //     return response;
  //   } catch (error) {
  //     logger.error(error);
  //   }
  // }

  async getReportList(variables: TReportListVariables) {
    try {
      const { filters = {}, orderBy, ...rest } = variables;
      const generatedFilters = Object.entries(filters ?? {})
        .filter(([_key, value]) => Boolean(value))
        .reduce((acc: any, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      const paramsObj: any = { ...rest, filters: generatedFilters };

      if (orderBy) {
        paramsObj.orderBy = SORT_ORDER[orderBy];
      } else {
        delete paramsObj.orderBy;
      }

      const params = qs.stringify(paramsObj, { arrayFormat: "repeat" });
      const response = await this.get(`/api/reports?${params}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async getReportById(reportId: string) {
    try {
      const response = await this.get(`/api/reports/${reportId}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async updateReportById(reportId: string, variables: any = {}) {
    try {
      const response = await this.put(`/api/reports/${reportId}`, variables);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async deleteReportById(reportId: string) {
    try {
      const response = await this.delete(`/api/reports/${reportId}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async getReportFields() {
    try {
      const response = await this.get("/api/reports/fields");
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async createReport(variables: any) {
    try {
      const response = await this.post("/api/reports", variables);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async cloneReport(reportId: string) {
    try {
      const response = await this.post(`/api/reports/clone/${reportId}`);
      return response;
    } catch (error) {
      logger.error(error);
    }
  }

  async downloadReport(reportId: string, fileName: string = "Report.csv") {
    try {
      const response = await this.get(`/api/reports/download/${reportId}`);
      triggerDownload(response, fileName);
    } catch (error) {
      logger.error(error);
    }
  }
}
