import { setPage } from "actions/app";
import { getMapFormBuilder } from "actions/mapFormBuilder";
import { Spin, Steps } from "antd";
import CommonLayout from "components/layout/Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  FIELD_REQUIRED } from "utils/constants";
import Element from "./Element";
import FirstStep from "./stepComponents/FirstStep";
import SecondStep from "./stepComponents/SecondStep";
import ThirdStep from "./stepComponents/ThirdStep";
const { Step } = Steps;

const MapCreate = ({showCreate,setShowCreate}) => {
  const dispatch = useDispatch();
  const mapFormBuilder = useSelector((state) => state.mapFormBuilder.mapFormBuilder)
  const [step,setStep] = useState(0)
  const [mapType,setMapType] = useState(null)
  const [mapTypeError,setMapTypeError] = useState('')
  const [selectedSku,setSelectedSku] = useState([])
  const [selectedBrand,setSelectedBrand] = useState([])
  const [selectedSkuError,setSelectedSkuError] = useState([])
  const [selectedBrandError,setSelectedBrandError] = useState([])
  const [loadingState,setLoadingState] = useState(false)
  
  const [skuDataSource,setSkuDataSource] = useState([])
  const [brandDataSource,setBrandDataSource] = useState([])

  //third steps
  const [skuPayload,setSkuPayload] = useState([])
  const [tableData,setTableData] = useState([])

  useEffect(() => {
        dispatch(setPage('MAP Create'))
    },[dispatch])

  useEffect(() => {
        const getCall = async() =>{
        setLoadingState(true)
        const res = await dispatch(getMapFormBuilder())
        if(res){
          setLoadingState(false)
        }else{
        setLoadingState(false)
        }
      }
      getCall()
    },[dispatch])

  const handleMapType = (value) => {
    setMapTypeError(value === null ? FIELD_REQUIRED : '')
    setMapType(value)
  }

  useEffect(() => {
    if(skuPayload && skuPayload.length>0 && skuPayload[0].mapType === 'sku'){
      const rows = [];
      const {dynamicRecord} = skuPayload[0];

      dynamicRecord.forEach((rec,i) => {
        let obj = {}
        obj = {
          'key': i,
          'sku': rec.sku,
          'mapPriceFlag': rec.mapPriceFlag
        }

        rec.fieldData.forEach((fd,i) => {
          const obj1 = {
            [fd.questionHeader] : <Element
                                    key={i}
                                    question={fd?.question_id}
                                    index={i}
                                    questionType={fd?.question_id?.questionType}
                                    questionHeader={fd?.question_id?.questionHeader}
                                    options={fd?.question_id?.optionValue}
                                    designate={fd?.question_id?.designate}
                                    action={'view'}
                                    answer={fd.value || ''}
                                    subAnswer={fd.subAnswer || ''}
                                    subAnswerQuestionType={fd.subAnswerQuestionType || ''}
                                />,
          }

          obj = { ...obj, ...obj1}
        })

        rec?.fieldSysData.forEach(fd => {
          const obj2 = {
            [fd.label] : fd.value
          }

          obj = {...obj,...obj2}
        })

        const lastObj = {
          'mapPriceUpdate': '',
          'mapPriceLoad':'',
        }

        obj = {...obj, ...lastObj}


        rows.push(obj)
      })

      setTableData(rows)
    }
  },[skuPayload])

  const handleSelectedSku = (value) => {
    setSelectedSkuError((value === '' || value === null || value.length === 0) ? FIELD_REQUIRED : '')
    setSelectedSku(value)
    setSelectedBrand([])
    setBrandDataSource([])

    const data = value.map((item,i) => {
      return {
        key: i,
        sku : item
      }
    })

    const qsHeader = mapFormBuilder && mapFormBuilder.length>0 && mapFormBuilder[0].skuQuestionIds.map(item => {
        return {
          [item.questionHeader]:''
        }
      })

      for (let i = 0; i < data.length; i++) {
        let obj= {}
        for (let j = 0; j < qsHeader.length; j++) {
          obj= {...obj,...qsHeader[j]}
        }
        data[i] = {
          ...data[i],...obj
        }
      }

    setSkuDataSource(data)
  }

  const handleSelectedBrand = (value) => {
    setSelectedBrandError((value === '' || value === null || value.length === 0) ? FIELD_REQUIRED : '')
    setSelectedBrand(value)
    setSelectedSku([])
    setSkuDataSource([])
    setSkuPayload([])

    const data = value.map((item,i) => {
      
      return {
        key: i,
        brand : item,
      }
    })

    const qsHeader = mapFormBuilder && mapFormBuilder.length>0 && mapFormBuilder[0].brandQuestionIds.map(item => {
        return {
          [item.questionHeader]:''
        }
      })

      for (let i = 0; i < data.length; i++) {
        let obj= {}
        for (let j = 0; j < qsHeader.length; j++) {
          obj= {...obj,...qsHeader[j]}
        }
        data[i] = {
          ...data[i],...obj
        }
      }
    
      setBrandDataSource(data)
  }

  const stepDisplay = () => {
    if (step === 0) {
      return <FirstStep step={step} setStep={setStep} mapType={mapType} handleMapType={handleMapType}
              mapTypeError={mapTypeError} setMapTypeError={setMapTypeError}
              selectedSku={selectedSku} selectedBrand={selectedBrand}
              handleSelectedSku={handleSelectedSku} handleSelectedBrand={handleSelectedBrand}
              setSelectedBrandError={setSelectedBrandError} setSelectedSkuError={setSelectedSkuError}
              selectedBrandError={selectedBrandError} selectedSkuError={selectedSkuError}
      />
    } else if (step === 1) {
      return <SecondStep step={step} setStep={setStep} mapType={mapType} skuDataSource={skuDataSource} brandDataSource={brandDataSource}
              mapFormBuilder={mapFormBuilder}
              setBrandDataSource={setBrandDataSource} setSkuDataSource={setSkuDataSource}
              selectedBrand={selectedBrand}
              selectedSku={selectedSku}
              setSkuPayload={setSkuPayload}
              skuPayload={skuPayload}
              />
    } else {
      return <ThirdStep step={step} setStep={setStep} setSkuPayload={setSkuPayload}
              skuPayload={skuPayload}
              tableData={tableData} setTableData={setTableData}
              />
    }
  };

    return(
       <CommonLayout className="content-mr" breadCrumbText='MAP Create' backUrl={'/map'} backButton={true}>
        {
          loadingState ? 
              <div className="errorTipDiv">
              <Spin size='middle' tip='Loading...'/>
            </div> :

            <>

              

              <div className="map-create-card">
                <div>
                  <Steps current={step}>
                    <Step title="MAP Type" />
                    <Step title="Fill Details" />
                    <Step title="Review" />
                  </Steps>
                </div>

                <div className="mt-30 d-flex">
                  {stepDisplay()}
                </div>
              </div>
            </>
        }
            
       </CommonLayout>
    )
}

export default MapCreate;