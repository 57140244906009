import { MainMenu,BottomMenu }            from 'utils/sidebar-menu';

export default class AppStateManager {
    static setPage = (prevState, action) => {
        const state        = { ...prevState };
        const { pageName } = action;
        const menu         =  MainMenu;
        let currentP       = "";
        let index          = menu.findIndex((o) => { return o.title === pageName });
        if (index === -1) {
            index    = BottomMenu.findIndex((o) => { return o.title === pageName });
            if (index !== -1) {
                currentP = BottomMenu[index].id;
            }
            else {
                let mainIndex = -1;
                menu.every((o, idx) => {
                    mainIndex = idx;
                    index = o.children.findIndex((c) => { 
                        return c.title === pageName 
                    });
                    if (index !== -1) {
                        return false;
                    }
                    return true;
                })
                currentP = (index !== -1 && mainIndex !== -1) ? menu[mainIndex].children[index].id : "";
            }
        }
        else {
            currentP = menu[index].id;
        }
        document.title      = `${pageName} | Promo Tool`;
        state.currentPage   = (state.isCurrentPageChangeable) ? currentP : state.currentPage;
        state.isCurrentPageChangeable = true;
        return state;
    }

}