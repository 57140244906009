import "./sku.css";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fullscreen from "fullscreen-react";
import { Input, Modal, Spin, Row, Button } from "antd";
import {
  ColumnHeightOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  LoadingOutlined,
  ExportOutlined
} from "@ant-design/icons";
import CustomTable from "components/common/table/Table";
import CommonLayout from "components/layout/Layout";
import { Outlet , useNavigate} from "react-router-dom";
import { SKU_LIST_COLUMNS } from "utils/column";
import { getSKU, getSingleSKU, getSearchSKU, getSkuExportData } from "actions/sku";
import { setPage } from "actions/app";
import { Alert } from 'antd';

const Sku = () => {
  const [isEnter, setIsEnter] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { skuList, singleSKUData, skuPage,searchedValue,skuSearchList } = useSelector((state) => state.sku);
  const [modalOpen, setModalOpen] = useState(false);
  const [tableSize, setTableSize] = useState("default");
  const [loadingState, setLoadingState] = useState(false);
  const [loadingSingle, setLoadingSingle] = useState(false);
  const [tableClicked, setTableClicked] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [reloadingState,setReLoadingState] = useState(false);
  const [timer, setTimer] = useState(null)
  const [searched, setSearched] = useState(false)
  const [brandSearchText,setBrandSearchText] = useState('')
  const [skuSearchText,setSkuSearchText] = useState('')
  const [manufacturerSearchText,setmanufacturerSearchText] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentSuccessMsg,setCurrentSuccessMsg] = useState('')

  useEffect(() => {
    if(!searched){
      const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getSKU(pageNo));
      if (res) {
        setLoadingState(false);
      }
    };
    getCall();
    dispatch(setPage("SKU"));
    }
  }, [dispatch, pageNo,searched]);

  const handleReload = async() => {
    if(searched){
      setReLoadingState(true)
    const res = await dispatch(getSearchSKU(searchedValue.skuSearchText,searchedValue.brandSearchText,searchedValue.manufacturerSearchText))
    if(res){
      setReLoadingState(false)
    }
    }else{
      setReLoadingState(true)
    const res = await dispatch(getSKU(pageNo))
    if(res){
      setReLoadingState(false)
    }
    }
  }

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const viewSKU = async (record) => {
    setModalOpen(true);
    setLoadingSingle(true);
    const res = await dispatch(getSingleSKU(record._id));    
    if (res.error === undefined) {
      setLoadingSingle(false);
    }
  };

  const viewPromoDetails = (record) => {
    navigate(`/skupromodetails/${record._id}`);
  };

  // const onSearch = async (value) => {
  //   setSearchedPromo(
  //     skuList.filter((item) =>
  //       item.sbu_desc.toLowerCase().trim().includes(value.toLowerCase().trim())
  //     )
  //   );
  // };

  const handleSearchChange = () => {

    if(!skuSearchText && !brandSearchText && !manufacturerSearchText){
      return;
    }
     
    clearTimeout(timer)
        const newTimer = setTimeout(async() => {
        setLoadingState(true)
        const res = await dispatch(getSearchSKU(skuSearchText,brandSearchText,manufacturerSearchText))
        if(res.error === undefined){
          setLoadingState(false)
          setSearched(true)
        }else{
          setLoadingState(false)
          setSearched(false)
        }
      }, 700)
      setTimer(newTimer)

    }

    const handleSearchClear = async() => {
      if(!skuSearchText && !brandSearchText && !manufacturerSearchText){
      return;
    }
      setLoadingState(true)
      setSearched(false)
      setBrandSearchText('')
      setSkuSearchText('')
      setmanufacturerSearchText('')

      const res = await dispatch(getSKU(0))
      if(res){
        setLoadingState(false)
      }
    }

  const skuPagination = async (record) => {
    setPageNo(record - 1);
  };

  const setupRows = (skuList) => {
    return skuList?.map((o) => {
      return {
        sku: o.sku_id,
        product: o.product,
        dayId: o.id,
        brand: o.sku_brand_desc,
        _id: o.sku_id,
        productId: o.product_sku_id || '',
        buyerdesc:o.buyer_desc,
        classdesc:o.class_desc,
        skumanufacturerdesc:o.sku_manufacturer_desc,
        subclassdesc:o.sub_class_desc
      };
    });
  };

  const exportSkuData = async () => {
    let data = {
      "sortBy":"",
      "orderBy":"DESC",
      "search":{
           "sku_id": skuSearchText || "",
           "sku_brand_desc": brandSearchText || "",
           "sku_manufacturer_desc": manufacturerSearchText || ""
      },
      "filters":{} 
   }
    setButtonLoading(true);
    const res = await dispatch(
      getSkuExportData(data)
    );
      setCurrentSuccessMsg(res?.payload?.data?.message);
      setTimeout(() => {        
        setCurrentSuccessMsg('');
      }, 6000); 
      setButtonLoading(false);
  };
  return (
    <CommonLayout className="content-mr" breadCrumbText="SKU Management">
      {currentSuccessMsg && (
          <Alert
            message={currentSuccessMsg}
            closable
            type="success"
            showIcon
          />
        )}
      {
        (loadingState || reloadingState) ? <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div> :
        (<>
          {" "}
          <div className="skuadd-user-card">
            <div className="skuright-content">
              <div className="inputSkuGroup">
                <Input placeholder="Search By SKU"  
              className="skuSearchInput"
              onChange={(e) => setSkuSearchText(e.target.value)}
              value={skuSearchText}
              />
              <Input placeholder="Search By Brand"  
              onChange={(e) => setBrandSearchText(e.target.value)} 
              className="skuSearchInput"
              value={brandSearchText}
              />
              <Input placeholder="Search By SKU Manufacturer Description"  
              onChange={(e) => setmanufacturerSearchText(e.target.value)}
              value={manufacturerSearchText}
            className="skuSearchInput"
              />

              <Button type="primary" className='addUserBtn' onClick={handleSearchChange}>Search</Button>
              <Button danger onClick={handleSearchClear}>Clear</Button>
              </div>
            </div>
          </div>
          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="user-table-card">
              <div className="table-topbar">
                <div className="shelf-left-content">
                  <div className="filterGrpWrapper">
                    <div className="settingGrp">
                    <ReloadOutlined onClick={handleReload} className='reloadIconStyle'/>
                      <ColumnHeightOutlined
                        className="reloadIconStyle"
                        onClick={handleTableHeight}
                      />
                      <FullscreenOutlined
                        onClick={() => {
                          setIsEnter(true);
                        }}
                        className="reloadIconStyle"
                      />

                      <Button
                        icon={
                          buttonLoading ? (
                            <LoadingOutlined />
                          ) : (
                            <ExportOutlined />
                          )
                        }
                        onClick={exportSkuData}
                        disabled={buttonLoading} 
                      >
                        Export
                      </Button>

                    </div>
                  </div>
                </div>
              </div>

              <Outlet />

              {/* table starts */}
              <div className="w-100 mt-16">
                {
                  searched ?  <CustomTable
                    columns={SKU_LIST_COLUMNS}
                    rows={setupRows(skuSearchList)}
                    isViewable={true}
                    isViewablePromoDetails={true}
                    onView={viewSKU} 
                    onViewPromoDetails={viewPromoDetails}
                    rowKey="_id"
                    total={skuList?.length}
                    size={tableSize}
                    isPopup={true}
                    /> :<CustomTable
                  columns={SKU_LIST_COLUMNS}
                  rows={setupRows(skuList)}
                  rowKey="_id"
                  isViewable={true}
                  isViewablePromoDetails={true}
                  total={skuPage?.total}
                  onView={viewSKU}
                  onViewPromoDetails={viewPromoDetails}
                  onChange={skuPagination}
                  current={pageNo + 1}
                  size={tableSize}
                  isPopup={true}
                />
                }
              </div>
              <Modal
                visible={modalOpen}
                onCancel={handleCancel}
                footer={null}
                title={"View Details"}
                destroyOnClose
              >
                {!loadingSingle ? (
                  <div>
                    <label className="label-style">SKU Key</label>
                    <Row gutter={1} className="mb-25 row-style">
                      <span>{singleSKUData && singleSKUData.sku_key}</span>
                    </Row>
                    <label className="label-style">SBU Description</label>
                    <Row gutter={1} className="mb-25">
                      <span>{singleSKUData && singleSKUData.sbu_desc}</span>
                    </Row>
                    <label className="label-style">SKU Brand Description</label>
                    <Row gutter={1} className="mb-25">
                      <span>
                        {singleSKUData && singleSKUData.sku_brand_desc}
                      </span>
                    </Row>
                    <label className="label-style">SKU Brand ID</label>
                    <Row gutter={1} className="mb-25">
                      <span>
                        {singleSKUData && singleSKUData.sku_brand_id}
                      </span>
                    </Row>
                  </div>
                ) : (
                  <div className="errorTipDiv">
                    <Spin size="middle" tip="Loading..." />
                  </div>
                )}
              </Modal>
            </div>
          </Fullscreen>
        </>
      ) }
    </CommonLayout>
  );
};

export default Sku;
