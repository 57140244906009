import logo from "image/Frame 147.svg";
import { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { MailOutlined, LeftOutlined } from "@ant-design/icons";
import {
  FormControl,
  Container,
  WrapperImg,
} from "components/common/LoginStyle";
import Footer from "components/common/footer";
import Error from "components/common/error";
import { FIELD_REQUIRED } from "utils/constants";
import { Validator } from "utils/validations";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPass } from "actions/login";
import { setPage } from "actions/app";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [userError, setUserError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setPage("Forgot Password"));
  }, [dispatch]);

  const handleForgot = async (e) => {
    e.preventDefault();
    if (userError !== "" || user === "") {
      if (user === "") {
        setUserError(FIELD_REQUIRED);
      } else {
        setUserError(userError);
      }
      return;
    }

    setLoading(true)

    const res = await dispatch(
      forgotPass({
        email: user,
      })
    );

    if (res.error === undefined) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleUserChange = (e) => {
    const { value } = e.target;
    setUser(value);
    setUserError(Validator.validate("email", value, null, null, true));
  };

  return (
    <>
      <Container>
        <img src={logo} alt="logo" />
        <WrapperImg>
          <div className="head-box">
            <div className="item-count-forgot">
              <div className="item-tab-forgot">
                <span className="tab1-forgot">
                  <span className="text-mode-forgot">{"Reset password"}</span>
                </span>
                <span className="ink-forgot"></span>
              </div>
            </div>
            <span className="line-only"></span>
          </div>
          <Error />
          <form className="form" onSubmit={handleForgot}>
            <FormControl>
              <Input
                type="text"
                size="large"
                placeholder="Email"
                prefix={<MailOutlined />}
                value={user}
                onChange={handleUserChange}
              />
              <span className="error-label">{userError}</span>
            </FormControl>

            <Button
              onClick={handleForgot}
              className="addUserBtnAuth"
              disabled={loading}
              block
            >
              {loading ? `Loading...` : "Send password reset link"}
            </Button>
            <div className="back-login">
              <Link to="/login" className="links">
                <LeftOutlined />
                <span className="back-login-text">Back to login</span>
              </Link>
            </div>
          </form>
          <Footer />
        </WrapperImg>
      </Container>
    </>
  );
};

export default ForgotPassword;
