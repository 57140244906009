export default class FormBuilderStateManager{
    static getAllQuestions = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.questions = data.questionList || [];

        return state;
    }

    static createQuestion = (prevState, action) => {
        const state = { ...prevState };

        state.questions=[action.payload.data.data,...state.questions];
       
        return state;
    } 

    static updateQuestion = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta.questions;
        
        const index=state.questions.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.questions[index]={ ...state.questions[index], ...newRecord };
          state.questions = [ ...state.questions ];
        }
  
        return state;
    }

    static deleteQuestion = (prevState, action) => {
        const state             = { ...prevState };
        const  id    = action.meta.previousAction.meta._id;
    
        state.questions = state.questions.filter((item) => item._id !== id)
        
        return state;
    }

    static getFormBuilder = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.formBuilder = data.formBuilder || [];

        return state;
    }

    static createFormBuilder = (prevState, action) => {
        const state = { ...prevState };

        state.formBuilder=[action.payload.data.data,...state.formBuilder];
       
        return state;
    }

    static updateFormBuilder = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.formBuilder.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.formBuilder[index]={ ...state.formBuilder[index], ...newRecord };
          state.formBuilder = [ ...state.formBuilder ];
        }
  
        return state;
    }

    static updateQuestionStatus = (prevState, action) => {
        const state = { ...prevState };
        
        const newRecord=action.meta.previousAction.meta;
        
        const index=state.questions.findIndex(item=>item._id === newRecord._id);
        if (index !== -1) {
          state.questions[index]={ ...state.questions[index], ...newRecord };
          state.questions = [ ...state.questions ];
        }
  
        return state;
    }

}