class Session {
    get token() {
        return localStorage.getItem("token") || null;
    }

    setToken = (token) => {
        localStorage.setItem("token", token);
    }

    get user() {
        return localStorage.getItem("user") || null;
    }

    setUser = (user) => {
        localStorage.setItem("user", user);
    }

    get password() {
        return localStorage.getItem("password") || null;
    }

    setPassword = (password) => {
        localStorage.setItem("password", password);
    }

    get checkbox() {
        return localStorage.getItem("checkbox") || null;
    }

    setCheckbox = (checkbox) => {
        localStorage.setItem("checkbox", checkbox);
    }

    get fullName() {
        return localStorage.getItem("fullName") || null;
    }

    setFullName = (fullName) => {
        localStorage.setItem("fullName", fullName);
    }

    get role() {
        return localStorage.getItem("role") || null;
    }

    setRole = (role) => {
        localStorage.setItem("role", role);
    }

    get isAdmin() {
        return this.role?.toLowerCase() === 'admin';
    }

    get userId() {
        return localStorage.getItem("userId") || null;
    }

    setUserId = (userId) => {
        localStorage.setItem("userId", userId);
    }
    get teamId() {
        return localStorage.getItem("teamId") || null;
    }

    setTeamId = (teamId) => {
        localStorage.setItem("teamId", teamId);
    }
    get teamDetail() {
        const teamDetail = localStorage.getItem("teamDetail");
        return teamDetail ? JSON.parse(teamDetail) : null;
    }
    setTeamDetail = (teamDetail) => {
        teamDetail ? localStorage.setItem("teamDetail", JSON.stringify(teamDetail)) : localStorage.setItem("teamDetail", null);
    }
    get permissions() {
        return JSON.parse(localStorage.getItem("permissions")) || null;
    }

    setPermissions = (permissions) => {
        localStorage.setItem("permissions", JSON.stringify(permissions));
    }

    clear = () => {
        localStorage.clear();
    };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Session();
