import "./SkuPromoDetails.css";
import React from "react";
import { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Tabs,Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomTable from "components/common/table/Table";
import CommonLayout from "components/layout/Layout";
import TableSearchDropdown                                                        from 'components/TableSearchDropdown';
import { Outlet } from "react-router-dom";
import {  PROMOCODE_LIST_COLUMNS } from "utils/column";
import { useParams } from "react-router";
import data from "utils/dataList.json";
import { setPage } from "actions/app";
import { getPromoAssociatedSku } from "actions/promotions";

const { TabPane } = Tabs;

const SkuPromoDetails = () => {
  const dispatch = useDispatch(); 
  const { promoAssociatedSku } = useSelector((state) => state.promo);
  const { id } = useParams();
  const [key, setKey] = useState("promocode");
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      const res = await dispatch(getPromoAssociatedSku(id));

      if(res){
        setLoadingState(false);
      }
    };
    getCall();
    dispatch(setPage("SKU"));
   
  }, [dispatch,id]);

  const handleTab = (key) => {
    setKey(key);
    setLoadingState(false)
  };   


  const setupRowsPromo = (promoAssociatedSku) => {
    return promoAssociatedSku?.map((o) => {
      const oStatus =
        (o.status === "Submitted" && <Tag color={"red"}>Submitted</Tag>) ||
        (o.status === "Pending" && <Tag color={"red"}>Pending</Tag>) ||
        (o.status === "Expired" && (
          <Tag className="status-symbol-expired">Expired</Tag>
        )) ||
        (o.status === "Incomplete" && (
          <Tag className="status-symbol-incomplete">Incomplete</Tag>
        )) ||
        (o.status === "Approved" && (
          <Tag className="status-symbol-approved">Approved</Tag>
        )) ||
        (o.status === "Live" && (
          <Tag className="status-symbol-live">Live</Tag>
        )) ||
        (o.status === "Rejected" && (
          <Tag className="status-symbol-rejected">Rejected</Tag>
        )) ||
        (o.status === "Live-Errors" && (
          <Tag className="status-symbol-lv">Live-Errors</Tag>
        )) ||
        (o.status === "Pending-Errors" && (
          <Tag className="status-symbol-pv">Pending-Errors</Tag>
        )) ||
        (o.status === "Approved-Errors" && (
          <Tag className="status-symbol-av">Approved-Errors</Tag>
        )) ||
        (o.status === "Expired-Errors" && (
          <Tag className="status-symbol-pv">Expired-Errors</Tag>
        )) ||
        (o.status === "Incomplete-Errors" && (
          <Tag className="status-symbol-iv">Incomplete-Errors</Tag>
        ))||
        (o.status === "Submitted-Errors" && (
          <Tag className="status-symbol-iv">Submitted-Errors</Tag>
        ))
      return {
        promoname: o.name,
        _id: o._id,
        status: oStatus,
        expiringdate: o?.endDate || '',
      };
    });
  };

  const setColumnsPromocodes = () => {
        let cols = [];
    
        PROMOCODE_LIST_COLUMNS.forEach((o) => {
            if (o.dataIndex === "promoname") {
                const colsOptions = {
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        return (
                            <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                                                 selectedKeys={selectedKeys}
                                                 confirm={confirm}
                                                 clearFilters={clearFilters}
                                                 dataIndex={o.dataIndex} />
                        )
                    },
                    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                    onFilter: (value, record) => {
                    
                        const rec = record[o.dataIndex]
                                        ? record[o.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                                        : '';
                        return rec;
                    },
                    
                }
                cols.push({ ...o, ...colsOptions });
            }
            else {
                cols.push(o);
            }
               
        });
        return cols
    }
  
  return (    
    <CommonLayout breadCrumbText="SKU Management" className="content-mr" backButton={true} id={id} backUrl={'/sku'}  
    >
     {!loadingState?(<div><div className="sku-card">
        <div className="page-header1">
          <div className="layout-block">
            <div className="heading-left">
              <div className="heading-left-wrapper">
              <span className="head-text">{id}</span>
              </div>
            </div>
          </div>          

          <div className="tabs-top">
            <div className="tabs-wrapper">
              <div className="sku-box-shadow"></div>
              <div className="tabs-groups">
                <Tabs defaultActiveKey="promocode" onChange={handleTab}>
                  <TabPane tab="Promo codes" key="promocode"></TabPane>                  
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div className="user-table-card">
          <Outlet />

          {/* table starts */}

          <div className="w-100 mt-16">
            {
              key === "promocode"
               && (
              <CustomTable
                columns={setColumnsPromocodes()}
                rows={setupRowsPromo(promoAssociatedSku)}
                rowKey="_id"               
                total={data?.promoAssociatedSku?.length}
              />
            )}
           
          </div>
        </div>
      </div></div>):(<div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>)}
      
    </CommonLayout>
  );
};

export default SkuPromoDetails;