export const UPDATE_LOCAL_USER_JSON = "UPDATE_LOCAL_USER_JSON";

export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_UNASSIGN_USERS = "GET_UNASSIGN_USERS";
export const GET_ALL_TEAM_USERS = "GET_ALL_TEAM_USERS";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const GET_USER_BY_NAME = "GET_USER_BY_NAME";
export const UPDATE_USER_BY_NAME = "UPDATE_USER_BY_NAME";
export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS";
export const ASSIGN_USER_TO_TEAM = "ASSIGN_USER_TO_TEAM";
export const UNASSIGN_USER_TO_TEAM = "UNASSIGN_USER_TO_TEAM";
export const UPDATE_LOCAL_USER_DATA = "UPDATE_LOCAL_USER_DATA";
