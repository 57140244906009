import * as ActionTypes             from './action-types';

export const createLocalShelfJSON = (shelf) => ({
    type: ActionTypes.CREATE_LOCAL_SHELF_JSON,
    shelf
})
export const updateLocalShelfJSON = (shelf) => ({
    type: ActionTypes.UPDATE_LOCAL_SHELF_JSON,
    shelf
})
export const deleteLocalShelfJSON = (shelf) => ({
    type: ActionTypes.DELETE_LOCAL_SHELF_JSON,
    shelf
})

export const getAllShelf = () => ({
    type: ActionTypes.GET_ALL_SHELF,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/shelf`,
            method: "get",
        }
    }
});


export const getSkuAssociatedShelf = (id='',pageNo='') => (
    {  
      type: ActionTypes.GET_SINGLE_SHELF,      
      payload: {
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/shelf/sku?page=${pageNo}&perPage=10&shelfId=${id}`,        
          method: "get",
        },
      },
}
);

export const getSearchSKU = (id='',skuSearchText='') => (
    {  
      type: ActionTypes.GET_ALL_SEARCH_SKU,      
      payload: {
        request: {
          url: `${process.env.REACT_APP_API_URL}/api/shelf/search?shelfId=${id}&fieldName=sku_id&fieldValue=${skuSearchText}`,        
          method: "get",
        },
      },
  }
  )

export const createShelf = (shelfPath,skuKeys) => ({
    type: ActionTypes.CREATE_SHELF,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/shelf`,
            method: "post",
            data:{
                "shelfPath": shelfPath,
                "skuKey": skuKeys,
            }
        }
    }
})

export const updateShelfById =(shelfId,shelfPath,skuKeys) => ({
    type: ActionTypes.UPDATE_SHELF,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/shelf/${shelfId}`,
            method: "put",
            data:{
                "shelfPath": shelfPath,
                "skuKey": skuKeys,
            }
        }
    },
    meta:{
            "shelfPath": shelfPath,
            "skuKey": skuKeys,
            "_id": shelfId,
        }
});

export const deleteShelfById = (shelfId) => ({
    type: ActionTypes.DELETE_SHELF,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/shelf/${shelfId}`,
            method: "delete",
        }
    },
    meta:{
        "_id": shelfId
    }
})

export const updateShelfBySearch = (shelf) => ({
    type: ActionTypes.UPDATE_SHELF_BY_SEARCH,
    shelf
})

export const getSKUBySearch = (skuKeys) => ({
    type: ActionTypes.GET_SKU_BY_SEARCH,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/sku/searchKey/${skuKeys}`,
            method: "get",
        }
    }
});

export const importShelf = (excelFile) => ({
    type: ActionTypes.IMPORT_SHELF,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/shelf/upload`,
            method: "post",
            data:excelFile
        }
    }
})

export const getShelfExportData = (shelfPath) => ({
    type: ActionTypes.GET_SHELF_EXPORT_DATA,
    payload: {
        request: {
            url: (shelfPath === "") ?`${process.env.REACT_APP_API_URL}/api/shelf/exporttoexcel`: `${process.env.REACT_APP_API_URL}/api/shelf/exporttoexcel/?shelfPath=${shelfPath}`,
            method: "get",
        }
    }
})