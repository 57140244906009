import { getSKUBySearch } from 'actions/shelf'
import { getBrand } from 'actions/sku'
import { Button, Col, Row, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FIELD_REQUIRED } from 'utils/constants'
const {Option} = Select

const FirstStep = ({step,setStep,mapType,handleMapType,setMapTypeError,mapTypeError,selectedBrand,selectedSku,handleSelectedBrand,handleSelectedSku,setSelectedBrandError,setSelectedSkuError,selectedBrandError,selectedSkuError}) => {
    const dispatch = useDispatch();

    useEffect(() => {
    if(mapType === 'brand'){
        setFetching(true)
        const getBrandCall = async () => {
            const res = await dispatch(getBrand())
            
            if(res.error === undefined){
                setBrandOptionData(res.payload.data.data || [])
                setFetching(false)
            }
        }
        getBrandCall()
    }
  },[mapType,dispatch])

    const [timer, setTimer] = useState(null)
    const [fetching, setFetching] = useState(false);
    const [dataSku, setDataSku] = useState([]);
    const [brandOptionData,setBrandOptionData] = useState([])

    const brandChildren = [];
    if(brandOptionData.length>0){
        for (let i = 0; i < brandOptionData.length; i++) {
                brandChildren.push(<Option key={brandOptionData[i].sku_brand_desc}>{brandOptionData[i].sku_brand_desc}</Option>);
            }
    }

    const skuChildren = [];
    if(dataSku.length>0){
        let nDataSku;
        if (selectedSku && selectedSku.length > 0) {
            nDataSku = [...selectedSku.map(sku => {
                return { "sku_key": sku };
            }), ...dataSku].filter((value, index, self) => index === self.findIndex((t) => t.sku_key === value.sku_key));
        } else {
            nDataSku = dataSku;
        }

        for (let i = 0; i < nDataSku.length; i++) {
            skuChildren.push(<Option key={nDataSku[i].sku_key}>{nDataSku[i].sku_key}</Option>);
        }
    }

    const handleStepChange = () => {
        if(mapType === null){
            setMapTypeError(FIELD_REQUIRED)
            return;
        }else{
            setMapTypeError('')
        }

        if(mapType === 'sku' && selectedSku.length === 0){
            setSelectedSkuError(FIELD_REQUIRED)
            return;
        }else{
            setSelectedSkuError('')
        }

        if(mapType === 'brand' && selectedBrand.length === 0){
            setSelectedBrandError(FIELD_REQUIRED)
            return;
        }else{
            setSelectedBrandError('')
        }
        setStep(step + 1)
    }

    const handleSearchSkuKeys = async(value) => {
      clearTimeout(timer)

      if(value.length>=3){
        const newTimer = setTimeout(async() => {
        setFetching(true)
        setDataSku([])
        const res = await dispatch(getSKUBySearch(value))
        if(res.error === undefined){
          setDataSku(res.payload.data.data)
          setFetching(false)
        }else{
          setFetching(false)
        }
      }, 700)
      setTimer(newTimer)
      }

      
    }
  return (
    <div>
        <Row gutter={16} className='mb-25'>
            <Col className="gutter-row" span={24}>
                <div>
                    <label>
                       <span className='spanReq'>*</span> Select MAP Type
                    </label>

                    <Select placeholder="Select" className='w-100 mt-5'
                        onChange={handleMapType}
                        value={mapType}
                    >
                        <Option key={'sku'}>SKU</Option>
                        <Option key={'brand'}>Brand</Option>
                    </Select>
                    <span className='errorLabel'>{mapTypeError}</span>
                </div>
            </Col>
        </Row>

        {
         mapType === 'sku' &&  
         <Row>
            <Col span={24}>
                <div>
                    <label>
                       <span className='spanReq'>*</span> Select SKU
                    </label>
                    <Select
                    showSearch
                      onChange={handleSelectedSku}
                    placeholder="Search and Select SKU"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearchSkuKeys}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    mode='multiple'
                    tokenSeparators={[',']}
                    maxTagCount={3}
                    className='w-100'
                    value={selectedSku}
                    >
                        {skuChildren.length>0 && skuChildren}
                    </Select>
                    <span className='errorLabel'>{selectedSkuError}</span>
                </div>
            </Col>
         </Row>
        }

        {
         mapType === 'brand' &&  
         <Row>
            <Col span={24}>
                <div>
                    <label>
                       <span className='spanReq'>*</span> Select Brand
                    </label>
                    <Select
                        showSearch
                        placeholder="Search and select Brand"
                        optionFilterProp="children"
                        className="w-100"
                        mode='multiple'
                        onChange={handleSelectedBrand}
                        value={selectedBrand}
                        maxTagCount={3}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                      >
                        {brandChildren.length>0 && brandChildren}
                    </Select>
                    <span className='errorLabel'>{selectedBrandError}</span>
                </div>
            </Col>
         </Row>
        }

        <div className='mt-30 text-end'>
            <Button className='footerBtnNavy' onClick={handleStepChange}>Next</Button>
        </div>
    </div>
  )
}

export default FirstStep