import * as ActionTypes             from './action-types';
import qs from "qs";

export const updateLocalUsersJSON = (users) => ({
    type: ActionTypes.UPDATE_LOCAL_USER_JSON,
    users
})

export const updateLocalUsersData = (userType) => ({
    type: ActionTypes.UPDATE_LOCAL_USER_DATA,
    userType
})

export const updateUserBySearch = (users) => ({
    type: ActionTypes.UPDATE_USER_BY_NAME,
    users
})

export const getAllUsers = () => ({
    type: ActionTypes.GET_ALL_USERS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users`,
            method: "get",
        }
    }
});

export const getAllTeamUsers = (teamId) => (
    {
    type: ActionTypes.GET_ALL_TEAM_USERS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users/team/${teamId}`,
            method: "get",
        }
    }
});

export const getUnassignUsers = (params) => {
    let url = `${process.env.REACT_APP_API_URL}/api/users/v2?filters[unassigned]=true`
    if (params.search) {
        url += `&filters[fullName]=${params.search}`
    }
    return {
    type: ActionTypes.GET_UNASSIGN_USERS,
    payload: {
        request: {
            url,
            method: "get",
            params: params,
            paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
            },
        }
    }
}};

export const createUser = (payload) => ({
    type: ActionTypes.CREATE_USER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users`,
            method: "post",
            data: payload
        }
    }
})

export const updateUserById =(payload) => ({
    type: ActionTypes.UPDATE_USER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users/${payload._id}`,
            method: "put",
            data: payload
        }
    },
    meta: payload
});

export const updateUserStatus =(userId,status) => ({
    type: ActionTypes.UPDATE_USER_STATUS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users/status/${userId}`,
            method: "patch",
            data:{
                "isActive": status,
            }
        }
    },
    meta:{
            "isActive": status,
            "_id": userId,
        }
});

export const assignUserToTeam =(users,teamId) => ({
    type: ActionTypes.ASSIGN_USER_TO_TEAM,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users/assignUsers/${teamId}`,
            method: "patch",
            data: users
        }
    }
});
export const unassignUserFromTeam =(users,teamId,isWarning) => 
   { 
    let url = `${process.env.REACT_APP_API_URL}/api/users/unassignUsers/${teamId}`;
    if (isWarning) {
        url += `?warning=yes`
    }
    return {
    type: ActionTypes.UNASSIGN_USER_TO_TEAM,
    payload: {
        request: {
            url,
            method: "patch",
            data: users
        }
    }
}};

export const deleteUserById = (userId) => ({
    type: ActionTypes.DELETE_USER,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users/${userId}`,
            method: "delete",
        }
    },
    meta:{
        "_id": userId
    }
})

export const getUserByName = (fullName) => ({
    type: ActionTypes.GET_USER_BY_NAME,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/users?fieldName=fullName&fieldValue=${fullName}`,
            method: "get",
        }
    }
});