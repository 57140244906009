import {
  DeleteOutlined,
  PlusOutlined,
  SisternodeOutlined,
  DownCircleOutlined
} from "@ant-design/icons";
import { Button, Input, Select, Checkbox } from "antd";
import { questionTypesSelectOption, showQuestionError, CircleTypeIcon } from "utils/constants";
import BottomSection from "./BottomSection";
const Option = Select;

const TabQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleAddSubOptionsTab,
  handleAddOptionsTab,
  handleDeleteOptionsTab,
  handleOptionTextChangeTab,
  handleSubOptionTypeChangeTab,
  handleDeleteSubOptionsTab,
  handleSuboptionTextChangeTab,
  index,
  handleSaveQuestion,
  handleQuestionDesignate,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
  isTab,
  deleteQuestion,
  copyQuestion,
  handleInnerSubOptionTextChange,
  handleDeleteInnerSubOptions,
  handleAddMoreInnerSubOptions,
  onRegexCheckboxChange,
  handleRegexChange,
}) => {
  return (
    <>
      {question.questionType === "tabs" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
            {question.questionType === "tabs" &&
              question.optionValue?.map((op, j) => (
                <>
                  <div
                    className="formBuilderFrameTwoDynamicOptionFrame"
                    key={j}
                  >
                    <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                      <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                        <Input
                          addonBefore={`${j + 1}st`}
                          addonAfter={
                            <SisternodeOutlined
                              onClick={() => handleAddSubOptionsTab(index, j)}
                            />
                          }
                          suffix={
                            <DeleteOutlined
                              className="cp"
                              onClick={() => handleDeleteOptionsTab(index, j)}
                            />
                          }
                          value={op.optionText}
                          onChange={(e) =>
                            handleOptionTextChangeTab(e.target.value, index, j)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    {op?.subOptions?.map((sOp, k) => (
                      <>
                        <div
                          className="formBuilderFrameTwoDynamicSubOptionFrame"
                          key={k}
                        >
                          <div className="formBuilderFrameTwoDynamicSubOptionValueFrame">
                            <div className="formBuilderFrameTwoDynamicSubOptionValueGroup">
                              <div className="formBuilderFrameTwoDynamicSubOptionValueGroupFrame">
                                <div className="formBuilderFrameTwoDynamicSubOptionValueGroupFirstChildWrapper">
                                  <Input placeholder="Label"
                                    addonAfter={
                                      <Select placeholder="Select" value={sOp.subOptionType}
                                        onChange={(value) => handleSubOptionTypeChangeTab(value, index, j, k)}>
                                        {questionTypesSelectOption
                                          .filter(
                                            (item) => item.value !== "Tabs"
                                          )
                                          .map((item) => (
                                            <Option value={item._id}>
                                              {item.icon} {item.value}
                                            </Option>
                                          ))}
                                      </Select>
                                    }
                                    suffix={
                                      <DeleteOutlined className="cp"
                                        onClick={() =>
                                          handleDeleteSubOptionsTab(index, j, k)
                                        }
                                      />
                                    }
                                    value={sOp.subOptionText}
                                    onChange={(e) =>
                                      handleSuboptionTextChangeTab(e.target.value, index, j, k)
                                    }
                                  />
                                </div>
                                <div className="formBuilderFrameTwoDynamicSubOptionValueGroupSubFrame">
                                  {sOp?.subOptions?.map((inSop, l) => (
                                    <>
                                      <div key={l} className="w-100">
                                        <Input
                                          addonBefore={
                                            inSop.subOptionType === "radio" ? (
                                              <CircleTypeIcon />
                                            ) : (
                                              <DownCircleOutlined />
                                            )
                                          }
                                          value={inSop.subOptionText}
                                          onChange={(e) =>
                                            handleInnerSubOptionTextChange(
                                              e.target.value,
                                              index,
                                              j,
                                              k,
                                              l
                                            )
                                          }
                                          suffix={
                                            <DeleteOutlined
                                              className="cp"
                                              onClick={() =>
                                                handleDeleteInnerSubOptions(
                                                  index,
                                                  j,
                                                  k,
                                                  l
                                                )
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    </>
                                  ))}
                                  {sOp.subOptionType === "input" && (
                                    <div key={index}>
                                      <Checkbox
                                        onChange={(e) =>
                                          onRegexCheckboxChange(
                                            e,
                                            index,
                                            j,
                                            k,
                                            "tabs"
                                          )
                                        }
                                        checked={
                                          sOp.isRegexRequired &&
                                            sOp?.isRegexRequired === true
                                            ? true
                                            : false
                                        }
                                      >
                                        Regex
                                      </Checkbox>
                                      {sOp?.isRegexRequired && (
                                        <div
                                          className="formBuilderFrameTwoPromoQuestion"
                                          key={index}
                                        >
                                          <label className="formBuilderFrameTwoPromoLabel">
                                            Conditions
                                          </label>
                                          <input
                                            type="text"
                                            className="formBuilderFrameTwoPromoQuestionInput"
                                            placeholder="Write Here"
                                            value={sOp.regex}
                                            onChange={(e) =>
                                              handleRegexChange(
                                                e,
                                                index,
                                                j,
                                                k,
                                                "tabs"
                                              )
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {
                                    <div
                                      className="formBuilderFrameTwoDynamicSubOptionValueAddFrame"
                                      style={{ padding: "4px 0" }}
                                    >
                                      {[
                                        "radio",
                                        "dropdown",
                                        "multiSelect",
                                        "checkbox",
                                      ].includes(sOp?.subOptionType) &&
                                        !sOp.isMaster && (
                                          <Button
                                            icon={
                                              <PlusOutlined
                                                style={{
                                                  marginRight: "-10px",
                                                  color: "#0F2A6F",
                                                }}
                                              />
                                            }
                                            className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                                            onClick={() =>
                                              handleAddMoreInnerSubOptions(
                                                index,
                                                j,
                                                k
                                              )
                                            }
                                          >
                                            Add Option
                                          </Button>
                                        )}
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ))}
          </div>

          {question.questionType === "tabs" && (
            <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
              <Button
                icon={
                  <PlusOutlined
                    style={{ marginRight: "-10px", color: "#0F2A6F" }}
                  />
                }
                className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                onClick={() => handleAddOptionsTab(index)}
              >
                Add Tab
              </Button>
            </div>
          )}
        </div>
      )}
      <BottomSection
        isSameType={question.questionType === "tabs"}
        onCheckboxChange={(e) => handleQuestionDesignate(e, index)}
        onSaveClick={() => handleSaveQuestion(question, index)}
        loading={loading}
        handleCancelEditView={handleCancelEditView}
        deleteQuestion={() => deleteQuestion(index)}
        copyQuestion={() => copyQuestion(index)}
        question={question}
        showError={() => showQuestionError(question)}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
      />
    </>
  );
};

export default TabQuestionView;
