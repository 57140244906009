import React, { Component }             from 'react';
import { Input, Button, Space }         from 'antd';
import { SearchOutlined }               from '@ant-design/icons';

class TableSearchDropdown extends Component {

  render () {
    const { dataIndex, selectedKeys, confirm, clearFilters, setSelectedKeys } = this.props;
    
    return (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    )
  }

  handleReset = clearFilters => {
    clearFilters();
    this.props.onReset && this.props.onReset()
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    
    confirm();
    this.props.onSearch && this.props.onSearch(selectedKeys[0], dataIndex);
  }
}

export default TableSearchDropdown