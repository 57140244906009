import './Shelf.css'
import { useEffect, useState }                                      from "react";
import { ColumnHeightOutlined, DeleteOutlined, 
    ExclamationCircleOutlined, 
    FileAddOutlined, FullscreenOutlined,SearchOutlined, 
    PlusCircleOutlined, ReloadOutlined, ExportOutlined, DownloadOutlined ,LoadingOutlined}                            from '@ant-design/icons';
import { Alert, Button, Col, Input, 
    Modal, Row, Select, Spin }                                 from 'antd';
import Fullscreen                                                   from "fullscreen-react";
import { useDispatch, useSelector }                                 from "react-redux";
import CommonLayout                                                 from "components/layout/Layout";
import CustomTable                                                  from 'components/common/table/Table';
import TableSearchDropdown                                          from 'components/TableSearchDropdown';
import { SHELF_LIST_COLUMNS }                    from 'utils/column';
import { Validator }                                                from 'utils/validations';
import { TextMessage, FIELD_REQUIRED, SECONDS  }                    from 'utils/constants';
import { setPage }                                                  from "actions/app";
import { createShelf, deleteShelfById,getAllShelf,
     getSKUBySearch,
     importShelf,
     updateShelfById,updateShelfBySearch,getShelfExportData}                           from 'actions/shelf';
import LoadingScreen from 'components/common/LoadingScreen';
import { useNavigate } from "react-router-dom";



const { Search } = Input;
const { Option } = Select;

const Shelf = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const shelf = useSelector(state => state.shelf.shelf)
    const searchedShelf = useSelector((state) => state.shelf.searchedShelf)
    const [loadingState,setLoadingState] = useState(false)
    const [reloadingState,setReLoadingState] = useState(false)

    useEffect(() => {
        dispatch(setPage('Shelf'))
    },[dispatch])

    useEffect(() => {
      const getCall = async() =>{
        setLoadingState(true)
        const res = await dispatch(getAllShelf())
        if(res){
          setLoadingState(false)
        }
      }
      getCall()
    },[dispatch])

    const [isEnter, setIsEnter] = useState(false);
    const [tableSize,setTableSize] = useState('default')
    const [tableClicked,setTableClicked] = useState(0)
    const [modalOpen,setModalOpen] = useState(false)
    const [currentAction,setCurrentAction] = useState('')
    const [currentRecord,setCurrentRecord] = useState({ })
    
    const [shelfPath,setShelfPath] = useState('')
    const [skuKeys,setSkuKeys] = useState([])
    const [shelfPathError,setShelfPathError] = useState('')
    const [skuKeysError,setSkuKeysError] = useState('')
    const [okBtnText,setOkBtnText] = useState('')
    const [showDeleteAlert,setShowDeleteAlert] = useState(false)
    const [showUpdateAlert,setShowUpdateAlert] = useState(false)
    const [showAddAlert,setShowAddAlert] = useState(false)
    const [loading,setLoading] = useState(false)
    const [searchedText,setSearchedText] = useState('')
    const [fetching, setFetching] = useState(false);
    const [dataSku, setDataSku] = useState([]);
    const [timer, setTimer] = useState(null);
    const [buttonLoading,setButtonLoading] = useState(false);


    const [showSpin,setShowSpin]= useState(false)
    const [showImportAlert,setShowImportAlert] = useState(false)
    const [showExportAlert,setShowExportAlert] = useState(false)

    const [importAlertMessage,setImportAlertMessage] = useState(false)
    const [importAlertType,setImportAlertType] = useState(false)

    const [exportAlertMessage,setExportAlertMessage] = useState(false)
    const [exportAlertType,setExportAlertType] = useState(false)



    useEffect(() => {
      if(searchedText === ''){
        dispatch(updateShelfBySearch([]))
      }
    },[searchedText,dispatch])

    useEffect(() => {
      if(showUpdateAlert){
        setTimeout(() => {
          setShowUpdateAlert(false)
        }, SECONDS);
      }
      if(showDeleteAlert){
        setTimeout(() => {
          setShowDeleteAlert(false)
        }, SECONDS);
      }
      if(showAddAlert){
        setTimeout(() => {
          setShowAddAlert(false)
        }, SECONDS);
      }

      if(showImportAlert){
        setTimeout(() => {
          setShowImportAlert(false)
        }, SECONDS);
      }
      if(showExportAlert){
        setTimeout(() => {
          setShowExportAlert(false)
        }, SECONDS);
      }
    },[showUpdateAlert,showDeleteAlert,showAddAlert,showImportAlert,showExportAlert])

    const setupRows = (shelf) => {
        return shelf?.map((o) => {
            return {
                "shelfPath": o.shelfPath,
                "_id": o._id,
                "skuKeys": o?.skuKey.length,
                "skuKeysValue": o.skuKey
            }
        }) 
    }
    
    const handleSearchSkuKeys = async(value) => {
      clearTimeout(timer)

      if(value.length>=3){
        const newTimer = setTimeout(async() => {
        setFetching(true)
        setDataSku([])
        const res = await dispatch(getSKUBySearch(value))
        if(res.error === undefined){
          setDataSku(res.payload.data.data)
          setFetching(false)
        }else{
          setFetching(false)
        }
      }, 700)

      setTimer(newTimer)
      }
    }
  
    const renderTag = () => {
       const children = [];
       if(dataSku.length>0){
        for (let i = 0; i < dataSku.length; i++) {
         children.push(<Option key={dataSku[i].sku_key}>{dataSku[i].sku_key}</Option>);
       }
       }
        return (
            <form 
            onSubmit={handleSubmit}
            >

            <Row gutter={16} className='mb-25'>
                  <Col className="gutter-row" span={24}>
                    <div>
                      <label>
                       <span className='spanReq'>*</span> Shelf Path
                      </label>

                      <Input placeholder='Write here' value={shelfPath} className='mt-5'
                      onChange={handleShelfPath}
                      />
                    <span className='errorLabel'>{shelfPathError}</span>
                    </div>
                  </Col>
            </Row>

            <Row gutter={16} className='mb-25'>
                  <Col className="gutter-row" span={24}>
                    <div>
                      <label>
                       <span className='spanReq'>*</span> SKU Keys
                      </label>

                      <Select
                        showSearch
                        value={skuKeys}
                        placeholder={'Search and select SKU Keys'}
                        style={{display:'block'}}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearchSkuKeys}
                        onChange={handleSkuKeys}
                        notFoundContent={fetching ? <Spin size="small" /> : null}
                        mode={fetching ? 'multiple' :'tags'}
                        tokenSeparators={[',']}
                        maxTagCount={3}
                      >
                        {children}
                      </Select>
                    <span className='errorLabel'>{skuKeysError}</span>
                    </div>
                  </Col>
            </Row>
               
            </form>
        );
    };

    const handleAdd = () => {
        setModalOpen(true)
        setCurrentAction('Create Shelf')
        setOkBtnText('Add')
    }

    const handleCancel = () => {
      setModalOpen(false)
      setCurrentAction('')
      setOkBtnText('')
      setShelfPath('')
      setSkuKeys([])
      setShelfPathError('')
      setSkuKeysError('')
    }

    const handleShelfPath = (e) => {
      const { value } = e.target;
    if(currentAction === 'Create Shelf'){
          setShelfPathError(shelf.findIndex(item => item.shelfPath === value) !== -1 ? "Shelf Path already exists!" : Validator.validate('title',value,null,null,true))
          setShelfPath(value)
      }else{
          setShelfPathError(Validator.validate('title',value,null,null,true))
          setShelfPath(value)
      }
    }

    const handleSkuKeys = (value) => {
        let errormsg=(value===""||value===null||value.length === 0)?"Select SKU Keys":"";
        setSkuKeysError(errormsg)
        setSkuKeys(value)
    }

    const handleSubmit = async (e) => {

      e.preventDefault()
      let newRecord= {}

      if(currentAction === 'Create Shelf'){
        if (shelfPathError !== "" || shelfPath === "" || skuKeys === null || skuKeysError !== '' || skuKeys.length === 0) {
            setShelfPathError(shelfPath === "" ? FIELD_REQUIRED : shelfPathError)
            setSkuKeysError((skuKeys === null || skuKeys.length === 0) ? FIELD_REQUIRED : skuKeysError)
            return;
        }
        setLoading(true)

        newRecord = {
            shelfPath: shelfPath,
            skuKeys: skuKeys
        }
        const response = await dispatch(createShelf(newRecord.shelfPath,newRecord.skuKeys))
        if(response.error === undefined){
            setShowAddAlert(true)
            setLoading(false)
            window.scrollTo(0, 0);
            handleCancel()
        }else{
           setLoading(false);
           handleCancel() 
        }
        
      }
     
      if(currentAction === 'Update Shelf'){

        if (shelfPathError !== "" || shelfPath === "" || skuKeys === null || skuKeysError !== '' || skuKeys.length === 0) {
            setShelfPathError(shelfPath === "" ? FIELD_REQUIRED : shelfPathError)
            setSkuKeysError((skuKeys === null||skuKeys.length === 0) ? FIELD_REQUIRED : skuKeysError)
            return;
        }
        
        newRecord = {
            _id: currentRecord._id,
            shelfPath: shelfPath,
            skuKeys: skuKeys,
        }
        setLoading(true)

        const response = await dispatch(updateShelfById(newRecord._id,newRecord.shelfPath,newRecord.skuKeys))
        if(response.error === undefined){
            setShowUpdateAlert(true)
            setLoading(false)
            window.scrollTo(0, 0);
            handleCancel()
        }else{
            setLoading(false);
            handleCancel()
        }
        
      }

    }

    const updateShelf = (record) => {
        setModalOpen(true)
        setCurrentAction('Update Shelf')
        setCurrentRecord(record)
        setShelfPath(record.shelfPath)
        setSkuKeys(record.skuKeysValue)
        setOkBtnText('Save')
    }

    const deleteShelf = (value) => {
          Modal.confirm({
            title: TextMessage.CONFIRMATION_TEXT,
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () =>deleteShelfYes(value),
            okType:'primary',
        });
    }

    const deleteShelfYes = async (value) => {
        await dispatch(deleteShelfById(value._id))
        window.scrollTo(0, 0);
        setShowDeleteAlert(true)
    }

    const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
    }

    const handleUpdateAlertClose = () => {
        setShowUpdateAlert(false)
        setCurrentAction('')
    }

    const handleAddAlertClose = () => {
        setShowAddAlert(false)
        setCurrentAction('')
    }

    const handleImportAlertClose = () => {
        setShowImportAlert(false)
        setImportAlertMessage('')
        setImportAlertType('')
    }

    const handleExportAlertClose = () => {
      setShowExportAlert(false)
      setExportAlertMessage('')
      setExportAlertType('')
  }

    const handleReload = async() => {
      setReLoadingState(true)
      const res = await dispatch(getAllShelf())
      if(res){
        setReLoadingState(false)
      }
    }

    const setColumns = () => {
        let cols = [];
    
        SHELF_LIST_COLUMNS.forEach((o) => {
            if (o.dataIndex !== "skuKeys") {
                const colsOptions = {
                    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                        return (
                            <TableSearchDropdown setSelectedKeys={setSelectedKeys}
                                                 selectedKeys={selectedKeys}
                                                 confirm={confirm}
                                                 clearFilters={clearFilters}
                                                 dataIndex={o.dataIndex} />
                        )
                    },
                    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
                    onFilter: (value, record) => {
                        const rec = record[o.dataIndex]
                                        ? record[o.dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                                        : '';
                        return rec;
                    },
                    
                }
                cols.push({ ...o, ...colsOptions });
            }
            else {
                cols.push(o);
            }
               
        });
        return cols
    }

    const handleTableHeight = () => {
      if(tableClicked === 2){
        setTableClicked(0)
        setTableSize('default')
      }
      if(tableClicked === 0){
        setTableClicked(tableClicked + 1)
        setTableSize('small')
      }
      if(tableClicked === 1){
        setTableClicked(tableClicked + 1)
        setTableSize('middle')
      }
    }
    const onSearch = async (value) => {
      const searchedShelf = shelf.filter(item => item.shelfPath.toLowerCase().trim().includes(value.toLowerCase().trim()))
      dispatch(updateShelfBySearch(searchedShelf))
    }

    const handleSearchChange = (e) => {
      setSearchedText(e.target.value)
    }

    const onInputClick = (event) => {
      event.target.value = ''
    }

    const handleExcelFile = async (e) => {
        setShowSpin(true)
        let file = e.target.files[0]
        e.preventDefault()

        const formData = new FormData()
        formData.append('file',file)

        const response = await dispatch(importShelf(formData))
        
        if(response.error === undefined){
          setShowSpin(false)
          setShowImportAlert(true)
          setImportAlertMessage(TextMessage.SHELF_FILE_TEXT)
          setImportAlertType('success')
          await dispatch(getAllShelf())
        }else{
          setShowSpin(false)
          setShowImportAlert(true)
          setImportAlertMessage(response.error.response.data.message)
          setImportAlertType('error')
        }
        
    }

    const exportShelfData = async() => {
      setButtonLoading(true)
      const res = await dispatch(getShelfExportData(searchedText));
      
      if(res.error === undefined){
        setButtonLoading(false)
        setShowExportAlert(true);
        setExportAlertMessage(TextMessage.SHELF_EXPORT_TEXT)
        setExportAlertType('success')
        await dispatch(getAllShelf())
        }else{
          setButtonLoading(true);
          setExportAlertMessage(res.error.response.data.message)
          setExportAlertType('error')
        }
    }

    const viewSingleShelf = (record) => {
      navigate(`/singleshelf/${record._id}`);
    };
   
    return(
        <CommonLayout  className="content-mr"breadCrumbText='Shelf'>
          {
            (loadingState || reloadingState) ? 
                <div className="shelfTipDiv">
                    <Spin size='middle' tip='Loading...'/>
                </div>
            : 
            <>
            <div className="shelf-card">
            <div className="shelfs-right-content">
                <Search placeholder="Search"  enterButton className='searchShelfBtn' allowClear onSearch={onSearch}
                onChange={handleSearchChange}
                />
            </div>

            <div className="shelfs-left-content">
                <a className="ant-btn ant-btn-default" type="button" href={process.env.REACT_APP_DOWNLOAD_FILE_URL} download target='_blank' title='Download' rel='noreferrer'>
                  <DownloadOutlined className='mr-5'/> Download Template
                </a>

                <label className='ant-btn ant-btn-default'>
                   <PlusCircleOutlined className='mr-5'/> Import Data
                    <input type='file' accept='.xlsx'
                      hidden
                      onChange={handleExcelFile}
                      onClick={onInputClick}
                    />
                </label>

                <Button type="primary" icon={<FileAddOutlined />} className='addShelfBtn' onClick={handleAdd}>
                    Add Shelf
                </Button>
            </div>
          </div>

          <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
            <div className="shelf-table-card">
                <div className="shelfs-table-topbar">
                    <div className="shelfRightFilterGrpWrapper">

                            <div className="shelfRightSettingGrp">
                                <ReloadOutlined onClick={handleReload} className='reloadIconStyle'/>
                                <ColumnHeightOutlined className='reloadIconStyle' onClick={handleTableHeight} />
                                <FullscreenOutlined onClick={() => {setIsEnter(true)}} className='reloadIconStyle'/>
                            </div>
                    </div>
                      <Button icon={buttonLoading ? <LoadingOutlined /> : <ExportOutlined />}  onClick={exportShelfData} disabled={buttonLoading}>Export</Button>
                </div>

                

                {/* table */}

                <div className='w-100'>
                    <CustomTable
                        columns={setColumns()}
                        rows={setupRows(searchedShelf.length>0 ? searchedShelf :shelf)}
                        isViewable={true} 
                        isEditable={true}
                        isDeleteable={true}
                        rowKey="_id"
                        onView={viewSingleShelf}
                        onEdit={updateShelf}
                        onDelete={deleteShelf}
                        total={shelf?.length}
                        size={tableSize}
                        module='shelf'
                        />
                </div>

                <Modal
                        visible={modalOpen}
                        title={currentAction}
                        onOk={handleSubmit} 
                        onCancel={handleCancel}
                        okText={okBtnText}
                        destroyOnClose
                        footer={
                            (currentAction === "Create Shelf" || currentAction === "Update Shelf") ? [
                                <Button onClick={handleCancel}
                                >Cancel</Button>,
                                <Button type='primary' 
                                onClick={handleSubmit} 
                                disabled={loading}
                                className='footerBtnNavy'>{loading ? 'Loading...' : okBtnText}</Button>
                            ] : null
                            
                        }
                    >
                        {(currentAction === "Create Shelf" || currentAction === "Update Shelf") ?
                            renderTag(currentRecord) : <></>}
                    </Modal>

                    {
                        showDeleteAlert && 
                            <Alert message={TextMessage.SHELF_DELETE_TEXT} type="error" 
                            showIcon closable onClose={handleDeleteAlertClose} icon={<DeleteOutlined/>} className='alertStyle'
                            />
                    }

                    {
                        showUpdateAlert && 
                            <Alert message={TextMessage.SHELF_UPDATE_TEXT} type={'success'}
                            showIcon closable onClose={handleUpdateAlertClose} className='alertStyle'
                            />
                    }
                    {
                        showAddAlert && 
                            <Alert message={TextMessage.SHELF_CREATE_TEXT} type={'success'}
                            showIcon closable onClose={handleAddAlertClose}
                                className='alertStyle'
                            />
                    }
                    {
                      showImportAlert && 
                            <Alert message={importAlertMessage} type={importAlertType}
                            showIcon closable onClose={handleImportAlertClose}
                                className='alertStyle'
                            />
                    }
                    {
                        showExportAlert && 
                            <Alert message={exportAlertMessage} type={exportAlertType}
                            showIcon closable onClose={handleExportAlertClose}
                                className='alertStyle'
                            />
                    }
            </div>
          </Fullscreen>

          <LoadingScreen showSpin={showSpin}/>
            </>
          }
        </CommonLayout>
    )
}

export default Shelf;