import { Button, Input, Select, Modal } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateStage, editPromo } from "actions/promotions/index";
import { QuestionStatus } from "utils/constants";

const { TextArea } = Input;

const { Option } = Select;

const RejectionModal = (props) => {
  const dispatch = useDispatch();
  const [rTeamId, setRTeamId] = useState();
  const [reason, setReason] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const handleReject = async () => {
    if (!reason) {
      setErrorMessage("Please enter rejection reason");
      return;
    }
    let teamId = rTeamId;
    if (!teamId) {
      teamId = props?.stages?.[props?.currentStage || 0]?.teamId;
    }
    const res = await dispatch(
      updateStage(props.data?._id, {
        teamId,
        reason: reason,
        status: QuestionStatus.Rejected,
      })
    );
    await dispatch(editPromo(props.data?._id, { actionTeam: teamId }));

    if (res.error === undefined) {
      props.showAlert();
      props.onClose(false);
    }
  };

  const handleCancel = () => {
    setErrorMessage("");
    props.onClose(false);
  };
  return (
    <Modal
      open={props.showModal}
      title={"Rejection Comment"}
      onCancel={handleCancel}
      destroyOnClose
      maskClosable={false}
      footer={[
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button type="primary" onClick={handleReject} className="footerBtnNavy">
          {"Add"}
        </Button>,
      ]}
    >
      <div className="input-frame">
        <div className="d-label">
          <label className="input-label">Team</label>
        </div>
        <Select
          className="w-full"
          onChange={(value) => {
            setRTeamId(value);
          }}
          placeholder="Select"
        >
          {props.stages?.map((item, index) => {
            if (index < props.myTeamIndex) {
              return <Option key={item.teamId}>{item.name}</Option>;
            }
            return <></>;
          })}
        </Select>
      </div>

      <div className="text-frame">
        <div className="text-frame-flex">
          <span className="spanReq">*</span>{" "}
          <label className="input-label">
            Please mention the reason of rejection for the team you have
            selected
          </label>
        </div>
      </div>
      <TextArea
        onChange={(e) => {
          if (e.target.value) {
            setErrorMessage("");
          }
          setReason(e.target.value);
        }}
      />
      {!!errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
    </Modal>
  );
};
export default RejectionModal;
