import { DatePicker } from "antd";
import moment from "moment";
import { DATE_FORMAT, MAP_DATE_FORMAT } from "utils/constants";

const DatePickerEle = ({question,index,questionType,questionHeader,options,designate,action,handleDatePickerChange,answer,record}) => {
    return(
    <div 
    className="datePickerDiv"
    >
      {
        action === 'create' && 
          <DatePicker
            onChange={(value) => handleDatePickerChange(value,index,record)}
            format={DATE_FORMAT}
            showToday={false}
            />
      }

      {
        action === 'view' && <DatePicker
            disabled
            value={answer ? moment(answer) : ''}
            format={MAP_DATE_FORMAT}
            showToday={false}
            className="select-date"
          />
      }

    </div>
    )
}

export default DatePickerEle;