import React from "react";
import { useState } from "react";
import { Checkbox } from "antd";

const CheckEle = (props) => {
  const [value, setValue] = useState(props.ans ? props.ans : "");  

  const handleCheckbox = (e) => {
    props.isPromoTypeQuestion ? props.handlePromoTypeQuestionChange(e, props.id,props.pqid): props.onChange(e, props.id); 
    setValue(e)   
  };

  const plainOption = props.options?.map((item) => item.optionText);

  return (
    <div className="radio-frame">
      <div className="d-label"> 
        {props.designate && props.designate === 'required' ? <span className="spanReq">*</span> : ''} <label className="input-label">{props.label}</label>
      </div>
      {props.action === "create" && (
        <>
          <Checkbox.Group options={plainOption} value={value} onChange={handleCheckbox} />
          <span className="error-label">{props.error}</span>
        </>
      )}
      {props.action === "view" && (
        <Checkbox.Group options={plainOption} value={value} disabled />
      )}
      {props.action === "edit" && (
        <Checkbox.Group
          options={plainOption}
          value={value}
          onChange={handleCheckbox}
        />
      )}
    </div>
  );
};

export default CheckEle;
