import "../formBuilder/FormBuilder.css";
import { Button, Checkbox, Input, Select } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const Option = Select;

const MultiSelectQuestionView = ({
  question,
  handleCancelEditView,
  handleDeleteOptions,
  handleOptionTextChange,
  handleAddOptions,
  index,
  handleSaveQuestion,
  loading,
  handleFormBuilderSelectMasterOption,
  handleQuestionDesignate,
}) => {
  return (
    <>
      {question.questionType === "multiSelect" && (
        <div className="formBuilderFrameTwoDynamic" key={index}>
          <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
            {question.questionType === "multiSelect" &&
              question.optionValue?.map((op, j) => (
                <>
                  <div
                    className="formBuilderFrameTwoDynamicOptionFrame"
                    key={j}
                  >
                    <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                      <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                        <Input
                          addonBefore={j + 1}
                          placeholder={`Option ${j + 1}`}
                          suffix={
                            <DeleteOutlined
                              className="cp"
                              onClick={() => handleDeleteOptions(index, j)}
                            />
                          }
                          value={op.optionText}
                          onChange={(e) =>
                            handleOptionTextChange(e.target.value, index, j)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>

          {question.questionType === "multiSelect" && (
            <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
              {!question.isMaster && (
                <Button
                  icon={
                    <PlusOutlined
                      style={{ marginRight: "-10px", color: "#0F2A6F" }}
                    />
                  }
                  className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                  onClick={() => handleAddOptions(index)}
                >
                  Add Option
                </Button>
              )}
              <div className="fomBuilderSelectStyle">
                <Select
                  placeholder="Select Master"
                  value={question.masterDataType}
                  onChange={(value) =>
                    handleFormBuilderSelectMasterOption(index, value)
                  }
                >
                  <Option value={"shelf"}>Shelf</Option>
                  {/* <Option value={"sku"}>SKU</Option> */}
                  <Option value={"promo"}>Promo</Option>
                  <Option value={"promoType"}>Promo Type</Option>
                  <Option value={"user"}>Users</Option>
                  {/* <Option value={"brand"}>Brands</Option>
                  <Option value={"department"}>Departments</Option>
                  <Option value={"class"}>Class</Option>
                  <Option value={"subclass"}>Subclass</Option> */}
                </Select>
              </div>
            </div>
          )}
        </div>
      )}
      {question.questionType === "multiSelect" && (
        <div className="formBuilderFrameTwoDynamic">
          <div className="requiredCheckboxDiv">
            <Checkbox
              onChange={(e) => handleQuestionDesignate(e, index)}
              checked={
                question.designate && question.designate === "optional"
                  ? false
                  : true
              }
            >
              Required
            </Checkbox>
            <span className="requiredSpanCheck">
              (check if you want question to be required)
            </span>
          </div>
        </div>
      )}
      {question.questionType === "multiSelect" && (
        <div className="formBuilderActionsDiv">
          <Button
            icon={<SaveOutlined />}
            className="saveBtn"
            onClick={() => handleSaveQuestion(question, index)}
            disabled={loading}
          >
            Save
          </Button>
          <span className="errorLabel">
            {question?.errors?.optionTextError !== null && "Option Required"}
          </span>
          <div className="formBuilderActionsWrapper">
            <Button
              icon={<CloseOutlined />}
              className="saveBtn"
              disabled={loading}
              onClick={handleCancelEditView}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default MultiSelectQuestionView;
