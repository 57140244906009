/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Checkbox, Input, Radio, Spin } from "antd";
import styles from "./view-report.module.scss";
import { useEffect, useMemo, useState } from "react";
import { ReportVisibility } from "common/enum";
import useSWR from "swr";
import ReportService from "services/report-service/report-service";
import { useFormik } from "formik";
import session from "utils/session";
import { ReportEditSchema } from "common/schemas.yup";

interface IViewReport {
  reportId?: string;
  isEditMode?: boolean;
  handleUpdateComplete: () => void;
}

const ViewReport: React.FC<IViewReport> = (props) => {
  const {
    reportId,
    isEditMode: isEditModeDefault,
    handleUpdateComplete,
  } = props;
  const { isAdmin, userId } = session;
  const [isEditMode, setIsEditMode] = useState<boolean>(!!isEditModeDefault);
  const reportService = useMemo(() => new ReportService(), []);

  const { data } = useSWR({ key: "report-details", id: reportId }, (payload) =>
    payload.id ? reportService.getReportById(payload.id) : undefined
  );

  const hasEditAccess =
    isAdmin || data?.data?.report?.createdBy?._id === userId;

  useEffect(() => {
    if (isEditModeDefault) {
      setIsEditMode(isEditModeDefault);
    }
  }, [isEditModeDefault]);

  const {
    touched,
    errors,
    values,
    setValues,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      title: "",
      projections: [],
      status: ReportVisibility.PRIVATE,
    },
    validationSchema: ReportEditSchema,
    onSubmit: async (formValues) => {
      setIsEditMode(false);
      await reportService.updateReportById(reportId as string, formValues);
      handleUpdateComplete();
    },
  });

  useEffect(() => {
    if (data?.data?.report) {
      const report = data?.data?.report;
      setValues((prev) => ({
        ...prev,
        title: report?.title,
        projections: report?.projections ?? [],
        status: report?.status,
      }));
    }
  }, [data, setValues]);

  const handleDownloadReportClick = () => {
    reportService.downloadReport(reportId as string, data?.data?.report?.title);
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.body_container}>
        <div>
          <p className={styles.field_label}>Report Name</p>
          <Input
            id="title"
            placeholder="Report Name"
            value={values.title}
            disabled={!isEditMode}
            onChange={handleChange}
            status={touched.title && errors.title ? "error" : ""}
          />
          <span className={styles.error_message}>
            {touched.title && errors.title}
          </span>
        </div>
        <div className={styles.fields_container}>
          {values?.projections?.map((item: any) => (
            <Checkbox key={item.label} disabled checked>
              <div>{item?.label}</div>
            </Checkbox>
          ))}
        </div>
        <div className={styles.report_visibility_container}>
          <p>
            {isEditMode
              ? "Would you prefer to make the report private or public?"
              : "Report Visibility"}
          </p>
          <Radio.Group
            value={values.status}
            disabled={!isEditMode}
            onChange={({ target }) => setFieldValue("status", target.value)}
          >
            {Object.values(ReportVisibility)?.map((item) => (
              <Radio value={item} key={item}>
                {item}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div className={styles.footer_container}>
        {isEditMode || !hasEditAccess ? (
          <span />
        ) : (
          <button
            key="Edit button"
            className={styles.download_csv}
            onClick={() => setIsEditMode(true)}
          >
            Edit
          </button>
        )}

        <div className={styles.action_container}>
          {isEditMode && <button type="submit" key="Save button" className={styles.save}>
            Save
          </button>}
          <button
            className={styles.download_csv}
            onClick={handleDownloadReportClick}
            type="button"
          >
            Download CSV File
          </button>
        </div>
      </div>
    </form>
  );
};

export default ViewReport;
