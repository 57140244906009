import { S, } from 'utils/prefix';
import * as ActionTypes from 'actions/reports/action-types'
import ReportsStateManager from './state-manager';

const INITIAL_STATE = {
    reports: [],
    pagination: undefined,
    total: 0,
    showUnassignButton: false,
    newSelectedRowKeys: []
};

export default function ReportStates(state = INITIAL_STATE, action) {
    switch (action.type) {
        case S(ActionTypes.GET_ALL_REPORT):
            return ReportsStateManager.getAllReports(state, action);
        default:
            return state;
    }
}