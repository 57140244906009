import BottomSection from "./BottomSection";

const ShortAnswerQuestionView = ({
  question,
  handleCancelEditView,
  loading,
  handleQuestionDesignate,
  index,
  handleSaveQuestion,
  handleStandardTeamChange,
  handleEmergencyTeamChange,
  onRegexCheckboxChange,
  handleRegexChange,
}) => {
  return (
    <>
      <BottomSection
        isSameType={question.questionType === "input"}
        onCheckboxChange={(e) => handleQuestionDesignate(e, index)}
        onSaveClick={() => handleSaveQuestion(question, index)}
        loading={loading}
        handleCancelEditView={handleCancelEditView}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
        showRegex={true}
        onRegexCheckboxChange={(e) => onRegexCheckboxChange(e, index)}
        handleRegexChange={(e) => handleRegexChange(e, index)}
      />
    </>
  );
};

export default ShortAnswerQuestionView;
