import { S,  }                  from 'utils/prefix';
import * as ActionTypes         from 'actions/formBuilder/action-types'
import FormBuilderStateManager        from './state-manager';

const INITIAL_STATE = {
    formBuilder: [],
    questions:[],
    questionsLocal:[],
};

export default function FormBuilderStates(state=INITIAL_STATE, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_LOCAL_FORM_BUILDER_JSON:
                return { ...state, formBuilder: [ ...action.formBuilder ] };
        case ActionTypes.UPDATE_LOCAL_QUESTION_SAVE_JSON:
                return { ...state, questionsLocal: [ ...action.questions ] };

        case S(ActionTypes.GET_ALL_QUESTIONS):
                return FormBuilderStateManager.getAllQuestions(state,action);
        case S(ActionTypes.CREATE_QUESTION):
                return FormBuilderStateManager.createQuestion(state,action);
        case S(ActionTypes.UPDATE_QUESTION):
                return FormBuilderStateManager.updateQuestion(state,action);
        case S(ActionTypes.DELETE_QUESTION):
                return FormBuilderStateManager.deleteQuestion(state,action);
        case S(ActionTypes.UPDATE_QUESTION_STATUS):
                return FormBuilderStateManager.updateQuestionStatus(state,action);
        
        case S(ActionTypes.GET_FORMBUILDER):
                return FormBuilderStateManager.getFormBuilder(state,action);
        case S(ActionTypes.CREATE_FORMBUILDER):
                return FormBuilderStateManager.createFormBuilder(state,action);
        case S(ActionTypes.UPDATE_FORMBUILDER):
                return FormBuilderStateManager.updateFormBuilder(state,action);     
        default:
            return state;
    }
}