import { Button, Input, Select } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import BottomSection from "./BottomSection";
const Option = Select;

const MultiSelectQuestion = ({
  question,
  handleDeleteOptions,
  handleOptionTextChange,
  handleAddOptions,
  isMultiSelect,
  index,
  deleteQuestion,
  copyQuestion,
  handleSaveQuestion,
  loading,
  handleQuestionDesignate,
  handleFormBuilderSelectMasterOption,
  handleStandardTeamChange,
  handleEmergencyTeamChange
}) => {
  return (
    <>
      {isMultiSelect && question.questionType === "multiSelect" && (
        <div className="formBuilderFrameTwoDynamic" key={index}>
          <div className="formBuilderFrameTwoDynamicFormFrameWrapper">
            {question.questionType === "multiSelect" &&
              question.optionValue?.map((op, j) => (
                <>
                  <div
                    className="formBuilderFrameTwoDynamicOptionFrame"
                    key={j}
                  >
                    <div className="formBuilderFrameTwoDynamicOptionValueFrame">
                      <div className="formBuilderFrameTwoDynamicOptionValueInputWrapper">
                        <Input
                          addonBefore={j + 1}
                          placeholder={`Option ${j + 1}`}
                          // addonAfter={<SisternodeOutlined onClick={() => handleAddSubOptions(index,j)}/>}
                          suffix={
                            <DeleteOutlined
                              className="cp"
                              onClick={() => handleDeleteOptions(index, j)}
                            />
                          }
                          value={op.optionText}
                          onChange={(e) =>
                            handleOptionTextChange(e.target.value, index, j)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>

          {question.questionType === "multiSelect" && (
            <div className="formBuilderFrameTwoDynamicAddOptionsFrame">
              {!question.isMaster && (
                <Button
                  icon={
                    <PlusOutlined
                      style={{ marginRight: "-10px", color: "#0F2A6F" }}
                    />
                  }
                  className="formBuilderFrameTwoDynamicSubOptionValueAddFrameAddOptionBtn"
                  onClick={() => handleAddOptions(index)}
                >
                  Add Option
                </Button>
              )}
              <div className="fomBuilderSelectStyle">
                <Select
                  placeholder="Select Master"
                  onChange={(value) =>
                    handleFormBuilderSelectMasterOption(index, value)
                  }
                >
                  <Option value={"shelf"}>Shelf</Option>
                  {/* <Option value={"sku"}>SKU</Option> */}
                  <Option value={"promo"}>Promo</Option>
                  <Option value={"user"}>Users</Option>
                  {/* <Option value={"brand"}>Brands</Option>
                  <Option value={"department"}>Departments</Option>
                  <Option value={"class"}>Class</Option>
                  <Option value={"subclass"}>Subclass</Option> */}
                </Select>
              </div>
            </div>
          )}
        </div>
      )}
      <BottomSection
        isSameType={isMultiSelect && question.questionType === "multiSelect"}
        onCheckBoxChange={(e) => handleQuestionDesignate(e, index)}
        loading={loading}
        handleSaveQuestion={() => handleSaveQuestion(question, index)}
        copyQuestion={() => copyQuestion(index)}
        deleteQuestion={() => deleteQuestion(index)}
        question={question}
        handleStandardTeamChange={(e) => handleStandardTeamChange(e, index)}
        handleEmergencyTeamChange={(e) => handleEmergencyTeamChange(e, index)}
        showError={() => (
          <span className="errorLabel">
            {question?.errors?.optionTextError !== null && "Option Required"}
          </span>
        )}
      />
    </>
  );
};

export default MultiSelectQuestion;
