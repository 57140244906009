import logger from "common/logger";
import { HttpService } from "services/network/http.service";

export default class PromoTypeService extends HttpService {
  async reOrderPromoQuestions(promoTypeId: string, questions: any) {
    try {
      const response = await this.patch(
        `/api/promoType/${promoTypeId}/question/sequence`,
        questions
      );
      return response;
    } catch (error) {
      logger.error(error);
    }
  }
}
