import { S, F } from "utils/prefix";
import * as ActionTypes from "actions/promotions/action-types";
import PromoStateManager from "./state-manager";

const INITIAL_STATE = {
  promoList: null,
  promoListNames: null,
  promoById: null,
  promoByStatus: null,
  statusValue: null,
  subOption1: [],
  subOptionInput: [],
  subOption2: null,
  subOptionsLast: [],
  promoAssociatedBrand: [],
  subOptionInputThree: [],
  requiredQuestionPromoType: [],
  promoByStatusTotal: null,
};

export default function loginStates(state = INITIAL_STATE, action) {
  switch (action.type) {
    case S(ActionTypes.GET_PROMO_LIST):
      return {
        ...state,
        promoList: action.payload?.data?.data?.promoList,
        promoListTotal: action.payload?.data?.data?.pagination?.total,
      };
    case S(ActionTypes.GET_TIMELINE):
      return {
        ...state,
        timeline: action.payload?.data?.data,
      };
    case S(ActionTypes.GET_PROMO_STATS):
      return {
        ...state,
        statusValue: action.payload?.data?.data,
      };
    case S(ActionTypes.GET_PROMOTYPE_FOR_PROMO):
      return {
        ...state,
        promoTypeForPromo:
          action.payload?.data?.data || [],
      };

    case S(ActionTypes.GET_PROMO_LIST_NAMES):
      return {
        ...state,
        promoListNames: action.payload?.data?.data,
      };

    case S(ActionTypes.GET_PROMO_ASSOCIATED_BRAND):
      return PromoStateManager.getPromoAssociatedBrand(state, action);

    case S(ActionTypes.GET_PROMO_ASSOCIATED_SKU):
      return PromoStateManager.getPromoAssociatedSku(state, action);

    case S(ActionTypes.GET_PROMO_ASSOCIATED_SHELF):
      return PromoStateManager.getPromoAssociatedShelf(state, action);

    case S(ActionTypes.VIEW_PROMO):
      return {
        ...state,
        promoById: action.payload?.data?.data.promo,
      };

    case S(ActionTypes.PROMOBY_STATUS):
      return {
        ...state,
        promoByStatus: action.payload?.data?.data?.promoList,
        promoByStatusTotal: action.payload?.data?.data?.pagination?.total,
      };

    case ActionTypes.SEND_SUB1:
      return PromoStateManager.subOptionSuccess(state, action);

    case ActionTypes.SEND_SUB_INPUT:
      return PromoStateManager.subOptionInputSuccess(state, action);

    case ActionTypes.SEND_SUB_INPUT_THREE:
      return PromoStateManager.subOptionInputThreeSuccess(state, action);

    case ActionTypes.REQUIRED_QUES_PROMO_TYPE:
      return PromoStateManager.getRequiredQuestionPromoType(state, action);

    case ActionTypes.SEND_SUB2:
      return PromoStateManager.resetData(state, action);

    case S(ActionTypes.CHANGE_STATUS_PROMO):
      return state;

    case F(ActionTypes.GET_PROMO_LIST):
    case F(ActionTypes.PROMOBY_STATUS):
    default:
      return state;
  }
}
