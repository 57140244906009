import { getRequiredQuestionPromoType } from "actions/promotions";
import { getAllPromoTypes, getQuestionUnPublished } from "actions/promoTypes"
import { Select, Spin } from "antd";
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import Element from "../Element";

const { Option } = Select;

const PromoTypeElement = (props) => {
  const dispatch = useDispatch();
  const [promoTypeOptionData, setPromoTypeOptionData] = useState([])
  const [promoType, setPromoType] = useState(props.ans ? props.ans : null)
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [notExist, setNotExist] = useState(false)

  useEffect(() => {
    if (props.item?.masterDataType === 'promoType') {
      const getPromoTypeCall = async () => {
        const res = await dispatch(getAllPromoTypes())

        if (res.error === undefined) {
          setPromoTypeOptionData(res.payload.data.data.payload.promoType || [])
        }
      }
      getPromoTypeCall()
    }
  }, [dispatch, props.item])

  useEffect(() => {
    if (props.action === 'edit' && props.promoTypeId) {
      const callApi = async () => {
        const res = await dispatch(getQuestionUnPublished(props.promoTypeId))
        if (res.error === undefined) {
          const getReqQuest = res.payload.data.data.questionIds.filter(item => item.isPublished === true && item.designate === 'required')
          dispatch(getRequiredQuestionPromoType(getReqQuest))
        }
      }
      callApi()
    }
  }, [props.action, props.promoTypeId, dispatch])

  const promoTypeChildren = [];
  if (promoTypeOptionData.length > 0) {
    const filteredPromoType = promoTypeOptionData.filter(item => item.status === true)
    for (let i = 0; i < filteredPromoType.length; i++) {
      promoTypeChildren.push(<Option key={filteredPromoType[i].promoType}>{filteredPromoType[i].promoType}</Option>);
    }
  }

  const handlePromoType = async (value) => {
    setLoading(true)
    setQuestions([])
    setPromoType(value)

    const filteredPromoType = promoTypeOptionData.filter(item => item.promoType === value)
    props.onChange(value, props.id, filteredPromoType[0]._id)
    const res = await dispatch(getQuestionUnPublished(filteredPromoType[0]._id))
    if (res.error === undefined) {
      setLoading(false)
      const filtered = res.payload.data.data.questionIds.filter(item => item.isPublished === true)
      const getReqQuest = res.payload.data.data.questionIds.filter(item => item.isPublished === true && item.designate === 'required')
      dispatch(getRequiredQuestionPromoType(getReqQuest))
      setQuestions(filtered)
    } else {
      setLoading(false)
    }
  }

  const handlePromoTypeEdit = async (value) => {
    setLoading(true)
    setQuestions([])
    setPromoType(value)


    const filteredPromoType = promoTypeOptionData.filter(item => item.promoType === value)
    if (filteredPromoType[0]._id === props.promoTypeId && props.fieldData && props.fieldData.length > 0) {
      setNotExist(false)
      setLoading(false)
      props.handleChangeExits()
      return;
    }
    if (filteredPromoType[0]._id === props.promoTypeId && !props.fieldData) {
      props.onChange(props.ans, props.id, props.promoTypeId)
      const res = await dispatch(getQuestionUnPublished(filteredPromoType[0]._id))
      if (res.error === undefined) {
        setLoading(false)
        setNotExist(true)
        const filtered = res.payload.data.data.questionIds.filter(item => item.isPublished === true)
        const getReqQuest = res.payload.data.data.questionIds.filter(item => item.isPublished === true && item.designate === 'required')
        dispatch(getRequiredQuestionPromoType(getReqQuest))
        setQuestions(filtered)
      } else {
        setLoading(false)
        setNotExist(false)
      }
      return;
    }

    props.onChange(value, props.id, filteredPromoType[0]._id)
    const res = await dispatch(getQuestionUnPublished(filteredPromoType[0]._id))
    if (res.error === undefined) {
      setLoading(false)
      setNotExist(true)
      const filtered = res.payload.data.data.questionIds.filter(item => item.isPublished === true)
      const getReqQuest = res.payload.data.data.questionIds.filter(item => item.isPublished === true && item.designate === 'required')
      dispatch(getRequiredQuestionPromoType(getReqQuest))
      setQuestions(filtered)
    } else {
      setLoading(false)
      setNotExist(false)
    }
  }

  return (
    <div className="promoTypeElement-container">
      <label className="input-label">
        {props.designate && props.designate === 'required' ? <span className="spanReq">*</span> : ''}  {props?.item?.subOptionText || props?.item?.questionHeader}
      </label>

      {props.action === "create" && (<>
        <div className="select-dropdown">
          <Select
            className="select-dropdown"
            onChange={handlePromoType}
            value={promoType}
            placeholder="Select"
          >
            {
              props.item.masterDataType && props.item.masterDataType === 'promoType' && promoTypeChildren && promoTypeChildren.length > 0 && promoTypeChildren
            }

          </Select>
          <span className="error-label">{props.error}</span>
        </div>
        {
          props.action === 'create' && loading && <div className="promoLoading" style={{ margin: '10px 0px' }}>
            <Spin size='middle' tip='Loading...' />
          </div>
        }
        <div className='promoTypeElement-question-container'>
          {
            props.action === 'create' && !loading && questions && questions.length > 0 && questions.map((item, i) => (
              <Element
                id={item._id}
                field={item?.questionType}
                label={item?.questionHeader}
                options={item?.optionValue}
                action={"create"}
                error={""}
                item={item}
                designate={item.designate || ''}
                handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
                isPromoTypeQuestion={true}
                pqid={props.id}
                isRegexRequired={item?.isRegexRequired}
                regex={item?.regex}
              />
            ))
          }
        </div>
      </>)}

      {
        props.action === 'view' && (
          <>
            <div className="select-dropdown">
              <Select
                className="select-dropdown"
                value={promoType}
                placeholder="Select"
                disabled
              >
                {
                  props.item.masterDataType && props.item.masterDataType === 'promoType' && promoTypeChildren && promoTypeChildren.length > 0 && promoTypeChildren
                }

              </Select>
              <span className="error-label">{props.error}</span>
            </div>

            <div className='promoTypeElement-question-container'>
              {
                props.action === 'view' && props.fieldData && props.fieldData.length > 0 && props.fieldData.map((item, i) => (
                  <Element
                    id={item?.quesObj?._id}
                    ans={item?.value}
                    field={item?.quesObj?.questionType}
                    label={item?.quesObj?.questionHeader}
                    subOption1={item?.subOption1 ? item?.subOption1 : ""}
                    options={item?.quesObj?.optionValue}
                    subOptions={item?.subOptions ? item?.subOptions : ""}
                    SubOptionInput={item?.SubOptionInput || item?.subOptionInput || ""}
                    SubOptionInputThree={item.subOptionInputThree || ""}
                    action={"view"}
                    item={item.quesObj}
                    designate={item.quesObj.designate || ''}
                  />
                ))
              }
            </div>
          </>
        )
      }

      {/* edit */}
      {
        props.action === 'edit' && (
          <>
            <div className="select-dropdown" >
              <Select
                className="select-dropdown"
                value={promoType}
                placeholder="Select"
                onChange={handlePromoTypeEdit}
              >
                {
                  props.item.masterDataType && props.item.masterDataType === 'promoType' && promoTypeChildren && promoTypeChildren.length > 0 && promoTypeChildren
                }

              </Select>
              <span className="error-label">{props.error}</span>
            </div>

            <div className='promoTypeElement-question-container'>
              {
                props.action === 'edit' && !loading && !notExist && props.fieldData && props.fieldData.length > 0 && props.fieldData.map((item, i) => (
                  <Element
                    id={item?.quesObj?._id}
                    ans={item?.value}
                    field={item?.quesObj?.questionType}
                    label={item?.quesObj?.questionHeader}
                    subOption1={item?.subOption1 ? item?.subOption1 : ""}
                    options={item?.quesObj?.optionValue}
                    subOptions={item?.subOptions ? item?.subOptions : ""}
                    SubOptionInput={item?.SubOptionInput || item?.subOptionInput || ""}
                    SubOptionInputThree={item.subOptionInputThree || ""}
                    action={"edit"}
                    item={item.quesObj}
                    designate={item.quesObj.designate || ''}
                    handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
                    isPromoTypeQuestion={true}
                    pqid={props.id}
                    isRegexRequired={item?.isRegexRequired}
                    regex={item?.regex}
                  />
                ))
              }
            </div>

            <div className='promoTypeElement-question-container'>
              {
                props.action === 'edit' && !loading && notExist && questions && questions.length > 0 && questions.map((item, i) => (
                  <Element
                    id={item._id}
                    field={item?.questionType}
                    label={item?.questionHeader}
                    options={item?.optionValue}
                    action={"create"}
                    error={""}
                    item={item}
                    designate={item.designate || ''}
                    handlePromoTypeQuestionChange={props.handlePromoTypeQuestionChange}
                    isPromoTypeQuestion={true}
                    pqid={props.id}
                    isRegexRequired={item?.isRegexRequired}
                    regex={item?.regex}
                  />
                ))
              }
            </div>

            {
              props.action === 'edit' && loading && <div className="promoLoading" style={{ margin: '10px 0px' }}>
                <Spin size='middle' tip='Loading...' />
              </div>
            }
          </>
        )
      }
    </div>
  )
}

export default PromoTypeElement