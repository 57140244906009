import "./statusDrawer.css";
import React from "react";
import { Button, Drawer } from "antd";
import { CircleTickIcon } from "utils/constants";

const StatusDrawer = (props) => {
  return (
    <>
      <Drawer
        className="userErrorBtnClose"
        width={640}
        visible={props.visible}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <div className="status-body">
          <div className="status-check">
            <div className="status-result">
              <div>
                <CircleTickIcon className="check-circle" />
              </div>
              <div className="status-description">
                <span className="description-title">{props.title}</span>
                {props.subtitle && (
                <div>
                  <span className="description-subtitle">{props.subtitle}</span>
                </div>
              )}
              </div>
             
              <div className="status-button">
                <Button onClick={props.onClose}>Close</Button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default StatusDrawer;
