import { QueryBuilderAntD } from "@react-querybuilder/antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.css";

const Querybuilder = forwardRef(({ fields, initialquery }, ref) => {
  const [query, setQuery] = useState(initialquery || undefined);
  const [errorMessage, setErrorMessage] = useState("");
  useImperativeHandle(ref, () => ({
    isValid() {
      const hasAtLeastOneRule = query?.rules?.some(
        (item) => item.value || ["notNull", "null"].includes(item.operator)
      );
      if (!hasAtLeastOneRule) {
        setErrorMessage("Please add at least one rule");
      } else {
        setErrorMessage("");
      }
      return hasAtLeastOneRule;
    },
    getQuery() {
      return query;
    },
  }));
  return (
    <div className="report-query-builder">
      <QueryBuilderAntD>
        <QueryBuilder fields={fields} query={query} onQueryChange={setQuery} />
      </QueryBuilderAntD>
      <span className="error-label">{errorMessage}</span>
    </div>
  );
});
export default Querybuilder;
