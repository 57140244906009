import ReactFlow, { Controls } from "reactflow";
import { Modal } from "antd";
import { useEffect, useState, useMemo } from "react";
import { Handle, Position } from "reactflow";

import React from "react";
import "./workflow.css";
const Start = ({
  data,
  isConnectable,
  selected,
  onClick,
  onConnect,
  onRemove,
  id,
  addClick,
  ...props
}) => {
  return (
    <div className="start-node-wrapper">
      <div className="start-node">
        <div className="start-node-label">{data.label}</div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id="a"
        isConnectable={isConnectable}
        style={{ top: 30 }}
      />
    </div>
  );
};

const Stage = ({
  data,
  isConnectable,
  selected,
  onClick,
  onConnect,
  onRemove,
  id,
  addClick,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
  ...props
}) => {
  return (
    <div className="stage-node-wrapper">
      <Handle
        type="target"
        position={targetPosition}
        style={{ background: "#555" }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      />
      <div className="stage-node">
        <div className="stage-node-label" title={data.label}>
          {data.label}
        </div>
      </div>
      <Handle
        type="source"
        position={sourcePosition}
        id="a"
        style={{ background: "#555" }}
        isConnectable={isConnectable}
      />
    </div>
  );
};

const Workflow = ({ data, onCancel }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const nodeType = useMemo(() => {
    return {
      start: (props) => <Start {...props} />,
      stage: (props) => <Stage {...props} />,
    };
  }, []);
  useEffect(() => {
    const getData = async () => {
      const stages = data.stages || [];
      const nodes = [
        {
          id: "1",
          type: "stage",
          data: { label: "Start" },
          position: { x: 0, y: 0 },
          className: "start-node",
        },
        {
          id: "2",
          type: "stage",
          data: { label: "Create Promo" },
          position: { x: 120, y: 80 },
          sourcePosition: Position.Top,
          targetPosition: Position.Left,
          className: "promo-node",
        },
        {
          id: "3",
          data: { label: "In progress" },
          position: { x: 250, y: 80 },
          style: {
            backgroundColor: "#FAFAFA",
            border: "1px solid #D9D9D9",
            height: stages.length * 100,
            zIndex: -1,
            width: "105px",
          },
          type: "group",
          className: "promo-node",
        },
      ];
      let lastApprove = -1;
      const stageNodes = stages.map((i, index) => {
        lastApprove = i.status === "Approve" ? index : lastApprove;
        return {
          id: `stage${i.stage}`,
          type: "stage",
          data: { label: i.name },
          position: { x: 20, y: index * 80 + 40 },
          parentNode: "3",
          className:
            lastApprove === index - 1
              ? "promo-node next-highlight"
              : "promo-node",
          sourcePosition:
            index === stages.length - 1 ? Position.Right : Position.Bottom,
        };
      });
      const lastNodePosition = stageNodes[stageNodes.length - 1].position;
      const endNodes = [
        {
          id: "approve",
          data: { label: "Approve" },
          position: {
            x: lastNodePosition.x + 200 + 250,
            y: lastNodePosition.y + 80,
          },
          type: "stage",
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          className: "promo-node",
        },
        {
          id: "live",
          data: { label: "Live" },
          position: {
            x: lastNodePosition.x + 400 + 250,
            y: lastNodePosition.y + 80,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: "stage",
          className: "promo-node",
        },
        {
          id: "expired",
          data: { label: "Expired" },
          position: {
            x: lastNodePosition.x + 600 + 250,
            y: lastNodePosition.y + 80,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: "stage",
          className: "promo-node",
        },
        {
          id: "end",
          data: { label: "End" },
          position: {
            x: lastNodePosition.x + 800 + 250,
            y: lastNodePosition.y + 80 - 19,
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          type: "stage",
          className: "start-node",
        },
      ];
      nodes.push(...stageNodes, ...endNodes);

      const edges = [
        { id: "edge_0", source: "1", target: "2", type: "step" },
        { id: "approve_live", source: "approve", target: "live", type: "step" },
        { id: "live_expired", source: "live", target: "expired", type: "step" },
        { id: "expired_end", source: "expired", target: "end", type: "step" },
      ];
      // if (lastApprove + 1 > 0) {
      //   edges.push({
      //     id: "reject",
      //     source: stageNodes[lastApprove]?.id,
      //     target: stageNodes[lastApprove - 1]?.id,
      //     type: "step",
      //   });
      // }
      const stageEdges = stages.map((i, index) => {
        const src = index === 0 ? "2" : "stage" + (index - 1);
        const tar = index === stages.length - 1 ? "approve" : "stage" + index;
        return {
          id: "stage_edge_" + index,
          source: src,
          target: tar,
          type: "step",
        };
      });
      edges.push(...stageEdges);
      setNodes(nodes);
      setEdges(edges);
    };
    getData();
  }, [data.stages]);

  return (
    <Modal
      visible={data}
      title={"Workflow Overview"}
      // onOk={handleSubmit}
      onCancel={() => onCancel()}
      // okText={okBtnText}
      destroyOnClose
      footer={null}
    >
      {data && (
        <div style={{ height: "calc(60vh)" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            // viewport={viewData.jsonView.viewport}
            nodeTypes={nodeType}
            fitView={true}
            defaultViewport={{ zoom: 1 }}
          >
            <Controls />
          </ReactFlow>
        </div>
      )}
    </Modal>
  );
};
export default Workflow;
