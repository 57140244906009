import { Checkbox } from "antd";

const CheckEle = ({question,index,questionType,questionHeader,options,designate,action,handleCheckboxChange,answer,record}) => {
    const plainOption = options?.map((item) => item.optionText);
    return(
    <div 
    // className="input-frame"
    >
      {
        action === 'create' && <>
          <Checkbox.Group options={plainOption} 
          onChange={(e) => handleCheckboxChange(e,index,record)} 
          />
        </>
      }

      {
        action === 'view' && <>
          <Checkbox.Group options={plainOption} 
          disabled
          value={answer || ''}
          />
        </>
      }

    </div>
    )
}

export default CheckEle;