import * as ActionTypes             from './action-types';

export const getAllSnapshots = (fieldName='',fieldValue='',page=0) => ({
    type: ActionTypes.GET_ALL_SNAPSHOT_LOGS,
    payload: {
        request: {
            url: `${process.env.REACT_APP_API_URL}/api/snapshot?perPage=10&fieldName=${fieldName}&fieldValue=${fieldValue}&page=${page}`,
            method: "get",
        }
    }
});
export const moduleChange = (module) => ({
    type: ActionTypes.MODULE_CHANGE,
    module
});