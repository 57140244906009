import logger from "common/logger";
import { HttpService } from "services/network/http.service";
export default class TicketReportService extends HttpService {

    async getPromoDetails(promoId: string) {
        try {
            const response = await this.get(`/api/promo/v2/${promoId}`);
            return response;
        } catch (error) {
            logger.error(error);
        }
    }
}