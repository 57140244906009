import './ErrorReportDetailPage.css'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Modal, Spin, Tag, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import CommonLayout from "components/layout/Layout";
import session from 'utils/session';
import { TextMessage, SECONDS } from 'utils/constants';
import { useParams } from "react-router-dom";
import { getSingleErrorTicket, updateTicketStatus, createCommentAdmin, getCommentAdmin } from 'actions/errorTicket';
import { setPage } from "actions/app";



const ErrorReportDetailPage = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const singleErrorTicket = useSelector((state) => state.errorTickets.singleErrorTicket)
    const [loadingState, setLoadingState] = useState(false)

    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [ticketMessage, setTicketMessage] = useState('')
    const [msg, setMsg] = useState('')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const commentData = useSelector((state) =>
        state.errorTickets.getCommentAdmin
    );

    useEffect(() => {
        dispatch(setPage('Error Report'))
    }, [dispatch])

    useEffect(() => {
        if (showDeleteAlert) {
            setTimeout(() => {
                setShowDeleteAlert(false)
            }, SECONDS);
        }
    }, [showDeleteAlert])

    useEffect(() => {
        const getCall = async () => {
            setLoadingState(true)
            const res = await dispatch(getSingleErrorTicket(id))
            await dispatch(getCommentAdmin(id))
            if (res) {
                setLoadingState(false)
            }
        }
        getCall()
    }, [dispatch, id])

    const handleTicketStatus = (val) => {
        Modal.confirm({
            title: 'Are you sure you want to Resolve this Error Ticket?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleTicketStatusYes(val),
            okType: 'primary',
        });
    }

    const handleTicketStatusYes = async (val) => {
        const response = await dispatch(updateTicketStatus(id, "Resolved"))
        if (response.error === undefined) {
            dispatch(getSingleErrorTicket(id))
            window.scrollTo(0, 0);
            setShowDeleteAlert(true)
            setTicketMessage(TextMessage.ERROR_TICKET_RESOLVE_TEXT)
        } else {
            dispatch(getSingleErrorTicket(id))
            window.scrollTo(0, 0);
            setShowDeleteAlert(true)
            setTicketMessage(TextMessage.ERROR_TEXT)
        }

    }

    const handleDeleteAlertClose = () => {
        setShowDeleteAlert(false)
        setTicketMessage('')
    }

    const sendMsg = async (val) => {
        if (msg !== "") {
            setError(false);
            setLoading(true);
            await dispatch(createCommentAdmin(id, msg));
            await dispatch(getCommentAdmin(id))
            setMsg('');
            setLoading(false);

        } else {
            setError(true);
        }


    }

    const handleReOpenTicket = (val) => {
        Modal.confirm({
            title: 'Are you sure you want to Re-Open this Error Ticket?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => handleReOpenTicketYes(val),
            okType: 'primary',
        });
    }

    const handleReOpenTicketYes = async (val) => {
        const response = await dispatch(updateTicketStatus(id, "Open"))
        if (response.error === undefined) {
            dispatch(getSingleErrorTicket(id))
            window.scrollTo(0, 0);
            setShowDeleteAlert(true)
            setTicketMessage(TextMessage.ERROR_TICKET_REOPEN_TEXT)
        } else {
            dispatch(getSingleErrorTicket(id))
            window.scrollTo(0, 0);
            setShowDeleteAlert(true)
            setTicketMessage(TextMessage.ERROR_TEXT)
        }
    }

    return (
        <CommonLayout className="content-mr" breadCrumbText='Error Report' backButton={true} id={singleErrorTicket?.ticketNumber} backUrl={'/error-report'}>

            {
                !loadingState ? <div className="singleError-card-body">

                    <div className="ticketFrame">
                        <div className="left">

                            <div className="leftCard">
                                <div className="leftCardHead">
                                    <div className="headTitleWrapper">
                                        <p className='brief'>Ticket Brief</p>
                                    </div>
                                </div>

                                <div className="leftCardBody">
                                    <div className="leftCardBodyFrame">
                                        <div className="titleDiv">
                                            <span className='titleText'>Title</span>
                                            <span className='titleTextContent'>{singleErrorTicket?.title}</span>
                                        </div>

                                        <div className="typeDiv">
                                            <span className="type">Type</span>
                                            <span className="typeContent">{singleErrorTicket?.errorType}</span>
                                        </div>

                                        <div className="descDiv">
                                            <span className='description'>Description</span>
                                            <span className='descriptionContent'>{singleErrorTicket.additionalInfo}</span>
                                        </div>
                                        <div className="skuDiv">
                                            <span className='skuDesc'>SKU Keys</span>
                                            <span className='skuContent'>{(singleErrorTicket.skuId && singleErrorTicket.skuId.map(function (item, index) {
                                                return <span key={`${index}`}>{(index ? `, ${item}` : ` ${item}`)}</span>;
                                            })) || ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="right">
                            <div className="rightCard">
                                <div className="rightCardHead">
                                    <div className="rightHeadTitleWrapper">
                                        <div className="detailWrapper">
                                            <span className='detailText'>Ticket Details</span>
                                        </div>
                                        <div className="idWrapper">
                                            <span className='idText'>#{singleErrorTicket.ticketNumber || ''}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="rightCardBody">
                                    <div className="rightCardBodyFrame">

                                        <div className="block1">
                                            <span className='statusText'>Status</span>
                                            {singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'open' && <Tag color={'red'}>Open</Tag>}
                                            {singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'resolved' && <Tag color={'green'}>Resolved</Tag>}

                                        </div>

                                        <div className="block2">
                                            <span className='submittedText'>Submitted at</span>
                                            <span className='dateText'>{singleErrorTicket ? singleErrorTicket?.date : ''}</span>
                                        </div>

                                        <div className="block3">
                                            <span className='priorityText'>Priority</span>
                                            {singleErrorTicket?.priority?.toLowerCase() === 'high' && <span className='priorityClr highClr'>{singleErrorTicket?.priority}</span>}
                                            {singleErrorTicket?.priority?.toLowerCase() === 'low' && <span className='priorityClr lowClr'>{singleErrorTicket?.priority}</span>}
                                            {singleErrorTicket?.priority?.toLowerCase() === 'medium' && <span className='priorityClr mediumClr'>{singleErrorTicket?.priority}</span>}
                                        </div>

                                        <div className="block4">
                                            <span className='raisedByText'>Raised By</span>
                                            <span className='raisedByName'>{singleErrorTicket?.raisedBy}</span>
                                        </div>

                                        <div className="block4">
                                            <span className='pNameText'>Promotion Name</span>
                                            <span className='pNameSubText'>{singleErrorTicket?.promoName?.name || ''}</span>
                                        </div>

                                        <div className="block4">
                                            <span className='pNameText'>Action Owner</span>
                                            <span className='pNameSubText'>{singleErrorTicket?.actionOwner?.fullName || ''}</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        commentData && (commentData?.commentData?.length > 0) ? <div className="mainbodycomment">
                            {
                                commentData.commentData?.map((comment) => {

                                    return (
                                        <>
                                            <div className={comment.raisedBy === session.fullName ? "righta" : "leftc"} >
                                                <span className={comment.raisedBy === session.fullName ? "" : ""}></span>
                                                <div className={comment.raisedBy === session.fullName ? "reverseName" : ""}>
                                                    <span className={comment.raisedBy === session.fullName ? "adminName" : "sysName"} >{(comment.createdBy === `${singleErrorTicket?.createdBy}` ? comment.raisedBy : "Petco Support Team")}</span>
                                                    <span className={comment.raisedBy === session.fullName ? "sysTime" : "adminTime"}  >
                                                        {moment(`${comment.commentDate}`).fromNow()}
                                                    </span>

                                                </div>
                                                <span className={comment.raisedBy === session.fullName ? "adminComment" : "sysComment"} >{comment.comment}</span>

                                            </div>
                                        </>
                                    )
                                }

                                )
                            }

                        </div> : ''
                    }
                    {
                        singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'open' && session.role?.toLowerCase() === 'admin' &&
                        <textarea name="" id="" placeholder='Add comment to this ticket' value={msg} onChange={(e) => { setMsg(e.target.value); setError(false) }} style={{ resize: 'none' }} className='newtexta'></textarea>
                    }

                    {
                        singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'open' && session.role?.toLowerCase() === 'merchant' &&
                        <textarea name="" id="" placeholder='Add comment to this ticket' value={msg} onChange={(e) => { setMsg(e.target.value); setError(false) }} style={{ resize: 'none' }} className='newtexta'></textarea>
                    }

                    <span className='errormsg'>{error ? "Please add a comment" : ""}</span>
                    <div className="buttonFrame">
                        {
                            singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'open' && session.role?.toLowerCase() === 'admin' &&
                            <Button className='sendmsg' disabled={loading} onClick={sendMsg}> {loading ? `Loading...` : "Add Comment"}</Button>
                        }
                        {
                            singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'open' && session.role?.toLowerCase() === 'merchant' &&
                            <Button className='sendmsg' disabled={loading} onClick={sendMsg}> {loading ? `Loading...` : "Add Comment"}</Button>
                        }

                        {
                            singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'open' && session.role?.toLowerCase() === 'admin' &&
                            <button className='closeBtn' onClick={handleTicketStatus}>Mark as close</button>
                        }

                        {
                            singleErrorTicket && singleErrorTicket.status?.toLowerCase() === 'resolved'
                            && session.role?.toLowerCase() === 'merchant' &&
                            <button className='reOpenBtn' onClick={handleReOpenTicket}>Re-Open Ticket</button>
                        }
                    </div>

                    {
                        showDeleteAlert &&
                        <Alert message={ticketMessage} type="success" showIcon closable onClose={handleDeleteAlertClose} className='alertStyle'
                        />
                    }

                </div>

                    :
                    <div className="errorTipDiv">
                        <Spin size='middle' tip='Loading...' />
                    </div>
            }

        </CommonLayout>
    )
}

export default ErrorReportDetailPage;