import { createMap, mapCreateMessage, mapValidateMessage, validateMap } from 'actions/map'
import { Alert, Button, Table } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import {  MAP_DATE_FORMAT, SECONDS, TextMessage } from 'utils/constants'
import Element from '../Element'

const SecondStep = ({step,setStep,mapType,mapFormBuilder,selectedBrand,selectedSku,skuDataSource,setSkuDataSource,brandDataSource,setBrandDataSource,setSkuPayload,skuPayload,}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [skuColumn,setSkuColumn] = useState([])
  const [brandColumn,setBrandColumn] = useState([])
  const [questions,setQuestions]= useState([])
  const [requiredQues,setRequiredQues] = useState([])
  const [requiredErrorText, setRequiredErrorText] = useState('');
  const [requiredError, setRequiredError] = useState(false);
  const [rowKey, setRowKey] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
      if(requiredError){
        setTimeout(() => {
          setRequiredError(false)
        }, SECONDS);
      }

      if(isSuccess){
        setTimeout(() => {
          setIsSuccess(false)
        }, SECONDS);
      }
      
    },[requiredError,isSuccess])

  const handleInputChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){

        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)

        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleTextAreaChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
         brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i]?.questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleCheckboxChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
         brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleDateChange = (value,dateString,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: dateString}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:dateString,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:dateString,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = dateString
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
         brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:dateString,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:dateString,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = dateString
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleDatePickerChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value === null ? '' : moment(value).format(MAP_DATE_FORMAT)}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value === null ? '' : moment(value).format(MAP_DATE_FORMAT),
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value === null ? '' : moment(value).format(MAP_DATE_FORMAT),
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value === null ? '' : moment(value).format(MAP_DATE_FORMAT)
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value === null ? '' : moment(value).format(MAP_DATE_FORMAT),
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value === null ? '' : moment(value).format(MAP_DATE_FORMAT),
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value === null ? '' : moment(value).format(MAP_DATE_FORMAT)
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }

    }

    const handleDropdownChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]
  

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }

        questionsArr[i]={...questionsArr[i],answer: value}
        
        setQuestions(questionsArr)
       
    }

    const handleMultipleSelectChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: questionsArr[i]?.isMaster || false,
          masterDataType : questionsArr[i]?.masterDataType || '',
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: questionsArr[i]?.isMaster || false,
          masterDataType : questionsArr[i]?.masterDataType || '',
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].isMaster = true
              skuArr[record.key].fieldData[j].masterDataType = questionsArr[i].masterDataType
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: questionsArr[i]?.isMaster || false,
          masterDataType : questionsArr[i]?.masterDataType || '',
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: questionsArr[i]?.isMaster || false,
          masterDataType : questionsArr[i]?.masterDataType || '',
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].isMaster = true
              brandArr[record.key].fieldData[j].masterDataType = questionsArr[i].masterDataType
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleRadioChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)

        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleTabChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }

    const handleMasterDropDownChange = (value,i,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        questionsArr[i]={...questionsArr[i],answer: value}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        skuArr[record.key].fieldData = (skuArr[record.key].fieldData) ? [...skuArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: true,
          masterDataType: questionsArr[i].masterDataType,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: true,
          masterDataType: questionsArr[i].masterDataType,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              skuArr[record.key].fieldData[j].value = value
              skuArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              skuArr[record.key].fieldData[j].isMaster = true
              skuArr[record.key].fieldData[j].masterDataType = questionsArr[i].masterDataType
              skuArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        skuArr[record.key].fieldData = skuArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)

        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        brandArr[record.key].fieldData = (brandArr[record.key].fieldData) ? [...brandArr[record.key].fieldData, {
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: true,
          masterDataType: questionsArr[i].masterDataType,
          questionFlag: questionsArr[i]?.questionFlag || ''
        } ] 
          : 
          [{
          question_id:questionsArr[i]._id,
          value:value,
          questionHeader:questionsArr[i].questionHeader,
          isMaster: true,
          masterDataType: questionsArr[i].masterDataType,
          questionFlag: questionsArr[i]?.questionFlag || ''
        }]
        
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].question_id = questionsArr[i]._id
              brandArr[record.key].fieldData[j].value = value
              brandArr[record.key].fieldData[j].questionHeader = questionsArr[i].questionHeader
              brandArr[record.key].fieldData[j].isMaster = true
              brandArr[record.key].fieldData[j].masterDataType = questionsArr[i].masterDataType
              brandArr[record.key].fieldData[j].questionFlag = questionsArr[i].questionFlag || ''
            }
          }
        }

        brandArr[record.key].fieldData = brandArr[record.key].fieldData.filter((v,i,a)=>a.findIndex(v2=>v.question_id === v2.question_id)===i)
        setBrandDataSource(brandArr)
        }
    }
    const handleRadioSubChange = (value,i,questionType,questionHeader,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]
        
        if(questionsArr[i].subAnswerQuestionType === 'input' || questionsArr[i].subAnswerQuestionType === 'textarea'){
            delete questionsArr[i].subAnswer

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete skuArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete brandArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }
        }

        questionsArr[i].subAnswerQuestionType= questionType
        questionsArr[i]={...questionsArr[i],subAnswer: [{value: value,label:questionHeader}]}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
          if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j] = {
                ...skuArr[record.key].fieldData[j],
                subAnswer: [{value: value,label:questionHeader}],
                subAnswerQuestionType:questionType
              }
            }
          }
        }
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
          if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j] = {
                ...brandArr[record.key].fieldData[j],
                subAnswer: [{value: value,label:questionHeader}],
                subAnswerQuestionType:questionType
              }
            }
          }
        }
        setBrandDataSource(brandArr)
        }
    }

    const handleDropdownSubChange = (value,i,questionType,questionHeader,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        if(questionsArr[i].subAnswerQuestionType === 'input' || questionsArr[i].subAnswerQuestionType === 'textarea'){
            delete questionsArr[i].subAnswer

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete skuArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete brandArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }
        }

        questionsArr[i].subAnswerQuestionType= questionType
        questionsArr[i]={...questionsArr[i],subAnswer: [{value:value, label:questionHeader}]}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
          if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j] = {
                ...skuArr[record.key].fieldData[j],
                subAnswer: [{value: value,label:questionHeader}],
                subAnswerQuestionType:questionType
              }
            }
          }
        }
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
          if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j] = {
                ...brandArr[record.key].fieldData[j],
                subAnswer: [{value: value,label:questionHeader}],
                subAnswerQuestionType:questionType
              }
            }
          }
        }
        setBrandDataSource(brandArr)
        }
    }

    const handleMasterDropDownSubChange = (value,i,questionType,questionHeader,record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        if(questionsArr[i].subAnswerQuestionType === 'input' || questionsArr[i].subAnswerQuestionType === 'textarea'){
            delete questionsArr[i].subAnswer

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete skuArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete brandArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }
        }

        questionsArr[i].subAnswerQuestionType= questionType
        questionsArr[i].subIsMaster= true
        questionsArr[i]={...questionsArr[i],subAnswer: [{value:value, label:questionHeader}]}
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j] = {
                ...skuArr[record.key].fieldData[j],
                subAnswer: [{value: value,label:questionHeader}],
                subIsMaster: true,
                subAnswerQuestionType:questionType
              }
            }
          }
        }
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
          if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j] = {
                ...brandArr[record.key].fieldData[j],
                subAnswer: [{value: value,label:questionHeader}],
                subIsMaster: true,
                subAnswerQuestionType:questionType
              }
            }
          }
        }
        setBrandDataSource(brandArr)
        }
    }
    
    const handleInputSubChange = (value,i,subIndex,questionType,questionHeader='',record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        if(value === '' && subIndex === '' && questionType === ''){
            questionsArr[i].subAnswer = ''
            questionsArr[i].subAnswerQuestionType = ''

            setQuestions(questionsArr)

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   skuArr[record.key].fieldData[j].subAnswer = ''
                   skuArr[record.key].fieldData[j].subAnswerQuestionType = ''
                  }
                }
              }
              return;
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   brandArr[record.key].fieldData[j].subAnswer = ''
                   brandArr[record.key].fieldData[j].subAnswerQuestionType = ''
                  }
                }
              }
              return;
            }
            
            return;
        }

        if(questionsArr[i].subAnswerQuestionType === 'radio' || questionsArr[i].subAnswerQuestionType === 'dropdown' || questionsArr[i].subAnswerQuestionType === 'textarea'){
            delete questionsArr[i].subAnswer

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete skuArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete brandArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }
        }
        
        questionsArr[i].subAnswerQuestionType= questionType
        questionsArr[i].subAnswer = (questionsArr[i].subAnswer) ? [...questionsArr[i].subAnswer, {subIndex:subIndex,value:value,label:questionHeader} ] : [{subIndex:subIndex,value:value,label:questionHeader}]

        if(questionsArr[i].subAnswer && questionsArr[i].subAnswer.length>0){
          for (let j = 0; j < questionsArr[i].subAnswer.length; j++) {
            if(questionsArr[i].subAnswer[j].subIndex === subIndex){
              questionsArr[i].subAnswer[j].subIndex = subIndex
              questionsArr[i].subAnswer[j].value = value
              questionsArr[i].subAnswer[j].label = questionHeader
            }
          }
        }

        questionsArr[i].subAnswer = questionsArr[i].subAnswer.filter((v,i,a)=>a.findIndex(v2=>v.subIndex === v2.subIndex)===i)
        setQuestions(questionsArr)

        if(mapType === 'sku'){
          if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].subAnswerQuestionType= questionType
              skuArr[record.key].fieldData[j].subAnswer = (skuArr[record.key].fieldData[j].subAnswer) ? [...skuArr[record.key].fieldData[j].subAnswer, {subIndex:subIndex,value:value,label:questionHeader} ] : [{subIndex:subIndex,value:value,label:questionHeader}]

              if(skuArr[record.key].fieldData[j].subAnswer && skuArr[record.key].fieldData[j].subAnswer.length>0){
                for (let k = 0; k < skuArr[record.key].fieldData[j].subAnswer.length; k++) {
                  if(skuArr[record.key].fieldData[j].subAnswer[k].subIndex === subIndex){
                    skuArr[record.key].fieldData[j].subAnswer[k].subIndex = subIndex
                    skuArr[record.key].fieldData[j].subAnswer[k].value = value
                    skuArr[record.key].fieldData[j].subAnswer[k].label = questionHeader
                  }
                }
              }

              skuArr[record.key].fieldData[j].subAnswer = skuArr[record.key].fieldData[j].subAnswer.filter((v,i,a)=>a.findIndex(v2=>v.subIndex === v2.subIndex)===i)

            }
          }
        }
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){

        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].subAnswerQuestionType= questionType
              brandArr[record.key].fieldData[j].subAnswer = (brandArr[record.key].fieldData[j].subAnswer) ? [...brandArr[record.key].fieldData[j].subAnswer, {subIndex:subIndex,value:value,label:questionHeader} ] : [{subIndex:subIndex,value:value,label:questionHeader}]

              if(brandArr[record.key].fieldData[j].subAnswer && brandArr[record.key].fieldData[j].subAnswer.length>0){
                for (let k = 0; k < brandArr[record.key].fieldData[j].subAnswer.length; k++) {
                  if(brandArr[record.key].fieldData[j].subAnswer[k].subIndex === subIndex){
                    brandArr[record.key].fieldData[j].subAnswer[k].subIndex = subIndex
                    brandArr[record.key].fieldData[j].subAnswer[k].value = value
                    brandArr[record.key].fieldData[j].subAnswer[k].label = questionHeader
                  }
                }
              }

              brandArr[record.key].fieldData[j].subAnswer = brandArr[record.key].fieldData[j].subAnswer.filter((v,i,a)=>a.findIndex(v2=>v.subIndex === v2.subIndex)===i)

            }
          }
        }
        setBrandDataSource(brandArr)
        }
    }

    const handleTextareaSubChange = (value,i,subIndex,questionType,questionHeader='',record) => {
        const questionsArr = [...questions]
        const brandArr = [...brandDataSource]
        const skuArr = [...skuDataSource]

        if(value === '' && subIndex === '' && questionType === ''){
            questionsArr[i].subAnswer = ''
            questionsArr[i].subAnswerQuestionType = ''

            setQuestions(questionsArr);

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   skuArr[record.key].fieldData[j].subAnswer = ''
                   skuArr[record.key].fieldData[j].subAnswerQuestionType = ''
                  }
                }
              }
              return;
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   brandArr[record.key].fieldData[j].subAnswer = ''
                   brandArr[record.key].fieldData[j].subAnswerQuestionType = ''
                  }
                }
                setBrandDataSource(brandArr)
              }
              return;
            }
            return;
        }

        if(questionsArr[i].subAnswerQuestionType === 'radio' || questionsArr[i].subAnswerQuestionType === 'dropdown' || questionsArr[i].subAnswerQuestionType === 'input'){
            delete questionsArr[i].subAnswer

            if(mapType === 'sku'){
              if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
                for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
                  if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete skuArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }

            if(mapType === 'brand'){
              if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
                for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
                  if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
                   delete brandArr[record.key].fieldData[j].subAnswer
                  }
                }
              }
            }
        }
        
        questionsArr[i].subAnswerQuestionType= questionType
        questionsArr[i].subAnswer = (questionsArr[i].subAnswer) ? [...questionsArr[i].subAnswer, {subIndex:subIndex,value:value,label:questionHeader} ] : [{subIndex:subIndex,value:value,label:questionHeader}]

        if(questionsArr[i].subAnswer && questionsArr[i].subAnswer.length>0){
          for (let j = 0; j < questionsArr[i].subAnswer.length; j++) {
            if(questionsArr[i].subAnswer[j].subIndex === subIndex){
              questionsArr[i].subAnswer[j].subIndex = subIndex
              questionsArr[i].subAnswer[j].value = value
              questionsArr[i].subAnswer[j].label = questionHeader
            }
          }
        }

        questionsArr[i].subAnswer = questionsArr[i].subAnswer.filter((v,i,a)=>a.findIndex(v2=>v.subIndex === v2.subIndex)===i)
        setQuestions(questionsArr)

        if(mapType === 'sku'){
        
        if(skuArr[record.key].fieldData && skuArr[record.key].fieldData.length>0){
          for (let j = 0; j < skuArr[record.key].fieldData.length; j++) {
            if(skuArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              skuArr[record.key].fieldData[j].subAnswerQuestionType= questionType
              skuArr[record.key].fieldData[j].subAnswer = (skuArr[record.key].fieldData[j].subAnswer) ? [...skuArr[record.key].fieldData[j].subAnswer, {subIndex:subIndex,value:value,label:questionHeader} ] : [{subIndex:subIndex,value:value,label:questionHeader}]

              if(skuArr[record.key].fieldData[j].subAnswer && skuArr[record.key].fieldData[j].subAnswer.length>0){
                for (let k = 0; k < skuArr[record.key].fieldData[j].subAnswer.length; k++) {
                  if(skuArr[record.key].fieldData[j].subAnswer[k].subIndex === subIndex){
                    skuArr[record.key].fieldData[j].subAnswer[k].subIndex = subIndex
                    skuArr[record.key].fieldData[j].subAnswer[k].value = value
                    skuArr[record.key].fieldData[j].subAnswer[k].label = questionHeader
                  }
                }
              }

              skuArr[record.key].fieldData[j].subAnswer = skuArr[record.key].fieldData[j].subAnswer.filter((v,i,a)=>a.findIndex(v2=>v.subIndex === v2.subIndex)===i)

            }
          }
        }
        setSkuDataSource(skuArr)
        }

        if(mapType === 'brand'){
        if(brandArr[record.key].fieldData && brandArr[record.key].fieldData.length>0){
          for (let j = 0; j < brandArr[record.key].fieldData.length; j++) {
            if(brandArr[record.key].fieldData[j].question_id === questionsArr[i]._id){
              brandArr[record.key].fieldData[j].subAnswerQuestionType= questionType
              brandArr[record.key].fieldData[j].subAnswer = (brandArr[record.key].fieldData[j].subAnswer) ? [...brandArr[record.key].fieldData[j].subAnswer, {subIndex:subIndex,value:value,label:questionHeader} ] : [{subIndex:subIndex,value:value,label:questionHeader}]

              if(brandArr[record.key].fieldData[j].subAnswer && brandArr[record.key].fieldData[j].subAnswer.length>0){
                for (let k = 0; k < brandArr[record.key].fieldData[j].subAnswer.length; k++) {
                  if(brandArr[record.key].fieldData[j].subAnswer[k].subIndex === subIndex){
                    brandArr[record.key].fieldData[j].subAnswer[k].subIndex = subIndex
                    brandArr[record.key].fieldData[j].subAnswer[k].value = value
                    brandArr[record.key].fieldData[j].subAnswer[k].label = questionHeader
                  }
                }
              }

              brandArr[record.key].fieldData[j].subAnswer = brandArr[record.key].fieldData[j].subAnswer.filter((v,i,a)=>a.findIndex(v2=>v.subIndex === v2.subIndex)===i)

            }
          }
        }
        setBrandDataSource(brandArr)
        }
    }

  useEffect(() => {
    if(mapFormBuilder && mapFormBuilder.length>0 && mapType === 'sku'){
            setQuestions(mapFormBuilder[0].skuQuestionIds)
            const filter = mapFormBuilder[0].skuQuestionIds.filter(item => item.designate && item.designate === 'required')
            setRequiredQues(filter)
        }
        if(mapFormBuilder && mapFormBuilder.length>0 && mapType === 'brand'){
            setQuestions(mapFormBuilder[0].brandQuestionIds)
            const filter = mapFormBuilder[0].brandQuestionIds.filter(item => item.designate && item.designate === 'required')
            setRequiredQues(filter)
        }
  },[mapFormBuilder,mapType])  

  useEffect(() => {
    if(mapType === 'sku'){
      const columnsData = questions.map((question,i) => {
        return {
          title: (question.designate && question.designate === 'required') ?  <>
          <span className="spanReq mr-5">*</span>
          {question.questionHeader}
          </> :  question.questionHeader,
          dataIndex: question.questionHeader,
          key: question.questionHeader,
          render: (text, record, index) => (
            <Element
            record={record}
                                    key={i}
                                    question={question}
                                    index={i}
                                    questionType={question.questionType}
                                    questionHeader={question.questionHeader}
                                    options={question.optionValue}
                                    designate={question.designate}
                                    action={'create'}
                                    handleInputChange={handleInputChange}
                                    handleTextAreaChange={handleTextAreaChange}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleDateChange={handleDateChange}
                                    handleDropdownChange={handleDropdownChange}
                                    handleMultipleSelectChange={handleMultipleSelectChange}
                                    handleRadioChange={handleRadioChange}
                                    handleTabChange={handleTabChange}
                                    handleMasterDropDownChange={handleMasterDropDownChange}
                                    handleRadioSubChange={handleRadioSubChange}
                                    handleInputSubChange={handleInputSubChange}
                                    handleDropdownSubChange={handleDropdownSubChange}
                                    handleMasterDropDownSubChange={handleMasterDropDownSubChange}
                                    handleTextareaSubChange={handleTextareaSubChange}
                                    handleDatePickerChange={handleDatePickerChange}
                                    questionFlag={question?.questionFlag || ''}
                                />
          )
        }
      })
      setSkuColumn([{
          title: 'SKU',
          dataIndex: 'sku',
          key: 'sku',
        },...columnsData])

    }
    if(mapType === 'brand'){
      const columnsData = questions.map((question,i) => {
        return {
          title: (question.designate && question.designate === 'required') ?  <>
          <span className="spanReq mr-5">*</span>
          {question.questionHeader}
          </> :  question.questionHeader,
          dataIndex: question.questionHeader,
          key: question.questionHeader,
          width: '30%',
          render: (text, record, index) => (
            <Element
            record={record}
                                    key={i}
                                    question={question}
                                    index={i}
                                    questionType={question.questionType}
                                    questionHeader={question.questionHeader}
                                    options={question.optionValue}
                                    designate={question.designate}
                                    action={'create'}
                                    handleInputChange={handleInputChange}
                                    handleTextAreaChange={handleTextAreaChange}
                                    handleCheckboxChange={handleCheckboxChange}
                                    handleDateChange={handleDateChange}
                                    handleDropdownChange={handleDropdownChange}
                                    handleMultipleSelectChange={handleMultipleSelectChange}
                                    handleRadioChange={handleRadioChange}
                                    handleTabChange={handleTabChange}
                                    handleMasterDropDownChange={handleMasterDropDownChange}
                                    handleRadioSubChange={handleRadioSubChange}
                                    handleInputSubChange={handleInputSubChange}
                                    handleDropdownSubChange={handleDropdownSubChange}
                                    handleMasterDropDownSubChange={handleMasterDropDownSubChange}
                                    handleTextareaSubChange={handleTextareaSubChange}
                                    handleDatePickerChange={handleDatePickerChange}
                                    questionFlag={question?.questionFlag || ''}
                                />
          )
        }
      })
      setBrandColumn([{
          title: 'Brand',
          dataIndex: 'brand',
          key: 'brand',
        },...columnsData])
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[mapType,dispatch,mapFormBuilder,questions]) 

  
  const handleNextStepChange = async () => {

    const optionValueArr = questions.filter(item => item.questionFlag && item.questionFlag.toLowerCase() === 'map hold duration')[0]?.optionValue
  
    if(mapType === 'brand'){
      
      for (let i = 0; i < brandDataSource.length; i++) {
        let fieldDataIds = brandDataSource[i].fieldData ? brandDataSource[i]?.fieldData?.map(item => item.question_id) : []

        const containsAll = requiredQues.every(element => {
          return fieldDataIds.includes(element._id);
        });

        if(!containsAll){
          setRequiredError(true)
          setRequiredErrorText(TextMessage.REQUIRED_ERROR_TEXT)
          return;
        }else{
          setRequiredErrorText("")
          setRequiredError(false)
        }
      }

      let arr =[]

      for (let i = 0; i < brandDataSource.length; i++) {
        const result = questions.filter(o1 => !brandDataSource[i]?.fieldData?.some(o2 => o1._id === o2.question_id));

        const obj = result && result.length>0 && result.map(item => {
          return {
            question_id: item._id,
            questionHeader: item.questionHeader,
            value: '',
            questionFlag : item?.questionFlag || ''
          }
        })

        if(obj){
          brandDataSource[i].fieldData = (brandDataSource[i].fieldData) ? [...brandDataSource[i].fieldData,...obj] : [...obj]
        }
    
        const checkMapHold = brandDataSource[i].fieldData.filter(item => (item?.questionFlag.toLowerCase() === 'map hold duration' || item.questionHeader.toLowerCase().includes('map hold duration')))

        for (let k = 0; k < optionValueArr.length; k++) {
          if((optionValueArr[k].optionText === checkMapHold[0].value) && optionValueArr[k].hasOwnProperty('subOptions') && !checkMapHold[0].hasOwnProperty('subAnswer')){
            arr.push(brandDataSource[i].key)
          }

          if((optionValueArr[k].optionText === checkMapHold[0].value) && optionValueArr[k].hasOwnProperty('subOptions') && checkMapHold[0].hasOwnProperty('subAnswer') && (checkMapHold[0]?.subAnswer[0]?.value === '' || checkMapHold[0]?.subAnswer === '')){
            arr.push(brandDataSource[i].key)
          }
        }
      }
      setRowKey([...arr])

      //payload code
      if(arr.length === 0){
        const data = brandDataSource.map((item,i) => {
        return {
          fieldData:item.fieldData,
          brand:item.brand
        }
      })

      const payload = {
        mapType,
        dynamicRecord: data,
        status: 'approved'
      }

      setLoading(true)
      const res = await dispatch(createMap(payload))

      if(res.error === undefined){
        dispatch(mapCreateMessage(TextMessage.MAP_CREATE_TEXT))
        navigate('/map')
        setLoading(false)
      }else{
        navigate('/map')
        setLoading(false)
      }

      }else{
        setRequiredErrorText(TextMessage.MAP_APPROVAL_TEXT)
        setRequiredError(true)
      }
      
    }

    

    if(mapType === 'sku'){
      for (let i = 0; i < skuDataSource.length; i++) {
        let fieldDataIds = skuDataSource[i].fieldData ? skuDataSource[i]?.fieldData?.map(item => item.question_id) : []

        const containsAll = requiredQues.every(element => {
          return fieldDataIds.includes(element._id);
        });

        if(!containsAll){
          setRequiredError(true)
          setRequiredErrorText(TextMessage.REQUIRED_ERROR_TEXT)
          return;
        }else{
          setRequiredErrorText("")
          setRequiredError(false)
        }
      }

      let arr =[]

      for (let i = 0; i < skuDataSource.length; i++) {
        const result = questions.filter(o1 => !skuDataSource[i]?.fieldData?.some(o2 => o1._id === o2.question_id));

        const obj = result && result.length>0 && result.map(item => {
          return {
            question_id: item._id,
            questionHeader: item.questionHeader,
            value: '',
            questionFlag : item?.questionFlag || ''
          }
        })

        if(obj){
          skuDataSource[i].fieldData = (skuDataSource[i].fieldData) ? [...skuDataSource[i].fieldData,...obj] : [...obj]
        }
      
        const checkMapHold = skuDataSource[i].fieldData.filter(item => (item?.questionFlag.toLowerCase() === 'map hold duration' || item.questionHeader.toLowerCase().includes('map hold duration')))

        for (let k = 0; k < optionValueArr.length; k++) {
          if((optionValueArr[k].optionText === checkMapHold[0].value) && optionValueArr[k].hasOwnProperty('subOptions') && !checkMapHold[0].hasOwnProperty('subAnswer')){
            arr.push(skuDataSource[i].key)
          }

          if((optionValueArr[k].optionText === checkMapHold[0].value) && optionValueArr[k].hasOwnProperty('subOptions') && checkMapHold[0].hasOwnProperty('subAnswer') && (checkMapHold[0]?.subAnswer[0]?.value === '' || checkMapHold[0]?.subAnswer === '')){
            arr.push(skuDataSource[i].key)
          }
        }

        setRowKey([...arr])
      }

      //payload structure code
      if(arr.length === 0){
        const data = skuDataSource.map((item,i) => {
        return {
          fieldData:item.fieldData,
          sku:item?.sku?.trim(),
        }
      })

      const payload = {
        mapType,
        dynamicRecord: data
      }
      setLoading(true)
      const res = await dispatch(validateMap(payload))

      if(res.error === undefined){
        const misMatch = res.payload?.data?.data?.requestData?.mapPriceMisMatch;
        const pricingGroupAllValueEmpty = res.payload?.data?.data?.requestData?.pricingGroupAllValueEmpty;

        const data = res.payload?.data?.data?.requestData;
        const createData = {
          ...data,
          status: (pricingGroupAllValueEmpty && pricingGroupAllValueEmpty === true) ? 'pending' : 'approved'
        }

        if(misMatch && misMatch === true){
          setSkuPayload([data])
          setLoading(false)
          dispatch(mapValidateMessage(TextMessage.MAP_VALIDATE_TEXT))
          setStep(step + 1)
        }else{
          const res1 = await dispatch(createMap(createData))
          if(res1.error === undefined){
            setLoading(false)
            dispatch(mapCreateMessage(TextMessage.MAP_CREATE_TEXT))
            navigate('/map')
          }else{
            setLoading(false)
            dispatch(mapCreateMessage(TextMessage.ERROR_TEXT))
            navigate('/map')
          }
        }
      }
      
      }else{
        setRequiredErrorText(TextMessage.MAP_APPROVAL_TEXT)
        setRequiredError(true)
      }
    }
    
    }

  const handlePrevStepChange = () => {
      if(mapType === 'brand'){
        const data = selectedBrand.map((item,i) => {
      
      return {
        key: i,
        brand : item,
      }
    })

    const qsHeader = mapFormBuilder && mapFormBuilder.length>0 && mapFormBuilder[0].brandQuestionIds.map(item => {
        return {
          [item.questionHeader]:''
        }
      })

      for (let i = 0; i < data.length; i++) {
        let obj= {}
        for (let j = 0; j < qsHeader.length; j++) {
          obj= {...obj,...qsHeader[j]}
        }
        data[i] = {
          ...data[i],...obj
        }
      }
    
      setBrandDataSource(data)
      setSkuPayload([])

      setStep(step - 1)
      }

      if(mapType === 'sku'){
        const data = selectedSku.map((item,i) => {
      
      return {
        key: i,
        sku : item,
      }
    })

    const qsHeader = mapFormBuilder && mapFormBuilder.length>0 && mapFormBuilder[0].skuQuestionIds.map(item => {
        return {
          [item.questionHeader]:''
        }
      })

      for (let i = 0; i < data.length; i++) {
        let obj= {}
        for (let j = 0; j < qsHeader.length; j++) {
          obj= {...obj,...qsHeader[j]}
        }
        data[i] = {
          ...data[i],...obj
        }
      }
    
      setSkuDataSource(data)

      setStep(step - 1)
      }
      
    }

    const handleErrorAlertClose = () => {
      setRequiredError(false)
    }

    const handleRowClassName = (record, index) => {
      for (let i = 0; i < rowKey.length; i++) {
        if(rowKey[i] === index) return 'tb-bg'
      }
    }

  return (
    <div className='w-100'>
      
        <>
        <div className='w-100 map-table' style={{overflowX:'auto'}}>
        <Table
        columns={mapType === 'sku' ? skuColumn : brandColumn}
        dataSource={mapType === 'sku' ? skuDataSource : brandDataSource}
        pagination={false}
        rowClassName={(record, index) => handleRowClassName(record, index)}
        />
      </div>
      <div className='mt-30 text-end'>
            <Button className='mr-10' onClick={handlePrevStepChange} disabled={loading}>Prev</Button>
            <Button className='footerBtnNavy' onClick={handleNextStepChange} disabled={loading}>{loading ? 'Loading...' : 'Next'}</Button>
      </div>

      {
        requiredError && <Alert message={requiredErrorText} type={'error'}
                        showIcon closable onClose={handleErrorAlertClose}
                        className='alertStyle'
                  />
      }

        </>
      
    </div>
  )
}

export default SecondStep