/* eslint-disable no-unused-vars */
import "./Error-type.css";
import { useEffect, useState } from "react";
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  UserAddOutlined,
  FullscreenOutlined,
  ReloadOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Spin,
  Switch,
  Select,
} from "antd";
import Fullscreen from "fullscreen-react";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "components/layout/Layout";
import CustomTable from "components/common/table/TableNew";
import TableSearchDropdown from "components/TableSearchDropdown";
import { ERRORS_TYPE_LIST } from "utils/column";
import { Validator } from "utils/validations";
import { TextMessage, SECONDS, priorities, workflow } from "utils/constants";
import { setPage } from "actions/app";
import { updatePromoTypeBySearch } from "actions/promoTypes";
import { useNavigate } from "react-router";
import useAlert from "context/alert/alert.hook";
import {
  getAllErrorType,
  createErrorType,
  updateErrorByStatus,
  deleteById,
  updateErrorById,
  updateLocalCurrentTeam,
} from "actions/error-type";
import { AlertVariant } from "context/alert/alert.provider";
import ErrorTypeService from "services/error-type/error-type.service";
import session from "utils/session";
const { Search } = Input;

const ErrorType = () => {
  const errorTypeService = new ErrorTypeService();
  const [isEnter, setIsEnter] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const { errors, total } = useSelector((state) => state.errorType);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableLoading, setTableLoading] = useState(false);
  const [showTeamDeleteAlert, setShowTeamDeleteAlert] = useState(false);
  const [formData, setFormData] = useState([]);

  const searchedPromoTypes = useSelector(
    (state) => state.promoTypes.searchedPromoTypes
  );

  const [tableSize, setTableSize] = useState("default");
  const [tableClicked, setTableClicked] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRecord, setCurrentRecord] = useState({});

  const [promoTypeName, setPromoTypeName] = useState("");
  const [errorPriority, setErrorPriority] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [status, setStatus] = useState(true);
  const [promoTypeNameError, setPromoTypeNameError] = useState("");
  const [priorityError, setPriorityError] = useState("");
  const [okBtnText, setOkBtnText] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [showAddAlert, setShowAddAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [promoDeActive, setPromoDeActive] = useState(false);
  const [promoActive, setPromoActive] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [reloadingState, setReLoadingState] = useState(false);

  const [urgentWorkflow, setUrgentWorkflow] = useState("");
  const [highWorkflow, setHighWorkflow] = useState("");
  const [mediumWorkflow, setMediumWorkflow] = useState("");
  const [lowWorkflow, setLowWorkflow] = useState("");

  const [defaultWorkflow, setDefaultWorkflow] = useState("");

  const [urgentDays, setUrgentDays] = useState("");
  const [highDays, setHighDays] = useState("");
  const [mediumDays, setMediumDays] = useState("");
  const [lowDays, setLowDays] = useState("");

  const [urgentDaysError, setUrgentDaysError] = useState("");
  const [highDaysError, setHighDaysError] = useState("");
  const [mediumDaysError, setMediumDaysError] = useState("");
  const [lowDaysError, setLowDaysError] = useState("");

  const [urgentWorkflowList, setUrgentWorkflowList] = useState(workflow);
  const [highWorkflowList, setHighWorkflowList] = useState(workflow);

  const [mediumWorkflowList, setMediumWorkflowList] = useState(workflow);

  const [lowWorkflowList, setLowWorkflowList] = useState(workflow);
  const role = session && session.role ? session.role.toLowerCase() : "";

  useEffect(() => {
    dispatch(setPage("Change Type"));
  }, [dispatch]);

  useEffect(() => {
    if (searchedText === "") {
      dispatch(updatePromoTypeBySearch([]));
    }
  }, [searchedText, dispatch]);

  const getData = async (params = {}, isLoading = true) => {
    if (params?.search !== "" && searchedText) {
      params.search = searchedText;
    }
    if (isLoading) {
      setLoadingState(true);
    }
    const res = await dispatch(getAllErrorType(params));
    if (res) {
      setLoadingState(false);
      setTableLoading(false);
    }
  };
  useEffect(() => {
    const getCall = async () => {
      setLoadingState(true);
      getData({ page: 1 }, false);
    };
    getCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (showUpdateAlert) {
      setTimeout(() => {
        setShowUpdateAlert(false);
      }, SECONDS);
    }
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
    if (showTeamDeleteAlert) {
      setTimeout(() => {
        setShowTeamDeleteAlert(false);
      }, SECONDS);
    }
  }, [showUpdateAlert, showDeleteAlert, showTeamDeleteAlert]);

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Are you sure you want to deactivate this error type?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => promoDeactivateYes(id, false),
      okCancel: promoDeactivateNo,
      okType: "primary",
    });
  };

  const promoDeactivateYes = (id, checked) => {
    dispatch(updateErrorByStatus(id, checked));
  };
  const promoDeactivateNo = () => {
    setPromoActive(true);
  };

  const changeStatusNo = async (id) => {
    Modal.confirm({
      title: "Do you want to activate this error type?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => promoActivateYes(id, true),
      okCancel: promoActivateNo,
      okType: "primary",
    });
  };

  const promoActivateYes = async (id, checked) => {
    await dispatch(updateErrorByStatus(id, checked));
  };
  const promoActivateNo = () => {
    setPromoDeActive(false);
  };

  const setupRows = (promoTypes) => {
    return promoTypes?.map((o) => {
      return {
        errorType: o.name,
        priorities: o.priorities,
        _id: o._id,
        status: o.isActive ? (
          <Switch
            checked={promoActive}
            className="switchStyle"
            onChange={() => changeStatusYes(o._id)}
          />
        ) : (
          <Switch
            checked={promoDeActive}
            onChange={() => changeStatusNo(o._id)}
          />
        ),
      };
    });
  };

  const handleChange = (value) => {
    setErrorPriority(value);
  };
  const handleDeselect = (value) => {
    // eslint-disable-next-line default-case
    switch (value) {
      case "urgent":
        setUrgentWorkflow("");
        setUrgentDays("");
        break;
      case "high":
        setHighWorkflow("");
        setHighDays("");
        break;
      case "medium":
        setMediumWorkflow("");
        setMediumDays("");
        break;
      case "low":
        setLowWorkflow("");
        setLowDays("");
        break;
    }
  };
  const handleUrgentChange = (value) => {
    setUrgentWorkflow(value);
    // !defaultWorkflow && setDefaultWorkflow("urgent");
    defaultWorkflow !== "high" &&
      setHighWorkflowList(
        workflow.filter(
          (o) =>
            +o.value < +value &&
            +o.value !== +mediumWorkflow &&
            +o.value !== +lowWorkflow
        )
      );
    defaultWorkflow !== "medium" &&
      setMediumWorkflowList(
        workflow.filter((o) => +o.value < +value && +o.value !== +lowWorkflow)
      );
    defaultWorkflow !== "low" &&
      setLowWorkflowList(
        workflow.filter(
          (o) =>
            +o.value < +value &&
            +o.value !== +highWorkflow &&
            +o.value !== +mediumWorkflow &&
            +o.value !== +lowWorkflow
        )
      );

    setHighWorkflow("");
    setMediumWorkflow("");
    setLowWorkflow("");
  };
  const handleHighChange = (value) => {
    setHighWorkflow(value);
    // !defaultWorkflow && setDefaultWorkflow("high");
    defaultWorkflow !== "urgent" &&
      setUrgentWorkflowList(
        workflow.filter(
          (o) =>
            +o.value > +value &&
            +o.value !== +mediumWorkflow &&
            +o.value !== +lowWorkflow
        )
      );
    defaultWorkflow !== "medium" &&
      setMediumWorkflowList(
        workflow.filter((o) => +o.value < +value && +o.value !== +lowWorkflow)
      );
    defaultWorkflow !== "low" &&
      setLowWorkflowList(
        workflow.filter(
          (o) =>
            +o.value < +value &&
            +o.value !== +mediumWorkflow &&
            +o.value !== +lowWorkflow
        )
      );

    setMediumWorkflow("");
    setLowWorkflow("");
  };
  const handleMediumChange = (value) => {
    setMediumWorkflow(value);
    // !defaultWorkflow && setDefaultWorkflow("medium");
    defaultWorkflow !== "urgent" &&
      setUrgentWorkflowList(
        workflow.filter(
          (o) =>
            // eslint-disable-next-line no-mixed-operators
            +o.value > +value + o.value !== +highWorkflow &&
            +o.value !== +lowWorkflow
        )
      );
    defaultWorkflow !== "high" &&
      setHighWorkflowList(
        workflow.filter(
          (o) => +o.value > +value && +o.value !== +urgentWorkflow
        )
      );
    defaultWorkflow !== "low" &&
      setLowWorkflowList(
        workflow.filter((o) => +o.value < +value && +o.value !== +lowWorkflow)
      );

    setLowWorkflow("");
  };
  const handleLowChange = (value) => {
    setLowWorkflow(value);
    // !defaultWorkflow && setDefaultWorkflow("low");
    defaultWorkflow !== "urgent" &&
      setUrgentWorkflowList(
        workflow.filter(
          (o) =>
            +o.value > +value &&
            +o.value !== +mediumWorkflow &&
            +o.value !== +highWorkflow
        )
      );
    defaultWorkflow !== "high" &&
      setHighWorkflowList(
        workflow.filter(
          (o) => +o.value > +value && +o.value !== +urgentWorkflow
        )
      );
    defaultWorkflow !== "medium" &&
      setMediumWorkflowList(
        workflow.filter(
          (o) =>
            +o.value > +value &&
            +o.value !== +highWorkflow &&
            +o.value !== +urgentWorkflow
        )
      );
  };

  const handleUrgentDaysChange = (value) => {
    setUrgentDays(value);
    if (highDays && +value < +highDays) {
      setUrgentDaysError("");
      setUrgentDays(value);
    } else {
      highDays && setUrgentDaysError("Value must be less than high start days");
    }

    if (mediumDays && +value < +mediumDays) {
      setUrgentDaysError("");
      setUrgentDays(value);
    } else {
      mediumDays &&
        setUrgentDaysError("Value must be less than medium start days");
    }

    if (lowDays && +value < +lowDays) {
      setUrgentDaysError("");
      setUrgentDays(value);
    } else {
      lowDays && setUrgentDaysError("Value must be less than low start days");
    }
  };

  const handleHighDaysChange = (value) => {
    setHighDays(value);
    // (!urgentDays && !mediumDays && !lowDays) && setHighDays(value)
    if (urgentDays && +value > +urgentDays) {
      setHighDaysError("");
      setHighDays(value);
    } else {
      urgentDays &&
        setHighDaysError("Value must be greater than urgent start days");
    }

    if (mediumDays && +value < +mediumDays) {
      setHighDaysError("");
      setHighDays(value);
    } else {
      mediumDays &&
        setHighDaysError("Value must be less than medium start days");
    }

    if (lowDays && +value < +lowDays) {
      setHighDaysError("");
      setHighDays(value);
    } else {
      lowDays && setHighDaysError("Value must be less than low start days");
    }
  };

  const handleMediumDaysChange = (value) => {
    setMediumDays(value);
    // (!urgentDays && !mediumDays && !lowDays) && setHighDays(value)
    if (urgentDays && +value > +urgentDays) {
      setMediumDaysError("");
      setMediumDays(value);
    } else {
      urgentDays &&
        setMediumDaysError("Value must be greater than urgent start days");
    }

    if (highDays && +value > +highDays) {
      setMediumDaysError("");
      setMediumDays(value);
    } else {
      highDays &&
        setMediumDaysError("Value must be greater than high start days");
    }

    if (lowDays && +value < +lowDays) {
      setMediumDaysError("");
      setMediumDays(value);
    } else {
      lowDays && setMediumDaysError("Value must be less than low start days");
    }
  };

  const handleLowDaysChange = (value) => {
    setLowDays(value);
    if (urgentDays && +value > +urgentDays) {
      setLowDaysError("");
      setLowDays(value);
    } else {
      urgentDays &&
        setLowDaysError("Value must be greater than urgent start days");
    }

    if (highDays && +value > +highDays) {
      setLowDaysError("");
      setLowDays(value);
    } else {
      highDays && setLowDaysError("Value must be greater than high start days");
    }

    if (mediumDays && +value > +mediumDays) {
      setLowDaysError("");
      setLowDays(value);
    } else {
      mediumDays &&
        setLowDaysError("Value must be greater than medium start days");
    }
  };

  const renderTag = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Error type name
              </label>

              <Input
                placeholder="Write here"
                value={promoTypeName}
                className="mt-5"
                onChange={handlePromoTypeName}
              />
              <span className="errorLabel">{promoTypeNameError}</span>
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label>
                <span className="spanReq">*</span> Priority
              </label>

              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Select"
                defaultValue={errorPriority}
                onDeselect={handleDeselect}
                onChange={handleChange}
                options={priorities}
              />
              <span className="errorLabel">{priorityError}</span>
            </div>
          </Col>
        </Row>
        {errorPriority.length > 1 && (
          <div gutter={16} className="mb-10">
            <span className="spanReq">
              Note:- The workflow completion percentage should always be from
              high to low
            </span>
          </div>
        )}
        {errorPriority.length > 1 && errorPriority.includes("urgent") && (
          <div key={"urgent"} className="mb-8">
            <span className="priority-label">{"Urgent"}</span>
            <div className=" dynamic-container">
              <div className="label-container">
                <label>
                  <span className="spanReq">*</span> Workflow complete
                </label>

                <Select
                  placeholder="Select"
                  onChange={handleUrgentChange}
                  value={urgentWorkflow}
                  options={urgentWorkflowList}
                />
              </div>
              <div>
                <label>
                  <span className="spanReq">*</span> Number of days before start
                  days
                </label>

                <Input
                  placeholder="Write here"
                  value={urgentDays}
                  style={{ marginTop: 0 }}
                  onChange={(e) => handleUrgentDaysChange(e.target.value)}
                  maxLength={31}
                />
                <span className="errorLabel">{urgentDaysError}</span>
              </div>
            </div>
          </div>
        )}

        {errorPriority.length > 1 && errorPriority.includes("high") && (
          <div key={"high"} className="mb-8">
            <span className="priority-label">{"High"}</span>
            <div className="dynamic-container">
              <div className="label-container">
                <label>
                  <span className="spanReq">*</span> Workflow complete
                </label>

                <Select
                  placeholder="Select"
                  onChange={handleHighChange}
                  value={highWorkflow}
                  options={highWorkflowList}
                />
              </div>
              <div>
                <label>
                  <span className="spanReq">*</span> Number of days before start
                  days
                </label>

                <Input
                  placeholder="Write here"
                  value={highDays}
                  style={{ marginTop: 0 }}
                  onChange={(e) => handleHighDaysChange(e.target.value)}
                  maxLength={31}
                />
                <span className="errorLabel">{highDaysError}</span>
              </div>
            </div>
          </div>
        )}

        {errorPriority.length > 1 && errorPriority.includes("medium") && (
          <div key={"medium"} className="mb-8">
            <span className="priority-label">{"Medium"}</span>
            <div className="dynamic-container">
              <div className="label-container">
                <label>
                  <span className="spanReq">*</span> Workflow complete
                </label>

                <Select
                  placeholder="Select"
                  onChange={handleMediumChange}
                  value={mediumWorkflow}
                  options={mediumWorkflowList}
                />
              </div>
              <div>
                <label>
                  <span className="spanReq">*</span> Number of days before start
                  days
                </label>

                <Input
                  placeholder="Write here"
                  value={mediumDays}
                  style={{ marginTop: 0 }}
                  onChange={(e) => handleMediumDaysChange(e.target.value)}
                  maxLength={31}
                />
                <span className="errorLabel">{mediumDaysError}</span>
              </div>
            </div>
          </div>
        )}

        {errorPriority.length > 1 && errorPriority.includes("low") && (
          <div key={"low"} className="mb-8">
            <span className="priority-label">{"Low"}</span>
            <div className="dynamic-container">
              <div className="label-container">
                <label>
                  <span className="spanReq">*</span> Workflow complete
                </label>

                <Select
                  placeholder="Select"
                  onChange={handleLowChange}
                  value={lowWorkflow}
                  options={lowWorkflowList}
                />
              </div>
              <div>
                <label>
                  <span className="spanReq">*</span> Number of days before start
                  days
                </label>

                <Input
                  placeholder="Write here"
                  value={lowDays}
                  style={{ marginTop: 0 }}
                  onChange={(e) => handleLowDaysChange(e.target.value)}
                  maxLength={31}
                />
                <span className="errorLabel">{lowDaysError}</span>
              </div>
            </div>
          </div>
        )}

        {errorPriority.length > 1 && (
          <Button
            type="primary"
            className="addPromoTypeBtn"
            onClick={handleReset}
            style={{ width: "200px", marginTop: "10px" }}
          >
            Reset
          </Button>
        )}
      </form>
    );
  };

  const handleReset = () => {
    setUrgentWorkflow("");
    setHighWorkflow("");
    setMediumWorkflow("");
    setLowWorkflow("");
    setDefaultWorkflow("");
    setUrgentDays("");
    setHighDays("");
    setMediumDays("");
    setLowDays("");

    setUrgentWorkflowList(workflow);
    setHighWorkflowList(workflow);
    setMediumWorkflowList(workflow);
    setLowWorkflowList(workflow);

    setUrgentDaysError("");
    setHighDaysError("");
    setMediumDaysError("");
    setLowDaysError("");
  };

  const handleAdd = () => {
    setModalOpen(true);
    setCurrentAction("Create Team");
    setOkBtnText("Add");
  };

  const handleCancel = () => {
    setErrorPriority([]);
    setModalOpen(false);
    setCurrentAction("");
    setOkBtnText("");
    setPromoTypeName("");
    setStatus(true);
    setPromoTypeNameError("");
  };

  const handlePromoTypeName = (e) => {
    const { value } = e.target;
    if (value.length === 0 || value.trim()) {
      setPromoTypeName(value);
    }
    setPromoTypeNameError(
      Validator.validate(
        "alphaNumeric_specialNotStart",
        e.target.value,
        null,
        null,
        true
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newRecord = {};
    if (currentAction === "Create Team") {
      setLoading(true);
      if (errorPriority.length === 1) {
        newRecord = {
          name: promoTypeName,
          priorities: [
            {
              priority: errorPriority[0],
            },
          ],
        };
      }

      if (errorPriority.length > 1) {
        newRecord = {
          name: promoTypeName,
          priorities: getPriorities(),
        };
      }
      const response = await errorTypeService.addErrorType(newRecord);
      setLoading(false);
      if (response?.status) {
        handleCancel();
        showAlert({
          message: response?.message,
          variant: AlertVariant.SUCCESS
        }
        );
        handleReload();
      }
    }

    if (currentAction === "Update Team") {
      setLoading(true);
      if (errorPriority.length === 1) {
        newRecord = {
          name: promoTypeName,
          priorities: [
            {
              priority: errorPriority[0],
            },
          ],
        };
      }

      if (errorPriority.length > 1) {
        newRecord = {
          name: promoTypeName,
          priorities: getPriorities(),
        };
      }
      const resp = await errorTypeService.updateErrorType(
        currentRecord._id, newRecord
      );
      setLoading(false);
      if (resp?.status) {
        handleCancel();
        setLoading(false);
        showAlert({ message: resp?.message || "Change Type successfully updated", variant: AlertVariant.SUCCESS });
        handleReload();
      }
    }

  };

  const getPriorities = () => {
    let data = [];
    errorPriority.forEach((value) => {
      if (value === "urgent") {
        data.push({
          priority: "urgent",
          workflowComplete: getWorkflowLabel(urgentWorkflow),
          numberOfDaysBeforeStart: urgentDays,
        });
      }
      if (value === "high") {
        data.push({
          priority: "high",
          workflowComplete: getWorkflowLabel(highWorkflow),
          numberOfDaysBeforeStart: highDays,
        });
      }
      if (value === "medium") {
        data.push({
          priority: "medium",
          workflowComplete: getWorkflowLabel(mediumWorkflow),
          numberOfDaysBeforeStart: mediumDays,
        });
      }
      if (value === "low") {
        data.push({
          priority: "low",
          workflowComplete: getWorkflowLabel(lowWorkflow),
          numberOfDaysBeforeStart: lowDays,
        });
      }
    });
    return data;
  };

  const getWorkflowLabel = (key) => {
    switch (key) {
      case "25":
        return "Workflow completed >=25%";
      case "50":
        return "Workflow completed >=50%";
      case "75":
        return "Workflow completed >=75%";
      case "100":
        return "Workflow completed 100%";
      default:
        break;
    }
  };

  const getWorkflowValue = (key) => {
    switch (key) {
      case "Workflow completed >=25%":
        return "25";
      case "Workflow completed >=50%":
        return "50";
      case "Workflow completed >=75%":
        return "75";
      case "Workflow completed 100%":
        return "100";
      default:
        break;
    }
  };

  const updatePromoType = (record) => {
    setModalOpen(true);
    setCurrentAction("Update Team");
    setCurrentRecord(record);
    // setPromoTypeName(record.teamName)
    // setStatus(record.statusValue)
    setOkBtnText("Save");
    setPromoTypeName(record.errorType);
    let arr = [];
    record.priorities.forEach((value) => {
      arr.push(value.priority);
      if (value.priority === "urgent") {
        setUrgentDays(value.numberOfDaysBeforeStart);
        setUrgentWorkflow(getWorkflowValue(value.workflowComplete));
      }
      if (value.priority === "high") {
        setHighDays(value.numberOfDaysBeforeStart);
        setHighWorkflow(getWorkflowValue(value.workflowComplete));
      }
      if (value.priority === "medium") {
        setMediumDays(value.numberOfDaysBeforeStart);
        setMediumWorkflow(getWorkflowValue(value.workflowComplete));
      }
      if (value.priority === "low") {
        setLowDays(value.numberOfDaysBeforeStart);
        setLowWorkflow(getWorkflowValue(value.workflowComplete));
      }
    });
    setErrorPriority(arr);
  };

  const deletePromoType = (value) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deletePromoTypeYes(value),
      okType: "primary",
    });
  };

  const deletePromoTypeYes = async (value) => {
    await dispatch(deleteById(value._id));
    window.scrollTo(0, 0);
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
    setShowTeamDeleteAlert(false);
  };

  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false);
    setCurrentAction("");
  };

  const onSearch = async (value) => {
    if (value) {
      getData({ page: 1 });
    } else {
      setSearchedText("");
      getData({ page: 1, search: "" });
    }
  };

  const handleReload = async () => {
    setReLoadingState(true);
    const res = await dispatch(getAllErrorType());
    if (res) {
      setReLoadingState(false);
    }
  };

  const setColumns = () => {
    let cols = [];

    ERRORS_TYPE_LIST.forEach((o) => {
      if (o.dataIndex !== "status" && o.dataIndex !== "statusType") {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
          onFilter: (value, record) => {
            const rec = record[o.dataIndex]
              ? record[o.dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
              : "";
            return rec;
          },
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }
    });
    return cols;
  };

  const handleTableHeight = () => {
    if (tableClicked === 2) {
      setTableClicked(0);
      setTableSize("default");
    }
    if (tableClicked === 0) {
      setTableClicked(tableClicked + 1);
      setTableSize("small");
    }
    if (tableClicked === 1) {
      setTableClicked(tableClicked + 1);
      setTableSize("middle");
    }
  };

  const handleSearchChange = (e) => {
    setSearchedText(e.target.value);
  };
  const viewTeamDetails = async (record) => {
    let newRecord = {
      _id: record._id,
      name: record.teamName,
      status: record.statusValue,
    };
    dispatch(updateLocalCurrentTeam(newRecord));
    navigate(`/teams/team-detail/${record._id}`);
  };

  const isFormValidate = () => {
    let isValuePresent = false;
    errorPriority.forEach((value) => {
      if (value === "urgent") {
        if (urgentWorkflow && urgentDays && !urgentDaysError) {
          isValuePresent = true;
        } else {
          isValuePresent = false;
        }
      }
      if (value === "high") {
        if (highWorkflow && highDays && !highDaysError) {
          isValuePresent = true;
        } else {
          isValuePresent = false;
        }
      }
      if (value === "medium") {
        if (mediumWorkflow && mediumDays && !mediumDaysError) {
          isValuePresent = true;
        } else {
          isValuePresent = false;
        }
      }
      if (value === "low") {
        if (lowWorkflow && lowDays && !lowDaysError) {
          isValuePresent = true;
        } else {
          isValuePresent = false;
        }
      }
    });
    return errorPriority.length === 1 ? true : isValuePresent;
  };
  const checkPermission = (row) => {
    if (role === "admin") {
      return false;
    } else {
      return row?.createdBy === session.userId ? false : true;
    }

  };
  return (
    <CommonLayout className="content-mr" breadCrumbText="Change Type">
      <div className="promo-type-card">
        <div className="promo-right-content">
          <Search
            placeholder="Search"
            enterButton
            allowClear
            className="searchPromoTypeBtn"
            onSearch={onSearch}
            onChange={handleSearchChange}
          />
        </div>

        <div className="promo-left-content">
          <Button
            type="primary"
            icon={<UserAddOutlined />}
            className="addPromoTypeBtn"
            onClick={handleAdd}
          >
            Add change type
          </Button>
        </div>
      </div>
      {loadingState || reloadingState ? (
        <div className="errorTipDiv">
          <Spin size="middle" tip="Loading..." />
        </div>
      ) : (
        <Fullscreen isEnter={isEnter} onChange={setIsEnter}>
          <div className="promo-table-card">
            <div className="promo-table-topbar">
              <div className="promoRightFilterGrpWrapper">
                <div className="promoRightSettingGrp">
                  <ReloadOutlined
                    onClick={handleReload}
                    className="reloadIconStyle"
                  />
                  <ColumnHeightOutlined
                    className="reloadIconStyle"
                    onClick={handleTableHeight}
                  />
                  <FullscreenOutlined
                    onClick={() => {
                      setIsEnter(true);
                    }}
                    className="reloadIconStyle"
                  />
                </div>
              </div>
            </div>

            {/* table */}

            <div className="w-100">
              <CustomTable
                columns={setColumns()}
                rows={setupRows(
                  searchedPromoTypes.length > 0 ? errors : errors
                )}
                isEditable={true}
                isDeleteable={true}
                rowKey="_id"
                onEdit={updatePromoType}
                onDelete={deletePromoType}
                total={total}
                size={tableSize}
                isViewable={false}
                isPopup={true}
                isPromoView={false}
                onView={viewTeamDetails}
                isLoading={tableLoading}
                checkRowDeleatable={checkPermission}
                onChange={async (
                  { current, pageSize },
                  filter = {},
                  { order, columnKey }
                ) => {
                  const params = {
                    page: current,
                    perPage: pageSize,
                    sortBy: order && columnKey,
                    orderBy: order,
                  };
                  setCurrPage(current);
                  setPageSize(pageSize);
                  setTableLoading(true);
                  getData(params, false);
                }}
              />
            </div>

            <Modal
              open={modalOpen}
              title={`${currentAction === "Create Team" ? 'Add' : 'Update'} a Change type`}
              onOk={handleSubmit}
              onCancel={handleCancel}
              okText={okBtnText}
              width={550}
              destroyOnClose
              footer={
                currentAction === "Create Team" ||
                  currentAction === "Update Team"
                  ? [
                    <Button onClick={handleCancel}>Cancel</Button>,
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={
                        !promoTypeName ||
                        errorPriority.length === 0 ||
                        !isFormValidate()
                      }
                      className={`footerBtnNavy ${!promoTypeName ||
                        errorPriority.length === 0 ||
                        !isFormValidate()
                        ? "footerDisabled"
                        : ""
                        }`}
                    >
                      {loading ? "Loading..." : okBtnText}
                    </Button>,
                  ]
                  : null
              }
            >
              {currentAction === "Create Team" ||
                currentAction === "Update Team" ? (
                renderTag(currentRecord)
              ) : (
                <></>
              )}
            </Modal>

            {showDeleteAlert && (
              <Alert
                message={TextMessage.ERROR_TYPE_DELETE_TEXT}
                type="error"
                showIcon
                closable
                onClose={handleDeleteAlertClose}
                icon={<DeleteOutlined />}
                className="alertStyle"
              />
            )}

            {showTeamDeleteAlert && (
              <Alert
                message={TextMessage.TEAM_DELETE_MESSAGE}
                type="error"
                showIcon
                closable
                onClose={handleDeleteAlertClose}
                icon={<DeleteOutlined />}
                className="alertStyle"
              />
            )}

            {showUpdateAlert && (
              <Alert
                message={TextMessage.TEAM_UPDATE_TEXT}
                type={"success"}
                showIcon
                closable
                onClose={handleUpdateAlertClose}
                className="alertStyle"
              />
            )}
            {
              <Modal
                open={showAddAlert}
                title={""}
                onCancel={() => setShowAddAlert(false)}
                destroyOnClose
                footer={null}
              >
                <div className="success-container">
                  <CheckCircleFilled className="check-icon" />
                  <p>Your Change Type has been created</p>
                </div>
              </Modal>
            }
          </div>
        </Fullscreen>
      )}
    </CommonLayout>
  );
};

export default ErrorType;
