import { createMap, mapCreateMessage, mapValidateMessage } from 'actions/map'
import { Alert, Button, Input, Select, Table } from 'antd'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { SECONDS, TextMessage } from 'utils/constants'

const Option = Select;

const ThirdStep = ({step,setStep,skuPayload,setTableData,tableData,setSkuPayload}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const mapValidateText = useSelector(state => state.maps.mapValidateMessage)
  const [tableColumn,setTableColumn] = useState([])
  const [rowMapKey, setRowMapKey] = useState([]);
  const [requiredErrorText, setRequiredErrorText] = useState('');
  const [requiredError, setRequiredError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      if(requiredError){
        setTimeout(() => {
          setRequiredError(false)
        }, SECONDS);
      }

      if(mapValidateText){
        setTimeout(() => {
          dispatch(mapValidateMessage(''))
        }, SECONDS);
      }

      if(mapCreateMessage){
        setTimeout(() => {
          dispatch(mapCreateMessage(''))
        }, SECONDS);
      }
      
    },[requiredError,mapValidateText,dispatch])

  useEffect(() => {
    if(skuPayload && skuPayload.length>0 && skuPayload[0].mapType === 'sku' && tableData && tableData.length>0){
      const columnsData = skuPayload[0].dynamicRecord[0]?.fieldData?.map((item,i) => {
        return {
          title: item.questionHeader,
          dataIndex: item.questionHeader,
          key: item.questionHeader,
        }
      })

      const columnsSystemData = skuPayload[0].dynamicRecord[0]?.fieldSysData?.map((item,i) => {
        return {
          title: item.label,
          dataIndex: item.label,
          key: item.label,
        }
      })

      setTableColumn([{
          title: 'SKU',
          dataIndex: 'sku',
          key: 'sku',
        },...columnsData,
        ...columnsSystemData,
        {
          title: 'MAP Price Update',
          dataIndex: 'mapPriceUpdate',
          key: 'mapPriceUpdate',
          render: (text, record, index) => (
        <Select
        value={text || null}
        onChange={(value) =>handleMapPriceUpdate(value,record,index,tableData)}
        style={{width:'100px'}}
        placeholder='Select'
        >
          <Option value={'Yes'}>Yes</Option>
          <Option value={'No'}>No</Option>
        </Select>
      )
        },{
          title: 'MAP Price Load',
          dataIndex: 'mapPriceLoad',
          key: 'mapPriceLoad',
          render: (text, record, index) => (
        <Input value={text} 
          onChange={(e) => handleMapPriceLoad(e,record,index)} 
          style={{width:'fit-content',maxWidth:'100px'}}
        />
      )
        },    
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[skuPayload,tableData])


  const handleMapPriceUpdate = (value,record,index) => {
    const newData = [...tableData]
    const newData1 = [...skuPayload[0].dynamicRecord]
    newData[index]['mapPriceUpdate'] = value;
    newData1[index]['mapPriceUpdate'] = value;
    setTableData(newData)
  }

  const handleMapPriceLoad = (e,record,index) => {
    const newData = [...tableData]
    const newData1 = [...skuPayload[0].dynamicRecord]
    newData[index]['mapPriceLoad'] = e.target.value;
    newData1[index]['mapPriceLoad'] = e.target.value;
    setTableData(newData)
  }

  const handleSubmit = async () => {
    let arr = [];
    
    for (let i = 0; i < tableData.length; i++) {

      if(tableData[i]?.mapPriceFlag === true && tableData[i]['mapPriceUpdate'] === ''){
        arr.push(tableData[i].key)
      }

      if(tableData[i]?.mapPriceFlag === true && (tableData[i]['mapPriceUpdate'] === 'Yes' && tableData[i]['mapPriceLoad'] === '')){
        arr.push(tableData[i].key)
      }

      setRowMapKey([...arr])

    }

    if(arr.length === 0) {
      const payload = skuPayload[0]
      const data = {
        ...payload,
        status: 'pending'
      }

      setLoading(true)
      
      const res = await dispatch(createMap(data))

      if(res.error === undefined){
        dispatch(mapCreateMessage(TextMessage.MAP_CREATE_TEXT))
        navigate('/map')
        setLoading(false)
      }else{
        setLoading(false)
      }

    }else{
      setRequiredErrorText(TextMessage.MAP_PRICE_REVIEW_TEXT)
      setRequiredError(true)
    }

  }

  const handleRowClassName = (record, index) => {
      for (let i = 0; i < rowMapKey.length; i++) {
        if(rowMapKey[i] === index) return 'tb-bg'
      }
    }

  const handleErrorAlertClose = () => {
      setRequiredError(false)
    }

  const handleSucessAlertClose = () => {
      dispatch(mapValidateMessage(''))
    }

  return (
    <div className='w-100'>
      <>
          <div className='w-100 map-table' style={{overflowX:'auto'}}>
        <Table
        columns={tableColumn}
        dataSource={tableData}
        rowClassName={(record, index) => handleRowClassName(record, index)}
        pagination={false}
        />
      </div>

      <div className='mt-30 text-end'>
            <Button className='footerBtnNavy' onClick={handleSubmit} disabled={loading}>{loading ? 'Loading...' : 'Submit'}</Button>
      </div>

      {
        requiredError && <Alert message={requiredErrorText} type={'error'}
                        showIcon closable onClose={handleErrorAlertClose}
                        className='alertStyle'
                  />
      }

      {
        mapValidateText && <Alert message={mapValidateText} type={'error'}
                        showIcon closable onClose={handleSucessAlertClose}
                        className='alertStyle'
                  />
      }
        </>
      
    </div>
  )
}

export default ThirdStep