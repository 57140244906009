export default class PromotionStateManager {
  static subOptionSuccess = (prevState, action) => {
    let state = { ...prevState };     
    const { value, id1, id2,label, masterDataType } = action.data;
    let Ans = state.subOption1;

    let b= []

    let same = true;
    if (state?.subOption1?.length) {
     Ans = state?.subOption1?.map((item) => {
        if (id1 === null && id2 === item?.id2) {
          b.push(item);          
        }
        if (value === null && id1 === null && id2 === item?.id2) {
          same = false;
          return { value: '', id1, id2,label,masterDataType };     
        }
        if (id1 === item?.id1 && id2 === item?.id2) {
          same = false;
          return { value, id1, id2,label,masterDataType };
        } else {
          return item;
        }        
      });
    } else {
      value && Ans.push({ value, id1, id2,label,masterDataType });
      same = false;
    }
    same && value && Ans.push({ value, id1, id2,label ,masterDataType});    
    state.subOption1 = Ans[0] ? Ans : []; 
 
    return state;
  };

  static subOptionInputSuccess = (prevState, action) => {
    let state = { ...prevState };
    const { value, id1, id2,label } = action.data;
    let Ans = state.subOptionInput;
    let b= []

    let same = true;
    if (state?.subOptionInput?.length) {
     Ans = state?.subOptionInput?.map((item) => {
        if (id1 === null && id2 === item?.id2) {
          b.push(item);          
        }
        if (value === null && id1 === null && id2 === item?.id2) {
          same = false;
          return { value: '', id1, id2,label };     
        }
        if (id1 === item?.id1 && id2 === item?.id2) {
          same = false;
          return { value, id1, id2,label };
        }  else {
          return item;
        }        
      });
    } else {
      value && Ans.push({ value, id1, id2,label });
      same = false;
    }
    same && value && Ans.push({ value, id1, id2,label });
   
    state.subOptionInput = Ans[0] ? Ans : [];
    
    return state;
  };

  static subOptionInputThreeSuccess = (prevState, action) => {
    let state = { ...prevState };
    const { value, id1, id2,label } = action.data;
    let Ans = state.subOptionInputThree;
    let b= []
   
    let same = true;
    if (state?.subOptionInputThree?.length) {
     Ans = state?.subOptionInputThree?.map((item) => {
        if (id1 === null && id2 === item?.id2) {
          b.push(item);          
        }
        if (value === null && id1 === null && id2 === item?.id2) {
          same = false;
          return { value: '', id1, id2,label };     
        }
        if (id1 === item?.id1 && id2 === item?.id2) {
          same = false;
          return { value, id1, id2,label };
        }  else {
          return item;
        }        
      });
    } else {
      value && Ans.push({ value, id1, id2,label });
      same = false;
    }
    same && value && Ans.push({ value, id1, id2,label });
   
    state.subOptionInputThree = Ans[0] ? Ans : [];
    
    return state;
  };

  static getPromoAssociatedBrand = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.promoAssociatedBrand = data || [];

        return state;
    }

  static getPromoAssociatedSku = (prevState, action) => {
      const state = { ...prevState };

      const  data  = action.payload.data.data;
      
      state.promoAssociatedSku = data || [];

      return state;
    }

  static getPromoAssociatedShelf = (prevState, action) => {
      const state = { ...prevState };

      const  data  = action.payload.data.data;
      
      state.promoAssociatedShelf = data || [];

      return state;
    }

  static getRequiredQuestionPromoType = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.data.value;
        
        state.requiredQuestionPromoType = data || [];

        return state;
    }

  static resetData = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.data.value;
        
        state.subOption1 = data || [];
        state.subOptionInput = data || [];
        state.subOptionInputThree = data || [];

        return state;
    }

}
