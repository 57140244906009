export default class SnapshotStateManager{
    static getAllSnapshots = (prevState, action) => {
        const state = { ...prevState };

        const  data  = action.payload.data.data;
        
        state.snapshots = data.logs || [];
        state.paginateDetails = data.paginate || [];

        return state;
    }

}