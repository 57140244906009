import "./Users.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Switch,
  Select,
  Space,
} from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { setPage } from "actions/app";
import CustomTable from "components/common/table/Table";
import { USER_LIST_COLUMNS } from "utils/column";
import { Validator } from "utils/validations";
import { TextMessage, FIELD_REQUIRED, SECONDS } from "utils/constants";
import {
  updateUserById,
  deleteUserById,
  updateUserStatus,
  getAllUsers,
} from "actions/users/index";
import TableSearchDropdown from "components/TableSearchDropdown";
import { USER_PERMISSIONS } from "utils/column";
import session from "utils/session";
import useAlert from "context/alert/alert.hook";
import { AlertVariant } from "context/alert/alert.provider";

const ApproverUsers = ({ props }) => {
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const users = useSelector((state) => state.users.users);
  const searchedUsers = useSelector((state) => state.users.searchedUsers);
  const [selectedTeamId, setSelectedTeamId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const { teams } = useSelector((state) => state.teams);
  const [teamIdError, setTeamIdError] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState("");
  const [currentRecord, setCurrentRecord] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("Merchant");
  const userRole = session && session.role ? session.role.toLowerCase() : "";
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [okBtnText, setOkBtnText] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showUpdateAlert, setShowUpdateAlert] = useState(false);
  const [updateAlertText, setUpdateAlertText] = useState("");
  const [updateAlertType, setUpdateAlertType] = useState("");
  const [loading, setLoading] = useState(false);
  const [userActive, setUserActive] = useState(true);
  const [userDeActive, setUserDeActive] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const teamDetail = session && session.teamDetail ? session.teamDetail : null;
  useEffect(() => {
    dispatch(setPage("Approver"));
  }, [dispatch]);

  useEffect(() => {
    setFilteredUsers(
      users?.filter((item) => item.role.toLowerCase() === "approver")
    );
  }, [users]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (showUpdateAlert) {
      setTimeout(() => {
        setShowUpdateAlert(false);
      }, SECONDS);
    }
    if (showDeleteAlert) {
      setTimeout(() => {
        setShowDeleteAlert(false);
      }, SECONDS);
    }
  }, [showUpdateAlert, showDeleteAlert]);

  const changeStatusYes = async (id) => {
    Modal.confirm({
      title: "Do you want to make this user De-activate?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => userDeactivateYes(id, false),
      okCancel: userDeactivateNo,
      okType: "primary",
    });
  };

  const userDeactivateYes = async (id, checked) => {
    const resp = await dispatch(updateUserStatus(id, checked));
    if (resp.error) {
      showAlert({
        message: resp.error.response.data.message,
        variant: AlertVariant.ERROR
      });
    }
  };
  const userDeactivateNo = () => {
    setUserActive(true);
  };

  const changeStatusNo = async (id, checked) => {
    Modal.confirm({
      title: "Do you want to make this user Activate?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => userActivateYes(id, true),
      okCancel: userActivateNo,
      okType: "primary",
    });
  };

  const userActivateYes = async (id, checked) => {
    const resp = await dispatch(updateUserStatus(id, checked));
    if (resp.error) {
      showAlert({
        message: resp.error.response.data.message,
        variant: AlertVariant.ERROR
      });
    }
  };
  const userActivateNo = () => {
    setUserDeActive(false);
  };

  const setupRows = (users) => {
    return users?.map((o) => {
      return {
        userName: o.fullName,
        _id: o._id,
        email: o.email,
        role: o.role.charAt(0).toUpperCase() + o.role.slice(1),
        accessType: o.role.toLowerCase() === "Admin" ? "Full" : "Edit Only",
        active: o.isActive ? (
          <Switch
            checked={userActive}
            className="switchStyle"
            onChange={() => changeStatusYes(o._id)}
          />
        ) : (
          <Switch
            checked={userDeActive}
            onChange={() => changeStatusNo(o._id)}
          />
        ),
        firstName: o?.firstName,
        lastName: o?.lastName,
        teamId: o?.teamId,
        permissions: o?.permissions,
      };
    });
  };

  const renderTag = () => {
    return (
      <form className="userForm" onSubmit={handleSubmit}>
        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={12}>
            <div>
              <label
                className={currentAction === "View Details" ? "label-grey" : ""}
              >
                {currentAction !== "View Details" && (
                  <span className="spanReq">*</span>
                )}{" "}
                First Name
              </label>

              <Input
                placeholder="First Name"
                value={firstName}
                className="mt-5"
                onChange={handleFirstName}
                disabled={currentAction === "View Details"}
              />
              <span className="errorLabel">{firstNameError}</span>
            </div>
          </Col>

          <Col className="gutter-row" span={12}>
            <div>
              <label
                className={currentAction === "View Details" ? "label-grey" : ""}
              >
                {currentAction !== "View Details" && (
                  <span className="spanReq">*</span>
                )}{" "}
                Last Name
              </label>
              <Input
                placeholder="Last Name"
                value={lastName}
                className="mt-5"
                onChange={handleLastName}
                disabled={currentAction === "View Details"}
              />
              <span className="errorLabel">{lastNameError}</span>
            </div>
          </Col>
        </Row>

        <Row gutter={16} className="mb-25">
          <Col className="gutter-row" span={24}>
            <div>
              <label
                className={currentAction === "View Details" ? "label-grey" : ""}
              >
                {currentAction !== "View Details" && (
                  <span className="spanReq">*</span>
                )}{" "}
                Email
              </label>
              <Input
                placeholder="Email"
                value={email}
                className="mt-5"
                onChange={handleEmail}
                disabled={currentAction !== "Add User"}
              />
              <span className="errorLabel">{emailError}</span>
            </div>
          </Col>
        </Row>

        <span className="passwordSpan">Role</span>
        <div>
          <Radio.Group
            onChange={onRoleChange}
            value={role.toLowerCase()}
            disabled={currentAction === "View Details"}
          >
            <Radio value={"admin"}>Admin</Radio>
            <Radio value={"basic"}>Basic User</Radio>
            <Radio value={"approver"}>Approver</Radio>
          </Radio.Group>
        </div>

        {role !== "Admin" && (
          <>
            {" "}
            <span
              className="passwordSpan"
              style={{
                marginTop: "10px",
              }}
            >
              Teams
            </span>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Select team"
                onChange={handleUserChange}
                options={teams.filter((i) => i.status === 1)}
                defaultValue={teams.find((team) => team._id === selectedTeamId)}
                disabled={currentAction === "View Details"}
              />
            </Space>
            <span className="errorLabel">{teamIdError}</span>
          </>
        )}

        {role !== "Admin" && (
          <>
            <span
              className="passwordSpan"
              style={{
                marginTop: "10px",
              }}
            >
              Permissions
            </span>
            <Space
              style={{
                width: "100%",
              }}
              direction="vertical"
            >
              <Select
                mode="multiple"
                allowClear
                style={{
                  width: "100%",
                }}
                placeholder="Select permissions"
                onChange={handlePermissionChange}
                options={USER_PERMISSIONS}
                defaultValue={permissions}
                disabled={currentAction === "View Details"}
              />
            </Space>
          </>
        )}
      </form>
    );
  };

  const handleUserChange = (value) => {
    setTeamIdError("");
    setSelectedTeamId(value);
  };

  const handlePermissionChange = (value) => {
    setPermissions(value);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setCurrentAction("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setOkBtnText("");
  };

  const onRoleChange = (e) => {
    setTeamIdError("");
    setRole(e.target.value);
  };

  const handleFirstName = (e) => {
    const { value } = e.target;
    setFirstNameError(Validator.validate("title", value, null, null, true));
    setFirstName(value);
  };

  const handleLastName = (e) => {
    const { value } = e.target;
    setLastNameError(Validator.validate("title", value, null, null, true));
    setLastName(value);
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmailError(Validator.validate("email", value, null, null, true));
    setEmail(value);
  };

  const updateUser = (record) => {
    setModalOpen(true);
    setCurrentAction("Update User");
    setCurrentRecord(record);
    setFirstName(record.firstName);
    setLastName(record.lastName);
    setEmail(record.email);
    setRole(record.role);
    setSelectedTeamId(record?.teamId);
    setPermissions(record?.permissions);
    setOkBtnText("Save");
  };

  const viewUser = (record) => {
    setModalOpen(true);
    setCurrentAction("View Details");
    setCurrentRecord(record);
    setFirstName(record.firstName);
    setLastName(record.lastName);
    setEmail(record.email);
    setRole(record.role);
    setSelectedTeamId(record?.teamId);
    setPermissions(record?.permissions);
  };

  const resetValues = () => {
    setModalOpen(false);
    setCurrentAction("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setOkBtnText("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let newRecord = {};

    if (currentAction === "Update User") {
      if (
        firstNameError !== "" ||
        lastNameError !== "" ||
        emailError !== "" ||
        firstName === "" ||
        lastName === "" ||
        email === ""
      ) {
        setFirstNameError(firstName === "" ? FIELD_REQUIRED : firstNameError);
        setLastNameError(lastName === "" ? FIELD_REQUIRED : lastNameError);
        setEmailError(email === "" ? FIELD_REQUIRED : emailError);
        return;
      }

      if (role.toLowerCase() === "approver" && !selectedTeamId) {
        setTeamIdError(FIELD_REQUIRED);
        return;
      }

      setLoading(true);

      newRecord = {
        _id: currentRecord._id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        role: role,
        fullName: `${firstName} ${lastName}`,
        permissions:
          permissions.length && role.toLowerCase() !== "admin"
            ? permissions
            : [],
      };

      if (role.toLowerCase() !== "admin" && selectedTeamId) {
        newRecord.teamId = selectedTeamId;
      }

      const response = await dispatch(updateUserById(newRecord));

      if (response.error === undefined) {
        setLoading(false);
        setShowUpdateAlert(true);
        setUpdateAlertText(TextMessage.USER_UPDATE_TEXT);
        setUpdateAlertType("success");
        resetValues();
      } else {
        setLoading(false);
        setShowUpdateAlert(true);
        setUpdateAlertText(response.error.message);
        setUpdateAlertType("error");
        resetValues();
      }
    }
  };

  const deleteUser = (val) => {
    Modal.confirm({
      title: TextMessage.CONFIRMATION_TEXT,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => deleteUserYes(val),
      okType: "primary",
    });
  };

  const deleteUserYes = async (val) => {
    await dispatch(deleteUserById(val._id));
    setShowDeleteAlert(true);
    dispatch(getAllUsers());
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleUpdateAlertClose = () => {
    setShowUpdateAlert(false);
    setCurrentAction("");
  };

  const setColumns = () => {
    let cols = [];

    USER_LIST_COLUMNS.forEach((o) => {
      if (
        o.dataIndex !== "role" &&
        o.dataIndex !== "accessType" &&
        o.dataIndex !== "active"
      ) {
        const colsOptions = {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <TableSearchDropdown
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                dataIndex={o.dataIndex}
              />
            );
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          ),
          onFilter: (value, record) => {
            const rec = record[o.dataIndex]
              ? record[o.dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase())
              : "";
            return rec;
          },
        };
        cols.push({ ...o, ...colsOptions });
      } else {
        cols.push(o);
      }
    });
    return cols;
  };

  return (
    <>
      {/* table starts */}
      <div className="w-100">
        <CustomTable
          columns={setColumns()}
          rows={setupRows(
            searchedUsers.length > 0 ? searchedUsers : filteredUsers
          )}
          isEditable={!teamDetail?.isVCM}
          isDeleteable={!teamDetail?.isVCM}
          rowKey="_id"
          isViewable={true}
          onEdit={updateUser}
          onDelete={deleteUser}
          total={users?.length}
          onView={viewUser}
          isPopup={true}
        />
      </div>

      {/* modal starts */}

      <Modal
        visible={modalOpen}
        title={currentAction}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText={okBtnText}
        destroyOnClose
        footer={
          currentAction === "Add User" || currentAction === "Update User"
            ? [
              <Button onClick={handleCancel}>Cancel</Button>,
              <Button
                type="primary"
                onClick={handleSubmit}
                className="footerBtnNavy"
                disabled={loading}
              >
                {loading ? `Loading...` : okBtnText}
              </Button>,
            ]
            : null
        }
      >
        {currentAction === "Add User" ||
          currentAction === "Update User" ||
          currentAction === "View Details" ? (
          renderTag(currentRecord)
        ) : (
          <></>
        )}
      </Modal>
      {showDeleteAlert && (
        <Alert
          message={TextMessage.USER_DELETE_TEXT}
          type="error"
          showIcon
          closable
          onClose={handleDeleteAlertClose}
          icon={<DeleteOutlined />}
          className="alertStyle"
        />
      )}

      {showUpdateAlert && (
        <Alert
          message={updateAlertText}
          type={updateAlertType}
          showIcon
          closable
          onClose={handleUpdateAlertClose}
          className="alertStyle"
        />
      )}
    </>
  );
};

export default ApproverUsers;
